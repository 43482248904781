import React, { useEffect, useRef } from 'react';

const useScrollListItemIntoView = (isFocused: boolean): React.Ref<HTMLLIElement> => {
  const ref = useRef<HTMLLIElement>(null);
  useEffect(() => {
    if (isFocused) {
      ref.current?.scrollIntoView?.({ block: 'nearest' });
    }
  }, [isFocused]);
  return ref;
};

export default useScrollListItemIntoView;
