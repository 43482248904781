import { Maybe } from '@tellurian/ts-utils';
import { TermsOfServicePolicyType, User } from '../../generated/graphql';

export const PolicyTypeDisplayNames: Readonly<Record<TermsOfServicePolicyType, string>> = {
  [TermsOfServicePolicyType.ResellerPartner]: 'Reseller Partner',
  [TermsOfServicePolicyType.AgencyThirdParty]: 'Agency/3rd Party',
  [TermsOfServicePolicyType.ProofOfConcept]: 'Evaluation',
};

type UserFragment = Pick<User, 'id' | 'displayName' | 'email'>;

export type TermsOfServiceAcceptance = {
  acceptedBy: UserFragment;
  fullName: string;
  acceptedAt: string;
} & VersionIdentifier;

export type TermsOfServiceRecord = Maybe<{
  policyType: TermsOfServicePolicyType;
  policyAdmin: UserFragment;
  acceptance?: TermsOfServiceAcceptance;
}>;

export type VersionIdentifier = {
  policyType: TermsOfServicePolicyType;
  version: number;
};
