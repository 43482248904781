import React, { useState } from 'react';
import { useRevokeUserAccountAccessMutation, UserFragment } from '../../../../generated/graphql';
import DeleteModal, { DeleteModalProps } from '../../components/Modal/DeleteModal';
import useEventCallback from '../../utils/useEventCallback';
import { useSuccess } from '../../../Toast/Toasts';
import GenericUserItem from './GenericUserItem';

type DeleteUserModalProps = Pick<DeleteModalProps, 'onCloseClick'> & {
  accountId: string;
  onUserDeleted?: () => void;
  user: Pick<UserFragment, 'id' | 'email' | 'displayName' | 'avatarUrl'>;
};

const DeleteUserModal: React.FC<DeleteUserModalProps> = ({
  accountId,
  user,
  onCloseClick,
  onUserDeleted,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [revokeUserAccess] = useRevokeUserAccountAccessMutation({
    variables: { input: { accountId, userId: user.id } },
  });
  const { show } = useSuccess();
  const onDelete = useEventCallback(() => {
    setIsDeleting(true);
    revokeUserAccess()
      .then(() => onUserDeleted?.())
      .finally(() => {
        setIsDeleting(false);
        show(
          <>
            Removed user <strong>{user.email}</strong>.
          </>,
        );
      });
  });

  return (
    <DeleteModal
      title="Delete user"
      onCloseClick={onCloseClick}
      onDelete={onDelete}
      isDeleting={isDeleting}
      size="S"
    >
      <p>Are you sure you want to delete this user?</p>
      <div className="mod">
        <GenericUserItem {...user} />
      </div>
    </DeleteModal>
  );
};

export default DeleteUserModal;
