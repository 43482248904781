import { useCallback } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ConnectorApplication } from '../../../generated/graphql';
import Configuration from '../FeatureFlagRules';
import { KnownFlags } from '../../lettuce/common/featureFlags/KnownFlags';
import useIsFeatureFlagEnabled from '../../lettuce/common/featureFlags/useIsFeatureFlagEnabled';

export const useIsDashboardAvailableForConnectorApplications = () => {
  const flags = useFlags();

  const isRetailerUnifiedVoidsDashboardEnabled = useIsFeatureFlagEnabled(
    KnownFlags.EnableRetailerUnifiedVoidsDashboard,
  );

  const isRetailerUnifiedOpportunitiesDashboardEnabled = useIsFeatureFlagEnabled(
    KnownFlags.EnableRetailerUnifiedOpportunitiesDashboard,
  );

  const isUnifiedSalesV2DashboardsEnabled = useIsFeatureFlagEnabled(
    KnownFlags.EnableUnifiedSalesV2Dashboards,
  );

  const isDistributorVoidsDashboardEnabled = useIsFeatureFlagEnabled(
    KnownFlags.EnableDistributorVoidsDashboard,
  );

  const isRetailerWasteDashboardEnabled = useIsFeatureFlagEnabled(
    KnownFlags.EnableRetailerWasteDashboard,
  );

  return useCallback(
    (
      dashboardId: string,
      connectorApplications: ConnectorApplication[],
      dashboardGroupId?: string,
    ) => {
      const rulesForDashboardId = Configuration[dashboardId];
      const connectorApplication = connectorApplications
        .slice()
        .sort((a, b) => a.localeCompare(b))
        .join('-');
      if (
        !isRetailerUnifiedVoidsDashboardEnabled &&
        dashboardGroupId === 'retailer-unified' &&
        dashboardId === 'voids'
      ) {
        return false;
      }
      if (
        !isRetailerUnifiedOpportunitiesDashboardEnabled &&
        dashboardGroupId === 'retailer-unified' &&
        dashboardId === 'opportunities'
      ) {
        return false;
      }
      if (
        !isUnifiedSalesV2DashboardsEnabled &&
        (dashboardGroupId === 'distributor-unified' || dashboardGroupId === 'retailer-unified') &&
        dashboardId === 'sales-v2'
      ) {
        return false;
      }
      if (
        !isDistributorVoidsDashboardEnabled &&
        dashboardGroupId === 'distributor-unified' &&
        dashboardId === 'voids'
      ) {
        return false;
      }
      if (
        !isRetailerWasteDashboardEnabled &&
        dashboardGroupId === 'retailer-unified' &&
        dashboardId === 'waste'
      ) {
        return false;
      }
      if (rulesForDashboardId) {
        const includeIf = [
          rulesForDashboardId.Global?.includeIf,
          rulesForDashboardId.DashboardGroups?.[connectorApplication]?.includeIf,
        ].filter(Boolean) as string[];
        const excludeIf = [
          rulesForDashboardId.Global?.excludeIf,
          rulesForDashboardId.DashboardGroups?.[connectorApplication]?.excludeIf,
        ].filter(Boolean) as string[];
        return (
          (!includeIf.length || includeIf.some(r => flags[r])) &&
          (!excludeIf.length || excludeIf.every(r => !flags[r]))
        );
      }
      return true;
    },
    [
      isRetailerUnifiedVoidsDashboardEnabled,
      isRetailerUnifiedOpportunitiesDashboardEnabled,
      flags,
      isUnifiedSalesV2DashboardsEnabled,
      isDistributorVoidsDashboardEnabled,
      isRetailerWasteDashboardEnabled,
    ],
  );
};
