import React from 'react';
import { Redirect } from 'react-router';
import { MemberAuthorityGrant } from '../../../security/AuthorizationContext';
import { useAuthenticationContext } from '../../../security/AuthenticationContext';
import AccountTemplate from '../templates/AccountTemplate';
import { path } from '../routing/lib';
import { lazyWithRetry } from '../../../lib';
import { AccountIdMatchParams } from './lib';

const { REACT_APP_REMA_PROVIDER_ID } = process.env;

const RenderHome = lazyWithRetry(() => import(/* webpackChunkName: "home" */ '../../../Home'));

function Homepage({ accountId }: { accountId: string }) {
  return (
    <AccountTemplate requiredGrant={MemberAuthorityGrant}>
      <RenderHome accountId={accountId} />
    </AccountTemplate>
  );
}

const AccountHomePage: React.FC<AccountIdMatchParams> = ({ match: { params } }) => {
  const { firebaseUser } = useAuthenticationContext();
  const isRemaUser = firebaseUser?.providerData?.find(
    data => data?.providerId === REACT_APP_REMA_PROVIDER_ID,
  );

  return isRemaUser ? (
    <Redirect to={path('RemaConnect')({ accountId: params.accountId })} />
  ) : (
    <Homepage accountId={params.accountId} />
  );
};

export default AccountHomePage;
