import React, { useMemo } from 'react';
import clsx from 'clsx';
import ReportTitle from '../ReportTitle';
import ExpandableList from '../../../../components/ExpandableList';
import { RenderItemProps } from '../../../../../ui/lists/listOfItems/ListOfItems';
import ConnectorLogo from '../../../../components/ConnectorLogo';
import Chip from '../../../../components/Chip';
import { usePopoverContext } from '../../../../components/NavRail/PopoverActionContext';
import {
  ConnectorApplication,
  DashboardFragment,
  DashboardTier,
} from '../../../../../../generated/graphql';
import { Feature, trackFeature } from '../../../../../../utils/features';
import {
  DashboardForMenu,
  DashboardGroupsWithDashboardsByTier,
} from '../AllReports/useDashboardGroupsWithDashboardsByTier';
import SectionTitle from './SectionTitle';
import useIsBetaDashboard from './useIsBetaDashboard';
import style from './StandardDashboardGroup.module.css';
import commonStyle from '../common.module.css';

type RenderListItemProps = Pick<StandardDashboardGroupProps, 'getPath'> & {
  isBeta: (dashboard: DashboardForMenu) => boolean;
};
const RenderListItem: React.FC<RenderItemProps<DashboardForMenu, RenderListItemProps>> = ({
  item,
  getPath,
  isBeta,
}) => {
  const { hide } = usePopoverContext();
  const itemPath = getPath(item.dashboardGroupId, item.id);

  return (
    <li className={style.listItem}>
      <Chip
        onClick={() => {
          trackFeature(Feature.AnalyticsMenuItemClicked, item);
          hide();
        }}
        href={itemPath}
        className={clsx({ [commonStyle.beta]: isBeta(item) })}
      >
        {item.name}
      </Chip>
    </li>
  );
};

/**
 * Display standard dashboards in this order by name.
 *
 *  Name is used over id due to occassional dashboard upgrades that result in a change of id.
 *  This is probably not ideal but name should be more constant than id.
 *
 *  Unspecified dashboards are displayed in whatever order they are returned by the API.
 */
const DASHBOARD_ORDER_BY_TIER = {
  [DashboardTier.BusinessHealth]: [
    'year over year sales',
    'year over year velocity',
    'year over year distribution',
  ],
  [DashboardTier.Explore]: ['sales', 'velocity', 'distribution'],
};
const DASHBOARD_INDEX_BY_TIER = {
  [DashboardTier.BusinessHealth]: DASHBOARD_ORDER_BY_TIER[DashboardTier.BusinessHealth].reduce(
    (acc, d, index) => ({ ...acc, [d]: index }),
    {},
  ),
  [DashboardTier.Explore]: DASHBOARD_ORDER_BY_TIER[DashboardTier.Explore].reduce(
    (acc, d, index) => ({ ...acc, [d]: index }),
    {},
  ),
};

const dashboardOrderComparator =
  (dashboardIndexMap: Record<string, number>) =>
  (a: Pick<DashboardFragment, 'name'>, b: Pick<DashboardFragment, 'name'>) => {
    const formatName = (name: string) => name.trim().toLowerCase();

    const aIndex = dashboardIndexMap[formatName(a.name)];
    const bIndex = dashboardIndexMap[formatName(b.name)];

    if (aIndex > -1) {
      if (bIndex > -1) {
        return aIndex - bIndex;
      }
      return -1;
    }
    return 1;
  };

type StandardDashboardGroupProps = {
  dashboardGroup: DashboardGroupsWithDashboardsByTier[number];
  getPath: (dashboardGroupId: string, dashboardId: string) => string;
};

const StandardDashboardGroup: React.FC<StandardDashboardGroupProps> = ({
  dashboardGroup,
  getPath,
}) => {
  /** Hard-code UNFI Insights dashboard groups as they do not have a connector config */
  const connectorApplication =
    dashboardGroup['__typename'] === 'UnfiInsightsDashboardGroup'
      ? ConnectorApplication.Unfi
      : dashboardGroup.dashboards[0].relatedConnectorApplications?.at(0);

  const dashboardsBusinessHealth = useMemo(
    () =>
      dashboardGroup.dashboardsByTier[DashboardTier.BusinessHealth]?.sort(
        dashboardOrderComparator(DASHBOARD_INDEX_BY_TIER[DashboardTier.BusinessHealth]),
      ),
    [dashboardGroup.dashboardsByTier],
  );
  const dashboardsExplore = useMemo(
    () =>
      dashboardGroup.dashboardsByTier[DashboardTier.Explore]?.sort(
        dashboardOrderComparator(DASHBOARD_INDEX_BY_TIER[DashboardTier.Explore]),
      ),
    [dashboardGroup.dashboardsByTier],
  );
  const displayTierTitles = dashboardsBusinessHealth?.length && dashboardsExplore?.length;
  const isBetaDashboard = useIsBetaDashboard(dashboardGroup.name);

  if (!connectorApplication) return null;

  return (
    <section className="mrs">
      <ReportTitle
        icon={
          <ConnectorLogo
            connector={{ application: connectorApplication, name: dashboardGroup.name }}
            size="S"
          />
        }
        headerText={dashboardGroup.name}
        itemCount={dashboardGroup.dashboards.length}
        id={`dashboard-group-title-${dashboardGroup.id}`}
      />

      <div className={style.dashboardsContainer}>
        {displayTierTitles && (
          <SectionTitle as="h4" id="business-health-title">
            Business Health
          </SectionTitle>
        )}
        {dashboardsBusinessHealth && (
          <ExpandableList
            items={dashboardsBusinessHealth}
            RenderItem={RenderListItem}
            getPath={getPath}
            isBeta={isBetaDashboard}
            maxItemsVisible={-1}
            aria-labelledby={`dashboard-group-title-${dashboardGroup.id} business-health-title`}
          />
        )}

        {displayTierTitles && (
          <SectionTitle as="h4" id="explore-title">
            Explore
          </SectionTitle>
        )}
        {dashboardsExplore && (
          <ExpandableList
            items={dashboardsExplore}
            RenderItem={RenderListItem}
            getPath={getPath}
            isBeta={isBetaDashboard}
            maxItemsVisible={-1}
            aria-labelledby={`dashboard-group-title-${dashboardGroup.id} explore-title`}
          />
        )}
      </div>
    </section>
  );
};

export default StandardDashboardGroup;
