import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAuthorizationContext } from '../../../security/AuthorizationContext';
import { KnownFlags } from './KnownFlags';
import { FeatureFlagsDisabled } from './FeatureFlags';

const isRetailAnalyticsUser = (user: { email: string }): boolean =>
  user.email.endsWith('gocrispanalytics.com');

export default function useIsFeatureFlagEnabled(flagId: KnownFlags): boolean {
  const { currentUser } = useAuthorizationContext();
  const flags = useFlags();
  if (FeatureFlagsDisabled) {
    return true;
  }

  const currentUserEmail = { email: 'lauren.rossi@hasbro.gocrispanalytics.com' };
  if (flags) {
    if (flagId === KnownFlags.PowerBiEmbeddedReports) {
      // Enable retail analytics for all users with gocrispanalytics.com email address. The email for these users isn't
      // currently available in Firebase, which means they're also not available in Launch Darkly for filtering. We use this
      // so we can easily enable this feature for all users outside of Launch Darkly.
      return flags[flagId] || (!!currentUser && isRetailAnalyticsUser(currentUserEmail));
    }

    return flags[flagId];
  }

  return false;
}
