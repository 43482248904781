import React from 'react';
import { Redirect, Route } from 'react-router';
import { useLocation, useParams } from 'react-router-dom';
import { compile as compilePath } from 'path-to-regexp';
import { encodeRouteParams } from './lib';

/**
 * Function which returns a "Redirect" react-router route.
 * The purpose of this utility is to trigger redirects in a very similar way to how
 * the <Redirect /> component does, but also include the search (query parameters)
 * in the destination URL.
 * It is for the reason of addressing this shortcoming of <Redirect /> that this function exists.
 * If the query parameters are not required in the redirection, then one may simply use:
 * <Redirect from={'/:accountId/some-path-segment'} to={'/:accountId/some-other-path-segment'} />
 * and the URL parameter would be transferred successfully, as long as the above is a direct child of
 * a react-router <Switch> component.
 *
 * Similarly to <Redirect />, all URL parameters required by the destination path should
 * be included in the fromPath.
 * @param fromPath
 * @param redirectTo
 * @constructor
 */
const RedirectRoute = (fromPath: string, redirectTo: string) => {
  const Component = () => {
    const { search, state } = useLocation();
    const params = useParams();
    const pathname = compilePath(redirectTo)(encodeRouteParams(params));
    return <Redirect to={{ pathname, search, state }} />;
  };
  return <Route key={fromPath} path={fromPath} component={Component} />;
};

export default RedirectRoute;
