import React from 'react';

import { FieldError } from 'react-hook-form';
import { Fieldset } from './Fieldset';
import { RadioIncompatibleWithUseForm } from './CheckboxAndRadio';
import { Radio } from './';

export type RadioSelectOption = { id: string; name: string; disabled?: boolean };

type RadioSelect = {
  heading?: string;
  description?: string;
  options: readonly RadioSelectOption[];
  loading: boolean;
  onChange?: (value: string) => void;
  value?: string;
  unselectedOptionClassName: string;
  selectedOptionClassName: string;
  column: boolean;
  name?: string;
  inputRef?: (value: HTMLInputElement) => void;
  errorMessage?: string | FieldError;
  RadioElement: typeof Radio | typeof RadioIncompatibleWithUseForm;
};

export function RadioSelect({
  heading,
  description,
  options,
  loading,
  onChange,
  value,
  unselectedOptionClassName,
  selectedOptionClassName,
  column,
  name,
  inputRef,
  errorMessage,
  RadioElement,
}: RadioSelect) {
  return (
    <Fieldset
      heading={heading}
      description={description}
      column={column}
      errorMessage={errorMessage}
    >
      {loading ? (
        <div>Loading</div>
      ) : (
        options.map(option => (
          <RadioElement
            checked={!inputRef ? value === option.id : undefined}
            disabled={option.disabled}
            key={option.id}
            label={option.name}
            name={name || option.id}
            onChange={isChecked => {
              if (isChecked && onChange) onChange(option.id);
            }}
            className={value === option.id ? selectedOptionClassName : unselectedOptionClassName}
            value={option.id}
            ref={inputRef}
          />
        ))
      )}
    </Fieldset>
  );
}

RadioSelect.defaultProps = {
  loading: false,
  unselectedOptionClassName: '',
  selectedOptionClassName: '',
  column: false,
  RadioElement: Radio,
};
