import { Maybe } from '@tellurian/ts-utils';
import { LocalStorage } from '@tellurian/ts-utils/dist/localStorage';
import { TableMetadataLabel } from '../../generated/graphql';
import { SourceTableCategory } from '../../components/SourceDataSelect/lib';
import { AnalyticsView } from '../../components/lettuce/crisp/navigation/AnalyticsMenu';
import { PersistentNavRailState } from '../../components/lettuce/components/NavRail/NavRailContextProvider';

export enum MemoryStorageKey {
  IsAcceptingUserInvitation = 'isAcceptingUserInvitation',
  DataCatalogLabelSelection = 'dataCatalogLabelSelection',
  DataCatalogRefreshFrequencySelection = 'dataCatalogRefreshFrequencies',
  DataCatalogDataTypeSelection = 'dataCatalogDataTypeSelection',
  DataCatalogItemsSelected = 'dataCatalogItemsSelected',
  DataCatalogAtLeastOneItemExpandedByUserBeforeFilterApplied = 'dataCatalogAtLeastOneItemExpandedByUserBeforeFilterApplied',
  IsTermsOfServiceNotificationVisible = 'isTermsOfServiceNotificationVisible',
  DeductionsSearchQuery = 'deductionsSearchQuery',
  TermsOfServiceVersionAcknowledged = 'termsOfServiceVersionAcknowledged',
  AnalyticsMenuCurrentView = 'analyticsMenuCurrentView',
  AnalyticsMenuSearchQuery = 'analyticsMenuSearchQuery',
  NavRailSubmenuIdToggle = 'navRailSubmenuToggle',
  NavRailState = 'navRailState',
  RetailAnalyticsSearchRefreshed = 'retailAnalyticsSearchRefreshed',
}

export type MemoryStorageSpec = {
  [MemoryStorageKey.DataCatalogLabelSelection]: TableMetadataLabel[];
  [MemoryStorageKey.DataCatalogRefreshFrequencySelection]: string[];
  [MemoryStorageKey.DataCatalogDataTypeSelection]: SourceTableCategory[];
  [MemoryStorageKey.DataCatalogItemsSelected]: Record<string, boolean>;
  [MemoryStorageKey.DataCatalogAtLeastOneItemExpandedByUserBeforeFilterApplied]: boolean;
  [MemoryStorageKey.IsTermsOfServiceNotificationVisible]: boolean;
  [MemoryStorageKey.DeductionsSearchQuery]: string;
  [MemoryStorageKey.TermsOfServiceVersionAcknowledged]: number;
  [MemoryStorageKey.AnalyticsMenuCurrentView]: AnalyticsView;
  [MemoryStorageKey.AnalyticsMenuSearchQuery]: string;
  [MemoryStorageKey.NavRailSubmenuIdToggle]: Maybe<string>;
  [MemoryStorageKey.NavRailState]: PersistentNavRailState;
  [MemoryStorageKey.RetailAnalyticsSearchRefreshed]: boolean;
  [MemoryStorageKey.IsAcceptingUserInvitation]: boolean;
};

/**
 * Provides a LocalStorage interface to an "in-memory" storage space (internally a Map).
 * This allows a level of persistence that is between a React component's state and that of
 * window local storage. This should be primarily used via the "usePersistentState" hook.
 */
export const MemoryStorage: LocalStorage<MemoryStorageSpec> = (() => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const store = new Map<MemoryStorageKey, any>();
  return {
    getItemOrDefault: <T extends MemoryStorageKey, P = MemoryStorageSpec[T]>(
      key: T,
      defaultReturn: P,
    ): MemoryStorageSpec[T] | P => {
      if (store.has(key)) {
        return store.get(key);
      }

      return defaultReturn;
    },
    getItem: <T extends MemoryStorageKey>(key: T): Maybe<MemoryStorageSpec[T]> => store.get(key),
    removeItem: (key: MemoryStorageKey) => store.delete(key),
    clear: (): boolean => {
      store.clear();
      return true;
    },
    setItem: <T extends MemoryStorageKey>(key: T, value: MemoryStorageSpec[T]): boolean => {
      store.set(key, value);
      return true;
    },
    findItem: <T extends MemoryStorageKey>(key: T) => {
      if (store.has(key)) {
        return { key, hasRecord: true, hasValue: true, value: store.get(key) };
      }
      return { key, hasRecord: false, hasValue: false };
    },
  };
})();
