import { MouseEvent, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { KeyHandlerEventProps } from '../../getListKeyHandler';
import { Key } from '../../../../../utils/keyCodes';
import { FocusParentProps } from './useFocusableItemIndex';

type ActionableItemIndexParams = {
  onToggle: (itemIndex: number) => void;
  isItemAtIndexActionable: (itemIndex: number) => boolean;
  isMouseDownDefaultPrevented?: boolean;
};

export type UseActionableItem = (index: number) => {
  isActionable: boolean;
  onMouseDown: (e: MouseEvent) => void;
};

type ActionableItemIndex = {
  setItemIndexActionable: (index: number, actionable: boolean) => void;
  useActionableItem: UseActionableItem;
};

const actionableItemIndex = ({
  onToggle,
  isItemAtIndexActionable,
  isMouseDownDefaultPrevented = false,
}: ActionableItemIndexParams): ActionableItemIndex => {
  const itemCallbackRegistry = new Map<number, (isActionable: boolean) => void>();

  const setItemIndexActionable = (index: number, actionable: boolean) => {
    itemCallbackRegistry.get(index)?.(actionable);
  };

  return {
    setItemIndexActionable,
    useActionableItem: (index: number) => {
      const [isActionable, setIsActionable] = useState(isItemAtIndexActionable(index));

      useEffect(() => {
        itemCallbackRegistry.set(index, setIsActionable);
        return () => {
          itemCallbackRegistry.delete(index);
        };
      }, [index]);

      const onMouseDown = useCallback(
        (e: MouseEvent) => {
          // Reason: prevent focus lost (from focusParent) on mouse down; focusParent is responsible
          // for blurring other elements in response to the "focus" prop supplied by this hook.
          isMouseDownDefaultPrevented && e.preventDefault();
          if (isActionable) {
            onToggle(index);
          }
        },
        [index, isActionable],
      );
      return { onMouseDown, isActionable };
    },
  };
};

export type UseActionableItemIndexParams = {
  onToggle: (itemIndex: number) => void;
  onToggleFocused?: () => void;
  isItemAtIndexActionable?: (itemIndex: number) => boolean;
  isMouseDownDefaultPrevented?: boolean;
  toggleOnKeys?: Key[];
};

type UseActionableItemIndex = ActionableItemIndex & {
  getParentFocusProps: () => FocusParentProps;
};

const useActionableItemIndex = ({
  onToggle,
  onToggleFocused,
  isItemAtIndexActionable = () => true,
  toggleOnKeys = [Key.Enter, Key.Space],
  isMouseDownDefaultPrevented,
}: UseActionableItemIndexParams): UseActionableItemIndex => {
  const onToggleRef = useRef(onToggle);
  const isItemAtIndexActionableRef = useRef(isItemAtIndexActionable);
  const actionable = useRef(
    actionableItemIndex({
      onToggle: index => onToggleRef.current(index),
      isItemAtIndexActionable: index => isItemAtIndexActionableRef.current(index),
      isMouseDownDefaultPrevented,
    }),
  ).current;

  useLayoutEffect(() => {
    onToggleRef.current = onToggle;
  }, [onToggle]);

  useLayoutEffect(() => {
    isItemAtIndexActionableRef.current = isItemAtIndexActionable;
  }, [isItemAtIndexActionable]);

  const getParentFocusProps = useCallback(() => {
    return onToggleFocused
      ? {
          onKeyDown: (e: KeyHandlerEventProps) => {
            if (toggleOnKeys.includes(e.key as Key)) {
              onToggleFocused();
              e.preventDefault();
              return true;
            }

            return false;
          },
        }
      : {};
  }, [onToggleFocused, toggleOnKeys]);

  return { getParentFocusProps, ...actionable };
};

export default useActionableItemIndex;
