import React from 'react';
import { Maybe } from '@tellurian/ts-utils';
import { ReactComponent as HarmonizedIcon } from '../../../../../../assets/icons/lettuce/harmonized.svg';
import { ConnectorApplication } from '../../../../../../generated/graphql';
import ReportTitle from '../ReportTitle';
import ExpandableList from '../../../../components/ExpandableList';
import ListItemLink, { LinkItem } from '../ListItemLink';
import { FavoriteReport } from '../../../../components/FavoriteReportsContext';
import {
  DashboardForMenu,
  DashboardGroupsWithDashboardsByTier,
} from '../AllReports/useDashboardGroupsWithDashboardsByTier';
import useIsBetaDashboard from './useIsBetaDashboard';
import listStyle from '../ReportList.module.css';

export type HarmonizedDashboardGroupProps = {
  dashboardGroup: DashboardGroupsWithDashboardsByTier[number];
  getConnectorName: (application: ConnectorApplication) => Maybe<string>;
  getPath: (dashboardGroupId: string, dashboardId: string) => string;
  isReportFavorite: (favoriteReport: FavoriteReport) => boolean;
  addFavoriteReport: (favoriteReport: FavoriteReport) => void;
  removeFavoriteReport: (favoriteReport: FavoriteReport) => void;
};

const HarmonizedDashboardGroup: React.FC<HarmonizedDashboardGroupProps> = ({
  dashboardGroup,
  getConnectorName,
  getPath,
  isReportFavorite,
  addFavoriteReport,
  removeFavoriteReport,
}) => {
  const isBetaDashboard = useIsBetaDashboard(dashboardGroup.name);
  const getLabels = (item: DashboardForMenu) =>
    item.relatedConnectorApplications
      ?.map(c => getConnectorName(c))
      .filter((c): c is string => !!c) ?? [];

  return (
    <section className="mrs">
      <ReportTitle
        icon={<HarmonizedIcon />}
        headerText="Harmonized"
        itemCount={dashboardGroup.dashboards.length}
        id="harmonized-views-title"
      />
      <ExpandableList
        aria-labelledby="harmonized-views-title"
        className={listStyle.list}
        RenderItem={ListItemLink}
        items={dashboardGroup.dashboards.map((item): LinkItem => {
          const favoriteReport = {
            dashboardGroupId: item.dashboardGroupId,
            dashboardId: item.id,
          };
          const isFavorite = isReportFavorite(favoriteReport);
          return {
            id: item.id,
            name: item.name,
            path: getPath(item.dashboardGroupId, item.id),
            labels: getLabels(item),
            labelDescription: 'Connectors',
            isBeta: isBetaDashboard(item),
            actions: [
              {
                label: isFavorite ? 'Unpin' : 'Pin',
                onToggle: () => {
                  if (isFavorite) {
                    removeFavoriteReport(favoriteReport);
                  } else {
                    addFavoriteReport(favoriteReport);
                  }
                },
              },
            ],
          };
        })}
      />
    </section>
  );
};

export default HarmonizedDashboardGroup;
