import React from 'react';
import ScrollToTop from '../../../Utils/ScrollToTop';
import ConnectorConfigurations from '../ConnectorConfigurations';
import ConnectorsTemplate from '../templates/ConnectorsTemplate';
import { lazyWithRetry } from '../../../lib';
import { AccountIdMatchParams } from './lib';

const RenderConnectorConfigurations = lazyWithRetry<typeof ConnectorConfigurations>(
  () => import(/* webpackChunkName: "connectorConfigurations" */ '../ConnectorConfigurations'),
);

const ConnectorConfigurationsPage: React.FC<AccountIdMatchParams> = ({
  match: {
    params: { accountId },
  },
}) => {
  return (
    <ConnectorsTemplate size="L">
      <ScrollToTop />
      <RenderConnectorConfigurations accountId={accountId} />
    </ConnectorsTemplate>
  );
};

export default ConnectorConfigurationsPage;
