import { useEffect } from 'react';
import { Maybe, range } from '@tellurian/ts-utils';
import useEventCallback from '../utils/useEventCallback';

export enum CustomEventType {
  MenuOpen = 'menuOpen',
  MenuClose = 'menuClose',
}

export const useCustomEventListener = (
  eventType: CustomEventType,
  listener: (ev: Event) => void,
  enabled = true,
) => {
  const callback = useEventCallback(listener);
  useEffect(() => {
    if (enabled) {
      window.addEventListener(eventType, callback);
      return () => window.removeEventListener(eventType, callback);
    }
  }, [callback, eventType, enabled]);
};

export const useMenuEventListener = (menuId: Maybe<string>, setOpen: (open: boolean) => void) => {
  const predicate = (ev: Event) => ev instanceof CustomEvent && ev.detail['menuId'] === menuId;
  useCustomEventListener(
    CustomEventType.MenuOpen,
    ev => {
      predicate(ev) && setOpen(true);
    },
    !!menuId,
  );
  useCustomEventListener(
    CustomEventType.MenuClose,
    ev => predicate(ev) && setOpen(false),
    !!menuId,
  );
};

export const nextRandomInt =
  (random: () => number) =>
  (upperBound: number, lowerBound = 0) =>
    range(lowerBound, upperBound).random(random);
