import React, { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { noop } from '@tellurian/ts-utils';
import { NotificationItem, NotificationState } from '../lib/notifications/lib';
import Notification from '../Notification';
import style from './index.module.css';
import animationStyles from './animations.module.css'; // eslint-disable-line css-modules/no-unused-class

export const NOTIFICATION_ANIMATION_DURATION = 300; // ms

type ToastNotificationProps = Omit<NotificationItem, 'id'>;

const ToastNotification: React.FC<ToastNotificationProps> = ({
  content,
  state,
  notificationType,
  isAutoDismissed = false,
  onDismiss,
  onShowAnimationComplete,
  onDismissAnimationComplete,
  title,
}) => {
  onDismiss = onDismiss && (state === NotificationState.Showing ? onDismiss : noop);
  const ref = useRef(null);
  return (
    <CSSTransition
      appear={true}
      in={state === NotificationState.AnimatingShow || state === NotificationState.Showing}
      timeout={NOTIFICATION_ANIMATION_DURATION}
      onEntered={onShowAnimationComplete}
      onExited={onDismissAnimationComplete}
      classNames={{ ...animationStyles }}
      nodeRef={ref}
    >
      <Notification
        notificationType={notificationType}
        content={content}
        title={title}
        onDismiss={onDismiss}
        isAutoDismissed={isAutoDismissed}
        ref={ref}
        className={style.toast}
        direction="row"
      />
    </CSSTransition>
  );
};

export default ToastNotification;
