import { ReactComponent as IconClear } from '../../../../assets/icons/lettuce/clear.svg';
import { ReactComponent as IconEdit } from '../../../../assets/icons/lettuce/edit.svg';
import { EntityListItemAction } from './index';

export const removeAction = <T>(
  onToggle: EntityListItemAction<T>['onToggle'],
): EntityListItemAction<T> => ({
  label: 'Remove',
  Icon: IconClear,
  onToggle,
});

export const editAction = <T>(
  onToggle: EntityListItemAction<T>['onToggle'],
): EntityListItemAction<T> => ({
  label: 'Edit',
  Icon: IconEdit,
  onToggle,
});
