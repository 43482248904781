import { useCallback } from 'react';
import { ConnectorApplication } from '../../../../generated/graphql';
import { PartnerAccount, PartnerType } from '../../components/lib/Account';
import { BiPageConfig, getSearchParamsForConfig } from '../../components/BiReport/lib';
import { path } from '../../crisp/routing/lib';

export type PartnerAccountIdParams = Pick<PartnerAccount, 'partnerAccountId'>;
export type PartnerTypeParams = Pick<PartnerAccount, 'partnerType'>;

export type PartnerAccountProps = {
  partnerAccount: PartnerAccount;
};

export const PartnerTypeToConnectorApplication: Partial<Record<PartnerType, ConnectorApplication>> =
  {
    [PartnerType.Dot]: ConnectorApplication.Dot,
  };

export const useGetBiPageUrl = (partnerType: PartnerType) => {
  return useCallback(
    (pageConfig: BiPageConfig) =>
      `${path('PartnerAnalytics')({ partnerType })}?${getSearchParamsForConfig(pageConfig)}`,
    [partnerType],
  );
};
