import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { FeatureProps, invokesFeature, trackFeature } from '../../utils/features';
import { Button, ButtonSize, ButtonStyle, ButtonType } from './Button';
import styles from './ActionButtons.module.css';

export type ActionButtonsProps = {
  onCancel?: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
  isSaving?: boolean;
  onSave: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
  saveDisabled?: boolean;
  size?: ButtonSize;
  alignLeft?: boolean;
  cancelText?: string;
  saveText?: string;
  savingText?: string;
  children?: ReactNode;
  saveButtonType?: ButtonType;
} & FeatureProps;

export const ActionButtons: React.FC<ActionButtonsProps> = ({
  isSaving = false,
  onCancel,
  onSave,
  saveDisabled = false,
  size = ButtonSize.L,
  alignLeft,
  cancelText = 'Cancel',
  saveText = 'Save',
  savingText = 'Saving...',
  children,
  featureId,
  saveButtonType = ButtonType.Submit,
}) => {
  return (
    <div className={clsx(styles.buttonGrid, alignLeft ? styles.alignLeft : styles.alignRight)}>
      {onCancel && (
        <Button
          btnStyle={ButtonStyle.LINK}
          btnSize={size}
          onClick={onCancel}
          testId={'button-modal-cancel'}
        >
          {cancelText}
        </Button>
      )}
      <Button
        btnStyle={ButtonStyle.PRIMARY}
        btnSize={size}
        btnType={saveButtonType}
        disabled={isSaving || saveDisabled}
        onClick={e => {
          featureId && trackFeature(featureId);
          onSave(e);
        }}
        testId={'button-modal-submit'}
        {...invokesFeature(featureId)}
      >
        {children ? children : isSaving ? savingText : saveText}
      </Button>
    </div>
  );
};
