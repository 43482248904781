import React, { SVGProps, useState } from 'react';
import clsx from 'clsx';
import { ReactComponent as IconEye } from '../../../../assets/icons/eye.svg';
import useInitialFocus, { UseInitialFocusParams } from '../useInitialFocus';
import BasicInput, { BasicInputProps } from './BasicInput';
import styles from './index.module.css';

type RenderInputProps = BasicInputProps &
  UseInitialFocusParams & {
    hasError?: boolean;
  };

const DefaultRenderInput: React.FC<RenderInputProps> = ({
  className,
  hasError,
  isFocused,
  scrollIntoViewIfFocused,
  ...rest
}) => {
  return (
    <BasicInput
      ref={useInitialFocus<HTMLInputElement>({ isFocused, scrollIntoViewIfFocused })}
      className={clsx(styles.renderInput, { [styles.hasError]: hasError }, className)}
      {...rest}
    />
  );
};

/**
 * All (non-read-only) input components (i.e. not just "input" or "Input") should accept these parameters.
 */
export type InputPrimaryProps<T, Q = T> = {
  value: Q;
  onChange?: (nextValue: T) => void;
};

export type AcceptedInputTypes = 'text' | 'password' | 'email' | 'url' | 'number';

export type InputProps = InputPrimaryProps<string> & {
  type?: AcceptedInputTypes;
  className?: string;
  RenderInput?: React.FC<RenderInputProps>;
} & Omit<RenderInputProps, 'type' | 'onChange' | 'className' | 'value'>;

/**
 * An Input is a Crisp styled "input" component with an onChange handler whose value parameter is a string
 * instead of the original event.
 * This can be customised by either providing a `className` prop or `RenderInput` to substitute the
 * inner "input" element completely (e.g. include a prefix/suffix to the field).
 * @param type
 * @param value
 * @param onChange
 * @param className
 * @param RenderInput
 * @param renderInputProps
 * @constructor
 */
export const Input: React.FC<InputProps> = ({
  type = 'text',
  value,
  onChange,
  className,
  RenderInput = DefaultRenderInput,
  ...renderInputProps
}) => {
  return (
    <div className={clsx(styles.input, className)}>
      <RenderInput
        type={type}
        {...renderInputProps}
        value={value}
        onChange={e => onChange?.(e.target.value)}
      />
    </div>
  );
};

type IconButtonInputProps = InputProps & {
  Icon: React.FunctionComponent<SVGProps<SVGSVGElement>>;
  onButtonClick: () => void;
  buttonTitle?: string;
  isButtonToggled?: boolean;
};

export const IconButtonInput: React.FC<IconButtonInputProps> = ({
  type = 'text',
  value,
  onChange,
  className,
  RenderInput = DefaultRenderInput,
  onButtonClick,
  buttonTitle,
  isButtonToggled,
  Icon,
  ...renderInputProps
}) => {
  return (
    <div className={clsx(styles.input, className)}>
      <RenderInput
        type={type}
        {...renderInputProps}
        value={value}
        onChange={e => onChange?.(e.target.value)}
        className={styles.paddingForIcon}
      />
      <button
        title={buttonTitle}
        className={clsx(styles.iconButton, { [styles.toggled]: isButtonToggled })}
        onClick={onButtonClick}
      >
        <Icon />
      </button>
    </div>
  );
};

export const PasswordInput: React.FC<Omit<InputProps, 'type'>> = props => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <IconButtonInput
      {...props}
      type={isVisible ? 'text' : 'password'}
      Icon={IconEye}
      onButtonClick={() => setIsVisible(current => !current)}
      buttonTitle={isVisible ? 'Show password' : 'Hide password'}
      isButtonToggled={isVisible}
    />
  );
};

export * from './CheckboxInput';
