import React from 'react';
import { FCC } from '../../../../../../utils/types';
import { TitleProps } from '../../../../components/Title';
import style from './SectionTitle.module.css';

type SectionTitleProps = Pick<TitleProps, 'as' | 'id'>;

const SectionTitle: FCC<SectionTitleProps> = ({ as: Component = 'h3', id, children }) => {
  return (
    <Component id={id} className={style.sectionTitle}>
      {children}
    </Component>
  );
};

export default SectionTitle;
