import { datadogRum } from '@datadog/browser-rum';
import CrispApp from '../components/lettuce/crisp/CrispApp';
import logger from '../services/logger';

const isEventConsoleLogEnabled = process.env.REACT_APP_DISABLE_EVENT_LOG !== 'true';

const getUnfiInsightsCtx = () => {
  const firebaseUser = CrispApp.unfiInsightsEmbedded.getFirebaseUser();
  const azureUser = CrispApp.unfiInsightsEmbedded.getAzureUser();
  return {
    firebaseUser,
    azureUser,
  };
};

/**
 * Use this function to manually track errors in _exceptional circumstances_. Note that all
 * uncaught exceptions are already logged by datadog RUM, so this should only be used
 * when we wish to provide additional details to clarify the reason this error was triggered
 * and hint at its possible solutions.
 * @param error either the original error (Error) thrown upstream or custom error message
 * @param details arbitrary object with details which help debug the issue (note, accountId is automatically added)
 */
export const trackError = (error: Error | string, details?: string | Record<string, unknown>) => {
  const accountId = CrispApp.getAccountId();

  const base = { accountId, ...getUnfiInsightsCtx() };
  const ctx = typeof details === 'string' ? { message: details, ...base } : { ...base, ...details };
  if (process.env.REACT_APP_DATADOG_ENABLE === 'true') {
    datadogRum.addError(error, ctx);
    // @TODO remove this once we are confident that standard error logging includes all details in ctx
    if (ctx.azureUser) {
      logger.error(typeof error === 'string' ? error : error.message, ctx);
    }
  } else if (isEventConsoleLogEnabled) {
    console.error(error, ctx);
  }
};
