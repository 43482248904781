import React, { SVGProps } from 'react';
import clsx from 'clsx';
import { FCC } from '../../../../../utils/types';
import style from './index.module.css'; // eslint-disable-line css-modules/no-unused-class

export const ConnectorLogoSizes = ['S', 'M', 'L'] as const;
export type ConnectorLogoSize = (typeof ConnectorLogoSizes)[number];

export type LogoContainerProps = {
  size?: ConnectorLogoSize;
};

export const LogoContainer: FCC<LogoContainerProps> = ({ children, size = 'M' }) => {
  return <div className={clsx(style.container, style[size])}>{children}</div>;
};

export const IconLogo: React.FC<{ Icon: React.FC<SVGProps<SVGSVGElement>> }> = ({ Icon }) => {
  return (
    <LogoContainer>
      <Icon />
    </LogoContainer>
  );
};
