import React, { ReactNode } from 'react';
import { Modal, ModalProps } from '../index';
import { ModalSize } from '../lib';
import { FCC } from '../../../../utils/types';
import style from './index.module.css';

export type FullScreenModalProps = {
  header?: ReactNode;
  footer?: ReactNode;
} & Pick<ModalProps, 'onCloseClick' | 'ariaLabelledby' | 'ariaDescribedby'>;

const FullScreenModal: FCC<FullScreenModalProps> = ({ children, footer, header, ...rest }) => {
  return (
    <Modal size={ModalSize.FullScreen} className={style.modal} {...rest}>
      <div className={style.stretchContainer}>
        <div className={style.body}>
          {header && <div className={style.header}>{header}</div>}
          <div className={style.mainContent}>{children}</div>
          {footer && <div className={style.footer}>{footer}</div>}
        </div>
      </div>
    </Modal>
  );
};

export default FullScreenModal;
