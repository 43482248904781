import React from 'react';
import { AnyObject } from '@tellurian/ts-utils';
import _ from 'lodash';
import clsx from 'clsx';
import { ActionMenuItemSpec } from '../Menu/lib';
import ThreeDotMenu from '../ThreeDotMenu';
import ContextButton, { ContextButtonProps } from '../lib/ContextButton';
import style from './index.module.css';

export type EntityListItemAction<T> = Pick<ActionMenuItemSpec, 'label' | 'Icon'> & {
  onToggle: (item: T, index: number) => void;
};

type RenderItemContentBaseProps<T, P extends AnyObject> = { item: T; index: number } & P;

export type EntityListProps<T, P extends AnyObject = AnyObject> = P & {
  items: T[];
  RenderItemContent: React.FC<RenderItemContentBaseProps<T, P>>;
  actions?: EntityListItemAction<T>[] | ((item: T, index: number) => EntityListItemAction<T>[]);
  contextButtonVariant?: ContextButtonProps['variant'];
  noTopBorder?: boolean;
  noBottomBorder?: boolean;
  className?: string;
} & Pick<
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLUListElement>, HTMLUListElement>,
    'aria-label' | 'aria-labelledby'
  >;

/**
 * The GenericEntityList allows a custom item content renderer. Use the EntityList
 * which includes a standard renderer suitable for most use cases.
 */
function EntityList<T, P extends AnyObject = AnyObject>({
  items,
  RenderItemContent,
  actions = [],
  'aria-label': ariaLabel,
  'aria-labelledby': ariaLabelledBy,
  contextButtonVariant = 'neutral',
  className,
  noBottomBorder = false,
  noTopBorder = false,
  ...renderItemContentProps
}: EntityListProps<T, P>) {
  return (
    <ul
      className={clsx(
        style.list,
        { [style.noTopBorder]: noTopBorder, [style.noBottomBorder]: noBottomBorder },
        className,
      )}
      aria-labelledby={ariaLabelledBy}
      aria-label={ariaLabel}
    >
      {items.map((item, index) => {
        const actionsToUse: ActionMenuItemSpec[] = (
          Array.isArray(actions) ? actions : actions(item, index)
        ).map(a => ({
          ...a,
          onToggle: () => a.onToggle(item, index),
        }));

        return (
          <li key={index}>
            <div className={style.listItemContent}>
              <RenderItemContent
                item={item}
                index={index}
                {...(renderItemContentProps as unknown as P)}
              />
              {actionsToUse.length > 0 && (
                <div className={style.actionsContainer}>
                  {actionsToUse.length === 1 && actionsToUse[0].Icon ? (
                    <ContextButton
                      {..._.omit(actionsToUse[0] as ContextButtonProps, 'label')}
                      aria-label={actionsToUse[0].label}
                      variant={contextButtonVariant}
                    />
                  ) : (
                    <ThreeDotMenu items={actionsToUse} variant={contextButtonVariant} />
                  )}
                </div>
              )}
            </div>
          </li>
        );
      })}
    </ul>
  );
}

export default EntityList;
