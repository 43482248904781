import { useMemo } from 'react';
import _ from 'lodash';
import { DashboardPermission } from '../../../../generated/graphql';
import { useGetDashboardGroupsForPermissions } from '../../../businessDashboard/hooks/useGetDashboardGroups';
import { ALL_DASHBOARDS_ID } from './DashboardPermissionsSelection';

export function useGetDefaultDashboardPermissions(
  accountId: string,
  existingPermissions?: Omit<DashboardPermission, 'accountId'>[],
  fillValue?: boolean,
) {
  const { loading, dashboardGroups } = useGetDashboardGroupsForPermissions(accountId);

  const defaultDashboardPermissions = useMemo(() => {
    const defaultDashboardPermissionsObject = {};
    // If "all dashboards" permission is set, we pre-select it
    defaultDashboardPermissionsObject[ALL_DASHBOARDS_ID] = {
      [ALL_DASHBOARDS_ID]: {
        [ALL_DASHBOARDS_ID]:
          existingPermissions &&
          _.some(existingPermissions, {
            dashboardGroupId: '*',
            dashboardId: '*',
            connectorConfigurationId: '*',
          }),
      },
    };
    dashboardGroups.forEach(dashboardGroup => {
      defaultDashboardPermissionsObject[dashboardGroup.id] = {};
      dashboardGroup.dashboards.forEach(dashboard => {
        defaultDashboardPermissionsObject[dashboardGroup.id][dashboard.id] = {};
        dashboard.relatedConnectorConfigurations?.forEach(connectorConfig => {
          defaultDashboardPermissionsObject[dashboardGroup.id][dashboard.id][connectorConfig.id] =
            existingPermissions
              ? _.some(existingPermissions, {
                  dashboardGroupId: dashboardGroup.id,
                  dashboardId: dashboard.id,
                  connectorConfigurationId: connectorConfig.id,
                })
              : !!fillValue;
        });
      });
    });

    return defaultDashboardPermissionsObject;
  }, [dashboardGroups, existingPermissions, fillValue]);

  return {
    loading,
    defaultDashboardPermissions,
  };
}
