import React from 'react';
import clsx from 'clsx';
import { Col, Grid, GridType } from '../';
import { PageLayoutProvider, usePageLayoutContext } from './Context';
import styles from './index.module.css';

type StyleProps = {
  className?: string;
  style?: React.CSSProperties;
};

export function Layout({
  children,
  type = GridType.M,
  withoutSidebars = false,
  testId,
  ...rest
}: {
  children: React.ReactNode;
  type?: GridType;
  withoutSidebars?: boolean;
  testId?: string;
} & StyleProps) {
  return (
    <PageLayoutProvider type={type} withoutSidebars={withoutSidebars}>
      <div className="mvm" data-testid={testId} {...rest}>
        {children}
      </div>
    </PageLayoutProvider>
  );
}

function Header({
  children,
  className,
  description,
  heading,
  RenderSideAction,
  style,
  subHeading,
  condensed = true,
}: {
  children?: React.ReactNode;
  description?: string;
  heading?: string;
  RenderSideAction?: React.ReactNode;
  subHeading?: string;
  condensed?: boolean;
} & StyleProps) {
  const { type, withoutSidebars } = usePageLayoutContext();

  return (
    <div className={clsx(styles.header, className)} style={style}>
      <Grid type={type}>
        <Col flex={2}>
          <Grid>
            <Col>
              {subHeading && (
                <h3
                  className={clsx(styles.subHeading, {
                    mbn: !!heading || !!description,
                    [styles.subHeadingWithSideAction]: !!RenderSideAction,
                  })}
                >
                  {subHeading}
                </h3>
              )}
              {heading && <h1 className={clsx('mtn', { mbn: !!description })}>{heading}</h1>}
              {description && <p className={clsx({ mtn: condensed })}>{description}</p>}
            </Col>
            {RenderSideAction && <div className="mhm">{RenderSideAction}</div>}
          </Grid>
        </Col>
        {!withoutSidebars && <Col flex={1} />}
      </Grid>
      {children}
    </div>
  );
}

function Content({
  children,
  RenderSideContent,
  sideContentPosition = 'right',
  verticalAlign,
  ...rest
}: {
  children?: React.ReactNode;
  RenderSideContent?: React.ReactNode;
  sideContentPosition?: 'left' | 'right';
  verticalAlign?: 'flex-end' | 'flex-start' | 'center';
} & StyleProps) {
  const { type, withoutSidebars } = usePageLayoutContext();

  return (
    <div {...rest}>
      {withoutSidebars ? (
        <div>{children}</div>
      ) : (
        <Grid
          type={type}
          style={{
            flexDirection: sideContentPosition === 'left' ? 'row-reverse' : 'row',
            alignItems: verticalAlign ?? undefined,
          }}
        >
          <Col flex={2}>{children}</Col>
          {!withoutSidebars && <Col flex={1}>{RenderSideContent}</Col>}
        </Grid>
      )}
    </div>
  );
}

export const PageLayout = {
  Content,
  Header,
  Layout,
};
