import React from 'react';
import { StandardViewsProps } from '../StandardViews';
import View from './View';

type SearchReportsProps = {
  accountId: string;
  searchQuery: string;
} & Pick<
  StandardViewsProps,
  'dashboardGroups' | 'dashboardGroupsLoading' | 'getConnectorName' | 'getPath'
>;

const SearchReports: React.FC<SearchReportsProps> = props => {
  return <View {...props} />;
};

export default SearchReports;
