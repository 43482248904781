import React, { Suspense, useMemo } from 'react';
import { Route, Switch } from 'react-router';
import Spinner from '../Spinner';
import NotFound from './NotFound';
import NotFoundInAccount from './NotFoundInAccount';
import RedirectRoute from './RedirectRoute';
import RenderRoute from './RenderRoute';
import { RedirectMap, RouteDeclaration, RouteOptions } from './lib';

// This must be a used as a function call and not a component because `<Switch>` will not handle React fragments.
const RenderRedirects = (redirects: RedirectMap) =>
  Object.entries(redirects).map(([fromPath, redirectTo]) => RedirectRoute(fromPath, redirectTo));

type RouteSwitchParams = {
  routes: RouteDeclaration[];
  redirects?: RedirectMap;
  defaultRouteOptions?: RouteOptions;
};

const RouteSwitch: React.FC<RouteSwitchParams> = ({ routes, redirects, defaultRouteOptions }) => {
  const routesToUse = useMemo(
    () => routes.map(r => ({ ...defaultRouteOptions, ...r })),
    [routes, defaultRouteOptions],
  );

  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        {redirects && RenderRedirects(redirects)}
        {routesToUse.map(route => {
          return (
            <Route
              key={route.id}
              exact={true}
              path={route.path}
              render={params => <RenderRoute route={route} {...params} />}
            />
          );
        })}
        <Route exact path={'/:accountId/*'} component={NotFoundInAccount} />
        <Route exact path={'*'} component={NotFound} />
      </Switch>
    </Suspense>
  );
};

export default RouteSwitch;
