export enum KnownFlags {
  RemaConnect = 'remaConnect',
  EnableRetailerUnifiedVoidsDashboard = 'enableRetailerUnifiedVoidsDashboard',
  EnableRetailerUnifiedOpportunitiesDashboard = 'enableRetailerUnifiedOpportunitiesDashboard',
  InboundDataStatus = 'inboundDataStatus',
  EnableAmazonGroup = 'enableAmazonGroup',
  EnableUnifiedSalesV2Dashboards = 'unifiedSalesV2',
  BetaDashboardBadges = 'betaDashboardBadges',
  EnableDistributorVoidsDashboard = 'enableDistributorVoidsDashboard',
  EnableRetailerWasteDashboard = 'enableRetailerWasteDashboard',
  Deductions = 'deductions',
  Lettuce = 'lettuce',
  ScheduledReports = 'scheduledReports',
  PipelineObservability = 'pipelineObservability',
  ReportBuilder = 'reportBuilderIb',
  MdmPlusPlus = 'mdm',
  PowerBiEmbeddedReports = 'powerBiEmbeddedReports',
  DataDictionary = 'dataDictionary',
  LuminateBasicDataPresence = 'luminateBasicDataPresence',
  ReportFilters = 'reportFilters',
  DotMiniSite = 'dotMiniSite',
  NotificationsTile = 'notificationsTile',
}
