import { AuthError as AzureAuthError } from '@azure/msal-common/dist/error/AuthError';
import { AuthError as FirebaseAuthError, UserInfo as FirebaseUserInfo } from '@firebase/auth';
import firebase from 'firebase/compat/app';
import { FirebaseError } from '@firebase/util';
import { OAuthError as OktaAuthError, OAuthResponseType } from '@okta/okta-auth-js';
import { sha256 } from 'js-sha256';
import { Maybe } from '@tellurian/ts-utils';
import { AuthProvider } from '../../../../generated/graphql';

const NONCE_LENGTH = 15;

type FirebaseUser = firebase.User;

export type AuthInstanceProps = {
  authProvider: AuthProvider;
  children: React.ReactNode;
};

export type OktaConfig = {
  issuer: string;
  clientId: string;
  redirectUri: string;
  responseType: OAuthResponseType | OAuthResponseType[] | 'none' | undefined;
  pkce: boolean;
};

export type AuthError = {
  origin: string;
  code: string;
  message: string;
};

export type Nonce = {
  nonce: string;
  rawNonce: string;
};

export enum AuthState {
  Authenticated = 'authenticated',
  Loading = 'loading',
  LoginRequired = 'login_required',
  SignedInWithAnotherUser = 'signed_in_with_another_user',
  UserAlreadyExists = 'user_already_exists',
  Failed = 'failed',
  FirebaseProviderUserMismatch = 'firebase_provider_user_mismatch',
}

const genString = (): string => {
  let result = '';
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charsLength = chars.length;
  for (let i = 0; i < NONCE_LENGTH; i++) {
    result += chars.charAt(Math.floor(Math.random() * charsLength));
  }
  return result;
};

export const generateNonce = (): Nonce => {
  const rawNonce = genString();
  const hash = sha256(rawNonce);

  return {
    nonce: hash,
    rawNonce: rawNonce,
  };
};

export const toAuthError = (
  error: AzureAuthError | FirebaseAuthError | OktaAuthError,
): AuthError | undefined => {
  if (error instanceof AzureAuthError)
    return {
      origin: 'azure',
      code: error.errorCode,
      message: error.errorMessage,
    };
  else if (error instanceof FirebaseError)
    return {
      origin: 'firebase',
      code: error.code,
      message: error.message,
    };
  else
    return {
      origin: 'okta',
      code: error.errorCode,
      message: error.message,
    };
};

export const providerInfo = (user: FirebaseUser, providerId: string): Maybe<FirebaseUserInfo> =>
  user?.providerData?.find(userInfo => userInfo?.providerId === providerId) || undefined;

type isLinkedProps = {
  providerId: string;
  sub: string;
  firebaseUser: FirebaseUser;
};

export const isLinked = ({ providerId, sub, firebaseUser }: isLinkedProps): boolean => {
  const info = providerInfo(firebaseUser, providerId);
  return (info && info.uid === sub) || false;
};
