import React, { ReactNode } from 'react';
import clsx from 'clsx';
import style from './index.module.css'; // eslint-disable-line

export type NavRailProps = {
  alignChildren?: 'space-between' | 'center';
  children: ReactNode;
  isCompact?: boolean;
  className?: string;
};

const NavRail = React.forwardRef<HTMLElement, NavRailProps>(function NavRail(
  { alignChildren = 'center', className, isCompact = false, children },
  ref,
) {
  return (
    <nav
      ref={ref}
      className={clsx(style.body, { [style.compact]: isCompact }, style[alignChildren], className)}
      aria-label="Main menu"
    >
      {children}
    </nav>
  );
});

export default NavRail;
