import React from 'react';
import ConfirmCancelActionButtons, {
  ConfirmCancelActionButtonsProps,
} from './ConfirmCancelActionButtons';

export type SaveCancelActionButtonsProps = {
  onSave: () => void;
  isSaving?: boolean;
  isSaveDisabled?: boolean;
} & Pick<ConfirmCancelActionButtonsProps, 'onCancel' | 'isCancelDisabled'>;

/**
 * Convenience component for rendering a save/cancel action button pair. This is based
 * on the more generic ActionButtons component. If different configurations are required,
 * it is preferable to extend ActionButtons instead of this component.
 */
const SaveCancelActionButtons: React.FC<SaveCancelActionButtonsProps> = ({
  onSave,
  isSaveDisabled,
  isSaving,
  ...rest
}) => {
  return (
    <ConfirmCancelActionButtons
      onConfirm={onSave}
      isConfirming={isSaving}
      isConfirmDisabled={isSaveDisabled}
      {...rest}
    />
  );
};

export default SaveCancelActionButtons;
