import React, { ReactNode, useEffect, useRef, useState } from 'react';
import Tether, { Placement, TetherProps } from '../Tether';
import { Optional } from '../../lib';
import { onMouseEnter, onMouseLeave } from './lib';
import styles from './index.module.css';

type BasicTooltipProps = Omit<TetherProps, 'arrowEnabled' | 'arrowClassName' | 'popperClassName'>;

export function TooltipContent({ children }) {
  return <div className={styles.tooltipContent}>{children}</div>;
}

function BasicTooltip({ children, content, ...rest }: BasicTooltipProps) {
  const tooltipContent =
    typeof content === 'function' ? (
      () => <div className={styles.tooltipContent}>{(content as () => ReactNode)()}</div>
    ) : (
      <div className={styles.tooltipContent}>{content}</div>
    );

  return (
    <Tether
      arrowEnabled={true}
      arrowClassName={styles.arrow}
      className={styles.popper}
      content={tooltipContent}
      parentNode={null}
      role="status"
      {...rest}
    >
      {children}
    </Tether>
  );
}

export enum Trigger {
  NONE,
  HOVER,
  CLICK,
}

export { Placement };

export type TooltipContent = (() => ReactNode) | ReactNode;
export type TooltipProps = Optional<BasicTooltipProps, 'placement'> & {
  trigger?: Trigger | Exclude<Trigger, Trigger.NONE>[];
  showDelay?: number;
  hideDelay?: number;
};

function Tooltip({
  trigger = Trigger.NONE,
  showDelay = 0,
  hideDelay = 0,
  placement = Placement.Top,
  children,
  ...tooltipProps
}: TooltipProps) {
  const referenceElement = useRef<HTMLElement | null>(null);
  const [showTimeoutId, setShowTimeoutId] = useState<number | null>(null);
  const [hideTimeoutId, setHideTimeoutId] = useState<number | null>(null);
  const [isVisible, setIsVisible] = useState(trigger === Trigger.NONE);

  useEffect(() => {
    if (isVisible) {
      if (
        trigger === Trigger.HOVER ||
        (Array.isArray(trigger) && trigger.includes(Trigger.HOVER))
      ) {
        setIsVisible(false);
      }
    } else if (trigger === Trigger.NONE) {
      setIsVisible(true);
    }
    // eslint-disable-next-line
  }, [trigger]);

  useEffect(() => {
    const target = referenceElement.current;
    if (trigger !== Trigger.NONE && target) {
      const setVisible = () => {
        if (hideTimeoutId) {
          clearTimeout(hideTimeoutId);
          setHideTimeoutId(null);
        }
        if (showDelay === 0) {
          setIsVisible(true);
        } else if (!isVisible && !showTimeoutId) {
          setShowTimeoutId(
            window.setTimeout(() => {
              setShowTimeoutId(null);
              setIsVisible(true);
            }, showDelay),
          );
        }
      };
      const setHidden = () => {
        if (showTimeoutId) {
          clearTimeout(showTimeoutId);
          setShowTimeoutId(null);
        }
        if (hideDelay === 0) {
          setIsVisible(false);
        } else if (isVisible && !hideTimeoutId) {
          setHideTimeoutId(
            window.setTimeout(() => {
              setHideTimeoutId(null);
              setIsVisible(false);
            }, hideDelay),
          );
        }
      };

      const cleanUpFn = (Array.isArray(trigger) ? [...new Set(trigger)] : [trigger]).map(t => {
        if (t === Trigger.HOVER) {
          // Still not 100% perfect, does not handle mouseleave and then mouseenter to cancel setHidden,
          // but a reasonable compromise for now.
          return isVisible || showTimeoutId
            ? onMouseLeave(target, setHidden)
            : onMouseEnter(target, setVisible);
        } else if (t === Trigger.CLICK) {
          const toggle = () => (isVisible ? setHidden() : setVisible());
          target.addEventListener('click', toggle);
          return () => target.removeEventListener('click', toggle);
        }

        return undefined;
      });

      return () => cleanUpFn.forEach(fn => fn && fn());
    }
  });

  if (isVisible) {
    return (
      <BasicTooltip placement={placement} {...tooltipProps}>
        {ref =>
          children(node => {
            // Extract reference node
            referenceElement.current = node;
            ref(node);
          })
        }
      </BasicTooltip>
    );
  }

  return <>{children(node => (referenceElement.current = node))}</>;
}

const MaybeTooltip: React.FC<TooltipProps & { disabled?: boolean }> = ({
  disabled = false,
  children,
  ...props
}) => {
  return disabled ? children(null) : <Tooltip {...props}>{children}</Tooltip>;
};

export default MaybeTooltip;

export const DefaultTooltipProps = {
  trigger: Trigger.NONE,
  showDelay: 0,
  hideDelay: 0,
  placement: Placement.Top,
};
