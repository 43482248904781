import React, { ReactNode } from 'react';
import clsx from 'clsx';
import style from './index.module.css';

export type FieldLabelProps = {
  id?: string;
  htmlFor?: string;
  children: string;
  className?: string;
  description?: ReactNode;
};

const FieldLabel: React.FC<FieldLabelProps> = ({
  id,
  className,
  htmlFor,
  description,
  children,
}) => {
  return (
    <div className={clsx(style.container, className)}>
      <label htmlFor={htmlFor} className={style.label} id={id}>
        {children}
      </label>
      {description && <div className={style.description}>{description}</div>}
    </div>
  );
};

export default FieldLabel;
