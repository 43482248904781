import React from 'react';
import { FormElement, PartialFormElementProps } from '../../../ui/form/FormFields';
import SelectField, { SelectFieldProps } from '../SelectField';

type SelectFormFieldProps = PartialFormElementProps<string> &
  Omit<SelectFieldProps, 'value' | 'onChange'> &
  // onChange is optional here
  Partial<Pick<SelectFieldProps, 'onChange'>>;

const SelectFormField: React.FC<SelectFormFieldProps> = ({
  id,
  validate,
  name,
  defaultValue,
  ...selectProps
}) => {
  return (
    <FormElement
      id={id}
      name={name}
      defaultValue={defaultValue}
      validate={validate}
      render={({ triggerValidation: _, errorMessage, onChange, ...renderProps }) => (
        <SelectField
          {...renderProps}
          {...selectProps}
          error={errorMessage}
          onChange={nextValue => {
            onChange(nextValue);
            selectProps.onChange?.(nextValue);
          }}
        />
      )}
    />
  );
};

export default SelectFormField;
