import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { Account, useGetAccountsQuery } from '../../generated/graphql';
import { Menu, MenuItem, MenuType, PageHeader, SearchInput, Spinner, PageLayout } from '../ui';
import { useParsedQuery, withQueryParams } from '../../utils/querystringUtils';
import NoAccountsAccess from './NoAccountsAccess';

const { Content, Layout } = PageLayout;

type AccountSelectProps = {
  accounts: Pick<Account, 'id' | 'name'>[];
  getAccountUri: (accountId: string) => string;
  heading?: string;
  description?: string;
};

const AccountSelect: React.FC<AccountSelectProps> = ({
  accounts,
  getAccountUri,
  heading = 'Accounts',
  description = 'Please select an account',
}) => {
  const [query, setQuery] = useState('');
  const filteredAccounts = useMemo(() => {
    const lowerCaseQuery = query.trim().toLowerCase();
    if (lowerCaseQuery) {
      return accounts.filter(account => account.name.toLowerCase().includes(lowerCaseQuery));
    }
    return accounts;
  }, [query, accounts]);

  const queryParams = useParsedQuery();

  return (
    <Layout>
      <PageHeader heading={heading} description={description} />
      <Content>
        <div className="mod">
          <SearchInput
            value={query}
            onChange={setQuery}
            placeholder="Search accounts"
            focus={true}
          />
        </div>
        <Menu type={MenuType.VERTICAL}>
          {filteredAccounts.map(({ id, name }) => (
            <MenuItem key={id}>
              <Link to={withQueryParams(queryParams)(getAccountUri(id))}>{name}</Link>
            </MenuItem>
          ))}
        </Menu>
      </Content>
    </Layout>
  );
};

type AccountAutoSelectorProps = Pick<
  AccountSelectProps,
  'getAccountUri' | 'heading' | 'description'
>;

export default function AccountAutoSelector(props: AccountAutoSelectorProps) {
  const { data, loading } = useGetAccountsQuery({
    fetchPolicy: 'cache-and-network',
  });
  const accountEdges = data?.accounts.edges;
  const queryParams = useParsedQuery();

  if (loading) return <Spinner className="mtl" />;

  if (!accountEdges || !accountEdges.length) return <NoAccountsAccess />;

  if (accountEdges.length === 1) {
    return (
      <Redirect to={withQueryParams(queryParams)(props.getAccountUri(accountEdges[0].node.id))} />
    );
  }

  return <AccountSelect accounts={accountEdges.map(e => e.node)} {...props} />;
}
