import React, { PropsWithoutRef, Ref, RefAttributes, useState } from 'react';
import clsx from 'clsx';
import { ReactComponent as IconRadioChecked } from '../../../../../assets/icons/lettuce/radio-checked.svg';
import { ReactComponent as IconRadioUnchecked } from '../../../../../assets/icons/lettuce/radio-unchecked.svg';
import useEventCallback from '../../../utils/useEventCallback';
import CheckboxIcon from '../CheckboxIcon';
import style from './index.module.css';

export type CheckboxOrRadioProps<T extends string = string> = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'onChange' | 'type' | 'value'
> & {
  type: 'checkbox' | 'radio';
  onChange?: (isChecked: boolean) => void;
  value?: T;
};

const getIcon = (inputType: CheckboxOrRadioProps['type'], checked: boolean) => {
  if (inputType === 'checkbox') {
    return <CheckboxIcon isChecked={checked} />;
  }

  return checked ? <IconRadioChecked /> : <IconRadioUnchecked />;
};

const CheckboxOrRadio = React.forwardRef(function Checkbox<T extends string = string>(
  { checked = false, disabled, onChange, type, ...inputProps }: CheckboxOrRadioProps<T>,
  ref: Ref<HTMLInputElement>,
) {
  const [isFocused, setIsFocused] = useState(false);
  const onFocus = useEventCallback((e: React.FocusEvent<HTMLInputElement, HTMLElement>) => {
    setIsFocused(true);
    inputProps.onFocus?.(e);
  });
  const onBlur = useEventCallback((e: React.FocusEvent<HTMLInputElement, HTMLElement>) => {
    setIsFocused(false);
    inputProps.onBlur?.(e);
  });

  return (
    <div className={style.inputContainer}>
      <div
        className={clsx(style.inputOverlay, {
          [style.checked]: checked,
          [style.disabled]: disabled,
          [style.focus]: isFocused,
          [style.round]: type === 'radio',
        })}
      >
        {getIcon(type, checked)}
      </div>
      <input
        {...inputProps}
        checked={checked}
        disabled={disabled}
        type={type}
        ref={ref}
        onChange={() => onChange?.(!checked)}
        onFocus={onFocus}
        onBlur={onBlur}
        className={style.input}
      />
    </div>
  );
}) as <T extends string = string>(
  props: PropsWithoutRef<CheckboxOrRadioProps<T>> & RefAttributes<HTMLInputElement>,
) => React.ReactElement | null;

export default CheckboxOrRadio;
