import React from 'react';
import { inProgressLabel } from '../../../lib';
import ActionButtons from './index';

export type ConfirmCancelActionButtonsProps = {
  onConfirm: () => void;
  onCancel: () => void;
  isConfirming?: boolean;
  isConfirmDisabled?: boolean;
  isCancelDisabled?: boolean;
  getConfirmLabel?: (isConfirming: boolean) => string;
};

const ConfirmCancelActionButtons: React.FC<ConfirmCancelActionButtonsProps> = ({
  onConfirm,
  onCancel,
  isConfirming = false,
  isCancelDisabled = false,
  isConfirmDisabled = false,
  getConfirmLabel = isConfirming => (isConfirming ? inProgressLabel('Saving') : 'Save'),
}) => {
  return (
    <ActionButtons
      onPrimary={onConfirm}
      onSecondary={onCancel}
      disabled={{ primary: isConfirmDisabled || isConfirming, secondary: isCancelDisabled }}
      primaryLabel={getConfirmLabel(isConfirming)}
    />
  );
};

export default ConfirmCancelActionButtons;
