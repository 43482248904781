import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { ModalHeader } from '../../ui';
import { Modal } from '../../ui/Modal';
import { KeyboardShortcut } from '../lib';
import RenderKeyCombo from '../RenderKeyCombo';
import { useKeyboardShortcutContext } from '../KeyboardShortcutContext';
import useToggleKeyboardShortcutHelpShortcut from './useToggleKeyboardShortcutHelp';
import style from './KeyboardShortcutHelp.module.css';

type KeyboardShortcutHelpPanelProps = {
  onClose: () => void;
  shortcuts: KeyboardShortcut[];
};

const GeneralGroupName = 'General';

const RenderKeyboardShortcuts: React.FC<{ shortcuts: KeyboardShortcut[] }> = ({ shortcuts }) => {
  return (
    <table className={style.table}>
      <tbody>
        {shortcuts.map(shortcut => (
          <tr key={shortcut.description}>
            <td>
              <RenderKeyCombo keyCombo={shortcut.keyCombo} />
            </td>
            <td>{shortcut.description}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const KeyboardShortcutHelpModal: React.FC<KeyboardShortcutHelpPanelProps> = ({
  onClose,
  shortcuts,
}) => {
  const groupedShortcuts = useMemo(
    () =>
      shortcuts.reduce<Record<string, KeyboardShortcut[]>>((acc, shortcut) => {
        if (shortcut.group) {
          const list = acc[shortcut.group];
          if (!list) {
            acc[shortcut.group] = [shortcut];
          } else {
            list.push(shortcut);
          }
        } else {
          if (acc[GeneralGroupName]) {
            acc[GeneralGroupName].push(shortcut);
          } else {
            acc[GeneralGroupName] = [shortcut];
          }
        }

        return acc;
      }, {}),
    [shortcuts],
  );

  return (
    <Modal onCloseClick={onClose} noPadding={true}>
      <ModalHeader
        heading="Keyboard shortcuts"
        description="List of all the active keyboard shortcuts for the current page."
        className={style.header}
      />
      <div className={style.body}>
        {Object.entries(groupedShortcuts).map(([group, shortcuts]) => (
          <div key={group} className={style.group}>
            {<div className={style.groupName}>{group}</div>}
            <RenderKeyboardShortcuts shortcuts={shortcuts} />
          </div>
        ))}
      </div>
    </Modal>
  );
};

type KeyboardShortcutHelpProps = {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
} & Pick<KeyboardShortcutHelpPanelProps, 'shortcuts'>;

const KeyboardShortcutHelp: React.FC<KeyboardShortcutHelpProps> = ({
  shortcuts,
  isVisible,
  setIsVisible,
}) => {
  const { registerKeyboardShortcut } = useKeyboardShortcutContext();
  const toggleVisibleShortcut = useToggleKeyboardShortcutHelpShortcut();

  useEffect(
    () => registerKeyboardShortcut(toggleVisibleShortcut),
    [registerKeyboardShortcut, toggleVisibleShortcut],
  );

  return isVisible ? (
    <KeyboardShortcutHelpModal shortcuts={shortcuts} onClose={() => setIsVisible(false)} />
  ) : null;
};

export default KeyboardShortcutHelp;
