import React, { useMemo } from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { useAuthenticationContext } from '../../../security/AuthenticationContext';
import { withQueryParams } from '../../../../utils/querystringUtils';
import useIsFeatureFlagEnabled from '../../common/featureFlags/useIsFeatureFlagEnabled';
import { useFeatureFlagsReady } from '../../common/featureFlags/FeatureFlags';
import { Forbidden } from '../../../httpErrors';
import { path } from '../../crisp/routing/lib';
import { RouteDeclaration } from './lib';

type RenderRouteProps = RouteComponentProps & { route: RouteDeclaration };

const AuthRoute: React.FC<RenderRouteProps> = props => {
  const { loading, isSignedIn } = useAuthenticationContext();
  if (loading) {
    return null;
  }

  if (!isSignedIn) {
    return (
      <Redirect
        to={withQueryParams({
          returnTo: `${props.location?.pathname}${props.location?.search}`,
        })(path('SignIn')())}
      />
    );
  }

  return <RenderRoute {...props} route={{ ...props.route, requireAuth: false }} />;
};

const FeatureFlaggedRoute: React.FC<RenderRouteProps> = props => {
  const flagId = props.route.requireFeatureFlag!;
  const isEnabled = useIsFeatureFlagEnabled(flagId);
  if (useFeatureFlagsReady()) {
    return isEnabled ? (
      <RenderRoute {...props} route={{ ...props.route, requireFeatureFlag: undefined }} />
    ) : (
      <Forbidden />
    );
  }

  return null;
};

const DecodeParamsAndRender: React.FC<RenderRouteProps> = props => {
  const { route, ...routeComponentProps } = props;

  const { match } = routeComponentProps;
  routeComponentProps.match = {
    ...match,
    params: useMemo(() => {
      return Object.assign(
        match.params,
        Object.entries(match.params).reduce(
          (acc, [k, v]) => ({
            ...acc,
            [k]: typeof v === 'string' ? decodeURIComponent(v) : v,
          }),
          {},
        ),
      );
    }, [match.params]),
  };

  return React.createElement<typeof routeComponentProps>(route.Render, routeComponentProps);
};

const RenderRoute: React.FC<RenderRouteProps> = props => {
  const { route } = props;
  if (route.requireAuth) {
    return <AuthRoute {...props} />;
  }

  if (route.requireFeatureFlag) {
    return <FeatureFlaggedRoute {...props} />;
  }

  return <DecodeParamsAndRender {...props} />;
};

export default RenderRoute;
