import React from 'react';
import {
  CommonFieldProps,
  FormElement,
  PartialFormElementProps,
} from '../../../ui/form/FormFields';
import TextAreaField, { TextAreaFieldProps } from '../TextAreaField';

type TextAreaFormFieldProps = PartialFormElementProps<string> &
  Pick<TextAreaFieldProps, Exclude<CommonFieldProps, 'type'> | 'rows'>;

const TextFormField: React.FC<TextAreaFormFieldProps> = ({
  id,
  validate,
  name,
  defaultValue,
  ...textAreaProps
}) => {
  return (
    <FormElement
      id={id}
      name={name}
      defaultValue={defaultValue}
      validate={validate}
      render={({ triggerValidation: _, onChange, errorMessage, ...renderProps }) => (
        <TextAreaField
          {...renderProps}
          {...textAreaProps}
          onChange={onChange}
          error={errorMessage}
        />
      )}
    />
  );
};

export default TextFormField;
