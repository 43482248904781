import { Maybe } from '@tellurian/ts-utils';
import firebase from 'firebase/compat/app';
import isEqual from 'lodash/isEqual';

type User = firebase.User;

export type ZendeskWidgetOptions = {
  locale?: string;
  onShow?: () => void;
  position?: 'left' | 'right';
  offsetBottom?: number;
};

export type ZendeskWidgetInstance = {
  show: () => void;
  hide: () => void;
  dispose: () => void;
  update: (options: ZendeskWidgetOptions) => void;
  identify: (user: User) => void;
  setSuggestions: (suggestions?: string) => void;
  key: string;
};

export const initWidget = (
  key: string,
  options?: ZendeskWidgetOptions,
): Readonly<ZendeskWidgetInstance> => {
  let isInitialized = false;
  let pendingOptions: Maybe<ZendeskWidgetOptions> = options;
  let lastOptions: Maybe<ZendeskWidgetOptions> = undefined;
  let pendingUser: Maybe<User> = undefined;
  let pendingSuggestions: Maybe<string> = undefined;
  let isDisposed = false;

  const script: HTMLScriptElement = document.createElement('script');

  const updateSettings = () => {
    if (pendingOptions) {
      if (pendingOptions.onShow) {
        window.zE?.('webWidget:on', 'userEvent', event => {
          if (event.action === 'Help Center Shown') {
            pendingOptions?.onShow?.();
          }
        });
      }

      if (pendingOptions?.locale) {
        window.zE?.('webWidget', 'setLocale', pendingOptions?.locale);
      }
      window.zE?.('webWidget', 'updateSettings', {
        webWidget: {
          position: { horizontal: pendingOptions?.position },
          offset: { vertical: (v => v && `${v}px`)(pendingOptions?.offsetBottom) },
        },
      });

      pendingOptions = undefined;
    }
  };

  const update = (nextOptions: ZendeskWidgetOptions) => {
    if (!isDisposed) {
      if (!isEqual(nextOptions, lastOptions)) {
        pendingOptions = { ...pendingOptions, ...nextOptions };
        if (isInitialized) {
          updateSettings();
        }
        lastOptions = nextOptions;
      }
    } else {
      console.warn('Cannot update disposed zendesk widget.');
    }
  };

  const dispose = () => {
    if (!isDisposed) {
      window.zE?.('webWidget', 'close');
      window.zE?.('webWidget', 'hide');
      // The following are strictly necessary to completely dispose of the widget and mark it as unloaded, in
      // order to force Zendesk to reload a completely different widget (without reloading the page).
      window['zE'] = undefined;
      window['zEmbed'] = undefined;
      window['zEACLoaded'] = undefined;
      document.querySelector('[data-product="web_widget"]')?.remove();
      document.getElementById('launcher')?.parentElement?.remove();
      document.body.removeChild(script);
      isDisposed = true;
    }
  };

  const doIdentify = () => {
    if (isInitialized && pendingUser) {
      window.zE?.('webWidget', 'identify', {
        name: pendingUser.displayName,
        email: pendingUser.email,
      });
      window.zE?.('webWidget', 'prefill', {
        name: {
          value: pendingUser.displayName,
          readOnly: false,
        },
        email: {
          value: pendingUser.email,
          readOnly: false,
        },
      });
      pendingUser = undefined;
    }
  };

  const identify = (user: User) => {
    if (!isDisposed) {
      pendingUser = user;
      doIdentify();
    }
  };

  const updateSuggestions = () => {
    if (isInitialized) {
      window.zE?.('webWidget', 'helpCenter:setSuggestions', {
        search: pendingSuggestions?.toLowerCase(),
      });
    }
  };

  const setSuggestions = suggestions => {
    if (!isDisposed) {
      pendingSuggestions = suggestions;
      updateSuggestions();
    }
  };

  const show = () => {
    if (!isDisposed) {
      window.zE?.('webWidget', 'show');
    }
  };

  const hide = () => {
    if (!isDisposed) {
      window.zE?.('webWidget', 'hide');
    }
  };

  // This exact id is necessary
  script.id = 'ze-snippet';
  script.src = `https://static.zdassets.com/ekr/snippet.js?key=${key}`;
  script.async = true;
  document.body.appendChild(script);
  script.addEventListener('load', () => {
    isInitialized = true;
    updateSettings();
    updateSuggestions();
    doIdentify();
    show();
  });

  return {
    key,
    show,
    hide,
    dispose,
    update,
    identify,
    setSuggestions,
  };
};
