import React, { ReactNode, useMemo } from 'react';
import clsx from 'clsx';
import { ReactComponent as IconClose } from '../../../../assets/icons/lettuce/clear.svg';
import { NotificationType } from '../lib/notifications/lib';
import NotificationIcon from '../lib/notifications/NotificationIcon';
import Title from '../Title';
import style from './index.module.css'; // eslint-disable-line css-modules/no-unused-class

const getNextId = (
  (start = 0) =>
  () =>
    ++start
)();

const useNotificationId = (content: ReactNode) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => `notification-${String(getNextId())}`, [content]);
};

export type NotificationProps = {
  notificationType: NotificationType;
  content: ReactNode;
  title?: string;
  onDismiss?: () => void;
  isAutoDismissed?: boolean;
  className?: string;
  direction?: 'row' | 'column';
};

const Notification = React.forwardRef<HTMLDivElement, NotificationProps>(function Notification(
  {
    content,
    className,
    title,
    notificationType,
    isAutoDismissed = false,
    onDismiss,
    direction = 'column',
  },
  ref,
) {
  const id = useNotificationId(content);
  return (
    <div
      ref={ref}
      role={isAutoDismissed ? 'log' : 'alertdialog'}
      aria-live={isAutoDismissed ? 'polite' : 'off'}
      className={clsx(
        style.notification,
        style[notificationType],
        { [style.row]: direction === 'row' },
        className,
      )}
      aria-describedby={id}
      aria-modal="true"
      aria-label={notificationType}
    >
      <div className={style.iconSection}>
        <NotificationIcon notificationType={notificationType} />
      </div>
      <div id={id} className={style.contentSection}>
        {title && (
          <Title size="S" className="man">
            {title}
          </Title>
        )}
        {content}
      </div>
      {onDismiss && (
        <div className={style.dismissSection}>
          <button
            onClick={onDismiss}
            type="button"
            aria-label={
              isAutoDismissed
                ? /* "hide" the "close" title from the svg when the close button is unnecessary for screen readers */ ' '
                : 'Dismiss Notification'
            }
          >
            <IconClose />
          </button>
        </div>
      )}
    </div>
  );
});

export default Notification;
