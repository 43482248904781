import React from 'react';
import clsx from 'clsx';
import style from './ListItem.module.css';

export type ListItemProps = React.HTMLAttributes<HTMLLIElement> & {
  isActionable?: boolean;
  isFocused?: boolean;
  isSelected?: boolean;
  focusTransitionsEnabled?: boolean;
};

const ListItem = React.forwardRef<HTMLLIElement, ListItemProps>(function ListItem(
  {
    isActionable,
    isSelected,
    isFocused,
    children,
    className,
    focusTransitionsEnabled: _,
    ...rest
  }: ListItemProps,
  ref,
) {
  return (
    <li
      ref={ref}
      className={clsx(
        style.item,
        {
          [style.selected]: isSelected,
          [style.focused]: isFocused,
          [style.actionable]: isActionable,
        },
        className,
      )}
      {...rest}
    >
      {children}
    </li>
  );
});

export default React.memo(ListItem) as typeof ListItem;
