import React, { PropsWithoutRef, Ref, RefAttributes } from 'react';
import clsx from 'clsx';
import Checkbox, { CheckboxProps } from '../lib/Checkbox';
import { useDefaultHashId } from '../../../lib';
import FieldDetails from '../lib/FieldDetails';
import style from '../lib/CheckboxOrRadio/CheckboxOrRadioField.module.css';

export type CheckboxFieldProps<T extends string = string> = CheckboxProps<T> & {
  label?: React.ReactNode;
  description?: string;
  error?: string;
};

const CheckboxField = React.forwardRef<HTMLInputElement, CheckboxFieldProps>(function CheckboxField<
  T extends string = string,
>(
  { className, id, label, error, description, ...checkboxProps }: CheckboxFieldProps<T>,
  ref: Ref<HTMLInputElement>,
) {
  id = useDefaultHashId(id);
  return (
    <div className={clsx('mod', className)}>
      <div className={clsx(style.container, { [style.disabled]: checkboxProps.disabled })}>
        <Checkbox {...checkboxProps} ref={ref} id={id} />
        <label htmlFor={id}>{label}</label>
      </div>
      <FieldDetails
        error={error}
        description={description}
        className={clsx(style.details, {
          [style.hasError]: error,
        })}
      />
    </div>
  );
}) as <T extends string = string>(
  props: PropsWithoutRef<CheckboxFieldProps<T>> & RefAttributes<HTMLInputElement>,
) => React.ReactElement | null;

export default CheckboxField;
