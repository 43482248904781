import React, { createContext, ReactNode, useContext } from 'react';
import { Maybe, noop } from '@tellurian/ts-utils';
import { FCC } from '../../../../../utils/types';

type ApiRequestCacheContextInterface = {
  get: <T>(path: string) => Maybe<T>;
  set: <T>(path: string, value: T) => void;
  loading: boolean;
};

const ApiRequestCacheContext = createContext<ApiRequestCacheContextInterface>({
  get: () => undefined,
  set: noop,
  loading: false,
});

export type GetKeyForPath = (path: string) => string;
export type UseApiRequestCache = (
  cacheKey: string,
  getKey?: GetKeyForPath,
) => ApiRequestCacheContextInterface;

type ApiRequestCacheProviderProps = {
  namespace: string;
  useApiRequestCache: UseApiRequestCache;
  children: ReactNode;
  // Provide a convenient way to disable the cache for testing
  enabled?: boolean;
};

const ApiRequestCacheProvider: FCC<ApiRequestCacheProviderProps> = ({
  useApiRequestCache,
  children,
  namespace,
  enabled = true,
}) => {
  const value = useApiRequestCache(namespace);
  if (!enabled) {
    return children;
  }

  return (
    <ApiRequestCacheContext.Provider value={value}>{children}</ApiRequestCacheContext.Provider>
  );
};

export default ApiRequestCacheProvider;

export const useRequestCache = () => useContext(ApiRequestCacheContext);
