import React from 'react';
import { UserAccountRole } from '../../../../generated/graphql';
import RadioGroupField from '../../components/RadioGroupField';

const UserPermissions = {
  [UserAccountRole.Admin]: {
    name: 'Account Admin',
    description: 'Can edit and view everything on the account, including users.',
  },
  [UserAccountRole.Editor]: {
    name: 'Account Editor',
    // Editor and Viewer are the same right now but we've kept the "and edit" in here in case that changes in the future
    // so that roles are chosen appropriately for each user.
    description:
      'Can view and edit business dashboards and share reports with internal and external users.',
  },
  [UserAccountRole.Viewer]: {
    name: 'Account Viewer',
    description: 'Can view business dashboards and share reports with internal and external users.',
  },
  [UserAccountRole.Member]: {
    name: 'Dashboard Viewer',
    description:
      'Can view specific dashboards and save reports, but cannot view/edit anything else or invite new users.',
  },
};

const Roles = [
  //Explicitly specified to maintain desired order
  UserAccountRole.Admin,
  UserAccountRole.Editor,
  UserAccountRole.Viewer,
  UserAccountRole.Member,
];

const RoleRadioItems = Roles.map(role => ({
  label: UserPermissions[role].name,
  value: role,
  description: UserPermissions[role].description,
}));

type PermissionsSelectionCheckButtonsProps = {
  value: UserAccountRole;
  onChange: (role: UserAccountRole) => void;
};

const PermissionsSelectionCheckButtons: React.FC<PermissionsSelectionCheckButtonsProps> = ({
  value,
  onChange,
}) => {
  const label = 'Select the role the user will have';
  return (
    <RadioGroupField
      className="mhs"
      label={label}
      radioItems={RoleRadioItems}
      value={value}
      onChange={onChange}
    />
  );
};

export default PermissionsSelectionCheckButtons;
