import React, { useEffect } from 'react';
import { ReactComponent as IconClose } from '../../../assets/icons/close.svg';
import style from './CloseButton.module.css';

type CloseButtonProps = {
  onClick: () => void;
};

export const CloseButton: React.FC<CloseButtonProps> = ({ onClick }) => {
  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        onClick();
      }
    }

    window.addEventListener('keydown', handleKeyDown);

    return function cleanup() {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClick]);

  return (
    <button className={style.closeButton} onClick={onClick} type="button" aria-label="Close">
      <IconClose height={16} width={16} />
    </button>
  );
};
