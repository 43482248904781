import { Maybe } from '@tellurian/ts-utils';
import { LocalStorage, LocalStorageKey } from '../utils/localStorage';
import { useAuthorizationContext } from '../components/security/AuthorizationContext';

const useLastAccountId = (accountId?: string): Maybe<string> => {
  if (useAuthorizationContext().getHasGlobalAdminPermission() && accountId) {
    LocalStorage.setItem(LocalStorageKey.LastAccountId, accountId);
    return accountId;
  }

  return LocalStorage.getItem(LocalStorageKey.LastAccountId);
};

export default useLastAccountId;
