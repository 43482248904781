import React, { useContext, useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import { addAuthenticationListener, isSignedIn } from './lib';

type User = firebase.User;

export interface AuthenticationContextInterface {
  firebaseUser: User | null;
  isSignedIn: boolean;
  loading: boolean;
}

export const AuthenticationContext = React.createContext<AuthenticationContextInterface>({
  firebaseUser: null,
  isSignedIn: false,
  loading: true,
});

export function AuthenticationProvider({ children }: { children: React.ReactNode }) {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  const onAuthenticated = async (user: User | null) => {
    setCurrentUser(user);
    setLoading(false);
  };

  useEffect(() => addAuthenticationListener(onAuthenticated), []);

  return (
    <AuthenticationContext.Provider
      value={{
        isSignedIn: isSignedIn(currentUser),
        firebaseUser: currentUser,
        loading: loading,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
}

export function useAuthenticationContext() {
  return useContext(AuthenticationContext);
}
