import { useRef } from 'react';
import useEventCallback from '../../utils/useEventCallback';

const useAutoFocusOnce = (autoFocus = false) => {
  const hasBlurredRef = useRef(false);
  const onBlur = useEventCallback(() => {
    hasBlurredRef.current = true;
  });

  return { autoFocus: autoFocus && !hasBlurredRef.current, onBlur };
};

export default useAutoFocusOnce;
