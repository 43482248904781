import React, { useEffect } from 'react';
import { noop } from '@tellurian/ts-utils';
import useDropdown, { UseDropdownParams } from '../lib/useDropdown';
import { ActionMenuItemSpec } from '../Menu/lib';
import Popover from '../lib/Popover';
import Menu from '../Menu';
import PopoverActionContextProvider from '../NavRail/PopoverActionContext';
import FloatingPortal from '../lib/FloatingPortal';
import { ContextButtonProps } from '../lib/ContextButton';
import ThreeDotButton from './ThreeDotButton';

export type ThreeDotMenuProps = {
  items: ActionMenuItemSpec[];
  'aria-labelledby'?: string;
  'aria-label'?: string;
  onActiveStateChange?: (isActive: boolean) => void;
} & Pick<UseDropdownParams, 'isInitiallyActive' | 'placement'> &
  Pick<ContextButtonProps, 'variant' | 'size'>;

const ThreeDotMenu: React.FC<ThreeDotMenuProps> = ({
  'aria-label': ariaLabel = 'context menu',
  'aria-labelledby': ariaLabelledBy,
  items,
  placement = 'end',
  variant,
  onActiveStateChange,
  size,
  ...dropdownParams
}) => {
  const { isActive, getPopoverProps, getReferenceProps, setIsActive } = useDropdown({
    ...dropdownParams,
    placement,
    dismissOnClickAway: true,
  });

  useEffect(() => {
    onActiveStateChange?.(isActive);
  }, [isActive, onActiveStateChange]);

  return (
    <>
      <ThreeDotButton {...getReferenceProps()} variant={variant} size={size} />
      {isActive && (
        <>
          <FloatingPortal>
            <Popover
              {...getPopoverProps()}
              role="menu"
              // Prefer aria-labelledby if it is set
              aria-label={ariaLabelledBy ? undefined : ariaLabel}
              aria-labelledby={ariaLabelledBy}
              focusTrap={true}
            >
              <div className="pas">
                <PopoverActionContextProvider
                  show={() => setIsActive(true)}
                  hide={() => setIsActive(false)}
                  onContentChanged={noop}
                >
                  <Menu items={items} as="div" />
                </PopoverActionContextProvider>
              </div>
            </Popover>
          </FloatingPortal>
        </>
      )}
    </>
  );
};

export default ThreeDotMenu;
