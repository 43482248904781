import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Theme } from '../../components/Theme';
import { useThemeContext } from '../../components/Theme/ThemeProvider';
import { useUserPreferencesContext } from './UserPreferencesProvider';
import { getAccountBoundTheme, ThemeOptionToTheme } from './lib';

const SwappableThemes = new Set([
  Theme.Lettuce,
  Theme.Unfi,
  Theme.Rema,
  Theme.Advantage,
  Theme.HighImpact,
  Theme.Dot,
  Theme.Instacart,
]);

const useAccountBoundTheme = () => {
  const history = useHistory();
  const [accountBoundTheme, setAccountBoundTheme] = useState(getAccountBoundTheme());
  useEffect(() => {
    const onChange = () => setAccountBoundTheme(getAccountBoundTheme());
    return history.listen(onChange);
  }, [history]);

  return accountBoundTheme;
};

const getIsPartnerMiniSite = () => window.location.pathname.startsWith('/p/');

const useIsPartnerMiniSite = () => {
  const history = useHistory();
  const [isPartnerMiniSite, setIsPartnerMiniSite] = useState(getIsPartnerMiniSite);
  useEffect(() => {
    const onChange = () => setIsPartnerMiniSite(getIsPartnerMiniSite());
    return history.listen(onChange);
  }, [history]);

  return isPartnerMiniSite;
};

const ApplyPreferredTheme: React.FC = () => {
  const { theme: currentTheme, setTheme: applyTheme } = useThemeContext();
  const { preferences } = useUserPreferencesContext();
  const accountBoundTheme = useAccountBoundTheme();
  const isPartnerMiniSite = useIsPartnerMiniSite();

  useEffect(() => {
    const { theme: preferredTheme, overrideAccountTheme } = preferences.general;
    if (!overrideAccountTheme && accountBoundTheme) {
      applyTheme(accountBoundTheme);
    } else if (currentTheme !== ThemeOptionToTheme[preferredTheme]) {
      // Not all themes can be changed, e.g. Rema should not change to Crisp Lettuce or Advantage
      // Partner theme will be set by the template
      if ((overrideAccountTheme || !isPartnerMiniSite) && SwappableThemes.has(currentTheme)) {
        applyTheme(ThemeOptionToTheme[preferredTheme]);
      }
    }
  }, [preferences, currentTheme, applyTheme, accountBoundTheme, isPartnerMiniSite]);
  return null;
};

export default ApplyPreferredTheme;
