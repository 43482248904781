import React from 'react';
import { AnyObject } from '@tellurian/ts-utils';
import ListOfItems, { ListOfItemsProps } from '../../../ui/lists/listOfItems/ListOfItems';
import { useMaxItemsVisible } from '../../../../utils/hooks';
import ToggleExpandButton from './ToggleExpandButton';
import style from './index.module.css';

export type ExpandableListProps<T, P extends AnyObject = AnyObject> = {
  maxItemsVisible?: number;
} & ListOfItemsProps<T, P>;

export default function ExpandableList<T, P extends AnyObject = AnyObject>({
  maxItemsVisible = 5,
  items,
  RenderItem,
  ...rest
}: ExpandableListProps<T, P>) {
  const { exceedsVisibleCount, isCollapsed, setIsCollapsed, visibleItems } = useMaxItemsVisible({
    items,
    maxItemsVisible,
  });

  return (
    <>
      <ListOfItems<T, P>
        items={visibleItems}
        RenderItem={RenderItem}
        role="list"
        {...(rest as P)}
      />
      {exceedsVisibleCount && (
        <div className={style.toggleButtonContainer}>
          <ToggleExpandButton
            isExpanded={!isCollapsed}
            onToggle={() => setIsCollapsed(current => !current)}
            collapsedCount={maxItemsVisible}
            totalCount={items.length}
          />
        </div>
      )}
    </>
  );
}
