import React from 'react';
import { BookmarkFragmentWithDashboardInfo } from '../../../../BookmarksContext';
import Modal from '../../../components/Modal';
import RenderReportMetadata from './RenderReportMetadata';
import ReportDetails from './ReportDetails';
import ReportAccessDetails from './ReportAccessDetails';

type ReportDetailsModalProps = {
  onClose: () => void;
  report: BookmarkFragmentWithDashboardInfo;
};

const ReportDetailsModal: React.FC<ReportDetailsModalProps> = ({ onClose, report }) => {
  const { description } = report;
  return (
    <Modal onCloseClick={onClose} title={description} size="S">
      <div className="mvm">
        <RenderReportMetadata report={report} />
        <ReportAccessDetails report={report} />
        <ReportDetails report={report} />
      </div>
    </Modal>
  );
};

export default ReportDetailsModal;
