import { ValidationFn } from './validation';

export const createRegisterOptionsFromValidationFns = <T>(validationFns: ValidationFn<T>[]) =>
  validationFns.reduce(
    (acc, fn, index) => {
      acc.validate[fn.name || index] = fn;
      return acc;
    },
    { validate: {} },
  );

/**
 * Returns an "unsecure" hash value for a string.
 * @param src
 */
export const getHash = (src: string): number => {
  let hash = 0,
    i,
    chr;
  if (src.length === 0) return hash;
  for (i = 0; i < src.length; ++i) {
    chr = src.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }

  return hash;
};

export const generateId = (fieldName: string): string => String(getHash(fieldName));

/**
 * Filter an array based on the specified predicate function,
 * capping the length of the result.
 * @param arr - array of items to filter
 * @param predicate - predicate function to filter by
 * @param maxItemCount - max length of the result
 * @returns {Array} the filtered result
 */
export const filterMax = <T>(
  arr: T[],
  predicate: (item: T) => boolean,
  maxItemCount: number,
): T[] => {
  const result: T[] = [];
  for (const item of arr) {
    if (result.length >= maxItemCount) {
      return result;
    }
    if (predicate(item)) {
      result.push(item);
    }
  }

  return result;
};
