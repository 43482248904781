import React, { useState } from 'react';
import clsx from 'clsx';
import {
  DashboardForPermissionsFragment,
  RelatedConnectorConfigurationForPermissionsFragment,
} from '../../../../generated/graphql';
import { Checkbox } from '../../../ui';
import { CheckListItem } from '../../../ui/CheckList';
import { useGetDashboardGroupsForPermissions } from '../../../businessDashboard/hooks/useGetDashboardGroups';
import { adjustDashboardGroupName } from '../../../businessDashboard/DashboardGroup/lib';
import RadioGroupField from '../../components/RadioGroupField';
import styles from './DashboardPermissionsSelection.module.css';

type DashboardGroupCheckListItem = CheckListItem & {
  text: string;
  dashboards: DashboardForPermissionsFragment[];
};

type DashboardCheckListItem = CheckListItem & {
  text: string;
  dashboardGroupId: string;
  relatedConnectorConfigurations: RelatedConnectorConfigurationForPermissionsFragment[];
};

export const ALL_DASHBOARDS_ID = 'all-dashboards';
export type DashboardPermissions = {
  [dashboardGroupId: string]: {
    [dashboardId: string]: {
      [connectorConfigurationId: string]: boolean;
    };
  };
};

const hasKey = (src: Record<string, unknown>, key: string) => src.hasOwnProperty(key);

const RelatedConnectorConfigurationSelection: React.FC<{
  item: DashboardCheckListItem;
  selectedDashboards: DashboardPermissions;
  toggleByRelatedConnectorConfiguration: (
    dashboardGroupId: string,
    dashboardId: string,
    connectorConfigurationId: string,
  ) => void;
}> = ({ item, selectedDashboards, toggleByRelatedConnectorConfiguration }) => {
  return (
    <div className={styles.indentedCheckboxContainer}>
      {item.relatedConnectorConfigurations &&
        item.relatedConnectorConfigurations.length > 1 &&
        item.relatedConnectorConfigurations.map(cc => {
          return !hasKey(selectedDashboards[item.dashboardGroupId][item.id], cc.id) ? null : (
            <div className="mvm" key={cc.id}>
              <Checkbox
                label={cc.description}
                checked={selectedDashboards[item.dashboardGroupId][item.id][cc.id]}
                name={cc.description ?? cc.connector.name}
                onChange={() => {
                  toggleByRelatedConnectorConfiguration(item.dashboardGroupId, item.id, cc.id);
                }}
              />
            </div>
          );
        })}
    </div>
  );
};

const DashboardSelection: React.FC<{
  item: DashboardGroupCheckListItem;
  selectedDashboards: DashboardPermissions;
  toggleByDashboard: (dashboardGroupId: string, dashboardId: string) => void;
  toggleByRelatedConnectorConfiguration: (
    dashboardGroupId: string,
    dashboardId: string,
    connectorConfigurationId: string,
  ) => void;
}> = ({ item, selectedDashboards, toggleByDashboard, toggleByRelatedConnectorConfiguration }) => {
  return (
    <>
      {item.checked &&
        item.dashboards.map(d => {
          if (!hasKey(selectedDashboards[d.dashboardGroupId], d.id)) {
            return null;
          }

          const isChecked = Object.values(selectedDashboards[d.dashboardGroupId][d.id]).some(
            v => v,
          );
          return (
            <div className={clsx(styles.indentedCheckboxContainer, 'mvm')} key={d.id}>
              <Checkbox
                label={d.name}
                name={d.name}
                checked={isChecked}
                onChange={() => {
                  toggleByDashboard(d.dashboardGroupId, d.id);
                }}
              />
              {isChecked && (
                <RelatedConnectorConfigurationSelection
                  item={{
                    id: d.id,
                    text: d.name,
                    checked: isChecked,
                    dashboardGroupId: d.dashboardGroupId,
                    relatedConnectorConfigurations: d.relatedConnectorConfigurations ?? [],
                  }}
                  selectedDashboards={selectedDashboards}
                  toggleByRelatedConnectorConfiguration={toggleByRelatedConnectorConfiguration}
                />
              )}
            </div>
          );
        })}
    </>
  );
};

export type DashboardPermissionsSelectionProps = {
  accountId: string;
  value: DashboardPermissions;
  onChange: (nextValue: DashboardPermissions) => void;
};

const DashboardPermissionsSelection: React.FC<DashboardPermissionsSelectionProps> = ({
  accountId,
  value,
  onChange,
}) => {
  const { dashboardGroups } = useGetDashboardGroupsForPermissions(accountId);
  const [selectedDashboards, setSelectedDashboards] = useState<DashboardPermissions>(value);

  /**
   * Toggle a single related connector configuration.
   */
  const toggleByRelatedConnectorConfiguration = (
    dashboardGroupId: string,
    dashboardId: string,
    connectorConfigurationId: string,
  ) => {
    const isChecked = selectedDashboards[dashboardGroupId][dashboardId][connectorConfigurationId];
    const nextSelectedDashboards = { ...selectedDashboards };
    nextSelectedDashboards[dashboardGroupId][dashboardId][connectorConfigurationId] = !isChecked;
    setSelectedDashboards(nextSelectedDashboards);
    onChange(nextSelectedDashboards);
  };

  /**
   * Toggle an entire dashboard.
   * Every related connector configuration under the dashboard will also be
   * toggled.
   */
  const toggleByDashboard = (dashboardGroupId: string, dashboardId: string) => {
    const isChecked = Object.values(selectedDashboards[dashboardGroupId][dashboardId]).some(v => v);
    const nextSelectedDashboards = { ...selectedDashboards };
    for (const connectorConfigurationId in nextSelectedDashboards[dashboardGroupId][dashboardId]) {
      nextSelectedDashboards[dashboardGroupId][dashboardId][connectorConfigurationId] = !isChecked;
    }
    setSelectedDashboards(nextSelectedDashboards);
    onChange(nextSelectedDashboards);
  };

  /**
   * Toggle an entire dashboard group.
   * Every dashboard/related connector configuration under the dashboard group
   * will also be toggled.
   */
  const toggleByDashboardGroup = (dashboardGroupId: string) => {
    const isChecked = Object.values(selectedDashboards[dashboardGroupId]).some(dashboard =>
      Object.values(dashboard).some(v => v),
    );
    const nextSelectedDashboards = { ...selectedDashboards };
    for (const dashboardId in nextSelectedDashboards[dashboardGroupId]) {
      for (const connectorConfigurationId in nextSelectedDashboards[dashboardGroupId][
        dashboardId
      ]) {
        nextSelectedDashboards[dashboardGroupId][dashboardId][connectorConfigurationId] =
          !isChecked;
      }
    }
    setSelectedDashboards(nextSelectedDashboards);
    onChange(nextSelectedDashboards);
  };

  const isAllDashboardsSelected =
    selectedDashboards[ALL_DASHBOARDS_ID][ALL_DASHBOARDS_ID][ALL_DASHBOARDS_ID];
  return (
    <div className="mhl">
      <div className="mvm">
        <RadioGroupField
          className="mhs mvn"
          direction="column"
          radioItems={['All dashboards', 'Specific dashboards']}
          value={isAllDashboardsSelected ? 'All dashboards' : 'Specific dashboards'}
          onChange={() => toggleByDashboardGroup(ALL_DASHBOARDS_ID)}
          name="dashboardPermissions"
        />
      </div>
      <div className={styles.indentedCheckboxContainer}>
        {!selectedDashboards[ALL_DASHBOARDS_ID][ALL_DASHBOARDS_ID][ALL_DASHBOARDS_ID] &&
          dashboardGroups.map(g => {
            if (!hasKey(selectedDashboards, g.id)) {
              return null;
            }

            const isChecked = Object.values(selectedDashboards[g.id]).some(dashboard =>
              Object.values(dashboard).some(v => v),
            );
            return (
              <div className="mvm" key={g.id}>
                <Checkbox
                  label={adjustDashboardGroupName(g.name)}
                  name={g.name}
                  checked={isChecked}
                  onChange={() => {
                    toggleByDashboardGroup(g.id);
                  }}
                />
                <DashboardSelection
                  item={{ id: g.id, text: g.name, checked: isChecked, dashboards: g.dashboards }}
                  selectedDashboards={selectedDashboards}
                  toggleByDashboard={toggleByDashboard}
                  toggleByRelatedConnectorConfiguration={toggleByRelatedConnectorConfiguration}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default DashboardPermissionsSelection;
