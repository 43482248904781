import React, { useCallback, useMemo } from 'react';

import { useGetManageUsersQuery, UserInvitationFragment } from '../../../../generated/graphql';
import View from './View';

type ManageUsersProps = {
  accountId: string;
};

const ManageUsers: React.FC<ManageUsersProps> = ({ accountId }) => {
  const { data, loading, refetch } = useGetManageUsersQuery({
    variables: {
      accountId,
    },
  });

  const userInvitations: UserInvitationFragment[] = useMemo(() => {
    return data?.account.userInvitations.edges.map(e => e.node) ?? [];
  }, [data]);

  const users = useMemo(() => {
    return data?.account.users.edges.map(e => e.node) ?? [];
  }, [data]);

  const emailMatchesCurrentUser = useCallback(
    (email: string) => {
      if (data?.currentUser) {
        return data.currentUser.email === email;
      }

      return false;
    },
    [data],
  );

  return (
    <View
      accountId={accountId}
      userInvitations={userInvitations}
      users={users}
      refresh={refetch}
      loading={loading}
      emailMatchesCurrentUser={emailMatchesCurrentUser}
    />
  );
};

export default ManageUsers;
