import React from 'react';
import clsx from 'clsx';
import { useThemeContext } from '../lettuce/components/Theme/ThemeProvider';
import NotificationCard from '../lettuce/components/NotificationCard';
import { NotificationState, NotificationType } from '../lettuce/components/lib/notifications/lib';
import { useToastContext } from './ToastContext';
import Toast from './Toast';
import { ToastDetails, ToastState } from './lib';
import { ToastFlavourOptions } from './Toasts';
import styles from './ToastPanel.module.css';

const ToastAriaLabelToNotificationType: Readonly<Record<string, NotificationType>> = {
  [ToastFlavourOptions.Success.ariaLabel]: NotificationType.Success,
  [ToastFlavourOptions.Info.ariaLabel]: NotificationType.Info,
  [ToastFlavourOptions.Warning.ariaLabel]: NotificationType.Warning,
  [ToastFlavourOptions.Failure.ariaLabel]: NotificationType.Failure,
};

const ToastStateToNotificationState: Readonly<Record<ToastState, NotificationState>> = {
  [ToastState.Showing]: NotificationState.Showing,
  [ToastState.Dismissed]: NotificationState.Dismissed,
  [ToastState.AnimatingShow]: NotificationState.AnimatingShow,
  [ToastState.AnimatingDismiss]: NotificationState.AnimatingDismiss,
};

export const LegacyToastPanel: React.FC<{ notifications: ToastDetails[] }> = ({
  notifications,
}) => {
  return notifications.length ? (
    <div className={styles.notificationPanel}>
      {notifications.map(notification => (
        <Toast notification={notification} key={notification.createdAt} />
      ))}
    </div>
  ) : null;
};

export default function NotificationCardPanel() {
  const { notifications, panelHasReducedOffset } = useToastContext();
  const { isLettuceTheme } = useThemeContext();
  if (!isLettuceTheme) {
    return <LegacyToastPanel notifications={notifications} />;
  }

  return notifications.length ? (
    <div
      className={clsx(styles.notificationPanel, { [styles.reducedOffset]: panelHasReducedOffset })}
    >
      {notifications.map(notification => (
        <NotificationCard
          key={notification.createdAt}
          {...notification}
          state={ToastStateToNotificationState[notification.state]}
          notificationType={
            (notification.ariaLabel && ToastAriaLabelToNotificationType[notification.ariaLabel]) ||
            NotificationType.Info
          }
          isAutoDismissed={!!notification.autoDismissAfterMs}
        />
      ))}
    </div>
  ) : null;
}
