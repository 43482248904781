import React, { ReactNode } from 'react';
import clsx from 'clsx';
import styles from './index.module.css'; // eslint-disable-line

export const BackdropTestId = 'backdrop';

const BackdropVariants = ['transparent', 'translucent', 'darken'] as const;
export type BackdropVariant = (typeof BackdropVariants)[number];

type BackdropProps = {
  children?: ReactNode;
  variant?: BackdropVariant;
} & React.HTMLAttributes<HTMLDivElement>;

const Backdrop = React.forwardRef<HTMLDivElement, BackdropProps>(function Backdrop(
  { className, variant = 'translucent', children = null, ...divProps },
  ref,
) {
  return (
    <div
      ref={ref}
      {...divProps}
      className={clsx(styles.backdrop, styles[variant], className)}
      data-testid={BackdropTestId}
    >
      {children}
    </div>
  );
});

export default Backdrop;
