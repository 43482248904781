import React from 'react';
import clsx from 'clsx';
import { FCC } from '../../../../utils/types';
import style from './FullWidthLayout.module.css';

type StandardLayoutProps = {
  className?: string;
};

const FullWidthLayout: FCC<StandardLayoutProps> = ({ children, className }) => {
  return <div className={clsx(style.body, className)}>{children}</div>;
};

export default FullWidthLayout;
