import React from 'react';
import clsx from 'clsx';
import styles from './MainContent.module.css'; // eslint-disable-line

export enum ContentWidth {
  Wide = 'width-wide',
  Default = 'width-default',
  Floating = 'width-floating',
  FloatingNoMargin = 'width-floating-no-margin',
}

export function MainContent({
  children,
  width = ContentWidth.Default,
}: {
  children: React.ReactNode;
  width?: ContentWidth;
}) {
  return <div className={clsx(styles.main, styles[width])}>{children}</div>;
}
