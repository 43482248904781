import React, { Dispatch, useContext, useEffect, useMemo } from 'react';
import { noop } from '@tellurian/ts-utils';
import { FCC } from '../../../../utils/types';
import usePersistentState from '../../../../utils/memoryStorage/usePersistentState';
import { MemoryStorageKey } from '../../../../utils/memoryStorage';
import { useToastContext } from '../../../Toast/ToastContext';

/**
 * This triple of booleans represents a state subset of the NavRail which is to be persisted
 * for the duration of a browsing session (i.e. until the user closes/reloads the browser tab).
 * The requirement is necessary due to pages being lazy loaded and the NavRail being inherent
 * to each page template.
 * The state progression is as follows:
 * 1. When the NavRail content has completed loading (some menu items are predicated by async retrieved flags)
 * the NavRail enters { isNavRailReady: true, isCompact: false, hasDeterminedIsCompact: false }.
 * 2. From the state above, we determine if the NavRail can render in the viewport available. When this
 * determination succeeds, the hasDeterminedIsCompact is set to true and isCompact is set to true/false.
 * At this point the page template can render both the NavRail and the page content.
 * The mechanism is employed to quick state updates resulting in visual adjustments for both the NavRail
 * and page content.
 */
export type PersistentNavRailState = {
  isNavRailReady: boolean;
  isCompact: boolean;
  hasDeterminedIsCompact: boolean;
};

type NavRailContextInterface = {
  isNavRailReady: boolean;
  isCompact: boolean;
  hasDeterminedIsCompact: boolean;
  setIsCompact: Dispatch<boolean>;
  setHasDeterminedIsCompact: Dispatch<boolean>;
  setIsNavRailReady: Dispatch<boolean>;
};

export const NavRailContextBaseValue: Readonly<NavRailContextInterface> = {
  isNavRailReady: false,
  isCompact: false,
  hasDeterminedIsCompact: false,
  setIsCompact: noop,
  setHasDeterminedIsCompact: noop,
  setIsNavRailReady: noop,
};

export const NavRailContext = React.createContext<NavRailContextInterface>(NavRailContextBaseValue);

export type NavRailContextProviderProps = {
  isInitiallyReady?: boolean;
  SubsidiaryContext?: FCC;
};

export const NavRailContextProvider: FCC<NavRailContextProviderProps> = ({
  children,
  isInitiallyReady = true,
  SubsidiaryContext,
}) => {
  const [navRailState, setNavRailState] = usePersistentState(MemoryStorageKey.NavRailState, {
    isCompact: false,
    isNavRailReady: isInitiallyReady,
    hasDeterminedIsCompact: false,
  });
  const stateSetters = useMemo(() => {
    return {
      setIsCompact: (nextIsCompact: boolean) =>
        setNavRailState(current => ({ ...current, isCompact: nextIsCompact })),
      setIsNavRailReady: (nextIsNavRailReady: boolean) =>
        setNavRailState(current => ({ ...current, isNavRailReady: nextIsNavRailReady })),
      setHasDeterminedIsCompact: (nextHasDeterminedIsCompact: boolean) =>
        setNavRailState(current => ({
          ...current,
          hasDeterminedIsCompact: nextHasDeterminedIsCompact,
        })),
    };
  }, [setNavRailState]);

  const { setPanelHasReducedOffset } = useToastContext();
  const { isCompact } = navRailState;
  useEffect(() => setPanelHasReducedOffset(isCompact), [isCompact, setPanelHasReducedOffset]);

  return (
    <NavRailContext.Provider
      value={{
        ...navRailState,
        ...stateSetters,
      }}
    >
      {SubsidiaryContext ? <SubsidiaryContext>{children}</SubsidiaryContext> : children}
    </NavRailContext.Provider>
  );
};

export const useNavRailContext = () => useContext(NavRailContext);
export const useIsCompactNavRail = () => useNavRailContext().isCompact;
