import React, { useEffect, useRef, useState } from 'react';

export const getIsOverflowing = (el: HTMLDivElement): boolean => el.scrollWidth > el.clientWidth;

export const getIsAtScrollEnd = (el: HTMLDivElement): boolean =>
  // Depending on the browser view width on retina screens, the maximum scrollLeft is sometimes reported
  // 1px lower than actual, therefore the (not ideal) +1 scrollLeft addition
  el.scrollWidth - (el.scrollLeft + 1) <= el.clientWidth;

export const getIsAtScrollStart = (el: HTMLDivElement): boolean => el.scrollLeft === 0;

export type UseIsOverflowing = {
  containerRef: React.RefObject<HTMLDivElement>;
  isAtScrollEnd: boolean;
  isAtScrollStart: boolean;
  isOverflowing: boolean;
  onScroll: () => void;
  scrollLeft: () => void;
  scrollRight: () => void;
};

export default function useIsOverflowing(): UseIsOverflowing {
  const [isAtScrollEnd, setIsAtScrollEnd] = useState(false);
  const [isAtScrollStart, setIsAtScrollStart] = useState(true);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      setIsOverflowing(getIsOverflowing(containerRef.current));
    }
  }, []);

  useEffect(() => {
    const eventType = 'resize';
    const onResize = () => {
      if (containerRef.current) {
        setIsOverflowing(getIsOverflowing(containerRef.current));
      }
    };

    window.addEventListener(eventType, onResize);

    return () => window.removeEventListener(eventType, onResize);
  }, [containerRef]);

  const onScroll = () => {
    if (containerRef.current) {
      setIsAtScrollEnd(getIsAtScrollEnd(containerRef.current));
      setIsAtScrollStart(getIsAtScrollStart(containerRef.current));
    }
  };

  const scrollLeft = () => {
    const el = containerRef.current;
    if (el) {
      el.scrollLeft = el.scrollLeft - el.clientWidth;
    }
  };

  const scrollRight = () => {
    const el = containerRef.current;
    if (el) {
      el.scrollLeft = el.scrollLeft + el.clientWidth;
    }
  };

  return {
    containerRef,
    isAtScrollEnd,
    isAtScrollStart,
    isOverflowing,
    onScroll,
    scrollLeft,
    scrollRight,
  };
}
