import React from 'react';
import { Placement } from '@popperjs/core';
import { TextArea, TextAreaProps } from '../TextArea';
import { TooltipContent } from '../../Tooltip';
import { Field, FieldProps } from './index';

export type TextAreaFieldProps = Omit<FieldProps, 'children'> &
  Omit<TextAreaProps, 'className' | 'hasError'> & {
    tooltipContent?: TooltipContent;
    tooltipPlacement?: Placement;
  };

export const TextAreaField: React.FC<TextAreaFieldProps> = ({
  id,
  label,
  description,
  errorMessage,
  className,
  hidden,
  tooltipContent,
  tooltipPlacement,
  ...textAreaProps
}) => {
  const fieldProps = {
    id,
    label,
    description,
    errorMessage,
    className,
    hidden,
    tooltipContent,
    tooltipPlacement,
  };
  return (
    <Field {...fieldProps}>
      <TextArea id={id} hasError={!!errorMessage} {...textAreaProps} />
    </Field>
  );
};
