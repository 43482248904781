import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { ContentWidth } from '../../../ui';
import { useUnfiAuthenticationContext } from '../auth/UnfiAuthenticationProvider';
import useRecentlyViewedItems from '../Home/useRecentlyViewedItems';
import { UnfiEmbeddedLookerContextProvider } from '../UnfiEmbeddedLookerContext';
import UnfiInsightsEmbeddedTemplate from '../templates/UnfiInsightsEmbeddedTemplate';
import { lazyWithRetry } from '../../../lib';
import Dashboard from '../Dashboard/Dashboard';

type MatchParams = {
  dashboardGroupId?: string;
  dashboardId?: string;
};

const RenderDashboard = lazyWithRetry<typeof Dashboard>(
  () => import(/* webpackChunkName: "unfiInsightsEmbeddDashboardPage" */ '../Dashboard/Dashboard'),
);

export default function UnfiInsightsEmbeddedDashboardsPage({
  match: {
    params: { dashboardGroupId, dashboardId },
  },
}: RouteComponentProps<MatchParams>) {
  const { add: addRecentlyViewedItem } = useRecentlyViewedItems();

  useEffect(
    () => addRecentlyViewedItem({ dashboardGroupId, dashboardId }),
    [dashboardGroupId, dashboardId, addRecentlyViewedItem],
  );

  const { accessToken } = useUnfiAuthenticationContext();

  return (
    <UnfiInsightsEmbeddedTemplate mainContentWidth={ContentWidth.FloatingNoMargin}>
      <UnfiEmbeddedLookerContextProvider accessToken={accessToken}>
        <RenderDashboard dashboardGroupId={dashboardGroupId} dashboardId={dashboardId} />
      </UnfiEmbeddedLookerContextProvider>
    </UnfiInsightsEmbeddedTemplate>
  );
}
