import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Button, ButtonSize, ButtonStyle, ButtonType } from '../../Button';
import * as ValidationRules from '../validation';
import { ValidationFn } from '../validation';
import { Input, InputPrimaryProps } from '../Input';
import { EntityList, EntityListItem } from '../../../EntityList';
import styles from '../styles.module.css'; // eslint-disable-line
import { filterMax } from '../lib';
import ControlledListOfOptions from '../../lists/ControlledListOfOptions';
import useListOptionFocusWithControlHandlers from '../../lists/useListOptionFocusWithControlHandlers';
import { OverlayContainer, OverlayPosition } from '../../Dropdown';
import { Field, FieldError, InputFieldProps } from './index';

const MAX_AUTOSUGGEST_COUNT = 10;

const NoItems = () => <>No items specified.</>;

export type InputAddToListFieldProps = Omit<InputFieldProps, 'type' | 'value' | 'onChange'> & {
  type?: 'text' | 'email' | 'url';
  addToListButtonLabel?: string;
  addToListButtonStyle?: ButtonStyle;
  RenderEntityListItem?: (props: {
    children: string;
    onDelete: (item: string) => void;
    actionsDisabled: boolean;
  }) => ReactElement;
  RenderNoItems?: React.FC;
  validateItem?: ValidationFn<string> | ValidationFn<string>[];
  distinctValidationMessage?: string;
  itemsAreEqual?: (item1: string, item2: string) => boolean;
  autoSuggestPool?: string[];
} & InputPrimaryProps<string[]>;

export const InputAddToListField: React.FC<InputAddToListFieldProps> = ({
  addToListButtonLabel = 'Add',
  addToListButtonStyle = ButtonStyle.BORDER,
  id,
  label,
  description,
  errorMessage,
  className,
  hidden,
  RenderEntityListItem = EntityListItem,
  RenderNoItems = NoItems,
  onChange,
  value,
  validateItem,
  distinctValidationMessage = 'The value is already included.',
  itemsAreEqual = Object.is,
  tooltipContent,
  tooltipPlacement,
  autoSuggestPool,
  ...inputProps
}) => {
  const fieldProps = {
    id,
    label,
    description,
    errorMessage,
    className,
    hidden,
    tooltipContent,
    tooltipPlacement,
  };
  const [items, setItems] = useState<string[]>(value);
  const [itemError, setItemError] = useState('');
  const [inputValue, setInputValue] = useState<string>('');
  const [autoSuggestions, setAutoSuggestions] = useState<string[]>([]);

  const onSelectAutoSuggestion = index => {
    setInputValue(autoSuggestions[index]);
    setAutoSuggestions([]);
  };

  const { onKeyDown, focusedIndex, onFocusedIndexChange } = useListOptionFocusWithControlHandlers({
    onSelect: onSelectAutoSuggestion,
    optionCount: autoSuggestions.length,
    initialFocusedIndex: 0,
  });

  const validate = useMemo(() => {
    const distinctValidationRule = ValidationRules.distinct<string>(
      e => items.some(it => itemsAreEqual(e, it)),
      distinctValidationMessage,
    );
    return Array.isArray(validateItem)
      ? [...validateItem, distinctValidationRule]
      : ([validateItem, distinctValidationRule].filter(Boolean) as ValidationFn<string>[]);
  }, [items, validateItem, distinctValidationMessage, itemsAreEqual]);

  const clearError = useCallback(() => {
    setItemError('');
  }, []);

  const getValidationResult = useCallback(
    item =>
      validate.reduce<string | true>((res, v) => (typeof res === 'string' ? res : v(item)), true),
    [validate],
  );

  const addItem = useCallback(
    item => {
      if (item) {
        const validationResult = getValidationResult(item);
        if (validationResult === true) {
          clearError();
          const nextItems = [item, ...items];
          setItems(nextItems);
          onChange?.(nextItems);
          setInputValue('');
        } else {
          setItemError(validationResult);
        }
      } else {
        clearError();
      }
      setAutoSuggestions([]);
    },
    [items, onChange, clearError, getValidationResult],
  );

  const revalidate = useCallback(
    (value: string) => {
      if (itemError) {
        if (value) {
          const validationResult = getValidationResult(value);
          if (validationResult === true) {
            clearError();
            return;
          }

          setItemError(validationResult);
        } else {
          clearError();
        }
      }
    },
    [itemError, getValidationResult, clearError],
  );

  const removeItem = useCallback(
    item => {
      const nextItems = items.filter(it => it !== item);
      setItems(nextItems);
      onChange?.(nextItems);
    },
    [items, onChange],
  );

  const onInputChange = useCallback(
    (value: string) => {
      setInputValue(value);
      revalidate(value);
      onFocusedIndexChange(0);

      const autoSuggestResults = filterMax(
        autoSuggestPool ?? [],
        auto => !!value && auto.toLowerCase().startsWith(value.toLowerCase()),
        MAX_AUTOSUGGEST_COUNT,
      );
      if (autoSuggestResults) {
        setAutoSuggestions(autoSuggestResults);
      } else {
        setAutoSuggestions([]);
      }
    },
    [revalidate, autoSuggestPool, onFocusedIndexChange],
  );

  useEffect(() => {
    setItems(value);
  }, [value]);

  useEffect(() => {
    // Distinct reference set will have changed so item can now be valid
    revalidate(inputValue);
  }, [revalidate, inputValue]);

  return (
    <>
      <Field {...fieldProps}>
        <div className={styles.textSubmitContainer}>
          <div>
            <Input
              {...inputProps}
              id={id}
              value={inputValue}
              onChange={onInputChange}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  if (autoSuggestions.length === 0) {
                    addItem(inputValue);
                  } else {
                    addItem(autoSuggestions[focusedIndex]);
                  }
                  event.preventDefault();
                  event.stopPropagation();
                } else {
                  onKeyDown(event);
                }
              }}
              onBlur={() => {
                setAutoSuggestions([]);
              }}
            />
            {autoSuggestions.length > 0 && (
              <OverlayContainer
                onClose={() => {
                  setAutoSuggestions([]);
                }}
                overlayPosition={OverlayPosition.Right}
                closeOnContentClick={true}
                className={styles.autoSuggestContainer}
              >
                <ControlledListOfOptions
                  options={autoSuggestions}
                  focusedIndex={focusedIndex}
                  onFocusedIndexChange={onFocusedIndexChange}
                  onSelect={onSelectAutoSuggestion}
                />
              </OverlayContainer>
            )}
          </div>
          <div>
            <Button
              btnStyle={addToListButtonStyle}
              btnType={ButtonType.Button}
              onClick={() => addItem(inputValue)}
              btnSize={ButtonSize.L}
              disabled={inputProps.disabled || !inputValue?.length}
            >
              {addToListButtonLabel}
            </Button>
          </div>
        </div>

        {itemError && <FieldError error={itemError} />}
      </Field>
      <div className={clsx({ [styles.hidden]: fieldProps.hidden })}>
        {items.length ? (
          <EntityList>
            {items.map(item => (
              <RenderEntityListItem
                key={item}
                onDelete={() => removeItem(item)}
                actionsDisabled={!!inputProps.disabled}
              >
                {item}
              </RenderEntityListItem>
            ))}
          </EntityList>
        ) : (
          <div className="mod">
            <RenderNoItems />
          </div>
        )}
      </div>
    </>
  );
};
