import { extendPluralizeRules } from './components/lib';
import initDatadog from './services/initDatadog';

const setupFaviconSwitch = () => {
  const favicon = document.querySelector('link[rel="icon"]');
  if (favicon) {
    const setFavicon = () => {
      const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
      const href = isDarkMode ? '/favicons/favicon-dark-32x32.png' : '/favicons/favicon-32x32.png';
      favicon.setAttribute('href', href);
    };
    setFavicon();
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', setFavicon);
  }
};

const setup = (() => {
  let hasRun = false;
  return () => {
    if (hasRun) {
      console.warn('Setup routine was called more than once. This is not intended.');
      return;
    }
    // The following functions should only execute once per session.
    extendPluralizeRules();
    initDatadog();
    setupFaviconSwitch();
    hasRun = true;
  };
})();

export default setup;
