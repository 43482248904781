/*
 * Formats that don't have to be locale specific
 */
import { Locale } from './Locale';

export const fnsFormat = {
  ISODateTimeWOZ: "yyyy-MM-dd'T'HH:mm:ss",
  ISODate: 'yyyy-MM-dd',
};

export enum FnsFormat {
  DateShort,
  DateYearShort,
  DateTimeShort,
  DateAtTimeShort,
  DateYearTimeShort,
  DateYearAtTimeShort,
  DayDateAbbreviated,
  DayDateLong,
  DayDateYearAbbreviated,
  DayOfWeekDateShort,
  DateYearLong,
}

export const FnsFormats = (locale: Locale): Record<FnsFormat, string> => {
  switch (locale) {
    case Locale.enUS:
      return {
        [FnsFormat.DateShort]: 'M/d',
        [FnsFormat.DateYearShort]: 'M/d/yy',
        [FnsFormat.DateYearLong]: 'M/d/yyyy',
        [FnsFormat.DateTimeShort]: 'M/d p',
        [FnsFormat.DateAtTimeShort]: "M/d 'at' p",
        [FnsFormat.DateYearTimeShort]: 'M/d/yy p',
        [FnsFormat.DateYearAtTimeShort]: "M/d/yy 'at' p",
        [FnsFormat.DayDateAbbreviated]: 'EEE MMM d',
        [FnsFormat.DayDateLong]: 'EEEE MMMM do',
        [FnsFormat.DayDateYearAbbreviated]: 'EEE MMM d, yyyy',
        [FnsFormat.DayOfWeekDateShort]: 'EEEE, M/d',
      };

    default:
      return {
        [FnsFormat.DateShort]: 'dd.MM',
        [FnsFormat.DateYearShort]: 'd.MM.yy',
        [FnsFormat.DateYearLong]: 'd.MM.yyyy',
        [FnsFormat.DateTimeShort]: 'dd.MM p',
        [FnsFormat.DateAtTimeShort]: "dd.MM 'at' p",
        [FnsFormat.DateYearTimeShort]: 'dd.MM.yy p',
        [FnsFormat.DateYearAtTimeShort]: "dd.MM.yy 'at' p",
        [FnsFormat.DayDateAbbreviated]: 'EEE d. MMM',
        [FnsFormat.DayDateLong]: 'EEEE d. MMMM',
        [FnsFormat.DayDateYearAbbreviated]: 'EEE d. MMM yyyy',
        [FnsFormat.DayOfWeekDateShort]: 'EEEE dd.MM',
      };
  }
};
