import React from 'react';
import { Maybe } from '@tellurian/ts-utils';
import { ActionableListProps } from '../../../ui/lists/listOfItems/lib';
import ListOfItems, { ListOfItemsProps } from '../../../ui/lists/listOfItems/ListOfItems';
import { DefaultPopoverMaxHeight } from '../lib/useDropdown';
import { useDefaultHashId } from '../../../lib';
import { ChipSelect, ChipSelectProps } from '../Chip';
import Popover from '../lib/Popover';
import Backdrop from '../Backdrop';
import useDropdownListOfItemsActionable, {
  UseDropdownListOfItemsSelectParams,
} from '../lib/useDropdownListOfItemsSelect';
import FloatingPortal from '../lib/FloatingPortal';
import { useMenuEventListener } from '../../crisp/lib';
import style from './ChipDropdownSelect.module.css';

// Sync the list max-height with popover max-height to facilitate overflow auto. This will not work if a dynamic
// strategy is adopted for the popover height and a different sync mechanism will be required.
const ListStyle = Object.freeze({
  maxHeight: DefaultPopoverMaxHeight,
});

export type ChipDropdownSelectProps<T> = UseDropdownListOfItemsSelectParams<T> & {
  getChipText: (selectedItem: Maybe<T>) => string;
  listId?: string;
  chipSize?: ChipSelectProps['size'];
  IconLeft?: ChipSelectProps['IconLeft'];
  backdrop?: boolean;
  id?: string;
} & Pick<ListOfItemsProps<T, ActionableListProps>, 'getItemKey' | 'RenderItem'>;

/**
 * A generic dropdown component with a Chip as trigger.
 * Use with list items of a custom type (e.g. with list item containing an icon and various pieces of information, toggles etc)
 * or as a base component for more specific types (e.g. list with numbers or name/value pairs)
 */
function ChipDropdownSelect<T>({
  selectedItem,
  getChipText,
  listId,
  getItemKey,
  chipSize,
  IconLeft,
  RenderItem,
  backdrop = false,
  role = 'listbox',
  id,
  ...dropdownProps
}: ChipDropdownSelectProps<T>) {
  listId = useDefaultHashId(listId);
  const { getReferenceProps, getPopoverProps, listProps, isActive, setIsActive } =
    useDropdownListOfItemsActionable({
      ...dropdownProps,
      selectedItem,
      role,
      stopKeyDownPropagation: true,
    });
  const className = isActive && backdrop ? 'overBackdrop' : undefined;
  useMenuEventListener(id, setIsActive);

  return (
    <>
      {isActive && backdrop && <Backdrop variant="transparent" />}
      <ChipSelect
        id={id}
        text={getChipText(selectedItem)}
        size={chipSize}
        IconLeft={IconLeft}
        className={className}
        {...getReferenceProps()}
      />
      {isActive && (
        <FloatingPortal>
          <Popover
            className={className}
            // If a backdrop is used, then use zIndex provided by className instead of the default one from getPopoverProps
            {...getPopoverProps(backdrop ? { zIndex: undefined } : undefined)}
          >
            <ListOfItems<T, ActionableListProps>
              {...listProps}
              RenderItem={RenderItem}
              className={style.list}
              id={listId}
              getItemKey={getItemKey}
              style={ListStyle}
              role="none"
            />
          </Popover>
        </FloatingPortal>
      )}
    </>
  );
}

export default ChipDropdownSelect;
