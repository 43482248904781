import { useEffect } from 'react';
import { Maybe } from '@tellurian/ts-utils';
import useEventCallback from '../../utils/useEventCallback';

export const PageErrorEventType = 'page-error';

export enum PageError {
  FeatureFlagError = 'FeatureFlagError',
  Forbidden = 'Forbidden',
  NetworkError = 'NetworkError',
  NotFound = 'NotFound',
  ServerError = 'ServerError',
  ServiceUnavailable = 'ServiceUnavailable',
  UnknownError = 'UnknownError',
}

const HttpStatusToPageError: Readonly<Record<number, PageError>> = {
  401: PageError.Forbidden,
  403: PageError.Forbidden,
  404: PageError.NotFound,
  500: PageError.ServerError,
  501: PageError.ServiceUnavailable,
  502: PageError.ServiceUnavailable,
};

export const fromHttpStatus = (status: Maybe<number>): PageError => {
  if (status) {
    return HttpStatusToPageError[status] ?? PageError.UnknownError;
  }

  return PageError.UnknownError;
};

export const pageErrorEvent = (error: PageError) =>
  new CustomEvent(PageErrorEventType, { detail: error });

export const dispatchPageErrorEvent = (error: PageError) =>
  window.dispatchEvent(pageErrorEvent(error));

export const dispatchHttpErrorEvent = (status?: number) =>
  dispatchPageErrorEvent(fromHttpStatus(status));

export const usePageErrorListener = (onPageError: (pageError: PageError) => void) => {
  const onPageErrorCallback = useEventCallback<[Event], void>(event => {
    onPageError((event as CustomEvent<PageError>).detail);
  });
  useEffect(() => {
    window.addEventListener(PageErrorEventType, onPageErrorCallback);
    return () => window.removeEventListener(PageErrorEventType, onPageErrorCallback);
  }, [onPageErrorCallback]);
};
