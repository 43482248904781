// Do not move this to the partner folder as it will cause a circular dependency
export enum PartnerType {
  Dot = 'dot',
}

export type PartnerAccount = { partnerAccountId: string; partnerType: PartnerType };
export type PartnerAccountWithType = { type: 'partner' } & PartnerAccount;

export type Account = { type: 'platform'; accountId: string } | PartnerAccountWithType;

export const platformAccount = (accountId: string): Account => ({ type: 'platform', accountId });
export const partnerAccount = (
  partnerAccountId: string,
  partnerType: PartnerType,
): PartnerAccountWithType => ({
  type: 'partner',
  partnerAccountId,
  partnerType,
});
