import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import {
  mouseEventHandlerFactory,
  MouseEventHandlerFactoryParams,
  MouseEventHandlers,
} from './ControlledListOfOptions/lib';
import getDefaultListKeyHandler, {
  GetListKeyHandler,
  GetListKeyHandlerParams,
  OnKeyDownHandler,
} from './getListKeyHandler';

export type UseListOptionControlHandlersProps = {
  focusedIndex?: number;
  setFocusedIndex: Dispatch<SetStateAction<number>>;
  setIsFocusChangedByKeyboardEvent: Dispatch<SetStateAction<boolean>>;
  getListKeyHandler?: GetListKeyHandler;
} & Pick<GetListKeyHandlerParams, 'onSelect' | 'optionCount'> &
  Pick<MouseEventHandlerFactoryParams, 'isListFocused'>;

export type UseListOptionControlHandlers = {
  onKeyDown: OnKeyDownHandler;
  createOptionMouseEventHandlers: (index: number) => MouseEventHandlers;
};

const useListOptionControlHandlers = ({
  focusedIndex = 0,
  setFocusedIndex,
  getListKeyHandler = getDefaultListKeyHandler,
  onSelect,
  optionCount,
  isListFocused,
  setIsFocusChangedByKeyboardEvent,
}: UseListOptionControlHandlersProps): UseListOptionControlHandlers => {
  const [ignoreFocusOnMouseEnter, setIgnoreFocusOnMouseEnter] = useState(false);
  const handleKeyDown = useMemo(() => {
    const onFocusedIndexChange = index => {
      setIsFocusChangedByKeyboardEvent(true);
      setFocusedIndex(index);
    };
    return getListKeyHandler({
      focusedIndex,
      onFocusedIndexChange,
      onSelect,
      optionCount,
    });
  }, [
    focusedIndex,
    setFocusedIndex,
    onSelect,
    optionCount,
    getListKeyHandler,
    setIsFocusChangedByKeyboardEvent,
  ]);

  // There is a circumstance where we should not focus on mouse enter: when the focus element
  // changes the view, it will cause elements to change location and may come under the mouse cursor
  // which will trigger then onMouseEnter event.
  const onKeyDown: OnKeyDownHandler = useCallback(
    e => {
      setIgnoreFocusOnMouseEnter(true);
      return handleKeyDown(e);
    },
    [handleKeyDown],
  );

  const createOptionMouseEventHandlers = useMemo(() => {
    const onFocusChange = index => {
      setIsFocusChangedByKeyboardEvent(false);
      setFocusedIndex(index);
    };
    return mouseEventHandlerFactory({
      ignoreFocusOnMouseEnter,
      setIgnoreFocusOnMouseEnter,
      onFocusChange,
      onSelect,
      isListFocused,
    });
  }, [
    ignoreFocusOnMouseEnter,
    setFocusedIndex,
    onSelect,
    isListFocused,
    setIsFocusChangedByKeyboardEvent,
  ]);

  return {
    onKeyDown,
    createOptionMouseEventHandlers,
  };
};

export default useListOptionControlHandlers;
