import React from 'react';
import AccountManagerTemplate from '../pageTemplates/AccountManagerTemplate';
import TermsOfServiceVersions from '../components/TermsOfServiceVersions';

export default function TermsOfServiceVersionsPage() {
  return (
    <AccountManagerTemplate requireGlobalAdmin={true}>
      <TermsOfServiceVersions />
    </AccountManagerTemplate>
  );
}
