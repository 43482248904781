import React, { useEffect, useRef, useState } from 'react';
import { MsalProvider, useMsal } from '@azure/msal-react';
import { InteractionStatus, IPublicClientApplication } from '@azure/msal-browser';
import { getAuth, signOut } from 'firebase/auth';

const Logout = () => {
  const { instance, inProgress } = useMsal();
  const azureLogoutInProgress = useRef(false);
  const [loggedOut, setLoggedOut] = useState(false);

  useEffect(() => {
    // Wait for MSAL instance to become ready and ensure we're only logging out once.
    if (!azureLogoutInProgress.current && inProgress === InteractionStatus.None) {
      azureLogoutInProgress.current = true;
      // We are only clearing the local MSAL cache here.
      instance.logoutRedirect({
        onRedirectNavigate: () => {
          // Logout Firebase user.
          signOut(getAuth()).then(() => setLoggedOut(true));
          return false;
        },
      });
    }
  }, [inProgress, instance]);

  return <>{loggedOut ? <div>Logged out</div> : <div>Logging out...</div>}</>;
};

type UnfiInsightsEmbeddedLogoutProps = {
  instance: IPublicClientApplication;
};

const UnfiInsightsEmbeddedLogout: React.FC<UnfiInsightsEmbeddedLogoutProps> = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
      <Logout />
    </MsalProvider>
  );
};

export default UnfiInsightsEmbeddedLogout;
