import { useMemo, useRef, useState } from 'react';
import { Maybe } from '@tellurian/ts-utils';
import useEventCallback from '../../../utils/useEventCallback';

export type Features<T> = {
  search: boolean | ((items: T[]) => boolean);
  clear: boolean;
  selectAll: boolean;
};

export const DefaultFeatures: Features<unknown> = {
  search: items => items.length > 18,
  clear: true,
  selectAll: true,
};

export type UseMultiSelectItemsProps<T, V = T> = {
  items: T[];
  selection?: V[];
  onChange: (nextSelection: V[]) => void;
  features?: Partial<Features<T>>;
  searchMatch?: (query: string, item: T) => boolean | number;
  getItemValue: (item: T) => V;
};

const useMultiSelectItems = <T, V = T>({
  items,
  selection,
  onChange: onChangeProp,
  features,
  searchMatch,
  getItemValue,
}: UseMultiSelectItemsProps<T, V>) => {
  const { selectAll, clear, search } = { ...DefaultFeatures, ...features };
  const lastSelectionChangedRef = useRef<Maybe<V[]>>();
  const lastSelectedItemsRef = useRef<Maybe<T[]>>();
  const [query, setQuery] = useState('');

  const { isSearchEnabled, filteredItems } = useMemo(() => {
    const isSearchEnabled =
      (search === true || (typeof search === 'function' && search(items))) && searchMatch;

    const res = { items, isSearchEnabled, filteredItems: items };
    if (isSearchEnabled && query && searchMatch) {
      const q = query.trim().toLowerCase();
      res.filteredItems = items.filter(item => searchMatch(q, item));
    }

    return res;
  }, [items, search, query, searchMatch]);

  const isSearchActive = isSearchEnabled && query.length > 0;

  const onToggleAll = useMemo(() => {
    if (selectAll) {
      return (shouldSelect: boolean) => {
        if (isSearchActive) {
          if (shouldSelect) {
            const filteredValues = filteredItems.map(getItemValue);
            onChangeProp([
              ...new Set(selection ? [...selection, ...filteredValues] : filteredValues),
            ]);
          } else {
            if (selection) {
              onChangeProp(
                selection.filter(
                  value => !filteredItems.some(item => Object.is(value, getItemValue(item))),
                ),
              );
            }
          }
        } else {
          onChangeProp(shouldSelect ? items.map(getItemValue) : []);
        }
      };
    }
  }, [selectAll, items, filteredItems, onChangeProp, selection, isSearchActive, getItemValue]);

  const onClear = useMemo(() => {
    return clear ? () => onChangeProp([]) : undefined;
  }, [clear, onChangeProp]);

  const selectedItems = useMemo(
    () =>
      selection === undefined
        ? []
        : lastSelectedItemsRef.current && selection === lastSelectionChangedRef.current
          ? lastSelectedItemsRef.current
          : items.filter(item => selection.includes(getItemValue(item))),
    [selection, items, getItemValue],
  );

  const onChange = useEventCallback((nextItemSelection: T[]) => {
    lastSelectedItemsRef.current = nextItemSelection;
    const nextSelectedValues = nextItemSelection.map(getItemValue);
    lastSelectionChangedRef.current = nextSelectedValues;
    onChangeProp(nextSelectedValues);
  });

  const allItemsSelected = useMemo(() => {
    if (isSearchActive) {
      return filteredItems.every(filteredItem => {
        const value = getItemValue(filteredItem);
        return selectedItems.some(selectedItem => Object.is(value, getItemValue(selectedItem)));
      });
    }

    return filteredItems.length === selectedItems.length;
  }, [isSearchActive, filteredItems, selectedItems, getItemValue]);

  return {
    allItemsSelected,
    items: filteredItems,
    selectedItems,
    onChange,
    onSearch: isSearchEnabled ? setQuery : undefined,
    onToggleAll,
    onClear,
  };
};

export default useMultiSelectItems;
