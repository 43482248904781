import React from 'react';
import clsx from 'clsx';
import { Col, Grid, GridType, GridWrap } from '../';
import { HorizontalListProvider, useHorizontalList } from './Context';
import styles from './index.module.css';

type HorizontalItemProps = {
  children: React.ReactNode;
  className?: string;
};

export function HorizontalItem({ children, className }: HorizontalItemProps) {
  const { itemWidth } = useHorizontalList();

  return (
    <Col className={clsx(styles.item, className)} width={itemWidth}>
      {children}
    </Col>
  );
}

type HorizontalPaneProps = {
  children: React.ReactNode;
  className?: string;
  gridType?: GridType;
  maxItemsPerRow?: number;
};

export function HorizontalPane({
  children,
  className,
  gridType,
  maxItemsPerRow,
}: HorizontalPaneProps) {
  return (
    <Grid
      className={clsx(styles.pane, className)}
      type={gridType}
      wrap={maxItemsPerRow ? GridWrap.WRAP : GridWrap.NOWRAP}
    >
      <HorizontalListProvider maxItemsPerRow={maxItemsPerRow}>{children}</HorizontalListProvider>
    </Grid>
  );
}
