import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { BookmarkFragmentWithDashboardInfo } from '../../../../BookmarksContext';
import TextFormField from '../../../components/formFields/TextFormField';
import useEventCallback from '../../../utils/useEventCallback';
import { requiredField } from '../../../../ui/form/validation';
import SaveModal from '../../../components/Modal/SaveModal';
import RenderReportMetadata from './RenderReportMetadata';
import ReportDetails from './ReportDetails';
import ReportAccessDetails from './ReportAccessDetails';
import useUpdateSavedReport from './useUpdateSavedReport';

type RenameReportModalProps = {
  accountId: string;
  report: BookmarkFragmentWithDashboardInfo;
  onClose: () => void;
};

type FormValues = { description: string };

const RenameReportModal: React.FC<RenameReportModalProps> = ({ onClose, report, accountId }) => {
  const [updateBookmark, { loading: isSaving }] = useUpdateSavedReport({
    accountId,
    dashboardGroupId: report.dashboardGroupId,
    dashboardId: report.dashboardId,
    onCompleted: onClose,
  });

  const onSubmit = useEventCallback(({ description }: FormValues) => {
    updateBookmark({
      description,
      bookmarkId: report.id,
    });
  });

  const useFormMethods = useForm<FormValues>({
    defaultValues: {
      description: report.description,
    },
  });

  return (
    <SaveModal
      title="Rename report"
      onCloseClick={onClose}
      onSave={useFormMethods.handleSubmit(onSubmit)}
      isSaving={isSaving}
      size="S"
    >
      <div className="mvm">
        <FormProvider {...useFormMethods}>
          <TextFormField
            name="description"
            label="Report name"
            disabled={isSaving}
            validate={requiredField}
            isFocused={true}
          />
        </FormProvider>
        <RenderReportMetadata report={report} />
        <ReportAccessDetails report={report} />
        <ReportDetails report={report} maxItemsVisible={5} />
      </div>
    </SaveModal>
  );
};

export default RenameReportModal;
