import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { CellContentAlign } from './lib';
import styles from './CellInlineContentWrapper.module.css';

type Props = { children: ReactNode; alignContent?: CellContentAlign; noPointerEvents?: boolean };

const ContentAlignClassNames = Object.freeze({
  [CellContentAlign.LEFT]: styles.alignLeft,
  [CellContentAlign.RIGHT]: styles.alignRight,
  [CellContentAlign.CENTER]: styles.alignCenter,
});

export default React.forwardRef(function CellInlineContentWrapper(
  { children, alignContent, noPointerEvents }: Props,
  ref?: React.Ref<HTMLDivElement>,
) {
  return (
    <div
      ref={ref}
      className={clsx(
        styles.wrapper,
        'no-wrap',
        alignContent && ContentAlignClassNames[alignContent],
        {
          [styles.noPointerEvents]: !!noPointerEvents,
        },
      )}
    >
      {children}
    </div>
  );
});
