import React from 'react';
import clsx from 'clsx';
import { TermsOfServicePolicyType } from '../../generated/graphql';
import { VersionIdentifier } from './lib';
import style from './TermsOfServiceContent.module.css';

const PolicyTypeUrlSegment: Readonly<Record<TermsOfServicePolicyType, string>> = {
  [TermsOfServicePolicyType.ResellerPartner]: 'reseller_partner',
  [TermsOfServicePolicyType.AgencyThirdParty]: 'agency_third_party',
  [TermsOfServicePolicyType.ProofOfConcept]: 'proof_of_concept',
};

export const getVersionUrl = ({ policyType, version }: VersionIdentifier): string =>
  `${process.env.REACT_APP_TERMS_OF_SERVICE_BUCKET_URL}/${PolicyTypeUrlSegment[policyType]}/tos-${version}.html`;

export type TermsOfServiceContentProps = {
  title?: string;
  className?: string;
} & VersionIdentifier;

const TermsOfServiceContent: React.FC<TermsOfServiceContentProps> = ({
  title,
  className,
  ...versionIdentifier
}) => {
  return (
    <div className={clsx(style.container, className)}>
      {title && <h3 className="mhn mtn">{title}</h3>}
      <iframe title="Terms of service" src={getVersionUrl(versionIdentifier)} width="100%" />
    </div>
  );
};

export default TermsOfServiceContent;
