import { AnyObject, localStorageFactory, Maybe } from '@tellurian/ts-utils';
import { Mapping as ModelMetadataMapping } from '../../modelDocumentation/ModelMetadataDocs/lib';
import { Feature, trackFeature } from '../features';
import { RecentlyViewedDashboards } from '../../components/businessDashboard/hooks/useRecentlyViewedDashboards';
import { ToastPresentedRecords } from '../../components/Toast/useInvalidCredentialsToast';
import { PortableTimePeriodSelection } from '../../components/InboundDataStatus/Filters/useStickyTimePeriod';
import { FilterDisplayType } from '../../components/InboundDataStatus/ConnectorDetail/lib';
import { LastTouchedRecords } from '../../components/AccountManager/EnableAccountTouchLog';
import { LastAccountDataFetch } from '../../components/AdminConsole/useDataSync';
import { AccountDetails } from '../../components/AdminConsole/lib';
import { Nonce } from '../../components/lettuce/unfiInsightsEmbedded/auth/lib';
import { Theme } from '../../components/lettuce/components/Theme';
import { LinkProviderData } from '../../components/lettuce/unfiInsightsEmbedded/auth/UnfiSignIn';
import { UnfiInsightsRecentlyViewedItems } from '../../components/lettuce/unfiInsightsEmbedded/Home/useRecentlyViewedItems';
import { FavoriteReports } from '../../components/lettuce/components/FavoriteReportsContext';
import { ObservabilityViewSettings } from '../../components/lettuce/crisp/observability/common/lib';
import { trackError } from '../errorTracking';
import { SortConfiguration } from '../../components/lettuce/components/Sort/lib';
import { ConnectorConfigurationSortKey } from '../../components/lettuce/crisp/ConnectorConfigurations/useConnectorConfigurationSort';
import './cleanup';
import { WorkspaceFilter } from '../../components/lettuce/components/bi/PowerBiRestEmbed/PreferencesContext';
import { StoredReports } from '../../components/lettuce/components/BiReport/RecentReports/useRecentReports';

/**
 * Deprecated, use LocalStorageKey instead.
 * Delete these when migration events (see line 92) are no longer seen in 2-3 months.
 */
enum DeprecatedLocalStorageKey {
  InvalidCredentialsNotifications = 'invalidCredentialsNotifications',
  LastTouchedAccount = 'lastTouchedAccount',
  TimePeriodSelection = 'timePeriodSelection',
  TimePeriodTypeSelection = 'timePeriodTypeSelection',
  RunDateSelection = 'runDateSelection',
  LastForceRefresh = 'lastForceRefresh',
  RecentlyViewedDashboards2 = 'recently-viewed-dashboards-2',
  SelectedMapping = 'selectedMapping',
  CredentialVerificationRetryDebounce = 'credentialVerificationRetryDebounce',
  GlobalAdminDisabled = 'globalAdminDisabled',
  InsightsDiscoverySurveySubmitted = 'insightsDiscoverySurveySubmitted',
  LastAccountId = 'lastAccountId',
  PinnedReports = 'pinnedReports',
}

export const saveToLocalStorage = (
  key: DeprecatedLocalStorageKey,
  item: string | AnyObject,
  errorMsg = `Could not save item with key "${key}" to local storage`,
): boolean => {
  try {
    window.localStorage.setItem(key, typeof item === 'string' ? item : JSON.stringify(item));
    return true;
  } catch (error) {
    trackError(error as Error, errorMsg);
  }

  return false;
};

const loadStringFromLocalStorage = (
  key: DeprecatedLocalStorageKey,
  defaultReturn = undefined,
  errorMsg = `Could not get item with key "${key}" from local storage`,
): string | typeof defaultReturn => {
  try {
    const item = window.localStorage.getItem(key);
    return item !== null ? item : defaultReturn;
  } catch (error) {
    trackError(error as Error, errorMsg);
  }

  return defaultReturn;
};

export const loadObjectFromLocalStorage = <T>(
  key: DeprecatedLocalStorageKey,
  translate: (item: string) => T = JSON.parse,
  defaultReturn = undefined,
  errorMsg = `Could not get item with key "${key}" from local storage`,
): T | typeof defaultReturn => {
  try {
    const item = loadStringFromLocalStorage(key, defaultReturn, errorMsg);
    return item === defaultReturn ? item : translate(item);
  } catch (error) {
    trackError(error as Error, errorMsg);
  }
  return defaultReturn;
};

export enum LocalStorageKey {
  // Add version (e.g. 2) as suffix if the key already exists in DeprecatedLocalStorageKey
  LastAccountId = 'lastAccountId2',
  InsightsDiscoverySurveySubmitted = 'insightsDiscoverySurveySubmitted2',
  SynchronizedPollingEnabled = 'synchronizedPollingEnabled',
  RecentlyViewedDashboards = 'recentlyViewedDashboards',
  InvalidCredentialsNotifications = 'invalidCredentialsNotifications2',
  LastForceRefresh = 'lastForceRefresh2',
  RunDateSelection = 'runDateSelection2',
  TimePeriodSelection = 'timePeriodSelection2',
  TimePeriodTypeSelection = 'timePeriodTypeSelection2',
  CredentialVerificationRetryDebounce = 'credentialVerificationRetryDebounce2',
  LastTouchedAccount = 'lastTouchedAccount2',
  GlobalAdminDisabled = 'globalAdminDisabled2',
  LastDismissedTermsOfServiceNotification = 'lastDisplayedTermsOfServiceNotification',
  Theme = 'theme',
  QuickReportsSaveDefault = 'quickReportsSaveDefault',
  FavoriteReports = 'favoriteReports',
  TimelineGridScopeColumnWidth = 'timelineGridScopeColumnWidth',
  SeenPendoGuideIds = 'seenPendoGuideIds',
  LastDeductionSave = 'lastDeductionSave',
  ObservabilityViewSettings = 'observabilityViewSettings',
  ShowMdmGuide = 'showMdmGuide',
  ConnectorLogoNames = 'connectorLogos',
  ConnectorConfigurationCardViewSelected = 'connectorListStyle',
  ConnectorConfigurationSortConfiguration = 'connectorSortConfiguration',

  // Admin console
  AdminConsoleLastAccountDataFetch = 'adminConsoleLastAccountDataFetch',
  AdminConsoleRecentAccounts = 'adminConsoleRecentAccounts',
  AdminLastRetrievedAllUsers = 'adminLastRetrievedAllUsers',

  // Unfi Insights
  UnfiInsightsOAuthNonce = 'unfiInsightsOAuthNonce',
  UnfiInsightsRecentlyViewedItems = 'unfiInsightsRecentlyViewedItems',

  // Data Catalog
  DataCatalogShowDeprecated = 'dataCatalogShowDeprecated',
  SelectedMapping = 'selectedMapping2',
  DataCatalogTablesToPrint = 'dataCatalogTablesToPrint',
  DataCatalogSidebarWidth = 'dataCatalogSidebarWidth',

  RetailAnalyticsWorkspaceFilter = 'retailAnalyticsWorkspaceFilter',
  RetailAnalyticsWorkspaceFilterByAccountId = 'retailAnalyticsWorkspaceFilterByAccountId',
  RetailAnalyticsShowSignInPrompt = 'retailAnalyticsShowSignInPrompt',
  RetailAnalyticsPermissionsLastRefreshedAt = 'retailAnalyticsLastPermissionsLastRefreshedAt',
  RetailAnalyticsLastHierarchyPrefetchedAt = 'retailAnalyticsLastHierarchyPrefetchedAt',
  RetailAnalyticsRecentReports = 'retailAnalyticsRecentReports',
  RetailAnalyticsMenuWidth = 'retailAnalyticsMenuWidth',

  LinkProvider = 'linkProvider',

  // Partner Minisite
  PartnerMinisiteOAuthNonce = 'partnerMinisiteOAuthNonce',
  DemoSiteEnabled = 'partnerSiteDemoEnabled',
}

export type LocalStorageSpec = {
  [LocalStorageKey.LastAccountId]: string;
  [LocalStorageKey.SelectedMapping]: ModelMetadataMapping;
  [LocalStorageKey.InsightsDiscoverySurveySubmitted]: boolean;
  [LocalStorageKey.SynchronizedPollingEnabled]: string;
  [LocalStorageKey.RecentlyViewedDashboards]: RecentlyViewedDashboards;
  [LocalStorageKey.InvalidCredentialsNotifications]: ToastPresentedRecords;
  [LocalStorageKey.LastForceRefresh]: number;
  [LocalStorageKey.RunDateSelection]: Record<string, string>;
  [LocalStorageKey.TimePeriodSelection]: Record<string, PortableTimePeriodSelection>;
  [LocalStorageKey.TimePeriodTypeSelection]: Record<string, FilterDisplayType>;
  [LocalStorageKey.CredentialVerificationRetryDebounce]: Record<string, string>;
  [LocalStorageKey.LastTouchedAccount]: LastTouchedRecords;
  [LocalStorageKey.GlobalAdminDisabled]: boolean;
  [LocalStorageKey.LastDismissedTermsOfServiceNotification]: number;
  [LocalStorageKey.AdminConsoleLastAccountDataFetch]: Maybe<LastAccountDataFetch>;
  [LocalStorageKey.AdminConsoleRecentAccounts]: AccountDetails[];
  [LocalStorageKey.AdminLastRetrievedAllUsers]: Maybe<number>;
  [LocalStorageKey.Theme]: Theme;
  [LocalStorageKey.QuickReportsSaveDefault]: boolean;
  [LocalStorageKey.FavoriteReports]: FavoriteReports;
  [LocalStorageKey.TimelineGridScopeColumnWidth]: number;
  [LocalStorageKey.SeenPendoGuideIds]: Maybe<string[]>;
  [LocalStorageKey.LastDeductionSave]: Maybe<number>;
  [LocalStorageKey.ShowMdmGuide]: boolean;
  [LocalStorageKey.ConnectorLogoNames]: string[];
  [LocalStorageKey.ConnectorConfigurationCardViewSelected]: boolean;
  [LocalStorageKey.ConnectorConfigurationSortConfiguration]: SortConfiguration<ConnectorConfigurationSortKey>;

  // UNFI Insights
  [LocalStorageKey.UnfiInsightsOAuthNonce]: Maybe<Nonce>;
  [LocalStorageKey.UnfiInsightsRecentlyViewedItems]: UnfiInsightsRecentlyViewedItems;

  [LocalStorageKey.DataCatalogShowDeprecated]: boolean;
  [LocalStorageKey.DataCatalogTablesToPrint]: string[];
  [LocalStorageKey.DataCatalogSidebarWidth]: number | string;
  [LocalStorageKey.ObservabilityViewSettings]: ObservabilityViewSettings;

  [LocalStorageKey.RetailAnalyticsWorkspaceFilter]: WorkspaceFilter;
  [LocalStorageKey.RetailAnalyticsWorkspaceFilterByAccountId]: boolean;
  [LocalStorageKey.RetailAnalyticsShowSignInPrompt]: boolean;
  [LocalStorageKey.RetailAnalyticsPermissionsLastRefreshedAt]: Maybe<number>;
  [LocalStorageKey.RetailAnalyticsLastHierarchyPrefetchedAt]: Record<string, number>;
  [LocalStorageKey.RetailAnalyticsRecentReports]: Record<string, StoredReports>;
  [LocalStorageKey.RetailAnalyticsMenuWidth]: number;
  [LocalStorageKey.LinkProvider]: LinkProviderData;

  // Partner Minisite
  [LocalStorageKey.PartnerMinisiteOAuthNonce]: Maybe<Nonce>;
  [LocalStorageKey.DemoSiteEnabled]: boolean;
};

const onMigrationComplete = (key1: string, key2: string) => () => {
  // Inform us of when migrations occur, such that we can safely remove
  // the deprecated keys after n months of last received event.
  trackFeature(Feature.LocalStorageMigration, { from: key1, to: key2 });
  // Cleanup after the record was successfully migrated.
  window.localStorage.removeItem(key1);
};

const migrationResult = <T>(
  key1: string,
  key2: string,
  value: Maybe<T>,
): Maybe<{
  value: T;
  onComplete?: () => void;
}> => {
  return value !== undefined
    ? {
        value,
        onComplete: onMigrationComplete(key1, key2),
      }
    : undefined;
};

// Should only be used by migrations from old records. Do not export.
const loadLocalStorageObject = <T>(key: string): Maybe<T> => {
  try {
    const val = window.localStorage.getItem(key);
    if (val) {
      return JSON.parse(val) as T;
    }
  } catch (err) {}

  return undefined;
};

export const LocalStorage = localStorageFactory<LocalStorageSpec>({
  storage: window.localStorage,
  onError: info => {
    const error = info.error as Error;
    if (info.op === 'get') {
      const { key } = info;
      trackError(error, `Could not get item with key "${key}" from local storage`);
    } else if (info.op === 'set') {
      const { key } = info;
      trackError(error, `Could not set item with key "${key}" to local storage`);
    } else if (info.op === 'remove') {
      const { key } = info;
      trackError(error, `Could not remove item with key "${key}" from local storage`);
    } else if (info.op === 'clear') {
      trackError(error, 'Could not clear local storage');
    }
  },
  // Standard way of handling migrations from one record (and value type) to another
  migrations: {
    [LocalStorageKey.LastAccountId]: () =>
      migrationResult(
        DeprecatedLocalStorageKey.LastAccountId,
        LocalStorageKey.LastAccountId,
        loadStringFromLocalStorage(DeprecatedLocalStorageKey.LastAccountId),
      ),
    [LocalStorageKey.SelectedMapping]: () =>
      migrationResult(
        DeprecatedLocalStorageKey.SelectedMapping,
        LocalStorageKey.SelectedMapping,
        loadStringFromLocalStorage(
          DeprecatedLocalStorageKey.SelectedMapping,
        ) as Maybe<ModelMetadataMapping>,
      ),
    [LocalStorageKey.InsightsDiscoverySurveySubmitted]: () =>
      migrationResult(
        DeprecatedLocalStorageKey.InsightsDiscoverySurveySubmitted,
        LocalStorageKey.InsightsDiscoverySurveySubmitted,
        loadStringFromLocalStorage(DeprecatedLocalStorageKey.InsightsDiscoverySurveySubmitted) ===
          'true',
      ),
    [LocalStorageKey.RecentlyViewedDashboards]: () =>
      migrationResult(
        DeprecatedLocalStorageKey.RecentlyViewedDashboards2,
        LocalStorageKey.RecentlyViewedDashboards,
        loadLocalStorageObject<RecentlyViewedDashboards>(
          DeprecatedLocalStorageKey.RecentlyViewedDashboards2,
        ),
      ),
    [LocalStorageKey.InvalidCredentialsNotifications]: () =>
      migrationResult(
        DeprecatedLocalStorageKey.InvalidCredentialsNotifications,
        LocalStorageKey.InvalidCredentialsNotifications,
        loadLocalStorageObject<ToastPresentedRecords>(
          DeprecatedLocalStorageKey.InvalidCredentialsNotifications,
        ),
      ),
    [LocalStorageKey.LastForceRefresh]: () =>
      migrationResult(
        DeprecatedLocalStorageKey.LastForceRefresh,
        LocalStorageKey.LastForceRefresh,
        ((now: Maybe<string>) => (now && parseInt(now)) || 0)(
          loadStringFromLocalStorage(DeprecatedLocalStorageKey.LastForceRefresh),
        ),
      ),
    [LocalStorageKey.RunDateSelection]: () =>
      migrationResult(
        DeprecatedLocalStorageKey.RunDateSelection,
        LocalStorageKey.RunDateSelection,
        loadLocalStorageObject(DeprecatedLocalStorageKey.RunDateSelection),
      ),
    [LocalStorageKey.TimePeriodSelection]: () =>
      migrationResult(
        DeprecatedLocalStorageKey.TimePeriodSelection,
        LocalStorageKey.TimePeriodSelection,
        loadLocalStorageObject(DeprecatedLocalStorageKey.TimePeriodSelection),
      ),
    [LocalStorageKey.TimePeriodTypeSelection]: () =>
      migrationResult(
        DeprecatedLocalStorageKey.TimePeriodTypeSelection,
        LocalStorageKey.TimePeriodTypeSelection,
        loadLocalStorageObject(DeprecatedLocalStorageKey.TimePeriodTypeSelection),
      ),
    [LocalStorageKey.CredentialVerificationRetryDebounce]: () =>
      migrationResult(
        DeprecatedLocalStorageKey.CredentialVerificationRetryDebounce,
        LocalStorageKey.CredentialVerificationRetryDebounce,
        loadLocalStorageObject(DeprecatedLocalStorageKey.CredentialVerificationRetryDebounce),
      ),
    [LocalStorageKey.LastTouchedAccount]: () =>
      migrationResult(
        DeprecatedLocalStorageKey.LastTouchedAccount,
        LocalStorageKey.LastTouchedAccount,
        loadLocalStorageObject(DeprecatedLocalStorageKey.LastTouchedAccount),
      ),
    [LocalStorageKey.GlobalAdminDisabled]: () =>
      migrationResult(
        DeprecatedLocalStorageKey.GlobalAdminDisabled,
        LocalStorageKey.GlobalAdminDisabled,
        loadLocalStorageObject<{ state: boolean }>(DeprecatedLocalStorageKey.GlobalAdminDisabled)
          ?.state,
      ),
    [LocalStorageKey.FavoriteReports]: () =>
      migrationResult(
        DeprecatedLocalStorageKey.PinnedReports,
        LocalStorageKey.FavoriteReports,
        (
          loadLocalStorageObject(DeprecatedLocalStorageKey.PinnedReports) as Maybe<{
            value: FavoriteReports;
          }>
        )?.value,
      ),
  },
});
