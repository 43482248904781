import numeral from 'numeral';

export function registerNumeralLocales() {
  numeral.register('locale', 'nb', {
    delimiters: {
      thousands: ' ',
      decimal: ',',
    },
    abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't',
    },

    ordinal() {
      return '.';
    },

    currency: {
      symbol: 'kr',
    },
  });
}
