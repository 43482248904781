import { useMemo } from 'react';
import _ from 'lodash';
import { Maybe } from '@tellurian/ts-utils';
import { LocalStorage, LocalStorageKey } from '../../../../utils/localStorage';
import { useAuthorizationContext } from '../../../security/AuthorizationContext';

const MAX_ITEMS_COUNT = 5;

export type RecentlyViewedDashboard = {
  dashboardGroupId?: string;
  dashboardId?: string;
};

export type RecentlyViewedPayment = {
  paymentId?: string;
};

type RecentlyViewedItem = RecentlyViewedDashboard | RecentlyViewedPayment;

export type UnfiInsightsRecentlyViewedItems = Record<string, RecentlyViewedItem[]>;

const getAllRecentlyViewedItems: () => UnfiInsightsRecentlyViewedItems = () => {
  return LocalStorage.getItem(LocalStorageKey.UnfiInsightsRecentlyViewedItems) || {};
};

const setRecentlyViewedItems = (
  userId: Maybe<string>,
  recentlyViewed: RecentlyViewedItem[],
  maxItems = MAX_ITEMS_COUNT,
) => {
  if (!userId) {
    return;
  }
  const allRecentlyViewedItems = getAllRecentlyViewedItems();
  const newRecentlyViewed = _.uniq(recentlyViewed).slice(0, maxItems);
  LocalStorage.setItem(LocalStorageKey.UnfiInsightsRecentlyViewedItems, {
    ...allRecentlyViewedItems,
    [userId]: newRecentlyViewed,
  });
};

const getRecentlyViewedItems: (userId: Maybe<string>) => RecentlyViewedItem[] = userId => {
  if (!userId) {
    return [];
  }
  return getAllRecentlyViewedItems()[userId] ?? [];
};

const useRecentlyViewedItems = () => {
  const { currentUser } = useAuthorizationContext();
  const userId = useMemo(() => currentUser?.id, [currentUser]);

  return useMemo(
    () => ({
      getRecentlyViewedItems: () => getRecentlyViewedItems(userId),

      add: (item: RecentlyViewedItem) => {
        const recentlyViewed = getRecentlyViewedItems(userId);
        recentlyViewed?.unshift(item);
        setRecentlyViewedItems(userId, recentlyViewed);
      },

      remove: (item: RecentlyViewedItem) => {
        const recentlyViewed = getRecentlyViewedItems(userId);
        _.remove(recentlyViewed, r => _.isEqual(r, item));
        setRecentlyViewedItems(userId, recentlyViewed);
      },
    }),
    [userId],
  );
};

export default useRecentlyViewedItems;
