import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { LOGOUT_PATH } from '../components/UnfiEmbeddedApp';

const {
  REACT_APP_DATADOG_ENABLE,
  REACT_APP_DATADOG_APP_ID,
  REACT_APP_DATADOG_CLIENT_TOKEN,
  REACT_APP_DATADOG_ENV,
  REACT_APP_GRAPHQL_URL,
  REACT_APP_COMMIT,
} = process.env;

const DATADOG_SITE = 'us5.datadoghq.com';

const isUnfiInsightsEmbeddedLogoutPath = () => window.location.pathname.startsWith(LOGOUT_PATH);

export default function initDatadog() {
  if (REACT_APP_DATADOG_ENABLE === 'true' && !isUnfiInsightsEmbeddedLogoutPath()) {
    datadogRum.init({
      applicationId: REACT_APP_DATADOG_APP_ID ?? '',
      clientToken: REACT_APP_DATADOG_CLIENT_TOKEN ?? '',
      site: DATADOG_SITE,
      service: 'core-ui',
      env: REACT_APP_DATADOG_ENV ?? 'dev',
      version: REACT_APP_COMMIT,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: REACT_APP_GRAPHQL_URL ? [REACT_APP_GRAPHQL_URL] : [],
      useCrossSiteSessionCookie: true,
    });

    datadogLogs.init({
      clientToken: REACT_APP_DATADOG_CLIENT_TOKEN ?? '',
      site: DATADOG_SITE,
      service: 'core-ui',
      env: REACT_APP_DATADOG_ENV ?? 'dev',
      version: REACT_APP_COMMIT,
      forwardErrorsToLogs: false,
      sessionSampleRate: 100,
    });

    datadogRum.startSessionReplayRecording();
  }
}
