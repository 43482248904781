import React from 'react';
import { ViewerAuthorityGrant } from '../../../security/AuthorizationContext';
import AccountTemplate from '../templates/AccountTemplate';
import MaxWidthLayout from '../../components/layout/MaxWidthLayout';
import { lazyWithRetry } from '../../../lib';
import ReportSchedules from '../ReportSchedules';
import { AccountIdMatchParams } from './lib';

const RenderReportSchedules = lazyWithRetry<typeof ReportSchedules>(
  () => import(/* webpackChunkName: "reportSchedules" */ '../ReportSchedules'),
);

const ReportSchedulesPage: React.FC<AccountIdMatchParams> = ({
  match: {
    params: { accountId },
  },
}) => {
  return (
    <AccountTemplate requiredGrant={ViewerAuthorityGrant} Layout={MaxWidthLayout} size="L">
      <RenderReportSchedules accountId={accountId} />
    </AccountTemplate>
  );
};

export default ReportSchedulesPage;
