import React from 'react';
import clsx from 'clsx';
import { ReactComponent as IconChevron } from '../../../../../assets/icons/lettuce/chevron-down.svg';
import useEventCallback from '../../../utils/useEventCallback';
import Input, { InputProps } from './index';
import style from './InputSelect.module.css';

export type InputSelectProps = {
  value?: string;
  isToggled?: boolean;
  onToggle?: () => void;
  toggleEvent?: 'mousedown' | 'click';
} & Pick<
  InputProps,
  | 'className'
  | 'label'
  | 'onFocus'
  | 'onBlur'
  | 'onKeyDown'
  | 'hasError'
  | 'disabled'
  | 'containerRef'
>;

const InputSelect = React.forwardRef<HTMLInputElement, InputSelectProps>(function InputSelect(
  {
    className,
    isToggled = false,
    toggleEvent = 'mousedown',
    onToggle,
    ...inputProps
  }: InputSelectProps,
  ref,
) {
  const onFocus = useEventCallback((e: React.FocusEvent<HTMLInputElement, HTMLElement>) => {
    const input = e.target;
    // Disable text selection on focus
    input.selectionStart = input.selectionEnd;
    inputProps.onFocus?.(e);
  });

  return (
    <Input
      {...inputProps}
      onFocus={onFocus}
      className={clsx(style.select, { [style.toggled]: isToggled }, className)}
      readOnly={true}
      onClick={toggleEvent === 'click' ? onToggle : undefined}
      onMouseDown={toggleEvent === 'mousedown' ? onToggle : undefined}
      IconRight={IconChevron}
      ref={ref}
      role="combobox"
    />
  );
});

export default InputSelect;
