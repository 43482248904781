import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Maybe, split } from '@tellurian/ts-utils';
import { orderBy, toLower } from 'lodash';
import { ReactComponent as SavedReportsIcon } from '../../../../../../assets/icons/lettuce/saved-reports.svg';
import { ReactComponent as SharedReportsIcon } from '../../../../../../assets/icons/lettuce/shared-reports.svg';
import { useAuthorizationContext } from '../../../../../security/AuthorizationContext';
import ExpandableList from '../../../../components/ExpandableList';
import ReportTitle from '../ReportTitle';
import ChipDropdownOptionSelect from '../../../../components/ChipDropdown/ChipDropdownOptionSelect';
import ListItemLink, { LinkItem } from '../ListItemLink';
import { FnsFormat } from '../../../../../../utils/dateFnsFormats';
import { useI18nContext } from '../../../../../../utils/I18nContext';
import { useFavoriteReportsContext } from '../../../../components/FavoriteReportsContext';
import {
  BookmarkFragmentWithDashboardInfo,
  useBookmarksContext,
} from '../../../../../BookmarksContext';
import DeleteReportModal from '../../../dashboards/reportOptions/DeleteReportModal';
import RenameReportModal from '../../../dashboards/reportOptions/RenameReportModal';
import ShareReportModal from '../../../dashboards/reportOptions/ShareReportModal';
import ReportDetailsModal from '../../../dashboards/reportOptions/ReportDetailsModal';
import { path } from '../../../routing/lib';
import styles from './index.module.css';

type SavedReportsProps = {
  accountId: string;
  searchQuery?: string;
  setHasSavedReportsResults?: (nextValue: boolean) => void;
  maxItemsVisibleInSection?: number;
  autoScaleItemsVisibleCount?: boolean;
  RenderNoReports?: ReactNode;
};

export enum SortOption {
  DateDescending = 'Date Added: Newest First',
  DateAscending = 'Date Added: Oldest First',
  ConnectorAscending = 'Connector Name: A-Z',
  ConnectorDescending = 'Connector Name: Z-A',
}

const getSortIteratees = (sort: SortOption) => [
  sort === SortOption.ConnectorAscending || sort === SortOption.ConnectorDescending
    ? 'dashboardGroupName'
    : 'createdAtTimestamp',
  'description',
];

const getSortOrder = (sort: SortOption) =>
  sort === SortOption.ConnectorDescending || sort === SortOption.DateDescending ? 'desc' : 'asc';

const SavedReportOps = ['Details', 'Share', 'Rename', 'Delete'] as const;
const OwnSavedReportOps = SavedReportOps.slice(1);
type SavedReportOp = (typeof SavedReportOps)[number];

const SavedReports: React.FC<SavedReportsProps> = ({
  accountId,
  searchQuery,
  setHasSavedReportsResults,
  maxItemsVisibleInSection = 6,
  autoScaleItemsVisibleCount = true,
  RenderNoReports = 'You do not have any saved reports.',
}) => {
  const { dateFormat } = useI18nContext();

  const [reportOp, setReportOp] =
    useState<Maybe<{ report: BookmarkFragmentWithDashboardInfo; op: SavedReportOp }>>();

  const [sort, setSort] = useState(SortOption.DateDescending);
  const { bookmarks } = useBookmarksContext();
  const { currentUser } = useAuthorizationContext();

  const filteredBookmarks = useMemo(() => {
    if (!searchQuery) return bookmarks;

    const trimmedLowerCaseQuery = searchQuery.trim().toLowerCase();
    return bookmarks.filter(b =>
      [b.description, b.dashboardGroupName, b.dashboardName]
        .map(toLower)
        .some(text => text.includes(trimmedLowerCaseQuery)),
    );
  }, [bookmarks, searchQuery]);

  useEffect(() => {
    if (setHasSavedReportsResults) {
      setHasSavedReportsResults(filteredBookmarks.length > 0);
    }
  }, [filteredBookmarks, setHasSavedReportsResults]);

  const isOwnReport = useCallback(
    (r: Pick<BookmarkFragmentWithDashboardInfo, 'owner'>) => r.owner?.id === currentUser?.id,
    [currentUser],
  );
  const [ownBookmarks, sharedBookmarks] = useMemo(() => {
    const sorted = orderBy(filteredBookmarks, getSortIteratees(sort), getSortOrder(sort));
    return split(sorted, isOwnReport);
  }, [filteredBookmarks, isOwnReport, sort]);

  const {
    isReportFavorite,
    add: addFavoriteReport,
    remove: removeFavoriteReport,
  } = useFavoriteReportsContext();

  const SortSelect = () => (
    <ChipDropdownOptionSelect
      options={Object.keys(SortOption).map(o => ({ name: SortOption[o], value: o }))}
      getChipText={() => sort}
      onChange={nextValue => setSort(SortOption[nextValue])}
      popoverId="sort-select"
    />
  );

  const MaybeReportOpModal = () => {
    if (reportOp) {
      const { op, report } = reportOp;
      const Component =
        op === 'Details'
          ? ReportDetailsModal
          : op === 'Share'
            ? ShareReportModal
            : op === 'Rename'
              ? RenameReportModal
              : DeleteReportModal;
      return (
        <Component accountId={accountId} onClose={() => setReportOp(undefined)} report={report} />
      );
    }

    return null;
  };

  const formatItemForList = useCallback(
    (bookmark: BookmarkFragmentWithDashboardInfo): LinkItem => {
      const isFavorite = isReportFavorite({
        dashboardGroupId: bookmark.dashboardGroupId,
        dashboardId: bookmark.dashboardId,
        bookmarkId: bookmark.id,
      });
      return {
        id: bookmark.id,
        name: bookmark.description,
        path: path('BusinessDashboardGroup')({
          accountId,
          dashboardGroupId: bookmark.dashboardGroupId,
          dashboardId: bookmark.dashboardId,
          bookmarkId: bookmark.id,
        }),
        labels: [
          { label: bookmark.dashboardGroupName, title: 'Connector Name' },
          { label: bookmark.dashboardName, title: 'Dashboard' },
          {
            label: dateFormat(FnsFormat.DateYearShort)(new Date(bookmark.createdAtTimestamp)),
            title: 'Created On',
          },
        ],
        labelDescription: 'Report details',
        actions: [
          {
            label: isFavorite ? 'Remove favorite' : 'Favorite',
            onToggle: () => {
              const favoriteReport = {
                dashboardGroupId: bookmark.dashboardGroupId,
                dashboardId: bookmark.dashboardId,
                bookmarkId: bookmark.id,
              };
              if (isFavorite) {
                removeFavoriteReport(favoriteReport);
              } else {
                addFavoriteReport(favoriteReport);
              }
            },
          },
          {
            label: 'Details',
            onToggle: () => setReportOp({ report: bookmark, op: 'Details' }),
          },
        ].concat(
          isOwnReport(bookmark)
            ? OwnSavedReportOps.map(op => ({
                label: op,
                onToggle: () => setReportOp({ report: bookmark, op }),
              }))
            : [],
        ),
      };
    },
    [accountId, isOwnReport, isReportFavorite, addFavoriteReport, removeFavoriteReport, dateFormat],
  );

  if (bookmarks.length === 0 && !RenderNoReports) {
    return null;
  }

  const maxSavedCountSavedCount =
    maxItemsVisibleInSection +
    (autoScaleItemsVisibleCount
      ? Math.max(maxItemsVisibleInSection - sharedBookmarks.length, 0)
      : 0);
  const maxSharedCount =
    maxItemsVisibleInSection +
    (autoScaleItemsVisibleCount ? Math.max(maxItemsVisibleInSection - ownBookmarks.length, 0) : 0);

  return (
    <>
      {bookmarks.length === 0 || ownBookmarks.length > 0 ? (
        <section className="mrs">
          <div className={styles.headerContainer}>
            <ReportTitle
              headerText="Saved Reports"
              itemCount={ownBookmarks.length}
              icon={<SavedReportsIcon />}
              id="saved-reports-header"
              displayItemCount={ownBookmarks.length > 0}
            />
            {ownBookmarks.length > 0 ? <SortSelect /> : null}
          </div>
          {ownBookmarks.length > 0 ? (
            <ExpandableList
              aria-labelledby="saved-reports-header"
              className={styles.listContainer}
              items={ownBookmarks.map(formatItemForList)}
              RenderItem={ListItemLink}
              maxItemsVisible={maxSavedCountSavedCount}
            />
          ) : (
            <p className={styles.empty}>{RenderNoReports}</p>
          )}
        </section>
      ) : null}
      {sharedBookmarks.length > 0 ? (
        <section className="mrs">
          <div className={styles.headerContainer}>
            <ReportTitle
              headerText="Shared With Me"
              itemCount={sharedBookmarks.length}
              icon={<SharedReportsIcon />}
              id="shared-reports-header"
            />
            {ownBookmarks.length === 0 ? <SortSelect /> : null}
          </div>
          <ExpandableList
            aria-labelledby="shared-reports-header"
            className={styles.listContainer}
            items={sharedBookmarks.map(formatItemForList)}
            RenderItem={ListItemLink}
            maxItemsVisible={maxSharedCount}
          />
        </section>
      ) : null}
      <MaybeReportOpModal />
    </>
  );
};

export default SavedReports;
