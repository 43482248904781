export type KeyCombo = {
  code: KeyboardEvent['code'];
  meta?: boolean;
  alt?: boolean;
  shift?: boolean;
};

export const equalsKeyCombo = (c1: KeyCombo, c2: KeyCombo): boolean =>
  c1.meta === c2.meta && c1.shift === c2.shift && c1.alt === c2.alt && c1.code === c2.code;

export const eventMatchesCombo = (
  event: KeyboardEvent,
  { code, meta = false, alt = false, shift = false }: KeyCombo,
) =>
  event.metaKey === meta && event.altKey === alt && event.code === code && event.shiftKey === shift;

export type KeyboardShortcutSpec = {
  keyCombo: KeyCombo;
  description: string;
  group?: string;
  override?: boolean;
};

export type KeyboardShortcut = KeyboardShortcutSpec & {
  fn: () => void;
};
