import React from 'react';
import { Forbidden } from '../httpErrors';
import { useAuthorizationContext } from './AuthorizationContext';

export function RequireGlobalAdmin({
  requireGlobalAdmin = true,
  children,
}: {
  children: React.ReactNode;
  requireGlobalAdmin?: boolean;
}) {
  const { error, getHasGlobalAdminPermission, loading } = useAuthorizationContext();
  if (!requireGlobalAdmin) return <>{children} </>; // No need to wait for context to resolve if there's no requirement
  if (loading || error) return null;
  return getHasGlobalAdminPermission() ? <>{children}</> : <Forbidden />;
}
