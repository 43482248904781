import { Configuration, PublicClientApplication } from '@azure/msal-browser';

const {
  REACT_APP_RETAIL_ANALYTICS_CLIENT_ID,
  REACT_APP_RETAIL_ANALYTICS_AUTHORITY,
  REACT_APP_RETAIL_ANALYTICS_REDIRECT_URI,
} = process.env;
const CLIENT_ID = REACT_APP_RETAIL_ANALYTICS_CLIENT_ID || '';
const AUTHORITY = REACT_APP_RETAIL_ANALYTICS_AUTHORITY || '';
const REDIRECT_URI = REACT_APP_RETAIL_ANALYTICS_REDIRECT_URI || '';

const configuration: Configuration = {
  auth: {
    authority: AUTHORITY,
    clientId: CLIENT_ID,
    redirectUri: REDIRECT_URI,
    navigateToLoginRequestUrl: false,
  },
};

const msalInstance = new PublicClientApplication(configuration);

export default msalInstance;
