import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useEventCallback from '../components/lettuce/utils/useEventCallback';
import { routeIdFromPath } from '../components/lettuce/crisp/routing/lib';

export function useKeyListener(onKey: (e: KeyboardEvent) => void) {
  const onKeyDown = useEventCallback(onKey);
  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    return function cleanup() {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);
}

export function useOnKeyDown(key: string, fn: (e: KeyboardEvent) => void) {
  return useKeyListener(e => e.key === key && fn(e));
}

export function useMouseListener(onMouseDown: (event: MouseEvent) => void) {
  useEffect(() => {
    window.addEventListener('mousedown', onMouseDown);
    return () => {
      window.removeEventListener('mousedown', onMouseDown);
    };
  });
}

export function useRouteId() {
  const location = useLocation();
  return useMemo(() => routeIdFromPath(location.pathname), [location.pathname]);
}

export function useSameValueIfEquivalent<T>(resolveValue: (T) => T) {
  const lastResolvedValue = useRef<T>();
  const lastValue = useRef<T | undefined>(undefined);

  return useCallback(
    value => {
      const resolvedValue = resolveValue(value);
      if (Object.is(lastResolvedValue.current, resolvedValue)) {
        return lastValue.current;
      }

      lastResolvedValue.current = resolvedValue;
      lastValue.current = value;
      return value;
    },
    [resolveValue],
  );
}

export type ScrollIntoView =
  | boolean
  | {
      position?: ScrollLogicalPosition;
      verticalPosition?: ScrollLogicalPosition;
    };

export const useScrollIntoView = <T extends HTMLElement = HTMLDivElement>(
  scrollIntoView: ScrollIntoView,
): RefObject<T> => {
  const ref = useRef<T>(null);
  useEffect(() => {
    if (scrollIntoView && ref.current) {
      const setting =
        typeof scrollIntoView !== 'boolean'
          ? {
              block: scrollIntoView.verticalPosition,
              inline: scrollIntoView.position,
            }
          : { inline: 'nearest' as ScrollLogicalPosition };
      ref.current.scrollIntoView({
        ...setting,
        behavior: 'smooth',
      });
    }
  }, [scrollIntoView]);
  return ref;
};

/**
 * A utility for calculating a subset of items based on a maximum default visible count.
 * The intention is to use this in conjunction with an expandable/collapsible list UI.
 * If collapsed, `visibleItems` will contain only the first n=`maxItemsVisible` items.
 */
export function useMaxItemsVisible<T>({
  items,
  maxItemsVisible,
}: {
  items: readonly T[];
  maxItemsVisible: number;
}) {
  const exceedsVisibleCount = useMemo(
    () => maxItemsVisible > -1 && maxItemsVisible < items.length,
    [maxItemsVisible, items.length],
  );

  const [isCollapsed, setIsCollapsed] = useState(exceedsVisibleCount);
  const visibleItems = useMemo(
    () => (exceedsVisibleCount && isCollapsed ? items.slice(0, maxItemsVisible) : items),
    [exceedsVisibleCount, items, maxItemsVisible, isCollapsed],
  );

  return {
    exceedsVisibleCount,
    isCollapsed,
    setIsCollapsed,
    visibleItems,
  };
}

export const useSetPageTitle = (title: string) => {
  useEffect(() => {
    if (title) document.title = title;

    return () => {
      document.title = process.env.REACT_APP_DEFAULT_DOCUMENT_TITLE || 'Crisp Platform';
    };
  }, [title]);
};

export const useIsRemaPage = () => {
  const location = useLocation();
  return location.pathname.includes('rema-connect');
};
