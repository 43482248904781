import React from 'react';
import { ApolloError } from '@apollo/client/errors';
import { ErrorWithMessage, renderErrorForHttpStatus } from '../errorMessages';
import style from './index.module.css';

export type Error = {
  prodMessage: React.ReactNode;
  devMessage: React.ReactNode;
};

export function extractErrorMessage(error: string | ApolloError): Error {
  if (typeof error === 'string')
    return {
      prodMessage: <ErrorWithMessage message={error} />,
      devMessage: <ErrorWithMessage message={error} />,
    };

  const ar = error as ApolloError;
  const firstError = ar.graphQLErrors[0];
  const statusCode =
    firstError && firstError.extensions && (firstError.extensions.http_status as number);

  const renderedMessage = renderErrorForHttpStatus(statusCode);
  return {
    devMessage: ar.message,
    prodMessage: renderedMessage,
  };
}

export function ErrorMessage({
  error,
  production,
}: {
  error: string | ApolloError;
  production?: boolean;
}) {
  if (error instanceof ApolloError && error.networkError) return null;

  const messages = extractErrorMessage(error);
  const messageToShow =
    production || process.env.NODE_ENV === 'production'
      ? messages.prodMessage
      : messages.devMessage;

  return <div className={'mod ' + style.message}>{messageToShow}</div>;
}
