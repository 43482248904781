import { BookmarkScope, LookerBookmarkFilterInput } from '../../../../../generated/graphql';
import { LookerFilters } from '../../../../businessDashboard/embeddedLooker/EmbeddedLookerContext';
import { DropdownListOptions } from '../../../components/lib/dropdownListOption';

export const ReportAccessOptions: DropdownListOptions = [
  ['Private', BookmarkScope.Private],
  ['All account users', BookmarkScope.AccountShared],
  ['Specific users', BookmarkScope.UserShared],
].map(([name, value]) => ({ name, value }));

export const ReportLimitedAccessOptions = ReportAccessOptions.slice(0, -1);

export const filtersToFilterInput = (filters: LookerFilters): LookerBookmarkFilterInput[] =>
  Object.keys(filters).map(k => ({
    filterKey: k,
    filterValue: filters[k],
  }));
