import React from 'react';

import { NormalizedCacheObject } from '@apollo/client/cache';
import initApollo from '../services/initApollo';

export default (App, toastNotificationsEnabled = true) => {
  return class WithApolloClient extends React.Component {
    apolloClient;
    static displayName = `WithApolloClient(${App.displayName})`;

    constructor(props: { apolloState: NormalizedCacheObject }) {
      super(props);
      this.apolloClient = initApollo(props.apolloState, toastNotificationsEnabled);
    }

    render() {
      return <App {...this.props} apolloClient={this.apolloClient} />;
    }
  };
};
