import React, { useMemo } from 'react';
import { ReactComponent as IconKnowledgeBase } from '../../../assets/icons/lettuce/unfi/knowledgebase.svg';
import { ReactComponent as IconAcademy } from '../../../assets/icons/lettuce/unfi/academy.svg';
import { ReactComponent as IconUpdates } from '../../../assets/icons/lettuce/unfi/updates.svg';
import { ReactComponent as IconDataDictionary } from '../../../assets/icons/lettuce/data-dictionary.svg';
import { ExternalLinkTarget } from '../components/ExternalLink';
import { Resource } from '../components/ResourceCards/lib';
import { Feature } from '../../../utils/features';
import useIsFeatureFlagEnabled from './featureFlags/useIsFeatureFlagEnabled';
import { KnownFlags } from './featureFlags/KnownFlags';

const {
  REACT_APP_UNFI_INSIGHTS_HELP_URL,
  REACT_APP_UNFI_INSIGHTS_ACADEMY_URL,
  REACT_APP_UNFI_INSIGHTS_UPDATES_URL,
} = process.env;

export const Resources: Resource[] = [
  {
    title: 'Knowledge Base',
    subTitle: 'Dive into our detailed articles to solve your queries.',
    buttonText: 'Explore Now',
    url: REACT_APP_UNFI_INSIGHTS_HELP_URL,
    icon: <IconKnowledgeBase />,
    featureId: Feature.HomepageKnowledgeBase,
    target: ExternalLinkTarget.knowledgeBase,
  },
  {
    title: 'Academy',
    subTitle: 'Master our product with comprehensive tutorials in our Academy.',
    buttonText: 'Start Learning',
    url: REACT_APP_UNFI_INSIGHTS_ACADEMY_URL,
    icon: <IconAcademy />,
    featureId: Feature.Academy,
    target: ExternalLinkTarget.academy,
  },
  {
    title: 'Recent Updates',
    subTitle: 'Stay up-to-date with our latest features and improvements.',
    buttonText: 'See Updates',
    url: REACT_APP_UNFI_INSIGHTS_UPDATES_URL,
    icon: <IconUpdates />,
    featureId: Feature.HomepageKnowledgeBase,
    target: ExternalLinkTarget.knowledgeBase,
  },
];

type UseResourcesParams = {
  onDataDictionaryOpen?: () => void;
};

export const useResources = (params?: UseResourcesParams): Resource[] => {
  const onDataDictionaryOpen = params?.onDataDictionaryOpen;
  const isDataDictionaryEnabled = useIsFeatureFlagEnabled(KnownFlags.DataDictionary);

  return useMemo(() => {
    if (isDataDictionaryEnabled && onDataDictionaryOpen) {
      return [
        ...Resources,
        {
          title: 'Data Dictionary',
          subTitle: 'Look up dimensions or measures for a detailed explanation.',
          buttonText: 'Launch Data Dictionary',
          icon: <IconDataDictionary />,
          featureId: Feature.RetailAnalyticsDataDictionaryOpened,
          onClick: onDataDictionaryOpen,
        },
      ];
    }

    return Resources;
  }, [isDataDictionaryEnabled, onDataDictionaryOpen]);
};
