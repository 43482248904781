import React, { useMemo } from 'react';
import { ReactComponent as LogOutIcon } from '../../../../assets/icons/lettuce/logout.svg';
import UserDetails from '../../components/lib/navigation/UserDetails';
import { ItemSeparator } from '../../components/Menu/RenderMenuItem';
import Menu from '../../components/Menu';

const handleLogout = () => {
  window.location.href = '/p/logout';
};

const UserSettingsMenu: React.FC = () => {
  const items = useMemo(() => {
    return [
      {
        label: 'Log out',
        Icon: LogOutIcon,
        onToggle: handleLogout,
      },
    ];
  }, []);
  return (
    <>
      <UserDetails />
      <div className="mhs mbs">
        <ItemSeparator />
        <Menu aria-label="Settings menu" items={items} />
      </div>
    </>
  );
};

export default UserSettingsMenu;
