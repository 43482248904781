import React from 'react';
import Title from '../../../components/Title';
import SavedReportFilters, { SaveReportFiltersProps } from './SavedReportFilters';

type ReportDetailsProps = SaveReportFiltersProps;

const ReportDetails: React.FC<ReportDetailsProps> = props => {
  return (
    <>
      <Title size="M" className="mvm">
        Report details
      </Title>
      <div className="mhm">
        <SavedReportFilters {...props} />
      </div>
    </>
  );
};

export default ReportDetails;
