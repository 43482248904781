import React from 'react';
import { ContentWidth } from '../../../ui';
import UnfiInsightsEmbeddedTemplate from '../templates/UnfiInsightsEmbeddedTemplate';
import { useSetPageTitle } from '../../../../utils/hooks';
import { lazyWithRetry } from '../../../lib';
import Home from '../Home';

const RenderHome = lazyWithRetry<typeof Home>(
  () => import(/* webpackChunkName: "unfiEmbeddedHome" */ '../Home'),
);

export default function UnfiInsightsEmbeddedHomePage() {
  useSetPageTitle('UNFI Insights');

  return (
    <UnfiInsightsEmbeddedTemplate mainContentWidth={ContentWidth.Default}>
      <RenderHome />
    </UnfiInsightsEmbeddedTemplate>
  );
}
