import React, { useEffect, useState } from 'react';
import { Maybe } from '@tellurian/ts-utils';
import { useHistory } from 'react-router';
import { PageError, usePageErrorListener } from '../PageErrorNotifications/lib';
import { FCC } from '../../../../utils/types';
import { Forbidden, NotFound } from '../../../httpErrors';
import useEventCallback from '../../utils/useEventCallback';

const PageErrorsToRouteRedirects: Partial<Record<PageError, React.FC>> = {
  [PageError.Forbidden]: Forbidden,
  [PageError.NotFound]: NotFound,
};

export const pageErrorHasRedirect = (pageError: PageError) =>
  !!PageErrorsToRouteRedirects[pageError];

const PageErrorRedirect: FCC = ({ children }) => {
  const [error, setError] = useState<Maybe<PageError>>();
  const errorListener = useEventCallback((pageError: PageError) => {
    if (pageErrorHasRedirect(pageError)) {
      setError(pageError);
    }
  });
  usePageErrorListener(errorListener);
  const history = useHistory();
  useEffect(() => {
    return history.listen(() => setError(undefined));
  }, [history]);

  if (error) {
    const Component = PageErrorsToRouteRedirects[error];
    if (Component) {
      return <Component />;
    }
  }

  return children;
};

export default PageErrorRedirect;
