import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { AdminAuthorityGrant } from '../../components/security/AuthorizationContext';
import Forbidden from '../../components/connectors/Forbidden';
import useInvalidCredentialsToast from '../../components/Toast/useInvalidCredentialsToast';
import AccountTemplate from '../../components/lettuce/crisp/templates/AccountTemplate';

type ConnectorsTemplateProps = {
  children: React.ReactNode;
};

type MatchParams = {
  accountId?: string;
};

export default function ConnectorsTemplate({ children }: ConnectorsTemplateProps) {
  const {
    params: { accountId },
  } = useRouteMatch<MatchParams>();

  useInvalidCredentialsToast(accountId);

  return (
    <AccountTemplate requiredGrant={AdminAuthorityGrant} RenderForbidden={Forbidden}>
      {children}
    </AccountTemplate>
  );
}
