import React, { useEffect } from 'react';
import usePersistentState from '../../utils/memoryStorage/usePersistentState';
import { MemoryStorageKey } from '../../utils/memoryStorage';
import { path } from '../lettuce/crisp/routing/lib';
import useTermsOfServiceToast from './useTermsOfServiceToast';

type AcknowledgementNotificationProps = {
  accountId: string;
  onDismiss: () => void;
};

const AcknowledgementNotification: React.FC<AcknowledgementNotificationProps> = ({
  onDismiss,
  accountId,
}) => {
  const [isNotificationVisible, setIsNotificationVisible] = usePersistentState(
    MemoryStorageKey.IsTermsOfServiceNotificationVisible,
    false,
  );

  const { show, dismiss } = useTermsOfServiceToast();

  useEffect(() => {
    if (!isNotificationVisible) {
      show(
        <>
          Please note that our{' '}
          <a
            href={path('TermsOfService')({ accountId })}
            onClick={() => {
              dismiss();
              onDismiss();
            }}
          >
            Terms of Service
          </a>{' '}
          have changed.
        </>,
        {
          autoDismissAfterMs: undefined,
          onDismiss,
        },
      );
      setIsNotificationVisible(true);
    }
  }, [dismiss, show, accountId, isNotificationVisible, setIsNotificationVisible, onDismiss]);

  return null;
};

export default AcknowledgementNotification;
