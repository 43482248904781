import React from 'react';
import { inProgressLabel } from '../../../lib';
import ConfirmCancelActionButtons, {
  ConfirmCancelActionButtonsProps,
} from './ConfirmCancelActionButtons';

export type DeleteCancelActionButtonsProps = {
  onDelete: () => void;
  isDeleting?: boolean;
  isDeleteDisabled?: boolean;
} & Pick<ConfirmCancelActionButtonsProps, 'onCancel' | 'isCancelDisabled'>;

const DeleteCancelActionButtons: React.FC<DeleteCancelActionButtonsProps> = ({
  onDelete,
  isDeleteDisabled,
  isDeleting,
  ...rest
}) => {
  return (
    <ConfirmCancelActionButtons
      onConfirm={onDelete}
      isConfirming={isDeleting}
      isConfirmDisabled={isDeleteDisabled}
      getConfirmLabel={isDeleting => (isDeleting ? inProgressLabel('Deleting') : 'Delete')}
      {...rest}
    />
  );
};

export default DeleteCancelActionButtons;
