import React, { useId } from 'react';
import clsx from 'clsx';
import Input, { InputProps } from '../lib/Input';
import composeRefs from '../../utils/composeRefs';
import useInitialFocus from '../../../ui/form/useInitialFocus';
import FieldDetails, { FieldDetailsProps } from '../lib/FieldDetails';
import { FieldClassName } from '../lib/fieldSettings';

export type TextFieldProps = Omit<FieldDetailsProps, 'id'> &
  Omit<InputProps, 'onChange' | 'hasError'> & {
    onChange?: (value: string) => void;
    isFocused?: boolean;
    scrollIntoViewIfFocused?: boolean;
  };

const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(function TextField(
  {
    description,
    isDescriptionMarkdown,
    error,
    onChange,
    isFocused,
    scrollIntoViewIfFocused,
    className,
    ...inputProps
  },
  ref,
) {
  const hasError = !!error;
  const composedRef = composeRefs(
    ref,
    useInitialFocus<HTMLInputElement>({ isFocused, scrollIntoViewIfFocused }),
  );
  const supportingTextId = useId();
  return (
    <div className={clsx(FieldClassName, className)}>
      <Input
        {...inputProps}
        onChange={onChange && (e => onChange(e.target.value))}
        hasError={hasError}
        ref={composedRef}
        aria-describedby={supportingTextId}
      />
      <FieldDetails
        error={error}
        description={description}
        id={supportingTextId}
        isDescriptionMarkdown={isDescriptionMarkdown}
      />
    </div>
  );
});

export default TextField;
