import React from 'react';
import clsx from 'clsx';
import { FCC } from '../../../../utils/types';
import { isProduction } from '../../../lib';
import style from './index.module.css'; // eslint-disable-line css-modules/no-unused-class

const BrandColorNames = [
  'ube',
  'apple',
  'citrus',
  'pear',
  'plum',
  'salmon',
  'blueberry',
  'sorbet',
  'banana',
  'spearmint',
  'steak',
  'tangerine',
  'rare',
  'water',
] as const;

type BrandColorName = (typeof BrandColorNames)[number];

const getColorForInitial = (initial: string) => {
  const index = initial.charCodeAt(0) % BrandColorNames.length;
  return BrandColorNames[index];
};

const AvatarSizes = ['M', 'L'] as const;
type AvatarSize = (typeof AvatarSizes)[number];

type CommonProps = {
  size?: AvatarSize;
  backgroundColor?: BrandColorName;
};

type AvatarContainerProps = CommonProps & {
  className?: string;
};

export const AvatarContainer: FCC<AvatarContainerProps> = ({ className, children, size }) => {
  return <div className={clsx(style.avatar, className, size === 'L' && style.L)}>{children}</div>;
};

type PhotoAvatarProps = CommonProps & {
  photoUrl: string;
  name: string;
};

const PhotoAvatar: React.FC<PhotoAvatarProps> = ({ photoUrl, name, ...rest }) => {
  return (
    <AvatarContainer className={style.transparent} {...rest}>
      <img src={photoUrl} alt={`Avatar for ${name}`} title={name} />
    </AvatarContainer>
  );
};

type NameInitialAvatarProps = CommonProps & {
  name: string;
};

const NameInitialAvatar: React.FC<NameInitialAvatarProps> = ({
  name,
  backgroundColor,
  ...rest
}) => {
  return (
    <AvatarContainer
      {...rest}
      className={backgroundColor ? style[backgroundColor] : style[getColorForInitial(name)]}
    >
      <span>{name.substring(0, 1)}</span>
    </AvatarContainer>
  );
};

type AvatarProps = CommonProps & {
  photoUrl?: string;
  name: string;
};

const Avatar: React.FC<AvatarProps> = ({ photoUrl, name, ...rest }) => {
  return isProduction() && photoUrl ? (
    <PhotoAvatar photoUrl={photoUrl} name={name} {...rest} />
  ) : (
    <NameInitialAvatar name={name} {...rest} />
  );
};

export default Avatar;
