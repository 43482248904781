import React, { ReactNode } from 'react';
import clsx from 'clsx';
import ExpandableList, { ExpandableListProps } from '../ExpandableList';
import { RenderItemProps } from '../../../ui/lists/listOfItems/ListOfItems';
import style from './index.module.css';

export type KeyValuePair = {
  key: string;
  value: ReactNode;
};

const KeyValueListItem: React.FC<RenderItemProps<KeyValuePair>> = ({ item: { key, value } }) => (
  <>
    <dt>{key}</dt>
    <dd>{value}</dd>
  </>
);

type KeyValueExpandableListProps = Omit<
  ExpandableListProps<KeyValuePair>,
  'as' | 'items' | 'className' | 'RenderItem'
> & {
  className?: string;
  items: KeyValuePair[];
  variant?: 'standard' | 'space-between';
  omitBorders?: 'top' | 'bottom' | 'top-and-bottom';
  omitHorizontalPadding?: boolean;
};

const KeyValueExpandableList: React.FC<KeyValueExpandableListProps> = ({
  className,
  variant = 'standard',
  omitBorders,
  omitHorizontalPadding = false,
  ...props
}) => (
  <ExpandableList
    {...props}
    as="dl"
    RenderItem={KeyValueListItem}
    className={clsx(
      style.keyValueList,
      {
        [style.spaceBetween]: variant === 'space-between',
        [style.noTopBorder]: omitBorders === 'top' || omitBorders === 'top-and-bottom',
        [style.noBottomBorder]: omitBorders === 'bottom' || omitBorders === 'top-and-bottom',
        [style.noHPadding]: omitHorizontalPadding,
      },
      className,
    )}
    getItemKey={item => item.key}
  />
);

export default KeyValueExpandableList;
