import React, { ReactNode, Suspense, useEffect } from 'react';
import { AnyObject, Maybe, maybe } from '@tellurian/ts-utils';
import { useRouteMatch } from 'react-router-dom';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import {
  RequireAccountGrant,
  RequireAccountGrantProps,
} from '../../../security/RequireAccountGrant';
import { AuthorityGrant, ViewerAuthorityGrant } from '../../../security/AuthorizationContext';
import NavRailMenu, { NavRailMenuProps } from '../navigation/NavRailMenu';
import NavRailPageTemplate from '../../components/NavRailPageTemplate';
import MaxWidthLayout from '../../components/layout/MaxWidthLayout';
import PageError from '../../../errorHandling/PageError';
import SegmentUserAccountTracker from '../../../SegmentUserAccountTracker';
import TermsOfServiceNotification from '../../../TermsOfServiceNotification';
import PageMessage from '../../../PageMessage';
import { NotFound } from '../../../httpErrors';
import { FavoriteReportsProvider } from '../../components/FavoriteReportsContext';
import { useHelpUrl } from '../navigation/HelpLinkContextProvider';
import PageSpecificNavigationContextProvider from '../navigation/PageSpecificNavigationContext';
import { useAuthenticationContext } from '../../../security/AuthenticationContext';
import { trackError } from '../../../../utils/errorTracking';
import { FeatureFlagsDisabled } from '../../common/featureFlags/FeatureFlags';

let lastIdentifiedWith: Maybe<{ userId: string; accountId: string }> = undefined;

const useFeatureFlagIdentify = (accountId?: string) => {
  const ldClient = useLDClient();
  const { firebaseUser } = useAuthenticationContext();

  useEffect(() => {
    if (
      !FeatureFlagsDisabled &&
      ldClient &&
      firebaseUser &&
      accountId &&
      (accountId !== lastIdentifiedWith?.accountId ||
        firebaseUser.uid !== lastIdentifiedWith?.userId)
    ) {
      const context = {
        kind: 'multi',
        'crisp-account': {
          key: accountId,
          accountId,
        },
        user: {
          key: firebaseUser.uid,
          name: maybe(firebaseUser.displayName),
          email: firebaseUser.email,
          avatar: firebaseUser.photoURL,
        },
      };
      ldClient.identify(context).catch(err => {
        trackError(err);
      });
      lastIdentifiedWith = { accountId, userId: context.user.key };
    }
  }, [firebaseUser, accountId, ldClient]);
};

type MatchParams = {
  accountId?: string;
};

type AccountTemplateProps<T extends AnyObject> = T & {
  Layout?: 'div' | React.FC<T>;
  requiredGrant?: AuthorityGrant;
  helpUrl?: string;
  children?: ReactNode;
} & Pick<RequireAccountGrantProps, 'RenderForbidden'>;

function AccountTemplate<T extends AnyObject>({
  children,
  RenderForbidden,
  requiredGrant = ViewerAuthorityGrant,
  Layout = MaxWidthLayout as React.FC<T>,
  helpUrl,
  ...props
}: AccountTemplateProps<T>) {
  const { params } = useRouteMatch<MatchParams>();
  const { accountId } = params;
  useHelpUrl(helpUrl);
  useFeatureFlagIdentify(accountId);

  if (!accountId) {
    return <NotFound />;
  }

  const layoutProps = props as T;
  return (
    <FavoriteReportsProvider accountId={accountId}>
      <NavRailPageTemplate<NavRailMenuProps>
        RenderNavRail={NavRailMenu}
        SubsidiaryContext={PageSpecificNavigationContextProvider}
        accountId={accountId}
        isInitiallyReady={false}
      >
        <Layout {...layoutProps}>
          <PageError type="page" hideIfTypePresent="modal" />
        </Layout>
        <RequireAccountGrant
          accountId={accountId}
          grant={requiredGrant}
          RenderForbidden={RenderForbidden}
        >
          <main id="main">
            <Layout {...layoutProps}>
              <PageMessage />
              <Suspense>{children}</Suspense>
            </Layout>
          </main>
        </RequireAccountGrant>
        <SegmentUserAccountTracker accountId={accountId} />
        <TermsOfServiceNotification accountId={accountId} />
      </NavRailPageTemplate>
    </FavoriteReportsProvider>
  );
}

export default AccountTemplate;
