import React, { ReactNode, Suspense, useLayoutEffect, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router';
import MaxWidthLayout from '../../components/layout/MaxWidthLayout';
import { useHelpUrl } from '../../crisp/navigation/HelpLinkContextProvider';
import { NotFound } from '../../../httpErrors';
import NavRailPageTemplate from '../../components/NavRailPageTemplate';
import NavRailMenu from '../navigation/NavRailMenu';
import { PartnerAccountProps, PartnerTypeParams } from '../lib';
import RequirePartnerGrant, { RequirePartnerGrantProps } from '../lib/RequirePartnerGrant';
import PartnerContextProvider from '../lib/PartnerContext';
import { Theme } from '../../components/Theme';
import { useUserPreferencesContext } from '../../crisp/UserPreferences/UserPreferencesProvider';
import { useThemeContext } from '../../components/Theme/ThemeProvider';
import { partnerAccount, PartnerType } from '../../components/lib/Account';
import SegmentPartnerAccountTracker from './SegmentPartnerAccountTracker';

// TEMP
const PartnerTypeToAccountId = {
  [PartnerType.Dot]: '81823',
};

const usePartnerAccountId = (partnerType: PartnerType) => {
  // Use a context which resolves this once and caches the result such that we don't
  // run a query on every page.
  return { partnerAccountId: PartnerTypeToAccountId[partnerType], loading: false };
};

const usePartnerAccountTheme = () => {
  const history = useHistory();
  // Retrieve partner theme based on partner account settings (pass partnerId as param to this hook)
  const { preferences } = useUserPreferencesContext();
  const overrideTheme = preferences.general.overrideAccountTheme;
  const { setTheme: applyTheme } = useThemeContext();

  useLayoutEffect(() => {
    if (!overrideTheme) {
      applyTheme(Theme.Dot);
      const onChange = () => applyTheme(Theme.Dot);
      return history.listen(onChange);
    }
  }, [history, overrideTheme, applyTheme]);
};

type PartnerTemplateProps<T extends object> = {
  children?: ReactNode;
  helpUrl?: string;
  Layout?: 'div' | React.FC<T>;
} & Pick<RequirePartnerGrantProps, 'RenderForbidden'>;

function PartnerTemplate<T extends object>({
  children,
  RenderForbidden,
  Layout = MaxWidthLayout as React.FC<T>,
  helpUrl,
  ...props
}: PartnerTemplateProps<T>) {
  const { params } = useRouteMatch<PartnerTypeParams>();
  const { partnerType } = params;
  const { partnerAccountId, loading } = usePartnerAccountId(partnerType);
  const account = useMemo(
    () => (partnerAccountId ? partnerAccount(partnerAccountId, partnerType) : undefined),
    [partnerAccountId, partnerType],
  );
  useHelpUrl(helpUrl);
  usePartnerAccountTheme();

  if (loading) {
    return null;
  }

  if (!account) {
    return <NotFound />;
  }

  const layoutProps = props as T;
  return (
    <NavRailPageTemplate<PartnerAccountProps> RenderNavRail={NavRailMenu} partnerAccount={account}>
      <RequirePartnerGrant partnerAccountId={partnerAccountId} RenderForbidden={RenderForbidden}>
        <main id="main">
          <PartnerContextProvider partnerAccount={account}>
            <Layout {...layoutProps}>
              <Suspense>{children}</Suspense>
            </Layout>
          </PartnerContextProvider>
        </main>
        <SegmentPartnerAccountTracker partnerAccountId={partnerAccountId} />
      </RequirePartnerGrant>
    </NavRailPageTemplate>
  );
}

export default PartnerTemplate;
