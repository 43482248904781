import React from 'react';
import styles from './index.module.css'; // eslint-disable-line

export function NotFound() {
  return (
    <>
      <h1>
        404. <span className={styles.heading}>Not found.</span>
      </h1>
      <p>Oops, you have requested something that does not exist.</p>
    </>
  );
}
