import React, { useContext, useEffect, useState } from 'react';
import { noop } from '@tellurian/ts-utils';
import _ from 'lodash';
import { FCC } from '../../../../utils/types';
import useLocalStorageState from '../../../../utils/localStorage/useLocalStorageState';
import useEventCallback from '../../utils/useEventCallback';
import { LocalStorageKey } from '../../../../utils/localStorage';
import { useAuthorizationContext } from '../../../security/AuthorizationContext';
import { isAccountBoundTheme } from '../../crisp/UserPreferences/lib';
import { Theme, ThemeExtensions } from './index';

const DefaultTheme = Theme.Legacy;

type ThemeContextInterface = {
  theme: Theme;
  setTheme: (nextTheme: Theme, track?: boolean) => void;
  reset: () => void;
  isLettuceTheme: boolean;
  themeLineage: string;
};

const ThemeContext = React.createContext<ThemeContextInterface>({
  theme: DefaultTheme,
  setTheme: noop,
  isLettuceTheme: false,
  reset: noop,
  themeLineage: '',
});

type ThemeProviderProps = Partial<ThemeContextInterface>;

const getClassNameForTheme = (theme: Theme): string => {
  return [...(ThemeExtensions[theme] ?? []), theme].map(t => `theme-${t}`).join(' ');
};

const applyThemeClassNames = (theme: Theme) => {
  const nextThemeClassNames = getClassNameForTheme(theme);
  document.body.className = [
    document.body.className.replaceAll(/theme-\w+/g, ''),
    nextThemeClassNames,
  ]
    .join(' ')
    .trim();
};

const themeExtendsLettuce = _.memoize((theme: Theme) => {
  const extensions = ThemeExtensions[theme];
  if (extensions) {
    return extensions.includes(Theme.Lettuce);
  }

  return false;
});

const ThemeProvider: FCC<ThemeProviderProps> = ({ theme = DefaultTheme, setTheme, children }) => {
  const { reset: resetParentTheme, themeLineage } = useThemeContext();
  const reset = useEventCallback(() => applyThemeClassNames(theme));
  useEffect(() => applyThemeClassNames(theme), [theme]);
  useEffect(() => resetParentTheme, [resetParentTheme]);
  const isLettuceTheme = theme === Theme.Lettuce || themeExtendsLettuce(theme);

  // This may look redundant, however it is a necessary artifice to postpone a call to reset
  // until the next render. Using an effect directly on themeLineage causes a race condition
  // in which the majority of cases the parent theme provider succeeds in applying the classNames
  // on the body tag.
  // This should be temporary until we fully transition from the legacy theme and stop supporting
  // theme switching and implement a better way of handling theme extension.
  const [lastLineage, setLastLineage] = useState(themeLineage);
  useEffect(() => {
    setLastLineage(themeLineage);
  }, [themeLineage]);
  useEffect(reset, [reset, lastLineage]);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        isLettuceTheme,
        setTheme: setTheme ?? noop,
        reset,
        themeLineage: [lastLineage, theme].filter(Boolean).join(','),
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

// Check if the stored theme value is part of the enum (i.e. is supported)
const isThemeSupported = (theme: Theme) => Object.values(Theme).includes(theme);

type PersistentThemeProviderProps = {
  defaultTheme?: Theme;
};

export const PersistentThemeProvider: FCC<PersistentThemeProviderProps> = ({
  defaultTheme = Theme.Legacy,
  children,
}) => {
  const [theme, setTheme] = useLocalStorageState(LocalStorageKey.Theme, defaultTheme);
  const { getHasGlobalAdminPermission, loading } = useAuthorizationContext();
  const isGlobalAdmin = getHasGlobalAdminPermission();

  useEffect(() => {
    if (!loading) {
      if (
        !isThemeSupported(theme) ||
        (!isGlobalAdmin && !isAccountBoundTheme(theme) && theme !== Theme.Rema)
      ) {
        setTheme(Theme.Lettuce);
      }
    }
  }, [theme, setTheme, isGlobalAdmin, loading]);

  return (
    <ThemeProvider theme={theme} setTheme={setTheme}>
      {children}
    </ThemeProvider>
  );
};

export default ThemeProvider;

export const useThemeContext = () => useContext(ThemeContext);
