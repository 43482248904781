import React from 'react';
import clsx from 'clsx';
import { ReactComponent as CheckedIcon } from '../../../../assets/icons/check-1.svg';
import styles from './CopiedToClipboard.module.css';

export default function CopiedToClipboard() {
  return (
    <span className={clsx(styles.copiedToClipboard)}>
      <CheckedIcon width={14} /> Copied!
    </span>
  );
}
