import React from 'react';
import Button, { ButtonProps, ButtonVariant } from '../index';
import { Key } from '../../../../../utils/keyCodes';
import { useGlobalOnKeyDown } from '../../../utils/useGlobalKeyListener';

const isTextAreaFocused = () =>
  document.activeElement && document.activeElement.tagName === 'TEXTAREA';

const isSearchBoxFocused = () => {
  const el = document.activeElement;
  return (
    el &&
    (el.role === 'searchbox' || (el.tagName === 'INPUT' && el.getAttribute('type') === 'search'))
  );
};

const shouldIgnoreEnterKeyPress = () => isTextAreaFocused() || isSearchBoxFocused();

type ActionButtonProps = Omit<ButtonProps, 'onClick'> & {
  onClick: () => void;
};

/**
 * An ActionButton is just a Button which can be toggled by an Enter key down,
 * iff a textarea element is not in focus.
 */
const ActionButton = React.forwardRef<HTMLButtonElement, ActionButtonProps>(function ActionButton(
  { onClick, children, ...buttonProps }: ActionButtonProps,
  ref,
) {
  useGlobalOnKeyDown(Key.Enter, e => {
    if (!shouldIgnoreEnterKeyPress()) {
      onClick();
      e.preventDefault();
    }
  });
  return (
    <Button variant={ButtonVariant.Primary} {...buttonProps} ref={ref} onClick={onClick}>
      {children}
    </Button>
  );
});

export default ActionButton;
