import React from 'react';
import { RouteComponentProps } from 'react-router';
import { parseQuery } from '../../../../utils/querystringUtils';
import { lazyWithRetry } from '../../../lib';
import UnfiSignIn from '../auth/UnfiSignIn';

const RenderUnfiSignIn = lazyWithRetry<typeof UnfiSignIn>(
  () => import(/* webpackChunkName: "unfiEmbeddedSignIn" */ '../auth/UnfiSignIn'),
);

export default function SignInPage({ location }: RouteComponentProps) {
  const { returnTo, redirectUri } = parseQuery(location.search);

  return (
    <RenderUnfiSignIn
      returnToUri={returnTo?.toString()}
      returnToExternalUri={redirectUri?.toString()}
    />
  );
}
