import React, { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { BookmarkFragmentWithDashboardInfo } from '../../../../BookmarksContext';
import {
  BookmarkScope,
  useUpdateLookerBookmarkAndSetSharesMutation,
  useUpdateLookerBookmarkMutation,
  useUpdateUnfiInsightsLookerBookmarkMutation,
} from '../../../../../generated/graphql';
import { SearchSelectUserEntity } from '../../../components/UserSearchSelect';
import { useGetDashboardById } from '../../../../businessDashboard/hooks/useGetDashboardGroups';
import { isUnfiInsightsDashboard } from '../../../../lib';
import SaveModal from '../../../components/Modal/SaveModal';
import RenderReportMetadata from './RenderReportMetadata';
import ShareReport from './ShareReport';
import useGetAccountUsersForSharing from './useGetAccountUsersForSharing';
import { ReportAccessOptions, ReportLimitedAccessOptions } from './lib';

type ShareReportModalProps = {
  accountId: string;
  onClose: () => void;
  report: BookmarkFragmentWithDashboardInfo;
};

export type FormValues = {
  scope: BookmarkScope;
  users: SearchSelectUserEntity[];
  message?: string;
};

const ShareReportModal: React.FC<ShareReportModalProps> = ({ accountId, onClose, report }) => {
  const { users, loading } = useGetAccountUsersForSharing(accountId);

  const [updateBookmarkAndSetShares, { loading: isSavingUpdateAndSetShares }] =
    useUpdateLookerBookmarkAndSetSharesMutation({
      onCompleted: onClose,
    });

  const [updateBookmark, { loading: isSavingUpdateBookmark }] = useUpdateLookerBookmarkMutation({
    onCompleted: onClose,
  });

  const [updateUnfiBookmark, { loading: isSavingUnfiBookmark }] =
    useUpdateUnfiInsightsLookerBookmarkMutation({
      onCompleted: onClose,
    });

  const getDashboardById = useGetDashboardById(accountId);
  const isUnfiDashboard = useMemo(() => {
    const dashboard = getDashboardById(report);
    return isUnfiInsightsDashboard(dashboard);
  }, [getDashboardById, report]);

  const isSaving = isSavingUpdateBookmark || isSavingUpdateAndSetShares || isSavingUnfiBookmark;
  const bookmarkId = report.id;

  const onSubmit = useCallback(
    (data: FormValues) => {
      const { users, message } = data;
      const baseInput = {
        bookmarkId,
        scope: data.scope,
      };

      if (isUnfiDashboard) {
        updateUnfiBookmark({ variables: { input: baseInput } });
      } else if (data.scope === BookmarkScope.UserShared) {
        updateBookmarkAndSetShares({
          variables: {
            accountId,
            input: {
              ...baseInput,
              userEmails: users.map(u => u.email),
              message,
            },
          },
        });
      } else {
        updateBookmark({
          variables: {
            accountId,
            input: {
              ...baseInput,
              // Clear saved emails
              userEmails: [],
            },
          },
        });
      }
    },
    [
      accountId,
      bookmarkId,
      updateBookmarkAndSetShares,
      updateBookmark,
      updateUnfiBookmark,
      isUnfiDashboard,
    ],
  );

  const useFormMethods = useForm<FormValues>({
    defaultValues: { scope: report.scope, users: report.users ?? [], message: '' },
  });

  return (
    <SaveModal
      title="Share report"
      onCloseClick={onClose}
      onSave={useFormMethods.handleSubmit(onSubmit)}
      size="S"
      isSaving={isSaving}
    >
      <div className="mvm">
        <RenderReportMetadata report={report} includeDescription={true} />
        <FormProvider {...useFormMethods}>
          <ShareReport
            accountUsers={users}
            accountUsersLoading={loading}
            disabled={isSaving}
            control={useFormMethods.control}
            accessOptions={isUnfiDashboard ? ReportLimitedAccessOptions : ReportAccessOptions}
          />
        </FormProvider>
      </div>
    </SaveModal>
  );
};

export default ShareReportModal;
