import React from 'react';
import Button, { ButtonVariant } from '../Button';

type ToggleExpandButtonProps = {
  isExpanded: boolean;
  onToggle: () => void;
  collapsedCount: number;
  totalCount: number;
};

const ToggleExpandButton: React.FC<ToggleExpandButtonProps> = ({
  isExpanded,
  onToggle,
  collapsedCount,
  totalCount,
}) => (
  <Button
    variant={ButtonVariant.Text}
    onClick={onToggle}
    aria-label={isExpanded ? `Show only ${collapsedCount} items` : `Show all ${totalCount} items`}
  >
    Show {isExpanded ? 'less' : 'more'}
  </Button>
);

export default ToggleExpandButton;
