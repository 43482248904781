import React from 'react';
import { KeyHandlerEventProps, OnKeyDownHandler } from '../getListKeyHandler';
import { UseFocusableItem } from './core/useFocusableItemIndex';
import { UseActionableItem } from './core/useActionableItemIndex';
import { UseSelectableItem } from './core/useListItemSingleSelection';

type PropsWithSpecificMergePolicies = {
  onMouseDown: (e: React.MouseEvent) => void;
  onKeyDown: OnKeyDownHandler;
  onBlur: (e: React.FocusEvent) => void;
};

const composeTwoProps = <
  T1 extends Partial<PropsWithSpecificMergePolicies>,
  T2 extends Partial<PropsWithSpecificMergePolicies>,
>(
  props1: T1,
  props2: T2,
): T1 & T2 => ({
  ...props1,
  ...props2,
  // May specify custom merge policies for other props here
  onMouseDown:
    props1.onMouseDown && props2.onMouseDown
      ? e => {
          props1.onMouseDown?.(e);
          props2.onMouseDown?.(e);
        }
      : props1.onMouseDown || props2.onMouseDown,
  onKeyDown:
    props1.onKeyDown && props2.onKeyDown
      ? (e: KeyHandlerEventProps) => props1.onKeyDown?.(e) || props2.onKeyDown?.(e)
      : props1.onKeyDown || props2.onKeyDown,
  onBlur:
    props1.onBlur && props2.onBlur
      ? (e: React.FocusEvent) => {
          props1.onBlur?.(e);
          props2.onBlur?.(e);
        }
      : props1.onBlur || props2.onBlur,
});

export const composeProps = <T extends Partial<PropsWithSpecificMergePolicies>>(...props: T[]): T =>
  props.reduce(composeTwoProps, {} as T);

export type ActionableListHooks = {
  useFocusableItem: UseFocusableItem;
  useActionableItem: UseActionableItem;
};

export type SelectableListHooks<T> = ActionableListHooks & {
  useSelectableItem: UseSelectableItem<T>;
};

export type ActionableListProps = ActionableListHooks & {
  focusTransitionsEnabled: boolean;
};

export type SelectableListProps<T> = ActionableListProps &
  Pick<SelectableListHooks<T>, 'useSelectableItem'>;
