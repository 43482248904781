import { Dispatch, HTMLAttributes, SetStateAction, useCallback, useState } from 'react';
import { useDefaultHashId } from '../../../lib';
import { getItemId } from '../ControlledListOfOptions/lib';

type UseSearchListOfItemsParams = {
  baseListId: string;
};

type UseSearchListOfItems = {
  searchProps: Pick<
    HTMLAttributes<HTMLInputElement>,
    'aria-autocomplete' | 'aria-controls' | 'aria-activedescendant'
  >;
  focusedIndex: number;
  setFocusedIndex: Dispatch<SetStateAction<number>>;
  listId: string;
  getItemId: (item: unknown, index: number) => string;
};

const useSearchListOfItems = ({ baseListId }: UseSearchListOfItemsParams): UseSearchListOfItems => {
  const [focusedIndex, setFocusedIndex] = useState(0);
  const listId = useDefaultHashId(baseListId);

  return {
    searchProps: {
      'aria-autocomplete': 'list',
      'aria-controls': listId,
      'aria-activedescendant': getItemId(listId, focusedIndex),
    },
    listId,
    focusedIndex,
    setFocusedIndex,
    getItemId: useCallback((_, index) => getItemId(listId, index), [listId]),
  };
};

export default useSearchListOfItems;
