import React from 'react';
import { Connector, ConnectorApplication } from '../../../../generated/graphql';
import { ReactComponent as IconConnectors } from '../../../../assets/icons/lettuce/connectors.svg';
import { LogoContainer, LogoContainerProps } from '../lib/Logo';
import { useHasLogo } from './ConnectorLogoProvider';

const LogoOverwrites: Readonly<Partial<Record<ConnectorApplication, string>>> = {
  [ConnectorApplication.DollarGeneral]: 'DOLLAR GENERAL',
  [ConnectorApplication.Kroger]: 'Kroger',
  [ConnectorApplication.As2Kroger]: "Kroger's AS2",
};

export type ConnectorLogoProps = {
  connector: Pick<Connector, 'application'> & Partial<Pick<Connector, 'name'>>;
} & LogoContainerProps;

// Upload new logos here: https://console.cloud.google.com/storage/browser/crisp-platform-us-logos;tab=objects?forceOnBucketsSortingFiltering=true&project=crisp-platform-us
const getURLForConnectorApplication = (connectorApplication: string): string =>
  `${process.env.REACT_APP_CONNECTOR_LOGOS_URL}/${connectorApplication.toLowerCase()}.png`;

const ConnectorLogo: React.FC<ConnectorLogoProps> = ({ connector, ...rest }) => {
  const { name, application } = connector;
  const logoOverwrite = LogoOverwrites[application];
  const hasLogo = useHasLogo();

  return (
    <LogoContainer {...rest}>
      {logoOverwrite || !hasLogo(connector) ? (
        <IconConnectors />
      ) : (
        <img src={getURLForConnectorApplication(application)} alt={`${name ?? application} logo`} />
      )}
    </LogoContainer>
  );
};

export default ConnectorLogo;

export const GenericConnectorLogo: React.FC<LogoContainerProps> = props => {
  return (
    <LogoContainer {...props}>
      <IconConnectors />
    </LogoContainer>
  );
};
