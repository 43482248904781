import React from 'react';
import { RouteComponentProps } from 'react-router';
import AcceptUserInvitation from '../AcceptUserInvitation';
import AuthenticationLayout from '../authentication/AuthenticationTemplate';
import { lazyWithRetry } from '../../../lib';

const RenderAcceptUserInvitation = lazyWithRetry<typeof AcceptUserInvitation>(
  () => import(/* webpackChunkName: "acceptUserInvitation" */ '../AcceptUserInvitation'),
);

type MatchParams = {
  accountId: string;
  invitationId: string;
  token: string;
};

const UserInvitationPage: React.FC<RouteComponentProps<MatchParams>> = ({ match }) => {
  const { accountId, invitationId, token } = match.params;
  return (
    <AuthenticationLayout>
      <RenderAcceptUserInvitation accountId={accountId} invitationId={invitationId} token={token} />
    </AuthenticationLayout>
  );
};

export default UserInvitationPage;
