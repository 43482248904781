import React, { useCallback, useEffect, useState } from 'react';
import { Maybe } from '@tellurian/ts-utils';
import { useToastNotification } from '../lib/notifications/ToastNotificationContext';
import { NotificationType } from '../lib/notifications/lib';
import { useOnKeyDown } from '../../../../utils/hooks';
import Button, { ButtonVariant } from '../Button';
import useEventCallback from '../../utils/useEventCallback';
import { pageErrorHasRedirect } from '../PageErrorRedirect';
import { PageError, usePageErrorListener } from './lib';

const ContactUs = () => <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>contact us</a>;
const RefreshThePage = () => (
  <Button variant={ButtonVariant.Text} onClick={() => window.location.reload()}>
    refresh the page
  </Button>
);

const UnknownError = () => (
  <div>
    We were unable to process your request due to an unexpected error. Please <ContactUs /> if the
    problem persists.
  </div>
);

const NotFound = () => (
  <div>
    We&apos;re sorry. You are unexpectedly accessing a resource that does not exist. Please{' '}
    <ContactUs /> if you think this is an error.
  </div>
);

const ServerError = () => (
  <div>
    An unexpected error has occurred on our end. Please try again, and <ContactUs /> if the problem
    persists.
  </div>
);

export const Forbidden = () => (
  <div>
    You are unexpectedly accessing a resource you do not have access to. Please try again and{' '}
    <ContactUs /> if the problem persists.
  </div>
);

const NetworkError = () => (
  <div>
    We are unable to communicate with the Crisp service. Check your Internet connection, try again
    and <ContactUs /> if the problem persists.
  </div>
);

const ServiceUnavailable = () => (
  <div>
    The service is temporarily unavailable. Please try again, <RefreshThePage /> and <ContactUs />{' '}
    if the problem persists.
  </div>
);

const SomeServicesUnavailable = () => (
  <div>
    Some services are temporarily unavailable. Please try again, <RefreshThePage /> and{' '}
    <ContactUs /> if the problem persists.
  </div>
);

const PageErrorToMessage: Readonly<Record<PageError, React.FC>> = {
  [PageError.FeatureFlagError]: SomeServicesUnavailable,
  [PageError.Forbidden]: Forbidden,
  [PageError.NetworkError]: NetworkError,
  [PageError.NotFound]: NotFound,
  [PageError.ServerError]: ServerError,
  [PageError.ServiceUnavailable]: ServiceUnavailable,
  [PageError.UnknownError]: UnknownError,
};

const PageErrorNotifications: React.FC = () => {
  const [error, setError] = useState<Maybe<PageError>>();
  const onDismiss = useCallback(() => setError(undefined), []);
  const onDismissAnimationComplete = useCallback(() => setError(undefined), []);
  const { show, dismiss } = useToastNotification({
    notificationType: NotificationType.Warning,
    dismissOnPathnameChange: true,
    onDismiss,
    onDismissAnimationComplete,
  });
  const errorListener = useEventCallback((err: PageError) => {
    if (!pageErrorHasRedirect(err)) {
      setError(err);
    }
  });

  usePageErrorListener(errorListener);
  useEffect(() => {
    if (error) {
      const ErrorMessage = PageErrorToMessage[error];
      if (ErrorMessage) {
        show({
          notificationType:
            error === PageError.NetworkError ? NotificationType.Failure : NotificationType.Warning,
          content: <ErrorMessage />,
        });
      }
    }
  }, [error, show]);
  useOnKeyDown('Escape', e => {
    if (error) {
      setError(undefined);
      dismiss();
      e.stopPropagation();
    }
  });

  return null;
};

export default PageErrorNotifications;
