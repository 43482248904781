import React from 'react';
import BiReportTemplate from '../templates/BiReportTemplate';
import RetailAnalytics from '../RetailAnalytics';
import { lazyWithRetry } from '../../../lib';
import { AccountIdMatchParams } from './lib';

const RenderRetailAnalytics = lazyWithRetry<typeof RetailAnalytics>(
  () => import(/* webpackChunkName: "retailAnalytics" */ '../RetailAnalytics'),
);

const BiReportPage: React.FC<AccountIdMatchParams> = ({
  match: {
    params: { accountId },
  },
}) => {
  return (
    <BiReportTemplate accountId={accountId}>
      <RenderRetailAnalytics accountId={accountId} />
    </BiReportTemplate>
  );
};

export default BiReportPage;
