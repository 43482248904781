import React from 'react';
import clsx from 'clsx';
import style from './index.module.css';

export type PanelProps = {
  decorated?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const Panel = React.forwardRef<HTMLDivElement, PanelProps>(function Panel(
  { decorated, className, children, ...divProps }: PanelProps,
  ref: React.Ref<HTMLDivElement>,
) {
  return (
    <div
      ref={ref}
      className={clsx(style.panel, { [style.decorated]: decorated }, className)}
      {...divProps}
    >
      {children}
    </div>
  );
});

export default Panel;
