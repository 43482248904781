import { useMemo } from 'react';
import { Maybe } from '@tellurian/ts-utils';
import useListOfItemsActionable, {
  UseListOfItemsActionable,
} from '../../../ui/lists/listOfItems/useListOfItemsActionable';
import useEventCallback from '../../utils/useEventCallback';
import { KeyHandlerEventProps } from '../../../ui/lists/getListKeyHandler';
import { Key } from '../../../../utils/keyCodes';
import useDropdown, { DropdownReferenceType, UseDropdown, UseDropdownParams } from './useDropdown';
import useDropdownListCommon, {
  maybeStopKeyDownEventPropagation,
  ReferenceProps,
} from './useDropdownListCommon';

const getListItemAtRelativeIndex = <T>(
  items: readonly T[],
  selectedItem: Maybe<T>,
  indexOffset: number,
): Maybe<T> => {
  if (selectedItem && items.length > 1) {
    const index = items.findIndex(e => Object.is(e, selectedItem));
    if (index > -1) {
      const nextIndex = index + indexOffset;
      return items[(nextIndex < 0 ? items.length + nextIndex : nextIndex) % items.length];
    }
  }

  return undefined;
};

export type UseDropdownListOfItemsSelectParams<T> = {
  items: readonly T[];
  selectedItem?: T;
  onChange: (item: T) => void;
  stopKeyDownPropagation?: boolean | ((isActive: boolean) => boolean);
  toggleListItemOnSpace?: boolean;
  toggleOnSpace?: boolean;
} & Pick<UseDropdownParams, 'placement' | 'popoverId' | 'role' | 'getPopoverSizeProps'>;

export type UseDropdownListOfItemsSelect<T, R extends HTMLElement = HTMLButtonElement> = Pick<
  UseDropdown,
  'isActive' | 'setIsActive'
> & {
  listProps: Omit<UseListOfItemsActionable<T>, 'getFocusParentProps'>;
  getPopoverProps: UseDropdown['getPopoverProps'];
  getReferenceProps: <P extends DropdownReferenceType>(referenceType?: P) => ReferenceProps<R, P>;
};

const useDropdownListOfItemsSelect = <T, R extends HTMLElement = HTMLButtonElement>({
  items,
  onChange,
  selectedItem,
  toggleListItemOnSpace,
  // Some parent controls (e.g. input) do not trigger a click action when space is pressed. Use this prop
  // explicitly add keyDown handler that toggles the dropdown enabled when the space key is pressed.
  toggleOnSpace = false,
  // By default, prevent other keydown handlers from executing on this event, when handled by this hook
  stopKeyDownPropagation = true,
  ...dropdownProps
}: UseDropdownListOfItemsSelectParams<T>): UseDropdownListOfItemsSelect<T, R> => {
  const { getReferenceProps, getPopoverProps, isActive, setIsActive } =
    useDropdown<R>(dropdownProps);
  const { getFocusParentProps, ...listProps } = useListOfItemsActionable<T>({
    items,
    isListItemMouseDownDefaultPrevented: true,
    onItemToggle: item => {
      onChange(item);
      setIsActive(false);
    },
    toggleOnSpace: toggleListItemOnSpace,
  });

  const { setFocusedIndex, getFocusedIndex } = listProps;
  const maybeStopEventPropagation = useMemo(
    () => maybeStopKeyDownEventPropagation(stopKeyDownPropagation),
    [stopKeyDownPropagation],
  );

  // Keydown handler specific to list select dropdowns - to be composed with focusable and actionable keydown handlers
  const onKeyDownWhenActive = useEventCallback((e: KeyHandlerEventProps) => {
    if (e.key === Key.Tab) {
      e.preventDefault();
      maybeStopEventPropagation(isActive, e);
      const focusedItem = items[getFocusedIndex()];
      if (focusedItem) {
        onChange(focusedItem);
      }
      setIsActive(false);
      // Treat the event as handled to skip the focusable handlers.
      // closes the dropdown.
      return true;
    } else if (e.key === Key.Escape) {
      maybeStopEventPropagation(isActive, e);
      setIsActive(false);
      return true;
    }

    return false;
  });

  const onKeyDownWhenInactive = useEventCallback((e: KeyHandlerEventProps) => {
    // Enter and Space is normally handled by the default button behaviour. However
    // not all dropdown parents will be button elements
    if (
      e.key === Key.UpArrow ||
      e.key === Key.DownArrow ||
      (toggleOnSpace && e.key === Key.Space)
    ) {
      maybeStopEventPropagation(isActive, e);
      // Prevent scroll if handling these keys explicitly
      e.preventDefault();
      setIsActive(true);
      return true;
    } else if (e.key === Key.LeftArrow || e.key === Key.RightArrow) {
      const nextItem = selectedItem
        ? getListItemAtRelativeIndex(items, selectedItem, e.key === Key.LeftArrow ? -1 : 1)
        : items[getFocusedIndex()];
      if (nextItem) {
        onChange(nextItem);
        maybeStopEventPropagation(isActive, e);
        return true;
      }
    }

    return false;
  });

  const { getComposedReferenceProps } = useDropdownListCommon<R>({
    ...listProps,
    getReferenceProps,
    getFocusParentProps,
    isActive,
    setIsActive,
    setFocusedIndex,
    onKeyDownWhenInactive,
    onKeyDownWhenActive,
  });

  return {
    listProps,
    isActive,
    setIsActive,
    getPopoverProps,
    getReferenceProps: getComposedReferenceProps,
  };
};

export default useDropdownListOfItemsSelect;
