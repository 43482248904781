import React from 'react';
import clsx from 'clsx';
import { FCC } from '../../../../utils/types';
import style from './index.module.css'; // eslint-disable-line css-modules/no-unused-class

const GapSizes = ['S', 'M', 'L'] as const;
type GapSize = (typeof GapSizes)[number];

export type AutoFitItemsLayoutProps = {
  itemMinWidth: number | string;
  gap?: number | GapSize;
  rowGap?: number | GapSize;
  columnGap?: number;
  className?: string;
  as?: 'div' | 'ul';
};

const AutoFitItemsLayout: FCC<AutoFitItemsLayoutProps> = ({
  children,
  className,
  itemMinWidth,
  as = 'div',
  gap,
  rowGap,
  ...rest
}) => {
  const Container = as;
  const minWidth = typeof itemMinWidth === 'number' ? `${itemMinWidth}px` : itemMinWidth;

  return (
    <Container
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(auto-fit, minmax(${minWidth}, 1fr))`,
        gap: typeof gap === 'number' ? gap : undefined,
        rowGap: typeof rowGap === 'number' ? rowGap : undefined,
        ...rest,
      }}
      className={clsx(
        className,
        typeof gap === 'string' && style[`gap-${gap}`],
        typeof rowGap === 'string' && style[`rowGap-${rowGap}`],
      )}
    >
      {children}
    </Container>
  );
};

export default AutoFitItemsLayout;
