import React from 'react';
import { RouteComponentProps } from 'react-router';

import { SubmitGoogleSheetsMasterData } from '../components/connectors/googleSheetsMasterData/SubmitGoogleSheetsMasterData';
import ConnectorsTemplate from '../pageTemplates/AccountTemplate/ConnectorsTemplate';

type MatchParams = {
  accountId: string;
  connectorConfigurationSlug: string;
};
export default function SubmitGoogleSheetsMasterDataPage({
  match: {
    params: { accountId, connectorConfigurationSlug },
  },
}: RouteComponentProps<MatchParams>) {
  return (
    <ConnectorsTemplate>
      <SubmitGoogleSheetsMasterData
        accountId={accountId}
        connectorConfigurationSlug={connectorConfigurationSlug}
      />
    </ConnectorsTemplate>
  );
}
