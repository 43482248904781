import React, { HTMLAttributes } from 'react';

import clsx from 'clsx';
import { ReactComponent as IconCheck } from '../../../assets/icons/check_16x16.svg';
import { ReactComponent as IconRectangle } from '../../../assets/icons/Rectangle.svg';
import { ReactComponent as CheckedIcon } from '../../../assets/icons/check-circle-1.svg';
import { ReactComponent as UncheckedIcon } from '../../../assets/icons/circle-stroke.svg';
import styles from './CheckboxAndRadio.module.css';

type Props = {
  checked: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
  label?: React.ReactNode;
  name: string;
  onChange: (isChecked: boolean) => void;
  className?: string;
  stopClickPropagation?: boolean;
  stopMouseDownPropagation?: boolean;
} & Pick<HTMLAttributes<HTMLInputElement>, 'aria-label' | 'tabIndex'>;

export const Checkbox = React.forwardRef(
  (
    {
      checked,
      disabled = false,
      indeterminate,
      label,
      name,
      onChange,
      className,
      stopClickPropagation = false,
      stopMouseDownPropagation = false,
      ...rest
    }: Props,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <div
        className={clsx(styles.main, className)}
        onClick={stopClickPropagation ? e => e.stopPropagation() : undefined}
      >
        <label className={styles.label}>
          <div className={styles.inputContainer}>
            <div
              className={clsx(styles.inputOverlay, {
                [styles.checked]: checked,
                [styles.unchecked]: !checked,
                [styles.indeterminate]: indeterminate,
                [styles.disabled]: disabled,
              })}
            >
              {indeterminate ? <IconRectangle /> : (checked || null) && <IconCheck />}
            </div>
            <input
              checked={checked}
              disabled={disabled}
              className={styles.input}
              name={name}
              onChange={() => onChange(!checked)}
              type="checkbox"
              onMouseDown={stopMouseDownPropagation ? e => e.stopPropagation() : undefined}
              ref={ref}
              {...rest}
            />
          </div>
          {label}
        </label>
      </div>
    );
  },
);
Checkbox.displayName = 'Checkbox';

export type RadioProps = Partial<Exclude<Props, 'indeterminate'>> & {
  value?: string;
  testId?: string;
};

export const Radio = React.forwardRef(
  (
    { checked, disabled, label, name, onChange, className, value, testId }: RadioProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    return (
      <div className={clsx(styles.main, className)} data-testid={testId}>
        <label className={clsx(styles.label, { [styles.disabled]: disabled })}>
          <input
            checked={checked}
            className={styles.radioInput}
            disabled={disabled}
            name={name}
            onChange={event => onChange && onChange(event.target.checked)}
            type="radio"
            value={value}
            ref={ref}
          />
          {label}
        </label>
      </div>
    );
  },
);
Radio.displayName = 'Radio';

/**
 * This element does NOT work with useForm
 *
 * The issue is that the overlay is toggled independent of the actual state of the
 * radio button input element.
 *
 * I tried to reference the radio button input element and draw the checked value from
 * it but the problem is toggling the overlays of the other radio buttons in the group
 * when a new one is selected. When a different radio button is selected then it will
 * display the overlay but the previously selected radio button needs to know to toggle
 * it's overlay off.
 *
 * Since the radio button input element references do not trigger renders- they would
 * need to be informed by a parent component and I found this hard to implement within
 * the useForm construct.
 */
export const RadioIncompatibleWithUseForm = React.forwardRef(
  (
    { checked, disabled, label, name, onChange, className, value }: RadioProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const Input = () => (
      <div className={clsx(styles.inputContainer, className)}>
        <div
          className={clsx(styles.inputOverlay, styles.radioInputOverlay, {
            [styles.radioInputDisabled]: disabled,
          })}
        >
          {checked ? <CheckedIcon /> : <UncheckedIcon />}
        </div>
        <input
          checked={checked}
          disabled={disabled}
          className={clsx(styles.input)}
          name={name}
          ref={ref}
          onChange={event => onChange && onChange(event.target.checked)}
          type="radio"
          value={value}
        />
      </div>
    );

    return (
      <div className={clsx(styles.main, className)}>
        {label ? (
          <label className={clsx(styles.label, { [styles.radioInputLabelDisabled]: disabled })}>
            <Input />
            {label}
          </label>
        ) : (
          <Input />
        )}
      </div>
    );
  },
);

RadioIncompatibleWithUseForm.displayName = 'RadioIncompatibleWithUseForm';
