import { useCallback, useMemo } from 'react';
import { maybe, Maybe } from '@tellurian/ts-utils';
import {
  PolicyVersionFragment,
  TermsOfServicePolicyType,
  useGetTermsOfServiceVersionsQuery,
} from '../../generated/graphql';

const useTermsOfServiceVersions = () => {
  const { data, loading, refetch } = useGetTermsOfServiceVersionsQuery();
  const termsOfService = data?.termsOfService;

  const { sortedVersions, getLatestVersion } = useMemo(() => {
    if (termsOfService) {
      const sortedVersions = termsOfService.reduce<
        Record<TermsOfServicePolicyType, PolicyVersionFragment[]>
      >(
        (acc, { policyType, versions }) => {
          acc[policyType] = versions.slice().sort((v1, v2) => v2.version - v1.version);
          return acc;
        },
        {} as Record<TermsOfServicePolicyType, PolicyVersionFragment[]>,
      );

      const getLatestVersion = (
        policyType: TermsOfServicePolicyType,
      ): Maybe<PolicyVersionFragment> => {
        return maybe(termsOfService.find(tos => tos.policyType === policyType)?.latestVersion);
      };

      return { sortedVersions, getLatestVersion };
    }

    return { sortedVersions: {}, getLatestVersion: () => undefined };
  }, [termsOfService]);

  const getVersion = useCallback(
    (policyType: TermsOfServicePolicyType, version: number): Maybe<PolicyVersionFragment> => {
      return maybe(sortedVersions[policyType].find(v => v.version === version));
    },
    [sortedVersions],
  );

  return { versions: sortedVersions, getLatestVersion, getVersion, loading, refetch };
};

export const useTermsOfServiceLatestVersion = (
  policyType: Maybe<TermsOfServicePolicyType>,
): Maybe<PolicyVersionFragment> => {
  const { getLatestVersion } = useTermsOfServiceVersions();
  return useMemo(() => policyType && getLatestVersion(policyType), [policyType, getLatestVersion]);
};

export default useTermsOfServiceVersions;
