import React from 'react';
import styles from '../../PageMessage/View.module.css'; // eslint-disable-line
import { Infobar } from '../../ui/Infobar';
import { InfoType } from '../../ui/Infobar/lib';
import usePageError from './usePageError';

/**
 * Different error types. For instance, a `page` level error message might not
 * be shown if there is also a `modal` level error message on the page.
 */
export type PageErrorType = 'modal' | 'page';

export type PageErrorProps = {
  className?: string;
  type: PageErrorType; // The type of this page error
  hideIfTypePresent?: PageErrorType; // Do now show errors if the other type is present
};

export default function PageError({ className, hideIfTypePresent, type }: PageErrorProps) {
  const { errorMessage, dismissMessage, listenerTypes } = usePageError(type);

  if (
    errorMessage &&
    (hideIfTypePresent === undefined || !listenerTypes.includes(hideIfTypePresent))
  )
    return (
      <Infobar className={className} type={InfoType.Warning} onDismissed={dismissMessage}>
        <div className={styles.body}>{errorMessage}</div>
      </Infobar>
    );
  else return null;
}
