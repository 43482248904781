import React from 'react';
import clsx from 'clsx';
import { FCC } from '../../../../utils/types';
import { TitleSize } from '../Title';
import style from './index.module.css'; // eslint-disable-line

export const HeadlineSizes = ['L', 'M', 'S'] as const;
export type HeadlineSize = (typeof HeadlineSizes)[number];

/**
 * The semantic significance is tied to the size presently. We can change this if this becomes
 * unsuitable in the future by introducing a `primary: boolean` prop instead and keeping size
 * independent.
 * The rationale for this association is that it should never be necessary to have more than two large
 * heading types (i.e. h1/h2) on a page, however we may have circumstances where we wish to render a smaller
 * version of h2.
 * Note that M and S denote sizes only, and not a distinction between the type
 * of heading (e.g. h2 or h3), hence the intention is to use a single h1 of a fixed size
 * and multiple h2 of various sizes.
 */
const ComponentSizeMap: Readonly<Record<TitleSize, 'h1' | 'h2'>> = {
  L: 'h1',
  M: 'h2',
  S: 'h2',
};

type HeadlineProps = {
  size?: HeadlineSize;
  // To be used exceptionally, in circumstances where it might be necessary to include
  // a semantic `h1` with in size L or an `h3` where a 3 level context nesting is necessary
  as?: 'h1' | 'h2' | 'h3';
  id?: string;
  className?: string;
};

const Headline: FCC<HeadlineProps> = ({ size = 'L', children, as, className, id }) => {
  const HComponent = as ?? ComponentSizeMap[size];
  return (
    <HComponent className={clsx(style[size], className)} id={id}>
      {children}
    </HComponent>
  );
};

export default Headline;
