import React from 'react';
import { ReactComponent as IconInfo } from '../../../assets/icons/infoicon_outline_16x16.svg';
import { ReactComponent as GreenIconInfo } from '../../../assets/icons/infoicon_outline_16x16_green.svg';
import IconTooltip, { IconTooltipProps } from './IconTooltip';

type InfoIconTooltipProps = {
  isLinkInfo?: boolean;
} & Omit<IconTooltipProps, 'RenderIcon'>;

export default function InfoIconTooltip({ isLinkInfo = false, ...props }: InfoIconTooltipProps) {
  const InfoIcon = isLinkInfo ? GreenIconInfo : IconInfo;

  return <IconTooltip RenderIcon={InfoIcon} {...props} />;
}
