import React, { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { useAuthenticationContext } from './security/AuthenticationContext';
import { getEmailForRetailAnalyticsUser } from './lettuce/utils';

const IdentifyDatadogUser: React.FC = () => {
  const { firebaseUser } = useAuthenticationContext();

  useEffect(() => {
    if (firebaseUser) {
      const email = firebaseUser.email || getEmailForRetailAnalyticsUser() || undefined;
      const user = {
        id: firebaseUser.uid,
        name: firebaseUser.displayName ?? undefined,
        email,
      };
      datadogRum.setUser(user);
      datadogLogs.setUser(user);
    }
  }, [firebaseUser]);

  return null;
};

export default IdentifyDatadogUser;
