import React from 'react';
import ForbiddenBase from '../Forbidden';

export default function Forbidden() {
  return (
    <ForbiddenBase
      description={
        <>
          Please contact your account administrator to request the <strong>Admin</strong> role
          required to access this page.
        </>
      }
      resourceName="Connectors"
    />
  );
}
