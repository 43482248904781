import React, { ReactElement, useId, useMemo } from 'react';
import clsx from 'clsx';
import Radio, { RadioProps } from '../lib/Radio';
import { useDefaultHashId } from '../../../lib';
import FieldDetails from '../lib/FieldDetails';
import FieldLabel from '../lib/FieldLabel';
import style from './index.module.css';
import radioFieldStyle from '../lib/CheckboxOrRadio/CheckboxOrRadioField.module.css'; // eslint-disable-line css-modules/no-unused-class

type RadioFieldProps<T extends string = string> = RadioProps<T> & {
  label?: string;
  description?: string;
};

const RadioField = <T extends string = string>({
  className,
  description,
  label,
  id,
  ...radioProps
}: RadioFieldProps<T>) => {
  id = useDefaultHashId(id);
  return (
    <div className={className}>
      <div className={radioFieldStyle.container}>
        <Radio {...radioProps} id={id} />
        <label htmlFor={id} className={radioProps.disabled ? radioFieldStyle.disabled : undefined}>
          {label}
        </label>
      </div>
      <FieldDetails description={description} className={radioFieldStyle.details} />
    </div>
  );
};

type RadioItemProperties<T extends string = string> = {
  value: T;
  label?: string;
  description?: string;
};

export type RadioItem<T extends string = string> = T | RadioItemProperties<T>;

type CanonicalRadioItem<T extends string = string> = {
  value: T;
  label: string;
  description?: string;
};

const toCanonicalRadioItems = <T extends string = string>(
  radioItems: Readonly<RadioItem<T>[]>,
): CanonicalRadioItem<T>[] => {
  if (radioItems.length === 0) {
    return [];
  }

  if (typeof radioItems[0] === 'string') {
    return radioItems.map(value => ({ value, label: value as string }) as CanonicalRadioItem<T>);
  }

  return (radioItems as RadioItemProperties<T>[]).map(({ value, label, description }) => ({
    value,
    label: label ?? value,
    description,
  }));
};

export const RadioGroupDirections = ['row', 'column'] as const;
export type RadioGroupDirection = (typeof RadioGroupDirections)[number];

export type RadioGroupFieldProps<T extends string = string> = {
  id?: string;
  className?: string;
  radioItems: Readonly<RadioItem<T>[]>;
  value: string;
  onChange?: (nextValue: T) => void;
  label?: string | ReactElement;
  direction?: RadioGroupDirection;
} & Pick<RadioProps, 'name' | 'aria-labelledby'>;

const RadioGroupField = <T extends string = string>({
  id,
  label: labelProp,
  radioItems,
  name,
  className,
  value,
  onChange,
  direction = 'row',
  'aria-labelledby': ariaLabelledBy,
}: RadioGroupFieldProps<T>) => {
  const items = useMemo(() => toCanonicalRadioItems(radioItems), [radioItems]);
  name = useDefaultHashId(name);
  id = useDefaultHashId(id);
  const labelId = useId();
  // Only add a divider for column direction and if the items have descriptions.
  const hasDivider = direction === 'column' && !!items[0]?.description;

  return (
    <div className={clsx('mod', className)}>
      {typeof labelProp === 'string' ? (
        <FieldLabel id={labelId}>{labelProp}</FieldLabel>
      ) : (
        labelProp
      )}
      <div
        role="radiogroup"
        id={id}
        className={clsx(style.radioGroup, { [style.row]: direction === 'row' })}
        aria-labelledby={ariaLabelledBy ?? (typeof labelProp === 'string' ? labelId : undefined)}
      >
        {items.map(item => (
          <RadioField
            key={item.value}
            name={name}
            checked={value === item.value}
            onChange={isChecked => isChecked && onChange?.(item.value)}
            className={clsx({ [style.hasDivider]: hasDivider })}
            {...item}
          />
        ))}
      </div>
    </div>
  );
};

export default RadioGroupField;
