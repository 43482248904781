import { useCallback, useEffect, useRef } from 'react';
import { millisecondsToHours } from 'date-fns';
import {
  useGetAccountCountForAdminConsoleLazyQuery,
  useGetAccountsForAdminConsoleLazyQuery,
} from '../../generated/graphql';
import useLocalStorageState from '../../utils/localStorage/useLocalStorageState';
import { LocalStorageKey } from '../../utils/localStorage';
import CrispApp, { CrispAppNoop } from '../lettuce/crisp/CrispApp';
import { LocalDb } from './lib';

const RefreshFrequencyHours = 7 * 24; // One week

export type LastAccountDataFetch = {
  count: number;
  retrievedAt: number;
};

const useDataSync = () => {
  const [lastFetch, setLastFetch] = useLocalStorageState(
    LocalStorageKey.AdminConsoleLastAccountDataFetch,
    undefined,
  );
  const localAccounts = useRef(LocalDb.accounts).current;
  const [getAccounts] = useGetAccountsForAdminConsoleLazyQuery();
  const [getAccountCount, { data }] = useGetAccountCountForAdminConsoleLazyQuery();

  useEffect(() => {
    // Defer this API call as it is low priority
    setTimeout(() => {
      getAccountCount();
    }, 2000);
  }, [getAccountCount]);

  const accountCount = data?.accounts.totalCount;
  const updateAccountData = useCallback(() => {
    return getAccounts().then(result => {
      const accounts = result.data?.accounts.edges.map(({ node }) => node);
      if (accounts) {
        localAccounts.update(accounts);
        setLastFetch({
          retrievedAt: +new Date(),
          count: accounts.length,
        });
        return accounts;
      }

      return [];
    });
  }, [getAccounts, localAccounts, setLastFetch]);

  useEffect(() => {
    if (typeof accountCount === 'number') {
      localAccounts.count().then(count => {
        if (count !== accountCount) {
          updateAccountData();
        }
      });
    }
  }, [accountCount, localAccounts, updateAccountData]);

  useEffect(() => {
    if (
      !lastFetch ||
      millisecondsToHours(lastFetch.retrievedAt - +new Date()) > RefreshFrequencyHours
    ) {
      updateAccountData();
    }
  }, [lastFetch, updateAccountData]);

  useEffect(() => {
    CrispApp.updateAccountData = () => {
      console.log('Updating account data...');
      updateAccountData().then(accounts => {
        console.log(`Update complete. ${accounts.length} accounts retrieved.`);
      });
    };

    return () => {
      CrispApp.updateAccountData = CrispAppNoop;
    };
  }, [updateAccountData]);
};

export default useDataSync;
