import React from 'react';
import clsx from 'clsx';
import { keyDownTriggerOnClick } from '../../lib';
import { CellContentAlign, CellContentAlignClassNames } from './lib';
import styles from './SimpleCell.module.css'; // eslint-disable-line

export const isElementFocusableCell = (element: HTMLElement): boolean =>
  element.dataset.focusable === 'true';

export type SimpleCellProps = {
  alignContent?: CellContentAlign;
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
  title?: string;
  role?: string;
  tabIndex?: number;
};

export default React.forwardRef(function SimpleCell(
  {
    alignContent = CellContentAlign.LEFT,
    children,
    className,
    onClick,
    title,
    role,
    tabIndex,
  }: SimpleCellProps,
  ref?: React.Ref<HTMLDivElement>,
) {
  // If onClick is set, we are making sure that it at least has a 'button' role by default
  // but this is mostly used by EditableCell, which is setting role="textbox"
  // This eslint rule has a hard time detecting the dynamic role
  /* eslint-disable jsx-a11y/no-static-element-interactions */
  const defaultRole = onClick ? 'button' : undefined;

  return (
    <div
      ref={ref}
      title={title}
      className={clsx(
        styles.cell,
        alignContent && CellContentAlignClassNames[alignContent],
        {
          [styles.actionable]: !!onClick,
        },
        className,
      )}
      onClick={onClick}
      onKeyDown={onClick !== undefined ? keyDownTriggerOnClick(onClick) : undefined}
      tabIndex={tabIndex}
      role={role ? role : defaultRole}
    >
      {children}
    </div>
  );
});
