import React, { useEffect, useState } from 'react';
import useTermsOfService from '../TermsOfService/useTermsOfService';
import { routeIdFromPath } from '../lettuce/crisp/routing/lib';
import AcceptanceNotification from './AcceptanceNotification';
import MaybeAcknowledgementNotification from './MaybeAcknowledgementNotification';

type TermsOfServiceNotificationProps = { accountId: string };

const isTermsOfServiceRouteId = () =>
  routeIdFromPath(window.location.pathname) === 'TermsOfService';

const TermsOfServiceNotification: React.FC<TermsOfServiceNotificationProps> = ({ accountId }) => {
  const { loading, termsOfService } = useTermsOfService(accountId);

  if (!loading) {
    if (termsOfService && termsOfService.isUserPolicyAdmin && !isTermsOfServiceRouteId()) {
      return termsOfService.acceptance ? (
        <MaybeAcknowledgementNotification accountId={accountId} termsOfService={termsOfService} />
      ) : (
        <AcceptanceNotification accountId={accountId} />
      );
    }
  }

  return null;
};

const DeferredTermsOfServiceNotification: React.FC<TermsOfServiceNotificationProps> = ({
  accountId,
}) => {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    // Defer this to latter such that it does not compete with more important API
    // calls
    const to = setTimeout(() => {
      setIsReady(true);
    }, 2000);
    return () => clearTimeout(to);
  }, []);

  return isReady ? <TermsOfServiceNotification accountId={accountId} /> : null;
};

export default DeferredTermsOfServiceNotification;
