import React from 'react';
import clsx from 'clsx';
import { FCC } from '../../../../utils/types';
import style from './index.module.css'; // eslint-disable-line

export const TitleSizes = ['L', 'M', 'S'] as const;
export type TitleSize = (typeof TitleSizes)[number];

export type TitleProps = {
  size?: TitleSize;
  // To be used exceptionally, when nesting of contexts which utilise the Title component
  // is required, and we wish to emphasise the subordinate relation using the semantics
  // of `h` elements.
  // Use the `div` option to remove the header semantics, but re-use the Title style (e.g. in list items)
  as?: 'h2' | 'h3' | 'h4' | 'div';
  id?: string;
  className?: string;
};

const Title: FCC<TitleProps> = ({ size = 'L', children, as: Component = 'h3', id, className }) => {
  return (
    <Component className={clsx(style.title, style[size], className)} id={id}>
      {children}
    </Component>
  );
};

export default Title;
