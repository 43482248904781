import { ConnectorApplication } from '../../generated/graphql';

type DashboardId = string;
type Rule = {
  includeIf?: string;
  excludeIf?: string;
};
type ScopedRule = {
  Global?: Rule;
  DashboardGroups?: Record<string, Rule>;
};

type FeatureFlagDashboardRules = Readonly<Record<DashboardId, ScopedRule>>;

const Configuration: FeatureFlagDashboardRules = {
  'category-sales': {
    DashboardGroups: {
      [ConnectorApplication.Wegmans]: {
        includeIf: 'wegmansCategorySales',
      },
    },
  },
  chargebacks: {
    DashboardGroups: {
      [ConnectorApplication.Unfi]: {
        excludeIf: 'unfiChargebacksV2',
      },
    },
  },
  'chargebacks-v2': {
    DashboardGroups: {
      [ConnectorApplication.Unfi]: {
        includeIf: 'unfiChargebacksV2',
      },
    },
  },
  'daily-sales': {
    DashboardGroups: {
      [ConnectorApplication.Kehe]: {
        includeIf: 'dailySalesDashboard',
      },
      [ConnectorApplication.Target]: {
        includeIf: 'dailySalesDashboard',
      },
    },
  },
  distribution: {
    DashboardGroups: {
      [ConnectorApplication.BjsWholesale]: {
        includeIf: 'bjsWholesaleCoreDashboards',
      },
      [ConnectorApplication.DollarGeneral]: {
        includeIf: 'dollarGeneralDistribution',
      },
      [ConnectorApplication.Dot]: {
        includeIf: 'dotExpresswayCoreDashboards',
      },
      [ConnectorApplication.HarrisTeeter]: {
        includeIf: 'harrisTeeterCoreDashboards',
      },
      [ConnectorApplication.HomeDepot]: {
        includeIf: 'homeDepotCoreDashboards',
      },
      [ConnectorApplication.Kehe]: {
        excludeIf: 'cleanedStoreDataDashboards',
      },
      [ConnectorApplication.Shopify]: {
        includeIf: 'shopifyCoreDashboards',
      },
      [ConnectorApplication.SuperValu]: {
        includeIf: 'svHarborCoreDashboards',
      },
      [ConnectorApplication.Unfi]: {
        excludeIf: 'cleanedStoreDataDashboards',
      },
    },
  },
  'distribution-explore': {
    DashboardGroups: {
      [ConnectorApplication.Unfi]: {
        includeIf: 'distributionExploreDashboards',
      },
      [ConnectorApplication.Kehe]: {
        includeIf: 'distributionExploreDashboards',
      },
      [ConnectorApplication.Walmart]: {
        includeIf: 'distributionExploreDashboards',
      },
      [ConnectorApplication.WholeFoods]: {
        includeIf: 'distributionExploreDashboards',
      },
      [ConnectorApplication.Kroger]: {
        includeIf: 'distributionExploreDashboards',
      },
      [ConnectorApplication.SuperValu]: {
        includeIf: 'distributionExploreDashboards',
      },
      [ConnectorApplication.Target]: {
        includeIf: 'distributionExploreDashboards',
      },
      [ConnectorApplication.Wegmans]: {
        includeIf: 'distributionExploreDashboards',
      },
      [ConnectorApplication.BjsWholesale]: {
        includeIf: 'distributionExploreDashboards',
      },
      [ConnectorApplication.DollarGeneral]: {
        includeIf: 'distributionExploreDashboards',
      },
      [ConnectorApplication.Dot]: {
        includeIf: 'distributionExploreDashboards',
      },
      [ConnectorApplication.HarrisTeeter]: {
        includeIf: 'distributionExploreDashboards',
      },
      [ConnectorApplication.HomeDepot]: {
        includeIf: 'distributionExploreDashboards',
      },
      [ConnectorApplication.Shopify]: {
        includeIf: 'distributionExploreDashboards',
      },
    },
  },
  'fill-rate': {
    DashboardGroups: {
      [ConnectorApplication.Unfi]: {
        includeIf: 'unfiFillRate',
      },
    },
  },
  'fill-rate-to-stores': {
    DashboardGroups: {
      [ConnectorApplication.Kehe]: {
        includeIf: 'keheFillRateToStores',
      },
    },
  },
  'in-full': {
    DashboardGroups: {
      [ConnectorApplication.Walmart]: {
        includeIf: 'walmartInFull',
      },
    },
  },
  'in-stock': {
    DashboardGroups: {
      [ConnectorApplication.Walmart]: {
        includeIf: 'walmartInStock',
      },
    },
  },
  inventory: {
    DashboardGroups: {
      [ConnectorApplication.Aldi]: {
        includeIf: 'aldiInventory',
      },
      [ConnectorApplication.Kehe]: {
        includeIf: 'keheInventory',
      },
      [ConnectorApplication.Shopify]: {
        includeIf: 'shopifyInventory',
      },
      [ConnectorApplication.SuperValu]: {
        includeIf: 'svHarborInventory',
      },
      [ConnectorApplication.Unfi]: {
        includeIf: 'unfiInventory',
      },
      [ConnectorApplication.Walmart]: {
        includeIf: 'walmartInventory',
      },
    },
  },
  'inventory-store': {
    DashboardGroups: {
      [ConnectorApplication.DollarGeneral]: {
        includeIf: 'inventoryDashboards',
      },
      [ConnectorApplication.HarrisTeeter]: {
        includeIf: 'inventoryDashboards',
      },
      [ConnectorApplication.HomeDepot]: {
        includeIf: 'inventoryDashboards',
      },
      [ConnectorApplication.Kroger]: {
        includeIf: 'krogerInventoryDashboards',
      },
      [ConnectorApplication.Target]: {
        includeIf: 'inventoryDashboards',
      },
      [ConnectorApplication.Walmart]: {
        includeIf: 'inventoryDashboards',
      },
    },
  },
  'inventory-store-in-stock': {
    DashboardGroups: {
      [ConnectorApplication.DollarGeneral]: {
        includeIf: 'inventoryDashboards',
      },
      [ConnectorApplication.HomeDepot]: {
        includeIf: 'inventoryDashboards',
      },
      [ConnectorApplication.Target]: {
        includeIf: 'inventoryDashboards',
      },
      [ConnectorApplication.Walmart]: {
        includeIf: 'inventoryDashboards',
      },
    },
  },
  'inventory-distribution-center': {
    DashboardGroups: {
      [ConnectorApplication.DollarGeneral]: {
        includeIf: 'inventoryDashboards',
      },
      [ConnectorApplication.HarrisTeeter]: {
        includeIf: 'inventoryDashboards',
      },
      [ConnectorApplication.Kehe]: {
        includeIf: 'inventoryDashboards',
      },
      [ConnectorApplication.Kroger]: {
        includeIf: 'krogerInventoryDashboards',
      },
      [ConnectorApplication.SuperValu]: {
        includeIf: 'inventoryDashboards',
      },
      [ConnectorApplication.Target]: {
        includeIf: 'inventoryDashboards',
      },
      [ConnectorApplication.Unfi]: {
        includeIf: 'inventoryDashboards',
      },
      [ConnectorApplication.Walmart]: {
        includeIf: 'inventoryDashboards',
      },
    },
  },
  'inventory-distribution-center-aging': {
    DashboardGroups: {
      [ConnectorApplication.Kehe]: {
        includeIf: 'inventoryDashboards',
      },
    },
  },
  'on-time': {
    DashboardGroups: {
      [ConnectorApplication.Walmart]: {
        includeIf: 'walmartOnTime',
      },
    },
  },
  'on-time-in-full': {
    DashboardGroups: {
      [ConnectorApplication.Walmart]: {
        includeIf: 'walmartOnTimeInFull',
      },
    },
  },
  'potential-lost-sales': {
    DashboardGroups: {
      [ConnectorApplication.Unfi]: {
        includeIf: 'unfiPotentialLostSales',
      },
    },
  },
  sales: {
    DashboardGroups: {
      [ConnectorApplication.BjsWholesale]: {
        includeIf: 'bjsWholesaleCoreDashboards',
      },
      [ConnectorApplication.DollarGeneral]: {
        includeIf: 'dollarGeneralSales',
      },
      [ConnectorApplication.Dot]: {
        includeIf: 'dotExpresswayCoreDashboards',
      },
      [ConnectorApplication.HarrisTeeter]: {
        includeIf: 'harrisTeeterCoreDashboards',
      },
      [ConnectorApplication.HomeDepot]: {
        includeIf: 'homeDepotCoreDashboards',
      },
      [ConnectorApplication.Kehe]: {
        excludeIf: 'cleanedStoreDataDashboards',
      },
      [ConnectorApplication.Shopify]: {
        includeIf: 'shopifyCoreDashboards',
      },
      [ConnectorApplication.SuperValu]: {
        includeIf: 'svHarborCoreDashboards',
      },
      [ConnectorApplication.Unfi]: {
        excludeIf: 'cleanedStoreDataDashboards',
      },
    },
  },
  'sales-daily': {
    DashboardGroups: {
      [ConnectorApplication.Kehe]: {
        excludeIf: 'cleanedStoreDataDashboards',
      },
    },
  },
  'sales-daily-v2': {
    DashboardGroups: {
      [ConnectorApplication.Kehe]: {
        includeIf: 'keheLegacyDailySales',
      },
    },
  },
  'sales-drivers': {
    DashboardGroups: {
      [ConnectorApplication.Aldi]: {
        includeIf: 'aldiSalesDrivers',
      },
      [ConnectorApplication.SuperValu]: {
        includeIf: 'svHarborSalesDrivers',
      },
    },
  },
  'spoilage-risk': {
    DashboardGroups: {
      [ConnectorApplication.Unfi]: {
        includeIf: 'unfiSpoilageRisk',
      },
    },
  },
  'store-authorization': {
    DashboardGroups: {
      [ConnectorApplication.Kroger]: {
        includeIf: 'krogerStoreAuthorization',
      },
    },
  },
  'store-inventory': {
    DashboardGroups: {
      [ConnectorApplication.Walmart]: {
        includeIf: 'walmartStoreInventory',
      },
    },
  },
  'supply-plan': {
    DashboardGroups: {
      [ConnectorApplication.Walmart]: {
        includeIf: 'walmartSupplyPlan',
      },
    },
  },
  velocity: {
    DashboardGroups: {
      [ConnectorApplication.BjsWholesale]: {
        includeIf: 'bjsWholesaleCoreDashboards',
      },
      [ConnectorApplication.DollarGeneral]: {
        includeIf: 'dollarGeneralVelocity',
      },
      [ConnectorApplication.Dot]: {
        includeIf: 'dotExpresswayCoreDashboards',
      },
      [ConnectorApplication.HarrisTeeter]: {
        includeIf: 'harrisTeeterCoreDashboards',
      },
      [ConnectorApplication.HomeDepot]: {
        includeIf: 'homeDepotCoreDashboards',
      },
      [ConnectorApplication.Kehe]: {
        excludeIf: 'cleanedStoreDataDashboards',
      },
      [ConnectorApplication.Shopify]: {
        includeIf: 'shopifyCoreDashboards',
      },
      [ConnectorApplication.SuperValu]: {
        includeIf: 'svHarborCoreDashboards',
      },
      [ConnectorApplication.Unfi]: {
        excludeIf: 'cleanedStoreDataDashboards',
      },
    },
  },
  'year-over-year-sales': {
    DashboardGroups: {
      [ConnectorApplication.BjsWholesale]: {
        includeIf: 'yoySalesDashboards',
      },
      [ConnectorApplication.DollarGeneral]: {
        includeIf: 'yoySalesDashboards',
      },
      [ConnectorApplication.Dot]: {
        includeIf: 'dotYoySalesDashboard',
      },
      [ConnectorApplication.HarrisTeeter]: {
        includeIf: 'yoySalesDashboards',
      },
      [ConnectorApplication.Kehe]: {
        excludeIf: 'keheYoySalesDashboardV2',
      },
      [ConnectorApplication.Kroger]: {
        includeIf: 'yoySalesDashboards',
      },
      [ConnectorApplication.Shopify]: {
        includeIf: 'shopifyYoySalesDashboard',
      },
      [ConnectorApplication.SuperValu]: {
        includeIf: 'svHarborYoySalesDashboard',
      },
      [ConnectorApplication.Target]: {
        includeIf: 'yoySalesDashboards',
      },
      [ConnectorApplication.Walmart]: {
        includeIf: 'yoySalesDashboards',
      },
      [ConnectorApplication.Wegmans]: {
        includeIf: 'yoySalesDashboards',
      },
    },
  },
  'year-over-year-sales-v2': {
    DashboardGroups: {
      [ConnectorApplication.Kehe]: {
        includeIf: 'keheYoySalesDashboardV2',
      },
    },
  },
  'year-over-year-velocity': {
    DashboardGroups: {
      [ConnectorApplication.Unfi]: {
        includeIf: 'yoyVelocityDashboards',
      },
      [ConnectorApplication.WholeFoods]: {
        includeIf: 'yoyVelocityDashboards',
      },
      [ConnectorApplication.Kehe]: {
        includeIf: 'yoyVelocityDashboards',
      },
      [ConnectorApplication.Walmart]: {
        includeIf: 'yoyVelocityDashboards',
      },
      [ConnectorApplication.Kroger]: {
        includeIf: 'yoyVelocityDashboards',
      },
      [ConnectorApplication.SuperValu]: {
        includeIf: 'yoyVelocityDashboards',
      },
      [ConnectorApplication.Target]: {
        includeIf: 'yoyVelocityDashboards',
      },
      [ConnectorApplication.Wegmans]: {
        includeIf: 'yoyVelocityDashboards',
      },
      [ConnectorApplication.BjsWholesale]: {
        includeIf: 'yoyVelocityDashboards',
      },
      [ConnectorApplication.DollarGeneral]: {
        includeIf: 'yoyVelocityDashboards',
      },
      [ConnectorApplication.Dot]: {
        includeIf: 'yoyVelocityDashboards',
      },
      [ConnectorApplication.HarrisTeeter]: {
        includeIf: 'yoyVelocityDashboards',
      },
      [ConnectorApplication.HomeDepot]: {
        includeIf: 'yoyVelocityDashboards',
      },
      [ConnectorApplication.Shopify]: {
        includeIf: 'yoyVelocityDashboards',
      },
    },
  },
};

export default Configuration;
