import { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import { useAuthenticationContext } from '../../../security/AuthenticationContext';

const useFirebaseIdTokenResult = () => {
  const { firebaseUser, loading } = useAuthenticationContext();
  const [idTokenResult, setIdTokenResult] = useState<firebase.auth.IdTokenResult>();

  useEffect(() => {
    if (firebaseUser && !loading) {
      firebaseUser.getIdTokenResult().then(idTokenResult => {
        setIdTokenResult(idTokenResult);
      });
    }
  }, [firebaseUser, loading]);

  return idTokenResult;
};

export default useFirebaseIdTokenResult;
