import React, { useContext, useEffect, useState } from 'react';
import { get, set } from 'idb-keyval';
import _ from 'lodash';
import { noop } from '@tellurian/ts-utils';
import { FCC } from '../../../../utils/types';
import useEventCallback from '../../utils/useEventCallback';
import logger from '../../../../services/logger';
import { Store } from '../../common/IndexDBStores';

type PersistentUIState = {
  powerBi: {
    navigationBar: {
      visible: boolean;
    };
  };
};

type PartialUIState = Partial<{
  [K in keyof PersistentUIState]: Partial<PersistentUIState[K]>;
}>;

const DefaultUIState: PersistentUIState = {
  powerBi: {
    navigationBar: {
      visible: true,
    },
  },
};

type PersistentUIStateContextInterface = {
  state: PersistentUIState;
  setState: (state: PersistentUIState) => void;
  updateState: (state: PersistentUIState) => void;
};

const PersistentUIStateContext = React.createContext<PersistentUIStateContextInterface>({
  state: DefaultUIState,
  setState: noop,
  updateState: noop,
});

const Versions = ['1'];
const getKey = () => `UserUIState_v${Versions[0]}`;
const getState = async (): Promise<PersistentUIState> => {
  return get(getKey(), Store.GeneralSettings).then(state => {
    return _.merge({}, DefaultUIState, state);
  });
};

const PersistentUIStateContextProvider: FCC = ({ children }) => {
  const [state, setState] = useState(DefaultUIState);

  useEffect(() => {
    getState()
      .then(setState)
      .catch(err => logger.error('Failed to load user UI state.', err));
  }, []);

  const _setState = useEventCallback((nextState: PersistentUIState) => {
    setState(nextState);
    set(getKey(), nextState, Store.GeneralSettings).catch(err => {
      logger.error('Failed to save user UI State', err);
    });
  });

  const updateState = useEventCallback((nextPartialState: PartialUIState) => {
    _setState(_.merge({}, state, nextPartialState));
  });

  return (
    <PersistentUIStateContext.Provider value={{ state, setState: _setState, updateState }}>
      {children}
    </PersistentUIStateContext.Provider>
  );
};

export default PersistentUIStateContextProvider;
export const usePersistentUIState = () => useContext(PersistentUIStateContext);
