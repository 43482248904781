import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { ReactComponent as SearchIcon } from '../../../../../assets/icons/lettuce/search.svg';
import { ReactComponent as ClearIcon } from '../../../../../assets/icons/lettuce/clear.svg';
import Button, { ButtonVariant } from '../../../components/Button';
import Chip from '../../../components/Chip';
import useOnClickAway from '../../../utils/useOnClickAway';
import useEventCallback from '../../../utils/useEventCallback';
import style from './SearchBar.module.css';

type SearchBarProps = {
  onChange: (nextValue: string) => void;
  value: string;
  isActive: boolean;
  setActive: () => void;
  setInactive: () => void;
};

const SearchBar: React.FC<SearchBarProps> = ({
  onChange,
  value,
  isActive,
  setActive,
  setInactive,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isReady, setIsReady] = useState(isActive);

  useEffect(() => {
    if (isReady) {
      inputRef.current?.focus();
    }
  }, [isReady]);

  const onClose = useEventCallback(() => {
    onChange('');
    setInactive();
  });

  const onInputKeyDown = event => {
    if (event.key === 'Escape') {
      onClose();
    }
  };

  const ref = useOnClickAway<HTMLDivElement>({
    onClickAway: () => {
      if (isActive && !value.trim().length) {
        setIsReady(false);
        setInactive();
      }
    },
  });

  return (
    <div
      className={clsx(style.searchBarContainer, { [style.active]: isActive })}
      onTransitionEnd={() => setIsReady(isActive)}
      ref={ref}
    >
      {isActive ? (
        <div className={style.searchBar}>
          <SearchIcon className={style.icon} />
          <input
            ref={inputRef}
            name="searchQuery"
            placeholder="Search"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange && onChange(e.target.value)
            }
            value={value}
            className={clsx(style.searchInput, { [style.ready]: isReady })}
            onKeyDown={onInputKeyDown}
            autoComplete="off"
            autoCorrect="off"
            spellCheck={false}
          />
          <Button
            variant={ButtonVariant.Text}
            icon={<ClearIcon className={style.icon} aria-hidden />}
            onClick={onClose}
          >
            <span className="sr-only">Close search</span>
          </Button>
        </div>
      ) : (
        <Chip className={style.searchBarButton} onClick={setActive}>
          <SearchIcon className={style.icon} />
          <span className="sr-only">Search</span>
        </Chip>
      )}
    </div>
  );
};

export default SearchBar;
