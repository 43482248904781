import React from 'react';
import { SaveCancelActionButtonsProps } from '../ActionButtons/SaveCancelActionButtons';
import DeleteCancelActionButtons, {
  DeleteCancelActionButtonsProps,
} from '../ActionButtons/DeleteCancelActionButtons';
import { FCC } from '../../../../utils/types';
import Modal, { ModalProps } from './index';

export type DeleteModalProps = Omit<ModalProps, 'footer'> &
  Omit<DeleteCancelActionButtonsProps, 'onCancel'> & {
    // onCancel will default to onCloseClick and is therefore optional
    onCancel?: SaveCancelActionButtonsProps['onCancel'];
  };

const DeleteModal: FCC<DeleteModalProps> = ({
  title,
  subtitle,
  onCloseClick,
  children,
  onCancel = onCloseClick,
  size,
  ...rest
}) => {
  return (
    <Modal
      title={title}
      subtitle={subtitle}
      onCloseClick={onCloseClick}
      size={size}
      footer={<DeleteCancelActionButtons onCancel={onCancel} {...rest} />}
    >
      {children}
    </Modal>
  );
};

export default DeleteModal;
