import React from 'react';
import clsx from 'clsx';
import {
  Button,
  ButtonSize,
  ButtonStyle,
  ButtonType,
  GridType,
  MainContent,
  PageLayout,
} from '../ui';
import { FeatureProps } from '../../utils/features';
import styles from './index.module.css';

const { Content, Layout } = PageLayout;

export enum ActionPosition {
  Left = 'left',
  Right = 'right',
}

export type Action = {
  position?: ActionPosition;
  disabled?: boolean;
  isPrimary?: boolean;
  label: string;
  onClick: (e: React.BaseSyntheticEvent) => void;
  type?: ButtonType;
  testId?: string;
} & FeatureProps;

function ActionButton({
  onClick,
  isPrimary,
  btnSize,
  disabled,
  type,
  label,
  testId,
  featureId,
}: Action & { btnSize?: ButtonSize }) {
  return (
    <div>
      <Button
        onClick={onClick}
        btnStyle={isPrimary ? ButtonStyle.PRIMARY : ButtonStyle.LINK}
        btnSize={btnSize || ButtonSize.M}
        disabled={!!disabled}
        btnType={type}
        testId={testId}
        featureId={featureId}
      >
        {label}
      </Button>
    </div>
  );
}

const PageActionBarComponent = React.forwardRef(function PageActionBarComponent(
  {
    actions,
    btnSize,
    type,
    withoutSidebars,
    className,
  }: {
    actions: Action[];
    btnSize?: ButtonSize;
    withoutSidebars?: boolean;
    type?: GridType;
    className?: string;
  },
  ref?: React.Ref<HTMLDivElement>,
) {
  const left = actions.filter(a => a.position === ActionPosition.Left);
  const right = actions.filter(a => !a.position || a.position === ActionPosition.Right);

  return (
    <div className={clsx(styles.actionBar, className)} ref={ref}>
      <MainContent>
        <Layout className="man" type={type} withoutSidebars={withoutSidebars}>
          <Content>
            <hr className="mvn" />
            <div className={styles.actionBarContent}>
              {right.map((actionProps, index) => (
                <ActionButton
                  key={`act${index}`}
                  btnSize={btnSize || ButtonSize.M}
                  {...actionProps}
                />
              ))}
              <div className={styles.actionsLeft}>
                {left.map((actionProps, index) => (
                  <ActionButton
                    key={`act${index}`}
                    btnSize={btnSize || ButtonSize.M}
                    {...actionProps}
                  />
                ))}
              </div>
            </div>
          </Content>
        </Layout>
      </MainContent>
    </div>
  );
});

export default PageActionBarComponent;
