import { useMemo } from 'react';
import { useKeyboardShortcutContext } from '../KeyboardShortcutContext';
import { KeyboardShortcut } from '../lib';

const useToggleKeyboardShortcutHelpShortcut = (): KeyboardShortcut => {
  const { setKeyboardShortcutHelpVisible } = useKeyboardShortcutContext();
  return useMemo(
    () => ({
      keyCombo: { meta: true, shift: true, code: 'KeyH' },
      description: 'Toggle keyboard shortcut help (this panel)',
      fn: () => setKeyboardShortcutHelpVisible(isVisible => !isVisible),
    }),
    [setKeyboardShortcutHelpVisible],
  );
};

export default useToggleKeyboardShortcutHelpShortcut;
