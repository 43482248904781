/* eslint-disable @typescript-eslint/no-explicit-any */

export type ListenerManager<P = any> = {
  addListener: (listener: (params: P) => void) => void;
  clear: () => void;
  fire: (params: P) => void;
};

const listenerManager = <P = any>(): ListenerManager<P> => {
  const listeners: Set<(params: P) => void> = new Set();

  const addListener = (listener: (params: P) => void) => {
    listeners.add(listener);
    return () => listeners.delete(listener);
  };

  const clear = () => listeners.clear();

  const fire = (params: P) => {
    for (const listener of listeners) {
      listener(params);
    }
  };

  return { addListener, fire, clear };
};

export default listenerManager;
