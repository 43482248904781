import React from 'react';
import { keyDownTriggerOnClick } from './lib';

/**
 * We have a few places where we have a component that takes an optional `onClick` prop
 *
 * For accessibility, When we set `onClick`, we then also need to give the dom element an
 * onKeyDown that matches, have it in the tab order, and give it a role that will appear
 * clickable to screen readers.
 *
 * If we make all of the attributes conditional, eslint can't see that we are setting the role
 * properly, so this is the cleanest way to appease eslint while still having decent accessibility
 * (we may want to be more specific with roles, etc later though)
 */

type MaybeClickableDivProps = {
  onClick?: () => void;
  children: React.ReactNode;
  className?: string;
};
export default function MaybeClickableDiv({
  onClick,
  children,
  className,
}: MaybeClickableDivProps) {
  if (onClick) {
    return (
      <div
        className={className}
        tabIndex={0}
        onClick={onClick}
        onKeyDown={keyDownTriggerOnClick(onClick)}
        role="button"
      >
        {children}
      </div>
    );
  }
  return <div>{children}</div>;
}
