import { useCallback } from 'react';
import {
  ConnectorApplication,
  DashboardGroupForPermissionsFragment,
  DashboardGroupFragment,
} from '../../../generated/graphql';
import useIsFeatureFlagEnabled from '../../lettuce/common/featureFlags/useIsFeatureFlagEnabled';
import { KnownFlags } from '../../lettuce/common/featureFlags/KnownFlags';

export const useFilterAmazonDashboardGroups = () => {
  const enableAmazonGroup = useIsFeatureFlagEnabled(KnownFlags.EnableAmazonGroup);

  return useCallback(
    (dashboardGroup: DashboardGroupFragment | DashboardGroupForPermissionsFragment) =>
      enableAmazonGroup
        ? dashboardGroup.dashboards.some(
            d =>
              (!d.relatedConnectorApplications.includes(ConnectorApplication.AmazonSellerCentral) &&
                !d.relatedConnectorApplications.includes(
                  ConnectorApplication.AmazonVendorCentral,
                )) ||
              d.isCrossConnector,
          )
        : dashboardGroup.dashboards.some(
            d =>
              !d.isCrossConnector ||
              (!d.relatedConnectorApplications.includes(ConnectorApplication.AmazonSellerCentral) &&
                !d.relatedConnectorApplications.includes(ConnectorApplication.AmazonVendorCentral)),
          ),
    [enableAmazonGroup],
  );
};
