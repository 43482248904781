import React from 'react';
import clsx from 'clsx';
import style from './index.module.css';

const ContextMenuVariants = ['standard', 'neutral', 'red'] as const;
type ContextMenuVariant = (typeof ContextMenuVariants)[number];

const ContextButtonSizes = ['S', 'M'] as const;
type ContextButtonSize = (typeof ContextButtonSizes)[number];

export type ContextButtonProps = {
  onToggle?: () => void;
  isToggled?: boolean;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  className?: string;
  srOnly?: string;
  variant?: ContextMenuVariant;
  size?: ContextButtonSize;
} & Pick<
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  'onMouseDown' | 'onKeyDown' | 'onFocus' | 'onBlur' | 'aria-label' | 'aria-labelledby' | 'id'
>;

const ContextButton = React.forwardRef<HTMLButtonElement, ContextButtonProps>(
  function ContextButton(
    {
      className,
      Icon,
      srOnly,
      isToggled = false,
      variant = 'standard',
      onToggle,
      size,
      ...buttonProps
    },
    ref,
  ) {
    return (
      <button
        type="button"
        {...buttonProps}
        className={clsx(
          style.button,
          {
            [style.sizeS]: size === 'S',
            [style.toggled]: isToggled,
            [style.neutral]: variant === 'neutral',
            [style.red]: variant === 'red',
          },
          className,
        )}
        ref={ref}
        onClick={onToggle}
      >
        {srOnly && <span className="sr-only">{srOnly}</span>}
        <Icon />
      </button>
    );
  },
);

export default ContextButton;
