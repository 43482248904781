import React from 'react';
import clsx from 'clsx';
import { FCC } from '../../utils/types';
import style from './index.module.css';

export type PageContainerProps = { includeBottomPadding?: boolean };

const PageContainer: FCC<PageContainerProps> = ({ includeBottomPadding = true, children }) => {
  return <div className={clsx({ [style.container]: includeBottomPadding })}>{children}</div>;
};

export default PageContainer;
