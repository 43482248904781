import React from 'react';
import clsx from 'clsx';
import ListItem, { ListItemProps } from './ListItem';
import style from './ListItemFocusHighlight.module.css';

export type ListItemFocusHighlightProps = ListItemProps;

const ListItemFocusHighlight = React.forwardRef<HTMLLIElement, ListItemFocusHighlightProps>(
  function ListItemFocusHighlight(
    {
      isFocused,
      focusTransitionsEnabled = false,
      className,
      ...props
    }: ListItemFocusHighlightProps,
    ref,
  ) {
    return (
      <ListItem
        ref={ref}
        {...props}
        className={clsx(className, style.item, {
          [style.focused]: isFocused,
          [style.active]: focusTransitionsEnabled,
        })}
      />
    );
  },
);

export default React.memo(ListItemFocusHighlight) as typeof ListItemFocusHighlight;
