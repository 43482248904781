import React from 'react';
import SearchField from '../../components/SearchField';
import Flex from '../../components/Flex';
import { ChipOption } from '../../components/Chip';
import { UserAccountRoleOption, UserAccountRoleOptions, UserRoleLabel } from './lib';
import style from './UserListHeader.module.css';

type UserListHeaderProps = {
  query: string;
  onQueryChange: (query: string) => void;
  selectedRole?: UserAccountRoleOption;
  onSelectedRoleChange: (role?: UserAccountRoleOption) => void;
  autoFocusSearch?: boolean;
};

const UserListHeader: React.FC<UserListHeaderProps> = ({
  onQueryChange,
  query,
  selectedRole,
  onSelectedRoleChange,
  autoFocusSearch = false,
}) => {
  return (
    <Flex className={style.header}>
      <SearchField onChange={onQueryChange} value={query} autoFocus={autoFocusSearch} />
      <Flex size="S">
        <ChipOption
          text="All"
          isSelected={!selectedRole}
          onChange={() => onSelectedRoleChange(undefined)}
        />
        {UserAccountRoleOptions.map(role => (
          <ChipOption
            key={role}
            text={UserRoleLabel[role] + 's'}
            isSelected={selectedRole === role}
            onChange={() => onSelectedRoleChange(role)}
          />
        ))}
      </Flex>
    </Flex>
  );
};

export default UserListHeader;
