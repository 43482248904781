import React, { useState } from 'react';
import pluralize from 'pluralize';
import Title from '../../../components/Title';
import { BookmarkFragmentWithDashboardInfo } from '../../../../BookmarksContext';
import { BookmarkScope } from '../../../../../generated/graphql';
import Button, { ButtonVariant } from '../../../components/Button';
import UserItem, { UserItemDetails } from '../../../components/EntityList/entityItems/UserItem';
import EntityList from '../../../components/EntityList';

export const isReportPrivate = ({ scope, users }: BookmarkFragmentWithDashboardInfo) =>
  scope === BookmarkScope.Private || (scope === BookmarkScope.UserShared && users?.length === 0);

type ReportAccessDetailsProps = {
  report: BookmarkFragmentWithDashboardInfo;
};

const ReportAccessDetails: React.FC<ReportAccessDetailsProps> = ({ report }) => {
  const { scope } = report;
  const userCount = report.users?.length ?? 0;
  const [showUsers, setShowUsers] = useState(false);

  return (
    <>
      <Title size="M" className="mvm">
        Access details
      </Title>
      <p>
        This report is{' '}
        {isReportPrivate(report) ? (
          'private'
        ) : scope === BookmarkScope.AccountShared ? (
          'shared with all account users'
        ) : (
          <>
            shared with{' '}
            <Button variant={ButtonVariant.Text} onClick={() => setShowUsers(!showUsers)}>
              {pluralize('user', userCount, true)}
            </Button>
          </>
        )}
        .
      </p>
      {showUsers && (
        <EntityList<UserItemDetails>
          items={report.users ?? []}
          RenderItemContent={UserItem}
          aria-label="Users the report is shared with"
        />
      )}
    </>
  );
};

export default ReportAccessDetails;
