import React, {
  ChangeEvent,
  MouseEventHandler,
  ReactElement,
  Ref,
  SVGProps,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import { useDefaultHashId } from '../../../../lib';
import useEventCallback from '../../../utils/useEventCallback';
import composeRefs from '../../../utils/composeRefs';
import style from './index.module.css';

type InputBaseProps = {
  label: string;
} & Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'ref'
>;

const InputBase = React.forwardRef<HTMLInputElement, InputBaseProps>(function InputBase(
  { label, id, value = '', ...rest },
  ref,
) {
  id = useDefaultHashId(id);
  return (
    <div className={style.inputWithLabel}>
      {label && <label htmlFor={id}>{label}</label>}
      <input ref={ref} id={id} value={value} {...rest} />
    </div>
  );
});

type IconInputBaseProps = InputBaseProps & {
  IconLeft?: React.FunctionComponent<SVGProps<SVGSVGElement>>;
  IconRight?: React.FunctionComponent<SVGProps<SVGSVGElement>>;
};

const IconInputBase = React.forwardRef<HTMLInputElement, IconInputBaseProps>(function IconInputBase(
  { IconLeft, IconRight, ...inputBaseProps },
  ref,
) {
  return (
    <div
      className={clsx(style.iconInputWrapper, {
        [style.hasIconRight]: IconRight,
        [style.hasIconLeft]: IconLeft,
      })}
    >
      {IconLeft && <IconLeft />}
      <InputBase {...inputBaseProps} ref={ref} />
      {IconRight && <IconRight />}
    </div>
  );
});

type UncontrolledInputBaseProps = Omit<InputBaseProps, 'value'> & { initialValue?: string };

const UncontrolledInputBase = React.forwardRef<HTMLInputElement, UncontrolledInputBaseProps>(
  function UncontrolledInputBase({ id, label, initialValue, ...props }, ref) {
    id = useDefaultHashId(id);
    return (
      <div className={style.inputWithLabel}>
        {label && <label htmlFor={id}>{label}</label>}
        <input ref={ref} id={id} defaultValue={initialValue} {...props} />
      </div>
    );
  },
);

type RenderInputProps = Pick<
  InputBaseProps,
  'onFocus' | 'onBlur' | 'value' | 'onChange' | 'disabled' | 'placeholder'
>;

type InputContainerProps<T extends RenderInputProps> = T & {
  RenderInput: React.FC<
    T & {
      ref: React.Ref<HTMLInputElement>;
    }
  >;
  hasValue?: boolean;
  hasError?: boolean;
  containerRef?: Ref<HTMLDivElement>;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onMouseDown?: MouseEventHandler<HTMLDivElement>;
  className?: string;
};
function InputContainerBase<T extends RenderInputProps>(
  {
    RenderInput,
    hasValue,
    hasError = false,
    placeholder,
    containerRef,
    onClick,
    onMouseDown,
    className,
    ...props
  }: InputContainerProps<T>,
  externalRef: React.Ref<HTMLInputElement>,
) {
  const [isFocused, setIsFocused] = useState(false);
  const onFocus = useEventCallback((e: React.FocusEvent<HTMLInputElement, HTMLElement>) => {
    setIsFocused(true);
    props.onFocus?.(e);
  });
  const onBlur = useEventCallback((e: React.FocusEvent<HTMLInputElement, HTMLElement>) => {
    setIsFocused(false);
    props.onBlur?.(e);
  });
  const ref = useRef<HTMLInputElement>(null);
  hasValue = typeof hasValue === 'boolean' ? hasValue : !!props.value;
  const placeholderToUse = isFocused ? placeholder : undefined;

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <div
      className={clsx(
        style.container,
        {
          [style.focused]: isFocused,
          [style.hasValue]: hasValue,
          [style.hasError]: hasError,
          [style.disabled]: props.disabled,
        },
        className,
      )}
      onMouseDown={ev => {
        // If the event originates from the input we need not re-focus.
        if (ev.target !== ref.current) {
          // Since element.focus is not idempotent, prevent an immediate blur and then refocus by preventing
          // default if the active element is already the input.
          if (document.activeElement === ref.current) {
            ev.preventDefault();
          }
          setTimeout(() => {
            ref.current?.focus();
          }, 0);
        }
        onMouseDown?.(ev);
      }}
      onClick={onClick}
      ref={containerRef}
    >
      <RenderInput
        {...(props as unknown as T)}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholderToUse}
        ref={externalRef ? composeRefs(ref, externalRef) : ref}
      />
    </div>
  );
}

const InputContainer = React.forwardRef(InputContainerBase) as <T extends RenderInputProps>(
  props: T,
) => ReactElement;

export type InputProps = IconInputBaseProps &
  Pick<
    InputContainerProps<InputBaseProps>,
    'hasError' | 'containerRef' | 'onClick' | 'onMouseDown'
  >;

const Input = React.forwardRef<HTMLInputElement, InputProps>(function Input(props, ref) {
  const BaseComponent = props.IconRight || props.IconLeft ? IconInputBase : InputBase;
  return <InputContainer RenderInput={BaseComponent} {...props} ref={ref} />;
});

export default Input;

type UncontrolledInputProps = UncontrolledInputBaseProps &
  Pick<InputContainerProps<UncontrolledInputBaseProps>, 'hasError'>;
export const UncontrolledInput = React.forwardRef<HTMLInputElement, UncontrolledInputProps>(
  function UncontrolledInput(props, ref) {
    const [hasValue, setHasValue] = useState(!!props.initialValue);

    const onChange = useEventCallback((e: ChangeEvent<HTMLInputElement>) => {
      setHasValue(!!e.target.value);
      props.onChange?.(e);
    });

    return (
      <InputContainer
        ref={ref}
        RenderInput={UncontrolledInputBase}
        {...props}
        onChange={onChange}
        hasValue={hasValue}
      />
    );
  },
);
