import { RouteDeclaration, RouteIdToRoute } from '../../components/routing/lib';
import PartnerHomePage from '../../partner/pages/HomePage';
import PartnerSignInPage from '../../partner/pages/SignInPage';
import PartnerAnalyticsPage from '../pages/AnalyticsPage';
import PartnerLandingPage from '../pages/LandingPage';

export const Routes = [
  {
    id: 'PartnerLanding',
    path: '/p',
    Render: PartnerLandingPage,
  },
  {
    id: 'PartnerHome',
    path: '/p/:partnerType',
    Render: PartnerHomePage,
  },
  {
    id: 'PartnerAnalytics',
    path: '/p/:partnerType/reports',
    Render: PartnerAnalyticsPage,
    //requireFeatureFlag: KnownFlags.DotMiniSite,
  },
  {
    id: 'PartnerSignIn',
    path: '/p/signin',
    Render: PartnerSignInPage,
    requireAuth: false,
  },
  {
    id: 'PartnerAuthCallback',
    path: '/p/signin/callback',
    Render: PartnerSignInPage,
    requireAuth: false,
  },
] as const satisfies RouteDeclaration[];

export type RouteId = (typeof Routes)[number]['id'];

export const RouteById = Routes.reduce<RouteIdToRoute<RouteId>>(
  (acc, r) => ({ ...acc, [r.id]: r }),
  {} as RouteIdToRoute<RouteId>,
);
