import React from 'react';
import range from 'lodash/range';

import iconArrowLeft from '../../assets/icons/arrow-left-1.svg';
import iconArrowRight from '../../assets/icons/arrow-right-1.svg';
import { Button, ButtonStyle } from './Button';
import styles from './Pagination.module.css';

function Page({
  children,
  onClick,
  selected,
}: {
  children: React.ReactNode;
  onClick: () => void;
  selected: boolean;
}) {
  return (
    <div className={styles.item}>
      <Button btnStyle={ButtonStyle.LINK} disabled={selected} onClick={onClick}>
        {children}
      </Button>
    </div>
  );
}

function Previous({ onClick }: { onClick: () => void }) {
  return (
    <div className={styles.item}>
      <Button onClick={onClick} btnStyle={ButtonStyle.LINK} aria-label="Previous page">
        <img alt="" src={iconArrowLeft} width="12" />
      </Button>
    </div>
  );
}

function Next({ onClick }: { onClick: () => void }) {
  return (
    <div className={styles.next}>
      <Button onClick={onClick} btnStyle={ButtonStyle.LINK} aria-label="Next page">
        <img alt="" src={iconArrowRight} width="12" />
      </Button>
    </div>
  );
}

const maxVisible = 10;

function calculateStart(currentPage: number, totalPages: number): number {
  const low = currentPage - 6;

  if (totalPages <= maxVisible) return 0;
  if (low > 0 && low + maxVisible < totalPages) return low;
  if (low + maxVisible >= totalPages) return totalPages - maxVisible;
  return 0;
}

const calculateEnd = (start: number, totalPages: number): number =>
  start + maxVisible < totalPages ? start + maxVisible : totalPages;

function itemsIndex(page: number, pageSize: number, totalItems: number): [number, number] {
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize < totalItems ? startIndex + pageSize : totalItems;
  return [startIndex, endIndex];
}

export type PaginationProps = {
  currentPage: number;
  onClick: (page: number, range: [number, number]) => void;
  pageSize: number;
  totalItems: number;
};

export const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  onClick,
  pageSize,
  totalItems,
}) => {
  const totalPages = Math.round(totalItems / pageSize);
  const start = calculateStart(currentPage, totalPages);
  const end = calculateEnd(start, totalPages);
  const visiblePages = range(1, totalPages + 1).slice(start, end);

  return (
    <div className={styles.container}>
      {currentPage > 1 && (
        <Previous
          onClick={() => {
            const prevPage = currentPage - 1;
            onClick(prevPage, itemsIndex(prevPage, pageSize, totalItems));
          }}
        />
      )}
      {visiblePages.map(page => (
        <Page
          key={page}
          onClick={() => {
            onClick(page, itemsIndex(page, pageSize, totalItems));
          }}
          selected={page === currentPage}
        >
          {page}
        </Page>
      ))}
      {currentPage < totalPages && (
        <Next
          onClick={() => {
            const nextPage = currentPage + 1;
            onClick(nextPage, itemsIndex(nextPage, pageSize, totalItems));
          }}
        />
      )}
    </div>
  );
};

export type BasicPaginationProps = {
  currentPage: number;
  pageCount: number;
  onChange: (nextPage: number) => void;
  description?: string;
};

export const BasicPagination: React.FC<BasicPaginationProps> = ({
  currentPage,
  pageCount,
  onChange,
  description,
}) => {
  // Return null if there is nothing to display
  if (pageCount < 2 && !description) {
    return null;
  }
  return (
    <div className={styles.basic}>
      {description ? <span>{description}</span> : null}
      {currentPage > 0 ? <Previous onClick={() => onChange(currentPage - 1)} /> : null}
      {currentPage < pageCount - 1 ? <Next onClick={() => onChange(currentPage + 1)} /> : null}
    </div>
  );
};
