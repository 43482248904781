import React, { useContext, useEffect, useRef } from 'react';
import { noop } from '@tellurian/ts-utils';
import { FCC } from '../../../../utils/types';

type PopoverActionContextInterface = {
  show: () => void;
  hide: () => void;
  // Use to signal popper to update position of popover and arrow when
  // content changes inside the popover impact its size
  // (e.g. new data is pulled and an element which previously rendered null will now render a div that increases the popover size)
  // NOTES:
  // 1. Calls to this function are not required if the size of the popover is static and unaffected by content changes.
  // 2. Use the useUpdatePopoverOnContentLoadStateChange hook below to conveniently send updates from React components
  // when the content availability changes.
  onContentChanged: () => void;
};

/**
 * Use an *Action* context to only expose the memoized popover functions and not other state variables
 * (such as `isVisible`). In doing so, we are avoiding unnecessary re-renders as PopoverActionContextProvider
 * has no internal state changes (unless the functions change), so its children should never re-render as
 * a result of context usage.
 */
const PopoverActionContext = React.createContext<PopoverActionContextInterface>({
  show: noop,
  hide: noop,
  onContentChanged: noop,
});

const PopoverActionContextProvider: FCC<PopoverActionContextInterface> = ({
  children,
  ...value
}) => {
  return <PopoverActionContext.Provider value={value}>{children}</PopoverActionContext.Provider>;
};

export default PopoverActionContextProvider;

export const usePopoverContext = () => useContext(PopoverActionContext);

export const useUpdatePopoverOnContentLoadStateChange = (isContentLoaded: boolean) => {
  const { onContentChanged } = usePopoverContext();
  const lastIsContentLoadedRef = useRef(isContentLoaded);
  useEffect(() => {
    if (lastIsContentLoadedRef.current !== isContentLoaded) {
      onContentChanged();
    }
  }, [isContentLoaded, onContentChanged]);
};
