import React from 'react';
import { FormProvider } from 'react-hook-form';
import { UserAccountRole } from '../../../../generated/graphql';
import { email, requiredField } from '../../../ui/form/validation';
import Modal, { ModalProps } from '../../components/Modal';
import { inProgressLabel } from '../../../lib';
import ActionButtons from '../../components/ActionButtons';
import TextFormField from '../../components/formFields/TextFormField';
import AccountRoleAndDashboardPermissionsSelection from './AccountRoleAndDashboardPermissionsSelection';
import useInvitationForm from './useInvitationForm';

type InviteUserModalProps = Pick<ModalProps, 'onCloseClick'> & {
  accountId: string;
  onInvitationCreated: () => void;
};

const InviteUserModal: React.FC<InviteUserModalProps> = ({
  onCloseClick,
  onInvitationCreated,
  accountId,
}) => {
  const {
    useFormMethods,
    loading,
    loadingDefaultDashboardPermissions,
    defaultDashboardPermissions,
    submit,
    grantedRole,
  } = useInvitationForm(accountId, onInvitationCreated);

  return (
    <Modal
      title="Add new member"
      subtitle="Add a new user to the account."
      onCloseClick={onCloseClick}
      footer={
        <ActionButtons
          onPrimary={useFormMethods.handleSubmit(submit)}
          onSecondary={onCloseClick}
          disabled={{ primary: loading }}
          primaryLabel={loading ? inProgressLabel('Inviting') : 'Invite'}
        />
      }
    >
      <FormProvider {...useFormMethods}>
        <div className="mtm">
          <TextFormField
            name="email"
            label="Enter the email address of the user you want to invite"
            validate={[requiredField, email]}
            isFocused={true}
            type="email"
            data-testid="input-user-invite-email"
          />
          <AccountRoleAndDashboardPermissionsSelection
            accountId={accountId}
            defaultDashboardPermissions={defaultDashboardPermissions}
            defaultUserRole={UserAccountRole.Viewer}
            loading={loadingDefaultDashboardPermissions}
            userRole={grantedRole}
          />
        </div>
      </FormProvider>
    </Modal>
  );
};

export default InviteUserModal;
