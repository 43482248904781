import React, { useId } from 'react';
import clsx from 'clsx';
import composeRefs from '../../utils/composeRefs';
import useInitialFocus from '../../../ui/form/useInitialFocus';
import TextArea, { TextAreaProps } from '../lib/TextArea';
import FieldDetails, { FieldDetailsProps } from '../lib/FieldDetails';
import { FieldClassName } from '../lib/fieldSettings';

export type TextAreaFieldProps = Omit<FieldDetailsProps, 'id'> &
  Omit<TextAreaProps, 'onChange' | 'hasError'> & {
    onChange?: (value: string) => void;
    isFocused?: boolean;
    scrollIntoViewIfFocused?: boolean;
  };

const TextAreaField = React.forwardRef<HTMLTextAreaElement, TextAreaFieldProps>(
  function TextAreaField(
    {
      description,
      error,
      onChange,
      isFocused,
      scrollIntoViewIfFocused,
      className,
      ...textAreaProps
    }: TextAreaFieldProps,
    ref,
  ) {
    const hasError = !!error;
    const composedRef = composeRefs(
      ref,
      useInitialFocus<HTMLTextAreaElement>({ isFocused, scrollIntoViewIfFocused }),
    );
    const supportingTextId = useId();

    return (
      <div className={clsx(FieldClassName, className)}>
        <TextArea
          {...textAreaProps}
          onChange={onChange && (e => onChange(e.target.value))}
          hasError={hasError}
          ref={composedRef}
          aria-describedby={supportingTextId}
        />
        <FieldDetails error={error} description={description} id={supportingTextId} />
      </div>
    );
  },
);

export default TextAreaField;
