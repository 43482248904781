import { useEffect } from 'react';
import { PopoverStateReturn } from 'reakit';
import { isAncestorOfOrEquals } from '../../../lib';

export const useDismissPopoverOnOutsideMouseDown = (popover: PopoverStateReturn) => {
  const popoverRef = popover.unstable_popoverRef;
  const hide = popover.hide;
  useEffect(() => {
    if (popover.visible) {
      const onMouseDown = ev => {
        if (popoverRef.current) {
          if (!isAncestorOfOrEquals(ev.target, popoverRef.current)) {
            hide();
          }
        }
      };
      document.addEventListener('mousedown', onMouseDown);
      return () => document.removeEventListener('mousedown', onMouseDown);
    }
  }, [hide, popoverRef, popover.visible]);
};
