import React from 'react';
import clsx from 'clsx';
import Headline from '../Headline';
import { useResponsive } from '../../../ui';
import style from './index.module.css';

type PageHeaderProps = {
  headline: string;
  description?: string;
  rhs?: React.ReactNode;
};

const PageHeader: React.FC<PageHeaderProps> = ({ headline, description, rhs }) => {
  const { isMaxXSmallScreen } = useResponsive();
  return (
    <div className={clsx(style.headerContainer, isMaxXSmallScreen && style.compact)}>
      <div>
        <Headline>{headline}</Headline>
        {description && <p className={style.subHeading}>{description}</p>}
      </div>
      {rhs && <div className={style.actionContainer}>{rhs}</div>}
    </div>
  );
};

export default PageHeader;
