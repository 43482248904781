import styles from './SimpleCell.module.css'; // eslint-disable-line

// Horizontal Alignment
export enum CellContentAlign {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export const CellContentAlignClassNames = Object.freeze({
  [CellContentAlign.LEFT]: styles.contentAlignLeft,
  [CellContentAlign.CENTER]: styles.contentAlignCenter,
  [CellContentAlign.RIGHT]: styles.contentAlignRight,
});
