import React, { ReactNode, useState } from 'react';
import ReactDOM from 'react-dom';
import { Boundary, Placement as PopperPlacement, StrictModifiers } from '@popperjs/core';
import { usePopper } from 'react-popper';
import { isFunction } from '../../lib';
import { keyDownTriggerOnClick } from '../lib';

// Map to PopperPlacement values
export const Placement: Readonly<{ [key: string]: PopperPlacement }> = Object.freeze({
  AutoStart: 'auto-start',
  Auto: 'auto',
  AutoEnd: 'auto-end',
  TopStart: 'top-start',
  Top: 'top',
  TopEnd: 'top-end',
  RightStart: 'right-start',
  Right: 'right',
  RightEnd: 'right-end',
  BottomEnd: 'bottom-end',
  Bottom: 'bottom',
  BottomStart: 'bottom-start',
  LeftEnd: 'left-end',
  Left: 'left',
  LeftStart: 'left-start',
});

export type TetherProps = {
  children: (ref) => ReactNode;
  content: (() => ReactNode) | ReactNode;
  placement: PopperPlacement;
  boundariesElement?: Boundary;
  className?: string;
  arrowEnabled: boolean;
  arrowClassName?: string;
  parentNode?: HTMLElement | null;
  onClick?: (event: React.SyntheticEvent) => void;
  modifiers?: StrictModifiers[];
  fixedPlacement?: boolean;
  role?: string;
};

const getPopperModifiers = (
  arrowElement: HTMLElement | null | undefined,
  boundariesElement: Boundary | undefined,
  fixedPlacement: boolean,
): StrictModifiers[] => [
  {
    name: 'arrow',
    options: { element: arrowElement },
  },
  {
    name: 'flip',
    options: {
      rootBoundary: 'viewport',
      fallbackPlacements: fixedPlacement ? [] : ['left', 'right', 'top'],
    },
  },
  {
    name: 'preventOverflow',
    options: {
      boundary: boundariesElement,
    },
  },
];

export default function Tether({
  children,
  content,
  placement,
  boundariesElement,
  className,
  arrowEnabled,
  arrowClassName,
  onClick,
  modifiers,
  parentNode,
  fixedPlacement,
  role,
}: TetherProps) {
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: getPopperModifiers(arrowElement, boundariesElement, !!fixedPlacement).concat(
      modifiers || [],
    ),
  });

  // the onClick is only actually used to e.preventDefault right now anyways, we can probably make this a bit more robust
  /* eslint-disable jsx-a11y/no-static-element-interactions */

  const Popper = (
    <div
      role={role}
      className={className}
      onClick={onClick}
      onKeyDown={onClick ? keyDownTriggerOnClick(onClick) : undefined}
      ref={setPopperElement}
      style={styles.popper}
      {...attributes.popper}
    >
      <>
        {isFunction(content) ? (content as () => ReactNode)() : content}
        {arrowEnabled && (
          <div className={arrowClassName} ref={setArrowElement} style={styles.arrow} />
        )}
      </>
    </div>
  );

  return (
    <>
      {children(setReferenceElement)}
      {parentNode ? ReactDOM.createPortal(Popper, parentNode) : Popper}
    </>
  );
}

Tether.defaultProps = {
  arrowEnabled: false,
  boundariesElement: 'clippingParents',
  fixedPlacement: false,
  parentNode: document.body,
  placement: Placement.Top,
};
