import React from 'react';
import { Maybe } from '@tellurian/ts-utils';
import { Button, ButtonSize, ButtonStyle } from '../../../ui/Button';

type VerificationErrorProps = {
  step: string;
  onRetry: Maybe<() => void>;
  retryDisabled: boolean;
};

const Retry: React.FC<{ onRetry: () => void; disabled: boolean }> = ({ onRetry, disabled }) => {
  return (
    <Button
      onClick={onRetry}
      btnStyle={ButtonStyle.LINK}
      disabled={disabled}
      btnSize={ButtonSize.S}
      className="mts"
    >
      Retry
    </Button>
  );
};

export function VerificationError({ onRetry, retryDisabled }: VerificationErrorProps) {
  return (
    <>
      <div>Connection failed. Make sure your login information is correct or try again later.</div>
      {onRetry && <Retry onRetry={onRetry} disabled={retryDisabled} />}
    </>
  );
}

export const TokenExpired = () => (
  <div>
    The access token used to send data to the specified container has expired. To continue
    retrieving data from Crisp, please renew the token.
  </div>
);

export const TokenHasInsufficientPrivileges = () => (
  <div>
    The token provided does not allow write access to the specified container. To enable data
    retrieval from Crisp, please provide a token which allows for write access.
  </div>
);

export const ConnectionError: React.FC<VerificationErrorProps> = ({ onRetry, retryDisabled }) => (
  <>
    <div>
      We were unable to connect to the specified data container. This may be a temporary error, but
      you may wish to update your access details if you continue to see this message.
    </div>
    {onRetry && <Retry onRetry={onRetry} disabled={retryDisabled} />}
  </>
);
