import { useEffect } from 'react';
import { Feature, trackFeature } from '../utils/features';
import { useAuthorizationContext } from './security/AuthorizationContext';

/**
 * Segment event tracker component which is meant to be a temporary solution for updating Pendo with
 * user and account state, and should be removed when we have the backend ready for handling that.
 */
export default function SegmentUserAccountTracker({ accountId }: { accountId: string }) {
  const { currentUser } = useAuthorizationContext();

  useEffect(() => {
    const userAccountPermissions = currentUser?.accountPermissions
      .filter(p => p.accountId === accountId)
      .map(p => p.accountRole);

    trackFeature(Feature.AccountAccess, {
      userAccountPermissions,
    });
  }, [accountId, currentUser]);

  return null;
}
