import { useMemo } from 'react';
import _ from 'lodash';
import { Maybe } from '@tellurian/ts-utils';
import { LocalStorage, LocalStorageKey } from '../../../utils/localStorage';
import { useAuthorizationContext } from '../../security/AuthorizationContext';

const MAX_DASHBOARD_COUNT = 5;

export type RecentlyViewedDashboard = {
  dashboardGroupId?: string;
  dashboardId?: string;
  bookmarkId?: string;
};

export type RecentlyViewedDashboards = Record<string, Record<string, RecentlyViewedDashboard[]>>;

const getAllRecentlyViewedDashboards: () => RecentlyViewedDashboards = () => {
  return LocalStorage.getItem(LocalStorageKey.RecentlyViewedDashboards) || {};
};

const setRecentlyViewedDashboards = (
  userId: Maybe<string>,
  accountId: string,
  recentlyViewed: RecentlyViewedDashboard[],
  maxDashboards = MAX_DASHBOARD_COUNT,
) => {
  if (!userId) {
    return;
  }
  const allRecentlyViewedDashboards = getAllRecentlyViewedDashboards();
  LocalStorage.setItem(LocalStorageKey.RecentlyViewedDashboards, {
    ...allRecentlyViewedDashboards,
    [userId]: {
      ...allRecentlyViewedDashboards[userId],
      [accountId]: [..._.uniqWith(recentlyViewed, _.isEqual)].slice(0, maxDashboards),
    },
  });
};

const getRecentlyViewedDashboards: (
  userId: Maybe<string>,
  accountId: string,
) => RecentlyViewedDashboard[] = (userId, accountId) => {
  if (!userId) {
    return [];
  }

  const recentByUser = getAllRecentlyViewedDashboards()[userId];
  return recentByUser ? (recentByUser[accountId] ?? []) : [];
};

const useRecentlyViewedDashboards = (accountId: string) => {
  const { currentUser } = useAuthorizationContext();
  const userId = useMemo(() => currentUser?.id, [currentUser]);

  return useMemo(
    () => ({
      getRecentlyViewedDashboards: () => getRecentlyViewedDashboards(userId, accountId),

      add: (dashboard: RecentlyViewedDashboard) => {
        if (dashboard.dashboardGroupId && dashboard.dashboardId) {
          const recentlyViewed = getRecentlyViewedDashboards(userId, accountId);
          recentlyViewed.unshift(dashboard);
          setRecentlyViewedDashboards(userId, accountId, recentlyViewed);
        }
      },

      remove: (dashboard: RecentlyViewedDashboard) => {
        const recentlyViewed = getRecentlyViewedDashboards(userId, accountId);
        _.remove(recentlyViewed, r => _.isEqual(r, dashboard));
        setRecentlyViewedDashboards(userId, accountId, recentlyViewed);
      },
    }),
    [userId, accountId],
  );
};

export default useRecentlyViewedDashboards;
