import {
  DashboardAccessLevel,
  DashboardPermissionInput,
  UserAccountPermission,
  UserAccountRole,
} from '../../../../generated/graphql';
import { DashboardPermissions } from './DashboardPermissionsSelection';

/**
 * Get the user role for the specified accountId.
 * A user can only have one role per account (this is enforced at the database level).
 */
export const getUserAccountRole = (
  accountPermissions: Pick<UserAccountPermission, 'accountId' | 'accountRole'>[],
  accountId: string,
) =>
  accountPermissions.find(({ accountId: permissionAccountId }) => accountId === permissionAccountId)
    ?.accountRole;

export const UserRoleLabel: Record<UserAccountRole, string> = {
  [UserAccountRole.Admin]: 'Admin',
  [UserAccountRole.Editor]: 'Editor',
  [UserAccountRole.Viewer]: 'Viewer',
  [UserAccountRole.Member]: 'Dashboard Viewer',
};

export const PermittedUserAccountRoles = [
  UserAccountRole.Viewer,
  UserAccountRole.Editor,
  UserAccountRole.Admin,
] as const;

const AccountRoleEntries = Object.entries(UserAccountRole);
export const PermittedUserAccountRoleEntries = PermittedUserAccountRoles.map(
  role => AccountRoleEntries.find(([, v]) => v === role)!,
);

export const getUserPermissionsInputFromFormData = (formData: DashboardPermissions) => {
  const permissions: DashboardPermissionInput[] = [];
  for (const dashboardGroupId in formData) {
    for (const dashboardId in formData[dashboardGroupId]) {
      for (const connectorConfigurationId in formData[dashboardGroupId][dashboardId]) {
        if (formData[dashboardGroupId][dashboardId][connectorConfigurationId]) {
          permissions.push({
            accessLevel: DashboardAccessLevel.Read,
            scope: {
              dashboardGroupId,
              dashboardId,
              connectorConfigurationId,
            },
          });
        }
      }
    }
  }
  return permissions;
};

export const UserAccountRoleOptions = [
  UserAccountRole.Admin,
  UserAccountRole.Editor,
  UserAccountRole.Viewer,
  UserAccountRole.Member,
] as const;

export type UserAccountRoleOption = (typeof UserAccountRoleOptions)[number];
