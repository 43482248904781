import React from 'react';
import BasicTemplate from '../templates/BasicTemplate';
import { path } from '../routing/lib';
import AccountAutoSelector from '../../../AccountAutoSelector';

const AccountSelectPage = () => {
  return (
    <BasicTemplate>
      <AccountAutoSelector getAccountUri={accountId => path('AccountHome')({ accountId })} />
    </BasicTemplate>
  );
};

export default AccountSelectPage;
