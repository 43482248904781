import React, { ReactNode, useEffect, useRef } from 'react';
import { ButtonSize, GridType, useResponsive } from '../ui';
import PageActionBarComponent, { Action } from './index';

/**
 * Component which can adjust the bottom margin of a child component by providing a non-zero
 * additionalMargin value.
 * @param actions PageActionBar actions
 * @param children the child component whose margin will be adjusted
 * @param type GridType
 * @param withoutSidebars allow sidebar margin in the layout applied to PageActionBar
 * @param ensureMarginBottom true by default, computes the space required by the PageActionBar
 * component and ensures there is sufficient bottom spacing on the page, such that the action bar
 * can be displayed without obscuring (overlapping) content.
 * @constructor
 */
const WithPageActionBar = ({
  actions,
  children,
  type,
  withoutSidebars,
  ensureMarginBottom = true,
  className,
}: {
  actions: Action[];
  children: (ref: React.Ref<HTMLElement>) => ReactNode;
  type?: GridType;
  withoutSidebars?: boolean;
  ensureMarginBottom?: boolean;
  className?: string;
}) => {
  const childRef = useRef<HTMLElement | null>(null);
  const actionBarRef = useRef<HTMLDivElement>(null);
  const { isMaxSmallScreen } = useResponsive();

  useEffect(() => {
    if (ensureMarginBottom && childRef.current && actionBarRef.current) {
      const actionBarHeight = parseInt(window.getComputedStyle(actionBarRef.current).height, 10);
      const marginBottom = parseInt(window.getComputedStyle(childRef.current).marginBottom, 10);
      childRef.current.style.marginBottom = `${marginBottom + actionBarHeight}px`;
    }
  }, [ensureMarginBottom]);

  return (
    <>
      {children(childRef)}
      {actions.length ? (
        <PageActionBarComponent
          btnSize={isMaxSmallScreen ? ButtonSize.M : ButtonSize.L}
          actions={actions}
          type={type}
          withoutSidebars={withoutSidebars}
          ref={actionBarRef}
          className={className}
        />
      ) : null}
    </>
  );
};

export default WithPageActionBar;
