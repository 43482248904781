import React, { useMemo } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { BookmarkScope } from '../../../../../generated/graphql';
import { FormElement } from '../../../../ui/form/FormFields';
import UserSearchSelect, { SearchSelectUserEntity } from '../../../components/UserSearchSelect';
import TextAreaFormField from '../../../components/formFields/TextAreaFormField';
import { DropdownListOptions } from '../../../components/lib/dropdownListOption';
import { FormValues } from './ShareReportModal';
import ReportAccessSelector from './ReportAccessSelector';

export type ShareReportProps = {
  disabled?: boolean;
  accountUsers?: SearchSelectUserEntity[];
  accountUsersLoading?: boolean;
  control: Control<FormValues>;
  accessOptions: DropdownListOptions;
};

const ShareReport: React.FC<ShareReportProps> = ({
  accountUsersLoading = false,
  accountUsers = [],
  disabled,
  control,
  accessOptions,
}) => {
  const scope = useWatch({ control, name: 'scope' });
  const users = useWatch({ control, name: 'users' });
  const defaultUsers = control._defaultValues.users;
  const hasNewUsers = useMemo(
    () => users.some(u => u.isNewUser || !defaultUsers?.find(user => user?.email === u.email)),
    [defaultUsers, users],
  );

  return (
    <>
      <ReportAccessSelector accessOptions={accessOptions} />
      {scope === BookmarkScope.UserShared && (
        <>
          <FormElement<SearchSelectUserEntity>
            name="users"
            render={({ value, onChange }) => (
              <UserSearchSelect
                users={accountUsers}
                selection={value}
                onChange={onChange}
                label="Add users"
                disabled={accountUsersLoading || disabled}
                title="Share with"
                RenderEmptySelection="No users specified"
              />
            )}
          />
          {users.length > 0 && hasNewUsers && (
            <TextAreaFormField name="message" label="Message" rows={3} className="mod" />
          )}
        </>
      )}
    </>
  );
};

export default ShareReport;
