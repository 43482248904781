import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Connector } from '../../../../generated/graphql';
import { LocalStorageKey } from '../../../../utils/localStorage';
import useLocalStorageState from '../../../../utils/localStorage/useLocalStorageState';
import { FCC } from '../../../../utils/types';

export type ConnectorLogoContextInterface = {
  hasLogo: (connector: Pick<Connector, 'application'>) => boolean;
};

const ConnectorLogoContext = React.createContext<ConnectorLogoContextInterface>({
  hasLogo: () => false,
});

type ConnectorLogoList = { items: { name: string }[] };

const ConnectorLogoContextProvider: FCC = ({ children }) => {
  const [list, setList] = useLocalStorageState(LocalStorageKey.ConnectorLogoNames, []);
  const logoNameSet = useMemo(() => new Set(list), [list]);
  const runOnceRef = useRef(false);

  useEffect(() => {
    if (!runOnceRef.current) {
      runOnceRef.current = true;
      fetch(
        'https://storage.googleapis.com/storage/v1/b/crisp-platform-us-logos/o/?fields=items/name',
      )
        .then(response => response.json())
        .then((data: ConnectorLogoList) => {
          const nextList = data.items.map(item =>
            item.name.substring(0, item.name.lastIndexOf('.')),
          );
          // Do not update the list unless there is a discrepancy between the incoming and current set
          if (nextList.length !== list.length || nextList.some(name => !logoNameSet.has(name))) {
            setList(nextList);
          }
        });
    }
  }, [setList, logoNameSet, list]);
  const hasLogo: ConnectorLogoContextInterface['hasLogo'] = useCallback(
    connector => logoNameSet.has(connector.application.toLowerCase()),
    [logoNameSet],
  );

  return (
    <ConnectorLogoContext.Provider value={{ hasLogo }}>{children}</ConnectorLogoContext.Provider>
  );
};

export default ConnectorLogoContextProvider;

export const useConnectorLogoContext = () => React.useContext(ConnectorLogoContext);
export const useHasLogo = () => useConnectorLogoContext().hasLogo;
