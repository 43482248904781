export enum ModalSize {
  S = 'small',
  M = 'medium',
  L = 'large',
  XL = 'xlarge',
  FullScreen = 'fullScreen',
}

export const disableBodyScroll = (): number => {
  const { body, documentElement } = document;
  const scrollTop = Math.max(
    window.scrollX,
    documentElement ? documentElement.scrollTop : 0,
    body ? body.scrollTop : 0,
  );

  if (body) {
    body.style.overflow = 'hidden';
    body.style.position = 'fixed';
    body.style.left = '0';
    body.style.right = '0';
    body.style.top = `${-scrollTop}px`;
  }

  return scrollTop;
};

export const enableBodyScroll = (scrollTop: number) => {
  const { body, documentElement } = document;
  if (body) {
    body.style.overflow = '';
    body.style.position = '';
    body.style.left = '';
    body.style.right = '';
    body.style.top = '';
    body.scrollTop = scrollTop;
  }
  if (documentElement) {
    documentElement.scrollTop = scrollTop;
  }
};
