import React from 'react';
import { EditorAuthorityGrant } from '../../../security/AuthorizationContext';
import AccountTemplate from '../templates/AccountTemplate';
import Mdm from '../Mdm';
import { lazyWithRetry } from '../../../lib';
import { AccountIdMatchParams } from './lib';

const RenderMdm = lazyWithRetry<typeof Mdm>(() => import(/* webpackChunkName: "mdm" */ '../Mdm'));

const MdmPage: React.FC<AccountIdMatchParams> = ({
  match: {
    params: { accountId },
  },
}) => {
  return (
    <AccountTemplate requiredGrant={EditorAuthorityGrant}>
      <RenderMdm accountId={accountId} />
    </AccountTemplate>
  );
};

export default MdmPage;
