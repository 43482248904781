import React from 'react';
import clsx from 'clsx';
import style from './BasicInput.module.css';

export type BasicInputProps = React.InputHTMLAttributes<HTMLInputElement>;

const BasicInput = React.forwardRef<HTMLInputElement, BasicInputProps>(function BasicInput(
  { className, ...rest }: BasicInputProps,
  ref,
) {
  return <input className={clsx(style.input, className)} ref={ref} {...rest} />;
});

export default BasicInput;
