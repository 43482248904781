import React from 'react';
import { RouteComponentProps } from 'react-router';
import { AdminAuthorityGrant } from '../components/security/AuthorizationContext';
import ScrollToTop from '../components/Utils/ScrollToTop';
import TermsOfService from '../components/TermsOfService';
import { lazyWithRetry } from '../components/lib';
import AccountTemplate from '../components/lettuce/crisp/templates/AccountTemplate';

const RenderTermsOfService = lazyWithRetry<typeof TermsOfService>(
  () => import(/* webpackChunkName: "termsOfService" */ '../components/TermsOfService'),
);

type MatchParams = {
  accountId: string;
};

export default function TermsOfServicePage({
  match: {
    params: { accountId },
  },
}: RouteComponentProps<MatchParams>) {
  return (
    <AccountTemplate requiredGrant={AdminAuthorityGrant}>
      <ScrollToTop />
      <RenderTermsOfService accountId={accountId} />
    </AccountTemplate>
  );
}
