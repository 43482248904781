import { Dispatch, SetStateAction, useCallback } from 'react';
import { Maybe } from '@tellurian/ts-utils';
import { useHistory } from 'react-router';
import { stringifyToQuery, useParsedQuery } from '../utils/querystringUtils';
import { Feature, trackFeature } from '../utils/features';
import {
  getUrlForIdentifier,
  TableIdentifier,
  TableIdentifierSerialization,
} from './ModelMetadataDocs/lib';
import { MetadataModuleFragment } from './ModelMetadataDocs/DataBoundModelTable';

export type UseTableIdentifierQueryParameterState = {
  selectedTableIdentifier: Maybe<TableIdentifier>;
  columnToEmphasize: Maybe<string>;
  setSelectedTableIdentifier: (nextIdentifier: TableIdentifier) => void;
};

const [serializeIdentifier, deserializeIdentifier] = TableIdentifierSerialization;
export const getIdentifierUrl = (
  tableIdentifier: TableIdentifier,
  columnToEmphasize?: string,
): string => getUrlForIdentifier(window.location.pathname)(tableIdentifier, columnToEmphasize);

export const useTableIdentifierQueryParameterState = (): UseTableIdentifierQueryParameterState => {
  const history = useHistory();
  const { key, col } = useParsedQuery<{ key?: string; col?: string }>();

  const selectedTableIdentifier = key ? deserializeIdentifier(key) : undefined;
  const setSelectedTableIdentifier = useCallback(
    (tableIdentifier: TableIdentifier) => {
      const updateHistory = key ? history.push : history.replace;
      updateHistory({ search: stringifyToQuery({ key: serializeIdentifier(tableIdentifier) }) });
      trackFeature(Feature.DocsSelectTable, { tableIdentifier });
    },
    [history, key],
  );

  return {
    selectedTableIdentifier,
    columnToEmphasize: col,
    setSelectedTableIdentifier,
  };
};

export type SourceTableLabel = {
  id: string;
  name: string;
  description?: string;
};

export const sourceTableLabelEquals =
  (l1: Pick<SourceTableLabel, 'id'>) =>
  (l2: Pick<SourceTableLabel, 'id'>): boolean =>
    l1.id === l2.id;

export type DocsSearchContext = {
  module?: MetadataModuleFragment;
  labels: SourceTableLabel[];
};

export type SetSearchContext = Dispatch<SetStateAction<Maybe<DocsSearchContext>>>;
