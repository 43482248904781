import React, { KeyboardEvent, useEffect, useMemo, useState } from 'react';
import { DropdownListOption, DropdownListOptions, toCanonicalOptions } from '../dropdownListOption';
import useDebounce from '../../../../../utils/useDebounce';
import { ChipDropdownMultiSelectProps } from '../../ChipDropdown/ChipDropdownMultiSelectWithLabel';
import { OnKeyDownHandler } from '../../../../ui/lists/getListKeyHandler';
import { Key } from '../../../../../utils/keyCodes';
import useSelectCommon, {
  FooterHeight,
  MaxPopoverHeight,
  SearchPanelHeight,
  UseSelectCommonParams,
} from '../useSelectCommon';
import useMultiSelectItems, { Features } from './useMultiSelectItems';

export const deactivateDropdownOnBlurFactory =
  <T extends HTMLElement = HTMLElement, P extends HTMLElement = HTMLElement>(
    dropdownRef: React.RefObject<T>,
    dropdownParentRef: React.RefObject<P>,
    deactivateDropdown: () => void,
  ) =>
  (e: React.FocusEvent) => {
    if (dropdownRef.current || dropdownParentRef.current) {
      let relatedTargetTrace: HTMLElement | null = e.relatedTarget as HTMLElement;
      if (relatedTargetTrace === dropdownParentRef.current) {
        // If the focus was passed to the parent element of the dropdown, do not deactivate
        return;
      }

      while (relatedTargetTrace) {
        if (relatedTargetTrace === dropdownRef.current) {
          // If the focus was passed to another element inside the dropdown, do not deactivate
          return;
        }
        relatedTargetTrace = relatedTargetTrace.parentElement;
      }

      // At this point, we know the focus is outside the dropdown, so proceed with deactivation
      deactivateDropdown();
      return true;
    }

    return false;
  };

export type MultiSelectValues = DropdownListOption['value'][];

const getDropdownListOptionValue = (option: DropdownListOption) => option.value;
const dropdownListOptionSearchMatch = (query: string, item: DropdownListOption) =>
  (item.name || item.value).toLowerCase().includes(query);

type UseMultiSelectOptionsParams = {
  options: DropdownListOptions;
  selection?: MultiSelectValues;
  onChange: (nextValues: MultiSelectValues) => void;
  features?: Partial<Features<DropdownListOption>>;
};

export const selectedOptionsEqual = (
  options1: DropdownListOption[],
  options2: DropdownListOption[],
) => {
  if (options1.length === options2.length) {
    return options1.every((option, index) => options2[index].value === option.value);
  }

  return false;
};

export const useMultiSelectOptions = ({ options, ...rest }: UseMultiSelectOptionsParams) =>
  useMultiSelectItems<DropdownListOption, DropdownListOption['value']>({
    items: useMemo(() => toCanonicalOptions(options), [options]),
    getItemValue: getDropdownListOptionValue,
    searchMatch: dropdownListOptionSearchMatch,
    ...rest,
  });

export const useOnSearch = (onSearch?: (nextQuery: string) => void) => {
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 300);
  useEffect(() => {
    if (onSearch) {
      onSearch(debouncedQuery);
    }
  }, [debouncedQuery, onSearch]);

  return { query, setQuery };
};

type UseMultiSelectCommonParams<T extends HTMLElement> = UseSelectCommonParams<T> & {
  onToggleAll?: (selected: boolean) => void;
  onClear?: () => void;
};

const getStaticElementHeight = (
  features: Pick<ChipDropdownMultiSelectProps<unknown>, 'onToggleAll' | 'onClear' | 'onSearch'>,
): number => {
  let height = 0;
  if (features.onClear || features.onToggleAll) {
    height += FooterHeight;
  }

  if (features.onSearch) {
    height += SearchPanelHeight;
  }

  return height;
};

export const useMultiSelectCommon = <T extends HTMLElement>({
  onToggleAll,
  onClear,
  ...rest
}: UseMultiSelectCommonParams<T>) => {
  const { onSearch } = rest;

  const listStyle = useMemo(() => {
    return {
      maxHeight:
        onToggleAll || onClear
          ? `calc(${MaxPopoverHeight} - ${getStaticElementHeight({ onClear, onToggleAll, onSearch })}px)`
          : MaxPopoverHeight,
    };
  }, [onToggleAll, onClear, onSearch]);

  return {
    ...useSelectCommon<T>(rest),
    listStyle,
  };
};

export const escapeSpaceKey =
  (onKeyDownHandler?: OnKeyDownHandler) => (ev: KeyboardEvent<HTMLInputElement>) => {
    if (onKeyDownHandler && ev.key !== Key.Space) {
      return onKeyDownHandler(ev);
    }
  };
