import React, { ReactNode, useCallback } from 'react';
import clsx from 'clsx';
import Title from '../Title';
import EntityList, { EntityListProps } from '../EntityList';
import { removeAction } from '../EntityList/lib';
import SearchSelect, { SearchSelectProps } from './SearchSelect';
import style from './index.module.css';

export type EntitySearchSelectProps<T> = {
  itemEquals?: (item1: T, item2: T) => boolean;
  selection: T[];
  onChange: (nextSelection: T[]) => void;
  RenderEntityItem: React.FC<{ item: T }>;
  RenderEntitySearchResultItem: SearchSelectProps<T>['RenderItem'];
  disabled?: boolean;
  title?: string;
  RenderEmptySelection?: ReactNode;
} & Pick<
  SearchSelectProps<T>,
  | 'label'
  | 'getSearchResults'
  | 'initialQuery'
  | 'getItemKey'
  | 'autoFocus'
  | 'toggleOnSpace'
  | 'placeholder'
  | 'aria-describedby'
  | 'hasError'
> &
  Pick<EntityListProps<T>, 'aria-label' | 'aria-labelledby'>;

function EntitySearchSelect<T>({
  itemEquals = Object.is,
  selection,
  RenderEntityItem,
  RenderEntitySearchResultItem,
  'aria-label': ariaLabel,
  'aria-labelledby': ariaLabelledBy,
  onChange,
  disabled = false,
  title,
  RenderEmptySelection,
  ...searchSelectProps
}: EntitySearchSelectProps<T>) {
  const onItemSelect = useCallback(
    item => !selection.some(i => itemEquals(i, item)) && onChange([...selection, item]),
    [onChange, selection, itemEquals],
  );

  const onItemRemove = useCallback(
    (item: T) => onChange(selection.filter(i => !itemEquals(i, item))),
    [onChange, itemEquals, selection],
  );

  return (
    <>
      <div
        className={clsx('mhm', { mbm: selection.length === 0 && !title && !RenderEmptySelection })}
      >
        <SearchSelect
          RenderItem={RenderEntitySearchResultItem}
          onSelect={onItemSelect}
          disabled={disabled}
          {...searchSelectProps}
        />
      </div>
      {title && <Title size="M">{title}</Title>}
      {selection.length > 0 ? (
        <EntityList<T>
          items={selection}
          RenderItemContent={RenderEntityItem}
          aria-label={ariaLabel}
          aria-labelledby={ariaLabelledBy}
          actions={disabled ? [] : [removeAction(onItemRemove)]}
        />
      ) : (
        RenderEmptySelection && <p className={style.emptySelection}>{RenderEmptySelection}</p>
      )}
    </>
  );
}

export default EntitySearchSelect;
