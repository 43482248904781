import React, { useContext } from 'react';
import { FCC } from '../../../../utils/types';

type BackdropPredicateContextInterface = {
  useIncludeBackdrop: () => boolean;
};

export const BackdropPredicateContext = React.createContext<BackdropPredicateContextInterface>({
  // Use with either a hook or "plain" function predicate.
  useIncludeBackdrop: () => false,
});

type BackdropPredicateContextProviderProps = {
  useIncludeBackdrop: () => boolean;
};

export const BackdropPredicateContextProvider: FCC<BackdropPredicateContextProviderProps> = ({
  children,
  useIncludeBackdrop,
}) => {
  return (
    <BackdropPredicateContext.Provider value={{ useIncludeBackdrop }}>
      {children}
    </BackdropPredicateContext.Provider>
  );
};

export const useIncludeBackdrop = () => useContext(BackdropPredicateContext).useIncludeBackdrop();
