import React from 'react';
import {
  CommonFieldProps,
  FormElement,
  PartialFormElementProps,
} from '../../../ui/form/FormFields';
import TextField, { TextFieldProps } from '../TextField';

type TextFormFieldProps = PartialFormElementProps<string> & Pick<TextFieldProps, CommonFieldProps>;

const TextFormField: React.FC<TextFormFieldProps> = ({
  id,
  validate,
  name,
  defaultValue,
  onChange: onChangeProp,
  ...textFieldProps
}) => {
  return (
    <FormElement
      id={id}
      name={name}
      defaultValue={defaultValue}
      validate={validate}
      render={({ triggerValidation: _, onChange, errorMessage, ...renderProps }) => (
        <TextField
          {...renderProps}
          {...textFieldProps}
          onChange={(nextValue: string) => {
            onChange(nextValue);
            onChangeProp?.(nextValue);
          }}
          error={errorMessage}
        />
      )}
    />
  );
};

export default TextFormField;
