import React from 'react';
import { CellProps, Column, Renderer } from 'react-table';
import { ProductMasterDataEntry } from '../../../generated/graphql';
import SimpleCell from '../../ui/SimpleTable/Cells/SimpleCell';
import { SimpleTable } from '../../ui/SimpleTable';

const ProductMasterDataEntryCell: Renderer<CellProps<ProductMasterDataEntry>> = ({ cell }) => {
  return (
    <SimpleCell>
      {cell.value === true ? 'yes' : cell.value === false ? 'no' : cell.value?.toString()}
    </SimpleCell>
  );
};

const columns: Column<ProductMasterDataEntry>[] = [
  {
    Header: <SimpleCell>GTIN-13</SimpleCell>,
    accessor: 'productGtin13',
    Cell: ProductMasterDataEntryCell,
  },
  {
    Header: <SimpleCell>Product</SimpleCell>,
    accessor: 'productDescription',
    Cell: ProductMasterDataEntryCell,
  },
  {
    Header: <SimpleCell>Display name</SimpleCell>,
    accessor: 'productDescriptionOverride',
    Cell: ProductMasterDataEntryCell,
  },
  {
    Header: <SimpleCell>OData enabled</SimpleCell>,
    accessor: 'odataEnabled',
    Cell: ProductMasterDataEntryCell,
  },
  {
    Header: <SimpleCell>Dashboards enabled</SimpleCell>,
    accessor: 'dashboardsEnabled',
    Cell: ProductMasterDataEntryCell,
  },
];

type ModifiedEntriesTableProps = {
  entries: ProductMasterDataEntry[];
};

export function ModifiedEntriesTable({ entries }: ModifiedEntriesTableProps) {
  return (
    <div className={'mbm'}>
      <h3 className={'mln'}>Modified entries</h3>
      <SimpleTable columns={columns} data={entries} />
    </div>
  );
}
