import { useEffect } from 'react';

const disableBodyScroll = () => {
  const { body } = document;

  if (body) {
    body.style.overflow = 'hidden';
    body.style.height = '100%';
  }
};

const enableBodyScroll = () => {
  const { body } = document;
  if (body) {
    body.style.overflow = '';
    body.style.height = '';
  }
};

export const useDisableBodyScroll = (disable = false) => {
  useEffect(() => {
    disable ? disableBodyScroll() : enableBodyScroll();
    // Ensure body scroll is re-enabled if the component is unmounted
    return enableBodyScroll;
  }, [disable]);
};
