import React from 'react';
import { Button, ButtonStyle } from '../../Button';
import CopiedToClipboard from './CopiedToClipboard';
import useClipboardCopy from './useClipboardCopy';

type ClipboardCopyProps = {
  value: string;
};

const ClipboardCopy: React.FC<ClipboardCopyProps> = ({ value }) => {
  const { isValueCopiable, isValueCopied, copyToClipboard } = useClipboardCopy(value);
  if (isValueCopiable) {
    return (
      <div className="mhm">
        {isValueCopied ? (
          <CopiedToClipboard />
        ) : (
          <Button btnStyle={ButtonStyle.LINK} onClick={copyToClipboard}>
            Copy to clipboard
          </Button>
        )}
      </div>
    );
  }

  return null;
};

export default ClipboardCopy;
