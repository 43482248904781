import React from 'react';
import { TermsOfServicePolicyType } from '../../generated/graphql';
import { ReactComponent as IconEye } from '../../assets/icons/eye.svg';
import Text from '../ui/Text';
import { getVersionUrl } from '../TermsOfService/TermsOfServiceContent';
import { VersionIdentifier } from '../TermsOfService/lib';
import style from './lib.module.css';

export type CreateVersionValues = Pick<VersionIdentifier, 'policyType'> & {
  version: string;
  notes: string;
  requiresNotification: boolean;
};

export const termsOfServiceDocumentExistsInBucket = async (
  policyType: TermsOfServicePolicyType,
  version: number,
): Promise<boolean> => {
  try {
    const result = await fetch(getVersionUrl({ policyType, version }), { mode: 'cors' });
    return result.ok;
  } catch (err) {
    return false;
  }
};

export const VersionChangeGuideUrl = process.env.REACT_APP_TERMS_OF_SERVICE_GUIDE_URL;

export const NoPreviewAvailable: React.FC = () => {
  return (
    <div className={style.stretchContainer}>
      <div className={style.body}>
        <IconEye />
        <p className="mtl">
          Select a <Text semibold>policy type</Text> and <Text semibold>version</Text> to preview
          the terms of service document.
        </p>
      </div>
    </div>
  );
};
