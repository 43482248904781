import { useRef } from 'react';
import { Maybe } from '@tellurian/ts-utils';

const useSingleton = <T>(factory: () => T) => {
  const ref = useRef<Maybe<T>>(undefined);

  if (ref.current === undefined) {
    ref.current = factory();
  }

  return ref.current;
};

export default useSingleton;
