import pathToRegexp from 'path-to-regexp';
import { maybe, Maybe } from '@tellurian/ts-utils';
import { matchPath } from 'react-router-dom';
import { match, useHistory } from 'react-router';
import { encodeRouteParams, RouteParams } from '../../components/routing/lib';
import CrispApp from '../CrispApp';
import { ConnectorConfigurationTabId } from '../ConnectorConfigurations/View';
import { RouteById, RouteId, Routes } from './Routes';

const compilePath = pathToRegexp.compile;
export const path = (routeId: RouteId): ((params?: RouteParams) => string) => {
  return params => compilePath(RouteById[routeId].path)(params && encodeRouteParams(params));
};

CrispApp.path = path;

type GetPathConfig = {
  routeId: RouteId;
  // URL parameters. E.g. will substitute :accountId, :connectorConfigurationSlug, etc in path declarations
  params?: RouteParams;
  // Query parameters, will be appended to path as "?param1=value1&param2=value2"
  query?: RouteParams;
};

const toDictionary = (params: RouteParams): Record<string, string> => {
  return Object.fromEntries(
    Object.entries(params).map(([key, value]) => [key, value ? value.toString() : '']),
  );
};

function getPath(routeId: RouteId): string;
function getPath(config: GetPathConfig): string;
function getPath(config: RouteId | GetPathConfig) {
  if (typeof config === 'string') {
    return compilePath(RouteById[config].path)();
  }

  const { routeId, params, query } = config;
  const path = compilePath(RouteById[routeId].path)(params && encodeRouteParams(params));
  if (query) {
    return `${path}?${new URLSearchParams(toDictionary(query))}`;
  }

  return path;
}

export { getPath };

export const getPathWithTabId = <T extends string>(
  routeId: RouteId,
  tabId: T,
  params?: RouteParams,
): string => {
  return getPath({
    routeId,
    query: { tab: tabId },
    params,
  });
};

const PathRegexp: Record<RouteId, RegExp> = Routes.reduce(
  (acc, { id, path }) => {
    acc[id] = pathToRegexp(path);
    return acc;
  },
  {} as Record<RouteId, RegExp>,
);

export const routeIdFromPath = (path: string): Maybe<RouteId> => {
  return Object.entries(PathRegexp).find(([, regexp]) => regexp.test(path))?.[0] as Maybe<RouteId>;
};

/**
 * Fallback way to get an account id out of a url for places where hooks are not available.
 * @param pathname the path / URL to extract from, e.g `window.location.pathname`.
 */
export function getAccountId(pathname = window.location.pathname): Maybe<string> {
  type AccountParams = {
    accountId?: string;
  };
  return Routes.reduce<Maybe<match<AccountParams>>>(
    (acc, route) =>
      acc ?? maybe(matchPath<AccountParams>(pathname, { path: route.path, exact: true })),
    undefined,
  )?.params?.accountId;
}

CrispApp.getAccountId = getAccountId;

// Frequently used gotos
export const goToSourceConnectors = (history: ReturnType<typeof useHistory>, accountId: string) => {
  history.push(
    getPathWithTabId<ConnectorConfigurationTabId>('Connectors', 'sources', {
      accountId,
    }),
  );
};
