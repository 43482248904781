import React, { useCallback, useContext, useEffect, useState } from 'react';
import numeral, { Numeral } from 'numeral';
import { formatWithOptions } from 'date-fns/fp';
import { enGB, enUS } from 'date-fns/locale';
import { registerNumeralLocales } from './numeralLocales';
import { FnsFormat, FnsFormats } from './dateFnsFormats';
import { Locale } from './Locale';
export { FnsFormat } from './dateFnsFormats';

export type DateFormat = (format: FnsFormat | string) => (date: Date) => string;
const defaultLocale = Locale.enUS;

const isUSLang = (langCode: string) => langCode === 'en-US' || langCode === 'en-us';

function isNorwegianLang(langCode: string) {
  const lang = langCode.slice(0, 2);
  return lang === 'nb' || lang === 'nn' || lang === 'no';
}

export const toDateFnsLocale = (locale: Locale) => (locale === Locale.enUS ? enUS : enGB);
const toNumeralLocale = (locale: Locale) => (locale === Locale.nb ? 'nb' : 'en');

export enum NumFormat {
  currency = '0,0[.]00',
  rounded = '0,0',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  decimals = '0,0[.]00',
}

export interface I18nContextInterface {
  dateFormat: DateFormat;
  locale: Locale;
  numeral: (num: string | number | undefined | null) => Numeral;
}

registerNumeralLocales();
numeral.defaultFormat(NumFormat.rounded);
numeral.nullFormat('');

function localizedNumeral(locale: Locale, num: string | number | undefined | null) {
  numeral.locale(toNumeralLocale(locale));
  return numeral(num === undefined ? null : num);
}

export const toStringFormat = (format: FnsFormat | string, locale: Locale): string =>
  typeof format === 'string' ? format : FnsFormats(locale)[format];

export const I18nContext = React.createContext<I18nContextInterface>({
  dateFormat: format =>
    formatWithOptions(
      { locale: toDateFnsLocale(defaultLocale) },
      toStringFormat(format, defaultLocale),
    ),
  locale: defaultLocale,
  numeral: num => localizedNumeral(defaultLocale, num),
});

interface I18nProviderProps {
  children: React.ReactNode;
  locale?: Locale;
}

export function I18nProvider({ locale, children }: I18nProviderProps) {
  const [currentLocale, setCurrentLocale] = useState(() => {
    const navigatorLang = window.navigator.language;

    if (locale) return locale;
    if (isUSLang(navigatorLang)) return Locale.enUS;
    if (isNorwegianLang(navigatorLang)) return Locale.nb;

    return Locale.other;
  });

  useEffect(() => {
    if (locale) {
      setCurrentLocale(locale);
    }
  }, [locale]);

  const dateFormat = useCallback(
    (format: FnsFormat | string) =>
      formatWithOptions(
        { locale: toDateFnsLocale(currentLocale) },
        toStringFormat(format, currentLocale),
      ),
    [currentLocale],
  );

  return (
    <I18nContext.Provider
      value={{
        dateFormat,
        locale: currentLocale,
        numeral: num => localizedNumeral(currentLocale, num),
      }}
    >
      {children}
    </I18nContext.Provider>
  );
}

export const useI18nContext = () => useContext(I18nContext);
