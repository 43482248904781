import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { NormalizedCacheObject } from '@apollo/client/cache';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import { CookiesProvider } from 'react-cookie';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import logger from '../../../services/logger';
import SegmentTracker from '../../SegmentTracker';
import withApolloClient from '../../withApolloClient';
import { FeatureFlagsProvider } from '../common/featureFlags/FeatureFlags';
import { AuthorizationProvider } from '../../security/AuthorizationContext';
import { PageErrorProvider } from '../../errorHandling/PageError/usePageError';
import { AuthenticationProvider } from '../../security/AuthenticationContext';
import ThemeProvider from '../components/Theme/ThemeProvider';
import { Theme } from '../components/Theme';
import Spinner from '../components/Spinner';
import AppRouteSwitch from './routing/AppRouteSwitch';
import { useGetAuthConfiguration } from './authentication/useGetAuthConfiguration';
import { PartnerAuthProvider } from './authentication/PartnerAuthContext';
import Logout from './authentication/Logout';
import AuthConfigurationError from './authentication/prompts/AuthConfigurationError';
import PartnerAuthInstance from './authentication/PartnerAuthInstance';

export const LOGOUT_PATH = '/p/logout';

type AppProps = {
  apolloClient: ApolloClient<NormalizedCacheObject>;
};

const subdomain = 'dotfoodsdev';

const App: React.FC<AppProps> = ({ apolloClient }) => {
  const { authConfiguration, loading, error } = useGetAuthConfiguration({ subdomain: subdomain });
  if (loading) return <Spinner />;

  if (!authConfiguration) {
    logger.error(`Partner Auth Error: No auth configuration found for ${subdomain}`);
    return (
      <AuthConfigurationError
        subdomain={subdomain}
        error={`No auth configuration found for ${subdomain}`}
      />
    );
  }

  if (window.location.pathname === LOGOUT_PATH && authConfiguration) return <Logout />;

  if (error) {
    logger.error('Partner Auth Error: Error fetching auth configuration', error);
    return <AuthConfigurationError subdomain={subdomain} error={error?.message} />;
  }

  return (
    <div>
      <CookiesProvider>
        <AuthenticationProvider>
          <PartnerAuthInstance authConfiguration={authConfiguration}>
            <PartnerAuthProvider config={authConfiguration}>
              <ApolloProvider client={apolloClient}>
                <PageErrorProvider>
                  <AuthorizationProvider>
                    <ThemeProvider theme={Theme.Dot}>
                      <FeatureFlagsProvider>
                        <BrowserRouter>
                          <SegmentTracker />
                          <AppRouteSwitch />
                        </BrowserRouter>
                      </FeatureFlagsProvider>
                    </ThemeProvider>
                  </AuthorizationProvider>
                </PageErrorProvider>
              </ApolloProvider>
            </PartnerAuthProvider>
          </PartnerAuthInstance>
        </AuthenticationProvider>
      </CookiesProvider>
    </div>
  );
};

export default withApolloClient(
  // @ts-expect-error Improper type required
  withLDProvider<AppProps>({
    clientSideID: '5d7970f8c6a4a007ae338556',
  })(App),
  false,
);
