import React from 'react';
import { Maybe } from '@tellurian/ts-utils';

export type MouseEventHandlers = {
  onMouseMove: Maybe<() => void>;
  onMouseEnter: Maybe<() => void>;
  onMouseDown: (e: React.MouseEvent) => void;
};

export type MouseEventHandlerFactoryParams = {
  ignoreFocusOnMouseEnter: boolean;
  setIgnoreFocusOnMouseEnter: (ignore: boolean) => void;
  onFocusChange: (index: number) => void;
  onSelect: (index: number) => void;
  isListFocused?: boolean;
};

export const mouseEventHandlerFactory =
  ({
    ignoreFocusOnMouseEnter,
    setIgnoreFocusOnMouseEnter,
    onFocusChange,
    onSelect,
    isListFocused = false,
  }: MouseEventHandlerFactoryParams) =>
  (index: number): MouseEventHandlers => {
    const onMouseMove = isListFocused
      ? () => {
          if (ignoreFocusOnMouseEnter) {
            setIgnoreFocusOnMouseEnter(false);
            onFocusChange(index);
          }
        }
      : undefined;
    const onMouseEnter = isListFocused
      ? () => {
          if (!ignoreFocusOnMouseEnter) {
            onFocusChange(index);
          }
        }
      : undefined;

    // Use mouseDown here to ensure the element selection happens before the input field's blur event triggers
    const onMouseDown = (e: React.MouseEvent) => {
      e.preventDefault();
      onFocusChange(index);
      onSelect(index);
    };

    return { onMouseMove, onMouseEnter, onMouseDown };
  };

/**
 * Use values directly as class names (apply translation later when/if required).
 */
export enum ListFocusStyle {
  Border = 'borderFocus',
  BorderOmitTop = 'borderFocus omitTopBorder',
  BorderOmitBottom = 'borderFocus omitBottomBorder',
  BorderOmitTopBottom = 'borderFocus omitTopBorder omitBottomBorder',
  Background = 'backgroundFocus',
}

export const getItemId = (listId: string, index: number): string => `${listId}-item-${index}`;
