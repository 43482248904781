import React from 'react';
import clsx from 'clsx';
import style from './InterstitialItem.module.css';

type InterstitialItemProps = Omit<React.HTMLAttributes<HTMLLIElement>, 'role'> & {
  preventMouseDownDefault?: boolean;
  sticky?: boolean;
};

const InterstitialItem = React.forwardRef<HTMLLIElement, InterstitialItemProps>(
  function InterstitialItem(
    {
      children,
      className,
      preventMouseDownDefault = true,
      sticky = false,
      ...rest
    }: InterstitialItemProps,
    ref,
  ) {
    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <li
        role="separator"
        ref={ref}
        className={clsx({ [style.sticky]: sticky }, className)}
        {...rest}
        onMouseDown={ev => {
          preventMouseDownDefault && ev.preventDefault();
          rest.onMouseDown?.(ev);
        }}
      >
        {children}
      </li>
    );
  },
);

export default React.memo(InterstitialItem);
