import React, { ReactElement, ReactNode, RefAttributes, useId } from 'react';
import FocusTrap from 'focus-trap-react';
import clsx from 'clsx';
import Backdrop, { BackdropVariant } from '../Backdrop';
import Panel, { PanelProps } from '../Panel';
import FloatingPortal from '../lib/FloatingPortal';
import useModalDisableBodyScroll from './useModalDisableBodyScroll';
import style from './ModalBase.module.css'; // eslint-disable-line

const ModalSizes = ['XS', 'S', 'M', 'L'] as const;
export type ModalSize = (typeof ModalSizes)[number];

const ModalContentVariants = ['Dialog', 'Drawer'] as const;
type ModalContentVariant = (typeof ModalContentVariants)[number];

type ModalContentProps = {
  header?: ReactNode;
  footer?: ReactNode;
  children?: ReactNode;
  contentClassName?: string;
};

type DialogPanelProps = {
  fixedHeight?: boolean;
  children: ReactNode;
  size?: ModalSize;
} & Pick<PanelProps, 'aria-labelledby'>;

const DialogPanel = React.forwardRef<HTMLDivElement, DialogPanelProps>(function DialogPanel(
  { children, fixedHeight = false, size = 'M', ...rest },
  ref,
) {
  return (
    <Panel
      className={clsx(style.container, style[size], { [style.fixedHeight]: fixedHeight })}
      ref={ref}
      decorated={true}
      role="dialog"
      {...rest}
    >
      {children}
    </Panel>
  );
});

// type DrawerPanelProps = {};

const DrawerPanel = React.forwardRef<HTMLDivElement, DialogPanelProps>(function DialogPanel(
  { children },
  ref,
) {
  return (
    <Panel ref={ref} className={style.drawerContainer} decorated={true} role="dialog">
      {children}
    </Panel>
  );
});

const ModalContent: React.FC<ModalContentProps> = ({
  header,
  contentClassName,
  children,
  footer,
}) => {
  return (
    <>
      {header && <div className={style.header}>{header}</div>}
      <div className={clsx(style.content, contentClassName)}>{children}</div>
      {footer && (
        <div className={style.footer}>
          <hr className="mhm mvn" />
          {footer}
        </div>
      )}
    </>
  );
};

const isTestEnv = process.env.NODE_ENV === 'test';

const FocusTrapOptions = {
  clickOutsideDeactivates: true,
  // See https://github.com/focus-trap/tabbable#help for why this is necessary.
  tabbableOptions: isTestEnv
    ? ({
        displayCheck: 'none',
      } as const)
    : undefined,
};

export type ModalBaseProps<T extends ModalContentVariant = 'Dialog'> = ModalContentProps & {
  backdropVariant?: BackdropVariant;
} & (T extends 'Dialog'
    ? {
        variant?: 'Dialog';
      } & DialogPanelProps
    : { variant: 'Drawer' });

const ModalBase = React.forwardRef<HTMLDivElement, ModalBaseProps>(function ModalBase(
  {
    variant = 'Dialog',
    size,
    fixedHeight,
    backdropVariant,
    'aria-labelledby': ariaLabelledBy,
    ...contentProps
  }: ModalBaseProps,
  ref,
) {
  useModalDisableBodyScroll(useId());

  return (
    <FloatingPortal>
      {/* zindexref allows other floating elements rendered in a portal to compute the correct z-index */}
      <Backdrop ref={ref} data-zindexref={true} variant={backdropVariant}>
        <FocusTrap focusTrapOptions={FocusTrapOptions}>
          {variant === 'Dialog' ? (
            <DialogPanel size={size} fixedHeight={fixedHeight} aria-labelledby={ariaLabelledBy}>
              <ModalContent {...contentProps} />
            </DialogPanel>
          ) : (
            <DrawerPanel>
              <ModalContent {...contentProps} />
            </DrawerPanel>
          )}
        </FocusTrap>
      </Backdrop>
    </FloatingPortal>
  );
}) as <T extends ModalContentVariant>(
  props: ModalBaseProps<T> & RefAttributes<HTMLDivElement>,
) => ReactElement;

export default ModalBase;
