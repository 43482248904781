import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { FCC } from '../../../utils/types';
import style from './index.module.css'; // eslint-disable-line

type RenderInterstitialBaseProps = {
  size?: 's' | 'm';
};

const RenderInterstitialBase: FCC<RenderInterstitialBaseProps> = ({ size = 'm', children }) => {
  return <div className={clsx(style.container, style[size])}>{children}</div>;
};

type RenderInterstitialProps = {
  lhs?: ReactNode;
  rhs?: ReactNode;
} & RenderInterstitialBaseProps;

const RenderInterstitial: React.FC<RenderInterstitialProps> = ({ lhs, rhs, ...rest }) => {
  return (
    <RenderInterstitialBase {...rest}>
      <div className={style.lhs}>{lhs}</div>
      <div className={style.rhs}>{rhs}</div>
    </RenderInterstitialBase>
  );
};

export default RenderInterstitial;
