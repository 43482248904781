import React, { ReactNode } from 'react';
import { maybe } from '@tellurian/ts-utils';
import clsx from 'clsx';
import Card from '../../components/Card';
import { UserItemDetails } from '../../components/EntityList/entityItems/UserItem';
import { ActionMenuItemSpec } from '../../components/Menu/lib';
import { capitalizeFirst } from '../../../../utils/capitalizeFirst';
import Avatar from '../../components/Avatar';
import ThreeDotMenu from '../../components/ThreeDotMenu';
import Subtitle from '../../components/EntityList/entityItems/lib/Subtitle';
import Flex from '../../components/Flex';
import { Spinner, SpinnerSize } from '../../../ui';
import style from './GenericUserItem.module.css';

type GenericUserItemProps = UserItemDetails & {
  labels?: ReactNode;
  actions?: ActionMenuItemSpec[];
  loading?: boolean;
};

const GenericUserItem: React.FC<GenericUserItemProps> = ({
  actions = [],
  displayName,
  email,
  avatarUrl,
  labels,
  loading = false,
}) => {
  return (
    <Card className={clsx(style.card, { [style.readOnly]: !actions.length })}>
      <div className={style.lhs}>
        <div>
          <Avatar
            size="L"
            name={displayName || capitalizeFirst(email)}
            photoUrl={maybe(avatarUrl)}
          />
        </div>
        <div>
          <div className={style.header}>
            {displayName && <div className={style.displayName}>{displayName}</div>}
            <Flex size="S">{labels}</Flex>
          </div>
          <Subtitle className={style.email}>{email}</Subtitle>
        </div>
      </div>
      {(actions.length > 0 || loading) && (
        <div className={style.rhs}>
          {loading ? (
            <Spinner size={SpinnerSize.SMALL} />
          ) : (
            <ThreeDotMenu items={actions} variant="neutral" />
          )}
        </div>
      )}
    </Card>
  );
};

export default GenericUserItem;
