import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { noop } from 'lodash';
import { FCC } from '../../utils/types';
import { equalsKeyCombo, eventMatchesCombo, KeyboardShortcut } from './lib';
import KeyboardShortcutHelp from './help/KeyboardShortcutHelp';

const PreventKeyboardShortcutsOnElementsWithTagName = new Set(['INPUT', 'TEXTAREA']);
const isShortcutPrevented = () => {
  const tagName = document.activeElement?.tagName;
  return !!(tagName && PreventKeyboardShortcutsOnElementsWithTagName.has(tagName));
};

export type KeyboardShortcutContextInterface = {
  registerKeyboardShortcut: (shortcut: KeyboardShortcut) => () => void;
  setKeyboardShortcutHelpVisible: Dispatch<SetStateAction<boolean>>;
};

export const KeyboardShortcutContext = React.createContext<KeyboardShortcutContextInterface>({
  registerKeyboardShortcut: () => noop,
  setKeyboardShortcutHelpVisible: noop,
});

export const KeyboardShortcutContextProvider: FCC = ({ children }) => {
  const shortcutRegisterRef = useRef<KeyboardShortcut[]>([]);

  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (!isShortcutPrevented()) {
        const shortcut = shortcutRegisterRef.current.find(({ keyCombo }) =>
          eventMatchesCombo(event, keyCombo),
        );
        if (shortcut) {
          event.preventDefault();
          shortcut.fn();
        }
      }
    };

    window.addEventListener('keydown', handler);
    return () => window.removeEventListener('keydown', handler);
  });

  const registerKeyboardShortcut = useCallback((shortcut: KeyboardShortcut) => {
    const shortcuts = shortcutRegisterRef.current;
    if (!shortcut.override) {
      const conflicts = shortcuts.filter(s => equalsKeyCombo(s.keyCombo, shortcut.keyCombo));
      if (conflicts.length) {
        console.warn(
          `Keyboard shortcut "${shortcut.description}" conflicts with ${conflicts
            .map(c => `"${c.description}"`)
            .join(
              ', ',
            )}. If this is intended to override the latter, then please use the "override: true" flag on the shortcut object. See the KeyboardShortcut type for details.`,
        );
      }
    }
    shortcuts.unshift(shortcut);
    return () => {
      shortcutRegisterRef.current = shortcutRegisterRef.current.filter(s => s !== shortcut);
    };
  }, []);

  const [isHelpVisible, setHelpVisible] = useState(false);

  return (
    <KeyboardShortcutContext.Provider
      value={{ setKeyboardShortcutHelpVisible: setHelpVisible, registerKeyboardShortcut }}
    >
      <KeyboardShortcutHelp
        shortcuts={shortcutRegisterRef.current}
        isVisible={isHelpVisible}
        setIsVisible={setHelpVisible}
      />
      {children}
    </KeyboardShortcutContext.Provider>
  );
};

export const useKeyboardShortcutContext = () => useContext(KeyboardShortcutContext);
