import React from 'react';
import { FCC } from '../../utils/types';

type Props = { children?: string; subject?: string; body?: string };

const ContactUs: FCC<Props> = ({ children, subject, body }) => (
  <a
    href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}${subject || body ? '?' : ''}${
      subject ? `subject=${subject}` : ''
    }${body ? `&body=${body}` : ''}`}
  >
    {children || 'contact us'}
  </a>
);

export default ContactUs;
