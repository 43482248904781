import React from 'react';
import { lazyWithRetry } from '../../../lib';
import PartnerTemplate from '../templates/PartnerTemplate';
import FullWidthLayout from '../../components/layout/FullWidthLayout';
import { PartnerIdMatchParams } from './lib';

const RenderAnalytics = lazyWithRetry(
  () => import(/* webpackChunkName: "partnerAnalytics" */ '../Analytics'),
);

const AnalyticsPage: React.FC<PartnerIdMatchParams> = ({
  match: {
    params: { partnerAccountId },
  },
}) => {
  return (
    <PartnerTemplate Layout={FullWidthLayout}>
      <RenderAnalytics partnerAccountId={partnerAccountId} />
    </PartnerTemplate>
  );
};

export default AnalyticsPage;
