import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import GlobalAdminTemplate from '../../crisp/templates/GlobalAdminTemplate';
import { UnfiInsightsUserAdminTab } from '../../crisp/routing/Tabs';
import { lazyWithRetry } from '../../../lib';
import UnfiInsightsUserAdmin from '../admin/users/UnfiInsightsUserAdmin';

type MatchParams = {
  tab?: UnfiInsightsUserAdminTab;
};
const RenderUnfiInsightsUserAdmin = lazyWithRetry<typeof UnfiInsightsUserAdmin>(
  () =>
    import(/* webpackChunkName: "unfiInsightsUserAdmin" */ '../admin/users/UnfiInsightsUserAdmin'),
);
export default function unfiInsightsUserAdminPage({
  match: {
    params: { tab },
  },
}: RouteComponentProps<MatchParams>) {
  return (
    <GlobalAdminTemplate>
      <RenderUnfiInsightsUserAdmin tabId={tab} />
    </GlobalAdminTemplate>
  );
}
