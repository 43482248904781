import React, { useCallback, useMemo } from 'react';
import { useGetDashboardGroups } from '../../../../../businessDashboard/hooks/useGetDashboardGroups';
import { useFavoriteReportsContext } from '../../../../components/FavoriteReportsContext';
import { path } from '../../../routing/lib';
import View from './View';

type AllReportsProps = {
  accountId: string;
};

const AllReports: React.FC<AllReportsProps> = ({ accountId }) => {
  const { dashboardGroups, loading } = useGetDashboardGroups(accountId);
  const { getFavoriteReports } = useFavoriteReportsContext();
  const favoriteReports = useMemo(() => getFavoriteReports(), [getFavoriteReports]);
  const getPath = useCallback(
    (dashboardGroupId: string, dashboardId: string, bookmarkId?: string) =>
      path('BusinessDashboardGroup')({
        accountId,
        dashboardGroupId,
        dashboardId,
        bookmarkId,
      }),
    [accountId],
  );

  return (
    <View
      loading={loading}
      accountId={accountId}
      dashboardGroups={dashboardGroups}
      getPath={getPath}
      favoriteReports={favoriteReports}
    />
  );
};

export default AllReports;
