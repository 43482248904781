import { useEffect } from 'react';
import useEventCallback from './useEventCallback';

const useGlobalKeyListener = (callback: (e: KeyboardEvent) => void) => {
  const onKeyDown = useEventCallback(callback);
  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, [onKeyDown]);
};

export const useGlobalOnKeyDown = (key: string, fn: (e: KeyboardEvent) => void) => {
  return useGlobalKeyListener(e => e.key === key && fn(e));
};

export default useGlobalKeyListener;
