import React from 'react';
import { FnsFormat, useI18nContext } from '../../../../../utils/I18nContext';
import Text from '../../../../ui/Text';
import { Button, ButtonStyle } from '../../../../ui';
import { PolicyVersionFragment, TermsOfServicePolicyType } from '../../../../../generated/graphql';
import { PolicyTypeDisplayNames } from '../../../../TermsOfService/lib';
import TermsOfServiceContent from '../../../../TermsOfService/TermsOfServiceContent';
import FullScreenModal from '../../../../ui/Modal/FullScreenModal';
import style from './TermsOfServiceVersionViewer.module.css';

export const ModalFooter: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <div className={style.footer}>
      <Button btnStyle={ButtonStyle.PRIMARY} onClick={onClose}>
        Close
      </Button>
    </div>
  );
};

const VersionDetails: React.FC<PolicyVersionFragment> = ({
  version,
  publishedAtTimestamp,
  publishedByUser,
}) => {
  const { dateFormat } = useI18nContext();
  return (
    <div className={style.versionDetails}>
      <h3>Version {version}</h3>
      <div className="mhm">
        <Text subtle>Published on</Text>{' '}
        <Text semibold>{dateFormat(FnsFormat.DateYearShort)(new Date(publishedAtTimestamp))}</Text>{' '}
        <Text subtle>by</Text>{' '}
        {publishedByUser ? (
          <Text semibold>{publishedByUser.displayName ?? publishedByUser.email}</Text>
        ) : (
          '(User no longer exists)'
        )}
      </div>
    </div>
  );
};

export type TermsOfServiceVersionViewerProps = {
  onClose: () => void;
  policyType: TermsOfServicePolicyType;
  version: PolicyVersionFragment;
};

const TermsOfServiceVersionViewer: React.FC<TermsOfServiceVersionViewerProps> = ({
  onClose,
  policyType,
  version,
}) => {
  return (
    <FullScreenModal
      onCloseClick={onClose}
      header={
        <>
          <h1>Terms of service - {PolicyTypeDisplayNames[policyType]}</h1>
          <VersionDetails {...version} />
        </>
      }
      footer={<ModalFooter onClose={onClose} />}
    >
      <div className={style.container}>
        <TermsOfServiceContent
          policyType={policyType}
          version={version.version}
          className={style.content}
        />
      </div>
    </FullScreenModal>
  );
};

export default TermsOfServiceVersionViewer;
