import React from 'react';
import { ReactComponent as IconClose } from '../../../../assets/icons/lettuce/clear.svg';
import { useOnKeyDown } from '../../../../utils/hooks';
import style from './CloseButton.module.css';

type CloseButtonProps = {
  onClick: () => void;
};

const CloseButton: React.FC<CloseButtonProps> = ({ onClick }) => {
  useOnKeyDown('Escape', onClick);
  return (
    <button className={style.button} onClick={onClick} type="button" aria-label="Close">
      <IconClose />
    </button>
  );
};

export default CloseButton;
