import { ReactNode } from 'react';
import { InfoType } from '../ui/Infobar/lib';
import {
  ConnectionError,
  TokenExpired,
  TokenHasInsufficientPrivileges,
  VerificationError,
} from '../connectors/configurations/Edit/PageMessages';

export type PageMessage = {
  icon?: ReactNode;
  id: MessageId;
  infoType?: InfoType;
  // eslint-disable-next-line
  Render: (props?: any) => ReactNode;
};

/**
 * Use strings as values for this enum to mitigate the risk of incorrect assignment due to future
 * insertions (e.g. An insertion would offset all subsequent values by 1 if integers are used - this
 * will leave the message records out of sync).
 */
export enum MessageId {
  ConnectorFailedVerificationStep = 'connectorFailedVerificationStep',
  AccessTokenTokenExpired = 'accessTokenExpired',
  TokenHasInsufficientPrivileges = 'tokenHasInsufficientPrivileges',
  ConnectionError = 'connectionError',
}

export const Messages: Readonly<Record<MessageId, PageMessage>> = Object.freeze({
  [MessageId.ConnectorFailedVerificationStep]: {
    id: MessageId.ConnectorFailedVerificationStep,
    infoType: InfoType.Warning,
    Render: VerificationError,
  },
  [MessageId.AccessTokenTokenExpired]: {
    id: MessageId.AccessTokenTokenExpired,
    infoType: InfoType.Warning,
    Render: TokenExpired,
  },
  [MessageId.TokenHasInsufficientPrivileges]: {
    id: MessageId.TokenHasInsufficientPrivileges,
    infoType: InfoType.Warning,
    Render: TokenHasInsufficientPrivileges,
  },
  [MessageId.ConnectionError]: {
    id: MessageId.ConnectionError,
    infoType: InfoType.Warning,
    Render: ConnectionError,
  },
});

/**
 * Utility function which retrieves PageMessage entities by id. The purpose of this is to
 * avoid having to refer to the Messages object directly, outside the parent directory.
 * @param messageId
 */
export const getMessage = (messageId: MessageId): PageMessage => Messages[messageId];
