import React from 'react';
import clsx from 'clsx';
import Button, { ButtonProps, ButtonVariant } from '../Button';
import ActionButton from '../Button/ActionButton';
import style from './index.module.css';

type ActionButtonType = 'primary' | 'secondary' | 'tertiary';

type ActionButtonsProps = {
  onPrimary: () => void;
  onSecondary?: () => void;
  onTertiary?: () => void;
  primaryLabel?: string;
  secondaryLabel?: string;
  tertiaryLabel?: string;
  // Set to true to disable all action buttons
  // Set to 'primary' | 'secondary' | 'tertiary' to only disable the respective button
  // Use the object representation for specific configurations (e.g. { secondary: true, tertiary: true })
  disabled?: boolean | ActionButtonType | Partial<Record<ActionButtonType, boolean>>;
} & Pick<ButtonProps, 'featureId'>;

const getDisabledStatus = (
  actionButton: 'primary' | 'secondary' | 'tertiary',
  disabled: ActionButtonsProps['disabled'] = false,
): boolean => disabled === true || disabled === actionButton || disabled[actionButton];

const ActionButtons: React.FC<ActionButtonsProps> = ({
  onPrimary,
  onSecondary,
  primaryLabel = 'Save',
  secondaryLabel = 'Cancel',
  tertiaryLabel = 'Delete',
  onTertiary,
  featureId,
  disabled = false,
}) => {
  const hasTertiary = !!onTertiary;
  const rhs = (
    <>
      {onSecondary && (
        <Button
          variant={ButtonVariant.Secondary}
          onClick={onSecondary}
          disabled={getDisabledStatus('secondary', disabled)}
        >
          {secondaryLabel}
        </Button>
      )}
      <ActionButton
        onClick={onPrimary}
        featureId={featureId}
        disabled={getDisabledStatus('primary', disabled)}
      >
        {primaryLabel}
      </ActionButton>
    </>
  );
  return (
    <div className={clsx(style.container, { [style.hasTertiary]: hasTertiary })}>
      {hasTertiary ? (
        <>
          <div>
            <Button
              variant={ButtonVariant.Text}
              onClick={onTertiary}
              disabled={getDisabledStatus('tertiary', disabled)}
            >
              {tertiaryLabel}
            </Button>
          </div>
          <div>{rhs}</div>
        </>
      ) : (
        rhs
      )}
    </div>
  );
};

export default ActionButtons;
