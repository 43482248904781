import React, { useEffect, useState } from 'react';
import { Transition } from 'react-transition-group';
import clsx from 'clsx';
import { Button, ButtonSize, ButtonStyle } from '../Button';
import { Grid } from '../';
import { InfoType } from './lib';
import styles from './index.module.css'; // eslint-disable-line

type InfobarActionButtonProps = {
  action: string;
  onClick: () => void;
};

export type InfobarProps = {
  children: React.ReactNode;
  className?: string;
  onDismissed?: () => void;
  type?: InfoType;
  actionButton?: InfobarActionButtonProps;
};

const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0, display: 'none' },
};

export function Infobar({
  children,
  className,
  type = InfoType.Neutral,
  onDismissed,
  actionButton,
}: InfobarProps) {
  const [visible, setVisible] = useState(false);
  const nodeRef = React.useRef(null);

  useEffect(() => {
    setVisible(true);
  }, []);

  return (
    <Transition in={visible} timeout={duration} onExited={onDismissed} nodeRef={nodeRef}>
      {state => (
        <div
          ref={nodeRef}
          className={clsx(styles.infobar, styles[type], className)}
          style={{ ...defaultStyle, ...transitionStyles[state] }}
        >
          <Grid className={styles.grid}>
            <div className={styles.children} aria-live="polite">
              {children}
            </div>
            {actionButton && (
              <div className={styles.actionCol}>
                <Button
                  btnStyle={ButtonStyle.LINK}
                  btnSize={ButtonSize.S}
                  onClick={actionButton.onClick}
                >
                  {actionButton.action}
                </Button>
              </div>
            )}

            {onDismissed && (
              <div className={styles.dismissCol}>
                <Button
                  btnStyle={ButtonStyle.LINK}
                  btnSize={ButtonSize.S}
                  onClick={() => setVisible(false)}
                >
                  Dismiss
                </Button>
              </div>
            )}
          </Grid>
        </div>
      )}
    </Transition>
  );
}
