import {
  CSSProperties,
  Dispatch,
  MutableRefObject,
  Ref,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  arrow,
  autoUpdate,
  Boundary,
  flip,
  hide,
  limitShift,
  MiddlewareData,
  MiddlewareState,
  offset,
  shift,
  size,
  useFloating,
  UseFloatingReturn,
} from '@floating-ui/react-dom';
import { Placement } from '@floating-ui/core';
import { Maybe } from '@tellurian/ts-utils';
import { useDefaultHashId } from '../../../lib';
import useOnClickAway from '../../utils/useOnClickAway';
import composeRefs from '../../utils/composeRefs';
import { UseDropdownParams } from './useDropdown';

const isTestEnv = process.env.NODE_ENV === 'test';

type SizeValue = number | string;

type GetSizePropsReturn = {
  width?: SizeValue;
  height?: SizeValue;
  minWidth?: SizeValue;
  maxWidth?: SizeValue;
  minHeight?: SizeValue;
  maxHeight?: SizeValue;
};

export type GetSizeProps = (
  args: MiddlewareState & {
    availableWidth: number;
    availableHeight: number;
  },
) => GetSizePropsReturn;

export type GetReferencePropsReturn<T> = {
  ref: Ref<T>;
  'aria-haspopup': UseDropdownParams['role'];
  'aria-expanded': boolean;
  'aria-controls': string;
};

const getZIndex = (referenceElement: Element): Maybe<number> => {
  const zIndexAncestorReference = referenceElement.closest(
    ':is([data-floating], [data-zindexref])',
  );
  if (zIndexAncestorReference) {
    const ancestorZIndex = window.getComputedStyle(zIndexAncestorReference).zIndex;
    if (ancestorZIndex) {
      return parseInt(ancestorZIndex) + 1 || undefined;
    }
  }

  return undefined;
};

type GetDropdownFloatingOptionsParams = {
  getSizeProps: GetSizeProps;
  boundary?: Boundary;
  arrowRef?: MutableRefObject<HTMLElement | null>;
};

const getDropdownFloatingOptions = ({
  getSizeProps,
  boundary,
  arrowRef,
}: GetDropdownFloatingOptionsParams) =>
  Object.freeze({
    placement: 'bottom-start',
    strategy: 'absolute',
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      size({
        boundary,
        apply: params => {
          const sizeProps = Object.fromEntries(
            Object.entries(getSizeProps(params)).map(([key, value]) => [
              key,
              typeof value === 'number' ? `${value}px` : value,
            ]),
          );
          Object.assign(params.elements.floating.style, sizeProps);
        },
      }),
      flip({ mainAxis: true, boundary }),
      shift({
        boundary,
        crossAxis: true,
        padding: 5,
        limiter: limitShift({
          mainAxis: true,
        }),
      }),
      // Disable in test environment as it is not handled correctly with jest-dom
      !isTestEnv && hide(),
      arrowRef && arrow({ element: arrowRef }),
    ].filter(Boolean),
  });

export const getVisibility = (
  middlewareData: Pick<MiddlewareData, 'hide'>,
): CSSProperties['visibility'] => {
  return (
    middlewareData.hide &&
    ((middlewareData.hide.referenceHidden ? 'hidden' : 'visible') as CSSProperties['visibility'])
  );
};

export type UseTetherParams = {
  getPopoverSizeProps: GetSizeProps;
  isInitiallyActive?: boolean;
  popoverId?: string;
  role?: 'dialog' | 'listbox' | 'menu';
  dismissOnClickAway?: boolean;
  overflowBoundary?: Boundary;
  placement?: Placement;
} & Pick<GetDropdownFloatingOptionsParams, 'arrowRef'>;

type StyleOverrides = { zIndex: CSSProperties['zIndex'] };

export type UseTethered<T extends HTMLElement = HTMLElement> = {
  isActive: boolean;
  setIsActive: Dispatch<SetStateAction<boolean>>;
  getReferenceProps: () => GetReferencePropsReturn<T>;
  getPopoverProps: (overrides?: StyleOverrides) => {
    role: UseTetherParams['role'];
    ref: Ref<HTMLElement>;
    style: CSSProperties;
  };
  floatingContext: Pick<UseFloatingReturn, 'placement' | 'update' | 'middlewareData'>;
};

const useTether = <T extends HTMLElement = HTMLButtonElement>({
  isInitiallyActive = false,
  getPopoverSizeProps,
  popoverId,
  role = 'dialog',
  dismissOnClickAway = false,
  overflowBoundary,
  placement,
  arrowRef,
}: UseTetherParams): UseTethered<T> => {
  const id = useDefaultHashId(popoverId);
  const [isActive, setIsActive] = useState(isInitiallyActive);
  const { x, y, strategy, refs, ...floatingContext } = useFloating(
    useMemo(
      () => ({
        ...getDropdownFloatingOptions({
          getSizeProps: getPopoverSizeProps,
          boundary: overflowBoundary,
          arrowRef,
        }),
        placement,
      }),
      [getPopoverSizeProps, overflowBoundary, placement, arrowRef],
    ),
  );

  const onClickAwayRef = useOnClickAway({
    onClickAway: () => setIsActive(false),
    enabled: dismissOnClickAway && isActive,
  });

  const getReferenceProps = useCallback(
    () => ({
      ref: refs.setReference,
      'aria-haspopup': role,
      'aria-expanded': isActive,
      'aria-controls': id,
    }),
    [refs.setReference, isActive, id, role],
  );

  const referenceElement = refs.reference.current;
  const zIndex = useMemo(
    () =>
      (isActive &&
        referenceElement &&
        referenceElement instanceof Element &&
        getZIndex(referenceElement)) ||
      // Use a z-index of 1 to ensure the popover appears over other absolutely positioned elements in the
      // same frame of reference
      1,
    [referenceElement, isActive],
  );

  const visibility = getVisibility(floatingContext.middlewareData);
  const getPopoverProps = useCallback(
    (styleOverrides?: StyleOverrides) => ({
      role,
      ref: composeRefs(refs.setFloating, onClickAwayRef),
      style: {
        top: y ?? 0,
        left: x ?? 0,
        position: strategy,
        width: 'max-content',
        visibility: visibility,
        zIndex,
        ...styleOverrides,
      },
      id,
    }),
    [x, y, strategy, refs.setFloating, onClickAwayRef, id, role, zIndex, visibility],
  );

  return { isActive, setIsActive, getReferenceProps, getPopoverProps, floatingContext };
};

export default useTether;
