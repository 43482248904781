import React, { useState } from 'react';
import clsx from 'clsx';
import { ChipOption } from '../../../components/Chip';
import ListOfChips from '../../../components/Chip/ListOfChips';
import usePersistentState from '../../../../../utils/memoryStorage/usePersistentState';
import { MemoryStorageKey } from '../../../../../utils/memoryStorage';
import { Feature, trackFeature } from '../../../../../utils/features';
import { useGetDashboardGroups } from '../../../../businessDashboard/hooks/useGetDashboardGroups';
import { useGetConnectorName } from '../../dashboards/lib';
import { path } from '../../routing/lib';
import AllReports from './AllReports';
import SavedReports from './SavedReports';
import StandardViews from './StandardViews';
import SearchReports from './SearchReports';
import SearchBar from './SearchBar';
import style from './index.module.css';

export enum AnalyticsView {
  AllReports = 'All Reports',
  StandardViews = 'Standard Views',
  SavedReports = 'Saved Reports',
  Search = 'Search',
}

export type AnalyticsMenuProps = {
  accountId: string;
};

const AnalyticsMenu: React.FC<AnalyticsMenuProps> = props => {
  const { accountId } = props;

  const [currentView, setCurrentView] = usePersistentState(
    MemoryStorageKey.AnalyticsMenuCurrentView,
    AnalyticsView.AllReports,
  );
  const [previousView, setPreviousView] = useState(AnalyticsView.AllReports);
  const [searchQuery, setSearchQuery] = usePersistentState(
    MemoryStorageKey.AnalyticsMenuSearchQuery,
    '',
  );

  const { loading: dashboardGroupsLoading, dashboardGroups } = useGetDashboardGroups(
    props.accountId,
  );
  const getConnectorName = useGetConnectorName(accountId);
  const getPath = (dashboardGroupId: string, dashboardId: string) =>
    path('BusinessDashboardGroup')({ accountId, dashboardGroupId, dashboardId });

  const standardViewsProps = { dashboardGroups, dashboardGroupsLoading, getConnectorName, getPath };

  const isSearchActive = currentView === AnalyticsView.Search;

  const onClickTab = (view: AnalyticsView) => {
    if (!isSearchActive) {
      trackFeature(Feature.AnalyticsMenuViewChanged, { view });
      setCurrentView(view);
      setSearchQuery('');
    }
  };

  return (
    <div className={style.analyticsMenu}>
      <div className={style.header}>
        <h1>Analytics</h1>
        <ListOfChips
          className={clsx(style.analyticsTabs, {
            [style.searchActive]: isSearchActive,
          })}
        >
          {Object.values(AnalyticsView)
            .filter(view => view !== AnalyticsView.Search)
            .map(view => (
              <ChipOption
                key={`view-button-${view}`}
                onChange={() => onClickTab(view)}
                isSelected={view === currentView}
                text={view}
              />
            ))}
        </ListOfChips>
        <div className={style.spacer} />
      </div>
      <SearchBar
        key="search"
        value={searchQuery}
        onChange={value => setSearchQuery(value)}
        isActive={isSearchActive}
        setActive={() => {
          setPreviousView(currentView);
          setCurrentView(AnalyticsView.Search);
        }}
        setInactive={() => {
          setCurrentView(previousView);
          setPreviousView(AnalyticsView.Search);
        }}
      />

      <hr className="mvn" />

      <div className={style.view}>
        {currentView === AnalyticsView.AllReports ? (
          <AllReports {...props} />
        ) : currentView === AnalyticsView.StandardViews ? (
          <StandardViews {...standardViewsProps} />
        ) : currentView === AnalyticsView.SavedReports ? (
          <SavedReports {...props} />
        ) : currentView === AnalyticsView.Search ? (
          <SearchReports {...props} {...standardViewsProps} searchQuery={searchQuery.trim()} />
        ) : null}
      </div>
    </div>
  );
};

export default AnalyticsMenu;
