import React, { useEffect } from 'react';
import { useTouchAccountMutation } from '../../generated/graphql';
import { useAuthorizationContext } from '../security/AuthorizationContext';
import { trackError } from '../../utils/errorTracking';
import { LocalStorage, LocalStorageKey } from '../../utils/localStorage';

const hour = 3600000;
// do not send successive calls to touch account if within 3 hours from last call
export const TOUCH_ACCOUNT_HIATUS = 3 * hour;

type AccountTouchFns = {
  updateLastTouched: (accountId: string) => void;
  shouldTouchAccount: (accountId: string) => boolean;
  updateTouchInProgress: (accountId: string) => void;
  reloadRecords: () => void;
};

export type LastTouchedRecords = Record<string, number>;

const persistenceFns = (): [() => LastTouchedRecords, (records: LastTouchedRecords) => void] => [
  () => LocalStorage.getItemOrDefault(LocalStorageKey.LastTouchedAccount, {}),
  (records: LastTouchedRecords) => {
    LocalStorage.setItem(LocalStorageKey.LastTouchedAccount, records);
  },
];

const isValidAccountId = (accountId: string): boolean => Boolean(accountId.match(/^\d+$/));

const {
  shouldTouchAccount,
  updateLastTouched,
  updateTouchInProgress,
  reloadRecords,
}: AccountTouchFns = (() => {
  const [getRecords, saveRecords] = persistenceFns();
  let records = getRecords();
  const inProgressRecords = {};

  return {
    updateTouchInProgress: (accountId: string) => {
      inProgressRecords[accountId] = true;
    },
    updateLastTouched: (accountId: string) => {
      records[accountId] = +new Date();
      inProgressRecords[accountId] = false;
      saveRecords(records);
    },
    shouldTouchAccount: (accountId: string) => {
      if (!isValidAccountId(accountId) || inProgressRecords[accountId]) {
        return false;
      }

      const lastTouchedAccount = records[accountId];
      if (lastTouchedAccount) {
        return +new Date() - lastTouchedAccount > TOUCH_ACCOUNT_HIATUS;
      }

      return true;
    },
    reloadRecords: () => {
      records = getRecords();
    },
  };
})();

// For testing purposes only
if (process.env.NODE_ENV === 'test') {
  window['crispApp.test.reloadTouchAccountRecords'] = reloadRecords;
}

const EnableAccountTouchLog: React.FC = () => {
  const { getHasGlobalAdminPermission } = useAuthorizationContext();
  const [touchAccount] = useTouchAccountMutation();

  useEffect(() => {
    if (getHasGlobalAdminPermission()) {
      return undefined;
    }

    window['crispApp.touchAccount'] = async (accountId: string) => {
      if (accountId && shouldTouchAccount(accountId)) {
        try {
          updateTouchInProgress(accountId);
          await touchAccount({ variables: { accountId } });
          updateLastTouched(accountId);
        } catch (err) {
          trackError(err as Error, { message: 'Cannot touch account', accountId });
        }
      }
    };

    return () => {
      delete window['crispApp.touchAccount'];
    };
  }, [touchAccount, getHasGlobalAdminPermission]);

  return null;
};

export default EnableAccountTouchLog;
