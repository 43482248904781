import React, { ComponentType, lazy, SVGProps, useMemo } from 'react';
import { isBefore, sub } from 'date-fns';
import pluralize from 'pluralize';
import { AnyObject, Maybe } from '@tellurian/ts-utils';
import { startCase } from 'lodash';
import { ConnectorApplication } from '../generated/graphql';
import { getHash } from './ui/form/lib';
import { LocalStorage, LocalStorageKey } from '../utils/localStorage';

export const flatMap = <T, P>(a: Maybe<T>, b: (c: T) => Maybe<P>): Maybe<P> =>
  a === undefined ? undefined : b(a);
export type IconComponent = React.FunctionComponent<SVGProps<SVGSVGElement>>;
// Usage: type B = Optional<A, 'prop1' | 'prop2'>
export type Optional<T extends AnyObject, Q extends keyof T> = Omit<T, Q> & {
  [key in Q]+?: T[key];
};

export const isNumber = x => Number.isFinite(x);
export const isFunction = x => typeof x === 'function';

export const extendPluralizeRules = () => {
  // Do not allow pluralisation of 'kg' to 'kgs'
  pluralize.addUncountableRule(/[Kk]g/);
  pluralize.addUncountableRule('g');
};

export const inProgressLabel = (baseLabel: string): string => `${baseLabel}...`;

export const ellipsise = (src: string, maxLength = 30) => {
  if (src.length <= maxLength) {
    return src;
  }

  return `${src.slice(0, maxLength - 4)} ...`;
};

// Brand Colors
export const steakRed = '#CB333B';
export const bananaYellow = '#FFCD00';
export const citrusOrange = '#FFA400';
export const waterBlue = '#71C5E8';
export const tangerineOrange = '#D86D21';
export const ubePurple = '#8B84D7';
export const crispCypress = '#003A3F';
export const crispKale = '#005257';
export const crispTeal = '#007672';
export const crispGreen = '#00807f';
export const crispLeaf = '#00BB7E';
export const plum = '#7C2855';
export const sorbet = '#CAA2DD';
export const rare = '#DC8699';
export const salmon = '#E8927C';
export const spearmint = '#40C1AC';
export const apple = '#78BE21';
export const water = '#71C5E8';

const generateUniqueId = (
  (startAt: number) => (): string =>
    String(+new Date()) + startAt++
)(0);

export const useDefaultHashId = (specificId?: string) => {
  return useMemo(() => specificId || getHash(generateUniqueId()) + '', [specificId]);
};

export const emailAddressesAreEqual = (email1, email2) =>
  !email1.localeCompare(email2, undefined, { sensitivity: 'accent' });

export const getPaginationDescription = (currentPage: number, pageCount: number): string =>
  (pageCount && `${currentPage + 1} of ${pageCount} page${pageCount > 1 ? 's' : ''}`) || '';

export const getLatestCoreUIVersion = async (): Promise<Maybe<string>> => {
  try {
    // Presently the version is identified by the commit however, outside this
    // function, no such assumption should be made.
    const { commit } = await fetch('/version.json').then(response => response.json());
    return commit;
  } catch (err) {
    return undefined;
  }
};

const coreUIVersionEquals = (version: string): boolean => process.env.REACT_APP_COMMIT === version;

/**
 * Attempt to reload the current location on the basis of core-ui version mismatch.
 * @param strictCheck If true, it will only attempt to reload if the latest version can be retrieved. If false,
 * a "reload" may proceed even if the latest version cannot be resolved.
 */
export const maybeReloadPage = async (strictCheck = false): Promise<boolean> => {
  const latestCoreUIVersion = await getLatestCoreUIVersion();
  const lastForceRefresh = new Date(
    LocalStorage.getItemOrDefault(LocalStorageKey.LastForceRefresh, 0),
  );
  const now = Date.now();
  // Use this as a mechanism to prevent a continuous reload cycle in the edge case where
  // strictCheck === false and latestCoreUIVersion cannot be retrieved for some reason.
  const allowRefresh = isBefore(lastForceRefresh, sub(now, { seconds: 10 }));
  if (allowRefresh) {
    const shouldRefresh = strictCheck
      ? latestCoreUIVersion && !coreUIVersionEquals(latestCoreUIVersion)
      : !(latestCoreUIVersion && coreUIVersionEquals(latestCoreUIVersion));
    if (shouldRefresh) {
      LocalStorage.setItem(LocalStorageKey.LastForceRefresh, now);
      window.location.reload();
      return true;
    }
  }

  return false;
};

/**
 * Lazy-load an import, auto-refreshing the page if the chunk fails to load and the user
 * is not running the latest version of core-ui.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const lazyWithRetry = <T extends ComponentType<any> = ComponentType<any>>(componentImport) =>
  lazy<T>(async () => {
    try {
      return await componentImport();
    } catch (error) {
      if (!(await maybeReloadPage())) {
        // The user is already on the latest version of the application.
        // Let the application crash and raise the error.
        throw error;
      }
    }
  });

const ConnectorApplicationFriendlyNames = {
  [ConnectorApplication.Unfi]: 'UNFI',
  [ConnectorApplication.Kehe]: 'KeHE',
};

export const snakeCaseToTitleCase = (str: string): string => startCase(str.toLowerCase());

export const getConnectorApplicationFriendlyName = (
  connectorApplication: ConnectorApplication,
): string =>
  ConnectorApplicationFriendlyNames.hasOwnProperty(connectorApplication)
    ? ConnectorApplicationFriendlyNames[connectorApplication]
    : snakeCaseToTitleCase(connectorApplication);

export const isAncestorOfOrEquals = (srcElement: Element | null, ancestor: Element): boolean => {
  do {
    if (srcElement === ancestor) {
      return true;
    }
    srcElement = srcElement?.parentElement || null;
  } while (srcElement);

  return false;
};

export const isUnfiInsightsDashboard = (dashboard?: { __typename?: string }) => {
  return dashboard?.__typename === 'UnfiInsightsDashboard';
};

export const getPendo = async (retries = 3) => {
  const pendo = window['pendo'];
  if (pendo && Object.keys(pendo).length) {
    return pendo;
  }

  if (retries === 0) {
    return Promise.reject();
  }

  return new Promise(resolve =>
    setTimeout(() => resolve(getPendo(retries - 1)), 2000 / (retries * retries)),
  );
};

export const isProduction = () => process.env.NODE_ENV === 'production';
