import React, { useCallback, useEffect } from 'react';
import FocusTrap from 'focus-trap-react';
import clsx from 'clsx';
import { useDefaultHashId } from '../../lib';
import { Backdrop } from '../Backdrop';
import { FCC } from '../../../utils/types';
import { useThemeContext } from '../../lettuce/components/Theme/ThemeProvider';
import Panel from '../../lettuce/components/Panel';
import { disableBodyScroll, enableBodyScroll, ModalSize } from './lib';
import styles from './index.module.css'; // eslint-disable-line

type ContentWrapperProps = {
  style?: React.CSSProperties;
  children: React.ReactNode;
  className?: string;
  ariaLabelledby?: string;
  ariaDescribedby?: string;
};

const ContentWrapper = React.forwardRef(function ContentWrapper(
  { style, className, children, ariaLabelledby, ariaDescribedby }: ContentWrapperProps,
  ref: React.Ref<HTMLDivElement>,
) {
  const { isLettuceTheme } = useThemeContext();
  const Component = isLettuceTheme ? Panel : 'div';

  return (
    <Component
      ref={ref}
      className={clsx(styles.modal, className)}
      onClick={event => event.stopPropagation()}
      role={ariaLabelledby ? 'dialog' : 'presentation'}
      aria-labelledby={ariaLabelledby}
      aria-describedby={ariaDescribedby}
      style={style}
    >
      {children}
    </Component>
  );
});

// Keep a stack of presently rendered modals such that we can prevent the scroll
// on body if at least one Modal is rendered
const renderedModalIds: string[] = [];
const isTopModal = (id: string) => renderedModalIds[0] === id;

const isTestEnv = process.env.NODE_ENV === 'test';

export type ModalBaseProps = {
  backdropClassName?: string;
  onCloseClick: () => void;
  className?: string;
  size?: ModalSize;
  ariaLabelledby?: string;
  ariaDescribedby?: string;
  style?: React.CSSProperties | undefined;
};

const ModalBase: FCC<ModalBaseProps> = ({
  children,
  onCloseClick,
  backdropClassName,
  className,
  size = ModalSize.L,
  ariaDescribedby,
  ariaLabelledby,
  style,
}) => {
  const id = useDefaultHashId();
  useEffect(() => {
    const bodyScrollTop = disableBodyScroll();
    renderedModalIds.unshift(id);

    return function cleanup() {
      const index = renderedModalIds.indexOf(id);
      if (index > -1) {
        renderedModalIds.splice(index, 1);
      }

      if (renderedModalIds.length === 0) {
        enableBodyScroll(bodyScrollTop);
      }
    };
  }, [id]);

  const onMouseDown = useCallback(() => {
    // Only dismiss modal on mouse down if the backdrop of the top modal is clicked.
    if (isTopModal(id)) {
      onCloseClick();
    }
  }, [onCloseClick, id]);

  return (
    <Backdrop
      bgColor="rgba(255, 255, 255, 0.8)"
      onMouseDown={onMouseDown}
      className={backdropClassName}
    >
      <FocusTrap
        focusTrapOptions={{
          clickOutsideDeactivates: true,
          // See https://github.com/focus-trap/tabbable#help for why this is necessary.
          tabbableOptions: isTestEnv
            ? {
                displayCheck: 'none',
              }
            : undefined,
        }}
      >
        <ContentWrapper
          className={clsx(styles[size], className)}
          style={style}
          ariaLabelledby={ariaLabelledby}
          ariaDescribedby={ariaDescribedby}
        >
          {children}
        </ContentWrapper>
      </FocusTrap>
    </Backdrop>
  );
};

export default ModalBase;
