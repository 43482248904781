import React, { FunctionComponent } from 'react';

import { Forbidden } from '../httpErrors';
import { AuthorityGrant, useAuthorizationContext } from './AuthorizationContext';

export type RequireAccountGrantProps = {
  accountId: string;
  grant: AuthorityGrant;
  children?: React.ReactNode;
  RenderForbidden?: FunctionComponent;
};

export function RequireAccountGrant({
  accountId,
  children,
  grant,
  RenderForbidden = Forbidden,
}: RequireAccountGrantProps) {
  const { error, getHasAccountPermission, loading } = useAuthorizationContext();
  if (loading || error) return null;

  const hasPermission = getHasAccountPermission(accountId, grant);

  return hasPermission ? <>{children}</> : <RenderForbidden />;
}
