import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import {
  GetPageErrorDocument,
  PageErrorCode,
  useGetPageErrorQuery,
} from '../../../generated/graphql';
import { renderErrorForCode } from '../errorMessages';

export interface PageErrorContextInterface {
  errorMessage?: React.ReactNode;
  dismissMessage: () => void;
  setErrorMessage: (string) => void;
  listenerTypes: string[];
  registerType: (string) => void;
  unregisterType: (string) => void;
}

export const PageErrorContext = React.createContext<PageErrorContextInterface>({
  dismissMessage: () => {},
  listenerTypes: [],
  registerType: () => {},
  setErrorMessage: () => {},
  unregisterType: () => {},
});

export function PageErrorProvider({ children }: { children?: React.ReactNode }) {
  const client = useApolloClient();
  const { data } = useGetPageErrorQuery();

  const [message, setMessage] = useState<React.ReactNode | undefined>();

  // Maintain a reference count of listener types. This can be used
  // by the listeners to determine whether they actually want do show
  // errors or not. See <PageError />.
  const [listenerTypes, setListenerTypes] = useState<Map<string, number>>(new Map());

  const registerType = type => {
    listenerTypes.set(type, (listenerTypes.get(type) || 0) + 1);
    setListenerTypes(listenerTypes);
  };

  const unregisterType = type => {
    const count = (listenerTypes.get(type) || 0) - 1;
    if (count <= 0) listenerTypes.delete(type);
    else listenerTypes.set(type, count);
    setListenerTypes(listenerTypes);
  };

  const errorCode = data && data.pageErrorCode;

  const dismissMessage = () => {
    setMessage(undefined);
  };

  const setErrorMessage = useCallback(
    (code: PageErrorCode) => {
      client.writeQuery({
        query: GetPageErrorDocument,
        data: { pageErrorCode: code },
      });
    },
    [client],
  );

  useEffect(() => {
    const removePageError = () => {
      client.writeQuery({
        query: GetPageErrorDocument,
        data: { pageErrorCode: null },
      });
    };

    if (errorCode) {
      setMessage(renderErrorForCode(errorCode));
      removePageError();
    }
  }, [client, errorCode]);

  return (
    <PageErrorContext.Provider
      value={{
        errorMessage: message,
        dismissMessage: dismissMessage,
        setErrorMessage,
        listenerTypes: Array.from(listenerTypes.keys()),
        registerType,
        unregisterType,
      }}
    >
      {children}
    </PageErrorContext.Provider>
  );
}

/**
 * Get the `PageErrorContext` and register as an error listener of type `type`.
 */
export default function usePageError(type?: string): PageErrorContextInterface {
  const ctx = useContext(PageErrorContext);
  useEffect(() => {
    type && ctx.registerType(type);
    return () => {
      type && ctx.unregisterType(type);
    };
  });
  return ctx;
}
