import React from 'react';
import { ButtonProps, ButtonSize, ButtonStyle, ButtonType } from '../index';
import { ButtonVariant, default as LettuceButton } from '../../../lettuce/components/Button';
import ActionButton from '../../../lettuce/components/Button/ActionButton';

const toVariant = (btnStyle: ButtonStyle): ButtonVariant => {
  switch (btnStyle) {
    case ButtonStyle.PRIMARY:
      return ButtonVariant.Primary;
    case ButtonStyle.LINK:
    case ButtonStyle.LINK_DEFAULT_TEXT_COLOR:
      return ButtonVariant.Text;
  }

  return ButtonVariant.Secondary;
};

type LettuceButtonAdapterProps = ButtonProps;

const LettuceButtonAdapter = React.forwardRef<HTMLButtonElement, LettuceButtonAdapterProps>(
  function LettuceButtonAdapter(
    {
      btnType = ButtonType.Button,
      onClick,
      children,
      style: _,
      btnSize = ButtonSize.M,
      btnStyle,
      testId,
      ...rest
    },
    ref,
  ) {
    const size = btnSize === ButtonSize.S ? 'S' : 'M';

    if (btnType === ButtonType.Submit) {
      return (
        <ActionButton
          {...rest}
          size={size}
          variant={toVariant(btnStyle)}
          onClick={() => onClick({} as React.SyntheticEvent<HTMLButtonElement>)}
          type={'button'}
          data-testid={testId}
          ref={ref}
        >
          {children}
        </ActionButton>
      );
    }

    return (
      <LettuceButton
        {...rest}
        size={size}
        onClick={onClick}
        type={btnType === ButtonType.HtmlSubmit ? 'submit' : 'button'}
        variant={toVariant(btnStyle)}
        data-testid={testId}
        ref={ref}
      >
        {children}
      </LettuceButton>
    );
  },
);

export default LettuceButtonAdapter;
