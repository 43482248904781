import React from 'react';
import clsx from 'clsx';
import { Link, useHistory } from 'react-router-dom';
import { RenderItemProps } from '../../../../ui/lists/listOfItems/ListOfItems';
import ListOfLabels, { LabelWithTitle } from '../../../components/Label/ListOfLabels';
import { usePopoverContext } from '../../../components/NavRail/PopoverActionContext';
import ThreeDotMenu from '../../../components/ThreeDotMenu';
import { ActionMenuItemSpec } from '../../../components/Menu/lib';
import { useDefaultHashId } from '../../../../lib';
import { Feature, trackFeature } from '../../../../../utils/features';
import style from './ListItemLink.module.css';
import commonStyle from './common.module.css';

export type LinkItem = {
  id?: string;
  name: string;
  path: string;
  labels?: (string | LabelWithTitle)[];
  labelDescription?: string;
  actions?: ActionMenuItemSpec[];
  isBeta?: boolean;
};

/** This is intended to be used as a RenderItem for ExpandableList */
const ListItemLink: React.FC<RenderItemProps<LinkItem>> = ({
  item: { id, name, path, labels, labelDescription, actions, isBeta },
}) => {
  const history = useHistory();
  const { hide } = usePopoverContext();

  const currentLink = history.location.pathname === path;
  const linkId = useDefaultHashId(id);

  return (
    <li
      title={currentLink ? 'Current page' : undefined}
      className={clsx(style.listItem, { [style.current]: currentLink })}
      tabIndex={-1}
    >
      <Link
        to={path}
        id={linkId}
        className={style.link}
        onClick={() => {
          trackFeature(Feature.AnalyticsMenuItemClicked, { id, name, path });
          hide();
        }}
      >
        <div className={clsx(style.name, { [commonStyle.beta]: isBeta })}>{name}</div>
        {labels?.length ? (
          <ListOfLabels
            aria-label={labelDescription}
            labels={labels}
            className={style.listOfLabels}
          />
        ) : null}
      </Link>
      {actions?.length ? <ThreeDotMenu items={actions} aria-labelledby={linkId} /> : null}
    </li>
  );
};

export default ListItemLink;
