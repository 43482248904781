import React, { useMemo } from 'react';
import {
  PublicClientApplication,
  BrowserCacheLocation,
  Configuration as AzureConfiguration,
} from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import OktaAuth, { toRelativeUrl } from '@okta/okta-auth-js';
import { Security as OktaSecurity } from '@okta/okta-react';
import { ProviderType } from '../../../../generated/graphql';

const PartnerAuthInstance = ({ authConfiguration, children }) => {
  const pca: PublicClientApplication = useMemo(() => {
    const config: AzureConfiguration = {
      auth: {
        clientId: authConfiguration.clientId,
        authority: authConfiguration.issuer,
        redirectUri: authConfiguration.redirectUrl,
        knownAuthorities: authConfiguration.knownAuthorities,
      },
      cache: {
        cacheLocation: BrowserCacheLocation.SessionStorage,
      },
    };
    return new PublicClientApplication(config);
  }, [authConfiguration]);

  if (authConfiguration.providerType === ProviderType.Okta) {
    const oktaAuth = new OktaAuth({
      issuer: authConfiguration.issuer,
      clientId: authConfiguration.clientId,
      redirectUri: authConfiguration.redirectUrl,
      scopes: authConfiguration.scopes || ['openid', 'profile', 'email'],
      responseType: ['token', 'id_token'],
    });
    const customAuthHandler = () => {
      oktaAuth.signInWithRedirect();
    };

    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
      window.history.replaceState(
        {},
        '',
        toRelativeUrl(originalUri || '/p', window.location.origin),
      );
    };
    return (
      <OktaSecurity
        oktaAuth={oktaAuth}
        onAuthRequired={customAuthHandler}
        restoreOriginalUri={restoreOriginalUri}
      >
        {children}
      </OktaSecurity>
    );
  }

  if (authConfiguration.providerType === ProviderType.Azure) {
    return <MsalProvider instance={pca}>{children}</MsalProvider>;
  }
};

export default PartnerAuthInstance;
