import React from 'react';
import clsx from 'clsx';
import { FCC } from '../../../../../../utils/types';
import style from './Subtitle.module.css';

type SubtitleProps = {
  className?: string;
};

const Subtitle: FCC<SubtitleProps> = ({ children, className }) => {
  return <div className={clsx(style.subtitle, className)}>{children}</div>;
};

export default Subtitle;
