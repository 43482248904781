import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Maybe } from '@tellurian/ts-utils';
import useEventCallback from '../../../utils/useEventCallback';
import { NotificationItem } from './lib';
import toastDispatcherFactory from './toastDispatcherFactory';
import { NotificationContextInterface } from './useNotificationContextValue';

const useNotificationPanel = (useNotificationContext: () => NotificationContextInterface) => {
  const [notifications, setNotifications] = useState<NotificationItem[]>([]);
  const push = useCallback(
    (notificationItem: NotificationItem) =>
      setNotifications(current => [...current, notificationItem]),
    [],
  );

  const remove = useCallback(
    (notificationItem: NotificationItem) =>
      setNotifications(current => current.filter(item => item.id !== notificationItem.id)),
    [],
  );

  const flush = useCallback(() => setNotifications(current => [...current]), []);
  const history = useHistory();
  const onPathnameChangeListenersRef = useRef<(() => void)[]>([]);
  const lastPathnameRef = useRef<Maybe<string>>(undefined);

  const onPathnameChange = useEventCallback((fn: () => void) => {
    onPathnameChangeListenersRef.current.push(fn);
    return () =>
      (onPathnameChangeListenersRef.current = onPathnameChangeListenersRef.current.filter(
        listener => listener !== fn,
      ));
  });

  useEffect(() => {
    const unregisterOnPathnameChange = history.listen(({ pathname }) => {
      if (onPathnameChangeListenersRef.current.length) {
        if (lastPathnameRef.current !== pathname) {
          onPathnameChangeListenersRef.current.forEach(fn => fn());
        }
      }
      lastPathnameRef.current = pathname;
    });
    return () => unregisterOnPathnameChange();
  }, [history]);

  const onHistoryChange = useEventCallback((fn: () => void) => history.listen(() => fn()));

  const { setDispatcher } = useNotificationContext();
  useEffect(() => {
    setDispatcher(
      toastDispatcherFactory({ push, remove, flush, onPathnameChange, onHistoryChange }),
    );
    return () => setDispatcher(undefined);
  }, [setDispatcher, push, flush, remove, onPathnameChange, onHistoryChange]);

  return { notifications };
};

export default useNotificationPanel;
