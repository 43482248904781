import React, { useCallback } from 'react';
import {
  GetAccountTermsOfServiceDocument,
  useAcknowledgeTermsOfServiceVersionMutation,
} from '../../generated/graphql';
import usePersistentState from '../../utils/memoryStorage/usePersistentState';
import { MemoryStorageKey } from '../../utils/memoryStorage';
import { TermsOfService } from '../TermsOfService/useTermsOfService';
import AcknowledgementNotification from './AcknowledgementNotification';

type MaybeAcknowledgementNotificationProps = {
  accountId: string;
  termsOfService: TermsOfService;
};

const MaybeAcknowledgementNotification: React.FC<MaybeAcknowledgementNotificationProps> = ({
  accountId,
  termsOfService,
}) => {
  const [lastUnacknowledgedVersion, setLastUnacknowledgedVersion] = usePersistentState(
    MemoryStorageKey.TermsOfServiceVersionAcknowledged,
    0,
  );
  const {
    policy: { firstUnacknowledgedVersion, latestVersion },
  } = termsOfService;
  const [acknowledgeTermsOfServiceVersion] = useAcknowledgeTermsOfServiceVersionMutation();
  const acknowledgeTermsOfService = useCallback(() => {
    setLastUnacknowledgedVersion(latestVersion);
    return acknowledgeTermsOfServiceVersion({
      variables: {
        accountId,
        version: latestVersion,
      },
      refetchQueries: [{ query: GetAccountTermsOfServiceDocument, variables: { accountId } }],
      awaitRefetchQueries: true,
    });
  }, [accountId, latestVersion, acknowledgeTermsOfServiceVersion, setLastUnacknowledgedVersion]);

  if (firstUnacknowledgedVersion && lastUnacknowledgedVersion !== firstUnacknowledgedVersion) {
    return (
      <AcknowledgementNotification accountId={accountId} onDismiss={acknowledgeTermsOfService} />
    );
  }

  return null;
};

export default MaybeAcknowledgementNotification;
