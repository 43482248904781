import React from 'react';
import clsx from 'clsx';
import style from './index.module.css'; // eslint-disable-line
import { FCC } from '../../../utils/types'; // eslint-disable-line

export enum TextSize {
  XXS = 'xxs',
  XS = 'xs',
  S = 's',
  M = 'm',
  L = 'l',
  XL = 'xl',
  XXL = 'xxl',
}

type TextProps = {
  className?: string;
  subtle?: boolean;
  bold?: boolean;
  semibold?: boolean;
  size?: TextSize;
  uppercase?: boolean;
};

const Text: FCC<TextProps> = ({
  children,
  className,
  subtle,
  bold,
  semibold = false,
  size,
  uppercase = false,
}) => (
  <span
    className={clsx(
      {
        [style.subtle]: subtle,
        [style.bold]: bold,
        [style.semibold]: semibold,
        [style.uppercase]: uppercase,
      },
      size && style[size],
      className,
    )}
  >
    {children}
  </span>
);

export default Text;
