import React from 'react';
import clsx from 'clsx';
import Label from './index';
import style from './ListOfLabels.module.css';

export type LabelWithTitle = {
  label: string;
  title: string;
};

type ListOfLabelsProps = {
  labels: (string | LabelWithTitle)[];
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLUListElement>, HTMLUListElement>;

const labelToLabelWithTitle = (l: string | LabelWithTitle) =>
  l.hasOwnProperty('label') ? (l as LabelWithTitle) : { label: l as string, title: undefined };

const ListOfLabels: React.FC<ListOfLabelsProps> = ({ labels, className, ...rest }) => {
  return (
    <ul {...rest} className={clsx(style.list, className)}>
      {labels.map(labelToLabelWithTitle).map(({ label, title }) => (
        <li key={label}>
          <Label text={label} title={title} />
        </li>
      ))}
    </ul>
  );
};

export default ListOfLabels;
