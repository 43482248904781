export enum AccountDetailsTab {
  Overview = 'overview',
  Users = 'users',
  Connectors = 'connectors',
  BusinessDashboards = 'businessDashboards',
  IngestionStatus = 'ingestionStatus',
  History = 'history',
}

export enum InboundDataStatusTab {
  IngestionStatus = 'ingestion-status',
  ByReportDate = 'by-report-date',
  ByRunDate = 'by-run-date',
}

export enum ConnectorTab {
  Inbound = 'source',
  Outbound = 'destination',
}

export enum UnfiInsightsUserAdminTab {
  Diagnostics = 'diagnostics',
  Federated = 'federated',
}
