import React, { ReactNode } from 'react';
import styles from './index.module.css';

export function ForbiddenTemplate({ children }: { children: ReactNode }) {
  return <div className={styles.forbidden}>{children}</div>;
}

export function Forbidden() {
  return (
    <ForbiddenTemplate>
      <h1>
        403. <span className={styles.heading}>Forbidden.</span>
      </h1>
      <p>You don&apos;t have permissions to view this page.</p>
    </ForbiddenTemplate>
  );
}
