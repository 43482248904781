import React, { ReactNode } from 'react';
import { AnyObject } from '@tellurian/ts-utils';
import { NotFound } from '../../../httpErrors';
import useIsRemaAccountAdmin from '../../../powerbiConnect/remaConnect/RemaConnectAdmin/useIsRemaAccountAdmin';
import useIsGlobalAdmin from '../../../../utils/useIsGlobalAdmin';
import BasicTemplate from './BasicTemplate';

type RemaAdminTemplateProps<T extends AnyObject> = T & {
  Layout?: 'div' | React.FC<T>;
  children?: ReactNode;
};

function RemaAdminTemplate<T extends AnyObject>(props: RemaAdminTemplateProps<T>) {
  const isGlobalAdmin = useIsGlobalAdmin();
  if (!useIsRemaAccountAdmin() && !isGlobalAdmin) {
    return <NotFound />;
  }

  return <BasicTemplate {...props} />;
}

export default RemaAdminTemplate;
