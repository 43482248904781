import React, { CSSProperties } from 'react';
import clsx from 'clsx';
import { useThemeContext } from '../../lettuce/components/Theme/ThemeProvider';
import LettuceSpinner from '../../../components/lettuce/components/Spinner';
import styles from './index.module.css';

export function Blur({ className }: { className?: string }) {
  return <div className={clsx(styles.blur, className)} data-testid="blur" />;
}

export enum SpinnerSize {
  NORMAL = 'normal',
  SMALL = 'small',
}

export const SpinnerSvg = ({
  style,
  strokeWidth = 1,
  title = '',
}: {
  style: CSSProperties;
  strokeWidth?: number;
  title?: string;
}) => (
  <svg viewBox="25 25 50 50" className={styles.svg} style={style}>
    {title && <title>{title}</title>}
    <circle
      className={styles.circle}
      cx="50"
      cy="50"
      r="20"
      fill="none"
      strokeWidth={String(strokeWidth)}
    />
  </svg>
);

export function Spinner({
  className,
  center = true,
  size = SpinnerSize.NORMAL,
  testId = 'spinner',
}: {
  className?: string;
  center?: boolean;
  size?: SpinnerSize;
  testId?: string;
}) {
  const { isLettuceTheme } = useThemeContext();
  if (isLettuceTheme && size !== SpinnerSize.SMALL) {
    return <LettuceSpinner data-testid={testId} className={className} />;
  }

  const sizeClass = size === SpinnerSize.NORMAL ? styles.normal : styles.small;
  return (
    <div
      className={clsx(className, { [styles.center]: center })}
      data-testid={testId}
      role="status"
    >
      <div className={clsx(styles.spinner, sizeClass)}>
        <SpinnerSvg style={{ left: 0, top: 0 }} strokeWidth={size === SpinnerSize.SMALL ? 4 : 1} />
      </div>
      <div className="sr-only">Loading...</div>
    </div>
  );
}
