import { ReactNode, useCallback, useRef } from 'react';
import { Maybe } from '@tellurian/ts-utils';
import { useToastContext } from './ToastContext';
import { DismissFn, ShowFnResult, ToastOptions, ToastState } from './lib';

type ShowFn = (specificContent?: ReactNode, specificOptions?: Partial<ToastOptions>) => void;

export type UseToastResult = {
  show: ShowFn;
  dismiss: DismissFn;
  update: (content: ReactNode, options?: Partial<ToastOptions>) => void;
};

export default function useToast(
  content?: ReactNode,
  options?: Partial<ToastOptions>,
): UseToastResult {
  const { show: showToast } = useToastContext();
  const showResult = useRef<Maybe<ShowFnResult>>();
  // Used for lazy updating the notification;
  const lastUpdate = useRef<Maybe<{ content: ReactNode; options?: Partial<ToastOptions> }>>();

  const show = useCallback(
    (specificContent?: ReactNode, specificOptions?: Partial<ToastOptions>) => {
      const result = showResult.current;
      const updateResult = lastUpdate.current;
      const finalContent = specificContent ?? updateResult?.content ?? content;
      const finalOptions =
        options || specificOptions || updateResult?.options
          ? { ...options, ...updateResult?.options, ...specificOptions }
          : undefined;

      if (result) {
        result.showAgain(finalContent, finalOptions);
      } else {
        showResult.current = showToast(finalContent, finalOptions);
      }
      lastUpdate.current = undefined;
    },
    [showToast, content, options],
  );

  /**
   * The function should be used in the rare cases when we wish to update the notification if
   * this is visible, but not trigger its display (i.e. call of show). The use cases are generally
   * the ones where auto dismiss is disabled; all other updates should be handled using repetitive calls to
   * `show` since updates should be handled just in time and not eagerly.
   *
   * This provides the convenience of not having to store this change in separate state, instead using
   * an encapsulated ref.
   */
  const update = useCallback((content: ReactNode, options?: Partial<ToastOptions>) => {
    const state = showResult.current?.getState();
    if (!state || state === ToastState.Dismissed || state === ToastState.AnimatingDismiss) {
      lastUpdate.current = { content, options };
    } else {
      showResult.current?.showAgain(content, options);
    }
  }, []);

  const dismiss = useCallback(
    (immediate = false) => showResult.current?.dismiss(immediate) ?? false,
    [],
  );

  // Avoid undesirable side effects due to state batching, when the expected behaviour
  // is to persist a notification after a page transition. Also, a notification is not a high priority
  // update, it is usually considered a side effect, so it makes sense to have show deferred by default.
  // See https://reactjs.org/blog/2022/03/29/react-v18.html#new-feature-automatic-batching
  // for more infor on automatic state update batching.
  const showDeferred = useCallback((...args) => setTimeout(() => show(...args), 0), [show]);

  return {
    show: showDeferred,
    dismiss,
    update,
  };
}
