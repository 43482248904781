import React, { useEffect } from 'react';
import { Infobar, Spinner } from '../ui';
import Text from '../ui/Text';
import { InfoType } from '../ui/Infobar/lib';
import { PolicyTypeDisplayNames, VersionIdentifier } from '../TermsOfService/lib';
import TermsOfServiceContent, { getVersionUrl } from '../TermsOfService/TermsOfServiceContent';
import { termsOfServiceDocumentExistsInBucket, VersionChangeGuideUrl } from './lib';
import style from './PreviewNewVersion.module.css';

export type VersionAvailability = 'not-checked' | 'checking' | 'available' | 'unavailable';

type PreviewNewVersionProps = VersionIdentifier & {
  versionAvailability: VersionAvailability;
  setVersionAvailability: (nextAvailability: VersionAvailability) => void;
};

const PreviewNewVersion: React.FC<PreviewNewVersionProps> = ({
  policyType,
  version,
  versionAvailability,
  setVersionAvailability,
}) => {
  useEffect(() => {
    setVersionAvailability('checking');
    termsOfServiceDocumentExistsInBucket(policyType, version).then(isAvailable => {
      setVersionAvailability(isAvailable ? 'available' : 'unavailable');
    });
  }, [policyType, version, setVersionAvailability]);

  return (
    <div className={style.container}>
      <div className={style.header}>
        <h3>Preview</h3>
        <div className={style.rhs}>
          <div>
            <Text semibold>{policyType ? PolicyTypeDisplayNames[policyType] : ''}</Text>
          </div>
          <div>
            <Text semibold>version {version}</Text>
          </div>
        </div>
      </div>
      {versionAvailability === 'checking' ? (
        <Spinner />
      ) : versionAvailability === 'unavailable' ? (
        <Infobar type={InfoType.Warning} className="mhn">
          Terms of service document not found for version <strong>{version}</strong>. You must
          upload the document for this version before you can publish the update. See the{' '}
          <a target="crisp-tos-update-guide" href={VersionChangeGuideUrl}>
            Terms of Service Update Guide
          </a>{' '}
          for more information.
        </Infobar>
      ) : (
        (url => (
          <Infobar type={InfoType.Positive} className="mhn">
            Terms of service document found at:
            <br />
            <a href={url} target="crisp-tos">
              {url}
            </a>
          </Infobar>
        ))(getVersionUrl({ policyType, version }))
      )}
      {versionAvailability === 'available' && (
        <TermsOfServiceContent
          policyType={policyType}
          version={version}
          className={style.content}
        />
      )}
    </div>
  );
};

export default React.memo(PreviewNewVersion);
