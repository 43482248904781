import { useMemo } from 'react';
import { useGetUnfiInsightsDashboardGroupsQuery } from '../../../generated/graphql';
import { useUnfiAuthenticationContext } from './auth/UnfiAuthenticationProvider';

export default function () {
  const { accessToken } = useUnfiAuthenticationContext();
  const { data: dashboardGroupsData, loading } = useGetUnfiInsightsDashboardGroupsQuery({
    variables: { accessToken: accessToken || '' },
  });
  const dashboardGroups = useMemo(
    () => dashboardGroupsData?.unfiInsightsDashboardGroups || [],
    [dashboardGroupsData],
  );

  return { dashboardGroups, loading };
}
