import React from 'react';
import { RouteComponentProps } from 'react-router';
import ManageRemaBookmarks from '../components/powerbiConnect/ManageBookmarks';
import { ViewerAuthorityGrant } from '../components/security/AuthorizationContext';
import { lazyWithRetry } from '../components/lib';
import AccountTemplate from '../components/lettuce/crisp/templates/AccountTemplate';

const RenderManageRemaBookmarks = lazyWithRetry<typeof ManageRemaBookmarks>(
  () =>
    import(
      /* webpackChunkName: "managePowerBiBookmarks" */ '../components/powerbiConnect/ManageBookmarks'
    ),
);

type MatchParams = {
  accountId: string;
};
const ManagePowerBiBookmarksPage: React.FC<RouteComponentProps<MatchParams>> = ({
  match: {
    params: { accountId },
  },
}) => {
  return (
    <AccountTemplate requiredGrant={ViewerAuthorityGrant}>
      <RenderManageRemaBookmarks accountId={accountId} />
    </AccountTemplate>
  );
};

export default ManagePowerBiBookmarksPage;
