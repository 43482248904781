import React, { useMemo } from 'react';
import useNotificationPanel from '../lib/notifications/useNotificationPanel';
import ToastNotification from '../ToastNotification';
import { useToastNotificationContext } from '../lib/notifications/ToastNotificationContext';
import style from './index.module.css';

const stopMouseEventPropagation = (e: React.MouseEvent) => e.stopPropagation();

const ToastNotificationPanel: React.FC = () => {
  const { notifications } = useNotificationPanel(useToastNotificationContext);
  const orderedNotifications = useMemo(() => notifications.slice().reverse(), [notifications]);

  return notifications.length ? (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={style.panel}
      onMouseDown={stopMouseEventPropagation}
      onClick={stopMouseEventPropagation}
    >
      <div className={style.notificationContainer}>
        {orderedNotifications.map(notification => (
          <ToastNotification key={notification.id} {...notification} />
        ))}
      </div>
    </div>
  ) : null;
};

export default ToastNotificationPanel;
