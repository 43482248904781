import React from 'react';
import { MemberAuthorityGrant } from '../../../security/AuthorizationContext';
import { FCC } from '../../../../utils/types';
import useInvalidCredentialsToast from '../../../Toast/useInvalidCredentialsToast';
import FullWidthLayout from '../../components/layout/FullWidthLayout';
import AccountTemplate from './AccountTemplate';

const HELP_URL = 'https://support.gocrisp.com/hc/en-us/sections/20772254827031';

type BiReportTemplateProps = {
  accountId: string;
};

const BiReportTemplate: FCC<BiReportTemplateProps> = ({ accountId, children }) => {
  useInvalidCredentialsToast(accountId);
  return (
    <AccountTemplate
      requiredGrant={MemberAuthorityGrant}
      Layout={FullWidthLayout}
      helpUrl={HELP_URL}
    >
      {children}
    </AccountTemplate>
  );
};

export default BiReportTemplate;
