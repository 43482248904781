const clipboard = (() => {
  let canWrite = false;
  // Most browsers do support it, but not JSDOM in test environment
  if (navigator.permissions) {
    navigator.permissions
      .query({ name: 'clipboard-write' as PermissionName })
      .then(result => {
        if (result.state === 'granted') {
          canWrite = true;
        }
      })
      // Some browsers do not support querying for clipboard-write permission.
      // Ignore the error, canWrite will be false on the first attempt to write to clipboard.
      .catch(() => {});
  }

  const writeText = (text: string) =>
    navigator.clipboard
      .writeText(text)
      .then(() => true)
      .catch(() => {
        canWrite = false;
        return false;
      });

  return {
    get canWrite() {
      return canWrite;
    },
    writeText,
  };
})();

export default clipboard;
