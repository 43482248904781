import React, { Fragment, ReactNode } from 'react';
import clsx from 'clsx';
import { noop } from 'lodash';
import { AnyObject, Maybe } from '@tellurian/ts-utils';
import { Blur } from '../../Spinner';
import { useDefaultHashId } from '../../../lib';
import { getItemId, ListFocusStyle, mouseEventHandlerFactory, MouseEventHandlers } from './lib';
import OptionItem, { OptionMinWidth, RenderOptionProps, RenderReactNodeOption } from './OptionItem';
import InterstitialItem, { RenderInterstitialItemProps } from './InterstitialItem';
import styles from './index.module.css';

export type ControlledListOfOptionsProps<
  T = ReactNode,
  Q extends AnyObject = AnyObject,
  P = string,
> = {
  options: T[];
  // The context object houses a set of common props passed to all options. The purpose
  // of the context is to pass external state to the option renderers without
  // having to include this into every option.
  context?: Q;
  RenderOption?: React.FC<RenderOptionProps<T, Q>>;
  onSelect: (index: number) => void;
  isIndexSelected?: (index: number) => boolean;
  focusedIndex: number;
  isFocusChangedByKeyboardEvent?: boolean;
  onFocusedIndexChange: (index: number) => void;
  optionMinWidth?: number;
  id?: string;
  loading?: boolean;
  RenderLoading?: React.FC;
  maxHeight?: number | string;
  'aria-labelledby'?: string;
  listClassName?: string;
  createOptionMouseEventHandlers?: (index: number) => MouseEventHandlers;
  isListFocused?: boolean;
  focusStyle?: ListFocusStyle;
  generateItemId?: typeof getItemId;
  getInterstitialItemAt?: (index: number) => Maybe<P>;
  RenderInterstitialItem?: React.FC<RenderInterstitialItemProps<P>>;
};

/**
 * This is always used in a container, usually a dropdown, so at this point there is always
 * something outside of this component that is "controlling" it (text input for searching,
 * button for displaying, etc). So any direct parent component of ListOfOptions should be using
 * `useListKeyControl` to get the appropriate keyboard event handlers and aria tags for accessibility
 * (see storybook for an example).
 */
export default function ControlledListOfOptions<
  T = ReactNode,
  Q extends AnyObject = AnyObject,
  P = string,
>({
  options,
  // @ts-expect-error Acceptable compromise for a default
  RenderOption: RenderOptionProp = RenderReactNodeOption,
  onSelect,
  isIndexSelected = () => false,
  loading = false,
  maxHeight,
  optionMinWidth = OptionMinWidth.Standard,
  focusedIndex,
  isFocusChangedByKeyboardEvent,
  onFocusedIndexChange,
  id: idProp,
  RenderLoading = Blur,
  'aria-labelledby': labelledBy,
  listClassName,
  isListFocused = false,
  createOptionMouseEventHandlers = mouseEventHandlerFactory({
    ignoreFocusOnMouseEnter: false,
    setIgnoreFocusOnMouseEnter: noop,
    onSelect,
    onFocusChange: onFocusedIndexChange || noop,
    isListFocused,
  }),
  generateItemId = getItemId,
  context = {} as Q,
  getInterstitialItemAt,
  RenderInterstitialItem,
  focusStyle = ListFocusStyle.Border,
}: ControlledListOfOptionsProps<T, Q, P>) {
  const id = useDefaultHashId(idProp);
  const getOptionItemIndexRelatedProps = index => {
    const isFocused = isListFocused && index === focusedIndex;
    return {
      id: generateItemId(id, index),
      index,
      isFocused,
      scrollIntoView: isFocused && isFocusChangedByKeyboardEvent,
      mouseEventHandlers: createOptionMouseEventHandlers(index),
      isSelected: isIndexSelected(index),
    };
  };

  return (
    <div className="relativePosition">
      {loading && <RenderLoading />}
      <div className={clsx(styles.optionsContainer)} style={{ maxHeight }}>
        <ul
          className={clsx(styles.listOfOptions, listClassName)}
          role="listbox"
          id={id}
          aria-labelledby={labelledBy}
        >
          {options.map((option, index) => (
            <Fragment key={`it-${index}`}>
              {getInterstitialItemAt && RenderInterstitialItem && (
                <InterstitialItem
                  value={getInterstitialItemAt(index)}
                  index={index}
                  RenderInterstitialItem={RenderInterstitialItem}
                />
              )}
              <OptionItem<T, Q>
                option={option}
                optionMinWidth={optionMinWidth}
                RenderOption={RenderOptionProp}
                context={context}
                focusStyle={focusStyle}
                {...getOptionItemIndexRelatedProps(index)}
              />
            </Fragment>
          ))}
        </ul>
      </div>
    </div>
  );
}
