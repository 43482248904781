import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Popover, PopoverDisclosure, usePopoverState } from 'reakit/Popover';
import { ReactComponent as IconArrowDown } from '../../../../assets/icons/Arrow-Down-Line-Black-24.svg';
import { Option } from '../../lib';
import { SearchInput } from '../../formInputs';
import { useDefaultHashId } from '../../../lib';
import useSearchListOfItems from '../../lists/listOfItems/useSearchListOfItems';
import ListItemFocusHighlight from '../../lists/listOfItems/listItems/ListItemFocusHighlight';
import useDebounce from '../../../../utils/useDebounce';
import useListOfItemsActionable from '../../lists/listOfItems/useListOfItemsActionable';
import ListOfItems from '../../lists/listOfItems/ListOfItems';
import ActionableListItem, {
  RenderActionableItemProps,
} from '../../lists/listOfItems/ActionableListItem';
import { ActionableListProps } from '../../lists/listOfItems/lib';
import useInitialFocus, { UseInitialFocusParams } from '../useInitialFocus';
import { useDismissPopoverOnOutsideMouseDown } from './lib';
import { Field, FieldProps } from '.';
import style from './MultiSelectField.module.css'; // eslint-disable-line

type SelectProps = {
  options: Option<string>[];
  value?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  label?: string;
  searchable?: boolean;
  id?: string;
  popoverClassName?: string;
  ariaLabelledBy?: string;
  // Defines the values for options that should always be visible in the list,
  // even when filtering results due to a search
  alwaysVisibleOptionValues?: string[];
} & UseInitialFocusParams;

const RenderItem: React.FC<RenderActionableItemProps<Option<string>>> = ({ item }) => {
  return (
    <div className={style.item}>
      <div>{item.text}</div>
    </div>
  );
};

const RenderListItem = ActionableListItem(RenderItem, ListItemFocusHighlight);

const getItemKey = (item: Option<string>) => item.value;

function Select({
  options,
  onChange,
  value,
  placeholder,
  disabled = false,
  label,
  searchable,
  id,
  popoverClassName,
  ariaLabelledBy,
  isFocused,
  scrollIntoViewIfFocused,
  alwaysVisibleOptionValues,
}: SelectProps) {
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query);
  const queryToUse = useMemo(() => debouncedQuery.trim().toLowerCase(), [debouncedQuery]);

  const baseListId = useDefaultHashId(`select-${id}-list`);
  const popoverId = useDefaultHashId(`select-${id}-popover`);

  const popover = usePopoverState({ placement: 'bottom-start', baseId: popoverId });

  const { listId, getItemId, setFocusedIndex, searchProps } = useSearchListOfItems({
    baseListId,
  });

  const filteredOptions = useMemo(() => {
    return queryToUse
      ? options.filter(
          option =>
            alwaysVisibleOptionValues?.includes(option.value) ||
            option.text.toLowerCase().indexOf(queryToUse) !== -1,
        )
      : options;
  }, [options, queryToUse, alwaysVisibleOptionValues]);

  const {
    getFocusParentProps,
    setFocusedIndex: setFocus,
    ...listProps
  } = useListOfItemsActionable<Option<string>>({
    items: filteredOptions,
    toggleOnSpace: !searchable,
    isListFocused: false,
    isListItemMouseDownDefaultPrevented: true,
    onFocusedIndexChange: setFocusedIndex,
    onItemToggle: item => {
      onChange(item.value);
      popover.hide();
    },
  });

  useEffect(() => {
    setFocus(0);
  }, [queryToUse, setFocus]);

  const buttonText = options.find(o => o.value === value)?.text;

  const focusParentProps = useMemo(
    () => (searchable ? undefined : getFocusParentProps()),
    [getFocusParentProps, searchable],
  );

  useDismissPopoverOnOutsideMouseDown(popover);

  return (
    <>
      <PopoverDisclosure
        {...popover}
        as="button"
        ref={
          useInitialFocus({
            isFocused,
            scrollIntoViewIfFocused,
          }) as React.LegacyRef<HTMLButtonElement>
        }
        disabled={disabled}
        className={clsx(style.multiSelectDropdown, {
          [style.multiSelectDropdownOpen]: popover.visible,
          [style.disabledField]: disabled,
        })}
      >
        {buttonText ? (
          <span className={style.multiSelectDropdownButtonText}>{buttonText}</span>
        ) : (
          <span className={style.placeholder}>{placeholder}</span>
        )}
        <IconArrowDown
          className={style.multiSelectArrow}
          width="10px"
          height="10px"
          style={{
            transform: popover.visible ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
          aria-hidden
        />
      </PopoverDisclosure>
      <Popover
        {...popover}
        aria-label={label}
        aria-labelledby={ariaLabelledBy}
        className={clsx(style.multiSelectPopover, popoverClassName)}
      >
        {searchable && (
          <div className={style.searchInputContainer}>
            <SearchInput
              className={style.searchInput}
              value={query}
              onChange={setQuery}
              placeholder="Search"
              {...searchProps}
              {...getFocusParentProps()}
            />
          </div>
        )}
        {filteredOptions.length === 0 ? (
          <div className="mas">No results.</div>
        ) : (
          <ListOfItems<Option<string>, ActionableListProps>
            {...focusParentProps}
            {...listProps}
            RenderItem={RenderListItem}
            className={style.multiSelectList}
            id={listId}
            getItemKey={getItemKey}
            getItemId={getItemId}
          />
        )}
      </Popover>
    </>
  );
}

export type SelectFieldProps = FieldProps &
  SelectProps & {
    optionsLabel?: string;
    loading?: boolean;
  } & UseInitialFocusParams;

/**
 * A field for selection of a single item from a dropdown
 */
export function SelectField({
  options,
  onChange,
  value,
  placeholder,
  disabled = false,
  optionsLabel = 'options',
  loading = false,
  searchable = false,
  popoverClassName,
  isFocused,
  scrollIntoViewIfFocused,
  ariaLabelledBy,
  alwaysVisibleOptionValues,
  ...fieldProps
}: SelectFieldProps) {
  return (
    <Field {...fieldProps}>
      {options.length ? (
        <Select
          id={`${fieldProps.id}-select`}
          options={options}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          label={fieldProps.label}
          searchable={searchable}
          popoverClassName={popoverClassName}
          isFocused={isFocused}
          scrollIntoViewIfFocused={scrollIntoViewIfFocused}
          ariaLabelledBy={ariaLabelledBy}
          alwaysVisibleOptionValues={alwaysVisibleOptionValues}
        />
      ) : loading ? (
        <span className={style.loading}>Loading...</span>
      ) : (
        <span className={style.error}>No {optionsLabel} found.</span>
      )}
    </Field>
  );
}
