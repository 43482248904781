import React from 'react';
import clsx from 'clsx';
import style from './index.module.css';

const LabelVariants = ['neutral', 'red'] as const;
type LabelVariant = (typeof LabelVariants)[number];

type LabelProps = {
  text: string;
  title?: string;
  className?: string;
  variant?: LabelVariant;
};

/**
 * A simple label component designed to highlight a piece of text (i.e. not any ReactNode)
 */
const Label: React.FC<LabelProps> = ({ text, title, className, variant = 'neutral' }) => (
  <span className={clsx(style.label, className, { [style.red]: variant === 'red' })} title={title}>
    {text}
  </span>
);

export default Label;
