import React, { useCallback, useMemo } from 'react';
import {
  useResendUserInvitationMutation,
  useRevokeUserInvitationMutation,
  UserInvitationFragment,
} from '../../../../generated/graphql';
import useIsGlobalAdmin from '../../../../utils/useIsGlobalAdmin';
import { useSuccess } from '../../../Toast/Toasts';
import UserItemList from './UserItemList';
import InvitationItem, { InvitationItemProps } from './InvitationItem';
import useOnCopyEmail from './useOnCopyEmail';

type UserInvitationListProps = {
  accountId: string;
  userInvitations: UserInvitationFragment[];
  className?: string;
  onShowAcceptUrl: (invitationId: string) => void;
  onInvitationRevoked?: () => void;
  onInvitationResent?: () => void;
};

const UserInvitationList: React.FC<UserInvitationListProps> = ({
  accountId,
  userInvitations,
  className,
  onShowAcceptUrl,
  onInvitationRevoked,
  onInvitationResent,
}) => {
  const isGlobalAdmin = useIsGlobalAdmin();
  const mutationBaseOptions = {};
  const [revokeUserInvitation] = useRevokeUserInvitationMutation(mutationBaseOptions);
  const [resendUserInvitation] = useResendUserInvitationMutation(mutationBaseOptions);
  const { show: showCancelSuccessToast } = useSuccess();
  const { show: showResendSuccessToast } = useSuccess();

  const onRevokeInvitation = useCallback(
    (invitation: Pick<UserInvitationFragment, 'id' | 'email'>) =>
      revokeUserInvitation({
        variables: {
          accountId,
          input: {
            invitationId: invitation.id,
          },
        },
      })
        .then(() => onInvitationRevoked?.())
        .then(() =>
          showCancelSuccessToast(
            <>
              Cancelled invitation for <strong>{invitation.email}</strong>.
            </>,
          ),
        ),
    [accountId, revokeUserInvitation, showCancelSuccessToast, onInvitationRevoked],
  );

  const onReInvite = useCallback(
    (invitation: Pick<UserInvitationFragment, 'id' | 'email'>) =>
      resendUserInvitation({
        variables: {
          accountId,
          input: {
            invitationId: invitation.id,
          },
        },
      })
        .then(() => onInvitationResent?.())
        .then(() =>
          showResendSuccessToast(
            <>
              Resent invitation for <strong>{invitation.email}</strong>.
            </>,
          ),
        ),
    [accountId, resendUserInvitation, showResendSuccessToast, onInvitationResent],
  );

  const now = useMemo(() => new Date(), []);
  const onCopyEmail = useOnCopyEmail();

  const invitationItems: InvitationItemProps[] = useMemo(() => {
    return userInvitations.map(userInvitation => ({
      ...userInvitation,
      isExpired: +new Date(userInvitation.expiresAtTimestamp) < +now,
      onReInvite: () => onReInvite(userInvitation),
      onRevokeInvitation: () => onRevokeInvitation(userInvitation),
      onCopyEmail: () => onCopyEmail(userInvitation.email),
      onShowInvitationUrl: () => onShowAcceptUrl(userInvitation.id),
      isGlobalAdmin,
    }));
  }, [
    onCopyEmail,
    userInvitations,
    onShowAcceptUrl,
    now,
    onReInvite,
    onRevokeInvitation,
    isGlobalAdmin,
  ]);

  return (
    <UserItemList<InvitationItemProps>
      title="Invitations"
      items={invitationItems}
      RenderItem={InvitationItem}
      className={className}
    />
  );
};

export default UserInvitationList;
