import { ReactComponent as IconContract } from '../../assets/icons/business-contract-approve.svg';
import { useWithOptions, UseWithOptions } from '../Toast/Toasts';

const Flavor = {
  Icon: IconContract,
  highlight: 'var(--color-primary)',
  ariaLabel: 'Info',
  iconFill: '#f3ffe7',
  iconWidth: 22,
};

const useTermsOfServiceToast: UseWithOptions = (...args) => useWithOptions(Flavor)(...args);

export default useTermsOfServiceToast;
