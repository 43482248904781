import React, { Ref } from 'react';
import CheckboxOrRadio from './CheckboxOrRadio';
import { CheckboxProps } from './Checkbox';

export type RadioProps<T extends string = string> = Omit<CheckboxProps<T>, 'ref'>;

const Radio = React.forwardRef<HTMLInputElement, RadioProps>(function Radio<
  T extends string = string,
>(props: RadioProps<T>, ref: Ref<HTMLInputElement>) {
  return <CheckboxOrRadio<T> type="radio" {...props} ref={ref} />;
});

export default Radio;
