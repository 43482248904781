import React, { ChangeEvent, ReactElement, Ref, useRef, useState } from 'react';
import clsx from 'clsx';
import useEventCallback from '../../../utils/useEventCallback';
import { useDefaultHashId } from '../../../../lib';
import composeRefs from '../../../utils/composeRefs';
import inputStyle from '../Input/index.module.css'; // eslint-disable-line
import style from './index.module.css';

type TextAreaBaseProps = {
  label: string;
} & Omit<
  React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>,
  'ref'
>;

const TextAreaBase = React.forwardRef<HTMLTextAreaElement, TextAreaBaseProps>(function TextareaBase(
  { label, id, value = '', ...rest },
  ref,
) {
  id = useDefaultHashId(id);
  return (
    <div className={clsx(inputStyle.inputWithLabel, style.textAreaWithLabel)}>
      {label && <label htmlFor={id}>{label}</label>}
      <textarea ref={ref} id={id} value={value} {...rest} />
    </div>
  );
});

type UncontrolledTextAreaBaseProps = Omit<TextAreaBaseProps, 'value'> & { initialValue?: string };

const UncontrolledTextAreaBase = React.forwardRef<
  HTMLTextAreaElement,
  UncontrolledTextAreaBaseProps
>(function UncontrolledTextAreaBase({ id, label, initialValue, ...props }, ref) {
  id = useDefaultHashId(id);
  return (
    <div className={clsx(inputStyle.inputWithLabel, style.textAreaWithLabel)}>
      {label && <label htmlFor={id}>{label}</label>}
      <textarea ref={ref} id={id} defaultValue={initialValue} {...props} />
    </div>
  );
});

type RenderTextareaProps = Pick<
  TextAreaBaseProps,
  'onFocus' | 'onBlur' | 'value' | 'onChange' | 'disabled' | 'placeholder'
>;

type TextAreaContainerProps<T extends RenderTextareaProps> = T & {
  RenderTextArea: React.FC<
    T & {
      ref: React.Ref<HTMLTextAreaElement>;
    }
  >;
  hasValue?: boolean;
  hasError?: boolean;
  containerRef?: Ref<HTMLDivElement>;
};

function TextAreaContainerBase<T extends RenderTextareaProps>(
  {
    RenderTextArea,
    hasValue,
    hasError = false,
    placeholder,
    containerRef,
    ...props
  }: TextAreaContainerProps<T>,
  externalRef: React.Ref<HTMLTextAreaElement>,
) {
  const [isFocused, setIsFocused] = useState(false);
  const onFocus = useEventCallback((e: React.FocusEvent<HTMLTextAreaElement, HTMLElement>) => {
    setIsFocused(true);
    props.onFocus?.(e);
  });
  const onBlur = useEventCallback((e: React.FocusEvent<HTMLTextAreaElement, HTMLElement>) => {
    setIsFocused(false);
    props.onBlur?.(e);
  });
  const ref = useRef<HTMLTextAreaElement>(null);
  hasValue = typeof hasValue === 'boolean' ? hasValue : !!props.value;
  const placeholderToUse = isFocused ? placeholder : undefined;
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={clsx(inputStyle.container, style.container, {
        [inputStyle.focused]: isFocused,
        [inputStyle.hasValue]: hasValue,
        [inputStyle.hasError]: hasError,
        [inputStyle.disabled]: props.disabled,
      })}
      onMouseDown={() => {
        setTimeout(() => ref.current?.focus(), 0);
      }}
      ref={containerRef}
    >
      <RenderTextArea
        {...(props as unknown as T)}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholderToUse}
        ref={externalRef ? composeRefs(ref, externalRef) : ref}
      />
    </div>
  );
}

const TextAreaContainer = React.forwardRef(TextAreaContainerBase) as <
  T extends RenderTextareaProps,
>(
  props: T,
) => ReactElement;

export type TextAreaProps = TextAreaBaseProps &
  Pick<TextAreaContainerProps<TextAreaBaseProps>, 'hasError' | 'containerRef'>;

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  function TextArea(props, ref) {
    return <TextAreaContainer RenderTextArea={TextAreaBase} {...props} ref={ref} />;
  },
);

export default TextArea;

type UncontrolledTextAreaProps = UncontrolledTextAreaBaseProps &
  Pick<TextAreaContainerProps<UncontrolledTextAreaBaseProps>, 'hasError'>;

export const UncontrolledTextArea = React.forwardRef<HTMLInputElement, UncontrolledTextAreaProps>(
  function UncontrolledTextArea(props, ref) {
    const [hasValue, setHasValue] = useState(!!props.initialValue);

    const onChange = useEventCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
      setHasValue(!!e.target.value);
      props.onChange?.(e);
    });

    return (
      <TextAreaContainer
        ref={ref}
        RenderTextArea={UncontrolledTextAreaBase}
        {...props}
        onChange={onChange}
        hasValue={hasValue}
      />
    );
  },
);
