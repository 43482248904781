import React, { RefObject, useCallback, useContext, useEffect, useRef, useState } from 'react';
import isEqual from 'lodash/isEqual';
import querystring from 'query-string';
import { useHistory } from 'react-router';
import {
  DashboardFilterInput,
  LookerFilter,
  useGenerateLookerDashboardUrlMutation,
  useGenerateUnfiInsightsLookerDashboardUrlMutation,
} from '../../../generated/graphql';
import { parseQuery } from '../../../utils/querystringUtils';
import { isUnfiInsightsDashboard } from '../../lib';
import { useUnfiInsightsOnPlatformAuthenticationContext } from '../../lettuce/unfiInsightsOnPlatform/UnfiInsightsOnPlatformAuthenticationProvider';
import { useGetDashboardById } from '../hooks/useGetDashboardGroups';
import { dashboardFiltersToLookerFilters } from './lib';
import {
  EmbeddedLooker,
  LookerEvent,
  LookerMessageType,
  LookerPageProperties,
} from './EmbeddedLooker';

export type EmbeddedLookerInterface = {
  loading: boolean;
  iframeRef: RefObject<HTMLIFrameElement> | undefined;
  embeddedLooker: EmbeddedLooker | undefined;
  setFilters: (LookerFilters) => void;
  resetFilters: () => void;
  currentFilters: LookerFilters;
  embeddedUrl: string | undefined;
  pageProperties: LookerPageProperties | undefined;
  generateEmbeddedUrl: (
    dashboardGroupId: string,
    dashboardId: string,
    filters?: DashboardFilterInput[],
  ) => void;
  resetInitialQueryParams: (params?: string) => void;
  setFilterQueryParams: (filters: Record<string, string>) => void;
  extractFiltersFromQueryParams: (queryParams?: string) => DashboardFilterInput[];
  defaultFilters: LookerFilters | undefined;
};

export const EmbeddedLookerContext = React.createContext<EmbeddedLookerInterface>({
  iframeRef: undefined,
  loading: true,
  embeddedLooker: undefined,
  setFilters: () => {},
  resetFilters: () => {},
  resetInitialQueryParams: () => {},
  currentFilters: {},
  embeddedUrl: undefined,
  generateEmbeddedUrl: () => {},
  pageProperties: undefined,
  setFilterQueryParams: () => {},
  extractFiltersFromQueryParams: () => [],
  defaultFilters: undefined,
});

export const useEmbeddedLookerContext = () => useContext(EmbeddedLookerContext);

type EmbeddedLookerContextProviderProps = {
  accountId: string;
  children: React.ReactNode;
};

export type LookerFilters = Record<string, string>;

export const useEmbeddedLooker = (setLoading: (nextValue: boolean) => void) => {
  const history = useHistory();
  const initialQueryParams = useRef(history.location.search);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const embeddedLooker = useRef<EmbeddedLooker>();
  const [currentFilters, setCurrentFilters] = useState<LookerFilters>({});
  const [pageProperties, setPageProperties] = useState<LookerPageProperties | undefined>();

  const extractFiltersFromQueryParams = useCallback(
    (queryParams?: string): DashboardFilterInput[] => {
      return Object.entries(parseQuery(queryParams ?? initialQueryParams.current)).map(
        ([name, value]) => ({
          name,
          value: Array.isArray(value) ? value.join(',') : value || '',
        }),
      );
    },
    [initialQueryParams],
  );

  const resetInitialQueryParams = useCallback((params = '') => {
    initialQueryParams.current = params;
  }, []);

  const setFilterQueryParams = useCallback(
    (filters: Record<string, string>) => {
      const queryParams = querystring.stringify(filters, {
        arrayFormat: 'none',
      });
      const newPath = `${history.location.pathname}?${queryParams}`;
      history.replace(newPath);
    },
    [history],
  );

  const eventHandler = useCallback(
    (event: LookerEvent) => {
      if (event.type === LookerMessageType.DashboardLoaded) {
        setLoading(false);
        setCurrentFilters(event.dashboard.dashboard_filters);
      } else if (event.type === LookerMessageType.DashboardFiltersChanged) {
        setCurrentFilters(event.dashboard.dashboard_filters);
        setFilterQueryParams(event.dashboard.dashboard_filters);
      } else if (event.type === LookerMessageType.PageChanged) {
        // extract cross-filters from query params.
        // this is necessary since dashboard_filters does not supply the proper
        // cross-filter key.
        const filters = dashboardFiltersToLookerFilters(
          extractFiltersFromQueryParams(event.page.url.split('?')[1]),
        );
        setCurrentFilters(filters);
        setFilterQueryParams(filters);
      } else if (event.type === LookerMessageType.PagePropertiesChanged) {
        setPageProperties({
          height: event.height,
          absoluteUrl: event.page.absoluteUrl,
          url: event.page.url,
        });
      }
    },
    [
      setLoading,
      setCurrentFilters,
      setFilterQueryParams,
      setPageProperties,
      extractFiltersFromQueryParams,
    ],
  );

  useEffect(() => {
    const looker = new EmbeddedLooker(eventHandler, iframeRef);
    embeddedLooker.current = looker;

    return () => {
      looker.removeEventListener();
    };
  }, [eventHandler]);

  return {
    iframeRef,
    embeddedLooker: embeddedLooker.current,
    currentFilters,
    resetInitialQueryParams,
    extractFiltersFromQueryParams,
    setFilterQueryParams,
    pageProperties,
  };
};

export function EmbeddedLookerContextProvider({
  accountId,
  children,
}: EmbeddedLookerContextProviderProps) {
  const [isLoading, setLoading] = useState(true);
  const {
    iframeRef,
    embeddedLooker,
    currentFilters,
    resetInitialQueryParams,
    extractFiltersFromQueryParams,
    setFilterQueryParams,
    pageProperties,
  } = useEmbeddedLooker(setLoading);
  const [generateUrl, { data: embeddedUrlData }] = useGenerateLookerDashboardUrlMutation();
  const [generateUnfiInsightsUrl, { data: embeddedUnfiInsightsUrlData }] =
    useGenerateUnfiInsightsLookerDashboardUrlMutation();
  const { accessToken } = useUnfiInsightsOnPlatformAuthenticationContext();
  const isLatestUnfiInsightsDashboard = useRef(false);

  const getDashboardById = useGetDashboardById(accountId);

  const defaultFilters = useRef<LookerFilters>({});

  const setDefaultFilters = (filters?: LookerFilter[]) => {
    defaultFilters.current = {};
    filters?.forEach(f => {
      defaultFilters.current[f.name] = f.defaultValue;
    });
  };

  useEffect(() => {
    setDefaultFilters(embeddedUrlData?.generateLookerDashboardUrl?.defaultFilters);
  }, [embeddedUrlData]);

  useEffect(() => {
    setDefaultFilters(
      embeddedUnfiInsightsUrlData?.generateUnfiInsightsLookerDashboardUrl?.defaultFilters,
    );
  }, [embeddedUnfiInsightsUrlData]);

  const generateEmbeddedUrl = useCallback(
    (dashboardGroupId: string, dashboardId: string, filters?: DashboardFilterInput[]) => {
      setLoading(true);
      const dashboard = getDashboardById({ dashboardGroupId, dashboardId });
      if (isUnfiInsightsDashboard(dashboard)) {
        generateUnfiInsightsUrl({
          variables: {
            input: {
              dashboardId,
              accessToken: accessToken || '',
              embeddingDomain: window.location.origin,
              accountId,
            },
          },
        });
        isLatestUnfiInsightsDashboard.current = true;
      } else {
        generateUrl({
          variables: {
            accountId,
            input: {
              dashboardGroupId: dashboardGroupId,
              dashboardId: dashboardId,
              filters: filters ?? extractFiltersFromQueryParams(),
              embeddingDomain: window.location.origin,
            },
          },
        });
        isLatestUnfiInsightsDashboard.current = false;
      }
    },
    [
      accountId,
      generateUrl,
      generateUnfiInsightsUrl,
      accessToken,
      setLoading,
      extractFiltersFromQueryParams,
      getDashboardById,
    ],
  );

  const setFilters = useCallback(
    (filters: LookerFilters) => {
      const looker = embeddedLooker;
      if (looker && !isEqual(currentFilters, filters)) {
        looker.postMessage({ type: 'dashboard:filters:update', filters: filters });
        looker.postMessage({ type: 'dashboard:run' });
      }
    },
    [currentFilters, embeddedLooker],
  );

  const resetFilters = () => {
    setFilters(defaultFilters.current);
  };

  return (
    <EmbeddedLookerContext.Provider
      value={{
        iframeRef,
        loading: isLoading,
        embeddedLooker,
        currentFilters,
        setFilters,
        resetInitialQueryParams,
        resetFilters,
        embeddedUrl: isLatestUnfiInsightsDashboard.current
          ? embeddedUnfiInsightsUrlData?.generateUnfiInsightsLookerDashboardUrl?.url
          : embeddedUrlData?.generateLookerDashboardUrl?.url,
        generateEmbeddedUrl,
        pageProperties,
        setFilterQueryParams,
        extractFiltersFromQueryParams,
        defaultFilters: defaultFilters.current,
      }}
    >
      {children}
    </EmbeddedLookerContext.Provider>
  );
}
