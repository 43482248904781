import { useCallback } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { KnownFlags } from '../../../../common/featureFlags/KnownFlags';
import { FeatureFlagsDisabled } from '../../../../common/featureFlags/FeatureFlags';

const useIsBetaDashboard = (dashboardGroupName: string) => {
  const { [KnownFlags.BetaDashboardBadges]: betaDashboardBadges } = useFlags();
  return useCallback(
    (dashboard: { name: string }) => {
      if (FeatureFlagsDisabled) {
        return false;
      }
      const dashboardGroupNameToUse = dashboardGroupName.toLowerCase();
      const dashboardNameToUse = dashboard.name.toLowerCase();
      return betaDashboardBadges.some(
        e =>
          e.dashboardGroupName.toLowerCase() === dashboardGroupNameToUse &&
          e.dashboardName.toLowerCase() === dashboardNameToUse,
      );
    },
    [betaDashboardBadges, dashboardGroupName],
  );
};

export default useIsBetaDashboard;
