import { useMemo } from 'react';
import { useGetAccountUsersQuery } from '../../../../../generated/graphql';

const useGetAccountUsersForSharing = (accountId: string) => {
  const { data, loading } = useGetAccountUsersQuery({
    variables: {
      accountId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const users = useMemo(
    () =>
      data
        ? [
            ...data.account.users.edges.map(e => e.node),
            ...data.account.userInvitations.edges.map(e => ({
              id: e.node.userId || '',
              email: e.node.email,
            })),
          ]
        : [],
    [data],
  );

  return { users, loading };
};

export default useGetAccountUsersForSharing;
