import React, { ReactElement } from 'react';
import { Maybe } from '@tellurian/ts-utils';
import { defaultGetSizeProps } from '../useDropdown';
import FloatingPortal from '../FloatingPortal';
import Popover from '../Popover';
import InputSelect, { InputSelectProps } from '../Input/InputSelect';
import useDropdownListOfItemsActionable, {
  UseDropdownListOfItemsSelectParams,
} from '../useDropdownListOfItemsSelect';
import ListOfItems, { ListOfItemsProps } from '../../../../ui/lists/listOfItems/ListOfItems';
import { ActionableListProps } from '../../../../ui/lists/listOfItems/lib';
import { useDefaultHashId } from '../../../../lib';
import Backdrop from '../../Backdrop';
import { GetSizeProps } from '../useTether';
import LoadingListItems from '../LoadingListItems';
import NoItems from '../../../../ui/lists/listOfItems/NoItems';
import SearchField from '../../SearchField';
import { escapeSpaceKey } from '../MultiSelectDropdown/lib';
import useSelectCommon from '../useSelectCommon';
import composeRefs, { composeRefsInProps } from '../../../utils/composeRefs';
import style from '../../ChipDropdown/ChipDropdownSelect.module.css';

const getPopoverSizeProps: GetSizeProps = params => {
  const { rects } = params;
  return {
    ...defaultGetSizeProps(params),
    minHeight: 0,
    maxWidth: Math.max(rects.reference.width, 200),
  };
};

type SelectDropdownComponent = <T>(
  props: SelectDropdownProps<T> & { ref: React.Ref<HTMLInputElement> },
) => ReactElement;

/**
 * A generic dropdown component with an InputSelect as trigger.
 * Use with list items of a custom type (e.g. with list item containing an icon and various pieces of information, toggles etc)
 * or as a base component for more specific types (e.g. list with numbers or name/value pairs)
 */
export type SelectDropdownProps<T> = UseDropdownListOfItemsSelectParams<T> & {
  getInputText: (selectedItem: Maybe<T>) => string;
  listId?: string;
  backdrop?: boolean;
  isLoadingItems?: boolean;
  onSearch?: (query: string) => void;
} & Pick<InputSelectProps, 'label' | 'hasError' | 'disabled' | 'onBlur'> &
  Pick<ListOfItemsProps<T, ActionableListProps>, 'getItemKey' | 'RenderItem'>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EnabledSelectDropdown = React.forwardRef<HTMLInputElement, SelectDropdownProps<any>>(
  function SelectDropdown<T>(
    {
      selectedItem,
      label,
      getInputText,
      getItemKey,
      listId,
      RenderItem,
      backdrop = false,
      role = 'listbox',
      hasError,
      isLoadingItems = false,
      onSearch,
      ...dropdownProps
    }: SelectDropdownProps<T>,
    ref,
  ) {
    listId = useDefaultHashId(listId);
    const { getReferenceProps, getPopoverProps, listProps, isActive, setIsActive } =
      useDropdownListOfItemsActionable<T, HTMLInputElement>({
        getPopoverSizeProps,
        ...dropdownProps,
        selectedItem,
        role,
        stopKeyDownPropagation: true,
        toggleOnSpace: true,
      });

    const {
      className,
      referenceProps,
      setQuery,
      query,
      onPopoverParentBlur,
      onListMouseDown,
      relatedElementRef,
      popoverRef,
      listStyle,
    } = useSelectCommon({
      isActive,
      setIsActive,
      onSearch,
      backdrop,
      getReferenceProps,
    });

    return (
      <>
        {isActive && backdrop && <Backdrop variant="transparent" />}
        <InputSelect
          {...referenceProps}
          onBlur={onPopoverParentBlur}
          value={getInputText(selectedItem)}
          label={label}
          className={className}
          hasError={hasError}
          containerRef={referenceProps.ref}
          ref={composeRefs(ref, relatedElementRef)}
        />
        {isActive && (
          <FloatingPortal>
            <Popover className={className} {...composeRefsInProps(getPopoverProps(), popoverRef)}>
              {onSearch && !isLoadingItems && (
                // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                <div className="mtm mhm mbs" onMouseDown={ev => ev.stopPropagation()}>
                  <SearchField
                    onChange={setQuery}
                    value={query}
                    onKeyDown={escapeSpaceKey(referenceProps.onKeyDown)}
                    onBlur={onPopoverParentBlur}
                    autoFocus={true}
                    minWidth={80}
                  />
                </div>
              )}
              {isLoadingItems ? (
                <LoadingListItems />
              ) : listProps.items.length > 0 ? (
                <ListOfItems<T, ActionableListProps>
                  {...listProps}
                  RenderItem={RenderItem}
                  className={style.list}
                  id={listId}
                  getItemKey={getItemKey}
                  style={listStyle}
                  role="none"
                  onMouseDown={onListMouseDown}
                />
              ) : (
                <NoItems />
              )}
            </Popover>
          </FloatingPortal>
        )}
      </>
    );
  },
) as SelectDropdownComponent;

const DisabledSelectDropdown = React.forwardRef<HTMLInputElement, SelectDropdownProps<unknown>>(
  function SelectDropdown<T>(
    { selectedItem, label, getInputText, hasError }: SelectDropdownProps<T>,
    ref,
  ) {
    return (
      <InputSelect
        value={getInputText(selectedItem)}
        label={label}
        hasError={hasError}
        disabled={true}
        ref={ref}
      />
    );
  },
) as SelectDropdownComponent;

const SelectDropdown = React.forwardRef<HTMLInputElement, SelectDropdownProps<unknown>>(
  function SelectDropdown<T>(props, ref) {
    return props.disabled ? (
      <DisabledSelectDropdown<T> {...props} ref={ref} />
    ) : (
      <EnabledSelectDropdown<T> {...props} ref={ref} />
    );
  },
);

export default SelectDropdown as SelectDropdownComponent;
