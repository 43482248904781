import { useCallback } from 'react';
import { Maybe } from '@tellurian/ts-utils';
import {
  ConnectorApplication,
  DashboardFragment,
  useGetConnectorNamesQuery,
} from '../../../../generated/graphql';
import { useAuthorizationContext } from '../../../security/AuthorizationContext';

type UseGetConnectorName = (application: ConnectorApplication) => Maybe<string>;
export const useGetConnectorName = (accountId: string): UseGetConnectorName => {
  const { getHasGlobalAdminPermission, getHasAccountReadPermission } = useAuthorizationContext();
  const isGlobalAdmin = getHasGlobalAdminPermission();
  const hasReadPermission = getHasAccountReadPermission(accountId);
  const { data } = useGetConnectorNamesQuery({
    variables: { accountId },
    skip: !isGlobalAdmin && !hasReadPermission,
  });
  return useCallback(
    (application: ConnectorApplication) => {
      return data?.account.connectors.find(connector => connector.application === application)
        ?.name;
    },
    [data],
  );
};

type UseGetReportLabels = (
  report: Pick<DashboardFragment, 'relatedConnectorApplications'>,
) => string[];
export const useGetReportLabels = (accountId: string): UseGetReportLabels => {
  const getConnectorName = useGetConnectorName(accountId);
  return useCallback(
    (report: Pick<DashboardFragment, 'relatedConnectorApplications'>) => {
      return (
        report.relatedConnectorApplications
          ?.map(c => getConnectorName(c))
          .filter((c): c is string => !!c) ?? []
      );
    },
    [getConnectorName],
  );
};
