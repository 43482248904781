import React from 'react';
import Bowser from 'bowser';
import clsx from 'clsx';
import { KeyCombo } from './lib';
import style from './RenderKeyCombo.module.css'; // eslint-disable-line

export enum KeyComboSize {
  M = 'size-m',
  S = 'size-s',
}

export type RenderKeyComboProps = {
  keyCombo: KeyCombo;
  isAppleHost?: boolean;
  size?: KeyComboSize;
  className?: string;
};
const defaultIsApple =
  Bowser.getParser(window.navigator.userAgent).getPlatform().vendor === 'Apple';

const toPrintableKey = (code: KeyboardEvent['code']): string => {
  if (code.startsWith('Key')) {
    return code.substring(3);
  }

  if (code === 'Enter') {
    return '⮐';
  }

  if (code === 'Slash') {
    return '/';
  }

  if (code === 'Escape') {
    return 'Esc';
  }

  if (code === 'Comma') {
    return ',';
  }

  return code;
};

const RenderKeyCombo: React.FC<RenderKeyComboProps> = ({
  keyCombo: { meta, code, alt, shift },
  isAppleHost = defaultIsApple,
  size = KeyComboSize.M,
  className,
}) => {
  const content = [
    meta && <kbd key={'kbd-meta'}>{isAppleHost ? '⌘' : 'Ctrl'}</kbd>,
    shift && <kbd key={'kbd-shift'}>Shift</kbd>,
    alt && <kbd key={'kbd-alt'}>{isAppleHost ? '⌥' : 'Alt'}</kbd>,
    code && <kbd key={'kbd-key'}>{toPrintableKey(code)}</kbd>,
  ].filter(Boolean);
  return (
    <kbd className={clsx(style.container, className, style[size])}>
      {content.reduce<React.ReactNode[]>(
        (acc, item, index) =>
          index > 0 ? [...acc, <span key={`plus-${index}`}>+</span>, item] : [...acc, item],
        [],
      )}
    </kbd>
  );
};

export default RenderKeyCombo;
