import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Maybe } from '@tellurian/ts-utils';
import _ from 'lodash';
import { useCreateTermsOfServiceVersionMutation } from '../../generated/graphql';
import { Button, ButtonStyle, Col, Grid, GridType } from '../ui';
import Text from '../ui/Text';
import FullScreenModal from '../ui/Modal/FullScreenModal';
import { useSuccess } from '../Toast/Toasts';
import { inProgressLabel } from '../lib';
import { PolicyTypeDisplayNames, VersionIdentifier } from '../TermsOfService/lib';
import { CreateVersionValues, NoPreviewAvailable } from './lib';
import NewVersionForm from './NewVersionForm';
import PreviewNewVersion, { VersionAvailability } from './PreviewNewVersion';
import style from './CreateNewVersionModal.module.css';

type ModalFooterProps = {
  onClose: () => void;
  onSave: () => void;
  isSaving?: boolean;
  disabled?: boolean;
};

const ModalFooter: React.FC<ModalFooterProps> = ({
  onClose,
  onSave,
  isSaving = false,
  disabled = false,
}) => {
  return (
    <div className={style.footer}>
      <Button btnStyle={ButtonStyle.LINK} onClick={onClose}>
        Cancel
      </Button>
      <Button btnStyle={ButtonStyle.PRIMARY} onClick={onSave} disabled={disabled || isSaving}>
        {isSaving ? inProgressLabel('Submitting') : 'Submit'}
      </Button>
    </div>
  );
};

type CreateNewVersionModalProps = {
  onClose: () => void;
};

const CreateNewVersionModal: React.FC<CreateNewVersionModalProps> = ({ onClose }) => {
  const [createVersion] = useCreateTermsOfServiceVersionMutation();

  const useFormMethods = useForm<CreateVersionValues>({
    mode: 'onSubmit',
  });

  const [versionIdentifierToPreview, setVersionIdentifierToPreview] =
    useState<Maybe<VersionIdentifier>>();
  const [versionAvailability, setVersionAvailability] =
    useState<VersionAvailability>('not-checked');

  const { show } = useSuccess();
  const [isSaving, setIsSaving] = useState(false);
  const onSubmit = async (params: CreateVersionValues) => {
    setIsSaving(true);
    await createVersion({
      variables: {
        input: {
          ..._.pick(params, 'notes', 'policyType'),
          // Temporary, remove when API inconsistency is resolved
          notificationRequired: params.requiresNotification,
          version: parseInt(params.version),
        },
      },
    });
    show(
      <>
        Version <Text semibold>{params.version}</Text> successfully added for{' '}
        <strong>{PolicyTypeDisplayNames[params.policyType]}</strong>
      </>,
    );
    setIsSaving(false);
    onClose();
  };

  return (
    <FullScreenModal
      onCloseClick={onClose}
      header={<h1>New version</h1>}
      footer={
        <ModalFooter
          onClose={onClose}
          onSave={useFormMethods.handleSubmit(onSubmit)}
          isSaving={isSaving}
          disabled={versionAvailability !== 'available'}
        />
      }
    >
      <Grid type={GridType.L} className={style.grid}>
        <Col flex={1}>
          <NewVersionForm
            {...useFormMethods}
            disabled={isSaving}
            setSelectedVersionIdentifier={setVersionIdentifierToPreview}
          />
        </Col>
        <Col flex={2} className={style.stretch}>
          {versionIdentifierToPreview ? (
            <PreviewNewVersion
              {...versionIdentifierToPreview}
              setVersionAvailability={setVersionAvailability}
              versionAvailability={versionAvailability}
            />
          ) : (
            <NoPreviewAvailable />
          )}
        </Col>
      </Grid>
    </FullScreenModal>
  );
};

export default CreateNewVersionModal;
