import React from 'react';
import Notifications from '../../../Notifications';
import { ViewerAuthorityGrant } from '../../../security/AuthorizationContext';
import AccountTemplate from '../templates/AccountTemplate';
import { lazyWithRetry } from '../../../lib';
import { AccountIdMatchParams } from './lib';

const RenderNotifications = lazyWithRetry<typeof Notifications>(
  () => import(/* webpackChunkName: "notifications" */ '../../../Notifications'),
);

const NotificationsPage: React.FC<AccountIdMatchParams> = ({
  match: {
    params: { accountId },
  },
}) => {
  return (
    <AccountTemplate requiredGrant={ViewerAuthorityGrant}>
      <RenderNotifications accountId={accountId} />
    </AccountTemplate>
  );
};

export default NotificationsPage;
