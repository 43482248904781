import React from 'react';
import clsx from 'clsx';
import { Placement } from '@popperjs/core';
import _ from 'lodash';
import Label, { BasicLabel, LabelProps } from '../Label';
import styles from '../styles.module.css'; // eslint-disable-line
import { CheckboxInput, CheckboxInputProps, Input, InputProps, PasswordInput } from '../Input';
import ClipboardCopy from '../ClipboardCopy';
import { TooltipContent } from '../../Tooltip';
import { RadioInput, RadioInputProps } from '../../formInputs/RadioInput';
import { FCC } from '../../../../utils/types';
import NativeSelect, { NativeSelectProps } from '../NativeSelect';
import FileUpload, { FileUploadProps } from '../FileUpload';

export const FieldError: React.FC<{ error: string }> = ({ error }) => (
  <div className={styles.fieldError}>{error}</div>
);

export type FieldProps = {
  id?: string;
  className?: string;
  errorMessage?: string;
  hidden?: boolean;
  tooltipContent?: TooltipContent;
  tooltipPlacement?: Placement;
} & Pick<LabelProps, 'label' | 'description'>;

type CommonPropKeys = 'id';

const splitProps = <T extends FieldProps>(
  props: T,
): [fieldProps: FieldProps, inputProps: Omit<T, Exclude<keyof FieldProps, CommonPropKeys>>] => {
  const {
    label,
    description,
    errorMessage,
    className,
    hidden,
    tooltipContent,
    tooltipPlacement,
    ...rest
  } = props;
  return [
    {
      id: rest.id,
      label,
      description,
      errorMessage,
      className,
      hidden,
      tooltipContent,
      tooltipPlacement,
    },
    rest,
  ];
};

export const Field: FCC<FieldProps> = ({
  id,
  label,
  description,
  children,
  errorMessage,
  className,
  hidden = false,
  tooltipContent,
  tooltipPlacement,
}) => {
  return (
    <div className={clsx({ [styles.hidden]: hidden }, className)}>
      {label || description ? (
        <Label
          htmlFor={id}
          description={description}
          label={label}
          tooltipContent={tooltipContent}
          tooltipPlacement={tooltipPlacement}
        />
      ) : null}
      <div className={'mod'}>
        {children}
        {errorMessage && <FieldError error={errorMessage} />}
      </div>
    </div>
  );
};

export type InputFieldProps = Omit<FieldProps, 'children'> &
  Omit<InputProps, 'className' | 'hasError'> & {
    tooltipContent?: TooltipContent;
    tooltipPlacement?: Placement;
  };

export const InputField: React.FC<InputFieldProps> = props => {
  const [fieldProps, inputProps] = splitProps(props);
  const { errorMessage } = fieldProps;
  return (
    <Field {...fieldProps}>
      <Input hasError={!!errorMessage} {...inputProps} />
    </Field>
  );
};

export type TextFieldProps = Omit<InputFieldProps, 'type'>;

export const TextField: React.FC<TextFieldProps> = props => {
  return <InputField type="text" {...props} />;
};

export const PasswordField: React.FC<TextFieldProps> = props => {
  const [fieldProps, inputProps] = splitProps(props);
  return (
    <Field {...fieldProps}>
      <PasswordInput {...inputProps} hasError={!!fieldProps.errorMessage} />
    </Field>
  );
};

export const TextFieldWithCopyButton: React.FC<TextFieldProps> = props => {
  return (
    <>
      <TextField {...props} />
      <ClipboardCopy value={props.value} />
    </>
  );
};

export type RadioGroupFieldProps = FieldProps & {
  name: string;
  options: Omit<RadioInputProps, 'onChecked'>[];
  onChange: (value: RadioInputProps['value']) => void;
};

export const RadioGroupField: React.FC<RadioGroupFieldProps> = ({
  name,
  options,
  onChange,
  ...fieldProps
}) => {
  return (
    <Field {...fieldProps}>
      {options.map((option, index) => (
        <RadioInput
          name={name}
          {...option}
          key={`${name}-${index}`}
          onChecked={() => onChange(option.value)}
          className="mvs"
        />
      ))}
    </Field>
  );
};

export type CheckboxFieldProps = Omit<FieldProps, 'children'> & CheckboxInputProps;

export const CheckboxField: React.FC<CheckboxFieldProps> = props => {
  const [fieldProps, inputProps] = splitProps(props);
  const { id, errorMessage, hidden, className, ...rest } = _.omit(
    fieldProps,
    'tooltipContent',
    'tooltipPlacement',
  );
  return (
    <div className={clsx({ [styles.hidden]: hidden }, className)}>
      <div className={clsx(styles.checkboxField, { [styles.mbs]: !!rest.description })}>
        <CheckboxInput {...inputProps} id={id} />
        {rest.label ? <BasicLabel htmlFor={id} {...rest} /> : null}
      </div>
      {rest.description && <div className={styles.checkboxDescription}>{rest.description}</div>}
      <div className={'mod'}>{errorMessage && <FieldError error={errorMessage} />}</div>
    </div>
  );
};

export type NativeSelectFieldProps = Omit<FieldProps, 'children'> &
  Omit<NativeSelectProps, 'className' | 'hasError'>;

export const NativeSelectField: FCC<NativeSelectFieldProps> = ({ children, ...props }) => {
  const [fieldProps, inputProps] = splitProps(props);
  const { errorMessage } = fieldProps;
  return (
    <Field {...fieldProps}>
      <NativeSelect {...inputProps} hasError={!!errorMessage}>
        {children}
      </NativeSelect>
    </Field>
  );
};

export type FileUploadFieldProps = Omit<FieldProps, 'children'> & FileUploadProps;
export const FileUploadField: React.FC<FileUploadFieldProps> = ({
  value,
  onChange,
  ...fieldProps
}) => {
  return (
    <Field {...fieldProps}>
      <FileUpload value={value} onChange={onChange} id={fieldProps.id} />
    </Field>
  );
};

export * from './InputAddToListField';
export * from './TextArea';
export * from './SelectField';
