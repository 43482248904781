import React, { useEffect, useRef } from 'react';
import { Maybe } from '@tellurian/ts-utils';
import useKeyboardShortcut from '../keyboardSupport/useKeyboardShortcut';
import { useInfo } from '../Toast/Toasts';
import RenderKeyCombo, { KeyComboSize } from '../keyboardSupport/RenderKeyCombo';
import { KeyboardShortcutSpec } from '../keyboardSupport/lib';
import { useAuthorizationContext } from './AuthorizationContext';

const KeyboardToggle: React.FC = () => {
  const { isGlobalAdminPermissionDisabled, setIsGlobalAdminPermissionDisabled } =
    useAuthorizationContext();

  const { show: showNotice, dismiss: dismissNotice } = useInfo(
    <div style={{ paddingBottom: 1 }}>
      Global admin privileges have been disabled locally. Press{' '}
      <RenderKeyCombo keyCombo={DisableAdminShortcutSpec.keyCombo} size={KeyComboSize.S} /> to
      re-enable.
    </div>,
    {
      // Require user to dismiss such that they acknowledge the key shortcut to get backs
      autoDismissAfterMs: undefined,
    },
  );

  const lastIsGlobalAdminPermissionDisabledStateRef = useRef<Maybe<boolean>>();

  useEffect(() => {
    const lastState = lastIsGlobalAdminPermissionDisabledStateRef.current;
    if (lastState !== undefined && lastState !== isGlobalAdminPermissionDisabled) {
      if (isGlobalAdminPermissionDisabled) {
        showNotice();
      } else {
        dismissNotice();
      }
    }
    lastIsGlobalAdminPermissionDisabledStateRef.current = isGlobalAdminPermissionDisabled;
  }, [isGlobalAdminPermissionDisabled, showNotice, dismissNotice]);

  useKeyboardShortcut(DisableAdminShortcutSpec, () =>
    setIsGlobalAdminPermissionDisabled(!isGlobalAdminPermissionDisabled),
  );

  return null;
};

export const DisableAdminShortcutSpec: KeyboardShortcutSpec = {
  keyCombo: { code: 'KeyA', shift: true, meta: true },
  description: 'Toggle global admin privileges',
};

const GlobalAdminPermissionKeyboardToggle: React.FC = () => {
  const { isGlobalAdminPermissionDisabled, getHasGlobalAdminPermission } =
    useAuthorizationContext();

  // Do not include the toggle if the user never had global admin permission
  // This implicitly prevents the shortcut from being displayed in the KeyboardShortcutHelp listing.
  return getHasGlobalAdminPermission() || isGlobalAdminPermissionDisabled ? (
    <KeyboardToggle />
  ) : null;
};

export default GlobalAdminPermissionKeyboardToggle;
