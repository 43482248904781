import React, { useEffect, useMemo, useRef } from 'react';
import useEventCallback from '../../utils/useEventCallback';
import { DefaultPopoverMaxHeight, DropdownReferenceType } from './useDropdown';
import { ReferenceProps } from './useDropdownListCommon';
import { deactivateDropdownOnBlurFactory, useOnSearch } from './MultiSelectDropdown/lib';

export const MaxPopoverHeight = DefaultPopoverMaxHeight;
export const SearchPanelHeight = 58;
export const FooterHeight = 41;

export type UseSelectCommonParams<T extends HTMLElement> = {
  isActive: boolean;
  setIsActive: (active: boolean) => void;
  backdrop?: boolean;
  getReferenceProps: <P extends DropdownReferenceType>(referenceType?: P) => ReferenceProps<T, P>;
  onSearch?: (query: string) => void;
};

const useSelectCommon = <T extends HTMLElement>({
  isActive,
  setIsActive,
  backdrop,
  onSearch,
  getReferenceProps,
}: UseSelectCommonParams<T>) => {
  const className = isActive && backdrop ? 'overBackdrop' : undefined;
  const referenceProps = getReferenceProps('toggle');
  const deactivatePopover = () => setIsActive(false);
  const popoverRef = useRef<HTMLElement>(null);
  const relatedElementRef = useRef<T>(null);

  const listStyle = useMemo(
    () => ({
      maxHeight: onSearch ? `calc(${MaxPopoverHeight} - ${SearchPanelHeight}px)` : MaxPopoverHeight,
    }),
    [onSearch],
  );

  const getPopoverElementBlurHandler = () =>
    deactivateDropdownOnBlurFactory(popoverRef, relatedElementRef, deactivatePopover);

  const { query, setQuery } = useOnSearch(onSearch);

  const onPopoverParentBlur = useEventCallback((e: React.FocusEvent) => {
    if (getPopoverElementBlurHandler()(e)) {
      referenceProps.onBlur?.();
      setQuery('');
    }
  });

  const onListMouseDown = useEventCallback(() => {
    if (relatedElementRef.current && document.activeElement !== relatedElementRef.current) {
      relatedElementRef.current.focus();
    }
  });

  useEffect(() => {
    if (!isActive) {
      setQuery('');
    }
  }, [isActive, setQuery]);

  return {
    className,
    query,
    setQuery,
    onPopoverParentBlur,
    popoverRef,
    referenceProps,
    onListMouseDown,
    getPopoverElementBlurHandler,
    relatedElementRef,
    listStyle,
  };
};

export default useSelectCommon;
