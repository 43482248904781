import React, { useRef } from 'react';
import clsx from 'clsx';
import { ReactComponent as IconSearch } from '../../../../assets/icons/lettuce/search.svg';
import { ReactComponent as IconClear } from '../../../../assets/icons/lettuce/cancel.svg';
import useEventCallback from '../../utils/useEventCallback';
import style from './NeutralSearchInputHeader.module.css';

type NeutralSearchInputProps = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'type'
>;

export const NeutralSearchInput: React.FC<NeutralSearchInputProps> = props => {
  return <input type="text" {...props} className={clsx(style.searchInput, props.className)} />;
};

type NeutralSearchInputHeaderProps = {
  onClearSearch?: () => void;
  clearSearchAriaLabel?: string;
} & NeutralSearchInputProps;

const NeutralSearchInputHeader: React.FC<NeutralSearchInputHeaderProps> = ({
  onClearSearch,
  clearSearchAriaLabel = 'Clear search',
  ...searchInputProps
}) => {
  const searchContainerRef = useRef<HTMLDivElement>(null);
  const focusInput = useEventCallback(() => {
    const parent = searchContainerRef.current;
    setTimeout(() => {
      parent?.querySelector('input')?.focus();
    });
  });

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div className={style.searchContainer} ref={searchContainerRef} onMouseDown={focusInput}>
      <div className={style.icon}>
        <IconSearch />
      </div>
      <div className={style.searchElement}>
        <NeutralSearchInput {...searchInputProps} />
      </div>
      {onClearSearch && (
        <button
          className={style.clearSearch}
          onClick={onClearSearch}
          aria-label={clearSearchAriaLabel}
          type="button"
        >
          <IconClear />
        </button>
      )}
    </div>
  );
};

export default NeutralSearchInputHeader;
