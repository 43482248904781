import React from 'react';
import { AnyObject } from '@tellurian/ts-utils';
import { RenderItemProps } from './ListOfItems';
import useScrollListItemIntoView from './useScrollListItemIntoView';
import { ActionableListProps, composeProps } from './lib';
import ListItem from './listItems/ListItem';

export type RenderActionableItemProps<T, P extends AnyObject = AnyObject> = RenderItemProps<
  T,
  P
> & {
  isActionable: boolean;
  isFocused: boolean;
  focusTransitionsEnabled: boolean;
};

export type ActionableListItemProps<T, P extends AnyObject> = ActionableListProps &
  RenderItemProps<T, P>;

function ActionableListItem<T, P extends AnyObject = AnyObject>(
  RenderItem: React.FC<RenderActionableItemProps<T, P>>,
  RenderListItem = ListItem,
): React.FC<ActionableListItemProps<T, P>> {
  return React.memo(function ActionableListItem({
    useFocusableItem,
    useActionableItem,
    focusTransitionsEnabled,
    item,
    index,
    id,
    ...rest
  }: ActionableListItemProps<T, P>) {
    const { isFocused, ...focusableProps } = useFocusableItem(index);
    const { isActionable, ...actionableProps } = useActionableItem(index);
    const itemProps = { isFocused, isActionable, focusTransitionsEnabled };

    return (
      <RenderListItem
        ref={useScrollListItemIntoView(isFocused)}
        {...composeProps(focusableProps, actionableProps)}
        {...itemProps}
        id={id}
        role="option"
      >
        {/* @ts-expect-error Will not use subtype of a different constraint */}
        <RenderItem item={item} index={index} {...itemProps} {...rest} />
      </RenderListItem>
    );
  });
}

export default ActionableListItem;
