import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { Maybe } from '@tellurian/ts-utils';
import { TermsOfServicePolicyType } from '../../generated/graphql';
import { CheckboxFormField, TextAreaFormField, TextFormField } from '../ui/form/FormFields';
import { isNumeric, numberGreaterThan, requiredField } from '../ui/form/validation';
import { PolicyTypeOptions } from '../AccountManager/AccountDetails/General/tos/lib';
import { VersionIdentifier } from '../TermsOfService/lib';
import SelectFormField from '../lettuce/components/formFields/SelectFormField';
import useTermsOfServiceVersions from './useTermsOfServiceVersions';
import { CreateVersionValues } from './lib';

type NewVersionFormProps = UseFormReturn<CreateVersionValues> & {
  disabled?: boolean;
  setSelectedVersionIdentifier: Dispatch<SetStateAction<Maybe<VersionIdentifier>>>;
};

const NewVersionForm: React.FC<NewVersionFormProps> = ({
  disabled = false,
  setSelectedVersionIdentifier,
  ...useFormMethods
}) => {
  const { getLatestVersion } = useTermsOfServiceVersions();
  const policyType: Maybe<TermsOfServicePolicyType> = useFormMethods.watch('policyType');
  const version: Maybe<string> = useFormMethods.watch('version');
  const { setValue, clearErrors, getValues } = useFormMethods;

  useEffect(() => {
    if (policyType) {
      const latestVersion = getLatestVersion(policyType)?.version ?? 0;
      setValue('version', String(latestVersion + 1));
      clearErrors();
    }
  }, [policyType, setValue, clearErrors, getLatestVersion, setSelectedVersionIdentifier]);

  useEffect(() => {
    if (version && isNumeric(version)) {
      setSelectedVersionIdentifier({
        policyType: getValues('policyType'),
        version: parseInt(version, 10),
      });
    }
  }, [version, setSelectedVersionIdentifier, getValues]);

  const latestVersion: Maybe<number> = useMemo(() => {
    return policyType ? (getLatestVersion(policyType)?.version ?? 0) : undefined;
  }, [getLatestVersion, policyType]);

  const versionValidation = useMemo(() => {
    if (typeof latestVersion === 'number') {
      return numberGreaterThan(
        latestVersion,
        `Please specify a version number greater than ${latestVersion}.`,
      );
    }
    return requiredField;
  }, [latestVersion]);

  const versionFieldDescription = useMemo(() => {
    if (typeof latestVersion === 'number') {
      return `Specify the new version number. Must be greater than the current version which is ${latestVersion}.`;
    }
    return `Specify the new version number. Must be greater than the current version`;
  }, [latestVersion]);

  return (
    <FormProvider {...useFormMethods}>
      <SelectFormField
        id="policyType"
        name="policyType"
        options={PolicyTypeOptions}
        label="Policy type"
        disabled={disabled}
        validate={requiredField}
        defaultValue=""
        description="Select the policy type the new version applies to."
      />
      <TextFormField
        name="version"
        label="Version"
        description={versionFieldDescription}
        disabled={disabled}
        type={'number'}
        validate={versionValidation}
      />
      <TextAreaFormField
        name="notes"
        disabled={disabled}
        label="Summary of changes"
        description="Provide a summary of changes to the terms of service in this version. This will be included in the email sent to customers."
        rows={3}
      />
      <CheckboxFormField
        name="requiresNotification"
        label="Notify customer policy admins of change"
        description="Un-check this box only if the changes to ToS do not require APA acknowledgement. For example, if a typo was corrected or the styling adjusted, but the substance of the document is unchanged."
        defaultValue={true}
      />
    </FormProvider>
  );
};

export default NewVersionForm;
