import React, { Fragment } from 'react';
import { Maybe } from '@tellurian/ts-utils';
import { ConnectorApplication } from '../../../../../../generated/graphql';
import { Spinner } from '../../../../../ui';
import { DashboardGroupsWithDashboardsByTier } from '../AllReports/useDashboardGroupsWithDashboardsByTier';
import HarmonizedDashboardGroup, {
  HarmonizedDashboardGroupProps,
} from './HarmonizedDashboardGroup';
import StandardDashboardGroup from './StandardDashboardGroup';

type ViewProps = {
  loading: boolean;
  dashboardGroupsWithDashboardsByTier: DashboardGroupsWithDashboardsByTier;
  getConnectorName: (application: ConnectorApplication) => Maybe<string>;
  getPath: (dashboardGroupId: string, dashboardId: string) => string;
} & Pick<
  HarmonizedDashboardGroupProps,
  'isReportFavorite' | 'addFavoriteReport' | 'removeFavoriteReport'
>;

const View: React.FC<ViewProps> = ({
  loading,
  dashboardGroupsWithDashboardsByTier,
  getConnectorName,
  getPath,
  ...harmonizedGroupProps
}) => {
  const groupCount = dashboardGroupsWithDashboardsByTier.length;
  return loading ? (
    <Spinner />
  ) : (
    <>
      {dashboardGroupsWithDashboardsByTier.map((group, index) => (
        <Fragment key={group.name}>
          {group.name === 'Harmonized' ? (
            <HarmonizedDashboardGroup
              dashboardGroup={group}
              getConnectorName={getConnectorName}
              getPath={getPath}
              key="dashboard-group-harmonized"
              {...harmonizedGroupProps}
            />
          ) : (
            <StandardDashboardGroup
              dashboardGroup={group}
              getPath={getPath}
              key={`dashboard-group-${group.id}`}
            />
          )}
          {index < groupCount - 1 && <hr />}
        </Fragment>
      ))}
    </>
  );
};

export default View;
