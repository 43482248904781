import React, { PropsWithoutRef, Ref, RefAttributes } from 'react';
import CheckboxOrRadio, { CheckboxOrRadioProps } from './CheckboxOrRadio';

export type CheckboxProps<T extends string = string> = Omit<CheckboxOrRadioProps<T>, 'type'>;

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(function Checkbox<
  T extends string = string,
>(props: CheckboxProps<T>, ref: Ref<HTMLInputElement>) {
  return <CheckboxOrRadio<T> type="checkbox" {...props} ref={ref} />;
}) as <T extends string = string>(
  props: PropsWithoutRef<CheckboxProps<T>> & RefAttributes<HTMLInputElement>,
) => React.ReactElement | null;

export default Checkbox;
