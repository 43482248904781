import { useCallback, useState } from 'react';
import firebase from 'firebase/compat/app';
import { Maybe } from '@tellurian/ts-utils';
import { Account, UserAccountPermission } from '../../generated/graphql';
import { azureLogout } from '../powerbiConnect/PowerBiDashboard/AzureAuthenticationContext';
import { trackGroup } from '../../utils/features';
import { trackError } from '../../utils/errorTracking';

type FirebaseUser = firebase.User;
type Unsubscribe = firebase.Unsubscribe;

export const isSignedIn = (user: FirebaseUser | null) => !!user;

export const addAuthenticationListener = (
  onAuthenticated: (user: FirebaseUser | null) => void,
): Unsubscribe => {
  return firebase.auth().onAuthStateChanged(user => {
    return onAuthenticated(user);
  });
};

export const logOut: () => Promise<void> = () => {
  azureLogout();
  return firebase.auth().signOut();
};

export type UserWithAccountPermissions = {
  accountPermissions: (Pick<UserAccountPermission, 'accountId' | 'accountRole'> & {
    account: Pick<Account, 'name'>;
  })[];
};

const trackGroupForUser = (user: UserWithAccountPermissions) => {
  try {
    user.accountPermissions.forEach(e => trackGroup(e.accountId, { name: e.account?.name }));
  } catch (err) {
    trackError(err as Error);
  }
};

export function useUserGroupTracking() {
  // This is purposefully created as a "veiled" state, that is internal state updates should not
  // trigger unnecessary re-renders.
  const [state, setState] = useState<{
    currentUser: Maybe<UserWithAccountPermissions>;
    isAnalyticsSetupComplete: boolean;
  }>(() => ({ currentUser: undefined, isAnalyticsSetupComplete: false }));

  const onAnalyticsSetupComplete = useCallback(() => {
    const { currentUser } = state;
    setState(currentState => Object.assign(currentState, { isAnalyticsSetupComplete: true }));

    if (currentUser) {
      trackGroupForUser(currentUser);
    }
  }, [state]);

  const onCurrentUserRetrieved = useCallback(
    (currentUser: UserWithAccountPermissions): void => {
      setState(currentState => Object.assign(currentState, { currentUser }));
      if (state.isAnalyticsSetupComplete) {
        trackGroupForUser(currentUser);
      }
    },
    [state],
  );

  return {
    onAnalyticsSetupComplete,
    onCurrentUserRetrieved,
  };
}

const permittedExternalURIPredicates: Array<(url: URL) => boolean> = [
  url => url.hostname === 'localhost',
  url =>
    url.hostname === window.location.hostname && url.pathname.startsWith('/unfi-insights-embedded'),
];

export const isPermittedExternalUri = uri => {
  try {
    const url = new URL(uri);
    return permittedExternalURIPredicates.some(fn => fn(url));
  } catch {
    return false;
  }
};
