import { useAuthorizationContext } from '../../../security/AuthorizationContext';

const useIsRemaAccountAdmin = () => {
  // Bit of a hack for REMA, we don't have a separate "REMA Admin" role in
  // the API but we want to expose the REMA Connect Admin link just for REMA
  // administrators. Due to time constraints we do it this way for now, but
  // at some point we might want to make this more generic. See
  // https://github.com/gocrisp/motiion-platform/pull/8694#discussion_r1083844714
  const remaAccountId = process.env.REACT_APP_REMA_ACCOUNT_ID!;
  return useAuthorizationContext().getHasAccountAdminPermission(remaAccountId);
};

export default useIsRemaAccountAdmin;
