import React from 'react';
import { ReactComponent as IconWarning } from '../../../../../assets/icons/lettuce/warning.svg';
import { ReactComponent as IconFailure } from '../../../../../assets/icons/lettuce/warning-triangle-filled.svg';
import { ReactComponent as IconSuccess } from '../../../../../assets/icons/lettuce/success.svg';
import { ReactComponent as IconInfo } from '../../../../../assets/icons/lettuce/info.svg';
import { NotificationType } from './lib';

const NotificationIcons: Readonly<Record<NotificationType, typeof IconInfo>> = {
  [NotificationType.Success]: IconSuccess,
  [NotificationType.Failure]: IconFailure,
  [NotificationType.Info]: IconInfo,
  [NotificationType.Warning]: IconWarning,
};

type NotificationIconProps = {
  notificationType: NotificationType;
};

const NotificationIcon: React.FC<NotificationIconProps> = ({ notificationType }) => {
  const Component = NotificationIcons[notificationType];
  return Component ? <Component /> : null;
};

export default NotificationIcon;
