import React, { useState } from 'react';
import { useGetAccountCountForAdminConsoleQuery } from '../../generated/graphql';
import useKeyboardShortcut from '../keyboardSupport/useKeyboardShortcut';
import { useAuthorizationContext } from '../security/AuthorizationContext';
import { supportsIndexedDb } from './lib';
import AdminPanel from './AdminPanel';
import useDataSync from './useDataSync';
import { useRecentAccountsTracker } from './useRecentAccounts';

const KeyboardShortcutSpec = {
  description: 'Toggle admin console',
  keyCombo: {
    meta: true,
    code: 'KeyK',
  },
};

const AdminConsole: React.FC = () => {
  const [isAdminPanelVisible, setIsAdminVisible] = useState(false);
  useDataSync();
  useRecentAccountsTracker();
  useKeyboardShortcut(KeyboardShortcutSpec, () => setIsAdminVisible(current => !current));
  return isAdminPanelVisible ? <AdminPanel onClose={() => setIsAdminVisible(false)} /> : null;
};

const MaybeAdminConsole: React.FC = props => {
  const { isSignedIn, getHasGlobalAdminPermission, firebaseUser } = useAuthorizationContext();
  const isGlobalAdmin = getHasGlobalAdminPermission();
  const { data } = useGetAccountCountForAdminConsoleQuery({
    skip: !isSignedIn || !firebaseUser || isGlobalAdmin,
  });
  const hasAtLeastTwoAccounts = (data?.accounts.totalCount ?? 0) > 1;
  return (isGlobalAdmin || hasAtLeastTwoAccounts) && supportsIndexedDb() ? (
    <AdminConsole {...props} />
  ) : null;
};

export default MaybeAdminConsole;
