import React, { ReactNode } from 'react';
import { ForbiddenTemplate } from '../httpErrors';

type Props = {
  resourceName?: string;
  description?: ReactNode;
};

const Forbidden: React.FC<Props> = ({ resourceName = 'Resource', description }) => {
  return (
    <ForbiddenTemplate>
      <h1>{resourceName} inaccessible</h1>
      <p>You don&apos;t have permissions to view this page.</p>
      <p>{description}</p>
    </ForbiddenTemplate>
  );
};

export default Forbidden;
