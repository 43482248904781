import React from 'react';

import styles from './index.module.css';

export function EmptyResult({
  children,
  className = '',
  heading = 'Nothing here.',
}: {
  children?: React.ReactNode;
  className?: string;
  heading?: string;
}) {
  return (
    <div className={`${styles.container} ${className}`}>
      <h3 className={styles.heading}>{heading}</h3>
      {children && <div className={styles.text}>{children}</div>}
    </div>
  );
}
