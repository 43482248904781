import React from 'react';
import { PageErrorCode } from '../../generated/graphql';
import { Button, ButtonStyle } from '../ui/Button';

const ContactUs = () => <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>contact us</a>;
const RefreshThePage = () => (
  <Button btnStyle={ButtonStyle.LINK} onClick={() => window.location.reload()}>
    refresh the page
  </Button>
);

export function ErrorWithMessage({ message }: { message: string }) {
  return <div>{message}</div>;
}

export function UnknownError() {
  return (
    <div>
      We were unable to process your request due to an unexpected error. Please <ContactUs /> if the
      problem persists.
    </div>
  );
}

export function NotFound() {
  return (
    <div>
      We&apos;re sorry. You are unexpectedly accessing a resource that does not exist. Please{' '}
      <ContactUs /> if you think this is an error.
    </div>
  );
}

export function ServerError() {
  return (
    <div>
      An unexpected error has occurred on our end. Please try again, and <ContactUs /> if the
      problem persists.
    </div>
  );
}

export function Forbidden() {
  return (
    <div>
      You are unexpectedly accessing a resource you do not have access to. Please try again and{' '}
      <ContactUs /> if the problem persists.
    </div>
  );
}

export function NetworkError() {
  return (
    <div>
      We are unable to communicate with the Crisp service. Check your Internet connection, try again
      and <ContactUs /> if the problem persists.
    </div>
  );
}

export function ServiceUnavailable() {
  return (
    <div>
      The service is temporarily unavailable. Please try again, <RefreshThePage /> and <ContactUs />{' '}
      if the problem persists.
    </div>
  );
}

export function SomeServicesUnavailable() {
  return (
    <div>
      Some services are temporarily unavailable. Please try again, <RefreshThePage /> and{' '}
      <ContactUs /> if the problem persists.
    </div>
  );
}

export const errorCodeByHttpStatus = (statusCode: number | undefined): PageErrorCode => {
  if (!statusCode) return PageErrorCode.UnknownError;
  if ([401, 403].includes(statusCode)) return PageErrorCode.Forbidden;
  if (statusCode === 404) return PageErrorCode.NotFound;
  if (statusCode === 500) return PageErrorCode.ServerError;
  if ([501, 502].includes(statusCode)) return PageErrorCode.ServiceUnavailable;
  return PageErrorCode.UnknownError;
};

const P = PageErrorCode;

export const ErrorMessageByErrorCode = Object.freeze({
  [P.UnknownError]: UnknownError,
  [P.NetworkError]: NetworkError,
  [P.NotFound]: NotFound,
  [P.ServerError]: ServerError,
  [P.Forbidden]: Forbidden,
  [P.ServiceUnavailable]: ServiceUnavailable,
  [P.FeatureFlagError]: SomeServicesUnavailable,
});

export const renderErrorForCode = (code: PageErrorCode) => {
  const Tag = ErrorMessageByErrorCode[code];
  if (Tag) return <Tag />;
  else return <UnknownError />;
};

export const renderErrorForHttpStatus = (statusCode: number | undefined): React.ReactNode =>
  renderErrorForCode(errorCodeByHttpStatus(statusCode)) || <UnknownError />;
