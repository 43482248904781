import { useMemo } from 'react';
import querystring, { StringifyOptions } from 'query-string';
import { useLocation } from 'react-router-dom';

export const QueryStringOptions = Object.freeze({ arrayFormat: 'comma' }) as StringifyOptions;

export const parseQuery = (query: string) => querystring.parse(query, QueryStringOptions);

type SupportedValue = number | string | null | undefined;

export const stringifyToQuery = (tar: Record<string, SupportedValue | SupportedValue[]>) =>
  querystring.stringify(tar, QueryStringOptions);

type QueryParams = Record<string, SupportedValue | SupportedValue[]>;
export const withQueryParams = (params: QueryParams) => (path: string) => {
  const q = stringifyToQuery(params);
  return q ? `${path}?${q}` : path;
};

/**
 * Returns a parsed url query based on our standard parsing rules (querystring + QueryStringOptions).
 * If the query is provided, then the parameter is used, otherwise it retrieves the 'search' member
 * from the current location.
 * The advantage of using this hook is that results are memoized, so subsequent calls will not
 * execute the parser for the same input.
 * @param query
 */
export function useParsedQuery<T extends QueryParams = QueryParams>(query?: string): T {
  const { search } = useLocation();

  const parsedFromParam = useMemo(
    () => (typeof query === 'string' ? parseQuery(query) : undefined),
    [query],
  );

  return useMemo(() => (parsedFromParam ?? parseQuery(search)) as T, [parsedFromParam, search]);
}
