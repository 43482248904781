import { useMemo } from 'react';
import {
  LookerBookmarkFilterInput,
  useUpdateLookerBookmarkMutation,
  useUpdateUnfiInsightsLookerBookmarkMutation,
} from '../../../../../generated/graphql';
import { useGetDashboardById } from '../../../../businessDashboard/hooks/useGetDashboardGroups';
import { isUnfiInsightsDashboard } from '../../../../lib';

type UpdateBookmarkFn = ({
  description,
  bookmarkId,
  filters,
}: {
  bookmarkId: string;
  description?: string;
  filters?: LookerBookmarkFilterInput[];
}) => void;

const useUpdateSavedReport = ({
  accountId,
  dashboardGroupId,
  dashboardId,
  onCompleted,
}: {
  accountId: string;
  dashboardGroupId: string;
  dashboardId: string;
  onCompleted: () => void;
}): [UpdateBookmarkFn, { loading: boolean }] => {
  const [updateBookmarkMutation, { loading: isSavingBookmark }] = useUpdateLookerBookmarkMutation({
    onCompleted,
  });

  const [updateUnfiBookmarkMutation, { loading: isSavingUnfiBookmark }] =
    useUpdateUnfiInsightsLookerBookmarkMutation({
      onCompleted,
    });

  const getDashboardById = useGetDashboardById(accountId);
  const isUnfiDashboard = useMemo(() => {
    const dashboard = getDashboardById({ dashboardGroupId, dashboardId });
    return isUnfiInsightsDashboard(dashboard);
  }, [getDashboardById, dashboardGroupId, dashboardId]);

  const updateBookmark: UpdateBookmarkFn = ({ bookmarkId, description, filters }) => {
    const input = { bookmarkId, description, filters };
    if (isUnfiDashboard) {
      updateUnfiBookmarkMutation({ variables: { input } });
    } else {
      updateBookmarkMutation({
        variables: {
          accountId,
          input,
        },
      });
    }
  };

  return [updateBookmark, { loading: isSavingBookmark || isSavingUnfiBookmark }];
};

export default useUpdateSavedReport;
