import React from 'react';
import { RouteComponentProps } from 'react-router';
import UnfiInsightsEmbeddedTemplate from '../templates/UnfiInsightsEmbeddedTemplate';
import { lazyWithRetry } from '../../../lib';
import PaymentDetails from '../Deductions/PaymentDetails';

type MatchParams = {
  paymentId: string;
};

const RenderPaymentDetails = lazyWithRetry<typeof PaymentDetails>(
  () => import(/* webpackChunkName: "unfiEmbeddedPaymentDetails" */ '../Deductions/PaymentDetails'),
);

export default function UnfiInsightsEmbeddedDeductionsPaymentDetailsPage({
  match: {
    params: { paymentId },
  },
}: RouteComponentProps<MatchParams>) {
  return (
    <UnfiInsightsEmbeddedTemplate>
      <RenderPaymentDetails paymentId={paymentId} />
    </UnfiInsightsEmbeddedTemplate>
  );
}
