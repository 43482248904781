import { Maybe } from '@tellurian/ts-utils';
import msalInstance from '../crisp/RetailAnalytics/auth/msalInstance';

export const getEmailForRetailAnalyticsUser = (): Maybe<string> => {
  return msalInstance.getAllAccounts()[0]?.username;
};

export const not =
  <T extends unknown[]>(predicate: (...args: T) => boolean) =>
  (...args: T) =>
    !predicate(...args);
