import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom';
import { useGetFloatingPortalNode } from './FloatingContext';

export type FloatingPortalProps = {
  children: ReactElement;
  useGetPortalNode?: () => HTMLElement;
};

const FloatingPortal: React.FC<FloatingPortalProps> = ({
  children,
  useGetPortalNode = useGetFloatingPortalNode,
}) => {
  return ReactDOM.createPortal(children, useGetPortalNode());
};

export default FloatingPortal;
