import { useCallback } from 'react';
import {
  DashboardForPermissionsFragment,
  DashboardFragment,
  DashboardGroupForPermissionsFragment,
  DashboardGroupFragment,
} from '../../../generated/graphql';
import { useIsDashboardAvailableForConnectorApplications } from './useIsDashboardAvailableForConnectorApplications';

export const useDashboardFilterForDashboardGroup = () => {
  const isDashboardAvailableForConnectorApplications =
    useIsDashboardAvailableForConnectorApplications();

  return useCallback(
    <
      T extends Pick<
        DashboardGroupFragment | DashboardGroupForPermissionsFragment,
        'id' | 'dashboards'
      >,
    >(
      dashboardGroup: T,
    ): T => {
      const dashboards = dashboardGroup.dashboards as Array<
        DashboardFragment | DashboardForPermissionsFragment
      >;
      return {
        ...dashboardGroup,
        dashboards: dashboards.filter(dashboard => {
          const connectorApplications = dashboard.relatedConnectorApplications ?? [];
          return isDashboardAvailableForConnectorApplications(
            dashboard.id,
            connectorApplications,
            dashboardGroup.id,
          );
        }),
      };
    },
    [isDashboardAvailableForConnectorApplications],
  );
};
