import React from 'react';
import UserAccountPermissions from '../UserAccountPermissions';
import GlobalAdminTemplate from '../templates/GlobalAdminTemplate';
import { lazyWithRetry } from '../../../lib';

const RenderUserAccountPermissions = lazyWithRetry<typeof UserAccountPermissions>(
  () => import(/* webpackChunkName: "userAccountPermissions" */ '../UserAccountPermissions'),
);

const UserAccountPermissionsPage: React.FC = () => {
  return (
    <GlobalAdminTemplate>
      <RenderUserAccountPermissions />
    </GlobalAdminTemplate>
  );
};

export default UserAccountPermissionsPage;
