import React, { useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';
import { FCC } from '../../utils/types';
import styles from './Backdrop.module.css';

type Props = {
  bgColor?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  onMouseDown: () => void;
  className?: string;
};

/**
 * Deprecated in Lettuce. Use lettuce/components/Backdrop instead.
 * Re deprecation, do not attach event listeners to the backdrop component, instead use `useOnClickAway` hook to dismiss
 * menus or modals. Also, we should not have to overwrite style to include a background color, use variants instead.
 */
export const Backdrop: FCC<Props> = ({
  bgColor = 'transparent',
  children = null,
  style,
  onMouseDown,
  className,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const handleMouseDown = ev => {
    if (ev.target === ref.current) {
      onMouseDown();
    }
  };

  // useOnKeyDown causing circular dependency here
  useEffect(() => {
    const onEsc = ev => ev.key === 'Escape' && onMouseDown();
    window.addEventListener('keydown', onEsc);
    return () => window.removeEventListener('keydown', onEsc);
  });

  const derivedStyle = useMemo(() => ({ backgroundColor: bgColor, ...style }), [style, bgColor]);

  return (
    <>
      {/* We are handling the ESC key with a listener on the window above */}
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        ref={ref}
        className={clsx(styles.main, className)}
        onMouseDown={handleMouseDown}
        style={derivedStyle}
        data-testid="backdrop"
        // Allows other floating elements rendered in a portal to compute the correct z-index
        // (these will be searching for the closest floating parent of the reference element)
        data-floating={true}
      >
        {children}
      </div>
    </>
  );
};
