import { ReactNode, useMemo } from 'react';
import { ReactComponent as IconCheck } from '../../assets/icons/icon_20x20_checkmark.svg';
import { ReactComponent as IconInfo } from '../../assets/icons/icon_20x20_info.svg';
import { ReactComponent as IconWarning } from '../../assets/icons/icon_20x20_warning.svg';
import { ToastOptions } from './lib';
import useToast, { UseToastResult } from './useToast';

export const ToastFlavourOptions = Object.freeze({
  Success: {
    Icon: IconCheck,
    highlight: '#49d29f',
    ariaLabel: 'Success',
  },
  Info: {
    Icon: IconInfo,
    highlight: '#69C5EC',
    ariaLabel: 'Info',
  },
  Warning: {
    Icon: IconWarning,
    highlight: '#FFCD03',
    ariaLabel: 'Warning',
  },
  Failure: {
    Icon: IconWarning,
    highlight: '#E87E96',
    ariaLabel: 'Failure',
  },
});

export const useWithOptions =
  (flavourOptions?: Partial<ToastOptions>) =>
  (content?: ReactNode, options?: Partial<ToastOptions>) =>
    // eslint-disable-next-line
    useToast(
      content,
      // eslint-disable-next-line
      useMemo(() => ({ ...flavourOptions, ...options }), [flavourOptions, options]),
    );

export type UseWithOptions = (
  content?: ReactNode,
  options?: Partial<ToastOptions>,
) => UseToastResult;

export const useSuccess: UseWithOptions = (...args) =>
  useWithOptions(ToastFlavourOptions.Success)(...args);

export const useInfo: UseWithOptions = (...args) =>
  useWithOptions(ToastFlavourOptions.Info)(...args);

export const useWarning: UseWithOptions = (...args) =>
  useWithOptions(ToastFlavourOptions.Warning)(...args);

export const useFailure: UseWithOptions = (...args) =>
  useWithOptions(ToastFlavourOptions.Failure)(...args);
