import React from 'react';
import { Spinner, SpinnerSize } from '../../../../ui';
import Text from '../../Text';
import style from './index.module.css';

const LoadingListItems: React.FC = () => (
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
  <div className={style.loading} onMouseDown={ev => ev.preventDefault()}>
    <Spinner size={SpinnerSize.SMALL} />
    <Text size="S" subtle={true}>
      Loading
    </Text>
  </div>
);

export default LoadingListItems;
