import { useEffect } from 'react';
import { disableBodyScroll, enableBodyScroll } from './lib';

let isBodyScrollDisabled = false;
const renderedModalIds: Set<string> = new Set();

const useModalDisableBodyScroll = (id: string) => {
  useEffect(() => {
    renderedModalIds.add(id);
    if (!isBodyScrollDisabled) {
      disableBodyScroll();
      isBodyScrollDisabled = true;
    }
    return () => {
      renderedModalIds.delete(id);
      if (renderedModalIds.size === 0) {
        enableBodyScroll();
        isBodyScrollDisabled = false;
      }
    };
  }, [id]);
};

export default useModalDisableBodyScroll;
