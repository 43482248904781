import React from 'react';
import clsx from 'clsx';
import { ReactComponent as IconCheckboxChecked } from '../../../../../assets/icons/lettuce/checkbox-checked.svg';
import { ReactComponent as IconCheckboxUnchecked } from '../../../../../assets/icons/lettuce/checkbox-unchecked.svg';
import style from './index.module.css'; // eslint-disable-line css-modules/no-unused-class

const CheckboxSizes = ['S', 'M'];
type CheckboxSize = (typeof CheckboxSizes)[number];

type CheckboxIconProps = {
  isChecked?: boolean;
  size?: CheckboxSize;
};

const CheckboxIcon: React.FC<CheckboxIconProps> = ({ isChecked = false, size }) => {
  // Do not include a size if one is not specified - uses case is when the icon should stretch the size of its contained
  const className = clsx(style.icon, { [style.checked]: isChecked }, size && style[size]);
  return isChecked ? (
    <IconCheckboxChecked className={className} />
  ) : (
    <IconCheckboxUnchecked className={className} />
  );
};

export default CheckboxIcon;
