import React, { Fragment, useState } from 'react';
import { Maybe } from '@tellurian/ts-utils';
import { PolicyVersionFragment, TermsOfServicePolicyType } from '../../generated/graphql';
import { Button, ButtonStyle, GenericBackLink, PageHeader, PageLayout } from '../ui';
import ListOfItems, { RenderItemProps } from '../ui/lists/listOfItems/ListOfItems';
import Text from '../ui/Text';
import useLastAccountId from '../../pageTemplates/useLastAccountId';
import { FnsFormat, useI18nContext } from '../../utils/I18nContext';
import TermsOfServiceVersionViewer from '../AccountManager/AccountDetails/General/tos/TermsOfServiceVersionViewer';
import { PolicyTypeDisplayNames } from '../TermsOfService/lib';
import { path } from '../lettuce/crisp/routing/lib';
import useTermsOfServiceVersions from './useTermsOfServiceVersions';
import CreateNewVersionModal from './CreateNewVersionModal';
import style from './index.module.css';

const { Layout, Content } = PageLayout;

type TermsOfServiceVersionListItem = PolicyVersionFragment & {
  onClick: () => void;
};

const RenderTermsOfServiceVersion: React.FC<RenderItemProps<TermsOfServiceVersionListItem>> = ({
  item: { publishedByUser, publishedAtTimestamp, version, onClick },
}) => {
  const { dateFormat } = useI18nContext();

  return (
    <li className={style.item}>
      <div>
        <Button btnStyle={ButtonStyle.LINK} onClick={onClick}>
          Version {version}
        </Button>
      </div>
      <div className={style.rhs}>
        <div>
          <Text semibold>
            {dateFormat(FnsFormat.DateYearShort)(new Date(publishedAtTimestamp))}
          </Text>
        </div>
        <div>
          <Text subtle className={style.publishedBy}>
            published by
          </Text>{' '}
          {publishedByUser
            ? (publishedByUser.displayName ?? publishedByUser.email)
            : '(User no longer exists)'}
        </div>
      </div>
    </li>
  );
};

const TermsOfServiceVersions: React.FC = () => {
  const accountId = useLastAccountId();
  const { versions, refetch } = useTermsOfServiceVersions();
  const [versionToView, setVersionToView] =
    useState<Maybe<{ policyType: TermsOfServicePolicyType; version: PolicyVersionFragment }>>();
  const [isVersionEditorVisible, setIsVersionEditorVisible] = useState(false);

  return (
    <div>
      {accountId && (
        <GenericBackLink to={path('AccountHome')({ accountId })}>Back home</GenericBackLink>
      )}
      <PageHeader
        heading="Terms of service"
        description="Manage terms of service versions for each policy type"
        className="mbn"
        action={{
          fn: () => setIsVersionEditorVisible(true),
          label: 'Create version',
        }}
      />
      <Layout>
        <Content>
          {Object.entries(versions).map(([policyType, versionArr]) => {
            return (
              <Fragment key={policyType}>
                <h3>{PolicyTypeDisplayNames[policyType]}</h3>
                {versionArr.length > 0 ? (
                  <ListOfItems<TermsOfServiceVersionListItem>
                    items={versionArr.map(v => ({
                      ...v,
                      onClick: () =>
                        setVersionToView({
                          policyType: policyType as TermsOfServicePolicyType,
                          version: v,
                        }),
                    }))}
                    RenderItem={RenderTermsOfServiceVersion}
                    getItemKey={item => `${policyType}-${item.version}`}
                  />
                ) : (
                  <p>
                    <Text subtle>No versions for this policy type.</Text>
                  </p>
                )}
              </Fragment>
            );
          })}
          {versionToView && (
            <TermsOfServiceVersionViewer
              {...versionToView}
              onClose={() => setVersionToView(undefined)}
            />
          )}
          {isVersionEditorVisible && (
            <CreateNewVersionModal
              onClose={() => {
                setIsVersionEditorVisible(false);
                refetch();
              }}
            />
          )}
        </Content>
      </Layout>
    </div>
  );
};

export default TermsOfServiceVersions;
