import React from 'react';

import { ReactComponent as IconKnowledgeBase } from '../../../../assets/icons/lettuce/unfi/knowledgebase.svg';
import { ReactComponent as IconAcademy } from '../../../../assets/icons/lettuce/unfi/academy.svg';
import { ReactComponent as IconUpdates } from '../../../../assets/icons/lettuce/unfi/updates.svg';
import { ReactComponent as IconDistributionAnalysis } from '../../../../assets/icons/lettuce/unfi/distribution-analysis.svg';
import { ReactComponent as IconDistribution } from '../../../../assets/icons/lettuce/unfi/distribution.svg';
import { ReactComponent as IconDeductions } from '../../../../assets/icons/lettuce/unfi/deductions.svg';
import { ReactComponent as IconSales } from '../../../../assets/icons/lettuce/unfi/sales.svg';
import { ReactComponent as IconSpoilageRisk } from '../../../../assets/icons/lettuce/unfi/spoilage-risk.svg';
import { ReactComponent as IconVelocity } from '../../../../assets/icons/lettuce/unfi/velocity.svg';
import { ReactComponent as IconYoyDistribution } from '../../../../assets/icons/lettuce/unfi/yoy-distribution.svg';
import { ReactComponent as IconYoySales } from '../../../../assets/icons/lettuce/unfi/yoy-sales.svg';
import { ReactComponent as IconYoyVelocity } from '../../../../assets/icons/lettuce/unfi/yoy-velocity.svg';
import { ReactComponent as IconCategoryPerformance } from '../../../../assets/icons/lettuce/unfi/category-performance.svg';
import { ReactComponent as IconChargebacks } from '../../../../assets/icons/lettuce/unfi/chargebacks.svg';
import { ReactComponent as IconFillRate } from '../../../../assets/icons/lettuce/unfi/fill-rate.svg';
import { ReactComponent as IconInventory } from '../../../../assets/icons/lettuce/unfi/inventory.svg';
import { ReactComponent as IconPotentialLostSales } from '../../../../assets/icons/lettuce/unfi/potential-lost-sales.svg';
import { ReactComponent as IconRetention } from '../../../../assets/icons/lettuce/unfi/retention.svg';
import { Resource } from '../../components/ResourceCards/lib';
import { ExternalLinkTarget } from '../../components/ExternalLink';

const {
  REACT_APP_UNFI_INSIGHTS_HELP_URL,
  REACT_APP_UNFI_INSIGHTS_ACADEMY_URL,
  REACT_APP_UNFI_INSIGHTS_UPDATES_URL,
} = process.env;

export const resources: Array<Resource> = [
  {
    title: 'Knowledge Base',
    subTitle: 'Dive into our detailed articles to solve your queries.',
    buttonText: 'Explore Now',
    url: REACT_APP_UNFI_INSIGHTS_HELP_URL,
    icon: <IconKnowledgeBase />,
    target: ExternalLinkTarget.knowledgeBase,
  },
  {
    title: 'Academy',
    subTitle: 'Master our product with comprehensive tutorials in our Academy.',
    buttonText: 'Start Learning',
    url: REACT_APP_UNFI_INSIGHTS_ACADEMY_URL,
    icon: <IconAcademy />,
    target: ExternalLinkTarget.academy,
  },
  {
    title: 'Recent Updates',
    subTitle: 'Stay up-to-date with our latest features and improvements.',
    buttonText: 'See Updates',
    url: REACT_APP_UNFI_INSIGHTS_UPDATES_URL,
    icon: <IconUpdates />,
    target: ExternalLinkTarget.knowledgeBase,
  },
];

export type DashboardCard = {
  Icon: React.FunctionComponent;
  title: string;
  subTitle: string;
  dashboardId: string;
  dashboardGroupId: string;
};

export const popularDashboardsNatural: DashboardCard[] = [
  {
    Icon: IconDistributionAnalysis,
    title: 'Distribution Expansion',
    subTitle: 'Identify opportunities for expansion based on your category performance.',
    dashboardId: 'unfi_insights_manual::unfi_insights_natural_distribution_expansion',
    dashboardGroupId: 'natural',
  },
  {
    Icon: IconDeductions,
    title: 'Deductions',
    subTitle: 'Track deductions by type and retailer to help you reduce costs.',
    dashboardId: 'unfi_insights_manual::dashboard_unfi_insights_natural_deductions',
    dashboardGroupId: 'natural',
  },
  {
    Icon: IconSpoilageRisk,
    title: 'Spoilage Risk',
    subTitle: 'Identify and take action on products at risk of expiring.',
    dashboardId: 'unfi_insights_manual::dashboard_unfi_insights_natural_spoilage_risk',
    dashboardGroupId: 'natural',
  },
  {
    Icon: IconYoyDistribution,
    title: 'YOY Distribution',
    subTitle: 'See how your distribution this year stacks up to last year.',
    dashboardId: 'unfi_insights::unfi_insights_yoy_distribution',
    dashboardGroupId: 'natural',
  },
  {
    Icon: IconYoySales,
    title: 'YOY Sales',
    subTitle: 'Compare sales to last year to discover what drives growth.',
    dashboardId: 'unfi_insights::unfi_insights_yoy_sales',
    dashboardGroupId: 'natural',
  },
  {
    Icon: IconYoyVelocity,
    title: 'YOY Velocity',
    subTitle: "Track how your product's success is changing year to year.",
    dashboardId: 'unfi_insights::unfi_insights_yoy_velocity',
    dashboardGroupId: 'natural',
  },
];

export const popularDashboardsConventional: DashboardCard[] = [
  {
    Icon: IconDistributionAnalysis,
    title: 'Distribution Expansion',
    subTitle: 'Identify opportunities for expansion based on your category performance.',
    dashboardId: 'unfi_insights_manual::unfi_insights_conventional_distribution_expansion',
    dashboardGroupId: 'conventional',
  },
  {
    Icon: IconDeductions,
    title: 'Deductions',
    subTitle: 'Track deductions by type and retailer to help you reduce costs.',
    dashboardId: 'unfi_insights_svharbor::unfi_insights_svharbor_deductions',
    dashboardGroupId: 'conventional',
  },
  {
    Icon: IconSpoilageRisk,
    title: 'Spoilage Risk',
    subTitle: 'Identify and take action on products at risk of expiring.',
    dashboardId: 'unfi_insights_manual::dashboard_unfi_insights_conventional_spoilage_risk',
    dashboardGroupId: 'conventional',
  },
  {
    Icon: IconDistribution,
    title: 'Distribution',
    subTitle: 'See how your distribution this year stacks up to last year.',
    dashboardId: 'unfi_insights_svharbor::unfi_insights_svharbor_distribution',
    dashboardGroupId: 'conventional',
  },
  {
    Icon: IconSales,
    title: 'Sales',
    subTitle: 'Evaluate your sales performance at any point in time.',
    dashboardId: 'unfi_insights_svharbor::unfi_insights_svharbor_sales',
    dashboardGroupId: 'conventional',
  },
  {
    Icon: IconVelocity,
    title: 'Velocity',
    subTitle: 'Track success (regardless of store count) for any time frame.',
    dashboardId: 'unfi_insights_svharbor::unfi_insights_svharbor_velocity',
    dashboardGroupId: 'conventional',
  },
];

export const dashboards: DashboardCard[] = [
  {
    Icon: IconPotentialLostSales,
    title: 'Potential Lost Sales',
    subTitle: '',
    dashboardId: 'unfi_insights::unfi_insights_potential_lost_sales',
    dashboardGroupId: 'natural',
  },
  {
    Icon: IconFillRate,
    title: 'Fill Rate',
    subTitle: '',
    dashboardId: 'unfi_insights::unfi_insights_fill_rate',
    dashboardGroupId: 'natural',
  },
  {
    Icon: IconRetention,
    title: 'Retention',
    subTitle: '',
    dashboardId: 'unfi_insights::unfi_insights_retention',
    dashboardGroupId: 'natural',
  },
  {
    Icon: IconChargebacks,
    title: 'Chargebacks',
    subTitle: '',
    dashboardId: 'unfi_insights::unfi_insights_chargebacks_v2',
    dashboardGroupId: 'natural',
  },
  {
    Icon: IconDistributionAnalysis,
    title: 'Distribution Center Inventory',
    subTitle: '',
    dashboardId: 'unfi_insights::unfi_insights_inventory_distribution_center',
    dashboardGroupId: 'natural',
  },
  {
    Icon: IconCategoryPerformance,
    title: 'Category Performance',
    subTitle: '',
    dashboardId: 'unfi_insights_manual::unfi_insights_natural_category_performance',
    dashboardGroupId: 'natural',
  },
  {
    Icon: IconYoyDistribution,
    title: 'YOY Distribution',
    subTitle: '',
    dashboardId: 'unfi_insights_svharbor::unfi_insights_svharbor_yoy_distribution',
    dashboardGroupId: 'conventional',
  },
  {
    Icon: IconYoySales,
    title: 'YOY Sales',
    subTitle: '',
    dashboardId: 'unfi_insights_svharbor::unfi_insights_svharbor_yoy_sales',
    dashboardGroupId: 'conventional',
  },
  {
    Icon: IconYoyVelocity,
    title: 'YOY Velocity',
    subTitle: '',
    dashboardId: 'unfi_insights_svharbor::unfi_insights_svharbor_yoy_velocity',
    dashboardGroupId: 'conventional',
  },
  {
    Icon: IconCategoryPerformance,
    title: 'Category Performance',
    subTitle: '',
    dashboardId: 'unfi_insights_manual::unfi_insights_conventional_category_performance',
    dashboardGroupId: 'conventional',
  },
  {
    Icon: IconDistributionAnalysis,
    title: 'Distribution Expansion',
    subTitle: '',
    dashboardId: 'unfi_insights_manual::unfi_insights_conventional_distribution_expansion',
    dashboardGroupId: 'conventional',
  },
  {
    Icon: IconInventory,
    title: 'Distribution Center Inventory',
    subTitle: '',
    dashboardId: 'unfi_insights_svharbor::unfi_insights_svharbor_inventory_distribution_center',
    dashboardGroupId: 'conventional',
  },
  {
    Icon: IconPotentialLostSales,
    title: 'Potential Lost Sales',
    subTitle: '',
    dashboardId: 'unfi_insights::unfi_insights_potential_lost_sales',
    dashboardGroupId: 'natural',
  },
  ...popularDashboardsConventional,
  ...popularDashboardsNatural,
];
