import React, { PropsWithChildren } from 'react';
import { ReactComponent as IconEllipsisVertical } from '../../../../assets/icons/lettuce/ellipsis-vertical.svg';
import ContextButton, { ContextButtonProps } from '../lib/ContextButton';

type ThreeDotButtonProps = Omit<ContextButtonProps, 'Icon'>;

const ThreeDotButton = React.forwardRef<HTMLButtonElement, PropsWithChildren<ThreeDotButtonProps>>(
  function ThreeDotButton({ srOnly = 'Toggle item menu', ...buttonProps }, ref) {
    return <ContextButton srOnly={srOnly} Icon={IconEllipsisVertical} {...buttonProps} ref={ref} />;
  },
);

export default ThreeDotButton;
