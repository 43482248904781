import React, { useContext } from 'react';
import { noop } from '@tellurian/ts-utils';
import { FCC } from '../../../../../utils/types';
import { NotificationSpec } from './lib';
import useNotificationContextValue, {
  NotificationContextInterface,
} from './useNotificationContextValue';

export const ToastNotificationContext = React.createContext<NotificationContextInterface>({
  useNotification: () => ({ show: noop, dismiss: () => false }),
  setDispatcher: noop,
});

export const ToastNotificationContextProvider: FCC = ({ children }) => {
  return (
    <ToastNotificationContext.Provider value={useNotificationContextValue()}>
      {children}
    </ToastNotificationContext.Provider>
  );
};

export const useToastNotificationContext = () => useContext(ToastNotificationContext);
export const useToastNotification = (spec: NotificationSpec, dismissOnUnmount = false) =>
  useToastNotificationContext().useNotification(spec, dismissOnUnmount);
