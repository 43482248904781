import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { LocalStorage, LocalStorageSpec } from './index';

type UseLocalStorageState<T extends keyof LocalStorageSpec> = [
  state: LocalStorageSpec[T],
  setState: Dispatch<SetStateAction<LocalStorageSpec[T]>>,
  refetch: () => void,
];

/**
 * Hook which provides a React state variable (and update function) that is persisted in LocalStorage.
 * State updates are always synchronised - if local storage write fails, then the state is not updated.
 * Return tuple is similar to that of the `useState` hook, with the addition of a `refetch` function
 * which restores this state based on the value persisted in local storage. This is useful
 * when more than one procedure updates the same state (local storage bucket).
 *
 * The typical use case for this hook is for states which persists across session,
 * whose updates should trigger a rerender.
 *
 * NOTE: Initial state is only used if there is no local storage persisted value for the given key.
 * The initial state is never stored to local storage, this is assumed as default and as such need not
 * be persisted.
 * @param key
 * @param initialValue
 */
const useLocalStorageState = <T extends keyof LocalStorageSpec>(
  key: T,
  initialValue: LocalStorageSpec[T],
): UseLocalStorageState<T> => {
  const [state, setState] = useState<LocalStorageSpec[T]>(
    LocalStorage.getItemOrDefault(key, initialValue),
  );

  const setLocalStorageState: typeof setState = useCallback(
    param => {
      if (typeof param === 'function') {
        setState(current => {
          const nextState = param(current);
          return LocalStorage.setItem(key, nextState) ? nextState : current;
        });
      } else if (LocalStorage.setItem(key, param)) {
        setState(param);
      }
    },
    [setState, key],
  );

  const refetchFromLocalStorage = useCallback(() => {
    const result = LocalStorage.findItem(key);
    if (result.hasValue) {
      setState(result.value);
    }
  }, [setState, key]);

  return [state, setLocalStorageState, refetchFromLocalStorage];
};

export default useLocalStorageState;
