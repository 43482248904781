import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { FCC } from '../../../../utils/types';
import { RequireGlobalAdmin } from '../../../security/RequireGlobalAdmin';
import NavRailPageTemplate from '../../components/NavRailPageTemplate';
import NavRailMenu, { NavRailMenuProps } from './NavRailMenu';
import PageSpecificNavigationContextProvider from './PageSpecificNavigationContext';

type PageTemplateProps = {
  requireGlobalAdmin?: boolean;
};

const PageTemplate: FCC<PageTemplateProps> = ({ children, requireGlobalAdmin = false }) => {
  const {
    params: { accountId },
  } = useRouteMatch<{ accountId?: string }>();

  return (
    <NavRailPageTemplate<NavRailMenuProps>
      RenderNavRail={NavRailMenu}
      accountId={accountId}
      isInitiallyReady={false}
      SubsidiaryContext={PageSpecificNavigationContextProvider}
    >
      <RequireGlobalAdmin requireGlobalAdmin={requireGlobalAdmin}>{children}</RequireGlobalAdmin>
    </NavRailPageTemplate>
  );
};

export default PageTemplate;
