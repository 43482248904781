import React, { useMemo } from 'react';
import { Maybe } from '@tellurian/ts-utils';
import { UserAccountRole, UserFragment } from '../../../../generated/graphql';
import { ReactComponent as IconDelete } from '../../../../assets/icons/lettuce/delete.svg';
import { ReactComponent as IconEdit } from '../../../../assets/icons/lettuce/edit.svg';
import { ReactComponent as IconCopy } from '../../../../assets/icons/lettuce/copy.svg';
import { ActionMenuItemSpec } from '../../components/Menu/lib';
import Label from '../../components/Label';
import GenericUserItem from './GenericUserItem';
import { UserRoleLabel } from './lib';

export type UserItemProps = UserFragment & {
  isCurrentUser: boolean;
  onChangePermissions: () => void;
  onDelete: () => void;
  getUserAccountRole: () => Maybe<UserAccountRole>;
  onCopyEmail?: () => void;
};

const UserItem: React.FC<UserItemProps> = ({
  onDelete,
  onChangePermissions,
  isCurrentUser,
  getUserAccountRole,
  onCopyEmail,
  ...rest
}) => {
  const actions: ActionMenuItemSpec[] = useMemo(
    () =>
      [
        {
          Icon: IconEdit,
          label: 'Edit permissions',
          onToggle: onChangePermissions,
        },
        !isCurrentUser && {
          Icon: IconDelete,
          label: 'Delete',
          onToggle: onDelete,
        },
        {
          Icon: IconCopy,
          label: 'Copy email',
          onToggle: onCopyEmail,
        },
      ].filter(Boolean) as ActionMenuItemSpec[],
    [onDelete, onChangePermissions, isCurrentUser, onCopyEmail],
  );

  const userAccountRole = getUserAccountRole();

  return (
    <GenericUserItem
      {...rest}
      labels={userAccountRole && <Label text={UserRoleLabel[userAccountRole]} />}
      actions={actions}
    />
  );
};

export default UserItem;
