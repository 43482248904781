import { useCallback, useEffect } from 'react';
import _ from 'lodash';
import { UseListOfItemsActionable } from '../../../ui/lists/listOfItems/useListOfItemsActionable';
import { composeProps } from '../../../ui/lists/listOfItems/lib';
import {
  FocusableItemFocusParentProps,
  FocusParentProps,
} from '../../../ui/lists/listOfItems/core/useFocusableItemIndex';
import { KeyHandlerEventProps, OnKeyDownHandler } from '../../../ui/lists/getListKeyHandler';
import { DropdownReferenceType, UseDropdown } from './useDropdown';

export type ReferenceProps<T extends HTMLElement, P extends DropdownReferenceType> = ReturnType<
  UseDropdown<T>['getReferenceProps']
> &
  (P extends 'toggle'
    ? Omit<FocusableItemFocusParentProps, 'focus'>
    : FocusableItemFocusParentProps);

type UseDropdownListCommonParams = Pick<
  UseListOfItemsActionable<unknown>,
  'setFocusedIndex' | 'focusTransitionsEnabled' | 'getFocusParentProps'
> &
  Pick<UseDropdown, 'setIsActive' | 'isActive' | 'getReferenceProps'> & {
    onKeyDownWhenActive: OnKeyDownHandler;
    onKeyDownWhenInactive: OnKeyDownHandler;
    deactivateOnFocusTransitionsDisabled?: boolean;
  };

const useDropdownListCommon = <R extends HTMLElement>({
  isActive,
  setIsActive,
  setFocusedIndex,
  focusTransitionsEnabled,
  onKeyDownWhenActive,
  onKeyDownWhenInactive,
  getReferenceProps,
  getFocusParentProps,
  deactivateOnFocusTransitionsDisabled = true,
}: UseDropdownListCommonParams) => {
  useEffect(() => {
    if (isActive) {
      setFocusedIndex(0);
    }
  }, [isActive, setFocusedIndex]);
  useEffect(() => {
    // Close dropdown if the focus parent (event responder) is no longer focused
    if (!focusTransitionsEnabled && deactivateOnFocusTransitionsDisabled) {
      setIsActive(false);
    }
  }, [focusTransitionsEnabled, setIsActive, deactivateOnFocusTransitionsDisabled]);

  const getComposedReferenceProps = useCallback(
    <P extends DropdownReferenceType>(referenceType?: P) => {
      const refType: DropdownReferenceType = referenceType || 'toggle';
      return (props =>
        isActive
          ? composeProps<FocusParentProps>({ onKeyDown: onKeyDownWhenActive }, props)
          : {
              ...props,
              onKeyDown: onKeyDownWhenInactive,
            })({
        ...getReferenceProps(refType),
        ...(refType === 'toggle' ? _.omit(getFocusParentProps(), 'focus') : getFocusParentProps()),
      }) as ReferenceProps<R, P>;
    },
    [getReferenceProps, getFocusParentProps, isActive, onKeyDownWhenActive, onKeyDownWhenInactive],
  );

  return {
    getComposedReferenceProps,
  };
};

export default useDropdownListCommon;

export const maybeStopKeyDownEventPropagation =
  (stopPropagation: boolean | ((isActive: boolean) => boolean)) =>
  (isActive: boolean, event: KeyHandlerEventProps): boolean => {
    const shouldStopPropagation =
      typeof stopPropagation === 'function' ? stopPropagation(isActive) : stopPropagation;
    shouldStopPropagation && event.stopPropagation();
    return shouldStopPropagation;
  };
