import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { MemoryStorage, MemoryStorageKey, MemoryStorageSpec } from './index';

type UsePersistentState<T extends MemoryStorageKey> = [
  state: MemoryStorageSpec[T],
  setState: Dispatch<SetStateAction<MemoryStorageSpec[T]>>,
];

/**
 * The purpose of this hook is to allow a component state to persist after the component is
 * removed, such that it can be re-used on later renders of the respective component.
 *
 * Internally, it uses an in memory map to house the state values, such that states are not
 * persisted across sessions or page refreshes for instance. Indeed, the intention is to
 * allow a level of persistence that is between the transience of react component state and
 * that of local storage.
 *
 * Note: while multiple components can access the same persisted bucket, the hook is not
 * intended to be used as a mechanism for sharing state between components. State updates
 * are not propagated from one component to another, even if they use the same MemoryStorage key.
 * @param key
 * @param initialValue
 */
const usePersistentState = <T extends MemoryStorageKey>(
  key: T,
  initialValue: MemoryStorageSpec[T],
): UsePersistentState<T> => {
  const [state, setState] = useState(MemoryStorage.getItemOrDefault(key, initialValue));

  const setMemoryStorageState: typeof setState = useCallback(
    param => {
      if (typeof param === 'function') {
        setState(current => {
          const nextState = param(current);
          return MemoryStorage.setItem(key, nextState) ? nextState : current;
        });
      } else if (MemoryStorage.setItem(key, param)) {
        setState(param);
      }
    },
    [setState, key],
  );

  return [state, setMemoryStorageState];
};

export default usePersistentState;
