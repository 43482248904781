import React, { useEffect, useState } from 'react';
import { noop } from '@tellurian/ts-utils';
import { FCC } from '../../../../utils/types';
import { useZendeskWidgetContext } from '../../../zendeskWidget/Context';
import useEventCallback from '../../utils/useEventCallback';

export type HelpUrlContextInterface = {
  helpUrl?: string;
  pushHelpUrl: (helpLink: string) => void;
  popHelpUrl: () => void;
};

export const HelpUrlContext = React.createContext<HelpUrlContextInterface>({
  helpUrl: undefined,
  pushHelpUrl: noop,
  popHelpUrl: noop,
});

export const HelpLinkContextProvider: FCC = ({ children }) => {
  const { helpLink: baseHelpUrl } = useZendeskWidgetContext();
  const [helpLinkStack, setHelpLinkStack] = useState<string[]>([]);

  const pushHelpUrl = useEventCallback((link: string) => {
    setHelpLinkStack(current => [...current, link]);
  });
  const popHelpUrl = useEventCallback(() => {
    setHelpLinkStack(current => {
      const next = [...current];
      next.pop();
      return next;
    });
  });

  const top = helpLinkStack[helpLinkStack.length - 1];

  return (
    <HelpUrlContext.Provider value={{ helpUrl: top ?? baseHelpUrl, popHelpUrl, pushHelpUrl }}>
      {children}
    </HelpUrlContext.Provider>
  );
};

export const useHelpUrlContext = () => React.useContext(HelpUrlContext);
export const useHelpUrl = (helpUrl?: string) => {
  const { helpUrl: currentUrl, pushHelpUrl, popHelpUrl } = useHelpUrlContext();
  useEffect(() => {
    if (helpUrl) {
      pushHelpUrl(helpUrl);
      return popHelpUrl;
    }
  }, [pushHelpUrl, popHelpUrl, helpUrl]);

  return currentUrl;
};
