import React, { ReactNode, SVGProps } from 'react';
import clsx from 'clsx';
import { useIsMaxSmallScreen } from '../useResponsive';
import Tooltip, { TooltipProps, Trigger } from '.';
import styles from './IconTooltip.module.css';

export type IconTooltipProps = {
  RenderIcon: React.FunctionComponent<SVGProps<SVGSVGElement>>;
  iconWidth?: number;
  trigger?: Trigger | Exclude<Trigger, Trigger.NONE>[];
  ariaHidden?: boolean;
  buttonClassName?: string;
  content: ReactNode;
} & Omit<TooltipProps, 'children' | 'content'>;

export default function IconTooltip({
  RenderIcon,
  iconWidth = 16,
  trigger,
  ariaHidden,
  content,
  buttonClassName,
  ...rest
}: IconTooltipProps) {
  const isSmallScreen = useIsMaxSmallScreen();

  const defaultTrigger = isSmallScreen ? Trigger.CLICK : Trigger.HOVER;

  const wrappedContent = <div className={styles.tooltipContent}>{content}</div>;

  return (
    <Tooltip trigger={trigger ? trigger : defaultTrigger} content={wrappedContent} {...rest}>
      {ref => (
        <button
          ref={ref}
          type="button"
          className={clsx(styles.buttonWrapper, buttonClassName)}
          aria-hidden={ariaHidden}
        >
          {<RenderIcon width={iconWidth} />}
        </button>
      )}
    </Tooltip>
  );
}
