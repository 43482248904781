import React from 'react';
import { Feature } from '../../../../utils/features';
import { KeyboardShortcut } from '../../../keyboardSupport/lib';
import { ExternalLinkTarget } from '../ExternalLink';

export type MenuItemSpecBase = {
  id?: string;
  label: string;
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  featureId?: Feature;
};

type LinkMenuItemSpec = MenuItemSpecBase & { path: string };
type ExternalLinkMenuItemSpec = MenuItemSpecBase & { href: string; target?: ExternalLinkTarget };

export type ActionMenuItemSpec = MenuItemSpecBase & {
  onToggle: () => void;
};

export type KeyboardShortcutMenuItemSpec = Pick<MenuItemSpecBase, 'Icon' | 'featureId'> & {
  keyboardShortcut: KeyboardShortcut;
};

export const Separator = { separator: true } as const;

export type MenuItemSpec =
  | LinkMenuItemSpec
  | ExternalLinkMenuItemSpec
  | ActionMenuItemSpec
  | KeyboardShortcutMenuItemSpec
  | typeof Separator;

export const splitIntoGroups = (items: MenuItemSpec[]): MenuItemSpec[][] => {
  const res: MenuItemSpec[][] = [];
  for (const item of items) {
    if (!res.length) {
      res.push([]);
    }

    const lastGroup = res[res.length - 1];
    if ('separator' in item) {
      if (lastGroup.length) {
        res.push([]);
      }
    } else {
      lastGroup.push(item);
    }
  }

  return res;
};
