import React from 'react';
import Forbidden from '../../components/routing/Forbidden';
import { PartnerAccountIdParams } from './index';

export type RequirePartnerGrantProps = PartnerAccountIdParams & {
  children?: React.ReactNode;
  RenderForbidden?: React.FC;
};

const RequirePartnerGrant: React.FC<RequirePartnerGrantProps> = ({
  RenderForbidden = Forbidden,
  children,
}) => {
  // TODO Get user authorization when API includes it
  const hasPermission = true;

  return hasPermission ? <>{children}</> : <RenderForbidden />;
};

export default RequirePartnerGrant;
