/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { AuthProvider } from '../../../../generated/graphql';
import logger from '../../../../services/logger';

const AUTH_CONFIG_ENDPOINT = `${process.env.REACT_APP_REST_API_URL}/api/v1/sso/provider_config`;

type UseGetAuthConfigurationParams = {
  providerId?: string;
  subdomain: string;
};

export const useGetAuthConfiguration = ({
  providerId = '',
  subdomain,
}: UseGetAuthConfigurationParams) => {
  const [authConfiguration, setAuthConfiguration] = useState<AuthProvider>();
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetch(`${AUTH_CONFIG_ENDPOINT}?subdomain=${subdomain}`)
      .then(response => {
        if (response.ok) {
          response.json().then(data => {
            data.filter((config: AuthProvider) => config.enabled === true);
            if (providerId) {
              data.filter((config: AuthProvider) => config.providerId === providerId);
            }
            setAuthConfiguration(data[0]);
          });
        }
      })
      .catch(error => {
        logger.error('Error fetching auth configuration', error);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [providerId, subdomain]);

  return {
    authConfiguration,
    error,
    loading,
  };
};
