import React, { ReactElement } from 'react';

type ReactChildArray = ReturnType<typeof React.Children.toArray>;

const flattenChildren = (children: React.ReactNode): ReactChildArray => {
  const childrenArray = React.Children.toArray(children);
  return childrenArray.reduce((flatChildren: ReactChildArray, child) => {
    if (child['type'] === React.Fragment) {
      return flatChildren.concat(flattenChildren((child as ReactElement).props.children));
    }
    flatChildren.push(child);
    return flatChildren;
  }, []);
};
export default flattenChildren;
