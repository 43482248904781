import React, { useContext } from 'react';
import { AccountInfo } from '@azure/msal-browser';
import { UserClaims } from '@okta/okta-auth-js';
import { Maybe } from '@tellurian/ts-utils';
import firebase from 'firebase/compat/app';
import { AuthProvider } from '../../../../generated/graphql';
import { AuthError, Nonce, AuthState as PartnerAuthState } from './lib';
import { useSignIn } from './useSignIn';

type PartnerAuthContextInterface = {
  state: PartnerAuthState;
  authProvider: AuthProvider | null;
  azureUser: AccountInfo | null;
  oktaUser: UserClaims | undefined;
  accessToken: string | undefined;
  nonce: Maybe<Nonce>;
  firebaseUser: firebase.User | null;
  error?: AuthError;
  linkUsers: () => void;
  continueWithPartnerUser: () => void;
  signInWithRedirect: () => void;
};

const PartnerAuthContext = React.createContext<PartnerAuthContextInterface>({
  state: PartnerAuthState.Loading,
  authProvider: null,
  azureUser: null,
  oktaUser: undefined,
  accessToken: undefined,
  nonce: undefined,
  firebaseUser: {} as firebase.User,
  error: {} as AuthError,
  linkUsers: () => undefined,
  continueWithPartnerUser: () => undefined,
  signInWithRedirect: () => undefined,
});

export const usePartnerAuthContext = () => useContext(PartnerAuthContext);

export const PartnerAuthProvider = ({
  config,
  children,
}: {
  config: AuthProvider;
  children: React.ReactNode;
}) => {
  const signInContext = useSignIn({ config });

  return (
    <PartnerAuthContext.Provider value={signInContext}>{children}</PartnerAuthContext.Provider>
  );
};
