import React from 'react';
import clsx from 'clsx';
import { FCC } from '../../../../utils/types';
import FloatingPortal from '../lib/FloatingPortal';
import Backdrop from '../Backdrop';
import { useDisableBodyScroll } from '../../utils/toggleBodyScroll';
import { UsePopoverSubMenuReturn } from './usePopoverSubMenu';
import { useIncludeBackdrop } from './BackdropPredicateProvider';
import style from './SubMenuPopover.module.css'; // eslint-disable-line

export enum SubMenuType {
  Compact = 'compact',
  Expansive = 'expansive',
}

export type SubMenuPopoverProps = UsePopoverSubMenuReturn['popoverProps'] & {
  type?: SubMenuType;
};

const SubMenuPopover: FCC<SubMenuPopoverProps> = ({
  setPopoverElement,
  setArrowElement,
  styles,
  attributes,
  children,
  id,
  type = SubMenuType.Compact,
  ariaLabel,
}) => {
  const includeBackdrop = useIncludeBackdrop();
  // Disable body scroll on large submenus which behave more like modal dialogs
  useDisableBodyScroll(type === SubMenuType.Expansive);
  return (
    <>
      {includeBackdrop && <Backdrop variant="transparent" className={style.backdrop} />}
      <FloatingPortal>
        <div
          id={id}
          ref={setPopoverElement}
          style={styles.popper}
          {...attributes.popper}
          className={clsx(style.container, style[type])}
          role="menu"
          data-floating={true}
          aria-label={ariaLabel}
        >
          <div className={style.contentWrapper}>{children}</div>
          <div ref={setArrowElement} style={styles.arrow} className={style.arrow} />
        </div>
      </FloatingPortal>
    </>
  );
};

export default SubMenuPopover;
