import React, { useCallback, useMemo, useRef } from 'react';
import clsx from 'clsx';
import { CSSTransition } from 'react-transition-group';
import { ReactComponent as IconClose } from '../../assets/icons/close.svg';
import { ToastDetails, ToastState } from './lib';
import animationStyles from './ToastAnimations.module.css'; // eslint-disable-line
import styles from './Toast.module.css';

type Props = {
  notification: ToastDetails;
};

export const TOAST_ANIMATION_DURATION = 300; // ms

const getNextId = (
  (start = 0) =>
  () => {
    return ++start;
  }
)();

export default function Toast({ notification }: Props) {
  const {
    content,
    Icon,
    highlight,
    iconFill,
    iconWidth = 18,
    onDismiss,
    state,
    onDismissAnimationComplete,
    onShowAnimationComplete,
    ariaLabel,
    autoDismissAfterMs,
  } = notification;
  const isAutoDismissed = !!autoDismissAfterMs;

  const onClick = state === ToastState.Showing ? onDismiss : undefined;
  const getIconSection = useCallback(() => {
    if (highlight) {
      return (
        <div className={styles.highlightSection} style={{ backgroundColor: highlight }}>
          {Icon && <Icon width={iconWidth} fill={iconFill} />}
        </div>
      );
    }

    return (
      Icon && (
        <div className={styles.iconSection}>
          <Icon width={18} fill={iconFill} />
        </div>
      )
    );
  }, [highlight, Icon, iconFill, iconWidth]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const id = useMemo(() => `notification-${String(getNextId())}`, [content]);
  const nodeRef = useRef(null);

  return (
    <CSSTransition
      appear={true}
      in={state === ToastState.AnimatingShow || state === ToastState.Showing}
      timeout={TOAST_ANIMATION_DURATION}
      onEntered={onShowAnimationComplete}
      onExited={onDismissAnimationComplete}
      classNames={{ ...animationStyles }}
      nodeRef={nodeRef}
    >
      <div
        ref={nodeRef}
        className={styles.toast}
        role={isAutoDismissed ? 'log' : 'alertdialog'}
        aria-live={isAutoDismissed ? 'polite' : 'off'}
        aria-describedby={id}
        aria-modal="true"
        aria-label={ariaLabel}
      >
        {getIconSection()}
        <div className={styles.contentSection} id={id}>
          {content}
        </div>
        <button
          className={clsx(styles.dismissSection, { [styles.active]: !!onClick })}
          onClick={onClick}
          type="button"
          aria-label={
            isAutoDismissed
              ? /* "hide" the "close" title from the svg when the close button is unnecessary for screen readers */ ' '
              : 'Dismiss Notification'
          }
        >
          <IconClose />
        </button>
      </div>
    </CSSTransition>
  );
}
