import React, { ReactNode, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { AnyObject } from '@tellurian/ts-utils';
import { ListFocusStyle, MouseEventHandlers } from './lib';
import styles from './OptionItem.module.css'; // eslint-disable-line

// In the majority of the cases, "Standard" should be used for consistency.
export enum OptionMinWidth {
  Standard = 200,
  None = 0,
}

export type RenderOptionProps<T, Q extends AnyObject = AnyObject> = {
  option: T;
  isSelected: boolean;
  isFocused: boolean;
  index: number;
  context: Q;
};

export function RenderReactNodeOption({ option, isSelected }: RenderOptionProps<ReactNode>) {
  return <div className={clsx({ [styles.selectedOption]: isSelected })}>{option}</div>;
}

export type OptionItemProps<T, Q extends AnyObject = AnyObject> = {
  option: T;
  isSelected: boolean;
  isFocused: boolean;
  index: number;
  optionMinWidth: OptionMinWidth;
  RenderOption: React.FunctionComponent<RenderOptionProps<T, Q>>;
  id: string;
  mouseEventHandlers: MouseEventHandlers;
  context: Q;
  scrollIntoView?: boolean;
  focusStyle?: ListFocusStyle;
};

function OptionItem<T, Q extends AnyObject = AnyObject>({
  option,
  isSelected,
  isFocused,
  optionMinWidth,
  RenderOption,
  id,
  mouseEventHandlers,
  index,
  context,
  scrollIntoView = isFocused,
  focusStyle = ListFocusStyle.Border,
}: OptionItemProps<T, Q>) {
  const ref = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (scrollIntoView) {
      ref.current?.scrollIntoView?.({ block: 'nearest' });
    }
  }, [isFocused, scrollIntoView]);
  const style =
    optionMinWidth && optionMinWidth !== OptionMinWidth.Standard
      ? { minWidth: optionMinWidth }
      : undefined;

  return (
    <li
      id={id}
      role="option"
      aria-selected={isSelected}
      className={clsx(
        styles.option,
        {
          [styles.focused]: isFocused,
          [styles.minWidthStandard]: optionMinWidth === OptionMinWidth.Standard,
        },
        ...focusStyle.split(' ').map(cls => styles[cls]),
      )}
      style={style}
      {...mouseEventHandlers}
      ref={ref}
    >
      <RenderOption
        option={option}
        isSelected={isSelected}
        isFocused={isFocused}
        index={index}
        context={context}
      />
    </li>
  );
}

export default OptionItem;
