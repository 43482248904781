import React from 'react';
import { FormProvider } from 'react-hook-form';
import { ModalProps } from '../../components/Modal';
import SaveModal from '../../components/Modal/SaveModal';
import usePermissionSelectionForm, {
  UsePermissionSelectionFormParams,
} from './usePermissionSelectionForm';
import AccountRoleAndDashboardPermissionsSelection from './AccountRoleAndDashboardPermissionsSelection';

type PermissionSelectionModalProps = Omit<UsePermissionSelectionFormParams, 'onSubmit'> &
  Pick<ModalProps, 'onCloseClick'>;

const PermissionSelectionModal: React.FC<PermissionSelectionModalProps> = ({
  onCloseClick,
  ...permissionSelectionParams
}) => {
  const {
    accountId,
    user: { displayName, email },
  } = permissionSelectionParams;
  const {
    submit,
    isSaving,
    defaultDashboardPermissions,
    userRole,
    useFormMethods,
    defaultUserRole,
    loading,
  } = usePermissionSelectionForm({ onSubmit: onCloseClick, ...permissionSelectionParams });
  return (
    <SaveModal
      title="User permissions"
      subtitle={
        <p>
          {displayName && <strong>{displayName}</strong>} <span>{email}</span>
        </p>
      }
      onCloseClick={onCloseClick}
      onSave={submit}
      isSaving={isSaving}
    >
      <FormProvider {...useFormMethods}>
        <AccountRoleAndDashboardPermissionsSelection
          accountId={accountId}
          defaultDashboardPermissions={defaultDashboardPermissions}
          defaultUserRole={defaultUserRole}
          loading={loading}
          userRole={userRole}
        />
      </FormProvider>
    </SaveModal>
  );
};

export default PermissionSelectionModal;
