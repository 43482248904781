import React from 'react';
import clsx from 'clsx';
import { ReactComponent as IconLeft } from '../../../assets/icons/icon_arrow_20x10_left.svg';
import { ReactComponent as IconRight } from '../../../assets/icons/icon_arrow_20x10_right.svg';
import styles from './OverflowButton.module.css'; // eslint-disable-line

export enum Direction {
  Left = 'left',
  Right = 'right',
}

type OverflowButtonProps = {
  direction: Direction;
  onClick: () => void;
};

export default function OverflowButton({ onClick, direction }: OverflowButtonProps) {
  return (
    <button className={clsx(styles.button, styles[direction])} onClick={onClick} type="button">
      {direction === Direction.Left && (
        <>
          <div className={styles.iconBody}>
            <IconLeft height={20} />
          </div>
          <div className={styles.gradient} />
        </>
      )}
      {direction === Direction.Right && (
        <>
          <div className={styles.gradient} />
          <div className={styles.iconBody}>
            <IconRight height={20} />
          </div>
        </>
      )}
    </button>
  );
}
