import React, { ReactElement, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import { Maybe } from '@tellurian/ts-utils';
import {
  Connector,
  ConnectorApplication,
  ConnectorConfiguration,
  useGetConnectorConfigurationsLazyQuery,
} from '../../generated/graphql';
import isCredentialVerificationFailing from '../connectors/configurations/isCredentialVerificationFailing';
import { useAuthorizationContext } from '../security/AuthorizationContext';
import { LocalStorage, LocalStorageKey } from '../../utils/localStorage';
import 'firebase/compat/auth';
import { isNotificationPreventedForConnectorConfiguration } from '../connectors/lib';
import { FCC } from '../../utils/types';
import { path } from '../lettuce/crisp/routing/lib';
import { useFailure } from './Toasts';

type LinkToConnectorConfigurationProps = {
  accountId: string;
  connectorConfigurationSlug: string;
  text?: string;
};

export const LinkToConnectorConfiguration: FCC<LinkToConnectorConfigurationProps> = ({
  accountId,
  connectorConfigurationSlug,
  text = 'Update credentials',
}: LinkToConnectorConfigurationProps) => {
  const location = useLocation();
  const linkTo = path('EditConnectorConfiguration')({
    accountId,
    connectorConfigurationSlug,
  });

  if (location.pathname === linkTo) {
    return null;
  }

  return <Link to={linkTo}>{text}</Link>;
};

export const getInvalidCredentialsMessage = ({
  name,
  application,
}: Pick<Connector, 'name' | 'application'>) =>
  application === ConnectorApplication.AzureBlobStorage
    ? `An access token for ${name} has expired.`
    : `Credentials for ${name} are out of date.`;

export const getToastContentForConnectorConfiguration = ({
  accountId,
  connectorConfiguration,
}: {
  accountId: string;
  connectorConfiguration: { connector: Pick<Connector, 'name' | 'application'> } & Pick<
    ConnectorConfiguration,
    'slug'
  >;
}): ReactElement => {
  const { application } = connectorConfiguration.connector;
  const linkProps: LinkToConnectorConfigurationProps = {
    accountId,
    connectorConfigurationSlug: connectorConfiguration.slug,
  };
  const message = getInvalidCredentialsMessage(connectorConfiguration.connector);
  if (application === ConnectorApplication.AzureBlobStorage) {
    linkProps.text = 'Renew token';
  }

  return (
    <>
      <div>{message}</div>
      <LinkToConnectorConfiguration {...linkProps} />
    </>
  );
};

type EmployToastStateResult = [(accountId: string) => boolean, (accountId: string) => void];
export type ToastPresentedRecords = Record<string, Maybe<boolean>>;

// Avoid using "use" prefix, as this is not a hook despite being used analogously to one in a React component.
const employToastState: () => EmployToastStateResult = (() => {
  let isSetup = false;
  let toastPresentedRecords: ToastPresentedRecords = {};

  const setup = () => {
    toastPresentedRecords = LocalStorage.getItemOrDefault(
      LocalStorageKey.InvalidCredentialsNotifications,
      {},
    );

    firebase.auth().onAuthStateChanged(user => {
      // If user has signed out or requires authentication, then clear the localStorage records
      if (!user) {
        LocalStorage.removeItem(LocalStorageKey.InvalidCredentialsNotifications);
      }
    });

    isSetup = true;
  };

  // Only defined once, no additional requirement for memoization in React components
  const fns = [
    (accountId: string) => !!toastPresentedRecords[accountId],
    (accountId: string) => {
      toastPresentedRecords[accountId] = true;
      LocalStorage.setItem(LocalStorageKey.InvalidCredentialsNotifications, toastPresentedRecords);
    },
  ] as EmployToastStateResult;

  return () => {
    !isSetup && setup();
    return fns;
  };
})();

export default function useInvalidCredentialsToast(accountId) {
  const [isToastPresentedForAccountId, setIsToastPresentedForAccountId] = employToastState();
  const [getConnectorConfigurations, { data: connectorData }] =
    useGetConnectorConfigurationsLazyQuery({
      variables: { accountId },
    });

  const { show: showToast } = useFailure();
  const { getHasAccountAdminPermission } = useAuthorizationContext();
  const isAdmin = getHasAccountAdminPermission(accountId);

  useEffect(() => {
    if (isAdmin) {
      getConnectorConfigurations();
    }
  }, [isAdmin, getConnectorConfigurations]);

  useEffect(() => {
    const connectors = connectorData?.account.connectorConfigurations.edges || [];
    for (const { node } of connectors) {
      if (
        node.connector.isActive &&
        node.enabled &&
        !isToastPresentedForAccountId(accountId) &&
        !isNotificationPreventedForConnectorConfiguration(node.connector) &&
        isCredentialVerificationFailing(node)
      ) {
        showToast(
          getToastContentForConnectorConfiguration({
            accountId,
            connectorConfiguration: node,
          }),
          { autoDismissAfterMs: undefined },
        );
        setIsToastPresentedForAccountId(accountId);
      }
    }
  }, [
    accountId,
    connectorData,
    showToast,
    isToastPresentedForAccountId,
    setIsToastPresentedForAccountId,
  ]);
}
