import React from 'react';
import AccountTemplate from '../templates/AccountTemplate';
import { AdminAuthorityGrant } from '../../../security/AuthorizationContext';
import UserAdmin from '../RetailAnalytics/UserAdmin';
import { lazyWithRetry } from '../../../lib';
import { AccountIdMatchParams } from './lib';

const RenderRetailAnalyticsUserAdmin = lazyWithRetry<typeof UserAdmin>(
  () => import(/* webpackChunkName: "retailAnalyticsUserAdmin" */ '../RetailAnalytics/UserAdmin'),
);

const RetailAnalyticsUserAdminPage: React.FC<AccountIdMatchParams> = ({
  match: {
    params: { accountId },
  },
}) => {
  return (
    <AccountTemplate requiredGrant={AdminAuthorityGrant}>
      <RenderRetailAnalyticsUserAdmin accountId={accountId} />
    </AccountTemplate>
  );
};

export default RetailAnalyticsUserAdminPage;
