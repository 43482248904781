import { Dictionary, groupBy } from 'lodash';
import {
  DashboardFragment,
  DashboardGroupFragment,
  DashboardTier,
} from '../../../../../../generated/graphql';

export type DashboardForMenu = Pick<
  DashboardFragment,
  'id' | 'dashboardGroupId' | 'tier' | 'name'
> & {
  state?: DashboardFragment['state'];
  relatedConnectorApplications?: DashboardFragment['relatedConnectorApplications'];
};
export type DashboardGroupForMenu = Pick<DashboardGroupFragment, 'id' | 'name' | 'enabled'> & {
  dashboards: DashboardForMenu[];
};
export type DashboardGroupsWithDashboardsByTier = (DashboardGroupForMenu & {
  dashboardsByTier: Dictionary<DashboardForMenu[]>;
})[];

export default function (dashboardGroups: DashboardGroupForMenu[]) {
  const isUnifiedGroup = (group: DashboardGroupForMenu) => {
    return group.dashboards.some(dashboard => dashboard.tier === DashboardTier.Unified);
  };

  const dashboardGroupsWithDashboardsByTier = dashboardGroups
    .filter(group => !isUnifiedGroup(group))
    .sort((g1, g2) => g1.name.localeCompare(g2.name))
    .map(group => ({
      ...group,
      dashboardsByTier: groupBy(group.dashboards, 'tier'),
    }));

  const allUnifiedDashboards = dashboardGroups
    .filter(isUnifiedGroup)
    .flatMap(group => group.dashboards);

  if (allUnifiedDashboards.length) {
    dashboardGroupsWithDashboardsByTier.unshift({
      name: 'Harmonized',
      dashboards: allUnifiedDashboards,
      dashboardsByTier: { [DashboardTier.Unified]: allUnifiedDashboards },
      enabled: true,
      id: 'harmonized',
    });
  }

  return dashboardGroupsWithDashboardsByTier;
}
