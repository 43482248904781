import { useGetPageMessageIdQuery } from '../../generated/graphql';
import { MessageId, Messages, PageMessage } from './messages';

type UsePageMessageResult = PageMessage | undefined;

export default function usePageMessage(): UsePageMessageResult {
  const { data } = useGetPageMessageIdQuery();
  const pageMessageId = data?.pageMessageId as MessageId | undefined;
  return pageMessageId ? (Messages[pageMessageId] as PageMessage) : undefined;
}
