import React, { ReactElement, useEffect, useMemo, useState, useTransition } from 'react';
import ReactDOM from 'react-dom';
import Input, { InputProps } from '../lib/Input';
import Popover from '../lib/Popover';
import useDropdownListOfItemsActionable, {
  UseDropdownListOfItemsSelectParams,
} from '../lib/useDropdownListOfItemsSelect';
import { ActionableListProps } from '../../../ui/lists/listOfItems/lib';
import ListOfItems, { ListOfItemsProps } from '../../../ui/lists/listOfItems/ListOfItems';
import { useDefaultHashId } from '../../../lib';
import { defaultGetSizeProps, DefaultPopoverMaxHeight } from '../lib/useDropdown';
import useEventCallback from '../../utils/useEventCallback';
import { GetSizeProps } from '../lib/useTether';
import { useGetFloatingPortalNode } from '../lib/FloatingContext';
import style from '../ChipDropdown/ChipDropdownSelect.module.css';

const ListStyle = Object.freeze({
  maxHeight: DefaultPopoverMaxHeight,
});

const getPopoverSizeProps: GetSizeProps = params => {
  const { rects } = params;
  return {
    ...defaultGetSizeProps(params),
    minHeight: 0,
    maxWidth: rects.reference.width,
  };
};

export type SearchSelectProps<T> = {
  initialQuery?: string;
  getSearchResults: (input: string) => T[];
  listId?: string;
  onSelect: (item: T) => void;
  disabled?: boolean;
} & Pick<InputProps, 'label' | 'autoFocus' | 'placeholder' | 'aria-describedby' | 'hasError'> &
  Pick<ListOfItemsProps<T, ActionableListProps>, 'getItemKey' | 'RenderItem'> &
  Pick<UseDropdownListOfItemsSelectParams<T>, 'toggleOnSpace'>;

function SearchSelect<T>({
  initialQuery = '',
  label,
  getSearchResults,
  RenderItem,
  getItemKey,
  listId,
  onSelect,
  autoFocus,
  toggleOnSpace,
  placeholder,
  'aria-describedby': ariaDescribedBy,
  hasError,
  disabled = false,
}: SearchSelectProps<T>) {
  listId = useDefaultHashId(listId);
  const [query, setQuery] = useState(initialQuery);
  const onChange = useEventCallback((item: T) => {
    setQuery('');
    onSelect(item);
  });
  const items = useMemo(() => getSearchResults(query), [getSearchResults, query]);
  const { getReferenceProps, getPopoverProps, listProps, isActive, setIsActive } =
    useDropdownListOfItemsActionable<T, HTMLDivElement>({
      items,
      onChange,
      getPopoverSizeProps,
      // NOTE: toggleOnSpace in the context of the useDropdownList implies adding an explicit key event
      // handler to enable the dropdown. What we want is to instead disable the selection of a list item
      // when the space key is pressed.
      toggleListItemOnSpace: toggleOnSpace,
    });

  const { focus, ref, ...inputProps } = getReferenceProps('input');
  const [, startTransition] = useTransition();
  const shouldActivate = !!(focus && query && items.length);
  const activate = useEventCallback(() => {
    if (!isActive && shouldActivate) {
      startTransition(() => setIsActive(true));
    }
  });
  useEffect(activate, [shouldActivate, activate]);
  useEffect(() => {
    if (query) {
      activate();
    }
  }, [query, activate]);
  const floatingPortalNode = useGetFloatingPortalNode();

  return (
    <>
      <Input
        label={label}
        onChange={e => setQuery(e.target.value)}
        value={query}
        autoFocus={autoFocus}
        containerRef={ref}
        disabled={disabled}
        role="searchbox"
        type="search"
        placeholder={placeholder}
        aria-describedby={ariaDescribedBy}
        hasError={hasError}
        {...inputProps}
      />
      {items.length > 0 &&
        !disabled &&
        isActive &&
        ReactDOM.createPortal(
          <Popover {...getPopoverProps()}>
            <ListOfItems<T, ActionableListProps>
              {...listProps}
              RenderItem={RenderItem}
              className={style.list}
              id={listId}
              getItemKey={getItemKey}
              style={ListStyle}
              role="none"
            />
          </Popover>,
          floatingPortalNode,
        )}
    </>
  );
}

export default React.memo(SearchSelect) as <T>(props: SearchSelectProps<T>) => ReactElement;
