import { AriaAttributes, Dispatch, RefCallback, SetStateAction, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import { noop } from '@tellurian/ts-utils';
import { useDefaultHashId } from '../../../lib';
import useOnClickAway from '../../utils/useOnClickAway';
import composeRefs from '../../utils/composeRefs';
import useEventCallback from '../../utils/useEventCallback';

type VerticalPlacement = 'middle' | 'start' | 'end';

export type UsePopoverSubMenuParams = {
  id?: string;
  isInitiallyVisible?: boolean;
  placement?: VerticalPlacement;
  ariaLabel?: string;
};

export type UsePopoverSubMenuReturn = {
  setReferenceElement: RefCallback<HTMLElement>;
  popoverProps: {
    setPopoverElement: RefCallback<HTMLElement>;
    setArrowElement: RefCallback<HTMLElement>;
    id: string;
    ariaLabel?: string;
  } & Pick<ReturnType<typeof usePopper>, 'attributes' | 'styles'>;
  referenceElementProps: Pick<AriaAttributes, 'aria-haspopup' | 'aria-controls' | 'aria-expanded'>;
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  show: () => void;
  hide: () => void;
  toggle: () => void;
  update: () => void;
};

const usePopoverSubMenu = <T extends HTMLElement = HTMLElement>({
  isInitiallyVisible = false,
  placement = 'middle',
  id,
  ariaLabel,
}: UsePopoverSubMenuParams): UsePopoverSubMenuReturn => {
  const [isVisible, setIsVisible] = useState(isInitiallyVisible);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popoverElement, setPopoverElement] = useState<HTMLElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);
  const { styles, attributes, update } = usePopper(referenceElement, popoverElement, {
    placement: placement === 'middle' ? 'right' : `right-${placement}`,
    modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
    strategy: 'fixed',
  });

  const fns = useMemo(
    () => ({
      // Provided for convenience to avoid having to memoize these externally on every use
      show: () => setIsVisible(true),
      hide: () => setIsVisible(false),
      toggle: () => setIsVisible(current => !current),
    }),
    [],
  );
  const onClickAwayRef = useOnClickAway<T>({ onClickAway: () => fns.hide(), enabled: isVisible });
  const setPopover = useMemo(
    () => composeRefs<HTMLElement>(setPopoverElement, onClickAwayRef) as RefCallback<HTMLElement>,
    [onClickAwayRef],
  );

  id = useDefaultHashId(id);
  const updatePopper = useEventCallback(update || noop);

  return {
    popoverProps: {
      setPopoverElement: setPopover,
      setArrowElement,
      styles,
      attributes,
      id,
      ariaLabel,
    },
    referenceElementProps: {
      'aria-haspopup': 'menu',
      'aria-controls': id,
      'aria-expanded': isVisible,
    },
    setReferenceElement,
    isVisible,
    setIsVisible,
    update: updatePopper,
    ...fns,
  };
};

export default usePopoverSubMenu;
