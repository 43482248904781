import React from 'react';
import { RouteComponentProps } from 'react-router';
import ConnectorAuditTrail from '../../../connectors/AuditTrail';
import ScrollToTop from '../../../Utils/ScrollToTop';
import AccountTemplate from '../templates/AccountTemplate';
import { lazyWithRetry } from '../../../lib';

const RenderConnectorAuditTrail = lazyWithRetry<typeof ConnectorAuditTrail>(
  () => import(/* webpackChunkName: "connectorAuditTrail" */ '../../../connectors/AuditTrail'),
);

type MatchParams = {
  accountId: string;
  connectorConfigurationSlug: string;
};

const ConnectorAuditTrailPage: React.FC<RouteComponentProps<MatchParams>> = ({
  match: {
    params: { accountId, connectorConfigurationSlug },
  },
}) => {
  return (
    <AccountTemplate>
      <ScrollToTop />
      <RenderConnectorAuditTrail
        accountId={accountId}
        connectorConfigurationSlug={connectorConfigurationSlug}
      />
    </AccountTemplate>
  );
};

export default ConnectorAuditTrailPage;
