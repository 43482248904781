import React, { ReactElement } from 'react';
import { AnyObject } from '@tellurian/ts-utils';
import Panel from '../../components/Panel';
import Title from '../../components/Title';
import AutoFitItemsLayout from '../../components/AutoFitItemsLayout';
import { useIsMaxSmallScreen } from '../../../ui';

type ObjectWithId = { id: string };

type UserItemListProps<T extends ObjectWithId, P extends AnyObject = AnyObject> = {
  items: T[];
  RenderItem: React.FC<T & P>;
  title?: string;
  header?: React.ReactNode;
  noItems?: React.ReactNode;
  className?: string;
};

function UserItemList<T extends ObjectWithId, P extends AnyObject = AnyObject>({
  header,
  title,
  items,
  RenderItem,
  className,
  noItems,
  ...rest
}: UserItemListProps<T, P>) {
  const isMaxSmallScreen = useIsMaxSmallScreen();
  const itemMinWidth = isMaxSmallScreen ? '100%' : `calc(50% - var(--space-m))`;
  return (
    <Panel className={className}>
      {title && <Title>{title}</Title>}
      {header && header}
      {items.length ? (
        <AutoFitItemsLayout itemMinWidth={itemMinWidth} as="ul" gap="M">
          {items.map(item => (
            <li key={item.id}>
              <RenderItem {...item} {...(rest as P)} />
            </li>
          ))}
        </AutoFitItemsLayout>
      ) : (
        noItems
      )}
    </Panel>
  );
}

export default React.memo(UserItemList) as <
  T extends ObjectWithId,
  P extends AnyObject = AnyObject,
>(
  props: UserItemListProps<T, P>,
) => ReactElement;
