import React, { useCallback } from 'react';
import clsx from 'clsx';
import styles from './index.module.css'; // eslint-disable-line
import { Button, ButtonStyle } from '../Button';
import MaybeClickableDiv from '../MaybeClickableDiv';
import { Dropdown, DropdownProps, DropdownStyle } from '../Dropdown';

export type BaseMenuItemProps = {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
};

export function BaseMenuItem({
  className,
  children,
  onClick,
  disabled = false,
}: BaseMenuItemProps) {
  if (disabled) {
    return <div className={clsx(styles.menuItem, styles.disabled, className)}>{children}</div>;
  }
  if (!onClick) {
    return <div className={clsx(styles.menuItem, className)}>{children}</div>;
  }
  return (
    <div>
      <MaybeClickableDiv
        onClick={onClick}
        className={clsx(styles.menuItem, styles.pointerEnabled, className)}
      >
        {children}
      </MaybeClickableDiv>
    </div>
  );
}

export type MenuItemProps = {
  children: React.ReactNode;
  className?: string;
  highlighted?: boolean;
  onClick?: () => void;
  selected?: boolean;
  disabled?: boolean;
};

export function MenuItem({
  className,
  children,
  highlighted,
  selected,
  onClick,
  disabled = false,
}: MenuItemProps) {
  return (
    <BaseMenuItem
      className={clsx(
        styles.selectableMenuItem,
        {
          [styles.selected]: selected,
          [styles.highlighted]: highlighted,
        },
        className,
      )}
      disabled={disabled}
    >
      {onClick && !disabled ? (
        <Button className={styles.menuItemButton} onClick={onClick} btnStyle={ButtonStyle.LINK}>
          {children}
        </Button>
      ) : (
        children
      )}
    </BaseMenuItem>
  );
}

export enum MenuType {
  HORIZONTAL = 'menuHorizontal',
  VERTICAL = 'menuVertical',
}

type MenuProps = {
  children: React.ReactNode;
  type?: MenuType;
  hoverEnabled?: boolean;
  id?: string;
};

export function Menu({
  children,
  type = MenuType.HORIZONTAL,
  hoverEnabled = false,
  id,
}: MenuProps) {
  return (
    <div className={clsx(styles[type], { [styles.hoverEnabled]: hoverEnabled })} id={id}>
      {children}
    </div>
  );
}

export function VerticalMenu({ children, ...rest }: Omit<MenuProps, 'type'>) {
  return (
    <Menu {...rest} type={MenuType.VERTICAL}>
      {children}
    </Menu>
  );
}

type SubMenuDropdownProps = {
  overlayId: string;
  inlineOverlay?: boolean;
} & Omit<
  DropdownProps,
  | 'overlayPosition'
  | 'disableResponsiveOverlay'
  | 'RenderOverlayContainer'
  | 'closeOnContentClicked'
>;

export function SubMenuDropdown({
  children,
  overlayId,
  inlineOverlay = true,
  dropdownStyle = DropdownStyle.FLAT,
  ...dropdownProps
}: SubMenuDropdownProps) {
  const onToggle = (targetState: boolean, event?: React.SyntheticEvent) => {
    event?.stopPropagation();
    return dropdownProps.onToggle?.(targetState, event);
  };
  const onKeyDown = useCallback((event: React.KeyboardEvent<HTMLButtonElement>) => {
    event.stopPropagation();
  }, []);

  return (
    <BaseMenuItem>
      <Dropdown
        {...dropdownProps}
        RenderOverlayContainer={
          inlineOverlay ? ({ children }) => <div id={overlayId}>{children}</div> : undefined
        }
        disableResponsiveOverlay
        buttonProps={{ onKeyDown, 'aria-controls': overlayId, ...dropdownProps.buttonProps }}
        dropdownStyle={dropdownStyle}
        onToggle={onToggle}
      >
        {children}
      </Dropdown>
    </BaseMenuItem>
  );
}
