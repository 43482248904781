import { Maybe } from '@tellurian/ts-utils';
import { NotificationProps } from '../../Notification';

export enum NotificationType {
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Failure = 'failure',
}

export type NotificationItemCallbacks = {
  onDismiss?: () => void;
  onShowAnimationComplete: () => void;
  onDismissAnimationComplete: () => void;
};

/**
 * The states below also reflect the order of the transitions a notification is expected to perform.
 * Exceptionally, state "AnimatingDismiss" can be skipped.
 */
export enum NotificationState {
  AnimatingShow,
  Showing,
  AnimatingDismiss,
  Dismissed,
}

export type NotificationItem = {
  id: string;
  state: NotificationState;
  isAutoDismissed?: boolean;
} & Partial<NotificationItemCallbacks> &
  Pick<NotificationProps, 'content' | 'title' | 'notificationType'>;

export type NotificationOptions = {
  dismissAfterMs: Maybe<number>;
  dismissOnPathnameChange: boolean;
  dismissOnHistoryChange: boolean;
  dismissOnLocationChangeImmediate: boolean;
} & NotificationItemCallbacks;

export type NotificationSpec = Partial<
  Pick<NotificationProps, 'notificationType' | 'content' | 'title'> & NotificationOptions
>;

export type DismissFn = (immediate?: boolean) => boolean;
export type ShowFn = (spec?: NotificationSpec) => void;

export const EmptyNotificationSpec: Readonly<NotificationSpec> = {
  content: '',
  title: undefined,
  notificationType: NotificationType.Info,
  dismissAfterMs: 4000,
};

export type NotificationDispatcherShowResult = {
  dismiss: (immediate?: boolean) => void;
  showAgain: (nextSpec?: NotificationSpec) => void;
  getState: () => NotificationState;
};

export type NotificationDispatcher = {
  show: (spec: NotificationSpec) => NotificationDispatcherShowResult;
  // Cleanup notification dispatcher
  dispose: () => void;
};
