import React from 'react';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import { AnyObject } from '@tellurian/ts-utils';
import { Cell as TableCell } from 'react-table';
import { isNumber } from '../../../lib';
import SimpleCell, { SimpleCellProps } from './SimpleCell';
import { CellContentAlign } from './lib';
import CellInlineContentWrapper from './CellInlineContentWrapper';

export type CellProps<T extends AnyObject = AnyObject> = {
  cell: TableCell<T>;
  formatter?: (value: string) => string;
} & Omit<SimpleCellProps, 'children'>;

const Cell = React.forwardRef(function Cell(
  { cell, formatter, alignContent, ...rest }: CellProps,
  ref?: React.Ref<HTMLDivElement>,
) {
  const { value } = cell;
  alignContent = alignContent || (isNumber(value) ? CellContentAlign.RIGHT : CellContentAlign.LEFT);

  return (
    <SimpleCell ref={ref} alignContent={alignContent} {...rest}>
      <CellInlineContentWrapper alignContent={alignContent} noPointerEvents>
        {formatter ? formatter(value) : value}
      </CellInlineContentWrapper>
    </SimpleCell>
  );
});

function areEqual(prevProps: CellProps, nextProps: CellProps): boolean {
  const omitProps = ['column', 'getCellProps', 'onClick', 'render', 'row'];
  return isEqual(omit(prevProps, omitProps), omit(nextProps, omitProps));
}

export default React.memo(Cell, areEqual);
