import React, { ReactNode, Suspense } from 'react';
import { AnyObject, Maybe } from '@tellurian/ts-utils';
import { useRouteMatch } from 'react-router-dom';
import MaxWidthLayout from '../../components/layout/MaxWidthLayout';
import NavRailMenu, { NavRailMenuProps } from '../navigation/NavRailMenu';
import NavRailPageTemplate from '../../components/NavRailPageTemplate';
import PageError from '../../../errorHandling/PageError';
import PageMessage from '../../../PageMessage';
import PageSpecificNavigationContextProvider from '../navigation/PageSpecificNavigationContext';

type BasicTemplateProps<T extends AnyObject> = T & {
  Layout?: 'div' | React.FC<T>;
  children?: ReactNode;
};

function BasicTemplate<T extends AnyObject>({
  children,
  Layout = MaxWidthLayout as React.FC<T>,
  ...props
}: BasicTemplateProps<T>) {
  const {
    params: { accountId },
  } = useRouteMatch<{ accountId: Maybe<string> }>();

  const layoutProps = props as T;
  return (
    <NavRailPageTemplate<NavRailMenuProps>
      RenderNavRail={NavRailMenu}
      SubsidiaryContext={PageSpecificNavigationContextProvider}
      accountId={accountId}
      isInitiallyReady={false}
    >
      <Layout {...layoutProps}>
        <PageError type="page" hideIfTypePresent="modal" />
      </Layout>
      <main id="main">
        <Layout {...layoutProps}>
          <PageMessage />
          {/* Suspense is required for lazy loading child components. */}
          <Suspense>{children}</Suspense>
        </Layout>
      </main>
    </NavRailPageTemplate>
  );
}

export default BasicTemplate;
