import React, { ReactElement } from 'react';
import { FCC } from '../../../../utils/types';
import { useOnKeyDown } from '../../../../utils/hooks';
import ModalBase, { ModalBaseProps } from './ModalBase';
import { useModalRef } from './lib';
import style from './SearchModal.module.css';

export type SearchModalProps = {
  onCloseClick: () => void;
  Header: ReactElement;
} & Pick<ModalBaseProps, 'size'>;

const SearchModal: FCC<SearchModalProps> = ({ Header, children, onCloseClick, ...rest }) => {
  useOnKeyDown('Escape', onCloseClick);

  return (
    <ModalBase ref={useModalRef(onCloseClick)} {...rest}>
      <div className={style.body}>
        {Header}
        <div>{children}</div>
      </div>
    </ModalBase>
  );
};

export default SearchModal;
