import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Maybe } from '@tellurian/ts-utils';
import clsx from 'clsx';
import omit from 'lodash/omit';
import { Account } from '../../generated/graphql';
import ListOfItems, {
  ListOfItemsProps,
  RenderItemProps,
} from '../ui/lists/listOfItems/ListOfItems';
import { ActionableListProps } from '../ui/lists/listOfItems/lib';
import useListOfItemsActionable from '../ui/lists/listOfItems/useListOfItemsActionable';
import ActionableListItem, {
  RenderActionableItemProps,
} from '../ui/lists/listOfItems/ActionableListItem';
import ListItemFocusHighlight from '../ui/lists/listOfItems/listItems/ListItemFocusHighlight';
import InterstitialItem from '../ui/lists/listOfItems/listItems/InterstitialItem';
import RenderInterstitial from '../../modelDocumentation/common/RenderInterstitial';
import { Feature, trackFeature } from '../../utils/features';
import SearchModal from '../lettuce/components/Modal/SearchModal';
import NeutralSearchInputHeader from '../lettuce/components/Modal/NeutralSearchInputHeader';
import { getAccountId, path, routeIdFromPath } from '../lettuce/crisp/routing/lib';
import { RouteId } from '../lettuce/crisp/routing/Routes';
import { AccountDetails, LocalDb } from './lib';
import { useRecentAccounts } from './useRecentAccounts';
import style from './AdminPanel.module.css';

const RouteIdSwitch: Readonly<Partial<Record<RouteId, RouteId>>> = {
  SubmitGoogleSheetsMasterData: 'Connectors',
  AddConnectorConfiguration: 'Connectors',
  EditConnectorConfiguration: 'Connectors',
  ViewInvitation: 'AccountHome',
  EditNotification: 'Notifications',
  RemaConnectReport: 'RemaConnect',
};

const getTargetRouteId = (pathname: string): RouteId => {
  const routeId = routeIdFromPath(pathname);
  if (routeId) {
    const routeIdOverride = RouteIdSwitch[routeId];
    // If there is an override, use it, otherwise return the same route
    return routeIdOverride ?? routeId;
  }

  return 'AccountHome';
};

const switchToAccount = (
  pushUrl: (url: string) => void,
  toAccount: Pick<Account, 'id' | 'name'>,
  fromAccountId?: string,
) => {
  const pathname = window.location.pathname;
  if (fromAccountId !== toAccount.id) {
    const nextPath = fromAccountId
      ? path(getTargetRouteId(pathname))({ accountId: toAccount.id })
      : path('AccountHome')({ accountId: toAccount.id });

    pushUrl(nextPath);
    trackFeature(Feature.AdminConsoleAccountSwitched, {
      fromAccountId: fromAccountId,
      // Pendo only displays the first level in a JS object, so toAccount: account renders undefined.
      toAccountName: toAccount.name,
      toAccountId: toAccount.id,
    });
  }
};

type IsCurrentAccountFn = (account: Pick<AccountDetails, 'id'>) => boolean;

const RenderRecentAccountsHeader: React.FC<RenderItemProps<AccountDetails>> = ({ index }) => {
  return index === 0 ? (
    <InterstitialItem sticky={true}>
      <RenderInterstitial
        lhs={<span className={style.itemHeaderLhs}>Recent accounts</span>}
        size="s"
      />
    </InterstitialItem>
  ) : null;
};

const RenderFilteredAccountsHeader: React.FC<
  RenderItemProps<AccountDetails, Pick<AccountListOfItemsProps, 'itemCount' | 'totalAccountCount'>>
> = ({ index, itemCount, totalAccountCount }) => {
  return index === 0 ? (
    <InterstitialItem sticky={true}>
      <RenderInterstitial
        lhs={<span className={style.itemHeaderLhs}>accounts</span>}
        rhs={
          typeof totalAccountCount === 'number' ? (
            <span>
              {itemCount} / {totalAccountCount}
            </span>
          ) : (
            <span>{itemCount}</span>
          )
        }
        size="s"
      />
    </InterstitialItem>
  ) : null;
};

const RenderAccountItem: React.FC<
  RenderActionableItemProps<AccountDetails, { isCurrentAccount: IsCurrentAccountFn }>
> = ({ item, isCurrentAccount }) => {
  return (
    <div className={style.accountItem}>
      <div className={clsx(style.lhs, { [style.current]: isCurrentAccount(item) })}>
        {item.name}
        {item.isTest && <span className={style.tag}>Test account</span>}
      </div>
      <div className={style.rhs}>{item.id}</div>
    </div>
  );
};

const RenderListItem = ActionableListItem(RenderAccountItem, ListItemFocusHighlight);

type AccountListOfItemsProps = ActionableListProps & {
  isCurrentAccount: IsCurrentAccountFn;
  itemCount: number;
  totalAccountCount: Maybe<number>;
};

type ListOfAccountsProps = Omit<
  ListOfItemsProps<AccountDetails, AccountListOfItemsProps>,
  'RenderItem' | 'getItemKey'
>;

const ListOfAccounts: React.FC<ListOfAccountsProps> = listProps => {
  return (
    <ListOfItems<AccountDetails, AccountListOfItemsProps>
      {...listProps}
      RenderItem={RenderListItem}
      getItemKey={item => item.id}
      className={style.list}
    />
  );
};

type AdminPanelProps = {
  onClose: () => void;
};

const AdminPanel: React.FC<AdminPanelProps> = ({ onClose }) => {
  const [query, setQuery] = useState('');
  const latestQueryRef = useRef(query);
  const localAccounts = useRef(LocalDb.accounts).current;
  const [filteredAccounts, setFilteredAccounts] = useState<Maybe<AccountDetails[]>>(undefined);
  const history = useHistory();
  const currentAccountIdRef = useRef<Maybe<string>>();
  const [totalAccountCount, setTotalAccountCount] = useState<Maybe<number>>();

  useEffect(() => {
    localAccounts.count().then(setTotalAccountCount);
    trackFeature(Feature.AdminConsoleTriggered);
  }, [localAccounts]);

  const updateQuery = useCallback((nextQuery: string) => {
    latestQueryRef.current = nextQuery;
    setQuery(nextQuery);
  }, []);

  useEffect(() => {
    currentAccountIdRef.current = getAccountId(history.location.pathname);
  }, [history]);

  const onToggle = useCallback(
    account => {
      switchToAccount(history.push, account, currentAccountIdRef.current);
      onClose();
    },
    [history, onClose],
  );

  useEffect(() => {
    if (query.length) {
      localAccounts.query(query).then(result => {
        // Only update the result if the query had not changed in the meantime
        // to prevent an expensive unnecessary render
        if (query === latestQueryRef.current) {
          setFilteredAccounts(result);
        }
      });
    } else {
      setFilteredAccounts(undefined);
    }
  }, [query, localAccounts]);

  const recentAccounts = useRecentAccounts();
  const items = filteredAccounts ?? recentAccounts;

  const {
    getFocusParentProps,
    getFocusedIndex: _,
    ...listProps
  } = useListOfItemsActionable({
    items,
    onItemToggle: onToggle,
    toggleOnSpace: false,
  });

  const { setFocusedIndex } = listProps;
  useEffect(() => {
    setFocusedIndex(0);
  }, [filteredAccounts, setFocusedIndex]);

  const isCurrentAccount = useCallback(
    (account: Pick<AccountDetails, 'id'>) => currentAccountIdRef.current == account.id,
    [],
  );

  return (
    <SearchModal
      onCloseClick={onClose}
      Header={
        <NeutralSearchInputHeader
          value={query}
          onChange={ev => updateQuery(ev.target.value)}
          placeholder="Jump to account - search by name or id"
          onClearSearch={() => (query ? setQuery('') : onClose())}
          {...omit(getFocusParentProps(), 'focus')}
        />
      }
    >
      {items.length ? (
        <div className={style.listContainer}>
          <ListOfAccounts
            {...listProps}
            isCurrentAccount={isCurrentAccount}
            itemCount={items.length}
            totalAccountCount={totalAccountCount}
            RenderBeforeItem={
              filteredAccounts ? RenderFilteredAccountsHeader : RenderRecentAccountsHeader
            }
          />
        </div>
      ) : (
        <div className="phm pvs">
          No accounts found for <strong>{query}</strong>
        </div>
      )}
    </SearchModal>
  );
};

export default AdminPanel;
