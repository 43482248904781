import React from 'react';
import clsx from 'clsx';
import style from './index.module.css';

type SpinnerProps = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'children'
>;

const Spinner: React.FC<SpinnerProps> = ({ className, ...rest }) => {
  return (
    <div {...rest} className={clsx(style.container, className)}>
      <div className={style.spinner}>
        <svg className={style.spinnerSvg} viewBox="0 0 60 60">
          <circle cx={30} cy={30} r={25} fill="none" strokeWidth={2}></circle>
        </svg>
      </div>
      <div className="sr-only">Loading...</div>
    </div>
  );
};

export default Spinner;
