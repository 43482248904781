import React, {
  AriaAttributes,
  ClipboardEventHandler,
  CSSProperties,
  useEffect,
  useRef,
} from 'react';
import clsx from 'clsx';
import { SpinnerSvg } from '../Spinner';
import styles from './Input.module.css';

/**
 * Props for the lowest level components (`TextInput`, `NumberInput`, `TextAreaInput`, ...).
 */
export type InputProps<T> = {
  id?: string;
  className?: string;
  disabled?: boolean;
  focus?: boolean;
  hasError?: boolean;
  loading?: boolean;
  onChange?: (value: T) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  value?: T;
  type?: string;
  placeholder?: string;
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
  style?: CSSProperties;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  width?: number;
  autoComplete?: string;
  inputRef?: (value: HTMLInputElement) => void;
  inputName?: string;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  preventFocusScroll?: boolean;
  testId?: string;
  spellCheck?: boolean;
  role?: string;
  onPaste?: ClipboardEventHandler<HTMLInputElement>;
  suggestText?: string;
} & AriaAttributes;

/** DEPRECATED. Use Input component from core-ui/src/components/ui/form/Input/index.tsx instead. */
export function Input({
  id,
  type = 'text',
  hasError,
  onChange,
  value,
  focus,
  className,
  placeholder,
  loading,
  icon,
  iconPosition,
  style,
  prefix,
  suffix,
  width,
  inputRef,
  inputName,
  preventFocusScroll,
  testId,
  spellCheck,
  suggestText,
  ...rest
}: InputProps<string>) {
  const inputEl = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputEl.current && inputRef) {
      inputRef(inputEl.current);
    }
  }, [inputRef]);

  useEffect(() => {
    if (inputEl.current && focus) {
      inputEl.current.focus({ preventScroll: preventFocusScroll });
    }
  }, [focus, preventFocusScroll]);

  const hasIcon = icon !== undefined || loading;

  return (
    <div
      className={clsx(styles.input, { [styles.left]: iconPosition && iconPosition === 'left' })}
      style={{ ...style, width: width }}
    >
      {prefix && <div className={styles.inputLabel}>{prefix}</div>}
      <input
        {...rest}
        ref={inputEl}
        name={inputName}
        className={clsx(
          { [styles.hasError]: hasError },
          { [styles.hasIcon]: hasIcon },
          { [styles.left]: iconPosition && iconPosition === 'left' },
          className,
        )}
        id={id}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange && onChange(e.target.value)}
        type={type}
        value={value}
        placeholder={placeholder}
        data-testid={testId}
        spellCheck={spellCheck}
      />
      {suggestText && (
        <div
          aria-hidden="true"
          className={styles.suggestContainer}
          onClick={() => inputEl?.current?.focus({ preventScroll: preventFocusScroll })}
          onKeyDown={() => inputEl?.current?.focus({ preventScroll: preventFocusScroll })} //jsx-a11y
        >
          <span className={styles.valueText}>{value}</span>
          <span className={styles.suggestText}>{suggestText}</span>
        </div>
      )}
      {icon && !loading && <i className={styles.icon}>{icon}</i>}
      {loading && (
        <i className={styles.icon}>
          <SpinnerSvg style={{ right: 4, top: 0 }} />
        </i>
      )}
      {suffix && <div className={clsx(styles.inputLabel, styles.right)}>{suffix}</div>}
    </div>
  );
}
