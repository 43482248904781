import { useMemo } from 'react';
import { useAuthorizationContext } from '../security/AuthorizationContext';
import { BookmarkFragmentWithDashboardInfo } from '../BookmarksContext';

/**
 * Returns false if at least one related connector configuration has the dashboardsDisabled
 * flag true. Returns true otherwise.
 * @param group
 */
export const isDashboardGroupDisabledByConnectorConfiguration = (group: {
  dashboards: { state: { isDisabledByConnectorConfiguration: boolean } }[];
}) => group.dashboards.some(d => d.state.isDisabledByConnectorConfiguration);

/**
 * Returns false if at least one connector of a related connector configuration for this group
 * is not active (isActive === false), and true otherwise.
 * @param group
 */
export const isDashboardGroupDisabledByConnector = (group: {
  dashboards: { state: { hasInactiveConnector: boolean } }[];
}) => group.dashboards.some(d => d.state.hasInactiveConnector);

export const useIsOwnReportPredicate = () => {
  const currentUserId = useAuthorizationContext().currentUser?.id;
  return useMemo(() => {
    if (!currentUserId) {
      return () => false;
    }
    return (report: Pick<BookmarkFragmentWithDashboardInfo, 'owner'>) =>
      report.owner?.id === currentUserId;
  }, [currentUserId]);
};
