import React from 'react';
import { RouteComponentProps } from 'react-router';
import { parseQuery } from '../../../../utils/querystringUtils';
import { lazyWithRetry } from '../../../lib';
import PartnerSignIn from '../authentication/PartnerSignIn';

const RenderPartnerSignIn = lazyWithRetry<typeof PartnerSignIn>(
  () => import(/* webpackChunkName: "partnerSignIn" */ '../authentication/PartnerSignIn'),
);

const SignInPage: React.FC<RouteComponentProps> = ({ location }) => {
  const { returnTo, redirectUri } = parseQuery(location.search);

  return (
    <RenderPartnerSignIn
      returnToUri={returnTo?.toString()}
      returnToExternalUri={redirectUri?.toString()}
    />
  );
};

export default SignInPage;
