import React from 'react';

const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL;
const supportSubject = encodeURIComponent('Associate user account with an organization');

export default function NoAccountsAccess() {
  return (
    <div style={{ textAlign: 'center' }}>
      <h1>Welcome back!</h1>
      <p>
        Your account is not associated with an organization. Please{' '}
        <a href={`mailto:${supportEmail}?subject=${supportSubject}`}>contact Crisp</a> to request an
        invitation.
      </p>
    </div>
  );
}
