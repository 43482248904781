import React from 'react';
import clsx from 'clsx';
import { useDefaultHashId } from '../../lib';
import styles from './index.module.css';

export enum SwitchLabelPosition {
  Right = 'right',
  Bottom = 'bottom',
}

type SwitchProps = {
  className?: string;
  checked: boolean;
  label?: string;
  labelPosition?: SwitchLabelPosition;
  onClick: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
};

export function Switch({
  className,
  checked,
  label: labelText,
  onClick,
  labelPosition,
  disabled = false,
}: SwitchProps) {
  const id = useDefaultHashId(labelText?.replaceAll(' ', ''));

  return (
    <div
      className={clsx(styles.container, {
        [styles.stacked]: labelPosition === SwitchLabelPosition.Bottom,
        [styles.disabledContainer]: disabled,
      })}
    >
      <button
        id={id}
        className={clsx(styles.button, className, { [styles.checked]: checked })}
        onClick={onClick}
        role="switch"
        aria-checked={checked}
        disabled={disabled}
        type="button"
      >
        <div className={styles.switch} />
      </button>
      {labelText && (
        <label htmlFor={id} className={styles.label}>
          {labelText}
        </label>
      )}
    </div>
  );
}
