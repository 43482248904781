import React from 'react';
import SelectableListItem, {
  RenderSelectableItemProps,
} from '../../../../ui/lists/listOfItems/SelectableListItem';
import Flex from '../../Flex';
import CheckboxIcon from '../CheckboxIcon';
import ListItemFocusHighlight from '../../../../ui/lists/listOfItems/listItems/ListItemFocusHighlight';

const selectableItemFactory = <T,>(RenderItemContent: React.FC<RenderSelectableItemProps<T>>) => {
  const RenderSelectableItem: React.FC<RenderSelectableItemProps<T>> = props => {
    return (
      <Flex size="S">
        <CheckboxIcon size="S" isChecked={props.isSelected} />
        <RenderItemContent {...props} />
      </Flex>
    );
  };

  return SelectableListItem(RenderSelectableItem, ListItemFocusHighlight);
};

export default selectableItemFactory;
