import React from 'react';
import { Maybe } from '@tellurian/ts-utils';
import ActionableListItem, {
  RenderActionableItemProps,
} from '../../../../ui/lists/listOfItems/ActionableListItem';
import ListItemFocusHighlight from '../../../../ui/lists/listOfItems/listItems/ListItemFocusHighlight';
import { RenderSelectableItemProps } from '../../../../ui/lists/listOfItems/SelectableListItem';
import selectableItemFactory from '../MultiSelectDropdown/selectableItemFactory';

export type DropdownListOption = {
  name?: string;
  value: string;
};

export const getOptionName = (option: DropdownListOption): string => option.name ?? option.value;

export const getOptionSelectText =
  (noOptionText = 'Select') =>
  (option: Maybe<DropdownListOption>): string =>
    option ? getOptionName(option) : noOptionText;

export const getTextForSelection = (optionSelection: DropdownListOption[]): string =>
  optionSelection.length === 1
    ? (optionSelection[0].name ?? optionSelection[0].value)
    : `${optionSelection.length} selected`;

export const getOptionMultiSelectText =
  (noOptionText = 'Select') =>
  (optionSelection: DropdownListOption[]): string =>
    optionSelection.length ? getTextForSelection(optionSelection) : noOptionText;

export type DropdownListOptions =
  | string[]
  | { values: readonly string[]; getOptionName: (value: string) => string }
  | DropdownListOption[];

export const toCanonicalOptions = (options: DropdownListOptions): DropdownListOption[] => {
  if (Array.isArray(options)) {
    if (typeof options[0] === 'string') {
      return options.map(option => ({ value: option }));
    }
  } else if ('values' in options) {
    return options.values.map(value => ({ name: options.getOptionName(value), value }));
  }

  return options as DropdownListOption[];
};

export const RenderItem: React.FC<RenderActionableItemProps<DropdownListOption>> = ({ item }) => {
  return <div>{getOptionName(item)}</div>;
};

export const RenderListItem = ActionableListItem(RenderItem, ListItemFocusHighlight);

const RenderOptionNameContent: React.FC<RenderSelectableItemProps<DropdownListOption>> = ({
  item,
}) => {
  return <span>{getOptionName(item)}</span>;
};

export const RenderMultiSelectListItem =
  selectableItemFactory<DropdownListOption>(RenderOptionNameContent);

export const getItemKey = (item: DropdownListOption) => item.value;

export type ListOption<T extends string> = {
  name?: string;
  value: T;
};
