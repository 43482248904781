import React from 'react';
import clsx from 'clsx';
import styles from './index.module.css'; // eslint-disable-line

type ColProps = {
  children?: React.ReactNode;
  className?: string;
  flex?: number | string;
  style?: React.CSSProperties;
  width?: string | number; // Usage of 'width' overrides the 'flex' property. Specify with <length> or <percentage> value (ie. '33%').
};

export function Col({ className, children, flex = 1, style, width }: ColProps) {
  return (
    <div
      className={clsx(styles.col, { [styles.fixedCol]: width !== undefined }, className)}
      style={Object.assign({}, style, width ? { width } : { flex })}
    >
      {children}
    </div>
  );
}

export enum GridType {
  XS = 'gridXS',
  S = 'gridS',
  M = 'gridM',
  L = 'gridL',
}

export enum GridWrap {
  NOWRAP = 'nowrap',
  WRAP = 'wrap',
  REVERSE = 'wrap-reverse',
}

type GridProps = {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  testId?: string;
  type?: GridType;
  wrap?: GridWrap;
  id?: string;
};

export function Grid({
  children,
  testId,
  type = GridType.M,
  wrap = GridWrap.NOWRAP,
  style,
  className,
  id,
}: GridProps) {
  return (
    <div
      className={clsx(styles[type], className)}
      style={Object.assign({}, style, { flexWrap: wrap })}
      data-testid={testId}
      id={id}
    >
      {children}
    </div>
  );
}
