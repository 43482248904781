import React from 'react';
import clsx from 'clsx';
import styles from './SkipToMainLink.module.css';
import buttonStyles from '../../../ui/Button/index.module.css'; // eslint-disable-line

// https://css-tricks.com/how-to-create-a-skip-to-content-link/

const SkipToMainLink: React.FC = () => (
  <a
    href="#main"
    className={clsx(
      styles.skipLink,
      buttonStyles.button,
      buttonStyles.border,
      buttonStyles.medium,
      'mrm',
    )}
  >
    Skip to main content
  </a>
);

export default SkipToMainLink;
