import React, { useRef } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { keyDownTriggerOnClick } from '../lib';
import { ExternalLinkTarget } from '../../lettuce/components/ExternalLink';
import style from './index.module.css';

export type CardProps = {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
  testId?: string;
  to?: string;
  href?: string;
  target?: ExternalLinkTarget;
};

export function Card({
  children,
  className,
  onClick,
  disabled = false,
  testId,
  to,
  href,
  target,
}: CardProps) {
  const cardRef = useRef(null);
  const card = (
    <div
      ref={cardRef}
      className={clsx(style.card, className, {
        [style.clickable]: !disabled && (!!onClick || !!to),
        [style.disabled]: disabled,
      })}
      onClick={onClick}
      onKeyDown={onClick ? keyDownTriggerOnClick(onClick, cardRef) : undefined}
      role={onClick ? 'button' : undefined}
      data-testid={testId}
    >
      {children}
    </div>
  );

  return to ? (
    <Link to={to} className={style.link} target={target}>
      {card}
    </Link>
  ) : href ? (
    <a href={href} className={style.link} target={target}>
      {card}
    </a>
  ) : (
    card
  );
}
