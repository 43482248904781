import React, { useCallback, useContext, useState } from 'react';
import { useGenerateUnfiInsightsLookerDashboardUrlMutation } from '../../../generated/graphql';
import {
  EmbeddedLookerContext,
  useEmbeddedLooker,
} from '../../businessDashboard/embeddedLooker/EmbeddedLookerContext';

export const useUnfiEmbeddedLookerContext = () => useContext(EmbeddedLookerContext);

type UnfiEmbeddedLookerContextProviderProps = {
  accessToken?: string;
  children: React.ReactNode;
};

export function UnfiEmbeddedLookerContextProvider({
  accessToken,
  children,
}: UnfiEmbeddedLookerContextProviderProps) {
  const [isLoading, setLoading] = useState(true);
  const {
    iframeRef,
    embeddedLooker,
    resetInitialQueryParams,
    extractFiltersFromQueryParams,
    setFilterQueryParams,
    pageProperties,
    currentFilters,
  } = useEmbeddedLooker(setLoading);

  const [generateUrl, { data: embeddedUrlData }] =
    useGenerateUnfiInsightsLookerDashboardUrlMutation();

  const generateEmbeddedUrl = useCallback(
    (_, dashboardId: string) => {
      setLoading(true);
      generateUrl({
        variables: {
          input: {
            dashboardId,
            accessToken: accessToken || '',
            embeddingDomain: window.location.origin,
          },
        },
      });
    },
    [generateUrl, setLoading, accessToken],
  );

  const setFilters = () => {
    throw new Error('Setting filters is not yet supported in UNFI Insights');
  };

  const resetFilters = () => {
    throw new Error('Resetting filters is not yet supported in UNFI Insights');
  };

  return (
    <EmbeddedLookerContext.Provider
      value={{
        iframeRef,
        loading: isLoading,
        embeddedLooker,
        resetInitialQueryParams,
        setFilterQueryParams,
        extractFiltersFromQueryParams,
        embeddedUrl: embeddedUrlData?.generateUnfiInsightsLookerDashboardUrl?.url,
        generateEmbeddedUrl,
        pageProperties,
        currentFilters,
        setFilters,
        resetFilters,
        defaultFilters: undefined, // Unnecessary for UNFI Insights at this time
      }}
    >
      {children}
    </EmbeddedLookerContext.Provider>
  );
}
