import React from 'react';

export type TextOption = {
  text: string;
};

export type Option<T> = {
  value: T;
} & TextOption;

export type OptionalClassName = { className?: string };

/**
 * For accessibility, any visible, non-interactive elements that have an onClick event
 * should also have an onKeyDown that does something similar. Usually this just means
 * calling the same onClick event when the user hits the Enter Key or Spacebar.
 * Most of the time there should also be an appropriate role added to the element as well.
 */
export const keyDownTriggerOnClick =
  (callback: (e: React.SyntheticEvent) => void, elementRef?: React.MutableRefObject<null>) =>
  (event: React.KeyboardEvent) => {
    if (elementRef && event.target !== elementRef.current) {
      return;
    }
    if (event.key === 'Enter' || event.key === ' ') {
      return callback(event);
    }
  };
