import React from 'react';
import { RouteComponentProps } from 'react-router';
import UnfiInsightsEmbeddedTemplate from '../templates/UnfiInsightsEmbeddedTemplate';
import { lazyWithRetry } from '../../../lib';
import Categorization from '../Deductions/Categorization';

type MatchParams = {
  paymentId: string;
  deductionId: string;
};
const RenderCategorization = lazyWithRetry<typeof Categorization>(
  () => import(/* webpackChunkName: "unfiEmbeddedCategorization" */ '../Deductions/Categorization'),
);
export default function UnfiInsightsEmbeddedDeductionsCategorizationPage({
  match: {
    params: { paymentId, deductionId },
  },
}: RouteComponentProps<MatchParams>) {
  return (
    <UnfiInsightsEmbeddedTemplate>
      <RenderCategorization paymentId={paymentId} deductionId={deductionId} />
    </UnfiInsightsEmbeddedTemplate>
  );
}
