import React, { useCallback } from 'react';
import clsx from 'clsx';
import { ReactComponent as LinkIcon } from '../../assets/icons/hyperlink-3.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/check_17x17_green.svg';
import { Button, ButtonStyle } from './Button';
import useClipboardTextState from './form/ClipboardCopy/useClipboardTextState';
import styles from './PageHeader.module.css';

type PageHeaderProps = {
  action?: {
    label: string;
    fn: () => void;
  };
  className?: string;
  description?: string;
  heading: string;
  subHeading?: string;
  sticky?: boolean;
};

export function PageHeader({
  action,
  className,
  description,
  heading,
  subHeading,
  sticky = false,
}: PageHeaderProps) {
  return (
    <div className={clsx(styles.container, className, { [styles.sticky]: sticky })}>
      <div>
        {subHeading ? <h3 className={styles.subHeading}>{subHeading}</h3> : undefined}
        <h1 className={styles.heading}>{heading}</h1>
        {description ? <p className="mtn">{description}</p> : undefined}
      </div>
      {action && (
        <div className={styles.actionContainer}>
          <Button onClick={action.fn} btnStyle={ButtonStyle.PRIMARY}>
            {action.label}
          </Button>
        </div>
      )}
    </div>
  );
}

type ModalHeaderProps = {
  headingId?: string;
  descriptionId?: string;
  includeCopyLink?: URL;
} & Omit<PageHeaderProps, 'action'>;

const CopyButton: React.FC<{ link: URL }> = ({ link }) => {
  const [clipboardContent, setClipboardContent, { isClipboardWriteProhibited }] =
    useClipboardTextState();

  const isCopiedToClipboard = useCallback(value => value === clipboardContent, [clipboardContent]);
  const onCopyToClipboard = useCallback(value => setClipboardContent(value), [setClipboardContent]);

  if (isClipboardWriteProhibited) {
    return null;
  }

  const isCopied = isCopiedToClipboard(link);

  return (
    <Button
      btnStyle={ButtonStyle.ICON_HOVER}
      onClick={e => {
        e.stopPropagation();
        onCopyToClipboard(link);
      }}
      disabled={isCopied}
      className={styles.modalHeadingLinkButton}
      icon={isCopied ? <CheckIcon /> : <LinkIcon />}
      title={isCopied ? 'Copied!' : 'Copy link to clipboard'}
    />
  );
};

/** DEPRECATED Use new lettuce modal */
export function ModalHeader({
  heading,
  description,
  subHeading,
  headingId,
  descriptionId,
  className,
  includeCopyLink,
}: ModalHeaderProps) {
  return (
    <div className={clsx(styles.modalHeader, className)}>
      {subHeading ? <h3 className={styles.subHeading}>{subHeading}</h3> : undefined}
      <h3 className={styles.modalHeading} id={headingId}>
        {heading}
        {includeCopyLink && <CopyButton link={includeCopyLink} />}
      </h3>
      {description ? (
        <p className="mtn" id={descriptionId}>
          {description}
        </p>
      ) : undefined}
    </div>
  );
}
