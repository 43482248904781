import React from 'react';
import { lazyWithRetry } from '../../../lib';
import LuminateBasicDataPresence from '../observability/DataPresence/LuminateBasic';
import FullWidthLayout from '../../components/layout/FullWidthLayout';
import GlobalAdminTemplate from '../templates/GlobalAdminTemplate';
import { AccountIdMatchParams } from './lib';

const RenderLuminateBasicDataPresence = lazyWithRetry<typeof LuminateBasicDataPresence>(
  () => import(/* webpackChunkName: "mdm" */ '../observability/DataPresence/LuminateBasic'),
);

const LuminateBasicDataPresencePage: React.FC<AccountIdMatchParams> = ({
  match: {
    params: { accountId },
  },
}) => {
  return (
    <GlobalAdminTemplate Layout={FullWidthLayout}>
      <RenderLuminateBasicDataPresence accountId={accountId} />
    </GlobalAdminTemplate>
  );
};

export default LuminateBasicDataPresencePage;
