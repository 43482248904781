import React, { useCallback, useContext, useMemo } from 'react';
import { Maybe } from '@tellurian/ts-utils';
import _ from 'lodash';
import { LocalStorageKey } from '../../../utils/localStorage';
import useLocalStorageState from '../../../utils/localStorage/useLocalStorageState';
import { FCC } from '../../../utils/types';
import { useAuthorizationContext } from '../../security/AuthorizationContext';

export type FavoriteReport = {
  dashboardGroupId?: string;
  dashboardId?: string;
  bookmarkId?: string;
};

export type FavoriteReports = Record<string, Record<string, FavoriteReport[]>>;

type FavoriteReportsInterface = {
  getFavoriteReports: () => FavoriteReport[];
  add: (report: FavoriteReport) => void;
  remove: (report: FavoriteReport) => void;
  isReportFavorite: (report: FavoriteReport) => boolean;
};

export const FavoriteReportsContext = React.createContext<FavoriteReportsInterface>({
  getFavoriteReports: () => [],
  add: () => {},
  remove: () => {},
  isReportFavorite: () => false,
});

type FavoriteReportsProviderProps = {
  accountId: string;
};

export const FavoriteReportsProvider: FCC<FavoriteReportsProviderProps> = ({
  accountId,
  children,
}) => {
  const { currentUser } = useAuthorizationContext();
  const userId = useMemo(() => currentUser?.id, [currentUser]);
  const [favoriteReportsState, setFavoriteReportsState] = useLocalStorageState(
    LocalStorageKey.FavoriteReports,
    {},
  );

  const getFavoriteReportsForUser = useCallback(() => {
    if (!userId) {
      return [];
    }
    const favoriteByUser = favoriteReportsState[userId];
    return favoriteByUser ? (favoriteByUser[accountId] ?? []) : [];
  }, [favoriteReportsState, userId, accountId]);

  const setFavoriteReports = useCallback(
    (userId: Maybe<string>, accountId: string, reports: FavoriteReport[]) => {
      if (!userId) {
        return;
      }
      setFavoriteReportsState({
        ...favoriteReportsState,
        [userId]: {
          ...favoriteReportsState[userId],
          [accountId]: [..._.uniqWith(reports, _.isEqual)],
        },
      });
    },
    [favoriteReportsState, setFavoriteReportsState],
  );

  const value = useMemo(
    () => ({
      getFavoriteReports: getFavoriteReportsForUser,

      add: (report: FavoriteReport) => {
        if (report.dashboardGroupId && report.dashboardId) {
          const reports = getFavoriteReportsForUser();
          reports.unshift(report);
          setFavoriteReports(userId, accountId, reports);
        }
      },

      remove: (report: FavoriteReport) => {
        const reports = getFavoriteReportsForUser();
        _.remove(reports, r => _.isEqual(r, report));
        setFavoriteReports(userId, accountId, reports);
      },

      isReportFavorite: (report: FavoriteReport) => {
        const reports = getFavoriteReportsForUser();
        return reports.some(favoriteReport => _.isEqual(favoriteReport, report));
      },
    }),
    [userId, accountId, getFavoriteReportsForUser, setFavoriteReports],
  );

  return (
    <FavoriteReportsContext.Provider value={value}>{children}</FavoriteReportsContext.Provider>
  );
};

export const useFavoriteReportsContext = () => useContext(FavoriteReportsContext);
