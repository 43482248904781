import React, { useEffect, useRef } from 'react';

export type UseInitialFocusParams = {
  isFocused?: boolean;
  scrollIntoViewIfFocused?: boolean;
};

/**
 * Allow an element to grab focus and scroll into view on mount.
 * @param isFocused
 * @param scrollIntoViewIfFocused
 */
const useInitialFocus = <T extends HTMLElement>({
  isFocused = false,
  scrollIntoViewIfFocused = true,
}: UseInitialFocusParams): React.RefObject<T> => {
  const ref = useRef<T>(null);
  const complete = useRef(false);

  useEffect(() => {
    if (isFocused && !complete.current) {
      if (ref.current) {
        ref.current.focus();
        if (scrollIntoViewIfFocused) {
          ref.current.scrollIntoView();
        }
        complete.current = true;
      }
    }
  }, [isFocused, scrollIntoViewIfFocused]);

  return ref;
};

export default useInitialFocus;
