import React, { useCallback, useMemo } from 'react';
import { UserInvitationFragment } from '../../../../generated/graphql';
import { ReactComponent as IconCopy } from '../../../../assets/icons/lettuce/copy.svg';
import { ReactComponent as IconSend } from '../../../../assets/icons/lettuce/send.svg';
import { ReactComponent as IconDelete } from '../../../../assets/icons/lettuce/delete.svg';
import { ReactComponent as IconNewWindow } from '../../../../assets/icons/lettuce/new-window.svg';
import { ActionMenuItemSpec } from '../../components/Menu/lib';
import Label from '../../components/Label';
import GenericUserItem from './GenericUserItem';
import { UserRoleLabel } from './lib';

export type InvitationItemProps = Pick<UserInvitationFragment, 'id' | 'email' | 'grantedRole'> & {
  isGlobalAdmin?: boolean;
  isExpired?: boolean;
  onReInvite?: () => Promise<void>;
  onRevokeInvitation?: () => Promise<void>;
  onShowInvitationUrl?: () => void;
  onCopyEmail?: () => void;
  isOperationPending?: boolean;
};

const InvitationItem: React.FC<InvitationItemProps> = ({
  isGlobalAdmin,
  onRevokeInvitation,
  onReInvite,
  isExpired,
  grantedRole,
  onCopyEmail,
  onShowInvitationUrl,
  email,
}) => {
  const [loading, setLoading] = React.useState(false);
  const grantedRoleLabel = <Label text={UserRoleLabel[grantedRole]} />;
  const labels = isExpired ? (
    <>
      {grantedRoleLabel}
      <Label variant="red" text="Expired" />
    </>
  ) : (
    grantedRoleLabel
  );
  const reInvite = useCallback(() => {
    if (onReInvite) {
      setLoading(true);
      onReInvite().finally(() => setLoading(false));
    }
  }, [onReInvite]);

  const revokeInvitation = useCallback(() => {
    if (onRevokeInvitation) {
      setLoading(true);
      onRevokeInvitation().finally(() => setLoading(false));
    }
  }, [onRevokeInvitation]);

  const actions: ActionMenuItemSpec[] = useMemo(
    () =>
      [
        {
          Icon: IconDelete,
          label: 'Cancel invitation',
          onToggle: revokeInvitation,
        },
        {
          Icon: IconSend,
          label: 'Re-send invitation',
          onToggle: reInvite,
        },
        {
          Icon: IconCopy,
          label: 'Copy email',
          onToggle: onCopyEmail,
        },
        isGlobalAdmin && {
          Icon: IconNewWindow,
          label: 'View accept URL',
          onToggle: onShowInvitationUrl,
        },
      ].filter(Boolean) as ActionMenuItemSpec[],
    [reInvite, revokeInvitation, isGlobalAdmin, onCopyEmail, onShowInvitationUrl],
  );

  return <GenericUserItem email={email} labels={labels} actions={actions} loading={loading} />;
};

export default InvitationItem;
