import React, { useEffect, useRef, useState } from 'react';
import { Maybe } from '@tellurian/ts-utils';
import { FCC } from '../../../../utils/types';
import useEventCallback from '../../utils/useEventCallback';
import {
  NavRailContext,
  NavRailContextBaseValue,
  useNavRailContext,
} from './NavRailContextProvider';
import NavRail, { NavRailProps } from './index';
import style from './MaybeCompactNavRail.module.css';

const RESIZE_UPDATE_DELAY = 400;

const getDescendantCount = (el: HTMLElement, level: number, currentLevel = 0): number => {
  if (currentLevel === level) {
    return el.childElementCount;
  }

  return Array.from(el.children).reduce((acc, child) => {
    return (
      acc +
      (currentLevel < level ? getDescendantCount(child as HTMLElement, level, currentLevel + 1) : 0)
    );
  }, 0);
};

const useMaybeCompactNavRail = () => {
  const offscreenNavRailRef = useRef<HTMLElement>(null);
  const {
    isCompact,
    setIsCompact,
    hasDeterminedIsCompact,
    setHasDeterminedIsCompact,
    isNavRailReady,
  } = useNavRailContext();
  const [shouldDetermineIsCompact, setShouldDetermineIsCompact] = useState(!hasDeterminedIsCompact);

  const updateCompact = useEventCallback(() => {
    if (offscreenNavRailRef.current) {
      const el = offscreenNavRailRef.current;
      setIsCompact(el.offsetHeight < el.scrollHeight);
    }
  });

  useEffect(() => {
    if (shouldDetermineIsCompact && isNavRailReady) {
      updateCompact();
      setHasDeterminedIsCompact(true);
      setShouldDetermineIsCompact(false);
    }
  }, [shouldDetermineIsCompact, isNavRailReady, updateCompact, setHasDeterminedIsCompact]);

  useEffect(() => {
    let timeoutId: Maybe<number>;

    const onResize = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = window.setTimeout(() => {
        setShouldDetermineIsCompact(true);
        timeoutId = undefined;
      }, RESIZE_UPDATE_DELAY);
    };

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  });

  const navRailRef = useRef<HTMLElement>(null);
  const lastDescendantCountRef = useRef(0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (isNavRailReady && navRailRef.current) {
      const descendantCount = getDescendantCount(navRailRef.current, 1);
      if (descendantCount && descendantCount !== lastDescendantCountRef.current) {
        if (
          !lastDescendantCountRef.current ||
          (descendantCount > lastDescendantCountRef.current && !isCompact) ||
          (descendantCount < lastDescendantCountRef.current && isCompact)
        ) {
          setShouldDetermineIsCompact(true);
        }
        lastDescendantCountRef.current = descendantCount;
      }
    }
  });

  return {
    isCompact,
    offscreenNavRailRef,
    navRailRef,
    shouldDetermineIsCompact,
    hasDeterminedIsCompact,
  };
};

type MaybeCompactNavRailProps = NavRailProps & {
  isReady: boolean;
};

const MaybeCompactNavRail: FCC<MaybeCompactNavRailProps> = ({
  children,
  isReady,
  ...navRailProps
}) => {
  const { setIsNavRailReady, isNavRailReady } = useNavRailContext();
  useEffect(() => {
    isReady && setIsNavRailReady(isReady);
  }, [setIsNavRailReady, isReady]);

  const {
    isCompact,
    shouldDetermineIsCompact,
    hasDeterminedIsCompact,
    navRailRef,
    offscreenNavRailRef,
  } = useMaybeCompactNavRail();

  return (
    <>
      {isNavRailReady && shouldDetermineIsCompact && (
        <NavRailContext.Provider value={NavRailContextBaseValue}>
          <NavRail {...navRailProps} className={style.offscreen} ref={offscreenNavRailRef}>
            {children}
          </NavRail>
        </NavRailContext.Provider>
      )}
      {isNavRailReady && hasDeterminedIsCompact && (
        <NavRail {...navRailProps} isCompact={isCompact} ref={navRailRef}>
          {children}
        </NavRail>
      )}
    </>
  );
};

export default MaybeCompactNavRail;
