import React, { useState } from 'react';
import { useBookmarksContext } from '../../../../../BookmarksContext';
import SavedReports from '../SavedReports';
import StandardViews, { StandardViewsProps } from '../StandardViews';

type ViewProps = {
  accountId: string;
  searchQuery: string;
} & Pick<
  StandardViewsProps,
  'dashboardGroups' | 'dashboardGroupsLoading' | 'getConnectorName' | 'getPath'
>;

const View: React.FC<ViewProps> = ({ accountId, searchQuery, ...standardViewsProps }) => {
  const [hasSavedReportsResults, setHasSavedReportsResults] = useState(false);
  const [hasStandardViewsResults, setHasStandardViewsResults] = useState(false);
  const { bookmarks } = useBookmarksContext();

  return (
    <>
      {!searchQuery ? (
        <div className="mam">Enter a search term.</div>
      ) : (
        <>
          {!hasSavedReportsResults && !hasStandardViewsResults && (
            <div className="mam">No results for &quot;{searchQuery}&quot;.</div>
          )}

          {bookmarks.length > 0 ? (
            <SavedReports
              accountId={accountId}
              searchQuery={searchQuery}
              setHasSavedReportsResults={setHasSavedReportsResults}
            />
          ) : null}
          <StandardViews
            {...standardViewsProps}
            searchQuery={searchQuery}
            setHasStandardViewsResults={setHasStandardViewsResults}
          />
        </>
      )}
    </>
  );
};

export default View;
