import React from 'react';
import { DashboardGroupFragment } from '../../../../../../generated/graphql';
import StandardViews from '../StandardViews/View';
import { useFavoriteReportsContext } from '../../../../components/FavoriteReportsContext';
import { useGetConnectorName } from '../../../dashboards/lib';
import SavedReports from '../SavedReports';
import { Spinner } from '../../../../../ui';
import FavoriteReports, { FavoriteReportsProps } from './FavoriteReports';
import useDashboardGroupsWithDashboardsByTier from './useDashboardGroupsWithDashboardsByTier';

type ViewProps = {
  accountId: string;
  loading: boolean;
  dashboardGroups: DashboardGroupFragment[];
  getPath: (dashboardGroupId: string, dashboardId: string, bookmarkId?: string) => string;
} & Pick<FavoriteReportsProps, 'favoriteReports'>;

const View: React.FC<ViewProps> = ({
  accountId,
  favoriteReports,
  loading,
  dashboardGroups,
  getPath,
}) => {
  const dashboardGroupsWithDashboardsByTier =
    useDashboardGroupsWithDashboardsByTier(dashboardGroups);

  const {
    isReportFavorite,
    add: addFavoriteReport,
    remove: removeFavoriteReport,
  } = useFavoriteReportsContext();
  const getConnectorName = useGetConnectorName(accountId);

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <FavoriteReports
        favoriteReports={favoriteReports}
        dashboardGroups={dashboardGroups}
        getPath={getPath}
      />
      <SavedReports
        accountId={accountId}
        autoScaleItemsVisibleCount={false}
        maxItemsVisibleInSection={5}
        // Do not render message if no reports available for this section
        RenderNoReports={null}
      />
      <StandardViews
        getPath={getPath}
        loading={loading}
        dashboardGroupsWithDashboardsByTier={dashboardGroupsWithDashboardsByTier}
        getConnectorName={getConnectorName}
        isReportFavorite={isReportFavorite}
        addFavoriteReport={addFavoriteReport}
        removeFavoriteReport={removeFavoriteReport}
      />
    </>
  );
};

export default View;
