import React from 'react';
import { ReactComponent as IconFavorite } from '../../../../../../assets/icons/lettuce/heart-filled.svg';
import {
  FavoriteReport,
  useFavoriteReportsContext,
} from '../../../../components/FavoriteReportsContext';
import { DashboardGroupFragment } from '../../../../../../generated/graphql';
import { FnsFormat, useI18nContext } from '../../../../../../utils/I18nContext';
import ReportTitle from '../ReportTitle';
import ListItemLink, { LinkItem } from '../ListItemLink';
import ExpandableList from '../../../../components/ExpandableList';
import listStyle from '../ReportList.module.css';

export type FavoriteReportsProps = {
  dashboardGroups?: DashboardGroupFragment[];
  favoriteReports: FavoriteReport[];
  getPath: (dashboardGroupId: string, dashboardId: string, bookmarkId?: string) => string;
};

const FavoriteReports: React.FC<FavoriteReportsProps> = ({
  dashboardGroups,
  favoriteReports,
  getPath,
}) => {
  const { dateFormat } = useI18nContext();
  const { remove: removeFavoriteReport } = useFavoriteReportsContext();

  const favoriteItems = favoriteReports
    .map((favorite): LinkItem | null => {
      const dashboardGroup = dashboardGroups?.find(g => g.id === favorite.dashboardGroupId);
      const dashboard = dashboardGroup?.dashboards.find(d => d.id === favorite.dashboardId);

      if (!dashboardGroup || !dashboard) return null;

      const favoriteItem = {
        labels: [
          { label: dashboardGroup.name, title: 'Connector Name' },
          { label: dashboard.name, title: 'Dashboard' },
        ],
        labelDescription: 'Report details',
        actions: [
          {
            label: 'Remove favorite',
            onToggle: () => {
              removeFavoriteReport(favorite);
            },
          },
        ],
      };

      if (favorite.bookmarkId) {
        const bookmark = dashboard?.bookmarks.find(b => b.id === favorite.bookmarkId);
        if (!bookmark) return null;

        favoriteItem.labels.push({
          label: dateFormat(FnsFormat.DateYearShort)(new Date(bookmark.createdAtTimestamp)),
          title: 'Created On',
        });
        return {
          ...favoriteItem,
          name: bookmark.description,
          path: getPath(dashboardGroup.id, dashboard.id, bookmark.id),
        };
      }

      return {
        ...favoriteItem,
        name: dashboard.name,
        path: getPath(dashboardGroup.id, dashboard.id),
      };
    })
    .filter((item): item is LinkItem => !!item);

  return favoriteReports.length ? (
    <section className="mrs">
      <ReportTitle
        icon={<IconFavorite />}
        headerText="Favorite Reports"
        itemCount={favoriteReports.length}
        id="favorite-reports-title"
      />
      <ExpandableList
        aria-labelledby="favorite-reports-title"
        className={listStyle.list}
        RenderItem={ListItemLink}
        items={favoriteItems}
      />
    </section>
  ) : null;
};

export default FavoriteReports;
