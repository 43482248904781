import React from 'react';
import { Infobar } from '../ui/Infobar';
import { InfoType } from '../ui/Infobar/lib';
import { useThemeContext } from '../lettuce/components/Theme/ThemeProvider';
import Notification from '../lettuce/components/Notification';
import { NotificationType } from '../lettuce/components/lib/notifications/lib';
import { PageMessage } from './messages';
import styles from './View.module.css';

const InfoTypeToNotificationType: Readonly<Record<InfoType, NotificationType>> = {
  [InfoType.Neutral]: NotificationType.Info,
  [InfoType.Positive]: NotificationType.Success,
  [InfoType.Warning]: NotificationType.Warning,
};

type MessageViewProps = Omit<PageMessage, 'id'>;

export function PageMessageView({ infoType, icon, Render, ...otherProps }: MessageViewProps) {
  const { isLettuceTheme } = useThemeContext();
  return isLettuceTheme ? (
    <Notification
      notificationType={infoType ? InfoTypeToNotificationType[infoType] : NotificationType.Info}
      content={<div>{Render && Render(otherProps)}</div>}
    />
  ) : (
    <Infobar type={infoType ? infoType : InfoType.Neutral}>
      <div className={styles.body}>
        {icon && <div className={styles.icon}>{icon}</div>}
        {Render && Render(otherProps)}
      </div>
    </Infobar>
  );
}
PageMessageView.defaultProps = {
  onDismissed: () => {},
};
