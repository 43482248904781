import React, { useEffect } from 'react';
import { PartnerAccountIdParams } from '../lib';
import { Feature, trackFeature } from '../../../../utils/features';

type SegmentPartnerAccountTrackerProps = PartnerAccountIdParams;

const SegmentPartnerAccountTracker: React.FC<SegmentPartnerAccountTrackerProps> = ({
  partnerAccountId,
}) => {
  useEffect(() => {
    // TODO include customerId as extracted from the JWT when this is made available.
    trackFeature(Feature.PartnerAccountAccess, { partnerAccountId });
  }, [partnerAccountId]);

  return null;
};

export default SegmentPartnerAccountTracker;
