import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { path } from '../../crisp/routing/lib';
import { useIsCompactNavRail } from '../../components/NavRail/NavRailContextProvider';
import PartnerLogo from '../lib/PartnerLogo';
import { PartnerAccount } from '../../components/lib/Account';
// eslint-disable-next-line css-modules/no-unused-class
import style from '../../crisp/navigation/LogoLink.module.css';

type LogoLinkProps = {
  partnerAccount: PartnerAccount;
};

const LogoLink: React.FC<LogoLinkProps> = ({ partnerAccount: { partnerType } }) => {
  return (
    <Link
      to={path('PartnerHome')({ partnerType })}
      className={clsx(style.logoContainer, { [style.compact]: useIsCompactNavRail() })}
    >
      <PartnerLogo partnerType={partnerType} variant="navrail" />
    </Link>
  );
};

export default LogoLink;
