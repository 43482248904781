import React, { ReactNode, useEffect } from 'react';
import { getPendo } from '../lib';
import { useRemoteConfig } from './FirebaseRemoteConfig';

const showPendoGuide = async (guideName: string) => {
  try {
    const pendo = await getPendo();
    await pendo.loadGuides();
    pendo.showGuideByName(guideName);
  } catch (err) {
    console.log('Pendo failed to load', err);
  }
};

export function GlobalSiteGuides({ children }: { children: ReactNode }) {
  const { showGlobalSiteGuide, globalSiteGuideName } = useRemoteConfig();

  useEffect(() => {
    if (showGlobalSiteGuide) {
      showPendoGuide(globalSiteGuideName);
    }
  }, [showGlobalSiteGuide, globalSiteGuideName]);

  return showGlobalSiteGuide ? null : <>{children}</>;
}
