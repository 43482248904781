import React from 'react';
import clsx from 'clsx';
import isTouchDevice from 'is-touch-device';
import ReactDOM from 'react-dom';
import PageError from '../../errorHandling/PageError';
import { FCC } from '../../../utils/types';
import { CloseButton } from './CloseButton';
import ModalBase, { ModalBaseProps } from './ModalBase';
import styles from './index.module.css'; // eslint-disable-line

const ScrollStyle: Pick<React.CSSProperties, 'overflowY' | 'WebkitOverflowScrolling'> = {
  overflowY: isTouchDevice() ? 'scroll' : 'auto',
  WebkitOverflowScrolling: isTouchDevice() ? 'touch' : 'auto',
};

export type ModalProps = {
  children: React.ReactNode;
  bodyStyle?: React.CSSProperties;
  noPadding?: boolean;
  parentNode?: HTMLElement | null;
} & ModalBaseProps;

// Deprecated in Lettuce. Use src/components/lettuce/components/Modal/index.tsx instead.
export const Modal: FCC<ModalProps> = ({
  children,
  bodyStyle,
  noPadding = false,
  parentNode,
  ...modalBaseProps
}) => {
  const ModalWithBackdrop = (
    <ModalBase {...modalBaseProps}>
      <CloseButton onClick={modalBaseProps.onCloseClick} />
      <div
        style={{ ...ScrollStyle, ...bodyStyle }}
        className={clsx(styles.modalBody, { [styles.noPadding]: noPadding })}
      >
        <PageError className={styles.errorMessage} type="modal" />
        {children}
      </div>
    </ModalBase>
  );

  return parentNode ? ReactDOM.createPortal(ModalWithBackdrop, parentNode) : ModalWithBackdrop;
};
