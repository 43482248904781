import React, { CSSProperties } from 'react';
import clsx from 'clsx';
import styles from './PopoverArrow.module.css';

type PopoverArrowProps = {
  className?: string;
  style?: CSSProperties;
};

const PopoverArrow = React.forwardRef<HTMLDivElement, PopoverArrowProps>(function PopoverArrow(
  { className, style }: PopoverArrowProps,
  ref,
) {
  return <div className={clsx(styles.arrow, className)} style={style} ref={ref} />;
});

export default PopoverArrow;
