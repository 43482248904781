import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { ReactComponent as IconNewWindow } from '../../../../assets/icons/lettuce/new-window.svg';
import RenderKeyCombo, { KeyComboSize } from '../../../keyboardSupport/RenderKeyCombo';
import { KeyboardShortcut, KeyCombo } from '../../../keyboardSupport/lib';
import useKeyboardShortcut from '../../../keyboardSupport/useKeyboardShortcut';
import { RenderItemProps } from '../../../ui/lists/listOfItems/ListOfItems';
import { usePopoverContext } from '../NavRail/PopoverActionContext';
import { ExternalLinkTarget } from '../ExternalLink';
import { Feature, trackFeature } from '../../../../utils/features';
import { MenuItemSpec, MenuItemSpecBase } from './lib';
import style from './RenderMenuItem.module.css';

export const ItemSeparator: React.FC = () => <hr className={style.separator} />;

type MenuItemContentProps = Pick<MenuItemSpecBase, 'label' | 'Icon'> & {
  keyCombo?: KeyCombo;
};

const MenuItemContent: React.FC<MenuItemContentProps> = ({ label, Icon, keyCombo }) => {
  if (keyCombo) {
    return (
      <div className={clsx(style.menuItemContent, style.split)}>
        <div className={style.lhs}>
          {Icon && (
            <div className={style.iconContainer}>
              <Icon />
            </div>
          )}
          {label}
        </div>
        <div>
          <RenderKeyCombo keyCombo={keyCombo} size={KeyComboSize.S} className={style.keyCombo} />
        </div>
      </div>
    );
  }

  return (
    <div className={style.menuItemContent}>
      {Icon && (
        <div className={style.iconContainer}>
          <Icon />
        </div>
      )}
      {label}
    </div>
  );
};

export const ExternalLinkMenuItemContent: React.FC<Pick<MenuItemSpecBase, 'label'>> = ({
  label,
}) => {
  return (
    <div className={clsx(style.menuItemContent, style.split)}>
      {label}
      <div className={style.iconContainer}>
        <IconNewWindow />
      </div>
    </div>
  );
};

type MenuItemWithShortcutProps = {
  id?: string;
  label?: string;
  keyboardShortcut: KeyboardShortcut;
  registerShortcut?: boolean;
} & Pick<MenuItemContentProps, 'Icon'>;

type ActionMenuItemProps = MenuItemContentProps & {
  onToggle: () => void;
  featureId?: Feature;
  id?: string;
};

const ActionMenuItem: React.FC<ActionMenuItemProps> = ({ onToggle, featureId, id, ...props }) => {
  const { hide } = usePopoverContext();
  const onClick = useCallback(() => {
    hide();
    onToggle();
    featureId && trackFeature(featureId);
  }, [onToggle, hide, featureId]);
  return (
    <button
      id={id}
      type="button"
      onClick={onClick}
      className={style.menuItem}
      role="menuitem"
      aria-label={props.label}
    >
      <MenuItemContent {...props} />
    </button>
  );
};

const MenuItemRegisterShortcut: React.FC<Omit<MenuItemWithShortcutProps, 'registerShortcut'>> = ({
  keyboardShortcut,
  ...rest
}) => {
  const { fn, ...spec } = keyboardShortcut;
  useKeyboardShortcut(spec, fn);
  return (
    <ActionMenuItem
      onToggle={fn}
      {...rest}
      keyCombo={spec.keyCombo}
      label={rest.label || spec.description}
    />
  );
};

const MenuItemWithShortcut: React.FC<MenuItemWithShortcutProps> = ({
  registerShortcut = true,
  ...props
}) => {
  if (registerShortcut) {
    return <MenuItemRegisterShortcut {...props} />;
  }

  const { keyboardShortcut, label, id } = props;
  return (
    <ActionMenuItem
      id={id}
      label={label || keyboardShortcut.description}
      onToggle={keyboardShortcut.fn}
      keyCombo={keyboardShortcut.keyCombo}
    />
  );
};

const RenderMenuItem: React.FC<RenderItemProps<MenuItemSpec>> = ({ item }) => {
  // Hide popover on menu item click, if this is part of a popover submenu
  const { hide } = usePopoverContext();

  if ('separator' in item) {
    return <ItemSeparator />;
  }

  if ('path' in item) {
    return (
      <Link to={item.path} className={style.menuItem} role="menuitem" onClick={hide} id={item.id}>
        <MenuItemContent {...item} />
      </Link>
    );
  }

  if ('href' in item) {
    const { featureId } = item;
    const onClick = () => {
      hide();
      featureId && trackFeature(featureId);
    };
    return (
      <a
        id={item.id}
        href={item.href}
        className={style.menuItem}
        role="menuitem"
        onClick={onClick}
        target={item.target ?? ExternalLinkTarget.newTab}
      >
        <ExternalLinkMenuItemContent label={item.label} />
      </a>
    );
  }

  if ('onToggle' in item) {
    return <ActionMenuItem {...item} />;
  }

  return <MenuItemWithShortcut {...item} />;
};

export default RenderMenuItem;
