export enum Theme {
  Legacy = 'legacy',
  Lettuce = 'lettuce',
  Advantage = 'advantage',
  HighImpact = 'highImpact',
  Dot = 'dot',
  Unfi = 'unfi',
  Instacart = 'instacart',
  Rema = 'rema',
}

export const ThemeExtensions: Readonly<Partial<Record<Theme, Theme[]>>> = {
  [Theme.Rema]: [Theme.Lettuce],
  [Theme.Advantage]: [Theme.Lettuce],
  [Theme.HighImpact]: [Theme.Lettuce],
  [Theme.Dot]: [Theme.Lettuce],
  [Theme.Instacart]: [Theme.Lettuce],
};
