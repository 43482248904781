import { MutableRefObject, Ref } from 'react';

const updateRef = <T>(ref: NonNullable<Ref<T>>, instance: T | null) => {
  if (typeof ref === 'function') {
    ref(instance);
  } else {
    (ref as MutableRefObject<T | null>).current = instance;
  }
};

const composeTwoRefs = <T>(ref1: Ref<T>, ref2: Ref<T>): Ref<T> => {
  if (ref1 && ref2) {
    return (instance: T | null): void => {
      updateRef(ref1, instance);
      updateRef(ref2, instance);
    };
  }

  return ref1 || ref2;
};

/**
 * Reduce a series of React refs into one, since only one can be passed to a component.
 * This is useful when multiple hooks require a ref of the component, so rather than extending the ref in the
 * hook or just passing it as a param (some 3rd party utils do not allow this), compose the refs just before
 * passing to a component.
 *
 * E.g.
 * const ref1 = useSomeComponentResizeLogic();
 * const ref2 = useOnClickAway();
 *
 * return <MyComponent ref={composeRefs(ref1, ref2)} />
 * @param refs
 */
const composeRefs = <T>(...refs: [Ref<T>, Ref<T>, ...Ref<T>[]]): Ref<T> => {
  if (refs.length === 2) {
    return composeTwoRefs(refs[0], refs[1]);
  }

  return refs.slice(1).reduce((lhs, rhs) => composeTwoRefs(lhs, rhs), refs[0]);
};

export default composeRefs;

export const composeRefsInProps = <T, P extends { ref: Ref<T> }>(
  props: P,
  ref: Ref<T>,
  ...refs: Ref<T>[]
): P => {
  props.ref = composeRefs(props.ref, ref, ...refs);
  return props;
};
