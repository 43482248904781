import { Ref, useEffect, useRef } from 'react';
import { useFloatingContext } from '../components/lib/FloatingContext';
import useEventCallback from './useEventCallback';

type MouseEventType = 'click' | 'mouseDown' | 'mouseUp' | 'pointerDown' | 'pointerUp';
type MouseEventListenerType = Lowercase<MouseEventType>;

const getSiblings = (el: HTMLElement, direction: 'next' | 'previous'): Element[] => {
  const res: Element[] = [];
  const accessor = `${direction}ElementSibling`;
  let sibling = el[accessor];
  while (sibling) {
    res.push(sibling);
    sibling = sibling[accessor];
  }
  return res;
};

/**
 * Returns true if the click event is determined to have originated from a floating element that is
 * regarded as child (or "on top") of element, even if this is not reflected in the DOM node relationship, due to
 * the use of React portals.
 * E.g. Suppose we open popover B on top of an existing popover A. Since both popovers are opened with portals, these
 * will be rendered as siblings in the portal root node (identified by portalRootId) and consequently, any click
 * on popover B will be interpreted as a click away and dismiss both A and B. The expected behaviour is that
 * clicks on B should be handled as if B were a child node of A, and not dismiss A on click away.
 * isMouseEventOnStackedElement will return true when a click originated in A such that B need not
 * interpret it as a click away.
 */
const isMouseEventOnStackedElement = (
  isInEventPath: (el: Element) => boolean,
  element: HTMLElement,
  portalRoot: HTMLElement,
): boolean => {
  if (element.parentElement !== portalRoot) {
    return false;
  }

  if (isInEventPath(portalRoot)) {
    const siblings = getSiblings(element, 'next');
    // Check if mouse event originated on a subsequent sibling
    if (siblings.every(sibling => !isInEventPath(sibling))) {
      // If the mouse event originated on a previous sibling, then the event is of a lower hierarchical origin
      if (getSiblings(element, 'previous').some(sibling => isInEventPath(sibling))) {
        return false;
      }
    }

    return true;
  }

  return false;
};

type UseOnClickAwayParams = {
  onClickAway: (e: MouseEvent) => void;
  enabled?: boolean;
  mouseEvent?: MouseEventType;
  document?: Document;
  // If specified, it will not trigger the handler if a click is observed within siblings subsequent to the referenced element
  // in the portal root.
  excludeSiblingsInPortalId?: string;
};

const useOnClickAway = <T extends HTMLElement>({
  enabled = true,
  mouseEvent = 'click',
  document = global.document,
  onClickAway,
}: UseOnClickAwayParams): Ref<T> => {
  const excludeSiblingsInPortalId = useFloatingContext().getFloatingPortalNodeId();
  const ref = useRef<T>(null);

  const handleOnClickAway = useEventCallback((e: MouseEvent) => {
    if (enabled && ref.current) {
      const path = new Set(e.composedPath());
      const isInEventPath = (el: Element) => path.has(el);
      if (!isInEventPath(ref.current)) {
        if (excludeSiblingsInPortalId) {
          const portalRoot = document.getElementById(excludeSiblingsInPortalId);
          if (
            !(portalRoot && isMouseEventOnStackedElement(isInEventPath, ref.current, portalRoot))
          ) {
            onClickAway(e);
          }
        } else {
          onClickAway(e);
        }
      }
    }
  });

  useEffect(() => {
    if (enabled) {
      const eventName = mouseEvent.toLowerCase() as MouseEventListenerType;
      setTimeout(() => document.addEventListener(eventName, handleOnClickAway), 0);
      return () => document.removeEventListener(eventName, handleOnClickAway);
    }
  }, [mouseEvent, handleOnClickAway, document, enabled]);

  return ref;
};

export default useOnClickAway;
