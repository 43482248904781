import React from 'react';
import PageTemplate from '../../crisp/navigation/PageTemplate';
import { lazyWithRetry } from '../../../lib';
import UnfiInsightsAdmin from '../admin/UnfiInsightsAdmin';

const RenderUnfiInsightsAdmin = lazyWithRetry<typeof UnfiInsightsAdmin>(
  () => import(/* webpackChunkName: "unfiInsightsAdmin" */ '../admin/UnfiInsightsAdmin'),
);

export default function UnfiInsightsAdminPage() {
  return (
    <PageTemplate requireGlobalAdmin={true}>
      <RenderUnfiInsightsAdmin />
    </PageTemplate>
  );
}
