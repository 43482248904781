import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  AuthError as FirebaseAuthError,
  getAuth,
  linkWithCredential,
  OAuthProvider,
  signInWithCredential,
} from 'firebase/auth';
import _ from 'lodash';
import { useOktaAuth } from '@okta/okta-react';
import { AuthSdkError, OAuthError, UserClaims } from '@okta/okta-auth-js';
import { AuthProvider } from '../../../../generated/graphql';
import { useAuthenticationContext } from '../../../security/AuthenticationContext';
import { LocalStorageKey } from '../../../../utils/localStorage';
import useLocalStorageState from '../../../../utils/localStorage/useLocalStorageState';
import CrispApp from '../../crisp/CrispApp';
import logger from '../../../../services/logger';
import useAccessToken from '../../common/authentication/useAccessToken';
import { generateNonce, isLinked, toAuthError, AuthError, AuthState, providerInfo } from './lib';
import useFirebaseIdTokenResult from './useFirebaseIdTokenResult';

export const useSignIn = ({ config }: { config: AuthProvider }) => {
  const [nonce, setNonce, refetchNonce] = useLocalStorageState(
    LocalStorageKey.PartnerMinisiteOAuthNonce,
    undefined,
  );
  const initialized = useRef(false);
  const hasSetRedirectPromise = useRef(false);
  const oktaAuthResult = useRef<UserClaims>();
  const idTokenRef = useRef<string>();

  const [internalState, setInternalState] = useState(AuthState.Loading);
  const [error, setError] = useState<AuthError>();

  const { firebaseUser, loading: firebaseLoading } = useAuthenticationContext();

  const { oktaAuth, authState: oktaAuthState } = useOktaAuth();
  const activeAccount = useCallback(async () => {
    await oktaAuth.getUser().then(user => {
      return user;
    });
  }, [oktaAuth]);
  const { accessToken, setAccessToken } = useAccessToken('partner_access_token');
  const firebaseIdTokenResult = useFirebaseIdTokenResult();
  const signInProvider = firebaseIdTokenResult?.signInProvider;

  const onFirebaseSuccess = useCallback(() => {
    setNonce(undefined);
    setInternalState(AuthState.Authenticated);
  }, [setInternalState, setNonce]);

  const onOktaError = useCallback(
    (error: OAuthError) => {
      if (error.errorCode === 'interaction_required' || error.errorCode === 'user_cancelled') {
        setInternalState(AuthState.LoginRequired);
      } else {
        logger.error('OktaError error:', error);
        setError(toAuthError(error));
      }
    },
    [setInternalState],
  );

  const signInWithRedirect = useCallback(() => {
    const nonce = generateNonce();
    setNonce(nonce);
    oktaAuth
      .signInWithRedirect({
        scopes: config.scopes || ['openid', 'email', 'profile', 'offline_access'],
        nonce: nonce.nonce,
      })
      .catch(error => onOktaError(error));
  }, [setNonce, oktaAuth, config.scopes, onOktaError]);

  const onFirebaseError = useCallback(
    (error: FirebaseAuthError) => {
      if (error.code === 'auth/account-exists-with-different-credential') {
        setInternalState(AuthState.UserAlreadyExists);
      } else if (error.code === 'auth/missing-or-invalid-nonce') {
        setNonce(undefined);
        setInternalState(AuthState.LoginRequired);
      } else {
        setNonce(undefined);
        logger.error('Parnter MiniSite - Firebase error:', error);
        setError(toAuthError(error));
      }
    },
    [setInternalState, setNonce],
  );

  const firebaseSignIn = useCallback(
    (idToken: string, rawNonce: string) => {
      const provider = new OAuthProvider(config.providerId);
      const scopes = config.scopes || ['email', 'profile', 'openid'];
      scopes.forEach(scope => provider.addScope(scope));

      const credential = provider.credential({
        idToken: idToken,
        rawNonce: rawNonce,
      });

      signInWithCredential(getAuth(), credential).then(onFirebaseSuccess).catch(onFirebaseError);
    },
    [config.providerId, config.scopes, onFirebaseSuccess, onFirebaseError],
  );

  const linkUsers = useCallback(() => {
    setInternalState(AuthState.Loading);
    const idToken = idTokenRef.current;

    if (nonce && idToken) {
      if (firebaseUser) {
        const provider = new OAuthProvider(config.providerId);
        const scopes = config.scopes || ['email', 'profile', 'openid'];
        scopes.forEach(scope => provider.addScope(scope));
        const credential = provider.credential({
          idToken: idToken,
          rawNonce: nonce.rawNonce,
        });

        linkWithCredential(firebaseUser, credential).then(onFirebaseSuccess).catch(onFirebaseError);
      } else {
        setError({
          origin: 'link_users',
          code: 'missing_firebase_user',
          message: 'Missing Firebase user',
        });
      }
    } else {
      setInternalState(AuthState.LoginRequired);
    }
  }, [nonce, firebaseUser, config.providerId, config.scopes, onFirebaseSuccess, onFirebaseError]);

  const onOktaSuccess = useCallback(() => {
    refetchNonce();
    if (oktaAuthResult.current) {
      const idTokenClaims = oktaAuthResult.current;
      const firebaseEmail = firebaseUser?.email;
      const oktaEmail = idTokenClaims?.email as string | undefined;

      if (!firebaseUser && nonce) {
        firebaseSignIn(idTokenRef.current || '', nonce.rawNonce);
      } else if (!firebaseUser) {
        setNonce(undefined);
        signInWithRedirect();
      } else if (
        isLinked({
          providerId: config.providerId,
          sub: idTokenClaims.sub || '',
          firebaseUser: firebaseUser,
        })
      ) {
        setInternalState(AuthState.Authenticated);
      } else if (
        firebaseEmail &&
        oktaEmail &&
        firebaseEmail.toLowerCase() === oktaEmail.toLowerCase()
      ) {
        linkUsers();
      } else {
        setInternalState(AuthState.SignedInWithAnotherUser);
      }
    } else {
      setError({
        origin: 'sign_in',
        code: 'missing_account',
        message: 'No account in response',
      });
    }
  }, [
    refetchNonce,
    firebaseUser,
    nonce,
    config.providerId,
    firebaseSignIn,
    setNonce,
    signInWithRedirect,
    linkUsers,
  ]);

  const continueWithPartnerUser = useCallback(() => {
    setInternalState(AuthState.Loading);
    const idToken = idTokenRef.current;

    if (nonce && idToken) {
      firebaseSignIn(idToken, nonce.rawNonce);
    } else {
      signInWithRedirect();
    }
  }, [firebaseSignIn, setInternalState, nonce, signInWithRedirect]);

  useEffect(() => {
    if (!hasSetRedirectPromise.current && oktaAuth.isLoginRedirect()) {
      hasSetRedirectPromise.current = true;
      oktaAuth
        .handleLoginRedirect()
        .then(() => {
          oktaAuth.tokenManager.getTokens().then(tokens => {
            oktaAuth.authStateManager.updateAuthState();
            oktaAuthResult.current = tokens.idToken?.claims;
            idTokenRef.current = tokens.idToken?.idToken;
            setAccessToken(tokens?.accessToken?.accessToken || '');
          });
          onOktaSuccess();
        })
        .catch((error: AuthSdkError) => {
          setError({
            origin: 'okta',
            code: error.errorCode,
            message: error.message,
          });
        });
    }
  }, [oktaAuth, oktaAuthState?.idToken?.claims, onOktaError, onOktaSuccess, setAccessToken]);

  useEffect(() => {
    if (
      oktaAuthState?.idToken?.claims &&
      oktaAuthResult.current !== oktaAuthState?.idToken?.claims
    ) {
      oktaAuthResult.current = oktaAuthState?.idToken?.claims;
    }
  }, [oktaAuthState?.idToken?.claims]);

  useEffect(() => {
    if (!initialized.current && !firebaseLoading && !oktaAuth.isLoginRedirect()) {
      initialized.current = true;
      if (oktaAuthResult.current) {
        onOktaSuccess();
      } else if (oktaAuthState?.isAuthenticated) {
        oktaAuth.token
          .getWithRedirect()
          .then(() => {
            onOktaSuccess();
          })
          .catch(error => {
            if (
              error.errorCode === 'token_refresh_required' ||
              error.errorCode === 'invalid_grant'
            ) {
              signInWithRedirect();
            } else {
              onOktaError(error);
            }
          });
      } else {
        signInWithRedirect();
      }
    }
  }, [
    firebaseLoading,
    firebaseUser,
    activeAccount,
    firebaseSignIn,
    setInternalState,
    setNonce,
    signInWithRedirect,
    oktaAuth,
    oktaAuthState?.isAuthenticated,
    onOktaSuccess,
    onOktaError,
    oktaAuthState?.idToken?.claims,
  ]);

  const state = useMemo(() => {
    const oktaEmail = oktaAuthState?.idToken?.claims.email as string | undefined;
    if (
      !oktaAuth.isLoginRedirect() &&
      oktaAuthState?.isAuthenticated &&
      firebaseUser &&
      oktaAuthState.accessToken &&
      signInProvider
    ) {
      // User is signed in with Partner credentials and Firebase user is linked
      if (
        isLinked({
          providerId: config.providerId,
          sub: oktaAuthState?.idToken?.claims.sub || '',
          firebaseUser: firebaseUser,
        }) &&
        signInProvider === config.providerId
      ) {
        localStorage.removeItem('LinkProvider');
        return AuthState.Authenticated;
      }
      // Firebase user is not linked with Partner credentials, but the email matches the authenticated Partner user.
      // Show the loading screen while we link credentials.
      else if (
        signInProvider !== config.providerId &&
        oktaEmail &&
        firebaseUser.email?.toLowerCase() === oktaEmail?.toLowerCase() &&
        !providerInfo(firebaseUser, config.providerId)
      )
        return AuthState.Loading;
      // UNFI credentials does not match the Firebase user
      else return AuthState.FirebaseProviderUserMismatch;
    } else if (internalState === AuthState.Authenticated && (!firebaseUser || !activeAccount)) {
      return AuthState.LoginRequired;
    } else if (error) {
      return AuthState.Failed;
    } else {
      return internalState;
    }
  }, [
    oktaAuthState?.idToken?.claims.email,
    oktaAuthState?.idToken?.claims.sub,
    oktaAuthState?.isAuthenticated,
    oktaAuthState?.accessToken,
    oktaAuth,
    firebaseUser,
    signInProvider,
    internalState,
    activeAccount,
    error,
    config.providerId,
  ]);

  useEffect(() => {
    oktaAuth.authStateManager.subscribe(authState => {
      if (authState.isAuthenticated) {
        oktaAuthResult.current = authState?.idToken?.claims;
        setAccessToken(authState?.accessToken?.accessToken || '');
        idTokenRef.current = authState?.idToken?.idToken;
      }
    });
    oktaAuth.start();
  }, [oktaAuth, onOktaSuccess, setAccessToken]);

  useEffect(() => {
    if (state !== internalState) {
      setInternalState(state);
    }
  }, [state, internalState, setInternalState]);

  useEffect(() => {
    CrispApp.partnerMiniSite.getOktaUser = () => oktaAuthState?.idToken?.claims || undefined;
    return () => {
      CrispApp.partnerMiniSite.getOktaUser = () => undefined;
    };
  }, [activeAccount, oktaAuthState?.idToken?.claims]);

  useEffect(() => {
    CrispApp.partnerMiniSite.getFirebaseUser = () =>
      firebaseUser ? _.pick(firebaseUser, 'displayName', 'email', 'providerData') : undefined;
    return () => {
      CrispApp.partnerMiniSite.getFirebaseUser = () => undefined;
    };
  }, [firebaseUser]);

  return {
    authProvider: config,
    oktaUser: oktaAuthResult.current,
    azureUser: null,
    error: error,
    state: state,
    nonce: nonce,
    accessToken: accessToken,
    firebaseUser,
    linkUsers,
    signInWithRedirect,
    continueWithPartnerUser,
  };
};
