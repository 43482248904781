import React from 'react';
import { lazyWithRetry } from '../../../lib';
import PartnerTemplate from '../templates/PartnerTemplate';
import MaxWidthLayout from '../../components/layout/MaxWidthLayout';
import { PartnerIdMatchParams } from './lib';

const RenderHome = lazyWithRetry(() => import(/* webpackChunkName: "partnerHome" */ '../Home'));

const HomePage: React.FC<PartnerIdMatchParams> = ({
  match: {
    params: { partnerAccountId },
  },
}) => {
  return (
    <PartnerTemplate Layout={MaxWidthLayout}>
      <RenderHome partnerAccountId={partnerAccountId} />
    </PartnerTemplate>
  );
};

export default HomePage;
