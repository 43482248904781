import useClipboardTextState from './useClipboardTextState';

type UseClipboardCopyResult = {
  copyToClipboard: () => void;
  isValueCopied: boolean;
  isValueCopiable: boolean;
};

const useClipboardCopy = (value: string): UseClipboardCopyResult => {
  const [clipboardContent, setClipboardContent, { isClipboardWriteProhibited }] =
    useClipboardTextState();

  return {
    copyToClipboard: () => setClipboardContent(value),
    isValueCopied: clipboardContent === value,
    isValueCopiable: !!value && !isClipboardWriteProhibited,
  };
};

export default useClipboardCopy;
