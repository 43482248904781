import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { BookmarkFragment } from '../generated/graphql';
import { FCC } from '../utils/types';
import { useGetDashboardGroups } from './businessDashboard/hooks/useGetDashboardGroups';
import { getAccountId } from './lettuce/crisp/routing/lib';

export type DashboardInfo = {
  dashboardGroupId: string;
  dashboardGroupName: string;
  dashboardId: string;
  dashboardName: string;
};

export type BookmarkFragmentWithDashboardInfo = BookmarkFragment & DashboardInfo;

export type BookmarksInterface = {
  bookmarks: BookmarkFragmentWithDashboardInfo[];
  loading: boolean;
  addBookmark: (b: BookmarkFragmentWithDashboardInfo) => void;
  updateBookmark: (b: BookmarkFragmentWithDashboardInfo) => void;
  refetch: () => void;
};

export const BookmarksContext = React.createContext<BookmarksInterface>({
  bookmarks: [],
  loading: false,
  addBookmark: () => {},
  updateBookmark: () => {},
  refetch: () => {},
});

export const BookmarksProvider: FCC = ({ children }) => {
  const location = useLocation();
  const pathname = location.pathname;
  const accountId = getAccountId(pathname);

  const [lastUpdated, setLastUpdated] = useState(new Date());
  const {
    dashboardGroups,
    loading,
    refetch: refetchDashboardGroups,
  } = useGetDashboardGroups(accountId);
  const [bookmarks, setBookmarks] = useState<BookmarkFragmentWithDashboardInfo[]>([]);

  useEffect(() => {
    if (dashboardGroups.length) {
      setBookmarks(
        dashboardGroups.flatMap(g =>
          g.dashboards.flatMap(d =>
            d.bookmarks.flatMap(b => ({
              ...b,
              dashboardGroupId: g.id,
              dashboardGroupName: g.name,
              dashboardId: d.id,
              dashboardName: d.name,
            })),
          ),
        ),
      );
    }
  }, [dashboardGroups, lastUpdated]);

  const addBookmark = (b: BookmarkFragmentWithDashboardInfo) => {
    const newBookmarks = [...bookmarks];
    newBookmarks.push(b);
    setBookmarks(newBookmarks);
  };

  const updateBookmark = (updatedBookmark: BookmarkFragmentWithDashboardInfo) => {
    setBookmarks(bookmarks =>
      bookmarks.map(b => (b.id === updatedBookmark.id ? updatedBookmark : b)),
    );
    refetch();
  };

  const refetch = useCallback(() => {
    // force bookmarks to be recalculated after refetching dashboard groups by
    // setting lastUpdated
    refetchDashboardGroups().then(() => setLastUpdated(new Date()));
  }, [refetchDashboardGroups, setLastUpdated]);

  return (
    <BookmarksContext.Provider
      value={{
        bookmarks,
        loading,
        addBookmark,
        updateBookmark,
        refetch,
      }}
    >
      {children}
    </BookmarksContext.Provider>
  );
};

export const useBookmarksContext = () => useContext(BookmarksContext);
