import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';

const RetailAnalyticsAuthCallback = () => {
  const { instance } = useMsal();
  const [error, setError] = useState<string>();

  useEffect(() => {
    instance
      .handleRedirectPromise()
      .then(res => {
        if (res && res.state) {
          window.location.href = res.state;
        }
      })
      .catch(err => {
        // TODO handle redirect error with options to continue (e.g. try again / return home)
        console.log('redirect error:', err);
        setError(err.message);
      });
  }, [instance]);

  if (error) {
    return <div>{error}</div>;
  }
};

export default RetailAnalyticsAuthCallback;
