import React from 'react';
import clsx from 'clsx';
import { ReactComponent as IconCheck } from '../../../../assets/icons/check_16x16.svg';
import style from './CheckboxInput.module.css';

export type CheckboxInputProps = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'onChange' | 'type'
> & {
  onChange?: (checked: boolean) => void;
};

export const CheckboxInput: React.FC<CheckboxInputProps> = ({
  onChange,
  checked = false,
  ...props
}) => {
  return (
    <div className={style.container}>
      <div className={clsx(style.overlay, { [style.checked]: checked })} aria-hidden={true}>
        {checked ? <IconCheck /> : null}
      </div>
      <input
        checked={checked}
        className={style.input}
        onChange={() => onChange?.(!checked)}
        type="checkbox"
        {...props}
      />
    </div>
  );
};
