import { useCallback, useEffect, useRef, useState } from 'react';
import { Maybe } from '@tellurian/ts-utils';
import { isNumber } from '../../../lib';

export default function useClipboardTextState(
  autoUpdateAfterMs: Maybe<number> = 2000,
): [Maybe<string>, (newContent: Maybe<string>) => void, { isClipboardWriteProhibited: boolean }] {
  const [clipboardContent, setClipboardContent] = useState<Maybe<string>>();
  const [cannotWrite, setCannotWrite] = useState(false);
  const clipboardClearTimeout = useRef<Maybe<number>>();

  const clearTimeout = useCallback(() => {
    const to = clipboardClearTimeout.current;
    if (to) {
      window.clearTimeout(to);
      clipboardClearTimeout.current = undefined;
    }
  }, []);

  useEffect(() => {
    const clearContent = () => setClipboardContent(undefined);
    window.addEventListener('focus', clearContent);
    return () => {
      window.removeEventListener('focus', clearContent);
    };
  }, []);

  useEffect(() => clearTimeout, [clearTimeout]);

  const updateContent = useCallback(
    (maybeText: Maybe<string>) => {
      setClipboardContent(maybeText);
      clearTimeout();
      if (isNumber(autoUpdateAfterMs) && typeof maybeText === 'string') {
        clipboardClearTimeout.current = window.setTimeout(
          () => setClipboardContent(undefined),
          autoUpdateAfterMs,
        );
      }
    },
    [autoUpdateAfterMs, clearTimeout],
  );

  const copyToClipboard = useCallback(
    (maybeText: Maybe<string>) => {
      if (!cannotWrite && document.hasFocus()) {
        navigator?.clipboard
          ?.writeText(maybeText || '')
          .catch(() => setCannotWrite(true))
          .finally(() => updateContent(maybeText));
      }
    },
    [cannotWrite, updateContent],
  );

  return [clipboardContent, copyToClipboard, { isClipboardWriteProhibited: cannotWrite }];
}
