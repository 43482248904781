import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AuthError, AuthResponse, Configuration, UserAgentApplication } from 'msal';
import { useAuthenticationContext } from '../../security/AuthenticationContext';
import { trackError } from '../../../utils/errorTracking';

const {
  REACT_APP_REMA_AUTH_CLIENT_ID,
  REACT_APP_REMA_AUTH_AUTHORITY,
  REACT_APP_POWERBI_AUTH_REDIRECT_URI,
} = process.env;

let msal = new UserAgentApplication({
  auth: {
    clientId: `${REACT_APP_REMA_AUTH_CLIENT_ID}`,
    authority: REACT_APP_REMA_AUTH_AUTHORITY,
    redirectUri: REACT_APP_POWERBI_AUTH_REDIRECT_URI,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
  system: {
    tokenRenewalOffsetSeconds: 600,
  },
});

export function azureLogout() {
  if (msal.getAccount()) msal.logout();
}

export type AzureAuthenticationError = {
  errorCode: string;
  errorMessage?: string;
};

export type AzureAuthenticationInterface = {
  token?: string;
  loading: boolean;
  error?: AzureAuthenticationError;
};

export const AzureAuthenticationContext = React.createContext<AzureAuthenticationInterface>({
  token: 'token',
  loading: true,
});

export function AzureAuthenticationContextProvider({
  refreshInterval,
  children,
  msalConfiguration,
  usePopupAuthFlow = false,
}: {
  refreshInterval: number;
  children: React.ReactNode;
  msalConfiguration?: Configuration;
  usePopupAuthFlow?: boolean;
}) {
  const { firebaseUser, loading: fbLoading } = useAuthenticationContext();
  const [userEmail, setUserEmail] = useState<string>();
  const [token, setToken] = useState<string>();
  const [authError, setAuthError] = useState<AzureAuthenticationError>();
  const handleRedirectCallbackResponse = useCallback(() => {}, []);
  const handleRedirectCallbackError = useCallback((err: AuthError) => {
    setAuthError({ errorCode: err.errorCode, errorMessage: err.errorMessage });
  }, []);

  useEffect(() => {
    if (
      msalConfiguration &&
      msalConfiguration.auth.clientId !== msal.getCurrentConfiguration().auth.clientId
    ) {
      msal = new UserAgentApplication(msalConfiguration);
      msal.handleRedirectCallback(handleRedirectCallbackResponse, handleRedirectCallbackError);
    }
  }, [msalConfiguration, handleRedirectCallbackResponse, handleRedirectCallbackError]);

  const acquireTokenPopup = useCallback(
    loginRequest => {
      msal
        .acquireTokenPopup(loginRequest)
        .then((result: AuthResponse) => {
          setToken(result.accessToken);
        })
        .catch((err: AuthError) => {
          trackError(err, `Azure auth error (${err.errorCode})`);
          setAuthError({ errorCode: err.errorCode, errorMessage: err.errorMessage });
        });
    },
    [setAuthError],
  );

  const refreshToken = useCallback(() => {
    if (userEmail && !msal.getLoginInProgress()) {
      const loginRequest = {
        scopes: [
          'https://analysis.windows.net/powerbi/api/Report.Read.All',
          'https://analysis.windows.net/powerbi/api/Workspace.Read.All',
          'https://analysis.windows.net/powerbi/api/UserState.ReadWrite.All',
        ],
        loginHint: userEmail,
      };

      if (msal.getAccount()) {
        msal
          .acquireTokenSilent(loginRequest)
          .then((response: AuthResponse) => {
            setToken(response.accessToken);
          })
          .catch((err: AuthError) => {
            switch (err.errorCode) {
              case 'login_required':
              case 'interaction_required':
              case 'consent_required': {
                if (!msal.getLoginInProgress()) {
                  if (usePopupAuthFlow) acquireTokenPopup(loginRequest);
                  else msal.acquireTokenRedirect(loginRequest);
                }
                break;
              }
              default: {
                console.error(`Azure auth error (${err.errorCode})`, err);
                setAuthError({ errorCode: err.errorCode, errorMessage: err.errorMessage });
              }
            }
          });
      } else {
        if (usePopupAuthFlow) acquireTokenPopup(loginRequest);
        else msal.acquireTokenRedirect(loginRequest);
      }
    }
  }, [userEmail, setToken, usePopupAuthFlow, acquireTokenPopup]);

  useEffect(() => {
    if (firebaseUser?.email) {
      setUserEmail(firebaseUser.email);
    } else {
      firebaseUser
        ?.getIdTokenResult()
        .then(result => {
          const email = result?.claims?.firebase?.sign_in_attributes?.upn;
          if (email) {
            setUserEmail(email);
          } else {
            setAuthError({
              errorCode: 'firebase_email',
              errorMessage: 'Unable to get email address from Firebase ID token',
            });
          }
        })
        .catch((error: Error) => {
          setAuthError({ errorCode: 'firebase_id_token' });
          console.error('Error fetching id token:', error);
        });
    }
  }, [firebaseUser, fbLoading]);

  // Get a new token now and refresh it on a timer
  useEffect(() => {
    refreshToken();
    const tokenTimer = setInterval(() => {
      refreshToken();
    }, refreshInterval);
    return () => clearTimeout(tokenTimer);
  }, [refreshToken, refreshInterval]);

  useEffect(() => {
    msal.handleRedirectCallback(
      () => {},
      (err: AuthError) => {
        setAuthError({ errorCode: err.errorCode, errorMessage: err.errorMessage });
      },
    );
  }, []);

  return (
    <AzureAuthenticationContext.Provider
      value={{
        loading: !token && !authError,
        token,
        error: authError,
      }}
    >
      {children}
    </AzureAuthenticationContext.Provider>
  );
}

export const useAzureAuthenticationContext = () => useContext(AzureAuthenticationContext);
