import React, { useMemo } from 'react';
import { capitalCase } from 'change-case';
import { BookmarkFragmentWithDashboardInfo } from '../../../../BookmarksContext';
import KeyValueExpandableList, { KeyValuePair } from '../../../components/KeyValueExpandableList';

const EXCLUDED_FILTER_KEYS = new Set(['embed_domain']);

// extract a readable filter name since cross-filter keys do not have friendly names, e.g.
// "tile_id4232ae62fbb612da3a62fb370def7201.distributor_monthly_sales_temp.location_zip_code"
// => "Location Zip Code"
const formatFilterKey = (key: string): string => {
  if (key.startsWith('tile')) {
    const parts = key.split('.');
    const finalPart = parts[parts.length - 1];
    return capitalCase(finalPart);
  }
  return key;
};

const formatFilterValue = (value: string): string => {
  return value || 'any';
};

export const getReportFiltersForDisplay = (
  report: BookmarkFragmentWithDashboardInfo,
): KeyValuePair[] =>
  report.filters
    .filter(f => !EXCLUDED_FILTER_KEYS.has(f.filterKey))
    .map(({ filterValue, filterKey }) => ({
      key: formatFilterKey(filterKey),
      value: formatFilterValue(filterValue),
    }));

export type SaveReportFiltersProps = {
  report: BookmarkFragmentWithDashboardInfo;
  maxItemsVisible?: number;
};

const SavedReportFilters: React.FC<SaveReportFiltersProps> = ({ report, maxItemsVisible = 20 }) => {
  return (
    <KeyValueExpandableList
      maxItemsVisible={maxItemsVisible}
      items={useMemo(() => getReportFiltersForDisplay(report), [report])}
    />
  );
};

export default SavedReportFilters;
