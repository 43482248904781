import { MouseEvent, useEffect, useMemo, useState } from 'react';
import { noop, range } from '@tellurian/ts-utils';
import { KeyHandlerEventProps, OnKeyDownHandler } from '../../getListKeyHandler';
import { Key } from '../../../../../utils/keyCodes';
import useSingleton from '../../../../../utils/useSingleton';
import useEventCallback from '../../../../lettuce/utils/useEventCallback';

type GetResetIndexOnItemCountChange = (itemCount: number, focusedIndex: number) => number;
export const getCurrentOrLastItemIndex: GetResetIndexOnItemCountChange = (
  itemCount,
  focusedIndex,
) => (itemCount ? (focusedIndex >= itemCount ? itemCount - 1 : focusedIndex) : itemCount);

export const getFirstItemIndex: GetResetIndexOnItemCountChange = () => 0;

export type UseFocusableItem = (itemIndex: number) => {
  isFocused: boolean;
  onMouseEnter: (e: MouseEvent) => void;
  onMouseMove: (e: MouseEvent) => void;
  onMouseDown: (e: MouseEvent) => void;
};

export type FocusParentProps = {
  onKeyDown?: OnKeyDownHandler;
};

type FocusableItemIndex = {
  setItemCount: (nextItemCount: number) => void;
  getFocusedIndex: () => number;
  setFocusedIndex: (nextIndex: number) => void;
  useFocusableItem: UseFocusableItem;
  getFocusParentProps: () => FocusParentProps;
};

type FocusableItemIndexParams = {
  initialItemCount: number;
  initialIndex?: number;
  onChange?: (nextIndex: number) => void;
  getFocusTransitionsEnabled: () => boolean;
  setFocusTransitionsEnabled: (enabled: boolean) => void;
};

const focusableItemIndex = ({
  initialItemCount,
  initialIndex = 0,
  onChange,
  getFocusTransitionsEnabled,
  setFocusTransitionsEnabled,
}: FocusableItemIndexParams): FocusableItemIndex => {
  type FocusCallback = (isFocused: boolean) => void;
  const itemCallbackRegistry = new Map<number, FocusCallback>();

  let focusedIndex = initialIndex;
  let willFocusIndexOnMouseEnter = true;
  let willFocusOnMouseMove = false;
  let itemCount = initialItemCount;

  const setFocusedIndex = (nextIndex: number) => {
    itemCallbackRegistry.get(focusedIndex)?.(false);
    itemCallbackRegistry.get(nextIndex)?.(true);
    focusedIndex = nextIndex;
    onChange?.(nextIndex);
  };

  const setItemCount = (nextItemCount: number) => {
    if (itemCount !== nextItemCount) {
      if (nextItemCount < itemCount) {
        range(nextItemCount, itemCount).forEach(i => {
          itemCallbackRegistry.delete(i);
        });
      }
      itemCount = nextItemCount;
      willFocusOnMouseMove = true;
    }
  };

  // Convenience getter to allow usage "on demand", in response to other events and not the index changed events.
  // Essentially allows users to avoid having to update a parent state variable in response to onChange.
  const getFocusedIndex = () => focusedIndex;

  const getFocusParentProps = () => {
    return {
      onKeyDown: (e: KeyHandlerEventProps) => {
        if (getFocusTransitionsEnabled()) {
          const isShiftTab = e.key === Key.Tab && e.shiftKey;
          if (e.key === Key.UpArrow || isShiftTab) {
            if (focusedIndex > 0) {
              setFocusedIndex(focusedIndex - 1);
              e.preventDefault();
            }
            // Do not trap focus if the first focusable item is already focused.
            if (!isShiftTab) {
              e.preventDefault();
            }
          } else if (e.key === Key.DownArrow || e.key === Key.Tab) {
            if (focusedIndex < itemCount - 1) {
              setFocusedIndex(focusedIndex + 1);
              e.preventDefault();
            }
            // Do not trap focus if the last focusable item is already focused.
            if (e.key !== Key.Tab) {
              e.preventDefault();
            }
          } else if (e.key === Key.Home) {
            setFocusedIndex(0);
            e.preventDefault();
          } else if (e.key === Key.End) {
            setFocusedIndex(itemCount - 1);
            e.preventDefault();
          } else {
            return false;
          }

          willFocusIndexOnMouseEnter = false;
          return true;
        }
        return false;
      },
    };
  };

  return {
    setItemCount,
    setFocusedIndex,
    getFocusedIndex,
    getFocusParentProps,
    useFocusableItem: (itemIndex: number) => {
      const [isFocused, setIsFocused] = useState(false);

      useEffect(() => {
        itemCallbackRegistry.set(itemIndex, setIsFocused);
      }, [itemIndex]);

      useEffect(() => {
        if (isFocused && focusedIndex !== itemIndex) {
          itemCallbackRegistry.get(focusedIndex)?.(false);
          focusedIndex = itemIndex;
        } else {
          setIsFocused(focusedIndex === itemIndex);
        }
      }, [isFocused, itemIndex]);

      const props = useMemo(() => {
        return {
          onMouseEnter: () => {
            if (getFocusTransitionsEnabled() && willFocusIndexOnMouseEnter) {
              setFocusedIndex(itemIndex);
            }
          },
          onMouseMove: () => {
            if (
              getFocusTransitionsEnabled() &&
              (willFocusOnMouseMove || !willFocusIndexOnMouseEnter)
            ) {
              willFocusIndexOnMouseEnter = true;
              willFocusOnMouseMove = false;
              setFocusedIndex(itemIndex);
            }
          },
          onMouseDown: () => {
            willFocusIndexOnMouseEnter = true;
            setFocusTransitionsEnabled(true);
            if (focusedIndex !== itemIndex) {
              setFocusedIndex(itemIndex);
            }
          },
        };
      }, [itemIndex]);

      return { isFocused, ...props };
    },
  };
};

export type FocusableItemFocusParentProps = FocusParentProps & {
  onFocus?: () => void;
  onBlur?: () => void;
  focus: boolean;
};

export type UseFocusableItemIndexParams = {
  initialFocusedIndex?: number;
  itemCount: number;
  onChange?: FocusableItemIndexParams['onChange'];
  initiallyActive?: boolean;
  getResetIndexOnItemCountChange?: GetResetIndexOnItemCountChange;
};

export type UseFocusableItemIndex = Pick<
  FocusableItemIndex,
  'getFocusedIndex' | 'useFocusableItem' | 'setItemCount' | 'setFocusedIndex'
> & {
  focusTransitionsEnabled: boolean;
  getFocusParentProps: () => FocusableItemFocusParentProps;
};

export const useFocusableItemIndex = ({
  itemCount,
  initialFocusedIndex = 0,
  onChange: onChangeProp,
  initiallyActive = true,
  getResetIndexOnItemCountChange = getCurrentOrLastItemIndex,
}: UseFocusableItemIndexParams): UseFocusableItemIndex => {
  const [focusTransitionsEnabled, setFocusTransitionsEnabled] = useState(initiallyActive);
  const onChange = useEventCallback(onChangeProp ?? noop);
  const getFocusTransitionsEnabled = useEventCallback(() => focusTransitionsEnabled);
  const focusable = useSingleton(() =>
    focusableItemIndex({
      initialItemCount: itemCount,
      initialIndex: initialFocusedIndex,
      getFocusTransitionsEnabled,
      setFocusTransitionsEnabled,
      onChange,
    }),
  );

  const { getFocusedIndex, setFocusedIndex, setItemCount } = focusable;

  useEffect(() => {
    setItemCount(itemCount);
    const focusedIndex = getFocusedIndex();
    setFocusedIndex(getCurrentOrLastItemIndex(itemCount, focusedIndex));
  }, [itemCount, getFocusedIndex, setFocusedIndex, setItemCount, getResetIndexOnItemCountChange]);

  return useMemo(
    () => ({
      ...focusable,
      setItemCount: (nextItemCount: number) => setItemCount(nextItemCount),
      getFocusParentProps: () => ({
        ...focusable.getFocusParentProps(),
        focus: focusTransitionsEnabled,
        onBlur: () => setFocusTransitionsEnabled(false),
        onFocus: () => setFocusTransitionsEnabled(true),
      }),
      focusTransitionsEnabled,
    }),
    [focusTransitionsEnabled, focusable, setItemCount],
  );
};
