import React from 'react';
import UnfiInsightsEmbeddedTemplate from '../templates/UnfiInsightsEmbeddedTemplate';
import { useSetPageTitle } from '../../../../utils/hooks';
import { lazyWithRetry } from '../../../lib';
import DeductionsOverview from '../Deductions';

const RenderDeductionsOverview = lazyWithRetry<typeof DeductionsOverview>(
  () => import(/* webpackChunkName: "unfiEmbeddedDeductions" */ '../Deductions'),
);

export default function UnfiInsightsEmbeddedDeductionsPage() {
  useSetPageTitle('Deductions Tool');

  return (
    <UnfiInsightsEmbeddedTemplate>
      <RenderDeductionsOverview />
    </UnfiInsightsEmbeddedTemplate>
  );
}
