import React, { CSSProperties } from 'react';
import clsx from 'clsx';
import { FCC } from '../../../../utils/types';
import styles from './index.module.css'; // eslint-disable-line

export const FlexSizes = ['L', 'M', 'S', 'XS'] as const;
export type FlexSize = (typeof FlexSizes)[number];

type FlexProps = {
  size?: FlexSize;
  style?: CSSProperties;
  className?: string;
  wrap?: boolean;
  spaceBetween?: boolean;
  column?: boolean;
};

const Flex: FCC<FlexProps> = ({
  size = 'M',
  style,
  className,
  column = false,
  children,
  wrap,
  spaceBetween,
}) => {
  return (
    <div
      className={clsx(
        styles.flex,
        styles[size],
        { [styles.wrap]: wrap, [styles.spaceBetween]: spaceBetween, [styles.column]: column },
        className,
      )}
      style={style}
    >
      {children}
    </div>
  );
};

export default Flex;
