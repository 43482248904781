import React from 'react';
import { MsalProvider } from '@azure/msal-react';
import msalInstance from '../components/lettuce/crisp/RetailAnalytics/auth/msalInstance';
import RetailAnalyticsAuthCallback from '../components/lettuce/crisp/RetailAnalytics/auth/AuthCallback';

const RetailAnalyticsAuthPage = () => {
  return (
    <MsalProvider instance={msalInstance}>
      <RetailAnalyticsAuthCallback />
    </MsalProvider>
  );
};

export default RetailAnalyticsAuthPage;
