import React from 'react';
import { RouteComponentProps } from 'react-router';
import EditConnectorConfiguration from '../../../connectors/configurations/Edit';
import ScrollToTop from '../../../Utils/ScrollToTop';
import { lazyWithRetry } from '../../../lib';
import ConnectorsTemplate from '../templates/ConnectorsTemplate';

const RenderEditConnectorConfiguration = lazyWithRetry<typeof EditConnectorConfiguration>(
  () =>
    import(
      /* webpackChunkName: "editConnectorConfiguration" */ '../../../connectors/configurations/Edit'
    ),
);

type MatchParams = {
  accountId: string;
  connectorConfigurationSlug: string;
};

export default function EditConnectorConfigurationPage({
  match: { params },
}: RouteComponentProps<MatchParams>) {
  return (
    <ConnectorsTemplate>
      <ScrollToTop />
      <RenderEditConnectorConfiguration {...params} />
    </ConnectorsTemplate>
  );
}
