const { REACT_APP_UNFI_INSIGHTS_REDIRECT_URI, REACT_APP_UNFI_INSIGHTS_REDIRECT_URI_EMBEDDED } =
  process.env;

const REDIRECT_URI = REACT_APP_UNFI_INSIGHTS_REDIRECT_URI || '';
const REDIRECT_URI_EMBEDDED = REACT_APP_UNFI_INSIGHTS_REDIRECT_URI_EMBEDDED || '';

export const resolveUnfiInsightsRedirectUri = (): string => {
  if (window.location.hostname === process.env.REACT_APP_UNFI_INSIGHTS_EMBEDDED_HOSTNAME)
    return REDIRECT_URI_EMBEDDED;
  return REDIRECT_URI;
};

export const isYOYDashboard = (dashboardName: string) =>
  dashboardName.toLowerCase().startsWith('year over year');

export const isDCInventoryDashboard = (dashboardName: string) =>
  dashboardName.toLowerCase().includes('distribution center inventory');
