import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import _ from 'lodash';
import { UserAccountRole } from '../../../../generated/graphql';
import { Spinner, SpinnerSize } from '../../../ui';
import { FormElement, FormElementProps } from '../../../ui/form/FormFields';
import DashboardPermissionsSelection, {
  DashboardPermissions,
  DashboardPermissionsSelectionProps,
} from './DashboardPermissionsSelection';
import PermissionsSelectionCheckButtons from './PermissionsSelectionCheckButtons';

type DashboardMultiSelectFormElementProps = Omit<
  FormElementProps<string[]>,
  'render' | 'defaultValue'
> &
  Partial<Pick<FormElementProps<DashboardPermissions>, 'defaultValue'>> &
  Pick<DashboardPermissionsSelectionProps, 'accountId'>;

const DashboardMultiSelectFormField: React.FC<DashboardMultiSelectFormElementProps> = ({
  name,
  id,
  defaultValue,
  ...fieldProps
}) => {
  const { resetField, setValue } = useFormContext();
  const lastDefaultValueRef = React.useRef(defaultValue);
  useEffect(() => {
    if (!_.isEqual(lastDefaultValueRef.current, defaultValue)) {
      lastDefaultValueRef.current = defaultValue;
      resetField(name, { defaultValue });
    }
  }, [resetField, defaultValue, name]);

  return (
    <FormElement
      name={name}
      id={id}
      defaultValue={defaultValue}
      render={props => (
        <DashboardPermissionsSelection
          {...fieldProps}
          {...props}
          // Call set value explicitly here, since props.onChange will set the value to undefined for no obvious reason.
          onChange={nextValue => setValue(name, nextValue)}
        />
      )}
    />
  );
};

type PermissionsSelectionCheckButtonsFormElementProps = Omit<
  FormElementProps<string[]>,
  'render' | 'defaultValue'
> &
  Partial<Pick<FormElementProps<UserAccountRole>, 'defaultValue'>>;

const PermissionsSelectionCheckButtonsFormField: React.FC<
  PermissionsSelectionCheckButtonsFormElementProps
> = ({ name, id, defaultValue, ...fieldProps }) => {
  return (
    <FormElement
      name={name}
      id={id}
      defaultValue={defaultValue}
      render={props => <PermissionsSelectionCheckButtons {...fieldProps} {...props} />}
    />
  );
};

export default function AccountRoleAndDashboardPermissionsSelection({
  accountId,
  defaultDashboardPermissions,
  defaultUserRole,
  loading,
  userRole,
}: {
  accountId: string;
  defaultDashboardPermissions: DashboardPermissions;
  defaultUserRole: UserAccountRole;
  loading: boolean;
  userRole: UserAccountRole;
}) {
  return (
    <>
      <div className="mls">
        <PermissionsSelectionCheckButtonsFormField name="userRole" defaultValue={defaultUserRole} />
      </div>
      {userRole === UserAccountRole.Member ? (
        loading ? (
          <Spinner size={SpinnerSize.SMALL} center={false} className="mll" />
        ) : (
          <DashboardMultiSelectFormField
            accountId={accountId}
            name="dashboardSelection"
            defaultValue={defaultDashboardPermissions}
          />
        )
      ) : null}
    </>
  );
}
