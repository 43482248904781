import React, { Suspense } from 'react';
import { Redirect, useLocation } from 'react-router';
import { ContentWidth, MainContent } from '../../../ui';
import Spinner from '../../components/Spinner';
import PageError from '../../../errorHandling/PageError';
import { withQueryParams } from '../../../../utils/querystringUtils';
import { FCC } from '../../../../utils/types';
import Nav from '../Nav';
import { useUnfiAuthenticationContext } from '../auth/UnfiAuthenticationProvider';
import { UnfiAuthState } from '../auth/lib';
import { unfiEmbeddedPath } from '../routing/Routes';
import style from './UnfiInsightsEmbeddedTemplate.module.css';

type UnfiInsightsEmbeddedTemplateProps = {
  mainContentWidth?: ContentWidth;
};

const UnfiInsightsEmbeddedTemplate: FCC<UnfiInsightsEmbeddedTemplateProps> = ({
  mainContentWidth = ContentWidth.Floating,
  children,
}) => {
  const location = useLocation();
  const { azureUser, firebaseUser, state, error } = useUnfiAuthenticationContext();

  if (error) {
    return (
      <div className={'mhm mvl'}>
        <p>Error: {error?.message}</p>
        <p>Origin: {error?.origin}</p>
        <p>Code: {error?.code}</p>
      </div>
    );
  }

  if (state === UnfiAuthState.Loading || state === UnfiAuthState.FirebaseAzureUserMismatch) {
    return <Spinner />;
  }

  if (!azureUser || !firebaseUser) {
    return (
      <Redirect
        to={withQueryParams({
          returnTo: `${location.pathname}${location.search}`,
        })(unfiEmbeddedPath('UnfiInsightsEmbeddedSignIn')())}
      />
    );
  }
  return (
    <>
      <Nav />
      <main id="main" className={style.content}>
        {/* Temp: Fix body pushed down due to header margin top*/}
        <div style={{ height: 1 }} />
        <MainContent>
          <PageError type="page" hideIfTypePresent="modal" />
        </MainContent>
        <MainContent width={mainContentWidth}>
          <Suspense>{children}</Suspense>
        </MainContent>
      </main>
    </>
  );
};

export default UnfiInsightsEmbeddedTemplate;
