import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import useInvalidCredentialsToast from '../../../Toast/useInvalidCredentialsToast';
import { FCC } from '../../../../utils/types';
import { AdminAuthorityGrant } from '../../../security/AuthorizationContext';
import Forbidden from '../../../connectors/Forbidden';
import { MaxWidthLayoutProps } from '../../components/layout/MaxWidthLayout';
import AccountTemplate from './AccountTemplate';

type MatchParams = {
  accountId?: string;
};

const ConnectorsTemplate: FCC<Pick<MaxWidthLayoutProps, 'size'>> = ({
  children,
  ...templateProps
}) => {
  const {
    params: { accountId },
  } = useRouteMatch<MatchParams>();

  useInvalidCredentialsToast(accountId);
  return (
    <AccountTemplate
      requiredGrant={AdminAuthorityGrant}
      RenderForbidden={Forbidden}
      {...templateProps}
    >
      {children}
    </AccountTemplate>
  );
};

export default ConnectorsTemplate;
