import { RefObject } from 'react';

const { REACT_APP_LOOKER_INSTANCE_URL } = process.env;

export enum LookerMessageType {
  DashboardFiltersChanged = 'dashboard:filters:changed',
  PageChanged = 'page:changed',
  PagePropertiesChanged = 'page:properties:changed',
  DashboardLoaded = 'dashboard:loaded',
  DashboardRunStart = 'dashboard:run:start',
  DashboardRunComplete = 'dashboard:run:complete',
  DashboardTileDownload = 'dashboard:tile:download',
}

export interface LookerEvent {
  type: string;
  // eslint-disable-next-line
  [key: string]: any;
}

export interface LookerPageProperties {
  height: number;
  absoluteUrl: string;
  url: string;
}

export type LookerEventHandler = (event: LookerEvent) => void;

export class EmbeddedLooker {
  iframeRef: RefObject<HTMLIFrameElement>;
  eventHandler: (event: LookerEvent) => void;
  onMessage = event => {
    const { data, source, origin } = event;

    try {
      const cw = this.iframeRef?.current?.contentWindow;
      if (cw && source === cw && origin === REACT_APP_LOOKER_INSTANCE_URL) {
        const parsedEvent = JSON.parse(data);
        this.eventHandler(parsedEvent);
      }
    } catch (err) {}
  };

  constructor(eventHandler: (event: LookerEvent) => void, iframe: RefObject<HTMLIFrameElement>) {
    this.iframeRef = iframe;
    this.eventHandler = eventHandler;

    window.addEventListener('message', this.onMessage);
  }

  removeEventListener() {
    window.removeEventListener('message', this.onMessage);
  }

  postMessage(data) {
    const cw = this.iframeRef?.current?.contentWindow;
    cw && cw.postMessage(JSON.stringify(data), REACT_APP_LOOKER_INSTANCE_URL || '');
  }
}
