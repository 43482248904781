import pluralize from 'pluralize';
import Color from 'color';
import { isMatch } from 'date-fns';
import { Maybe } from '@tellurian/ts-utils';

type ValidationResult = true | string;

export type ValidationFn<T> = (value: T) => ValidationResult;

export const isNumeric = (value: string): boolean => !isNaN(+value) && value !== '';

// Validation function providers

export const required: (message: string) => ValidationFn<unknown> =
  (message = 'Required.') =>
  value =>
    value === 0 || !!value || message;

export const minLength =
  (length: number, message = `The value must be at least ${length} characters.`) =>
  (value: string) =>
    value.length >= length || message;

export const maxLength =
  (length: number, message = `The value must be maximum ${length} characters.`) =>
  (value: string) =>
    value.length <= length || message;

export const minSelectionCount =
  (
    selectionCount: number,
    entity = 'element',
    message = `Please select at least ${selectionCount} ${pluralize(entity, selectionCount)}.`,
  ) =>
  (value: unknown[]) =>
    value.length >= selectionCount || message;

export const minContrastRatio = (referenceColor: string, ratio: number) => (value: string) =>
  Color(referenceColor).contrast(Color(value)) >= ratio || 'Contrast ratio is too low.';

export const distinct =
  <T>(isValueIncluded: (element: T) => boolean, message = 'The value is already included.') =>
  (value: T): ValidationResult =>
    !isValueIncluded(value) || message;

export const distinctArrayElements = <T>(
  arr: T[],
  equals: (e1: T, e2: T) => boolean,
  message?: string,
) => {
  const isValueIncluded = (element: T) => arr.some(e => equals(e, element));
  return distinct(isValueIncluded, message);
};

export const isValidEmail = value => /^\S+@\S+\.\S+$/.test(value);

export const includesLowerCaseLetters = (message: string) => (value: string) =>
  /[a-z]/.test(value) || message;

export const includesUpperCaseLetters = (message: string) => (value: string) =>
  /[A-Z]/.test(value) || message;

export const includesSpecialCharacters = (message: string) => (value: string) =>
  /[!@#$%^&*()_\-+=]/.test(value) || message;

export const includesDigits = (message: string) => (value: string) => /\d/.test(value) || message;

// Validation functions

export const email: ValidationFn<string> = value =>
  isValidEmail(value) || 'The email address is invalid.';

export const number: ValidationFn<string> = value => isNumeric(value) || 'Please enter a number.';

export const positiveInteger: ValidationFn<string> = value =>
  /^\d+$/.test(value) || 'Please enter a positive number.';

export const currencyUSD: ValidationFn<string> = value => {
  const valueToCheck = value.replaceAll(',', '');
  return /^-?\$?(\d)+(\.\d\d?)?$/.test(valueToCheck) || 'Please enter a dollar amount.';
};

export const currencyUSDPositive: ValidationFn<string> = value => {
  const valueToCheck = value.replaceAll(',', '');
  return (
    (Number(valueToCheck) !== 0 && /^\$?(\d)+(\.\d\d?)?$/.test(valueToCheck)) ||
    'Please enter a positive dollar amount.'
  );
};

export const range: ValidationFn<string> = value => {
  const num = parseFloat(value);
  return (num > 0 && num < 100) || 'Please enter a number between 0 and 100.';
};

export const numberGreaterThan =
  (
    greaterThanValue: number,
    message = `Please enter a number greater than ${greaterThanValue}.`,
  ): ValidationFn<string> =>
  value => {
    return (isNumeric(value) && parseFloat(value) > greaterThanValue) || message;
  };

/**
 * Validates a date in US format, e.g. 06/27/2022
 */
export const dateUS: ValidationFn<string> = value => {
  /**
   * There is currently a bug in date-fns isMatch that causes any numeric
   * value with 1-4 digits to match the 'yyyy' pattern.
   * https://github.com/date-fns/date-fns/issues/2087
   *
   * To get around this, check if the year part of the date is actually a
   * 4-digit year from the 20th or 21st century.\
   */
  const hasValidYear = value => {
    const splitData = value.split('/');
    return /^(19|20)\d\d$/.test(splitData[splitData.length - 1]);
  };

  return (
    (isMatch(value, 'M/d/yyyy') && hasValidYear(value)) ||
    'Please enter a date value of the form mm/dd/yyyy.'
  );
};

export const requiredField = required('This field is required.');

export const maxFileSizeMB =
  (maxMB: number, message = `Please select a file smaller than ${maxMB}MB.`) =>
  (value: File) =>
    value.size <= maxMB * 1000000 || message;

export const hasValidExtension =
  (extensions: string[], message = 'Please select a valid file.') =>
  (value: File) =>
    extensions.some(ext => value.name.toLowerCase().endsWith(`.${ext}`)) || message;

export const maybeFileValidation =
  (fileValidationFns: ValidationFn<File>[]) => (value: Maybe<File>) =>
    value
      ? fileValidationFns.reduce<true | string>(
          (res, fn) => (typeof res === 'string' ? res : fn(value)),
          true,
        )
      : true;
