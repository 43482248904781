import React, { FunctionComponent, ReactNode, useEffect, useRef } from 'react';

import styles from './index.module.css'; // eslint-disable-line
import clsx from 'clsx';
import { FeatureProps, invokesFeature, trackFeature } from '../../../utils/features';
import { useThemeContext } from '../../lettuce/components/Theme/ThemeProvider';
import LettuceButtonAdapter from './temp/LettuceButtonAdapter';

export enum ButtonSize {
  L = 'large',
  M = 'medium',
  S = 'small',
}

export enum ButtonStyle {
  BORDER = 'border',
  EMPHASIS = 'emphasis',
  LINK = 'href',
  PRIMARY = 'primary',
  LIGHT_BORDER = 'light-border', // intended to match the Dropdown button
  ICON_HOVER = 'icon-hover', // like Dropdown Style ICON_HOVER, for use with icon-only buttons
  LINK_DEFAULT_TEXT_COLOR = 'link-default-text-color',
}

export enum ButtonType {
  Submit,
  HtmlSubmit, // Should be used with a <form> wrapper for auto-submit a11y
  Button,
}

type RenderBodyProps = {
  children: ReactNode;
};

function DefaultRenderBody({ children }: RenderBodyProps) {
  return <div className={styles.body}>{children}</div>;
}

export type ButtonProps = {
  block?: boolean;
  btnSize?: ButtonSize;
  btnStyle: ButtonStyle;
  btnType?: ButtonType;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  icon?: React.ReactNode;
  onClick: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
  style?: React.CSSProperties;
  testId?: string;
  iconPosition?: 'left' | 'right';
  RenderBody?: FunctionComponent<RenderBodyProps>;
} & FeatureProps &
  React.HTMLProps<HTMLButtonElement>;

const isTextAreaFocused = () =>
  document.activeElement && document.activeElement.tagName === 'TEXTAREA';

export const LegacyButton = React.forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  {
    block = false,
    btnSize = ButtonSize.M,
    btnStyle,
    btnType = ButtonType.Button,
    children,
    className,
    disabled = false,
    icon,
    iconPosition = 'left',
    onClick,
    style,
    testId,
    featureId,
    RenderBody = DefaultRenderBody,
    ...buttonProps
  }: ButtonProps,
  ref,
) {
  const buttonEl = useRef<HTMLButtonElement | null>(null);
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && buttonEl.current && !isTextAreaFocused()) {
      e.preventDefault();
      buttonEl.current.click();
    }
  };

  useEffect(() => {
    /* This seems to have been added for keyboard accessibility of forms,
    while trying to avoid making sure that each form is wrapped in a <form>
    tag. This doesn't always work well and we should let the browser handle this.
    (For example, the usage in the ConfirmWithModal was triggering onSave twice, which
    toggled the modal twice, effectively not hiding it)
    The `HtmlSubmit` type has been included as a better alternative but requires the
    use of <form> around the form itself. */
    if (btnType === ButtonType.Submit && !disabled) {
      window.addEventListener('keydown', handleKeyDown);

      return function cleanup() {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
  });

  const renderIcon = iconClassName => (
    <div className={clsx(styles.iconContainer, { [iconClassName]: !!children })}>{icon}</div>
  );

  return (
    <button
      ref={el => {
        buttonEl.current = el;
        if (ref) {
          typeof ref === 'function' ? ref(el) : (ref.current = el);
        }
      }}
      {...buttonProps}
      disabled={disabled}
      className={clsx(
        styles.button,
        'no-wrap',
        styles[btnSize],
        styles[btnStyle],
        { [styles[`${btnStyle}Disabled`]]: disabled },
        className,
      )}
      onClick={event => {
        featureId && trackFeature(featureId);
        onClick(event);
      }}
      style={Object.assign({}, style, { width: block ? '100%' : 'auto' })}
      type={btnType === ButtonType.HtmlSubmit ? 'submit' : 'button'}
      data-testid={testId}
      {...invokesFeature(featureId)}
    >
      <RenderBody>
        {icon && iconPosition === 'left' && renderIcon('mrs')}
        {children}
        {icon && iconPosition === 'right' && renderIcon('mls')}
      </RenderBody>
    </button>
  );
});

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  function SelectButton(props, ref) {
    const { isLettuceTheme } = useThemeContext();
    return isLettuceTheme ? (
      <LettuceButtonAdapter {...props} ref={ref} />
    ) : (
      <LegacyButton {...props} ref={ref} />
    );
  },
);
