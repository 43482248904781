import React, { ReactElement } from 'react';
import clsx from 'clsx';
import ReactMarkdown from 'react-markdown';
import style from './index.module.css';

export type FieldDetailsProps = {
  error?: string;
  description?: string | ReactElement;
  id?: string;
  className?: string;
  isDescriptionMarkdown?: boolean;
};

const FieldDetails: React.FC<FieldDetailsProps> = ({
  id,
  error,
  description,
  isDescriptionMarkdown = false,
  className,
}) => {
  if (error || description) {
    const hasMarkdownDescription =
      description && typeof description === 'string' && isDescriptionMarkdown;
    return (
      <div
        id={id}
        className={clsx(
          style.supportingText,
          { [style.error]: !!error, [style.hasMarkdownDescription]: hasMarkdownDescription },
          className,
        )}
      >
        {error ||
          (hasMarkdownDescription ? <ReactMarkdown>{description}</ReactMarkdown> : description)}
      </div>
    );
  }

  return null;
};

export default FieldDetails;
