import { Dispatch, SetStateAction, useState } from 'react';
import useListOptionControlHandlers, {
  UseListOptionControlHandlers,
  UseListOptionControlHandlersProps,
} from './useListOptionControlHandlers';

type UseListOptionFocusWithControlHandlersProps = Omit<
  UseListOptionControlHandlersProps,
  'focusedIndex' | 'setFocusedIndex' | 'setIsFocusChangedByKeyboardEvent'
> & {
  initialFocusedIndex?: number;
};

type UseListOptionFocusWithControlHandlers = {
  focusedIndex: number;
  onFocusedIndexChange: Dispatch<SetStateAction<number>>;
  isFocusChangedByKeyboardEvent: boolean;
} & UseListOptionControlHandlers;

const useListOptionFocusWithControlHandlers = ({
  initialFocusedIndex = 0,
  ...rest
}: UseListOptionFocusWithControlHandlersProps): UseListOptionFocusWithControlHandlers => {
  const [focusedIndex, setFocusedIndex] = useState(initialFocusedIndex);
  const [isFocusChangedByKeyboardEvent, setIsFocusChangedByKeyboardEvent] = useState(false);

  return {
    ...useListOptionControlHandlers({
      focusedIndex,
      setFocusedIndex,
      setIsFocusChangedByKeyboardEvent,
      ...rest,
    }),
    focusedIndex,
    isFocusChangedByKeyboardEvent,
    onFocusedIndexChange: setFocusedIndex,
  };
};

export default useListOptionFocusWithControlHandlers;
