import React from 'react';
import clsx from 'clsx';
import useInitialFocus, { UseInitialFocusParams } from '../useInitialFocus';
import styles from './index.module.css';

type BasicTextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  hasError?: boolean;
  focused?: boolean;
};

export type TextAreaProps = {
  value: string;
  onChange?: (nextValue: string) => void;
  className?: string;
} & Omit<BasicTextAreaProps, 'className' | 'focused'> &
  UseInitialFocusParams;

export const TextArea: React.FC<TextAreaProps> = ({
  value,
  onChange,
  className,
  isFocused,
  scrollIntoViewIfFocused,
  hasError,
  disabled,
  ...renderTextAreaProps
}) => {
  return (
    <textarea
      ref={useInitialFocus({ isFocused, scrollIntoViewIfFocused })}
      {...renderTextAreaProps}
      className={clsx(styles.textArea, { [styles.hasError]: hasError }, className)}
      disabled={disabled}
      value={value}
      onChange={e => onChange?.(e.target.value)}
    />
  );
};
