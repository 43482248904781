import React from 'react';
import {
  DropdownListOption,
  DropdownListOptions,
  getItemKey,
  RenderListItem,
} from '../lib/dropdownListOption';
import ChipDropdownSelect, { ChipDropdownSelectProps } from './ChipDropdownSelect';
import { useSelectItems } from './lib';

type ChipDropdownOptionSelectProps = {
  options: DropdownListOptions;
  selectedValue?: string;
  onChange: (nextValue: string) => void;
} & Pick<
  ChipDropdownSelectProps<DropdownListOption>,
  'getChipText' | 'chipSize' | 'placement' | 'backdrop' | 'popoverId' | 'getPopoverSizeProps'
>;

const ChipDropdownOptionSelect: React.FC<ChipDropdownOptionSelectProps> = ({
  options,
  onChange,
  selectedValue,
  ...rest
}) => {
  return (
    <ChipDropdownSelect<DropdownListOption>
      {...useSelectItems({ options, selectedValue })}
      onChange={option => onChange(option.value)}
      RenderItem={RenderListItem}
      getItemKey={getItemKey}
      {...rest}
    />
  );
};

export default ChipDropdownOptionSelect;
