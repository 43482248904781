import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Maybe } from '@tellurian/ts-utils';
import useLocalStorageState from '../../utils/localStorage/useLocalStorageState';
import { LocalStorageKey } from '../../utils/localStorage';
import { getAccountId } from '../lettuce/crisp/routing/lib';
import { AccountDetails, LocalDb } from './lib';

export const useRecentAccountsTracker = (): void => {
  const history = useHistory();
  const [_, setRecentAccounts] = useLocalStorageState(
    LocalStorageKey.AdminConsoleRecentAccounts,
    [],
  );
  const currentAccountIdRef = useRef<Maybe<string>>();

  useEffect(() => {
    const updateRecentAccounts = async (pathname: string) => {
      const accountId = getAccountId(pathname);
      if (accountId && accountId !== currentAccountIdRef.current) {
        currentAccountIdRef.current = accountId;
        // eslint-disable-next-line testing-library/no-await-sync-queries
        const account = await LocalDb.accounts.getById(accountId);
        if (account) {
          setRecentAccounts(currentRecentAccounts => {
            if (currentRecentAccounts[0]?.id !== account.id) {
              return [account, ...currentRecentAccounts.filter(acc => acc.id !== accountId)].slice(
                0,
                5,
              );
            }

            return currentRecentAccounts;
          });
        } else {
          setRecentAccounts(currentRecentAccounts =>
            currentRecentAccounts.filter(acc => acc.id !== accountId),
          );
        }
      }
    };

    updateRecentAccounts(window.location.pathname);

    return history.listen(async ev => {
      updateRecentAccounts(ev.pathname);
    });
  }, [history, setRecentAccounts]);
};

export const useRecentAccounts = (): AccountDetails[] => {
  return useLocalStorageState(LocalStorageKey.AdminConsoleRecentAccounts, [])[0];
};
