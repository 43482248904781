import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AnyObject, Maybe } from '@tellurian/ts-utils';
import _ from 'lodash';
import useEventCallback from '../../../utils/useEventCallback';
import { trackError } from '../../../../../utils/errorTracking';
import { WorkspaceDetails } from '../../BiReport/api';
import { usePowerBiAuthentication } from '../../BiReport/PowerBiAuthenticationContext';
import { useRequestCache } from './ApiRequestCacheContext';

export const ReportTypes = ['PowerBIReport', 'PaginatedReport'] as const;
export type ReportType = (typeof ReportTypes)[number];

const QueryRetry = (maxRetryCount = 1) => {
  let queryToRetry: Maybe<string> = undefined;
  let retryCount = 0;

  const maybeRetry = (fn: (query: string) => void) => {
    if (queryToRetry && retryCount < maxRetryCount) {
      retryCount++;
      fn(queryToRetry);
    }
  };

  return {
    setQueryToRetry: (query: string) => {
      queryToRetry = query;
    },
    maybeRetry,
    getQueryToRetry: () => queryToRetry,
    resetRetry: () => {
      queryToRetry = undefined;
      retryCount = 0;
    },
  };
};

const useRetryQuery = (maxRetryCount = 1) => {
  return useRef(QueryRetry(maxRetryCount)).current;
};

type GenericError = string | AnyObject;

export type FetchPolicy = 'cache-first' | 'cache-and-network' | 'network-only';

export type UsePowerBiLazyQueryOptions = {
  startLoading: boolean;
  method: 'GET' | 'POST';
  failSilently: boolean;
  // If a retry is scheduled, then the loading state will persist to true until the retry is fulfilled.
  // This is to prevent a potentially short intermediate state with no data when a token should be refreshed.
  continueLoadingOnRetry?: boolean;
  /**
   * cache-first = try retrieving from cache and _only_ if not available, fetch from network;
   * cache-and-network = retrieve the value from cache and also send the request down the wire
   * for the latest results (cache will be updated)
   */
  fetchPolicy?: FetchPolicy;
  firstFetchPolicy?: FetchPolicy;
  ignoreResponse?: boolean;
  body?: string | object;
  cacheResponse?: boolean;
};

const DefaultOptions: Omit<UsePowerBiLazyQueryOptions, 'firstFetchPolicy'> = {
  startLoading: false,
  method: 'GET',
  failSilently: false,
  continueLoadingOnRetry: true,
  fetchPolicy: 'cache-and-network',
  ignoreResponse: false,
  cacheResponse: true,
};

type PbiFetchParams = {
  token: string;
  path: string;
  method?: UsePowerBiLazyQueryOptions['method'];
  body?: string | object;
};

export const pbiFetch = async ({
  token,
  method = DefaultOptions.method,
  body,
  path,
}: PbiFetchParams) => {
  return fetch(`https://api.powerbi.com/v1.0/myorg/${path}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: typeof body === 'string' ? body : JSON.stringify(body),
    method,
  });
};

export const usePowerBiLazyQuery = <T>(
  path?: string,
  options?: Partial<UsePowerBiLazyQueryOptions>,
) => {
  const { token, refreshToken } = usePowerBiAuthentication();
  const { set: setCachedValue, get: getCachedValue } = useRequestCache();
  const {
    startLoading,
    method,
    fetchPolicy,
    firstFetchPolicy,
    continueLoadingOnRetry,
    ignoreResponse,
    body,
    cacheResponse,
  } = useMemo(() => {
    const mergedOptions = {
      ...DefaultOptions,
      ...options,
    };
    mergedOptions.firstFetchPolicy = mergedOptions.firstFetchPolicy ?? mergedOptions.fetchPolicy;
    return mergedOptions;
  }, [options]);
  const [state, setState] = useState<{
    data: Maybe<T>;
    loading: boolean;
    error?: GenericError;
  }>(() => ({
    data: fetchPolicy !== 'network-only' && path ? getCachedValue(path) : undefined,
    loading: startLoading,
    error: undefined,
  }));
  const { maybeRetry, setQueryToRetry, resetRetry, getQueryToRetry } = useRetryQuery();

  const doFetch = useEventCallback(
    async (path: string, body?: UsePowerBiLazyQueryOptions['body']) => {
      const failSilently = !!options?.failSilently;
      if (token) {
        // Ensure path does not include leading "/"
        const pathToUse = path.replace(/^\//, '');
        try {
          const response = await pbiFetch({
            token,
            path: pathToUse,
            method,
            body,
          });
          if (response.ok) {
            resetRetry();
            if (ignoreResponse) {
              return undefined;
            }

            try {
              const result = await response.json();
              return ('value' in result ? result.value : _.omit(result, '@odata.context')) as T;
            } catch (err) {
              // Track this error to understand what is causing the parsing to fail
              trackError('Error parsing PowerBI API response.', { path: pathToUse });
              return undefined;
            }
          } else if (response.status === 403) {
            setQueryToRetry(path);
            refreshToken?.();
          } else if (!failSilently) {
            trackError({
              message: `Error querying PowerBI API, path ${path}, status ${response.status}.`,
              name: 'PowerBI API Error',
            });
          }
        } catch (err) {
          if (!failSilently) {
            trackError(err as Error);
            process.env.NODE_ENV === 'development' && console.error(err);
            setState(current => ({ ...current, error: err as GenericError }));
          }
        }
      } else {
        trackError('Attempt to query PowerBI API without an access token!');
        process.env.NODE_ENV === 'development' &&
          console.log('Attempt to query PowerBI API without an access token!');
      }

      return undefined;
    },
  );

  const doFetchAndUpdateState = useEventCallback(
    (path: string, specificBody?: UsePowerBiLazyQueryOptions['body']) => {
      isFetchingPromiseRef.current = doFetch(path, specificBody)
        .then(value => {
          setState({
            data: value,
            loading: !value && !!continueLoadingOnRetry && getQueryToRetry() === path,
          });
          if (cacheResponse) {
            setCachedValue(path, value);
          }
          return value;
        })
        .finally(() => {
          isFetchingPromiseRef.current = undefined;
        });

      return isFetchingPromiseRef.current;
    },
  );

  useEffect(() => {
    if (token) {
      maybeRetry(doFetchAndUpdateState);
    }
  }, [token, doFetch, getQueryToRetry, maybeRetry, doFetchAndUpdateState]);

  const lastPathRef = useRef<Maybe<string>>(path);
  const isFetchingPromiseRef = useRef<Maybe<Promise<Maybe<T>>>>();
  const isFirstFetchRef = useRef(true);
  const maybeDoQuery = useEventCallback(
    (path?: string, specificBody?: UsePowerBiLazyQueryOptions['body']) => {
      if (!path) {
        process.env.NODE_ENV === 'development' &&
          console.warn('No path specified for Power BI query!');
        return Promise.reject();
      }

      if (!isFetchingPromiseRef.current) {
        const fetchPolicyToUse = isFirstFetchRef.current ? firstFetchPolicy : fetchPolicy;
        isFirstFetchRef.current = false;
        const cachedValue = getCachedValue<T>(path);
        setState(
          cachedValue && fetchPolicyToUse !== 'network-only'
            ? { ...state, loading: false, data: cachedValue }
            : { ...state, loading: true },
        );

        lastPathRef.current = path;
        if (!cachedValue || fetchPolicyToUse !== 'cache-first') {
          return doFetchAndUpdateState(path, specificBody);
        } else {
          return Promise.resolve(cachedValue);
        }
      }

      return isFetchingPromiseRef.current;
    },
  );

  const refetch = useEventCallback(() => maybeDoQuery(lastPathRef.current));
  const doQuery = useCallback(
    (specificPath?: string, specificBody?: UsePowerBiLazyQueryOptions['body']) =>
      maybeDoQuery(specificPath || path, specificBody || body),
    // Token is a necessary dependence here as we wish to re-trigger queries when token changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [maybeDoQuery, token, path, doFetchAndUpdateState, maybeRetry],
  );

  return [doQuery, { ...state, refetch }] as const;
};

type UsePowerBiQueryOptions = Pick<
  UsePowerBiLazyQueryOptions,
  'method' | 'failSilently' | 'fetchPolicy' | 'firstFetchPolicy' | 'ignoreResponse' | 'body'
> & {
  skip: boolean;
};

export const usePowerBiQuery = <T>(path: string, options?: Partial<UsePowerBiQueryOptions>) => {
  const skip = !!options?.skip;
  const [doQuery, rest] = usePowerBiLazyQuery<T>(path, { ...options, startLoading: true });
  useEffect(() => {
    if (!skip) {
      doQuery();
    }
  }, [doQuery, skip]);

  return { ...rest, loading: skip ? false : rest.loading };
};

export const PowerBiRequest = {
  groups: () => 'groups',
  reports: (groupId: string) => `groups/${groupId}/reports`,
  pages: (reportId: string) => `reports/${reportId}/pages`,
  report: (groupId: string, reportId: string) => `groups/${groupId}/reports/${reportId}`,
  executeQueries: (groupId: string, datasetId: string) =>
    `groups/${groupId}/datasets/${datasetId}/executeQueries`,
};

export const useGetGroupsLazy = (options?: Partial<UsePowerBiLazyQueryOptions>) =>
  usePowerBiLazyQuery<WorkspaceDetails[]>(PowerBiRequest.groups(), options);

export const getGroupFriendlyName = (groupName: string) => {
  return /Crisp RA -\s*([^(,]+)[(,]?/.exec(groupName.trim())?.[1]?.trim() ?? groupName;
};

// This should be a more lightweight request, does not require caching or other provisions included
// in usePowerBiLazyQuery
export const useRefreshUserPermissionsLazy = () => {
  const { token } = usePowerBiAuthentication();
  return useEventCallback(async () => {
    if (token) {
      return pbiFetch({
        token,
        path: 'RefreshUserPermissions',
        method: 'POST',
      }).then(response => {
        return response.ok;
      });
    }

    return false;
  });
};

export type ExecuteQueryResults<RowData extends object> = {
  results: {
    tables: {
      rows: RowData[];
    }[];
  }[];
};

export type DatasetTable = { name: string; value: string };
export type DatasetTableColumn = { name: string; value: string; type: string };

export type ExecuteQueryParams = {
  groupId: string;
  datasetId: string;
};

export const useGetDatasetTables = ({
  groupId,
  datasetId,
}: ExecuteQueryParams): Maybe<DatasetTable[]> => {
  const { data } = usePowerBiQuery<ExecuteQueryResults<{ '[Name]': string; '[ID]': number }>>(
    PowerBiRequest.executeQueries(groupId, datasetId),
    {
      fetchPolicy: 'cache-first',
      method: 'POST',
      body: {
        queries: [
          {
            query: 'evaluate SELECTCOLUMNS(INFO.TABLES(), [Name], [ID])',
          },
        ],
        serializerSettings: {
          includeNulls: true,
        },
      },
    },
  );

  return useMemo(
    () =>
      data?.results[0].tables[0].rows.map(table => ({
        name: table['[Name]'],
        value: table['[ID]'].toString(),
      })),
    [data],
  );
};

type GetTableColumnsParams = ExecuteQueryParams & { tableId: string };

export const useGetDatasetTableColumnsLazy = () => {
  type RowData = { '[ExplicitName]': string; '[ExplicitDataType]': string; '[ID]': number };
  const keys: (keyof RowData)[] = ['[ExplicitDataType]', '[ExplicitName]', '[ID]'];

  const [get, { data, loading }] = usePowerBiLazyQuery<ExecuteQueryResults<RowData>>('', {
    fetchPolicy: 'cache-first',
    method: 'POST',
  });

  const getTableColumns = useEventCallback(
    ({ groupId, tableId, datasetId }: GetTableColumnsParams) =>
      get(PowerBiRequest.executeQueries(groupId, datasetId) + '?tableId=' + tableId, {
        queries: [
          {
            query: `evaluate SELECTCOLUMNS(FILTER(INFO.COLUMNS("TableID", ${tableId}), [IsHidden] = false), ${keys.join(', ')})`,
          },
        ],
        serializerSettings: {
          includeNulls: true,
        },
      }),
  );

  return {
    getTableColumns,
    loading,
    data: useMemo(() => {
      return data?.results[0].tables[0].rows.map(
        table =>
          ({
            name: table['[ExplicitName]'],
            type: table['[ExplicitDataType]'],
            value: table['[ID]'].toString(),
          }) as DatasetTableColumn,
      );
    }, [data]),
  };
};

type GetColumnValuesParams = ExecuteQueryParams & { tableName: string; columnName: string };
export type ColumnValue = string | number | boolean | null;

export const useGetColumnValuesLazy = () => {
  const [get, { data, loading }] = usePowerBiLazyQuery<ExecuteQueryResults<object>>('', {
    fetchPolicy: 'cache-first',
    method: 'POST',
  });

  const getColumnValues = useEventCallback(
    ({ groupId, tableName, datasetId, columnName }: GetColumnValuesParams) =>
      get(
        PowerBiRequest.executeQueries(groupId, datasetId) +
          `?table=${tableName}&column=${columnName}`,
        {
          queries: [
            {
              query: `evaluate SELECTCOLUMNS(VALUES('${tableName}'[${columnName}]), "value", '${tableName}'[${columnName}])`,
            },
          ],
          serializerSettings: {
            includeNulls: true,
          },
        },
      ),
  );

  return {
    getColumnValues,
    loading,
    data: useMemo(() => {
      return data?.results[0].tables[0].rows.map(table => table['[value]'] as ColumnValue);
    }, [data]),
  };
};
