import { useMemo } from 'react';
import { maybe } from '@tellurian/ts-utils';
import { DropdownListOptions, toCanonicalOptions } from '../lib/dropdownListOption';

type UseSelectItemsProps = {
  options: DropdownListOptions;
  selectedValue?: string;
};

export const useSelectItems = ({ options, selectedValue }: UseSelectItemsProps) => {
  const items = useMemo(() => toCanonicalOptions(options), [options]);
  const selectedItem = useMemo(
    () =>
      selectedValue === undefined
        ? undefined
        : maybe(items.find(item => item.value === selectedValue)),
    [selectedValue, items],
  );

  return { items, selectedItem };
};
