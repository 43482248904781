import React from 'react';
import { FCC } from '../../../../utils/types';
import TitleHeader, { TitleHeaderProps } from './TitleHeader';
import ModalBase, { ModalBaseProps } from './ModalBase';
import { useModalRef } from './lib';

export type ModalProps = TitleHeaderProps & Pick<ModalBaseProps, 'footer' | 'size'>;

const HeaderId = 'modal-title';

const Modal: FCC<ModalProps> = ({ children, footer, onCloseClick, size, ...rest }) => {
  return (
    <ModalBase
      size={size}
      header={<TitleHeader id={HeaderId} onCloseClick={onCloseClick} {...rest} />}
      footer={footer}
      ref={useModalRef(onCloseClick)}
      aria-labelledby={HeaderId}
    >
      {children}
    </ModalBase>
  );
};

export default Modal;
