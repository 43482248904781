import { useEffect, useRef } from 'react';
import { Maybe } from '@tellurian/ts-utils';
import useEventCallback from '../../../utils/useEventCallback';
import {
  DismissFn,
  NotificationDispatcher,
  NotificationDispatcherShowResult,
  NotificationSpec,
  ShowFn,
} from './lib';

export type NotificationContextInterface = {
  useNotification: (spec: NotificationSpec, dismissOnUnmount?: boolean) => UseNotification;
  setDispatcher: (dispatcher: Maybe<NotificationDispatcher>) => void;
};

type UseNotification = {
  show: ShowFn;
  dismiss: DismissFn;
};

const notificationContextFactory = (): NotificationContextInterface => {
  let dispatcher: Maybe<NotificationDispatcher> = undefined;
  const setDispatcher = (nextDispatcher: Maybe<NotificationDispatcher>) => {
    if (dispatcher !== nextDispatcher) {
      dispatcher?.dispose();
    }

    dispatcher = nextDispatcher;
  };

  const useNotification = (spec: NotificationSpec, dismissOnUnmount = false): UseNotification => {
    const showResultRef = useRef<Maybe<NotificationDispatcherShowResult>>(undefined);
    const show = useEventCallback((specificSpec?: NotificationSpec) => {
      const specToUse = { ...spec, ...specificSpec };

      if (showResultRef.current) {
        showResultRef.current.showAgain(specToUse);
      } else {
        showResultRef.current = dispatcher?.show(specToUse);
      }
    });

    const dismiss = useEventCallback<[immediate?: boolean | undefined], boolean>(
      (immediate = false) => showResultRef.current?.dismiss(immediate) ?? false,
    );

    useEffect(() => {
      if (dismissOnUnmount) {
        return () => {
          dismiss();
        };
      }
    }, [dismiss, dismissOnUnmount]);

    return { show, dismiss };
  };

  return { setDispatcher, useNotification };
};

const useNotificationContextValue = () => {
  return useRef<NotificationContextInterface>(notificationContextFactory()).current;
};

export default useNotificationContextValue;
