import { useEffect, useRef } from 'react';
import useEventCallback from '../../utils/useEventCallback';
import composeRefs from '../../utils/composeRefs';
import useOnClickAway from '../../utils/useOnClickAway';

export const disableBodyScroll = () => {
  const { body } = document;
  if (body) {
    body.style.overflow = 'hidden';
    body.style.height = '100%';
  }
};

export const enableBodyScroll = () => {
  const { body } = document;
  if (body) {
    body.style.overflow = '';
    body.style.height = '';
  }
};

const useOnMouseDownRef = (onMouseDown: () => void) => {
  const ref = useRef<HTMLDivElement>(null);
  const eventCallback = useEventCallback(onMouseDown);
  useEffect(() => {
    if (ref.current) {
      const target = ref.current;
      const fn = (ev: MouseEvent) => {
        if (ev.target === target) {
          eventCallback();
        }
      };
      target.addEventListener('mousedown', fn);
      return () => target.removeEventListener('mousedown', fn);
    }
  }, [eventCallback]);

  return ref;
};

export const useModalRef = (onCloseClick: () => void) =>
  composeRefs(
    useOnClickAway<HTMLDivElement>({
      mouseEvent: 'mouseDown',
      onClickAway: onCloseClick,
    }),
    useOnMouseDownRef(onCloseClick),
  );
