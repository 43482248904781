import React from 'react';
import ReactDOM from 'react-dom/client';
import './utils/wdyr';
import './animations.css';
import './index.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import setup from './setup';
import UnfiEmbeddedApp from './components/UnfiEmbeddedApp';
import PartnerMiniSiteApp from './components/lettuce/partner/PartnerMiniSiteApp';
import CrispApp from './components/lettuce/crisp/CrispApp';

setup();

const isUnfiInsights = (pathname: string) =>
  pathname.startsWith('/unfi-insights-embedded') || pathname.startsWith('/unfi-fuel-embedded');

const isPartnerMiniSite = (pathname: string) => {
  if (CrispApp.partnerMiniSite.isDemoSiteEnabled()) {
    return false;
  }

  return pathname === '/p' || pathname.startsWith('/p/');
};
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    {isPartnerMiniSite(window.location.pathname) ? (
      <PartnerMiniSiteApp />
    ) : isUnfiInsights(window.location.pathname) ? (
      <UnfiEmbeddedApp />
    ) : (
      <App />
    )}
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
