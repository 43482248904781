import React from 'react';
import { useLocation } from 'react-router-dom';
import Spinner from '../components/Spinner';
import { useAuthorizationContext } from '../../security/AuthorizationContext';
import { FCC } from '../../../utils/types';
import { UnfiInsightsOnPlatformVerification } from './UnfiInsightsOnPlatformVerification';
import { useUnfiInsightsOnPlatformAuthenticationContext } from './UnfiInsightsOnPlatformAuthenticationProvider';

export const MaybeVerifyUnfiInsightsOnPlatformAccess: FCC = ({ children }) => {
  const {
    currentUser,
    getHasGlobalAdminPermission,
    loading: authLoading,
  } = useAuthorizationContext();
  const isGlobalAdmin = getHasGlobalAdminPermission();
  const { isSignedInWithUnfiInsights } = useUnfiInsightsOnPlatformAuthenticationContext();
  const { firebaseUser } = useAuthorizationContext();
  const location = useLocation();
  const hasAccountPermissions = (currentUser?.accountPermissions || []).length > 0;

  if (authLoading) {
    return <Spinner />;
  }

  // Bypass on-platform verification if the user is accessing invitations and has verified their email.
  if (location.pathname.includes('invitations') && firebaseUser?.emailVerified) {
    return <>{children}</>;
  }

  if (isSignedInWithUnfiInsights && !hasAccountPermissions && !isGlobalAdmin) {
    return <UnfiInsightsOnPlatformVerification />;
  }

  return <>{children}</>;
};
