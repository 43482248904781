import { Maybe, maybe } from '@tellurian/ts-utils';
import {
  ConfigurationCredentialVerificationStep,
  ConfigVerificationDetailsFragment,
  ConnectorConfigurationFragment,
  CredentialVerificationStepProgress,
} from '../../../generated/graphql';

export const getFailingStep = (
  verification?: ConfigVerificationDetailsFragment,
): Maybe<ConfigurationCredentialVerificationStep> =>
  verification?.steps.find(step => step.progress === CredentialVerificationStepProgress.Failure);

const isCredentialVerificationFailing = (
  configuration: Pick<ConnectorConfigurationFragment, 'credentialVerification'>,
): boolean => !!getFailingStep(maybe(configuration.credentialVerification));

export default isCredentialVerificationFailing;
