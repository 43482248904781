import React from 'react';
import AccountTemplate from '../../crisp/templates/AccountTemplate';
import NotFound from './NotFound';

const NotFoundInAccount: React.FC = () => {
  return (
    <AccountTemplate>
      <NotFound />
    </AccountTemplate>
  );
};

export default NotFoundInAccount;
