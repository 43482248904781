import React, { useContext } from 'react';
import { GridType } from '../Grid';

type PageLayoutContextProps = {
  type: GridType;
  withoutSidebars: boolean;
};

export const PageLayoutContext = React.createContext<PageLayoutContextProps>({
  type: GridType.M,
  withoutSidebars: false,
});

type PageLayoutProviderProps = {
  children: React.ReactNode;
  type: GridType;
  withoutSidebars: boolean;
};

export function PageLayoutProvider({ children, type, withoutSidebars }: PageLayoutProviderProps) {
  return (
    <PageLayoutContext.Provider value={{ type, withoutSidebars }}>
      {children}
    </PageLayoutContext.Provider>
  );
}

export const usePageLayoutContext = () => useContext(PageLayoutContext);
