import React from 'react';
import clsx from 'clsx';
import { FormFieldError, FormFieldErrorType } from './FormComponents';
import styles from './Fieldset.module.css';

type FieldsetProps = {
  heading?: string;
  description?: string;
  children: React.ReactNode;
  column?: boolean;
  errorMessage?: FormFieldErrorType;
};

export function Fieldset({ heading, description, children, column, errorMessage }: FieldsetProps) {
  return (
    <div>
      {heading ? <h3 className={styles.h2}>{heading}</h3> : null}
      <fieldset className={styles.fieldset}>
        <legend className={styles.legend}>{description}</legend>
        <div className={clsx(styles.children, { [styles.column]: column })}>{children}</div>
        {errorMessage && (
          <div className="mhm">
            <FormFieldError error={errorMessage} />
          </div>
        )}
      </fieldset>
    </div>
  );
}
