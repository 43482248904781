import { useEffect, useLayoutEffect, useRef } from 'react';
import { KeyboardShortcut } from './lib';
import { useKeyboardShortcutContext } from './KeyboardShortcutContext';

const useKeyboardShortcut = (
  spec: Omit<KeyboardShortcut, 'fn'>,
  fn: KeyboardShortcut['fn'],
  enabled = true,
): KeyboardShortcut => {
  const { registerKeyboardShortcut } = useKeyboardShortcutContext();

  const shortcutRef = useRef<KeyboardShortcut>({ ...spec, fn });
  // No need to re-register shortcut on every re-render if user forgets to memoize the function; Just
  // update the reference to fn instead.
  useLayoutEffect(() => {
    shortcutRef.current.fn = fn;
  }, [fn]);

  useEffect(() => {
    if (enabled) {
      return registerKeyboardShortcut(shortcutRef.current);
    }
  }, [enabled, registerKeyboardShortcut]);

  return { ...spec, fn };
};

export default useKeyboardShortcut;
