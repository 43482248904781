import { DashboardFilterInput, LookerBookmarkFilter } from '../../../generated/graphql';
import { LookerFilters } from './EmbeddedLookerContext';

export function bookmarkFiltersToLookerFilters(filters: LookerBookmarkFilter[]): LookerFilters {
  const ret = {};

  filters.forEach(f => {
    ret[f.filterKey] = f.filterValue;
  });

  return ret;
}

export function dashboardFiltersToLookerFilters(filters: DashboardFilterInput[]): LookerFilters {
  const ret = {};

  filters.forEach(f => {
    ret[f.name] = f.value;
  });
  return ret;
}
