import React, { FunctionComponent, KeyboardEventHandler, useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import clsx from 'clsx';
import { Button, ButtonStyle } from '../Button';
import { FeatureProps, invokesFeature, trackFeature } from '../../../utils/features';
import useIsOverflowing from '../../../utils/useIsOverflowing';
import { Key } from '../../../utils/keyCodes';
import { FCC } from '../../../utils/types';
import OverflowButton, { Direction } from './OverflowButton';
import styles from './index.module.css';

type TabLinkProps = {
  id: string;
  onClick: (e: React.SyntheticEvent<HTMLAnchorElement | HTMLButtonElement>) => void;
  selected: boolean;
  title: string;
  href?: string;
  onKeyDown: KeyboardEventHandler<HTMLElement>;
  focus?: boolean;
} & FeatureProps;

function TabLink({
  id,
  href,
  onClick,
  selected,
  title,
  featureId,
  onKeyDown,
  focus,
}: TabLinkProps) {
  const selectedRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (selected) {
      selectedRef.current?.scrollIntoView?.({
        block: 'nearest',
        inline: 'center',
      });
    }
  }, [selected]);

  useEffect(() => {
    if (focus && selected) {
      selectedRef.current?.focus();
    }
  }, [focus, selected]);

  if (selected)
    return (
      <span
        id={id}
        className={clsx(styles.tabLink, styles.selected)}
        ref={selectedRef}
        role="tab"
        aria-selected="true"
        tabIndex={0}
        aria-controls={`${id}-panel`}
        onKeyDown={onKeyDown}
      >
        {title}
      </span>
    );

  if (href) {
    return (
      <Link
        id={id}
        className={styles.tabLink}
        onClick={e => {
          featureId && trackFeature(featureId);
          onClick(e);
        }}
        to={href}
        role="tab"
        aria-selected="false"
        tabIndex={-1}
        aria-controls={`${id}-panel`}
        replace={true}
        {...invokesFeature(featureId)}
      >
        <span>{title}</span>
      </Link>
    );
  }

  return (
    <Button
      id={id}
      btnStyle={ButtonStyle.LINK}
      className={styles.tabButton}
      onClick={e => {
        featureId && trackFeature(featureId);
        onClick(e);
      }}
      role="tab"
      aria-selected="false"
      tabIndex={-1}
      aria-controls={`${id}-panel`}
      {...invokesFeature(featureId)}
    >
      <span className={styles.tabLink}>{title}</span>
    </Button>
  );
}

export type TabProps = {
  title: string;
  href?: string;
  id: string;
  children?: React.ReactNode;
  onClick?: () => void;
} & FeatureProps;

export const Tab: FCC<TabProps> = () => null;

export function Tabs({
  activeTabId,
  children,
  ariaLabel,
  RenderTab = TabLink,
  showActiveChild = true,
  className,
  rightContent,
}: {
  activeTabId?: string;
  children: React.ReactElement<TabProps>[];
  ariaLabel?: string;
  RenderTab?: FunctionComponent<TabLinkProps>;
  showActiveChild?: boolean;
  className?: string;
  rightContent?: React.ReactNode;
}) {
  const {
    containerRef: tabsContainerRef,
    isAtScrollEnd,
    isAtScrollStart,
    isOverflowing,
    onScroll,
    scrollLeft,
    scrollRight,
  } = useIsOverflowing();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [keyboardFocusIndex, setKeyboardFocusIndex] = useState<number | undefined>(undefined);
  const history = useHistory();

  const tabs = React.Children.map(children, child => {
    return child;
  }) as React.ReactElement<TabProps>[];

  const activeChild = activeTabId
    ? tabs.find(c => c.props.id === activeTabId)
    : tabs[selectedTabIndex];

  const navigateTabsOnKeyDown = (index: number) => (event: React.KeyboardEvent) => {
    if (event.key === Key.LeftArrow || event.key === Key.RightArrow) {
      let newIndex = index;
      if (event.key === Key.LeftArrow) {
        newIndex--;
        if (newIndex < 0) {
          newIndex = tabs.length - 1;
        }
      } else if (event.key === Key.RightArrow) {
        newIndex++;
        if (newIndex >= tabs.length) {
          newIndex = 0;
        }
      }

      if (!activeTabId) {
        setSelectedTabIndex(newIndex);
      } else {
        const newTab = tabs[newIndex];
        if (newTab && newTab.props.onClick) {
          newTab.props.onClick();
        } else if (newTab.props.href) {
          history.push(newTab.props.href);
        }
      }

      setKeyboardFocusIndex(newIndex);
      event.preventDefault();
      return false;
    }
  };

  return (
    <div className={clsx(styles.body, className)}>
      <div className={styles.tabsContainer}>
        <div className={styles.overflowContainer}>
          {isOverflowing && !isAtScrollStart && (
            <OverflowButton direction={Direction.Left} onClick={scrollLeft} />
          )}
          <div
            className={styles.tabs}
            onScroll={onScroll}
            ref={tabsContainerRef}
            role="tablist"
            aria-label={ariaLabel}
          >
            {tabs.map((t, index) => (
              <RenderTab
                key={t.props.id}
                id={t.props.id}
                onClick={() => {
                  if (!activeTabId) {
                    setSelectedTabIndex(index);
                  }
                  if (t.props.onClick) {
                    t.props.onClick();
                  }
                  setKeyboardFocusIndex(undefined);
                }}
                selected={activeTabId ? activeTabId === t.props.id : index === selectedTabIndex}
                title={t.props.title}
                href={t.props.href}
                featureId={t.props.featureId}
                onKeyDown={navigateTabsOnKeyDown(index)}
                focus={keyboardFocusIndex === index}
              />
            ))}
          </div>
          {isOverflowing && !isAtScrollEnd && (
            <OverflowButton direction={Direction.Right} onClick={scrollRight} />
          )}
        </div>
        {rightContent && <div className={styles.rightContainer}>{rightContent}</div>}
      </div>
      <hr className={styles.divider} />
      {showActiveChild && (
        <div role="tabpanel" aria-labelledby={activeTabId} id={`${activeTabId}-panel`}>
          {activeChild && activeChild.props.children}
        </div>
      )}
    </div>
  );
}
