import React, { Suspense } from 'react';
import clsx from 'clsx';
import { ReactComponent as Logo } from '../../../../assets/crisp-logo.svg';
import { ReactComponent as LogoWhite } from '../../../../assets/crisp-logo-white.svg';
import { useIsMaxSmallScreen } from '../../../ui/useResponsive';
import Headline from '../../components/Headline';
import Panel from '../../components/Panel';
import style from './AuthenticationTemplate.module.css';

type AuthenticationTemplatePropTypes = {
  title: string;
  subtitle?: string | React.ReactNode;
  children?: React.ReactNode;
};

export const AuthenticationTemplate = ({
  title,
  subtitle,
  children,
}: AuthenticationTemplatePropTypes) => {
  return (
    <Panel className={style.authPanel}>
      <Headline size={'L'} className={'text-center'}>
        {title}
      </Headline>
      <p className={clsx(style.subheading)}>{subtitle}</p>
      {children}
    </Panel>
  );
};

const LogoContainer: React.FC<{ compact?: boolean }> = ({ compact = false }) => {
  return (
    <div className={style.logoContainer}>
      {compact ? <Logo className={style.logo} /> : <LogoWhite className={style.logo} />}
    </div>
  );
};

export default function AuthenticationLayout({ children }: { children?: React.ReactNode }) {
  const compact = useIsMaxSmallScreen();
  return (
    <div className={clsx(style.securityContainer, { [style.compact]: compact })}>
      <LogoContainer compact={compact} />
      <div className={style.rhs}>
        <Suspense>{children}</Suspense>
      </div>
    </div>
  );
}
