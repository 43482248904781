import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import usePersistentState from '../../utils/memoryStorage/usePersistentState';
import { MemoryStorageKey } from '../../utils/memoryStorage';
import useLocalStorageState from '../../utils/localStorage/useLocalStorageState';
import { LocalStorageKey } from '../../utils/localStorage';
import { path } from '../lettuce/crisp/routing/lib';
import useTermsOfServiceToast from './useTermsOfServiceToast';

const TwelveHours = 43200000;

const AcceptanceNotification: React.FC<{ accountId: string }> = ({ accountId }) => {
  const [isNotificationVisible, setIsNotificationVisible] = usePersistentState(
    MemoryStorageKey.IsTermsOfServiceNotificationVisible,
    false,
  );
  const [lastDismissed, setLastDismissed] = useLocalStorageState(
    LocalStorageKey.LastDismissedTermsOfServiceNotification,
    0,
  );

  const { show, dismiss } = useTermsOfServiceToast();
  const history = useHistory();

  const onDismiss = useCallback(() => {
    setLastDismissed(+new Date());
    setIsNotificationVisible(false);
  }, [setLastDismissed, setIsNotificationVisible]);

  useEffect(() => {
    if (!isNotificationVisible) {
      const tosPath = path('TermsOfService')({ accountId });
      const now = +new Date();
      if (now - lastDismissed > TwelveHours) {
        show(
          <>
            Please accept our{' '}
            <a
              href={tosPath}
              onClick={event => {
                event.preventDefault();
                dismiss();
                onDismiss();
                history.push(tosPath);
              }}
            >
              Terms of Service
            </a>{' '}
            to unblock certain Crisp features.
          </>,
          {
            autoDismissAfterMs: undefined,
            onDismiss,
          },
        );
        setIsNotificationVisible(true);
      }
    }
  }, [
    dismiss,
    show,
    lastDismissed,
    setLastDismissed,
    accountId,
    isNotificationVisible,
    setIsNotificationVisible,
    onDismiss,
    history,
  ]);

  return null;
};

export default AcceptanceNotification;
