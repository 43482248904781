import React from 'react';
import clsx from 'clsx';
import { FCC } from '../../../../utils/types';
import style from './MaxWidthLayout.module.css'; // eslint-disable-line

const MaxWidthLayoutSizes = ['S', 'M', 'L'];
type MaxWidthLayoutSize = (typeof MaxWidthLayoutSizes)[number];

export type MaxWidthLayoutProps = {
  size?: MaxWidthLayoutSize;
  center?: boolean;
  className?: string;
};

const MaxWidthLayout: FCC<MaxWidthLayoutProps> = ({
  center = true,
  size = 'M',
  children,
  className,
}) => {
  return (
    <div className={clsx(style.body, style[size], { [style.center]: center }, className)}>
      {children}
    </div>
  );
};

export default MaxWidthLayout;
