import React from 'react';
import SaveCancelActionButtons, {
  SaveCancelActionButtonsProps,
} from '../ActionButtons/SaveCancelActionButtons';
import { FCC } from '../../../../utils/types';
import Modal, { ModalProps } from './index';

type SaveModalProps = Omit<ModalProps, 'footer'> &
  Omit<SaveCancelActionButtonsProps, 'onCancel'> & {
    // onCancel will default to onCloseClick and is therefore optional
    onCancel?: SaveCancelActionButtonsProps['onCancel'];
  };

const SaveModal: FCC<SaveModalProps> = ({
  title,
  subtitle,
  onCloseClick,
  children,
  onCancel = onCloseClick,
  size,
  ...rest
}) => {
  return (
    <Modal
      title={title}
      subtitle={subtitle}
      onCloseClick={onCloseClick}
      size={size}
      footer={<SaveCancelActionButtons onCancel={onCancel} {...rest} />}
    >
      {children}
    </Modal>
  );
};

export default SaveModal;
