import React, { Dispatch, SetStateAction, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import { FormProvider, useForm } from 'react-hook-form';
import {
  UnfiInsightsSendEmailAddressVerificationMailResponseResult,
  useUnfiInsightsGenerateUserMergeTokenMutation,
  useUnfiInsightsSendVerificationEmailMutation,
} from '../../../generated/graphql';
import { TextFormField } from '../../ui/form/FormFields';
import { email, requiredField } from '../../ui/form/validation';
import { withQueryParams } from '../../../utils/querystringUtils';
import { path } from '../crisp/routing/lib';
import Spinner from '../components/Spinner';
import Headline from '../components/Headline';
import Button, { ButtonVariant } from '../components/Button';
import { Feature, trackFeature } from '../../../utils/features';
import style from './VerifyEmail.module.css';

const UserMergingRequired: React.FC<{
  emailAddress: string;
  showDetail: Dispatch<SetStateAction<boolean>>;
}> = ({ emailAddress, showDetail }) => {
  const [generateToken, { loading, data }] = useUnfiInsightsGenerateUserMergeTokenMutation();
  const token = data?.unfiInsightsGenerateUserMergeToken?.token;

  const onClick = () => {
    localStorage.setItem('isUnfiUserMergeInProgress', 'true');
    generateToken({ variables: { input: { targetEmailAddress: emailAddress } } });
  };

  useEffect(() => {
    if (token) {
      const redirectUri =
        window.location.origin +
        withQueryParams({
          returnTo: `/unfi-insights/user-merge/token/` + token,
        })(path('SignIn')());

      firebase
        .auth()
        .signOut()
        .then(() => {
          window.location.replace(redirectUri);
        });
    }
  }, [token]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div>
      <Headline>Email registered to existing Crisp user</Headline>
      <div>
        <p>
          Please sign in with <b>{emailAddress}</b> and follow the instructions to link your UNFI
          Insights user to your existing Crisp user.
        </p>
        <p>
          If you are not the owner of the existing Crisp account with email <b>{emailAddress}</b>,
          please return to the MyUNFI Homepage and update the email in your User Profile to a
          different email address.
        </p>
        <div className="mhm mvl">
          <a href={'https://myunfi.com'} rel="noopener noreferrer">
            <Button
              variant={ButtonVariant.Secondary}
              style={{ marginLeft: '20px' }}
              onClick={() => {}}
            >
              Go to MyUNFI
            </Button>
          </a>
          <Button variant={ButtonVariant.Primary} className={'mhm'} onClick={onClick}>
            Continue
          </Button>
        </div>
        <div className="mhm mvl">
          <Button variant={ButtonVariant.Text} onClick={() => showDetail(false)}>
            Back To UNFI-Crisp account Options
          </Button>
        </div>
      </div>
    </div>
  );
};

const VerificationEmailSent = () => {
  return (
    <div className={style.panel}>
      <Headline>Verification email sent</Headline>
      <p>Check your inbox and follow the instructions to link your data to Crisp.</p>
      <div className={'pam'}>
        <Button variant={ButtonVariant.Primary} onClick={() => window.close()}>
          Close Window
        </Button>
      </div>
    </div>
  );
};

export const VerifyEmailPanel: React.FC<{ showDetail: Dispatch<SetStateAction<boolean>> }> = ({
  showDetail,
}) => {
  const useFormMethods = useForm({ shouldUnregister: false });
  const { getValues } = useFormMethods;
  const emailAddress = getValues('email');

  const [sendVerificationEmail, { loading, data }] = useUnfiInsightsSendVerificationEmailMutation();
  const result = data?.unfiInsightsSendEmailAddressVerificationMail?.result;
  const onSubmit = email => {
    trackFeature(Feature.UnfiInsightsAccountLinkClicked, { email });
    sendVerificationEmail({ variables: { input: { emailAddress: email } } });
  };
  return (
    <div>
      <div className={style.panel}>
        {loading && (
          <div className={style.panel}>
            <Headline>Link your acount</Headline>
            <p>Sending verification email.</p>
            <Spinner />
          </div>
        )}
        {result === UnfiInsightsSendEmailAddressVerificationMailResponseResult.EmailSent && (
          <VerificationEmailSent />
        )}
        {result ===
          UnfiInsightsSendEmailAddressVerificationMailResponseResult.EmailAlreadyExists && (
          <UserMergingRequired emailAddress={emailAddress} showDetail={showDetail} />
        )}
        {!loading && !result && (
          <FormProvider {...useFormMethods}>
            <Headline>Link your account</Headline>
            <p>Enter the email you used for signing up with Crisp.</p>
            <TextFormField
              name="email"
              label="Email address"
              validate={[requiredField, email]}
              autoFocus={true}
              type="email"
            />
            <div className="">
              <Button
                variant={ButtonVariant.Primary}
                disabled={loading}
                onClick={useFormMethods.handleSubmit(({ email }) => {
                  onSubmit(email);
                })}
              >
                Send verification email
              </Button>
            </div>
            <div className="pam">
              <Button variant={ButtonVariant.Text} onClick={() => showDetail(false)}>
                Back To UNFI-Crisp account Options
              </Button>
            </div>
          </FormProvider>
        )}
      </div>
    </div>
  );
};
