import { Maybe } from '@tellurian/ts-utils';
import { Account } from '../../generated/graphql';

const IndexedDb = window.indexedDB;

export const supportsIndexedDb = () => !!IndexedDb;

export type AccountDetails = Pick<Account, 'id' | 'name' | 'shortName' | 'isTest'>;

type LocalAccountsInterface = {
  update: (accounts: AccountDetails[]) => void;
  query: (accountNameOrId: string) => Promise<AccountDetails[]>;
  count: () => Promise<number>;
  getById: (accountId: string) => Promise<Maybe<AccountDetails>>;
  getShortNames: () => Promise<string[]>;
  insert: (account: AccountDetails) => void;
};

const getLocalAccounts = (): LocalAccountsInterface => {
  let db: Maybe<IDBDatabase> = undefined;
  const jobs: ((db: IDBDatabase) => void)[] = [];
  const initDb = (version = 1) => {
    const request = IndexedDb.open('CrispAdmin', version);
    request.addEventListener('upgradeneeded', event => {
      const db: IDBDatabase = event.target?.['result'];
      const accountsStore = db.createObjectStore('accounts', { keyPath: 'id' });
      accountsStore.createIndex('name', 'name', { unique: false });
      accountsStore.createIndex('shortName', 'shortName', { unique: false });
    });
    request.addEventListener('error', console.error);
    request.addEventListener('success', () => {
      db = request.result;
      jobs.forEach(job => job(request.result));
    });
  };

  initDb();

  const exec = (job: (db: IDBDatabase) => void) => {
    if (db) {
      job(db);
    } else {
      jobs.push(job);
    }
  };

  let updateScheduled = false;
  const update = (accounts: AccountDetails[]) => {
    if (!updateScheduled) {
      updateScheduled = true;
      exec(db => {
        const store = db.transaction('accounts', 'readwrite').objectStore('accounts');
        store.clear();
        accounts.forEach(account => {
          store.put(account);
        });
        updateScheduled = false;
      });
    }
  };

  const insert = (account: AccountDetails) => {
    exec(db => {
      const store = db.transaction('accounts', 'readwrite').objectStore('accounts');
      store.put(account);
    });
  };

  const query = async (accountNameOrId: string): Promise<AccountDetails[]> => {
    const accountNameOrIdLowerCase = accountNameOrId.toLowerCase();
    return new Promise(resolve => {
      exec(db => {
        const results: AccountDetails[] = [];
        const store = db.transaction('accounts', 'readonly').objectStore('accounts');

        store.openCursor().addEventListener('success', ev => {
          const cursor: IDBCursorWithValue = ev.target!['result'];
          if (cursor) {
            const account: AccountDetails = cursor.value;
            if (
              account.id.includes(accountNameOrIdLowerCase) ||
              account.name.toLowerCase().includes(accountNameOrIdLowerCase)
            ) {
              results.push(cursor.value);
            }
            cursor.continue();
          } else {
            resolve(results);
          }
        });
      });
    });
  };

  const getById = async (accountId: string): Promise<Maybe<AccountDetails>> => {
    return new Promise(resolve => {
      exec(db => {
        const store = db.transaction('accounts', 'readonly').objectStore('accounts');
        store.get(accountId).addEventListener('success', ev => {
          const account = ev.target!['result'];
          resolve(account);
        });
      });
    });
  };

  const count = async (): Promise<number> => {
    return new Promise(resolve => {
      exec(db => {
        const store = db.transaction('accounts', 'readonly').objectStore('accounts');
        store.count().addEventListener('success', ev => {
          resolve(ev.target!['result'] as number);
        });
      });
    });
  };

  const getShortNames = async (): Promise<string[]> => {
    return new Promise(resolve => {
      exec(db => {
        const res: string[] = [];
        const store = db.transaction('accounts', 'readonly').objectStore('accounts');
        store.openCursor().addEventListener('success', ev => {
          const cursor: IDBCursorWithValue = ev.target!['result'];
          if (cursor) {
            res.push(cursor.value.shortName);
            cursor.continue();
          } else {
            resolve(res);
          }
        });
      });
    });
  };

  return {
    update,
    query,
    count,
    getById,
    getShortNames,
    insert,
  };
};

let LocalAccounts: Maybe<LocalAccountsInterface> = undefined;

export const LocalDb = {
  get accounts(): LocalAccountsInterface {
    if (!LocalAccounts) {
      LocalAccounts = getLocalAccounts();
    }

    return LocalAccounts;
  },
};
