import React from 'react';
import { RenderItemProps } from './ListOfItems';
import useScrollListItemIntoView from './useScrollListItemIntoView';
import { composeProps, SelectableListProps } from './lib';
import ListItem from './listItems/ListItem';

export type RenderSelectableItemProps<T> = RenderItemProps<T> & {
  isActionable: boolean;
  isFocused: boolean;
  isSelected: boolean;
  focusTransitionsEnabled: boolean;
};

export type SelectableListItemProps<T> = SelectableListProps<T> & {
  item: T;
  index: number;
  id?: string;
};

function SelectableListItem<T>(
  RenderItem: React.FC<RenderSelectableItemProps<T>>,
  RenderListItem = ListItem,
): React.FC<SelectableListItemProps<T>> {
  return React.memo(function SelectableListItem({
    useFocusableItem,
    useSelectableItem,
    useActionableItem,
    focusTransitionsEnabled,
    item,
    index,
    id,
    ...additionalProps
  }: SelectableListItemProps<T>) {
    const { isFocused, ...focusableProps } = useFocusableItem(index);
    const { isActionable, ...actionableProps } = useActionableItem(index);
    const { isSelected } = useSelectableItem(item);
    const itemProps = { isFocused, isActionable, isSelected, focusTransitionsEnabled };

    return (
      <RenderListItem
        ref={useScrollListItemIntoView(isFocused)}
        {...composeProps(focusableProps, actionableProps)}
        {...itemProps}
        id={id}
        role="option"
      >
        <RenderItem item={item} index={index} {...itemProps} {...additionalProps} />
      </RenderListItem>
    );
  });
}

export default SelectableListItem;
