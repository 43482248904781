import React from 'react';
import { maybe } from '@tellurian/ts-utils';
import Title from '../../Title';
import { User } from '../../../../../generated/graphql';
import Avatar from '../../Avatar';
import { capitalizeFirst } from '../../../../../utils/capitalizeFirst';
import Subtitle from './lib/Subtitle';
import style from './index.module.css';

export type UserItemDetails = Pick<User, 'displayName' | 'email' | 'avatarUrl'>;
export type UserItemProps = { item: UserItemDetails };

const UserItem: React.FC<UserItemProps> = ({ item: { displayName, email, avatarUrl } }) => {
  displayName = maybe(displayName);
  return (
    <div className={style.hContainer}>
      <div className={style.avatarContainer} aria-hidden>
        <Avatar name={displayName || capitalizeFirst(email)} photoUrl={maybe(avatarUrl)} />
      </div>
      <div>
        {displayName && (
          <Title size="S" className="man" as="div">
            {displayName}
          </Title>
        )}
        <Subtitle>{email}</Subtitle>
      </div>
    </div>
  );
};

export default UserItem;
