import { compile as compilePath } from 'path-to-regexp';
import {
  encodeRouteParams,
  RouteDeclaration,
  RouteIdToRoute,
  RouteParams,
} from '../../components/routing/lib';
import { KnownFlags } from '../../common/featureFlags/KnownFlags';
import UnfiInsightsEmbeddedDebugPage from '../pages/unfiInsightsEmbeddedDebugPage';
import UnfiInsightsEmbeddedDeductionsCategorizationPage from '../pages/unfiInsightsEmbeddedDeductionsCategorizationPage';
import UnfiInsightsEmbeddedDeductionsPaymentDetailsPage from '../pages/unfiInsightsEmbeddedDeductionsPaymentDetailsPage';
import UnfiInsightsEmbeddedDeductionsPage from '../pages/unfiInsightsEmbeddedDeductionsPage';
import UnfiInsightsEmbeddedDashboardPage from '../pages/unfiInsightsEmbeddedDashboardPage';
import UnfiInsightsEmbeddedSignInPage from '../pages/unfiInsightsEmbeddedSignInPage';
import UnfiInsightsEmbeddedHomePage from '../pages/unfiInsightsEmbeddedHomePage';

export const UnfiEmbeddedRoutes = [
  {
    id: 'UnfiInsightsEmbedded',
    path: '/unfi-insights-embedded',
    Render: UnfiInsightsEmbeddedHomePage,
  },
  {
    id: 'UnfiInsightsEmbeddedAuthRedirect',
    path: '/unfi-fuel-embedded-auth',
    Render: UnfiInsightsEmbeddedSignInPage,
  },
  {
    id: 'UnfiInsightsEmbeddedSignIn',
    path: '/unfi-insights-embedded/signin',
    Render: UnfiInsightsEmbeddedSignInPage,
  },
  {
    id: 'UnfiInsightsEmbeddedDashboards',
    path: '/unfi-insights-embedded/dashboards/:dashboardGroupId?/:dashboardId?',
    Render: UnfiInsightsEmbeddedDashboardPage,
  },
  {
    id: 'UnfiInsightsEmbeddedDeductions',
    path: '/unfi-insights-embedded/deductions',
    Render: UnfiInsightsEmbeddedDeductionsPage,
    requireFeatureFlag: KnownFlags.Deductions,
  },
  {
    id: 'UnfiInsightsEmbeddedDeductionsPaymentDetails',
    path: '/unfi-insights-embedded/deductions/:paymentId',
    Render: UnfiInsightsEmbeddedDeductionsPaymentDetailsPage,
  },
  {
    id: 'UnfiInsightsEmbeddedDeductionsCategorization',
    path: '/unfi-insights-embedded/deductions/:paymentId/:deductionId',
    Render: UnfiInsightsEmbeddedDeductionsCategorizationPage,
  },
  {
    id: 'UnfiInsightsEmbeddedDebug',
    path: '/unfi-insights-embedded/debug',
    Render: UnfiInsightsEmbeddedDebugPage,
  },
] as const satisfies RouteDeclaration[];

export type UnfiEmbeddedRouteId = (typeof UnfiEmbeddedRoutes)[number]['id'];

export const RouteById = UnfiEmbeddedRoutes.reduce<RouteIdToRoute<UnfiEmbeddedRouteId>>(
  (acc, r) => ({ ...acc, [r.id]: r }),
  {} as RouteIdToRoute<UnfiEmbeddedRouteId>,
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const unfiEmbeddedPath = (
  routeId: UnfiEmbeddedRouteId,
): ((params?: RouteParams) => string) => {
  return params => compilePath(RouteById[routeId].path)(params && encodeRouteParams(params));
};
