import styles from './styles.module.css'; // eslint-disable-line
import React, { AnchorHTMLAttributes } from 'react';
import clsx from 'clsx';
import { Placement } from '@popperjs/core';
import ReactMarkdown from 'react-markdown';
import InfoIconTooltip from '../Tooltip/InfoIconTooltip';
import { TooltipContent, Trigger } from '../Tooltip';
import { ExternalLinkTarget } from '../../lettuce/components/ExternalLink';

type BasicLabelProps = {
  className?: string;
  htmlFor?: string;
  description?: string;
  label?: string;
};

export const BasicLabel = React.forwardRef<HTMLLabelElement, BasicLabelProps>(function BasicLabel(
  { label, ...labelProps },
  ref,
) {
  return (
    <label ref={ref} {...labelProps} className={styles.label}>
      {label}
    </label>
  );
});

const RenderWithTooltip = ({ children, content, placement }) => (
  <>
    {children}
    <InfoIconTooltip content={content} placement={placement} trigger={Trigger.HOVER} />
  </>
);

const getLinkTarget = (href?: string) => {
  return href && href.startsWith('https://support.gocrisp.com')
    ? ExternalLinkTarget.knowledgeBase
    : ExternalLinkTarget.newTab;
};

const MarkdownComponents = {
  a: ({ href, children, ...rest }: AnchorHTMLAttributes<HTMLAnchorElement>) => (
    <a {...rest} href={href} target={getLinkTarget(href)}>
      {children}
    </a>
  ),
};

type RenderDescriptionProps = { description?: string };
const RenderDescription: React.FC<RenderDescriptionProps> = React.memo(function RenderDescription({
  description,
}: RenderDescriptionProps) {
  return description ? (
    <div className={styles.labelDescription}>
      <ReactMarkdown components={MarkdownComponents}>{description}</ReactMarkdown>
    </div>
  ) : null;
});

export type LabelProps = BasicLabelProps & {
  tooltipContent?: TooltipContent;
  tooltipPlacement?: Placement;
};

const Label: React.FC<LabelProps> = ({
  className,
  description,
  htmlFor,
  label,
  tooltipContent,
  tooltipPlacement,
}) => {
  const labelProps = { description, htmlFor, label };
  const tooltipProps = { content: tooltipContent, placement: tooltipPlacement };

  return (
    <div className={clsx('mod', className)}>
      {label ? (
        tooltipContent ? (
          <RenderWithTooltip {...tooltipProps}>
            <BasicLabel {...labelProps} />
          </RenderWithTooltip>
        ) : (
          <BasicLabel {...labelProps} />
        )
      ) : null}
      <RenderDescription description={description} />
    </div>
  );
};

export default Label;
