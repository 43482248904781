import React from 'react';
import { Link } from 'react-router-dom';
import {
  useGetModifiedMasterDataEntriesQuery,
  useSubmitGoogleSheetsMasterDataMutation,
} from '../../../generated/graphql';
import { ButtonType, PageHeader, PageLayout, Spinner } from '../../ui';
import { useSuccess } from '../../Toast/Toasts';
import { useGoToConnectors } from '../lib';
import WithPageActionBar from '../../PageActionBar/WithPageActionBar';
import { Action, ActionPosition } from '../../PageActionBar';
import { path } from '../../lettuce/crisp/routing/lib';
import { ModifiedEntriesTable } from './ModifiedEntriesTable';

const { Content, Header } = PageLayout;

type SubmitGoogleSheetsMasterDataProps = {
  accountId: string;
  connectorConfigurationSlug: string;
};

export function SubmitGoogleSheetsMasterData({
  accountId,
  connectorConfigurationSlug,
}: SubmitGoogleSheetsMasterDataProps) {
  const goToConnectors = useGoToConnectors(accountId);
  const { show: showSuccessToast } = useSuccess(
    <>
      Master Data successfully saved.{' '}
      <Link to={path('BusinessDashboardGroup')({ accountId })}>
        Go to business dashboards to see results.
      </Link>
    </>,
    { autoDismissAfterMs: undefined },
  );

  const { data, loading } = useGetModifiedMasterDataEntriesQuery({
    variables: {
      accountId: accountId,
      connectorConfigurationSlug: connectorConfigurationSlug,
    },
  });

  const [submit, { loading: submitting }] = useSubmitGoogleSheetsMasterDataMutation({
    onCompleted: () => {
      showSuccessToast();
      goToConnectors();
    },
    variables: {
      accountId: accountId,
      connectorConfigurationSlug: connectorConfigurationSlug,
    },
  });

  const masterData = data?.account?.connectorConfiguration?.googleSheetsMasterData;

  const onSave = () => {
    submit();
  };
  const onCancel = () =>
    masterData?.spreadsheetUrl && (window.location.href = masterData.spreadsheetUrl);

  const modifiedEntries = masterData?.modifiedEntries || [];
  const canSubmit = modifiedEntries.length > 0;

  if (loading || !masterData) return <Spinner />;

  const actions: Action[] = [
    {
      label: 'Back to spreadsheet',
      position: ActionPosition.Left,
      type: ButtonType.Button,
      onClick: onCancel,
    },
    {
      label: submitting ? 'Submitting...' : 'Submit',
      isPrimary: true,
      type: ButtonType.Submit,
      onClick: onSave,
      disabled: loading || !canSubmit || submitting,
    },
  ];

  return (
    <WithPageActionBar actions={actions} withoutSidebars={true}>
      {ref => (
        <div ref={ref as React.Ref<HTMLDivElement>}>
          <Header>
            <PageHeader heading="Submit Master Data" className="mbn" />
          </Header>

          <Content className="mod">
            {canSubmit ? (
              <ModifiedEntriesTable entries={modifiedEntries} />
            ) : (
              <>No changes found in spreadsheet.</>
            )}
          </Content>
        </div>
      )}
    </WithPageActionBar>
  );
}
