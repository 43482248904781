import React from 'react';
import { MsalProvider } from '@azure/msal-react';
import { BrowserCacheLocation, Configuration, PublicClientApplication } from '@azure/msal-browser';
import { FCC } from '../../../utils/types';
import { useAuthenticationContext } from '../../security/AuthenticationContext';
import { resolveUnfiInsightsRedirectUri } from '../unfiInsightsEmbedded/lib';

const {
  REACT_APP_UNFI_INSIGHTS_CLIENT_ID,
  REACT_APP_UNFI_INSIGHTS_AUTHORITY,
  REACT_APP_UNFI_INSIGHTS_KNOWN_AUTHORITIES,
  REACT_APP_UNFI_INSIGHTS_PROVIDER_ID,
} = process.env;
const CLIENT_ID = REACT_APP_UNFI_INSIGHTS_CLIENT_ID || '';
const AUTHORITY = REACT_APP_UNFI_INSIGHTS_AUTHORITY || '';
const REDIRECT_URI = resolveUnfiInsightsRedirectUri();
const KNOWN_AUTHOROTIES = (REACT_APP_UNFI_INSIGHTS_KNOWN_AUTHORITIES || '').split(',');
const OIDC_PROVIDER_ID = REACT_APP_UNFI_INSIGHTS_PROVIDER_ID || '';

const configuration: Configuration = {
  auth: {
    clientId: CLIENT_ID,
    authority: AUTHORITY,
    redirectUri: REDIRECT_URI,
    knownAuthorities: KNOWN_AUTHOROTIES,
  },
  system: {
    allowRedirectInIframe: false,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage,
  },
};
const pca = new PublicClientApplication(configuration);

const UnfiMsalProvider: FCC = ({ children }) => {
  const { firebaseUser } = useAuthenticationContext();

  // User is not authenticated via UNFI (or is not logged in to Crisp at all)
  if (
    !firebaseUser ||
    firebaseUser.providerData.findIndex(data => data?.providerId === OIDC_PROVIDER_ID) === -1
  ) {
    return <>{children}</>;
  }

  return <MsalProvider instance={pca}>{children}</MsalProvider>;
};

export default UnfiMsalProvider;
