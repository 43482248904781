import { useForm } from 'react-hook-form';
import {
  useGetUserPermissionsQuery,
  useGrantUserAccountAccessMutation,
  useGrantUserAllDashboardsPermissionMutation,
  UserAccountRole,
  UserFragment,
  useSetUserDashboardPermissionsMutation,
} from '../../../../generated/graphql';
import { useGetDefaultDashboardPermissions } from './useGetDefaultDashboardPermissions';
import { ALL_DASHBOARDS_ID } from './DashboardPermissionsSelection';
import { getUserAccountRole, getUserPermissionsInputFromFormData } from './lib';

export type UsePermissionSelectionFormParams = {
  accountId: string;
  user: UserFragment;
  onSubmit: () => void;
};

const usePermissionSelectionForm = ({
  user,
  accountId,
  onSubmit,
}: UsePermissionSelectionFormParams) => {
  const { accountPermissions, id: userId } = user;
  const useFormMethods = useForm();
  const { data, loading: loadingPermissions } = useGetUserPermissionsQuery({
    variables: { accountId, userId: user.id },
    fetchPolicy: 'cache-and-network',
  });
  const { defaultDashboardPermissions, loading: loadingDefaultDashboardPermissions } =
    useGetDefaultDashboardPermissions(accountId, data?.account.dashboardPermissions);
  const loading = loadingPermissions || loadingDefaultDashboardPermissions;
  const [setUserDashboardPermissions, { loading: isSavingDashboardPermissions }] =
    useSetUserDashboardPermissionsMutation();
  const [grantUserAllDashboardPermissions, { loading: isSavingGrantUserAllDashboardPermissions }] =
    useGrantUserAllDashboardsPermissionMutation();
  const [grantUserAccountAccess, { loading: isSavingUserRole }] =
    useGrantUserAccountAccessMutation();

  const isSaving =
    isSavingDashboardPermissions || isSavingGrantUserAllDashboardPermissions || isSavingUserRole;

  const defaultUserRole =
    getUserAccountRole(accountPermissions, accountId) || UserAccountRole.Viewer;

  const userRole = useFormMethods.watch('userRole', defaultUserRole);

  const submit = useFormMethods.handleSubmit(data => {
    grantUserAccountAccess({
      variables: {
        input: {
          accountId,
          userId,
          accountRole: data.userRole,
        },
      },
    });
    if (data.userRole === UserAccountRole.Member) {
      if (data.dashboardSelection[ALL_DASHBOARDS_ID][ALL_DASHBOARDS_ID][ALL_DASHBOARDS_ID]) {
        grantUserAllDashboardPermissions({ variables: { accountId, userId } });
      } else {
        const permissions = getUserPermissionsInputFromFormData(data.dashboardSelection);
        setUserDashboardPermissions({
          variables: {
            accountId,
            userId: user.id,
            permissions,
          },
        });
      }
    }
    onSubmit();
  });

  return {
    submit,
    defaultDashboardPermissions,
    defaultUserRole,
    useFormMethods,
    userRole,
    isSaving,
    loading,
  };
};

export default usePermissionSelectionForm;
