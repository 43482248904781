import React, { PropsWithChildren, ReactNode, SVGProps } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { ReactComponent as IconRemove } from '../../../../assets/icons/lettuce/remove.svg';
import { ReactComponent as IconDropdown } from '../../../../assets/icons/lettuce/chevron-down.svg';
import { FCC } from '../../../../utils/types';
import style from './index.module.css'; // eslint-disable-line

export const ChipSizes = ['M', 'S'] as const;
export type ChipSize = (typeof ChipSizes)[number];

type RenderChipContentWithOptionalIconsProps = {
  IconLeft?: React.FC<SVGProps<SVGSVGElement>>;
  IconRight?: React.FC<SVGProps<SVGSVGElement>>;
};

const RenderChipContentWithOptionalIcons: FCC<RenderChipContentWithOptionalIconsProps> = ({
  children,
  IconLeft,
  IconRight,
}) => {
  return (
    <>
      {IconLeft && <IconLeft aria-hidden={true} />}
      {children}
      {IconRight && <IconRight aria-hidden={true} />}
    </>
  );
};

type ChipButtonProps = Pick<
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  'onMouseDown' | 'onKeyDown' | 'onFocus' | 'onBlur' | 'id' | 'disabled'
>;

type ChipLinkProps = Pick<
  React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
  'href' | 'target'
>;

export type ChipProps = {
  onClick?: () => void;
  size?: ChipSize;
  className?: string;
  srOnly?: string;
  IconLeft?: React.FC<SVGProps<SVGSVGElement>>;
  IconRight?: React.FC<SVGProps<SVGSVGElement>>;
} & ChipButtonProps &
  ChipLinkProps;

const Chip = React.forwardRef<HTMLButtonElement, PropsWithChildren<ChipProps>>(function Chip(
  {
    onClick,
    href,
    target,
    size = 'M',
    className,
    srOnly,
    children,
    IconLeft,
    IconRight,
    disabled,
    ...buttonProps
  },
  ref,
) {
  const flex = IconLeft || IconRight || typeof children !== 'string';
  const cls = clsx(
    style.chip,
    style[size],
    {
      [style.flex]: flex,
      [style.pls]: IconLeft || !children,
      [style.prs]: IconRight || !children,
      [style.disabled]: disabled,
    },
    className,
  );
  const content = (
    <RenderChipContentWithOptionalIcons IconLeft={IconLeft} IconRight={IconRight}>
      {srOnly && <span className="sr-only">{srOnly}</span>}
      {children}
    </RenderChipContentWithOptionalIcons>
  );

  return href ? (
    <Link
      to={href}
      onClick={onClick}
      target={target}
      ref={ref as React.ForwardedRef<HTMLAnchorElement>}
      className={cls}
    >
      {content}
    </Link>
  ) : onClick ? (
    <button
      disabled={disabled}
      className={cls}
      onClick={onClick}
      type="button"
      ref={ref}
      {...buttonProps}
    >
      {content}
    </button>
  ) : (
    <span ref={ref} className={cls}>
      {content}
    </span>
  );
});

export default Chip;

type ChipOptionProps = {
  text?: string;
  isSelected?: boolean;
  onChange?: (isSelected: boolean) => void;
} & Pick<ChipProps, 'size' | 'className' | 'srOnly' | 'IconRight' | 'IconLeft' | 'id'>;

export const ChipOption: React.FC<ChipOptionProps> = ({
  text,
  isSelected,
  onChange,
  className,
  ...rest
}) => {
  return (
    <Chip
      {...rest}
      className={clsx({ [style.selected]: isSelected }, className)}
      onClick={() => onChange?.(!isSelected)}
    >
      {text}
    </Chip>
  );
};

type ChipSelectBaseProps = {
  children: ReactNode;
  isToggled?: boolean;
  onToggle?: () => void;
} & Pick<ChipProps, 'size' | 'className' | 'srOnly' | 'IconLeft' | 'id'> &
  ChipButtonProps;

const ChipSelectBase = React.forwardRef<HTMLButtonElement, ChipSelectBaseProps>(
  function ChipSelectBase({ children, onToggle, isToggled = false, className, ...rest }, ref) {
    return (
      <Chip
        {...rest}
        className={clsx({ [style.toggled]: isToggled }, className)}
        onClick={() => onToggle?.()}
        ref={ref}
        IconRight={IconDropdown}
      >
        {children}
      </Chip>
    );
  },
);

export type ChipSelectProps = Omit<ChipSelectBaseProps, 'children'> & {
  text: string;
  isToggled?: boolean;
  onToggle?: () => void;
} & Pick<ChipProps, 'size' | 'className' | 'srOnly' | 'IconLeft' | 'id'> &
  ChipButtonProps;

export const ChipSelect = React.forwardRef<HTMLButtonElement, ChipSelectProps>(function ChipSelect(
  { text, ...rest },
  ref,
) {
  return (
    <ChipSelectBase ref={ref} {...rest}>
      {text}
    </ChipSelectBase>
  );
});

export type EphemeralChipProps = {
  text: string;
} & Pick<ChipProps, 'size' | 'className' | 'srOnly' | 'onClick'>;

export const EphemeralChip: React.FC<EphemeralChipProps> = ({ text, className, ...rest }) => {
  return (
    <Chip {...rest} className={className} IconRight={IconRemove}>
      {text}
    </Chip>
  );
};

export const ChipCombo: FCC = ({ children }) => {
  return <div className={style.chipCombo}>{children}</div>;
};

export type ChipSelectWithLabelProps = Omit<ChipSelectBaseProps, 'children'> & {
  labelText: string;
  valueText: string;
};

export const ChipSelectWithLabel = React.forwardRef<HTMLButtonElement, ChipSelectWithLabelProps>(
  function ChipSelectWithValueText({ labelText, valueText, ...rest }, ref) {
    return (
      <ChipSelectBase ref={ref} {...rest}>
        <span className={style.labelText}>{labelText}</span>
        <span>|</span>
        <span className={style.valueText}>{valueText}</span>
      </ChipSelectBase>
    );
  },
);
