import { maybe, Maybe } from '@tellurian/ts-utils';
import { ApolloQueryResult } from '@apollo/client';
import {
  AccountTermsOfServiceFragment,
  GetAccountTermsOfServiceQuery,
  TermsOfServiceUserFragment,
  useGetAccountTermsOfServiceQuery,
} from '../../generated/graphql';

export type TermsOfService = AccountTermsOfServiceFragment & {
  isUserPolicyAdmin: boolean;
  policyAdminUser: Maybe<TermsOfServiceUserFragment>;
};

export type UseTermsOfService = {
  loading: boolean;
  termsOfService: Maybe<TermsOfService>;
  refetch: () => Promise<ApolloQueryResult<GetAccountTermsOfServiceQuery>>;
};

const useTermsOfService = (accountId: string, skip = false): UseTermsOfService => {
  const { data, loading, refetch } = useGetAccountTermsOfServiceQuery({
    skip,
    variables: { accountId },
  });
  if (!data) {
    return {
      loading,
      refetch,
      termsOfService: undefined,
    };
  }

  const currentUserId = data.currentUser?.id;
  const termsOfService = data.account.termsOfService;
  const policyAdminUser = maybe(data.account.policyAdminUser);

  return {
    loading,
    refetch,
    termsOfService: termsOfService
      ? {
          ...termsOfService,
          policyAdminUser,
          isUserPolicyAdmin: !!policyAdminUser && currentUserId === policyAdminUser.id,
        }
      : undefined,
  };
};

export default useTermsOfService;
