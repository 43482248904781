import React, { useState } from 'react';
import { Maybe } from '@tellurian/ts-utils';
import { UserFragment, UserInvitationFragment } from '../../../../generated/graphql';
import { ReactComponent as IconAdd } from '../../../../assets/icons/lettuce/plus.svg';
import PageHeader from '../../components/PageHeader';
import Button, { ButtonVariant } from '../../components/Button';
import { Spinner } from '../../../ui';
import UserInvitationList from './UserInvitationList';
import UserList from './UserList';
import InviteUserModal from './InviteUserModal';
import InvitationAcceptUrlModal from './InvitationAcceptUrlModal';
import DeleteUserModal from './DeleteUserModal';
import PermissionSelectionModal from './PermissionSelectionModal';

type ViewProps = {
  accountId: string;
  loading?: boolean;
  userInvitations?: UserInvitationFragment[];
  users: UserFragment[];
  refresh: () => void;
  emailMatchesCurrentUser: (email: string) => boolean;
};

const View: React.FC<ViewProps> = ({
  accountId,
  userInvitations = [],
  refresh,
  users = [],
  loading = false,
  emailMatchesCurrentUser,
}) => {
  const [showInviteUserModal, setShowInviteUserModal] = useState(false);
  const [showPermissionsModalForUser, setShowPermissionsModalForUser] =
    useState<Maybe<UserFragment>>();
  const [showDeleteModalForUser, setShowDeleteModalForUser] = useState<Maybe<UserFragment>>();
  const [showAcceptUrlModalForInvitationId, setShowAcceptUrlModalForInvitationId] =
    useState<Maybe<string>>();

  return (
    <div className="pbl">
      {showInviteUserModal && (
        <InviteUserModal
          accountId={accountId}
          onCloseClick={() => setShowInviteUserModal(false)}
          onInvitationCreated={() => {
            refresh();
            setShowInviteUserModal(false);
          }}
        />
      )}
      {showAcceptUrlModalForInvitationId && (
        <InvitationAcceptUrlModal
          onCloseClick={() => setShowAcceptUrlModalForInvitationId(undefined)}
          invitationId={showAcceptUrlModalForInvitationId}
          accountId={accountId}
        />
      )}
      {showDeleteModalForUser && (
        <DeleteUserModal
          onCloseClick={() => setShowDeleteModalForUser(undefined)}
          accountId={accountId}
          user={showDeleteModalForUser}
          onUserDeleted={() => {
            refresh();
            setShowDeleteModalForUser(undefined);
          }}
        />
      )}
      {showPermissionsModalForUser && (
        <PermissionSelectionModal
          accountId={accountId}
          user={showPermissionsModalForUser}
          onCloseClick={() => setShowPermissionsModalForUser(undefined)}
        />
      )}
      <PageHeader
        headline="Manage users"
        description="Give users access to your data on the Crisp platform."
        rhs={
          <Button
            variant={ButtonVariant.Primary}
            icon={<IconAdd />}
            onClick={() => setShowInviteUserModal(true)}
          >
            Invite user
          </Button>
        }
      />
      {loading && <Spinner />}
      {userInvitations.length > 0 && (
        <UserInvitationList
          userInvitations={userInvitations}
          accountId={accountId}
          className="mod mtn"
          onShowAcceptUrl={setShowAcceptUrlModalForInvitationId}
          onInvitationRevoked={refresh}
          onInvitationResent={refresh}
        />
      )}
      {users.length > 0 && (
        <UserList
          accountId={accountId}
          users={users}
          emailMatchesCurrentUser={emailMatchesCurrentUser}
          onDelete={setShowDeleteModalForUser}
          onChangePermissions={setShowPermissionsModalForUser}
          className="mod"
        />
      )}
    </div>
  );
};

export default View;
