import React, { useState } from 'react';
import { Maybe } from '@tellurian/ts-utils';
import { noop } from 'lodash';
import clsx from 'clsx';
import { ReactComponent as ClearIcon } from '../../../../assets/icons/lettuce/clear.svg';
import useEventCallback from '../../../lettuce/utils/useEventCallback';
import { Button, ButtonStyle } from '../../Button';
import style from './index.module.css';

const RemoveButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <button className={style.removeButton} onClick={onClick} type="button" aria-label="Remove file">
      <ClearIcon width={18} height={18} /> Remove
    </button>
  );
};

export type FileUploadProps = {
  id?: string;
  value?: File;
  onChange: (nextFile: Maybe<File>) => void;
};

const FileUpload: React.FC<FileUploadProps> = ({ value, onChange, ...rest }) => {
  const onFileSelect = useEventCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0];
    onChange(file);
  });
  const [isFocusVisible, setIsFocusVisible] = useState(false);

  const onFocus = useEventCallback(() => setIsFocusVisible(true));
  const onBlur = useEventCallback(() => setIsFocusVisible(false));

  if (value) {
    return (
      <div className={style.selection}>
        <div>{value.name}</div>
        <div className="mvm">
          <RemoveButton onClick={() => onChange(undefined)} />
        </div>
      </div>
    );
  }

  return (
    <div className={style.fieldContainer}>
      <div className={clsx(style.fileSelectContainer, { [style.focusVisible]: isFocusVisible })}>
        <input type="file" onChange={onFileSelect} {...rest} onFocus={onFocus} onBlur={onBlur} />
        <Button
          tabIndex={-1}
          btnStyle={ButtonStyle.BORDER}
          onClick={noop}
          className={clsx(style.button)}
        >
          Add attachment
        </Button>
      </div>
    </div>
  );
};

export default FileUpload;
