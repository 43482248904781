import React, { useContext } from 'react';
import { AccountInfo } from '@azure/msal-browser';
import firebase from 'firebase/compat';
import { Maybe } from '@tellurian/ts-utils';
import { Nonce, UnfiAuthError, UnfiAuthState } from './lib';
import { useUnfiSignIn } from './useUnfiSignIn';

type UnfiAuthenticationInterface = {
  state: UnfiAuthState;
  azureUser: AccountInfo | null;
  accessToken: string | undefined;
  nonce: Maybe<{ nonce: Nonce; rawNonce: string }>;
  firebaseUser: firebase.User | null;
  error?: UnfiAuthError;
  linkUsers: () => void;
  continueWithUnfiUser: () => void;
  signInWithRedirect: () => void;
};

const UnfiAuthentication = React.createContext<UnfiAuthenticationInterface>({
  state: UnfiAuthState.Loading,
  azureUser: null,
  accessToken: undefined,
  nonce: undefined,
  firebaseUser: {} as firebase.User,
  error: {} as UnfiAuthError,
  linkUsers: () => undefined,
  continueWithUnfiUser: () => undefined,
  signInWithRedirect: () => undefined,
});
export const useUnfiAuthenticationContext = () => useContext(UnfiAuthentication);

export const UnfiAuthenticationProvider = ({
  providerId,
  scopes,
  children,
}: {
  providerId: string;
  scopes: string[];
  children: React.ReactNode;
}) => {
  const signInContext = useUnfiSignIn({ providerId, scopes });
  return (
    <UnfiAuthentication.Provider value={signInContext}>{children}</UnfiAuthentication.Provider>
  );
};
