import { useForm } from 'react-hook-form';
import {
  useCreateUserInvitationMutation,
  useGrantUserAllDashboardsPermissionMutation,
  useSetUserDashboardPermissionsMutation,
} from '../../../../generated/graphql';
import { Feature, trackFeature } from '../../../../utils/features';
import { useGetDefaultDashboardPermissions } from './useGetDefaultDashboardPermissions';
import { ALL_DASHBOARDS_ID } from './DashboardPermissionsSelection';
import { getUserPermissionsInputFromFormData } from './lib';

const useInvitationForm = (accountId: string, onInvitationCreated?: () => void) => {
  const useFormMethods = useForm();
  const [createUserInvitation, { loading: isSavingUserInvitation }] =
    useCreateUserInvitationMutation({
      onCompleted: onInvitationCreated,
    });

  const [setUserDashboardPermissions, { loading: isSavingDashboardPermissions }] =
    useSetUserDashboardPermissionsMutation();
  const [grantUserAllDashboardPermissions, { loading: isSavingGrantUserAllDashboardPermissions }] =
    useGrantUserAllDashboardsPermissionMutation();
  const grantedRole = useFormMethods.watch('userRole');

  const { defaultDashboardPermissions, loading: loadingDefaultDashboardPermissions } =
    useGetDefaultDashboardPermissions(accountId);

  const loading =
    isSavingUserInvitation ||
    isSavingDashboardPermissions ||
    isSavingGrantUserAllDashboardPermissions;

  const submit = data => {
    const email = data.email;
    createUserInvitation({
      variables: {
        accountId,
        userInput: {
          email,
          grantedRole,
        },
      },
    }).then(result => {
      trackFeature(Feature.UserInvited, { email, grantedRole });
      const userId = result.data?.createUserInvitation.invitation.userId;
      if (userId && data.dashboardSelection) {
        if (data.dashboardSelection[ALL_DASHBOARDS_ID][ALL_DASHBOARDS_ID][ALL_DASHBOARDS_ID]) {
          grantUserAllDashboardPermissions({ variables: { accountId, userId } });
        } else {
          const permissions = getUserPermissionsInputFromFormData(data.dashboardSelection);
          setUserDashboardPermissions({
            variables: {
              accountId,
              userId,
              permissions,
            },
          });
        }
      }
    });
  };

  return {
    submit,
    loading,
    useFormMethods,
    defaultDashboardPermissions,
    loadingDefaultDashboardPermissions,
    grantedRole,
  };
};

export default useInvitationForm;
