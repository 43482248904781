import React, { ReactNode } from 'react';
import Headline from '../Headline';
import CloseButton from './CloseButton';
import style from './TitleHeader.module.css';

export type TitleHeaderProps = {
  title: ReactNode;
  // Subtitle is an optional node which can be used to include content below the headline (which includes the close button)
  // Doing so will keep the close button aligned with the title (instead of the both the title and subtitle).
  subtitle?: ReactNode;
  onCloseClick: () => void;
  id?: string;
};

const TitleHeader: React.FC<TitleHeaderProps> = ({
  id = 'modal-title',
  title,
  subtitle,
  onCloseClick,
}) => {
  return (
    <div className={style.container}>
      <div className={style.headline}>
        {typeof title === 'string' ? (
          <Headline size="S" id={id} className={subtitle ? 'mbn' : undefined}>
            {title}
          </Headline>
        ) : (
          title
        )}
        <CloseButton onClick={onCloseClick} />
      </div>
      {subtitle && typeof subtitle === 'string' ? <p>{subtitle}</p> : subtitle}
    </div>
  );
};

export default TitleHeader;
