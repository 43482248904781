import React, { useContext } from 'react';
import { AnyObject, noop } from '@tellurian/ts-utils';
import { TokenType } from 'powerbi-models';
import { FCC } from '../../../../utils/types';
import Spinner from '../Spinner';

type PowerBiAuthenticationContextInterface = {
  loading: boolean;
  token?: string;
  tokenType: TokenType;
  refreshToken?: () => void;
  isTokenExpired?: () => boolean;
  error?: AnyObject;
  retry?: () => void;
  loginRedirect: () => void;
  isSignInRedirectNoticeVisible?: boolean;
};

export const PowerBiAuthenticationContext =
  React.createContext<PowerBiAuthenticationContextInterface>({
    loading: false,
    token: undefined,
    tokenType: TokenType.Aad,
    error: undefined,
    retry: undefined,
    loginRedirect: noop,
    isSignInRedirectNoticeVisible: false,
  });

export type UseAuthReturn = {
  token?: string;
  tokenType: TokenType;
  loading: boolean;
  error?: AnyObject;
  retry?: () => void;
  loginRedirect: () => void;
  isSignInRedirectNoticeVisible?: boolean;
  refreshToken?: () => void;
  isTokenExpired?: () => boolean;
};

export const MaybeChildren: FCC = ({ children }) => {
  const { loading } = usePowerBiAuthentication();
  return loading ? <Spinner /> : children;
};

export type PowerBiAuthenticationProviderProps = {
  useAuth: () => UseAuthReturn;
};

const PowerBiAuthenticationProvider: FCC<PowerBiAuthenticationProviderProps> = ({
  children,
  useAuth,
}) => {
  return (
    <PowerBiAuthenticationContext.Provider value={useAuth()}>
      <MaybeChildren>{children}</MaybeChildren>
    </PowerBiAuthenticationContext.Provider>
  );
};

export default PowerBiAuthenticationProvider;

export const usePowerBiAuthentication = () => useContext(PowerBiAuthenticationContext);
