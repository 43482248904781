import React from 'react';
import { lazyWithRetry } from '../../../lib';
import AccountTemplate from '../templates/AccountTemplate';
import { ViewerAuthorityGrant } from '../../../security/AuthorizationContext';
import AnalyticsBlueprints from '../AnalyticsBlueprints';
import MaxWidthLayout from '../../components/layout/MaxWidthLayout';

const RenderAnalyticsBlueprints = lazyWithRetry<typeof AnalyticsBlueprints>(
  () => import(/* webpackChunkName: "analyticsBlueprints" */ '../AnalyticsBlueprints'),
);

const AnalyticsBlueprintsPage: React.FC = () => {
  return (
    <AccountTemplate requiredGrant={ViewerAuthorityGrant} Layout={MaxWidthLayout} size="S">
      <RenderAnalyticsBlueprints />
    </AccountTemplate>
  );
};

export default AnalyticsBlueprintsPage;
