import { Dispatch, SetStateAction, useCallback } from 'react';
import useTether, {
  GetReferencePropsReturn,
  GetSizeProps,
  UseTethered,
  UseTetherParams,
} from './useTether';

export const DefaultPopoverMaxHeight = '60vh';

export const defaultGetSizeProps: GetSizeProps = ({ rects }) => {
  return {
    minWidth: rects.reference.width,
    minHeight: rects.reference.height,
    maxWidth: Math.max(rects.reference.width * 2, 300),
    maxHeight: DefaultPopoverMaxHeight,
  };
};

const DropdownPlacements = ['start', 'end'] as const;
type DropdownPlacement = (typeof DropdownPlacements)[number];

export type UseDropdownParams = Omit<UseTetherParams, 'placement' | 'getPopoverSizeProps'> & {
  placement?: DropdownPlacement;
  getPopoverSizeProps?: GetSizeProps;
};

export type DropdownReferenceType = 'toggle' | 'input';

export type UseDropdown<T extends HTMLElement = HTMLElement> = {
  isActive: boolean;
  setIsActive: Dispatch<SetStateAction<boolean>>;
  getReferenceProps: <U extends DropdownReferenceType>(
    referenceType?: U,
  ) => U extends 'toggle'
    ? GetReferencePropsReturn<T> & { isToggled: boolean; onToggle: () => void }
    : GetReferencePropsReturn<T>;
  getPopoverProps: UseTethered['getPopoverProps'];
};

/**
 * useDropdown is a specific use case of the useTether. It provides all props
 * required for the reference element (which is either a component that can be toggled or something more basic)
 * and the popover element. useDropdown does not include support for an arrow on the popover.
 * Use this hook for dropdown menus and select components.
 * @param placement
 * @param getPopoverSizeProps
 * @param tetheredPopoverParams
 */
const useDropdown = <T extends HTMLElement = HTMLButtonElement>({
  placement = 'start',
  getPopoverSizeProps = defaultGetSizeProps,
  ...tetheredPopoverParams
}: UseDropdownParams): UseDropdown<T> => {
  const {
    isActive,
    setIsActive,
    getPopoverProps,
    getReferenceProps: getReferencePropsBase,
  } = useTether({
    ...tetheredPopoverParams,
    getPopoverSizeProps,
    placement: `bottom-${placement}`,
  });

  const getReferenceProps = useCallback(
    (referenceType: DropdownReferenceType = 'toggle') => ({
      ...getReferencePropsBase(),
      ...(referenceType === 'toggle'
        ? { onToggle: () => setIsActive(active => !active), isToggled: isActive }
        : undefined),
    }),
    [isActive, setIsActive, getReferencePropsBase],
  ) as UseDropdown<T>['getReferenceProps'];

  return { isActive, setIsActive, getPopoverProps, getReferenceProps };
};

export default useDropdown;
