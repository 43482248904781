/* eslint-disable react/jsx-key */
import React from 'react';
import {
  Cell as ReactTableCell,
  Column,
  PluginHook,
  Row as ReactTableRow,
  useExpanded,
  useSortBy,
  useTable,
} from 'react-table';
import clsx from 'clsx';
import { AnyObject } from '@tellurian/ts-utils';
import { ReactComponent as ArrowNeutralIcon } from '../../../assets/icons/arrows-up-down-neutral.svg';
import { ReactComponent as ArrowActiveIcon } from '../../../assets/icons/arrows-up-down-active.svg';
import { Blur } from '../index';
import { useThemeContext } from '../../lettuce/components/Theme/ThemeProvider';
import legacyStyle from './legacy.module.css'; // eslint-disable-line
import lettuceStyle from './index.module.css'; // eslint-disable-line

export * from './Cells/Cell';
export * from './Cells/SimpleCell';

type RenderTableCellsProps<TData extends AnyObject> = {
  cellProps?: (cell: ReactTableCell<TData> & { className?: string }) => void;
  cells: ReactTableCell<TData>[];
};
type TableInterfaceProps<TInterface extends AnyObject = AnyObject> = TInterface;
type RenderTableRowProps<TData extends AnyObject> = Pick<RenderTableCellsProps<TData>, 'cellProps'>;

export type SimpleTableProps<
  TData extends AnyObject = AnyObject,
  TInterface extends AnyObject = AnyObject,
> = TableInterfaceProps<TInterface> &
  RenderTableRowProps<TData> & {
    columns: Column<TData>[];
    data: TData[];
    displayInline?: boolean;
    editable?: boolean;
    loading?: boolean;
    containerRef?: React.Ref<HTMLElement>;
    testId?: string;
    textWrap?: boolean;
    sortable?: boolean;
    onClickRow?: (row: TData) => void;
    className?: string;
  };

export type Row = Pick<ReactTableRow, 'index'> & {
  parentIndex?: number;
  depth: number;
};

const getTDProps = <TData extends AnyObject>({
  cell,
  cellProps,
}: Pick<RenderTableCellsProps<TData>, 'cellProps'> & {
  cell: ReactTableCell<TData>;
}) => {
  return cell.getCellProps(
    // @ts-ignore
    [
      cellProps?.(cell),
      {
        // @ts-ignore
        className: cell.column.className,
      },
      // Filter undefined results as react-table prop merger cannot handle undefined values in this array
    ].filter(e => e),
  );
};

function RenderTableCells<TData extends AnyObject>({
  cells,
  cellProps,
}: RenderTableCellsProps<TData>) {
  return (
    <>
      {cells.map(cell => {
        const tdProps = getTDProps({ cell, cellProps });
        return (
          <td {...tdProps} key={tdProps.key}>
            {cell.render('Cell')}
          </td>
        );
      })}
    </>
  );
}

export function SimpleTable<
  TData extends AnyObject = AnyObject,
  TInterface extends AnyObject = AnyObject,
>({
  cellProps,
  columns,
  data,
  displayInline = false,
  loading = false,
  testId,
  onClickRow,
  textWrap = false,
  sortable = false,
  className,
  ...rest
}: SimpleTableProps<TData, TInterface>) {
  const { isLettuceTheme } = useThemeContext();
  const style = isLettuceTheme ? lettuceStyle : legacyStyle;

  const plugins: Array<PluginHook<TData>> = sortable ? [useSortBy, useExpanded] : [useExpanded];

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      autoResetExpanded: true,
      columns,
      data,
      // Everything that's added to useTable options will be available on the table instance
      ...rest,
    },
    ...plugins,
  );

  return (
    <div
      className={clsx(style.body, className, {
        [style.inline]: displayInline,
      })}
    >
      {loading && <Blur className={style.blur} />}
      <table
        className={clsx(style.table, { [style.textWrap]: textWrap })}
        {...getTableProps()}
        data-testid={testId}
      >
        <thead>
          {headerGroups.map(headerGroup => {
            const headerGroupProps = headerGroup.getHeaderGroupProps();
            return (
              <tr {...headerGroupProps} key={headerGroupProps.key}>
                {/* eslint-disable @typescript-eslint/no-explicit-any */}
                {headerGroup.headers.map((column: any) => {
                  const columnHeaderProps = column.getHeaderProps(column.getSortByToggleProps);
                  return (
                    <th
                      {...columnHeaderProps}
                      key={columnHeaderProps.key}
                      className={column.className}
                    >
                      <div>
                        {column.render('Header')}
                        {column.isSorted ? (
                          <span className={clsx(style.sortIconContainer, style.sortIconSorted)}>
                            {column.isSortedDesc ? (
                              <ArrowActiveIcon
                                width="12"
                                height="12"
                                style={{ transform: 'rotate(180deg)' }}
                              />
                            ) : (
                              <ArrowActiveIcon width="12" height="12" />
                            )}
                          </span>
                        ) : column.canSort ? (
                          <span className={style.sortIconContainer}>
                            <ArrowNeutralIcon width="12" height="12" />
                          </span>
                        ) : null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            const { key, ...rowProps } = row.getRowProps();
            return (
              <React.Fragment key={key}>
                <tr
                  {...rowProps}
                  onClick={onClickRow ? () => onClickRow(row.original) : undefined}
                  tabIndex={onClickRow ? 0 : undefined}
                  onKeyDown={
                    onClickRow
                      ? event => {
                          if (event.key === 'Enter') {
                            onClickRow(row.original);
                          }
                        }
                      : undefined
                  }
                >
                  <RenderTableCells cells={row.cells} cellProps={cellProps} />
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
