import React from 'react';
import { RouteComponentProps } from 'react-router';
import ManageUsers from '../components/lettuce/crisp/ManageUsers';
import AccountTemplate from '../components/lettuce/crisp/templates/AccountTemplate';
import { AdminAuthorityGrant } from '../components/security/AuthorizationContext';

type MatchParams = {
  accountId: string;
};

export default function ManageAccountMembersPage({
  match: {
    params: { accountId },
  },
}: RouteComponentProps<MatchParams>) {
  return (
    <AccountTemplate requiredGrant={AdminAuthorityGrant}>
      <ManageUsers accountId={accountId} />
    </AccountTemplate>
  );
}
