import { TypePolicies } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import { Connector } from '../generated/graphql';

// Default merge policies
const arrayReplacePolicy = <T>(existing: T[], incoming: T[]) => incoming;

const typePolicies: TypePolicies = Object.freeze({
  Query: {
    fields: {
      tableMetadataLabels: { merge: arrayReplacePolicy },

      // Use @connection directive in order for apollo to use the predicate arg
      // as a cache key
      unfiInsightsPaymentDeductions: { keyArgs: ['@connection', ['key']] },
    },
  },
  AccountConnection: {
    merge: arrayReplacePolicy,
  },
  Account: {
    fields: {
      settings: {
        merge: true,
      },
      connectors: {},
      dashboardPermissions: {
        merge: arrayReplacePolicy,
      },
      alertRulesets: {
        merge: arrayReplacePolicy,
      },
      connectorConfigurations: {
        merge: arrayReplacePolicy,
      },
    },
  },
  Connector: {
    keyFields: (connector, context) => {
      // Disable normalisation if the Connector is a child of the Account object. This is to
      // prevent Apollo from using the same connector state (isActive) across all accounts queries.
      const condition = context.fragmentMap?.platformAccountDetails?.typeCondition?.name;
      if (condition?.kind === 'Name' && condition?.value === 'Account') {
        return undefined;
      }

      return (connector as Pick<Connector, 'id'>)?.id || undefined;
    },
  },
  ConnectorConfiguration: {
    fields: {
      credentialVerification: {
        merge: (existing, incoming) => incoming,
      },
      reportSchedule: {
        merge: arrayReplacePolicy,
      },
      products: relayStylePagination(),
    },
  },
  Dashboard: {
    keyFields: ['dashboardGroupId', 'id'],
    fields: {
      bookmarks: {
        merge: arrayReplacePolicy,
      },
      state: {
        merge: arrayReplacePolicy,
      },
    },
  },
  DashboardGroup: {
    fields: {
      dashboards: {
        merge: arrayReplacePolicy,
      },
    },
  },
  EmailAlertDestination: {
    keyFields: ['emailAddress'],
  },
  AlertRuleset: {
    fields: {
      destinations: {
        merge: arrayReplacePolicy,
      },
      retailVoidAlertRules: {
        merge: arrayReplacePolicy,
      },
    },
  },
  ConnectorMetadataVersion: {
    keyFields: ['accountId', 'metadataModuleId', 'majorVersion', 'minorVersion'],
  },
  ConnectorMetadataTable: {
    keyFields: false,
  },
  ConnectorConfigurationIngestionStatus: {
    keyFields: ['accountId', 'connectorConfigurationId'],
  },
  TableMetadataLabel: {
    // Apollo does not recognise id as a key because it is of type "Long" ("any" in TS)
    keyFields: ['name'],
  },
  MetadataModule: {
    fields: {
      labels: { merge: arrayReplacePolicy },
    },
  },
  UnfiInsightsPaymentDeductions: {
    fields: {
      payments: relayStylePagination(),
    },
  },
});

export default typePolicies;
