import React, { ReactNode } from 'react';
import style from './NoItems.module.css';

type NoItemsProps = {
  message?: ReactNode;
};

const NoItems: React.FC<NoItemsProps> = ({ message = 'No items available.' }) => {
  return <div className={style.noItems}>{message}</div>;
};

export default NoItems;
