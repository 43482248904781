import React, { useEffect, useMemo } from 'react';
import { Maybe } from '@tellurian/ts-utils';
import { ConnectorApplication } from '../../../../../../generated/graphql';
import { useFavoriteReportsContext } from '../../../../components/FavoriteReportsContext';
import useDashboardGroupsWithDashboardsByTier, {
  DashboardGroupForMenu,
} from '../AllReports/useDashboardGroupsWithDashboardsByTier';
import View from './View';

export type StandardViewsProps = {
  dashboardGroups: DashboardGroupForMenu[];
  dashboardGroupsLoading: boolean;
  getConnectorName: (application: ConnectorApplication) => Maybe<string>;
  getPath: (dashboardGroupId: string, dashboardId: string) => string;
  searchQuery?: string;
  setHasStandardViewsResults?: (nextValue: boolean) => void;
};

const StandardViews: React.FC<StandardViewsProps> = ({
  dashboardGroups,
  dashboardGroupsLoading,
  getConnectorName,
  getPath,
  searchQuery,
  setHasStandardViewsResults,
}) => {
  const filteredDashboardGroups = useMemo(() => {
    if (!searchQuery) return dashboardGroups;

    const trimmedLowerCaseQuery = searchQuery.trim().toLowerCase();
    const ret: DashboardGroupForMenu[] = [];
    for (const group of dashboardGroups) {
      if (group.name.toLowerCase().includes(searchQuery)) {
        ret.push(group);
      } else {
        const dashboards = group.dashboards.filter(d =>
          d.name.toLowerCase().includes(trimmedLowerCaseQuery),
        );
        if (dashboards.length > 0) {
          ret.push({
            ...group,
            dashboards,
          });
        }
      }
    }
    return ret;
  }, [dashboardGroups, searchQuery]);

  useEffect(() => {
    if (setHasStandardViewsResults) {
      setHasStandardViewsResults(filteredDashboardGroups.length > 0);
    }
  }, [filteredDashboardGroups, setHasStandardViewsResults]);

  const dashboardGroupsWithDashboardsByTier =
    useDashboardGroupsWithDashboardsByTier(filteredDashboardGroups);

  const {
    isReportFavorite,
    add: addFavoriteReport,
    remove: removeFavoriteReport,
  } = useFavoriteReportsContext();

  return (
    <View
      loading={dashboardGroupsLoading}
      dashboardGroupsWithDashboardsByTier={dashboardGroupsWithDashboardsByTier}
      getPath={getPath}
      getConnectorName={getConnectorName}
      isReportFavorite={isReportFavorite}
      addFavoriteReport={addFavoriteReport}
      removeFavoriteReport={removeFavoriteReport}
    />
  );
};

export default StandardViews;
