import React, { CSSProperties, DetailedHTMLProps, MouseEvent, PropsWithChildren, Ref } from 'react';
import FocusTrap from 'focus-trap-react';
import clsx from 'clsx';
import { GetArrowProps } from './useArrowTether';
import PopoverArrow from './PopoverArrow';
import styles from './Popover.module.css';

const disableMouseDown = (ev: MouseEvent<HTMLDivElement>) => {
  ev.stopPropagation();
  ev.preventDefault();
};

const FocusTrapOptions = {
  allowOutsideClick: true,
  // See https://github.com/focus-trap/tabbable#help for why this is necessary.
  tabbableOptions:
    process.env.NODE_ENV === 'test'
      ? ({
          displayCheck: 'none',
        } as const)
      : undefined,
};

export type PopoverProps = {
  style?: CSSProperties;
  className?: string;
  id?: string;
  role?: 'dialog' | 'listbox' | 'menu';
  focusTrap?: boolean;
  getArrowProps?: GetArrowProps;
  noMouseDown?: boolean;
} & Pick<DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'aria-label'>;

const Popover = React.forwardRef<HTMLElement, PropsWithChildren<PopoverProps>>(function Popover(
  {
    children,
    focusTrap = false,
    style,
    className,
    id,
    role = 'dialog',
    getArrowProps,
    noMouseDown = false,
    ...rest
  }: PropsWithChildren<PopoverProps>,
  ref,
) {
  const popover = (
    <div
      id={id}
      ref={ref as Ref<HTMLDivElement>}
      style={style}
      role={role}
      className={clsx(styles.popover, { [styles.hasArrow]: getArrowProps }, className)}
      data-floating={true}
      onMouseDown={noMouseDown ? disableMouseDown : undefined}
      {...rest}
    >
      {children}
      {getArrowProps && <PopoverArrow {...getArrowProps()} />}
    </div>
  );
  return focusTrap ? <FocusTrap focusTrapOptions={FocusTrapOptions}>{popover}</FocusTrap> : popover;
});

export default Popover;
