import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { BrowserCacheLocation, Configuration, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import PageTemplate from '../../crisp/navigation/PageTemplate';
import { resolveUnfiInsightsRedirectUri } from '../../unfiInsightsEmbedded/lib';
import { lazyWithRetry } from '../../../lib';
import UnfiInsightsUserMerge from '../UnfiInsightsUserMerge';

const {
  REACT_APP_UNFI_INSIGHTS_CLIENT_ID,
  REACT_APP_UNFI_INSIGHTS_AUTHORITY,
  REACT_APP_UNFI_INSIGHTS_KNOWN_AUTHORITIES,
  REACT_APP_UNFI_INSIGHTS_PROVIDER_ID,
  REACT_APP_UNFI_INSIGHTS_SCOPES,
} = process.env;
const CLIENT_ID = REACT_APP_UNFI_INSIGHTS_CLIENT_ID || '';
const AUTHORITY = REACT_APP_UNFI_INSIGHTS_AUTHORITY || '';
const REDIRECT_URI = resolveUnfiInsightsRedirectUri();
const KNOWN_AUTHOROTIES = (REACT_APP_UNFI_INSIGHTS_KNOWN_AUTHORITIES || '').split(',');
const OIDC_PROVIDER_ID = REACT_APP_UNFI_INSIGHTS_PROVIDER_ID || '';
const SCOPES = (REACT_APP_UNFI_INSIGHTS_SCOPES || '').split(',');

const configuration: Configuration = {
  auth: {
    clientId: CLIENT_ID,
    authority: AUTHORITY,
    redirectUri: REDIRECT_URI,
    knownAuthorities: KNOWN_AUTHOROTIES,
  },
  system: {
    allowRedirectInIframe: false,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage,
  },
};

const pca = new PublicClientApplication(configuration);

type MatchParams = {
  token: string;
};

const RenderUnfiInsightsUserMerge = lazyWithRetry<typeof UnfiInsightsUserMerge>(
  () => import(/* webpackChunkName: "unfiInsightsUserMerge" */ '../UnfiInsightsUserMerge'),
);

export default function UnfiInsightsUserMergePage({ match }: RouteComponentProps<MatchParams>) {
  const { token } = match.params;
  return (
    <PageTemplate>
      <MsalProvider instance={pca}>
        <RenderUnfiInsightsUserMerge token={token} providerId={OIDC_PROVIDER_ID} scopes={SCOPES} />
      </MsalProvider>
    </PageTemplate>
  );
}
