import React from 'react';
import clsx from 'clsx';
import style from './index.module.css';

export type NativeSelectProps = Omit<
  React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>,
  'ref'
> & {
  hasError?: boolean;
};

const NativeSelect = React.forwardRef<HTMLSelectElement, NativeSelectProps>(function NativeSelect(
  { children, className, hasError, ...rest },
  ref,
) {
  return (
    <div className={clsx(style.wrapper, className)}>
      <select ref={ref} className={clsx(style.select, { [style.hasError]: hasError })} {...rest}>
        {children}
      </select>
    </div>
  );
});

export default React.memo(NativeSelect);
