import React from 'react';
import { Redirect } from 'react-router';
import { path } from '../../crisp/routing/lib';
import { PartnerType } from '../../components/lib/Account';

const LandingPage: React.FC = () => {
  // Return partnerIds from auth details and redirect to respective partner page
  // We only have one partner today
  return <Redirect to={path('PartnerHome')({ partnerType: PartnerType.Dot })} />;
};

export default LandingPage;
