import { Maybe, noop } from '@tellurian/ts-utils';
import { TOAST_ANIMATION_DURATION } from '../../../../Toast/Toast';
import {
  NotificationDispatcher,
  NotificationItem,
  NotificationItemCallbacks,
  NotificationSpec,
  NotificationState,
  NotificationType,
} from './lib';

type ToastDispatcherFactoryParams = {
  push: (notificationItem: NotificationItem) => void;
  remove: (notificationItem: NotificationItem) => void;
  flush: () => void;
  onPathnameChange: (fn: () => void) => () => void;
  onHistoryChange: (fn: () => void) => () => void;
};

const nextNotificationId = ((startAt = 0) => {
  return () => String(startAt++);
})();

type GetCompletionFns = (fns: Partial<NotificationItemCallbacks>) => NotificationItemCallbacks;

const isAnimating = ({ state }: { state: NotificationState }) =>
  state === NotificationState.AnimatingShow || state === NotificationState.AnimatingDismiss;

const toastDispatcherFactory = ({
  push,
  remove,
  flush,
  onPathnameChange,
  onHistoryChange,
}: ToastDispatcherFactoryParams): NotificationDispatcher => {
  const show = (spec: NotificationSpec) => {
    let dismissTimeout: Maybe<number> = undefined;
    let unregisterOnDismiss: Maybe<() => void> = undefined;
    let pendingUpdate: Maybe<NotificationSpec> = undefined;

    const clearTimeout = () => {
      if (dismissTimeout) {
        window.clearTimeout(dismissTimeout);
        dismissTimeout = undefined;
      }
    };

    const {
      content,
      title,
      notificationType,
      dismissAfterMs,
      dismissOnLocationChangeImmediate,
      dismissOnHistoryChange,
      dismissOnPathnameChange,
    } = spec;
    const notification: NotificationItem = {
      id: nextNotificationId(),
      state: NotificationState.AnimatingShow,
      content,
      title,
      notificationType: notificationType || NotificationType.Info,
    };

    const notificationOptions = {
      dismissAfterMs,
      dismissOnLocationChangeImmediate,
      dismissOnHistoryChange,
      dismissOnPathnameChange,
    };

    const dismiss = (immediate = false) => {
      if (notification.state !== NotificationState.Dismissed) {
        if (unregisterOnDismiss) {
          unregisterOnDismiss();
          unregisterOnDismiss = undefined;
        }

        if (immediate) {
          remove(notification);
          notification.state = NotificationState.Dismissed;
        } else {
          notification.state = NotificationState.AnimatingDismiss;
          flush();
          clearTimeout();
        }

        return true;
      }

      return false;
    };

    const scheduleDismiss = (adjustment = 0) => {
      const { dismissAfterMs } = notificationOptions;
      if (dismissAfterMs !== undefined) {
        if (!dismissTimeout) {
          dismissTimeout = window.setTimeout(
            () => dismiss(),
            adjustment + dismissAfterMs + TOAST_ANIMATION_DURATION,
          );
          return true;
        }
      }

      return false;
    };

    const applyPendingUpdate = (getCompletionFns: GetCompletionFns) => {
      if (pendingUpdate) {
        const {
          dismissOnLocationChangeImmediate,
          dismissAfterMs,
          dismissOnHistoryChange,
          dismissOnPathnameChange,
          ...notificationProps
        } = pendingUpdate;
        Object.assign(notification, notificationProps, getCompletionFns(pendingUpdate));
        Object.assign(notificationOptions, {
          dismissOnLocationChangeImmediate,
          dismissAfterMs,
          dismissOnHistoryChange,
          dismissOnPathnameChange,
        });
        pendingUpdate = undefined;
        return true;
      }

      return false;
    };

    const getCompletionFns: GetCompletionFns = fns => ({
      onDismissAnimationComplete: () => {
        notification.state = NotificationState.Dismissed;
        remove(notification);
        applyPendingUpdate(getCompletionFns);
        return fns.onDismissAnimationComplete?.();
      },
      onShowAnimationComplete: () => {
        notification.state = NotificationState.Showing;
        applyPendingUpdate(getCompletionFns);
        flush();
        return fns.onShowAnimationComplete?.();
      },
      onDismiss:
        fns.onDismiss &&
        (() => {
          dismiss();
          fns.onDismiss?.();
        }),
    });

    Object.assign(notification, getCompletionFns(spec));

    const showNotification = () => {
      notification.state = NotificationState.AnimatingShow;
      push(notification);
      scheduleDismiss();

      const { dismissOnHistoryChange, dismissOnPathnameChange, dismissOnLocationChangeImmediate } =
        notificationOptions;
      if (dismissOnHistoryChange) {
        unregisterOnDismiss = onHistoryChange(() => dismiss(dismissOnLocationChangeImmediate));
      } else if (dismissOnPathnameChange) {
        unregisterOnDismiss = onPathnameChange(() => dismiss(dismissOnLocationChangeImmediate));
      }
    };

    const update = (nextSpec: NotificationSpec) => {
      pendingUpdate = nextSpec;
      if (!isAnimating(notification)) {
        applyPendingUpdate(getCompletionFns);
      }
    };

    const showAgain = (nextSpec?: NotificationSpec) => {
      if (nextSpec) {
        update({ ...spec, ...nextSpec });
      }
      if (notification.state === NotificationState.Showing) {
        // Continue to show animation for another autoDismissAfterMs milliseconds (if defined)
        clearTimeout();
        flush();
        scheduleDismiss(-TOAST_ANIMATION_DURATION);
        return true;
      }

      if (notification.state !== NotificationState.AnimatingShow) {
        dismiss(true);
        showNotification();
        return true;
      }

      return false;
    };

    showNotification();

    return { dismiss, showAgain, getState: () => notification.state };
  };

  return {
    show,
    dispose: noop,
  };
};

export default toastDispatcherFactory;
