import Bowser from 'bowser';

const { onMouseLeave, onMouseEnter } = (() => {
  const leaveSubscribers = new Set<{
    target: HTMLElement;
    callback: (toElement: unknown) => void;
  }>();
  const enterSubscribers = new Map<HTMLElement, (toElement: unknown) => void>();

  const moveListener = toElement => {
    leaveSubscribers.forEach(s => {
      if (s.target !== toElement) {
        leaveSubscribers.delete(s);
        s.callback(toElement);
      }
    });

    const callback = enterSubscribers.get(toElement);
    if (callback) {
      enterSubscribers.delete(toElement);
      callback(toElement);
    }

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    onSubscriberRemoved();
  };

  const moveEventListener = event => moveListener(event.toElement);

  const wheelListener = event => moveListener(document.elementFromPoint(event.x, event.y));

  const attachEventListenersIfRequired = () => {
    if (enterSubscribers.size === 0 && leaveSubscribers.size === 0) {
      document.addEventListener('mousemove', moveEventListener);
      document.addEventListener('wheel', wheelListener);
    }
  };

  const onSubscriberRemoved = () => {
    if (enterSubscribers.size === 0 && leaveSubscribers.size === 0) {
      document.removeEventListener('mousemove', moveListener);
      document.removeEventListener('wheel', wheelListener);
    }
  };

  const onMouseLeave = (target, callback) => {
    attachEventListenersIfRequired();
    const s = { target, callback };
    leaveSubscribers.add(s);
    return () => {
      leaveSubscribers.delete(s);
      onSubscriberRemoved();
    };
  };

  const onMouseEnter = (target, callback) => {
    attachEventListenersIfRequired();
    enterSubscribers.set(target, callback);
    return () => {
      enterSubscribers.delete(target);
      onSubscriberRemoved();
    };
  };

  const browser = Bowser.getParser(window.navigator.userAgent);
  const engine = browser.getEngine();
  // Use custom onMouseLeave and onMouseEnter event registration. The events will be interpreted from
  // the more granular (continuous) mousemove and wheel events to address the unreliable mouseleave event triggering
  // in the following browser engines (corresponding to Chrome, Opera, Safari and Edge at the time of writing).
  if (engine && engine.name && ['WebKit', 'Blink', 'EdgeHTML'].includes(engine.name)) {
    return { onMouseLeave, onMouseEnter };
  } else {
    // Default to native mouseenter and mouseleave handlers. This works as expected in Firefox!
    return {
      onMouseEnter: (target, callback) => {
        target.addEventListener('mouseenter', callback);
        return () => target.removeEventListener('mouseenter', callback);
      },
      onMouseLeave: (target, callback) => {
        target.addEventListener('mouseleave', callback);
        return () => target.removeEventListener('mouseleave', callback);
      },
    };
  }
})();

export { onMouseLeave, onMouseEnter };
