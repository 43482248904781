import React, { ReactNode } from 'react';
import { AnyObject } from '@tellurian/ts-utils';
import useIsGlobalAdmin from '../../../../utils/useIsGlobalAdmin';
import { NotFound } from '../../../httpErrors';
import BasicTemplate from './BasicTemplate';

export type GlobalUserTemplateProps<T extends AnyObject> = T & {
  Layout?: 'div' | React.FC<T>;
  children?: ReactNode;
};

function GlobalAdminTemplate<T extends AnyObject>(props: GlobalUserTemplateProps<T>) {
  if (!useIsGlobalAdmin()) {
    return <NotFound />;
  }

  return <BasicTemplate {...props} />;
}

export default GlobalAdminTemplate;
