import React, { useContext, useState } from 'react';
import { noop } from '@tellurian/ts-utils';
import { FCC } from '../../../../utils/types';
import useEventCallback from '../../utils/useEventCallback';

type PageSpecificNavigationItems = {
  logOutRetailAnalytics?: () => void;
};

type PageSpecificNavigationContextInterface = {
  items: PageSpecificNavigationItems;
  setItems: (nextItems: PageSpecificNavigationItems) => void;
  setItem: <T extends keyof PageSpecificNavigationItems>(
    name: T,
    value: PageSpecificNavigationItems[T],
  ) => void;
};

const PageSpecificNavigationContext = React.createContext<PageSpecificNavigationContextInterface>({
  items: {},
  setItem: noop,
  setItems: noop,
});

/**
 * The purpose of this context is to contain navigation items which are enabled/disabled by specific components
 * on specific pages (dynamic menu items). This is a context specific to Crisp navigation, hence items
 * are explicitly declared in the object, no need for a generic list. The items should be picked up by
 * our NavRailMenu component and associated a label and Icon.
 * @param children
 * @constructor
 */
const PageSpecificNavigationContextProvider: FCC = ({ children }) => {
  const [items, setItems] = useState<PageSpecificNavigationItems>({});

  const setItem = useEventCallback(
    <T extends keyof PageSpecificNavigationItems>(
      name: T,
      value: PageSpecificNavigationItems[T],
    ) => {
      setItems(current => ({ ...current, [name]: value }));
    },
  );

  return (
    <PageSpecificNavigationContext.Provider
      value={{
        items,
        setItems,
        setItem,
      }}
    >
      {children}
    </PageSpecificNavigationContext.Provider>
  );
};

export default PageSpecificNavigationContextProvider;

export const usePageSpecificNavigationContext = () => useContext(PageSpecificNavigationContext);
