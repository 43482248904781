import React, { FunctionComponent, useEffect, useState } from 'react';
import clsx from 'clsx';
import { ReactComponent as IconArrowDown } from '../../../assets/icons/Arrow-Down-Line-Black-24.svg';
import styles from './index.module.css'; // eslint-disable-line
import { OverlayContainerProps, Spinner, SpinnerSize } from '../index';
import { FeatureProps, invokesFeature, trackFeature } from '../../../utils/features';
import { TestProps } from '../../../TestIds';
import { Key } from '../../../utils/keyCodes';
import { disableBodyScroll, enableBodyScroll } from '../Modal/lib';
import OverlayContainer, { OverlayPosition } from './OverlayContainer';

export enum DropdownStyle {
  BUTTON = 'bordered',
  FLAT = 'flat',
  ICON = 'icon',
  ICON_HOVER = 'iconHover',
  BUTTON_WITH_ARROW = 'buttonWithArrow',
}

export type DropdownProps = {
  children: React.ReactNode;
  className?: string;
  closeOnBlur?: boolean;
  disabled?: boolean;
  disableResponsiveOverlay?: boolean;
  disableScrollOnOpen?: boolean;
  dropdownStyle?: DropdownStyle;
  onToggle?: (isOpen: boolean, event?: React.SyntheticEvent) => void;
  open?: boolean;
  overlay: React.ReactNode;
  overlayPosition?: OverlayPosition;
  RenderOverlayContainer?: FunctionComponent<OverlayContainerProps>;
  loading?: boolean;
  label?: string;
  buttonProps?: React.HTMLAttributes<HTMLButtonElement>;
} & FeatureProps &
  TestProps &
  Pick<OverlayContainerProps, 'closeOnContentMouseDown' | 'closeOnContentClick'>;

export function Dropdown({
  featureId,
  testId,
  children,
  className,
  closeOnBlur,
  disabled = false,
  disableResponsiveOverlay = false,
  disableScrollOnOpen = false,
  dropdownStyle = DropdownStyle.BUTTON,
  onToggle,
  open = false,
  overlay,
  overlayPosition = OverlayPosition.Left,
  RenderOverlayContainer = OverlayContainer,
  loading = false,
  buttonProps,
  label,
  closeOnContentMouseDown = false,
  closeOnContentClick = true,
}: DropdownProps) {
  const [showOverlay, setShowOverlay] = useState(false);
  useEffect(() => setShowOverlay(open), [open]);

  const toggle = (targetState?: boolean, event?: React.SyntheticEvent) => {
    const visible = targetState === undefined ? !showOverlay : targetState;
    setShowOverlay(visible);
    onToggle && onToggle(visible, event);
  };

  useEffect(() => {
    if (showOverlay && disableScrollOnOpen) {
      disableBodyScroll();

      return function cleanup() {
        enableBodyScroll(0);
      };
    }
  }, [showOverlay, disableScrollOnOpen]);

  useEffect(() => {
    disabled && setShowOverlay(false);
  }, [disabled]);

  const { onKeyDown, ...buttonOtherProps } = buttonProps || {};

  return (
    <div className={clsx(styles.dropdown, className)}>
      <button
        className={clsx(styles.button, styles[dropdownStyle], { [styles.open]: showOverlay })}
        onClick={
          disabled
            ? undefined
            : e => {
                featureId && trackFeature(featureId);
                toggle(undefined, e);
              }
        }
        onKeyDown={e => {
          if (!showOverlay && e.key === Key.Escape) {
            toggle(false, e);
          }
          if (showOverlay) {
            // prevent calling onSelect or navigating if the overlay isn't visible
            onKeyDown?.(e);
          }
        }}
        onBlur={
          closeOnBlur
            ? e => {
                toggle(false, e);
              }
            : undefined
        }
        type="button"
        {...invokesFeature(featureId)}
        data-testid={testId}
        aria-label={label}
        aria-haspopup="true"
        aria-expanded={showOverlay}
        {...buttonOtherProps}
      >
        {loading && <Spinner size={SpinnerSize.SMALL} className={styles.spinner} center={false} />}
        {children}
        {dropdownStyle === DropdownStyle.FLAT && (
          <IconArrowDown
            className={styles.arrow}
            width="10px"
            height="10px"
            style={{ transform: showOverlay ? 'rotate(180deg)' : 'rotate(0deg)' }}
            aria-hidden
          />
        )}
        {dropdownStyle === DropdownStyle.BUTTON_WITH_ARROW && (
          <IconArrowDown
            className={styles.arrowRight}
            width="10px"
            height="10px"
            style={{
              transform: showOverlay ? 'rotate(180deg)' : 'rotate(0deg)',
              display: 'inline',
            }}
            aria-hidden
          />
        )}
      </button>
      <div>
        {showOverlay && (
          <RenderOverlayContainer
            onClose={() => toggle(false)}
            disableResponsive={disableResponsiveOverlay}
            overlayPosition={overlayPosition}
            closeOnContentMouseDown={closeOnContentMouseDown}
            closeOnContentClick={closeOnContentClick}
          >
            {overlay}
          </RenderOverlayContainer>
        )}
      </div>
    </div>
  );
}

export { default as OverlayContainer } from './OverlayContainer';
export * from './OverlayContainer';
