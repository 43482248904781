import React from 'react';
import { EntitySearchSelectProps } from '../EntitySearchSelect';
import Label from '../Label';
import { SearchSelectUserEntity } from './index';
import style from './SearchResultItem.module.css';

const SearchResultItem: EntitySearchSelectProps<SearchSelectUserEntity>['RenderEntityItem'] = ({
  item,
}) => {
  return (
    <div className={style.container}>
      <div> {item.displayName || item.email}</div>
      {item.isNewUser && <Label text="new user" />}
    </div>
  );
};

export default SearchResultItem;
