import React from 'react';
import Title from '../../../components/Title';
import style from './ReportTitle.module.css';

type ReportTitleProps = {
  icon?: React.ReactNode;
  headerText?: string;
  displayItemCount?: boolean;
  itemCount: number;
  id?: string;
};

const ReportTitle: React.FC<ReportTitleProps> = ({
  icon,
  headerText,
  displayItemCount = true,
  itemCount,
  id,
}) => {
  return (
    <Title id={id} size="M">
      <span className={style.header}>
        <span className={style.icon} aria-hidden>
          {icon}
        </span>
        {headerText}
        {displayItemCount && (
          <span className={style.itemCount} aria-hidden>
            {itemCount}
          </span>
        )}
      </span>
    </Title>
  );
};

export default ReportTitle;
