import React, { useCallback, useMemo } from 'react';
import EntitySearchSelect, { EntitySearchSelectProps } from '../EntitySearchSelect';
import UserItem, { UserItemDetails } from '../EntityList/entityItems/UserItem';
import ActionableListItem from '../../../ui/lists/listOfItems/ActionableListItem';
import ListItemFocusHighlight from '../../../ui/lists/listOfItems/listItems/ListItemFocusHighlight';
import { isValidEmail } from '../../../ui/form/validation';
import SearchResultItem from './SearchResultItem';

export type SearchSelectUserEntity = UserItemDetails & { isNewUser?: boolean };
const RenderSearchResultItem = ActionableListItem(SearchResultItem, ListItemFocusHighlight);

const MaxSearchResults = 12;

export type UserSearchSelectProps = {
  users: SearchSelectUserEntity[];
  maxSearchResults?: number;
  allowNewUsers?: boolean;
} & Pick<
  EntitySearchSelectProps<SearchSelectUserEntity>,
  | 'label'
  | 'selection'
  | 'onChange'
  | 'autoFocus'
  | 'aria-labelledby'
  | 'aria-label'
  | 'initialQuery'
  | 'disabled'
  | 'title'
  | 'RenderEmptySelection'
  | 'placeholder'
  | 'aria-describedby'
  | 'hasError'
>;

const userEntityEquals = (u1: SearchSelectUserEntity, u2: SearchSelectUserEntity) =>
  u1.email === u2.email;

const UserSearchSelect: React.FC<UserSearchSelectProps> = ({
  users,
  maxSearchResults = MaxSearchResults,
  allowNewUsers = true,
  ...props
}) => {
  const selectedUserEmails = useMemo(
    () => new Set(props.selection.map(u => u.email)),
    [props.selection],
  );
  const availableUsers = useMemo(() => {
    return users.filter(u => !selectedUserEmails.has(u.email));
  }, [users, selectedUserEmails]);
  const sortedUsers = useMemo(
    () =>
      availableUsers.sort((u1, u2) =>
        u1.displayName ? (u2.displayName ? u1.displayName.localeCompare(u2.displayName) : -1) : 1,
      ),
    [availableUsers],
  );
  const getSearchResults = useCallback(
    (query: string) => {
      const q = query.trim().toLowerCase();
      if (!q) {
        return sortedUsers.slice(0, maxSearchResults);
      }

      const res: SearchSelectUserEntity[] = [];
      for (const u of sortedUsers) {
        if (u.displayName?.toLowerCase().includes(q) || u.email.toLowerCase().includes(q)) {
          res.push(u);
        }
        if (res.length >= maxSearchResults) {
          break;
        }
      }

      if (
        allowNewUsers &&
        isValidEmail(query) &&
        !res.some(u => u.email.toLowerCase() === q) &&
        !selectedUserEmails.has(q)
      ) {
        res.push({ email: query, isNewUser: true });
      }

      return res;
    },
    [sortedUsers, selectedUserEmails, maxSearchResults, allowNewUsers],
  );
  return (
    <EntitySearchSelect<SearchSelectUserEntity>
      {...props}
      RenderEntityItem={UserItem}
      RenderEntitySearchResultItem={RenderSearchResultItem}
      getSearchResults={getSearchResults}
      itemEquals={userEntityEquals}
      toggleOnSpace={false}
    />
  );
};

export default UserSearchSelect;
