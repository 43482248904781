import { ReactNode } from 'react';
import { Maybe } from '@tellurian/ts-utils';
import { IconComponent } from '../lib';
import { TOAST_ANIMATION_DURATION } from './Toast';

/**
 * The states below also reflect the order of the transitions a notification is expected to perform.
 * Exceptionally, state "AnimatingDismiss" can be skipped.
 */
export enum ToastState {
  AnimatingShow,
  Showing,
  AnimatingDismiss,
  Dismissed,
}

export type UnregisterCallback = () => void;

type IconOptions = {
  Icon: Maybe<IconComponent>;
  iconFill: Maybe<string>;
  highlight: Maybe<string>;
  ariaLabel?: string;
  // Use sparingly, prefer the default (18px) unless the chosen icon is too complex
  // to be rendered with these dimensions (e.g. 18x18).
  iconWidth?: number;
};

export type ToastOptions = {
  autoDismissAfterMs: Maybe<number>;
  onShowAnimationComplete: Maybe<() => void>;
  onDismissAnimationComplete: Maybe<() => void>;
  // Fires when user clicks on the dismiss button (not for scheduled dismiss)
  onDismiss: Maybe<() => void>;
  dismissOnPathnameChange: boolean;
  dismissOnHistoryChange: boolean;
  dismissOnLocationChangeImmediate: boolean;
} & IconOptions;

export type ToastDetails = {
  content: ReactNode;
  state: ToastState;
  createdAt: number;
  autoDismissAfterMs: Maybe<number>;
} & ToastCallbacks &
  Partial<IconOptions>;

type ToastCallbacks = {
  onDismiss: () => void;
  onShowAnimationComplete: () => void;
  onDismissAnimationComplete: () => void;
};

export type DismissFn = (immediate?: boolean) => boolean;

export type ShowFnResult = {
  dismiss: DismissFn;
  getState: () => ToastState;
  showAgain: (content?: ReactNode, options?: Partial<ToastOptions>) => boolean;
};

export type ShowFn = (content: ReactNode, options?: Partial<ToastOptions>) => ShowFnResult;

type GetCompletionFns = (
  options?: Partial<ToastOptions>,
) => Pick<ToastDetails, 'onShowAnimationComplete' | 'onDismissAnimationComplete'>;

export const DefaultToastOptions: Readonly<ToastOptions> = Object.freeze({
  Icon: undefined,
  iconFill: undefined,
  highlight: undefined,
  autoDismissAfterMs: 4000, // 4 seconds,
  onShowAnimationComplete: undefined,
  onDismissAnimationComplete: undefined,
  onDismiss: undefined,
  dismissOnPathnameChange: false,
  dismissOnHistoryChange: false,
  dismissOnLocationChangeImmediate: false,
});

const setTimeout = window.setTimeout;
const isAnimating = ({ state }: { state: ToastState }) =>
  state === ToastState.AnimatingShow || state === ToastState.AnimatingDismiss;

type ShowToastFactoryProps = {
  pushToast: (notificationDetails: ToastDetails) => void;
  removeToast: (notificationDetails: ToastDetails) => void;
  refreshToasts: () => void;
  onPathnameChange: (fn: () => void) => UnregisterCallback;
  onHistoryChange: (fn: () => void) => UnregisterCallback;
};

export const showToastFactory =
  ({
    pushToast,
    refreshToasts,
    removeToast,
    onPathnameChange,
    onHistoryChange,
  }: ShowToastFactoryProps) =>
  (content: ReactNode, options?: Partial<ToastOptions>) => {
    let pendingUpdate: Maybe<{
      content: ReactNode;
      options?: Partial<ToastOptions>;
    }> = undefined;
    let dismissTimeout: Maybe<number> = undefined;
    // Prevent memory leaks
    let unregisterOnDismiss: Maybe<() => void> = undefined;
    const clearTimeout = () => {
      if (dismissTimeout) {
        window.clearTimeout(dismissTimeout);
        dismissTimeout = undefined;
      }
    };

    const notification: ToastDetails & ToastOptions = {
      createdAt: +new Date(),
      content,
      ...DefaultToastOptions,
      ...options,
      state: ToastState.AnimatingShow,
      onDismiss: () => {},
      onDismissAnimationComplete: () => {},
      onShowAnimationComplete: () => {},
    };

    const dismiss = (immediate = false) => {
      if (notification.state !== ToastState.Dismissed) {
        if (unregisterOnDismiss) {
          unregisterOnDismiss();
          unregisterOnDismiss = undefined;
        }

        if (immediate) {
          removeToast(notification);
          notification.state = ToastState.Dismissed;
        } else {
          notification.state = ToastState.AnimatingDismiss;
          refreshToasts();
          clearTimeout();
        }

        return true;
      }

      return false;
    };

    const scheduleDismiss = (adjustment = 0) => {
      if (notification.autoDismissAfterMs !== undefined) {
        if (!dismissTimeout) {
          dismissTimeout = setTimeout(
            () => dismiss(),
            adjustment + notification.autoDismissAfterMs + TOAST_ANIMATION_DURATION,
          );
          return true;
        }
      }

      return false;
    };

    const applyPendingUpdate = (getCompletionFns: GetCompletionFns) => {
      if (pendingUpdate) {
        const { content, options } = pendingUpdate;
        notification.content = content;
        Object.assign(notification, options, getCompletionFns(options));
        pendingUpdate = undefined;
        return true;
      }

      return false;
    };

    const getCompletionFns: GetCompletionFns = (options?: Partial<ToastOptions>) => ({
      onDismissAnimationComplete: () => {
        notification.state = ToastState.Dismissed;
        removeToast(notification);
        applyPendingUpdate(getCompletionFns);
        return options?.onDismissAnimationComplete?.();
      },
      onShowAnimationComplete: () => {
        notification.state = ToastState.Showing;
        applyPendingUpdate(getCompletionFns);
        refreshToasts();
        return options?.onShowAnimationComplete?.();
      },
      onDismiss: () => {
        clearTimeout();
        notification.state = ToastState.AnimatingDismiss;
        refreshToasts();
        options?.onDismiss?.();
      },
    });

    Object.assign(notification, getCompletionFns(options));

    const showToast = () => {
      notification.state = ToastState.AnimatingShow;
      pushToast(notification);
      scheduleDismiss();

      if (notification.dismissOnHistoryChange) {
        unregisterOnDismiss = onHistoryChange(() =>
          dismiss(notification.dismissOnLocationChangeImmediate),
        );
      } else if (notification.dismissOnPathnameChange) {
        unregisterOnDismiss = onPathnameChange(() =>
          dismiss(notification.dismissOnLocationChangeImmediate),
        );
      }
    };

    const update = (content: ReactNode, options?: Partial<ToastOptions>) => {
      pendingUpdate = { content, options };
      if (!isAnimating(notification)) {
        applyPendingUpdate(getCompletionFns);
      }
    };

    const showAgain = (nextContent?: ReactNode, nextOptions?: Partial<ToastOptions>) => {
      if (nextContent || nextOptions) {
        update(nextContent || content, nextOptions);
      }
      if (notification.state === ToastState.Showing) {
        // Continue to show animation for another autoDismissAfterMs milliseconds (if defined)
        clearTimeout();
        refreshToasts();
        scheduleDismiss(-TOAST_ANIMATION_DURATION);
        return true;
      }

      if (notification.state !== ToastState.AnimatingShow) {
        dismiss(true);
        showToast();
        return true;
      }

      return false;
    };

    showToast();

    return {
      dismiss,
      showAgain,
      getState: () => notification.state,
    };
  };
