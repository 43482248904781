import { useCallback } from 'react';
import useRecentlyViewedDashboards from '../../../../businessDashboard/hooks/useRecentlyViewedDashboards';
import {
  useDeleteLookerBookmarkMutation,
  useDeleteUnfiInsightsLookerBookmarkMutation,
} from '../../../../../generated/graphql';
import { Feature, trackFeature } from '../../../../../utils/features';
import {
  BookmarkFragmentWithDashboardInfo,
  useBookmarksContext,
} from '../../../../BookmarksContext';
import { useGetDashboardById } from '../../../../businessDashboard/hooks/useGetDashboardGroups';
import { isUnfiInsightsDashboard } from '../../../../lib';

const useDeleteSavedReport = (
  accountId: string,
  onComplete?: () => void,
): [(report: BookmarkFragmentWithDashboardInfo) => void, { loading: boolean }] => {
  const getDashboardById = useGetDashboardById(accountId);

  const { refetch } = useBookmarksContext();
  const { remove: removeRecentlyViewedDashboard } = useRecentlyViewedDashboards(accountId);

  const onCompleted = () => {
    refetch();
    onComplete?.();
  };

  const [deleteBookmark, { loading }] = useDeleteLookerBookmarkMutation({
    onCompleted,
  });

  const [deleteUnfiBookmark, { loading: isDeletingUnfiBookmark }] =
    useDeleteUnfiInsightsLookerBookmarkMutation({ onCompleted });

  return [
    useCallback(
      (report: BookmarkFragmentWithDashboardInfo) => {
        const input = { bookmarkId: report.id };
        const dashboard = getDashboardById(report);

        if (isUnfiInsightsDashboard(dashboard)) {
          deleteUnfiBookmark({ variables: { input } });
        } else {
          deleteBookmark({
            variables: {
              accountId: accountId,
              input,
            },
          });
        }

        removeRecentlyViewedDashboard({
          dashboardGroupId: report.dashboardGroupId,
          dashboardId: report.dashboardId,
          bookmarkId: report.id,
        });
        trackFeature(Feature.BusinessDashboardDeleteSavedReport);
      },
      [
        deleteBookmark,
        deleteUnfiBookmark,
        accountId,
        removeRecentlyViewedDashboard,
        getDashboardById,
      ],
    ),
    { loading: loading || isDeletingUnfiBookmark },
  ];
};

export default useDeleteSavedReport;
