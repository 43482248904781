import { useMediaQuery } from 'react-responsive';

export function useIsMaxXSmallScreen(props?) {
  return useMediaQuery(
    {
      maxWidth: 479,
    },
    props,
  );
}

export function useIsMaxSmallScreen(props?) {
  return useMediaQuery(
    {
      maxWidth: 767,
    },
    props,
  );
}

export function useIsMaxMediumScreen(props?) {
  return useMediaQuery(
    {
      maxWidth: 991,
    },
    props,
  );
}

export function useIsLargeScreen(props?) {
  return useMediaQuery(
    {
      minWidth: 992,
    },
    props,
  );
}

export function useIsMaxLargeScren(props?) {
  return useMediaQuery(
    {
      maxWidth: 1299,
    },
    props,
  );
}

export function useIsExtraLargeScreen(props?) {
  return useMediaQuery(
    {
      minWidth: 1300,
    },
    props,
  );
}

export function useResponsive(props?) {
  return {
    isMaxXSmallScreen: useIsMaxXSmallScreen(props),
    isMaxSmallScreen: useIsMaxSmallScreen(props),
    isMaxMediumScreen: useIsMaxMediumScreen(props),
    isLargeScreen: useIsLargeScreen(props),
    isMaxLargeScreen: useIsMaxLargeScren(props),
    isExtraLargeScreen: useIsExtraLargeScreen(props),
  };
}
