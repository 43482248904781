import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { FCC } from '../../../../utils/types';
import flattenChildren from '../../utils/flattenChildren';
import style from './ListOfChips.module.css';

type ListOfChipsProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLUListElement>,
  HTMLUListElement
>;

const ListOfChips: FCC<ListOfChipsProps> = ({ children, className }) => {
  const flatChildren = flattenChildren(children);
  // Prevent key duplicates
  const shouldUseIndexAsKey =
    Array.isArray(children) &&
    (children.length !== flatChildren.length || children.some((c, i) => c !== flatChildren[i]));
  return (
    <ul className={clsx(style.list, className)}>
      {Array.isArray(children) ? (
        flatChildren.map((child, index) => {
          return (
            <li key={(!shouldUseIndexAsKey && (child as ReactElement).key) || `chip-${index}`}>
              {child}
            </li>
          );
        })
      ) : (
        <li>{children}</li>
      )}
    </ul>
  );
};

export default ListOfChips;
