import React from 'react';
import Title from '../../../components/Title';
import { FormElement } from '../../../../ui/form/FormFields';
import { BookmarkScope } from '../../../../../generated/graphql';
import ChipDropdownOptionSelect from '../../../components/ChipDropdown/ChipDropdownOptionSelect';
import { DropdownListOptions } from '../../../components/lib/dropdownListOption';

type ReportAccessSelectorProps = {
  accessOptions: DropdownListOptions;
};

const ReportAccessSelector: React.FC<ReportAccessSelectorProps> = ({ accessOptions }) => {
  return (
    <div className="mod">
      <Title size="M" className="mhn mvm">
        Access
      </Title>
      <FormElement<BookmarkScope>
        name="scope"
        render={({ value, onChange }) => (
          <ChipDropdownOptionSelect
            selectedValue={value}
            options={accessOptions}
            onChange={onChange}
            getChipText={selectedItem => selectedItem?.name ?? 'Select'}
          />
        )}
      />
    </div>
  );
};

export default ReportAccessSelector;
