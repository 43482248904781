import React from 'react';
import Headline from '../Headline';
import Text from '../../../ui/Text';

const Forbidden: React.FC = () => {
  return (
    <>
      <Headline>
        <Text subtle className="mrs">
          403
        </Text>{' '}
        Forbidden
      </Headline>
      <p>You don&apos;t have permissions to view this page.</p>
    </>
  );
};

export default Forbidden;
