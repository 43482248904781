import React from 'react';
import Headline from '../Headline';
import Text from '../../../ui/Text';

const NotFound: React.FC = ({}) => {
  return (
    <>
      <Headline>
        <Text subtle className="mrs">
          404
        </Text>{' '}
        Not found
      </Headline>
      <p>Oops, you have requested something that does not exist.</p>
    </>
  );
};

export default NotFound;
