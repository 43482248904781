import React from 'react';
import { Maybe } from '@tellurian/ts-utils';
import style from './InterstitialItem.module.css';

export type RenderInterstitialItemProps<T> = {
  value: T;
  index: number;
};

type InterstitialItemProps<T> = {
  value: Maybe<T>;
  index: number;
  RenderInterstitialItem: React.FC<RenderInterstitialItemProps<T>>;
};

function InterstitialItem<T>({ value, index, RenderInterstitialItem }: InterstitialItemProps<T>) {
  return value ? (
    // Temporary (onMouseDown) solution, until a generic mechanism to stop the search field from losing focus is implemented
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <li role="separator" onMouseDown={ev => ev.preventDefault()} className={style.item}>
      <RenderInterstitialItem value={value} index={index} />
    </li>
  ) : null;
}

export default InterstitialItem;
