import React from 'react';
import { BookmarkFragmentWithDashboardInfo } from '../../../../BookmarksContext';
import { inProgressLabel } from '../../../../lib';
import Modal from '../../../components/Modal';
import ActionButtons from '../../../components/ActionButtons';
import RenderReportMetadata from './RenderReportMetadata';
import ReportDetails from './ReportDetails';
import useDeleteSavedReport from './useDeleteSavedReport';
import ReportAccessDetails, { isReportPrivate } from './ReportAccessDetails';

type DeleteReportModalProps = {
  onClose: () => void;
  report: BookmarkFragmentWithDashboardInfo;
  accountId: string;
};

const DeleteReportModal: React.FC<DeleteReportModalProps> = ({ accountId, onClose, report }) => {
  const [deleteReport, { loading }] = useDeleteSavedReport(accountId, onClose);
  return (
    <Modal
      title="Delete report"
      onCloseClick={onClose}
      size="S"
      footer={
        <ActionButtons
          onPrimary={() => deleteReport(report)}
          primaryLabel={loading ? inProgressLabel('Deleting') : 'Delete'}
          onSecondary={onClose}
          disabled={{ primary: loading }}
        />
      }
    >
      <div className="mvm">
        <p>
          Are you sure you want to delete {!isReportPrivate(report) && 'the shared report'}{' '}
          <span className="em">{report.description}</span>?
        </p>
        <RenderReportMetadata report={report} />
        <ReportAccessDetails report={report} />
        <ReportDetails report={report} maxItemsVisible={5} />
      </div>
    </Modal>
  );
};

export default DeleteReportModal;
