import { useCallback } from 'react';
import { useCookies } from 'react-cookie';

const useAccessToken = (cookieKey: string) => {
  const [cookies, setCookie, removeCookie] = useCookies([cookieKey]);

  const setAccessToken = useCallback(
    (token: string) => {
      setCookie(cookieKey, token, { path: '/', sameSite: 'none', secure: true });
    },
    [setCookie, cookieKey],
  );

  const clearAccessToken = useCallback(() => {
    removeCookie(cookieKey);
  }, [removeCookie, cookieKey]);

  return {
    accessToken: cookies[cookieKey],
    setAccessToken,
    clearAccessToken,
  };
};

export default useAccessToken;
