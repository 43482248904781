import { createStore, UseStore } from 'idb-keyval';
import { Maybe } from '@tellurian/ts-utils';

export const getStoreFactory = (name: string) => {
  let store: Maybe<UseStore> = undefined;
  return (): UseStore => {
    if (!store) {
      store = createStore(name, name[0].toLowerCase() + name.slice(1));
    }

    return store;
  };
};

const StoreKeys = [
  'GeneralSettings',
  'Main',
  'ModelDocumentation',
  'RaRecentReports',
  'ApiRequestCache',
  'NewFeatureTemp',
] as const;
type StoreKey = (typeof StoreKeys)[number];

type StoreRegistry = Record<StoreKey, UseStore>;

const storeReg = StoreKeys.reduce((acc, key) => {
  acc[key] = getStoreFactory(key);
  return acc;
}, {}) as Record<StoreKey, () => UseStore>;

export const Store = Object.defineProperties(
  {},
  StoreKeys.reduce((acc, key) => {
    acc[key] = {
      get: () => storeReg[key](),
    };
    return acc;
  }, {}),
) as StoreRegistry;
