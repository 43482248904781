import React from 'react';
import { BookmarkFragmentWithDashboardInfo } from '../../../../BookmarksContext';
import { FnsFormat } from '../../../../../utils/dateFnsFormats';
import { useI18nContext } from '../../../../../utils/I18nContext';
import Title from '../../../components/Title';

type RenderReportMetadataProps = {
  report: Pick<BookmarkFragmentWithDashboardInfo, 'owner' | 'createdAtTimestamp' | 'description'>;
  includeDescription?: boolean;
};

const RenderReportMetadata: React.FC<RenderReportMetadataProps> = ({
  report: { owner, createdAtTimestamp, description },
  includeDescription = false,
}) => {
  const formatDate = useI18nContext().dateFormat(FnsFormat.DateTimeShort);
  return (
    <>
      {includeDescription && (
        <Title size="M" className="mvm">
          {description}
        </Title>
      )}
      <p className="mvs">Created on: {formatDate(new Date(createdAtTimestamp))}</p>
      {owner && <p className="mvs">Owner: {owner.email}</p>}
    </>
  );
};

export default RenderReportMetadata;
