import React, { Fragment, useMemo } from 'react';
import RenderMenuItem, { ItemSeparator } from './RenderMenuItem';
import { MenuItemSpec, splitIntoGroups } from './lib';

type MenuProps = {
  'aria-label'?: string;
  items: MenuItemSpec[];
  getItemKey?: (item: MenuItemSpec, index: number, groupIndex: number) => string;
  as?: 'nav' | 'div';
};

const Menu: React.FC<MenuProps> = React.forwardRef<HTMLDivElement, MenuProps>(function Menu(
  {
    items,
    as: Component = 'nav',
    getItemKey = (item, index, groupIndex) => `item-${groupIndex}:${index}`,
    ...rest
  },
  ref,
) {
  const groups = useMemo(() => splitIntoGroups(items), [items]);
  return (
    <Component ref={ref} {...rest}>
      {groups.map((groupItems, groupIndex) => (
        <Fragment key={`group-${groupIndex}`}>
          <ul className="man">
            {groupItems.map((item, index) => (
              <li key={getItemKey(item, index, groupIndex)}>
                <RenderMenuItem item={item} index={index} />
              </li>
            ))}
          </ul>
          {groupIndex < groups.length - 1 ? <ItemSeparator /> : null}
        </Fragment>
      ))}
    </Component>
  );
});

export default Menu;
