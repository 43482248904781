import React from 'react';
import RouteSwitch from '../../components/routing/RouteSwitch';
import { RouteOptions } from '../../components/routing/lib';
import { UnfiEmbeddedRoutes } from './Routes';

// Do not require authentication for UnfiInsightsEmbedded routes. This is not strictly necessary
// but is included for clarity.
const DefaultRouteOptions: RouteOptions = { requireAuth: false };

const AppRouteSwitch: React.FC = () => (
  <RouteSwitch routes={UnfiEmbeddedRoutes} defaultRouteOptions={DefaultRouteOptions} />
);

export default AppRouteSwitch;
