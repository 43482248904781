import React, { ReactNode, useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { noop } from '@tellurian/ts-utils';
import { ShowFn, showToastFactory, ToastDetails, ToastState } from './lib';

type ToastContextInterface = {
  show: ShowFn;
  notifications: ToastDetails[];
  panelHasReducedOffset: boolean;
  setPanelHasReducedOffset: (panelHasReducedOffset: boolean) => void;
};

export const ToastContext = React.createContext<ToastContextInterface>({
  show: () => ({
    dismiss: () => false,
    getState: () => ToastState.AnimatingShow,
    showAgain: () => false,
    update: () => {},
  }),
  notifications: [],
  panelHasReducedOffset: false,
  setPanelHasReducedOffset: noop,
});

export function ToastContextProvider({ children }: { children: ReactNode }) {
  const [notifications, setToasts] = useState<ToastDetails[]>([]);
  const history = useHistory();

  const pushToast = (notificationDetails: ToastDetails) => {
    setToasts(currentToasts => [notificationDetails, ...currentToasts]);
  };

  const removeToast = (notificationDetails: ToastDetails) => {
    setToasts(currentToasts => currentToasts.filter(n => n !== notificationDetails));
  };

  const refreshToasts = () => setToasts(currentToasts => [...currentToasts]);

  const onPathnameChange = (fn: () => void) =>
    history.listen(({ pathname }) => {
      if (history.location.pathname !== pathname) {
        fn();
      }
    });

  const onHistoryChange = (fn: () => void) => history.listen(() => fn());

  const show: ShowFn = useRef(
    showToastFactory({
      pushToast,
      removeToast,
      refreshToasts,
      onHistoryChange,
      onPathnameChange,
    }),
  ).current;

  const [panelHasReducedOffset, setPanelHasReducedOffset] = useState(false);

  return (
    <ToastContext.Provider
      value={{
        show,
        notifications,
        panelHasReducedOffset,
        setPanelHasReducedOffset,
      }}
    >
      {children}
    </ToastContext.Provider>
  );
}

export const useToastContext = () => useContext(ToastContext);
