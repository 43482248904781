import React, { useEffect } from 'react';
import firebase from 'firebase/compat/app';
import Flex from '../../components/Flex';
import Spinner from '../../components/Spinner';
import PageContainer from '../../../../pageTemplates/PageContainer';
import ThemeProvider from '../../components/Theme/ThemeProvider';
import { Theme } from '../../components/Theme';
import { usePartnerAuthContext } from './PartnerAuthContext';

const Logout = () => {
  const { authProvider, firebaseUser } = usePartnerAuthContext();

  useEffect(() => {
    if (firebaseUser) {
      firebase
        .auth()
        .signOut()
        .then(() => {
          window.location.replace(authProvider?.logoutRedirectUrl || '/');
        });
    } else {
      window.location.replace(authProvider?.logoutRedirectUrl || '/');
    }
  }, [authProvider?.logoutRedirectUrl, firebaseUser]);

  return (
    <ThemeProvider theme={Theme.Dot}>
      <PageContainer>
        <Flex spaceBetween column>
          <Spinner />
          <p>Logging out...</p>
        </Flex>
      </PageContainer>
    </ThemeProvider>
  );
};

export default Logout;
