import React, { useId } from 'react';
import clsx from 'clsx';
import composeRefs from '../../utils/composeRefs';
import useInitialFocus from '../../../ui/form/useInitialFocus';
import FieldDetails, { FieldDetailsProps } from '../lib/FieldDetails';
import SelectOptionDropdown, {
  SelectOptionDropdownProps,
} from '../lib/SelectDropdown/SelectOptionDropdown';
import style from './index.module.css';

export type SelectFieldProps = Omit<FieldDetailsProps, 'id'> &
  Omit<SelectOptionDropdownProps, 'hasError' | 'selectedValue'> & {
    isFocused?: boolean;
    scrollIntoViewIfFocused?: boolean;
    value?: string;
  };

const SelectField = React.forwardRef<HTMLInputElement, SelectFieldProps>(function SelectField(
  {
    isFocused,
    scrollIntoViewIfFocused,
    description,
    error,
    className,
    value,
    ...selectProps
  }: SelectFieldProps,
  ref,
) {
  const hasError = !!error;
  const composedRef = composeRefs(
    ref,
    useInitialFocus<HTMLInputElement>({ isFocused, scrollIntoViewIfFocused }),
  );
  const supportingTextId = useId();

  return (
    <div className={clsx(style.field, className)}>
      <SelectOptionDropdown
        {...selectProps}
        selectedValue={value}
        hasError={hasError}
        aria-describedby={supportingTextId}
        ref={composedRef}
      />
      <FieldDetails error={error} description={description} id={supportingTextId} />
    </div>
  );
});

export default SelectField;
