import React, { ReactNode, useContext, useMemo } from 'react';

type ProviderProps = {
  children?: ReactNode;
  maxItemsPerRow?: number;
};

type ContextProps = {
  itemWidth: string | undefined;
};

const HorizontalListContext = React.createContext<ContextProps>({
  itemWidth: undefined,
});

export function HorizontalListProvider({ children, maxItemsPerRow }: ProviderProps) {
  const itemWidth = useMemo(
    () => (maxItemsPerRow ? 100 / maxItemsPerRow + '%' : undefined),
    [maxItemsPerRow],
  );

  return (
    <HorizontalListContext.Provider value={{ itemWidth }}>
      {children}
    </HorizontalListContext.Provider>
  );
}

export const useHorizontalList = () => useContext(HorizontalListContext);
