import { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/remote-config';
import { isProduction } from '../lib';

const resolveAuthDomain = () => {
  if (window.location.hostname === process.env.REACT_APP_UNFI_INSIGHTS_EMBEDDED_HOSTNAME)
    return process.env.REACT_APP_UNFI_INSIGHTS_EMBEDDED_HOSTNAME;
  return 'auth.gocrisp.com';
};

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: resolveAuthDomain(),
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_CORE_UI_APP_ID,
};

firebase.initializeApp(firebaseConfig);

const remoteConfig = firebase.remoteConfig();
remoteConfig.settings = {
  fetchTimeoutMillis: 1000,
  minimumFetchIntervalMillis: 6000,
};
remoteConfig.defaultConfig = {
  showGlobalSiteGuide: false,
};

export function useRemoteConfig(): { showGlobalSiteGuide: boolean; globalSiteGuideName: string } {
  const [showGlobalSiteGuide, setShowGlobalSiteGuide] = useState<boolean>(false);
  const [globalSiteGuideName, setGlobalSiteGuideName] = useState<string>('');

  useEffect(() => {
    if (isProduction()) {
      remoteConfig
        .fetchAndActivate()
        .then(() => {
          setShowGlobalSiteGuide(remoteConfig.getBoolean('showGlobalSiteGuide'));
          setGlobalSiteGuideName(remoteConfig.getString('globalSiteGuide'));
        })
        .catch(() => setShowGlobalSiteGuide(false));
    }
  });

  return { showGlobalSiteGuide, globalSiteGuideName };
}
