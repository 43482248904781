import React from 'react';
import RemaConnectAdmin from '../components/powerbiConnect/remaConnect/RemaConnectAdmin';
import RemaAdminTemplate from '../components/lettuce/crisp/templates/RemaAdminTemplate';
import { lazyWithRetry } from '../components/lib';

const RenderRemaConnectAdmin = lazyWithRetry<typeof RemaConnectAdmin>(
  () =>
    import(
      /* webpackChunkName: "remaConnectAdmin" */ '../components/powerbiConnect/remaConnect/RemaConnectAdmin'
    ),
);

const RemaConnectAdminPage = () => {
  return (
    <RemaAdminTemplate>
      <RenderRemaConnectAdmin />
    </RemaAdminTemplate>
  );
};

export default RemaConnectAdminPage;
