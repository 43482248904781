import React, { useCallback } from 'react';
import clipboard from '../../components/lib/Clipboard';
import { useSuccess } from '../../../Toast/Toasts';

const useOnCopyEmail = () => {
  const { show } = useSuccess();
  return useCallback(
    async (email: string) => {
      await clipboard.writeText(email);
      show(
        <span>
          Copied <strong>{email}</strong>!
        </span>,
        { autoDismissAfterMs: 2000 },
      );
    },
    [show],
  );
};

export default useOnCopyEmail;
