/**
 * Global list of features to make it easier to keep track of what we're tracking.
 */
import { AnyObject } from '@tellurian/ts-utils';
import CrispApp from '../components/lettuce/crisp/CrispApp';

export enum Feature {
  UserMenuAvatar = 'Feat.: user-menu|avatar',
  UserMenuAccountSettings = 'Feat.: user-menu|account-settings',

  BusinessDashboardSelectDashboardGroup = 'Feat.: business-dashboard|select-dashboard-group',
  BusinessDashboardSelectDashboard = 'Feat.: business-dashboard|select-dashboard',
  BusinessDashboardSelectBookmark = 'Feat.: business-dashboard|select-bookmark',
  BusinessDashboardOpenSaveBookmark = 'Feat.: business-dashboard|open-save-bookmark',
  BusinessDashboardSubmitSaveBookmark = 'Feat.: business-dashboard|submit-save-bookmark',
  BusinessDashboardClearFilters = 'Feat.: business-dashboard|clear-filters',
  BusinessDashboardMegaMenu = 'Feat.: business-dashboard|mega-menu',
  BusinessDashboardMegaMenuDashboard = 'Feat.: business-dashboard|mega-menu-dashboard',
  BusinessDashboardOpenMyReports = 'Feat.: business-dashboard|open-my-reports',
  BusinessDashboardCloseMyReports = 'Feat.: business-dashboard|close-my-reports',
  BusinessDashboardApplySavedReport = 'Feat.: business-dashboard|apply-saved-report',
  BusinessDashboardEditSavedReport = 'Feat.: business-dashboard|edit-report',
  BusinessDashboardDeleteSavedReport = 'Feat.: business-dashboard|delete-report',
  BusinessDashboardOpenSmallReportsMenu = 'Feat.: business-dashboard|reports-menu',
  BusinessDashboardOpenScheduleReport = 'Feat.: business-dashboard|schedule-report',

  QuickReportsOpenModal = 'Quick Reports - Open modal',
  QuickReportsCloseModal = 'Quick Reports - Close modal',
  QuickReportsCatalogClick = 'Quick Reports - Catalog click',
  QuickReportsDashboardSelections = 'Quick Reports - Dashboard selections',
  QuickReportsRunReport = 'Quick Reports - Run',
  QuickReportsBackClick = 'Quick Reports - Back click',

  PowerBiSelectDashboard = 'Feat.: powerbi|select-dashboard',
  PowerBiSelectBookmark = 'Feat.: powerbi|select-bookmark',
  PowerBiOpenSaveBookmark = 'Feat.: powerbi|open-save-bookmark',
  PowerBiSubmitSaveBookmark = 'Feat.: powerbi|submit-save-bookmark',
  PowerBiSelectReportPage = 'Feat.: powerbi|select-report-page',

  NotificationsCreateNotification = 'Feat.: notifications|create-notification',
  NotificationsUpdateNotification = 'Feat.: notifications|update-notification',
  NotificationsCancel = 'Feat.: notifications|cancel',

  NavMenuHomepage = 'Feat.: nav-menu|homepage',
  NavMenuDashboards = 'Feat.: nav-menu|dashboards',
  NavMenuConnectors = 'Feat.: nav-menu|connectors',
  NavMenuInboundConnectors = 'Feat.: nav-menu|inbound-connectors',
  NavMenuOutboundConnectors = 'Feat.: nav-menu|outbound-connectors',
  NavMenuRemaConnect = 'Feat.: nav-menu|rema-connect',
  NavMenuRemaConnectAdmin = 'Feat.: nav-menu|rema-connect-admin',
  NavMenuNotifications = 'Feat.: nav-menu|notifications',
  NavMenuManageUsers = 'Feat.: nav-menu|manage-users',
  NavMenuMDM = 'Feat.: nav-menu|mdm',
  NavMenuKnowledgeBase = 'Feat.: nav-menu|knowledge-base',
  NavMenuModelDocumentation = 'Feat.: nav-menu|model-documentation',
  NavMenuDeductions = 'Feat.: nav-menu|deductions',
  NavMenuSettings = 'Feat.: nav-menu|settings',

  HomepageDashboards = 'Feat.: homepage|business-dashboard',
  HomepageConnectors = 'Feat.: homepage|connectors',
  HomepageInboundConnectors = 'Feat.: homepage|inbound-connectors',
  HomepageOutboundConnectors = 'Feat.: homepage|outbound-connectors',
  HomepageNotifications = 'Feat.: homepage|notifications',
  HomepageUsers = 'Feat.: homepage|manage-users',
  HomepageMDM = 'Feat.: homepage|mdm',
  HomepageKnowledgeBase = 'Feat.: homepage|knowledge-base',
  HomepageDataModels = 'Feat.: homepage|data-models',
  HomepageSupport = 'Feat.: homepage|support',
  HomepageDeductions = 'Feat.: homepage|deductions',
  HomepageQuickReports = 'Feat.: homepage|quick-reports',

  Academy = 'academy',

  // AdminMenu
  AdminMenuOpenSalesforceAccount = 'admin-menu|navigate-to-salesforce-account',

  // DocsFeat.:
  DocsTableMetadata = 'Feat.: docs|table-metadata',
  DocsModules = 'Feat.: docs|modules',
  DocsSelectModuleCard = 'Feat.: docs|select-module-card',
  DocsSelectTable = 'Feat.: docs|select-table',

  InsightsDiscoverySurveySubmitted = 'Feat.: insights|discovery-survey-submitted',

  DeductionsCategorizationSubmit = 'Feat.: deductions|categorization-submit',
  DeductionsDisputeSubmit = 'Feat.: deductions|dispute-submit',
  DeductionsDocumentDownload = 'Feat.: deductions|document-download',
  DeductionsDataDownload = 'Feat.: deductions|data-download',
  DeductionsDisputeFile = 'Feat.: deductions|dispute-file',

  MDMSpreadsheetLinkError = 'Error creating MDM google spreadsheet link',

  ConnectorDeleted = 'Connector deleted',
  ConnectorVerificationRetry = 'Verification retry triggered',
  ConnectorPrioritySetToImportant = 'Connector priority set to Important',
  ConnectorPrioritySetToNormal = 'Connector priority set to Normal',
  ConnectorDisabled = 'Connector disabled',

  ConnectorActionRequestAccess = 'connector-action|request-access',
  ConnectorActionContactCrisp = 'connector-action|contact-crisp',

  DashboardTierActivated = 'Dashboard tier activated',
  DashboardTierDeactivated = 'Dashboard tier deactivated',
  DashboardsEnabled = 'Dashboards enabled',
  DashboardsDisabled = 'Dashboards disabled',

  InboundDataStatusDetailsModal = 'Inbound data status viewed details modal',
  InboundDataStatusTaskRerunDownload = 'Inbound data status - task rerun download',
  InboundDataStatusTaskRerunParse = 'Inbound data status - task rerun parse',
  InboundDataStatusLinkAllConnectorStatus = 'Inbound data status clicked link to all connectors status',
  InboundDataStatusLinkEditConfigurations = 'Inbound data status clicked link to edit configuration',
  InboundDataStatusRerunFailedTasks = 'Inbound data status - rerun failed tasks',
  InboundDataStatusRerunAllTasks = 'Inbound data status - rerun all tasks',
  InboundDataStatusTimePeriodFilterChange = 'Inbound data status time period filter changed',
  InboundDataStatusLinkClick = 'Inbound data status link clicked',
  InboundDataStatusDetailsLinkClick = 'Inbound data status clicked details link',
  InboundDataStatusEditConfigurationLinkClick = 'Inbound data status clicked edit configuration link',
  InboundDataStatusRowExpanded = 'Inbound data status row expanded',
  InboundDataStatusRowCollapsed = 'Inbound data status row collapsed',

  UserInvited = 'Invited user',
  FeatureFlagError = 'Feature flag error',
  AccountAccess = 'Account accessed',

  LocalStorageMigration = 'Local storage migration',

  DataCatalogFilterConfigurationChanged = 'data-catalog|filter-configuration-changed',
  DataCatalogFiltersCleared = 'data-catalog|filters-cleared',
  DataCatalogSourceExpanded = 'data-catalog|source-expanded',
  DataCatalogSourceCollapsed = 'data-catalog|source-collapsed',
  DataCatalogSeeAllTablesForSource = 'data-catalog|see-all-tables-for-source',
  DataCatalogSeeFewerTablesForSource = 'data-catalog|see-fewer-tables-for-source',
  DataCatalogTableSelected = 'data-catalog|table-selected',

  TermsOfServiceVersionAcknowledged = 'terms-of-service|version-acknowledged',
  TermsOfServiceVersionAccepted = 'terms-of-service|version-accepted',
  TermsOfServicePageAccessed = 'terms-of-service|page-accessed',

  AdminConsoleTriggered = 'admin-console|triggered',
  AdminConsoleAccountSwitched = 'admin-console|account-switched',

  ThemeSwitched = 'theme-switched',

  AnalyticsMenuViewChanged = 'analytics-menu|view-changed',
  AnalyticsMenuItemClicked = 'analytics-menu|item-clicked',

  ReportResetFiltersTriggered = 'report|reset-filters-triggered',
  ReportOptionModalOpened = 'report|option-modal-opened',

  /** UNFI Insights specific features */
  NavMenuCrispPlatform = 'Feat.: nav-menu|crisp-platform',
  HomepagePopularDashboard = 'Feat.: homepage|popular-dashboard',
  HomepageRecentlyViewedDashboard = 'Feat.: homepage|recently-viewed-dashboard',
  HomepageRecentlyViewedPayment = 'Feat.: homepage|recently-viewed-payment',
  /** end UNFI Insights specific features*/

  /** Pipeline Observability */
  TimelineRefreshClicked = 'timeline|refresh-clicked',
  TimelineScopeExpanded = 'timeline|scope-expanded',
  TimelineScopeCollapsed = 'timeline|scope-collapsed',
  TimelineTimeIntervalChanged = 'timeline|time-interval-changed',
  TimelineTimeRangeChanged = 'timeline|time-range-changed',
  TimelineSegmentClicked = 'timeline|segment-clicked',
  LinkToTimelineClicked = 'timeline|link-to-timeline-clicked',

  MdmPlusPlus = 'nav-menu|mdm-plus-plus',
  MdmPlusPlusUpload = 'mdm-plus-plus|upload-file',
  MdmPlusPlusValidateBegin = 'mdm-plus-plus|validate-file',
  MdmPlusPlusValidateComplete = 'mdm-plus-plus|validate-file-complete',
  MdmPlusPlusCommitBegin = 'mdm-plus-plus|commit-file',
  MdmPlusPlusCommitComplete = 'mdm-plus-plus|commit-file-complete',
  MdmPlusPlusCancel = 'mdm-plus-plus|cancel-file',
  MdmPlusPlusDownload = 'mdm-plus-plus|download-file',
  MdmPlusPlusGuideOpen = 'mdm-plus-plus|guide-open',
  MdmPlusPlusGuideClosed = 'mdm-plus-plus|guide-closed',
  MdmPlusPlusChangeLogDownload = 'mdm-plus-plus|change-log-download',
  MdmPlusPlusChangeLogMore = 'mdm-plus-plus|change-log-page',
  MdmPlusPlusTabChange = 'mdm-plus-plus|tab-change',

  /** Retail Analytics **/
  RetailAnalyticsUserAuthenticationFailed = 'retail-analytics|user-authentication-failed',
  RetailAnalyticsUserAuthenticationRetryCancelled = 'retail-analytics|user-authentication-retry-cancelled',
  RetailAnalyticsStaggeredGroupLoadSuccess = 'retail-analytics|staggered-group-load-success',
  RetailAnalyticsStaggeredGroupLoadFailure = 'retail-analytics|staggered-group-load-failure',
  RetailAnalyticsGroupChanged = 'retail-analytics|group-changed',
  RetailAnalyticsReportChanged = 'retail-analytics|report-changed',
  RetailAnalyticsPageChanged = 'retail-analytics|page-changed',
  RetailAnalyticsBookmarkChanged = 'retail-analytics|bookmark-changed',
  RetailAnalyticsSelectionChanged = 'retail-analytics|selection-changed',
  RetailAnalyticsDataDictionaryOpened = 'retail-analytics|data-dictionary-opened',
  RetailAnalyticsDataDictionaryEntrySelected = 'retail-analytics|data-dictionary-entry-selected',
  RetailAnalyticsDataDictionarySearchCompleted = 'retail-analytics|data-dictionary-search-completed',

  UnfiInsightsAccountAlreadyExists = 'unfi-insights|account-already-exists',
  UnfiInsightsAccountRequestSubmitted = 'unfi-insights|account-request-submitted',
  UnfiInsightsBookDemoClicked = 'unfi-insights|book-demo-clicked',
  UnfiInsightsAccountLinkClicked = 'unfi-insights|account-link-clicked',
  UnfiInsightsOnPlatformLandingPage = 'unfi-insights|on-platform-landing-page',

  /* Partner */
  PartnerAccountAccess = 'partner-account-access',
}

const hasAnalytics = window['analytics'] !== undefined;
const isEventConsoleLogEnabled = process.env.REACT_APP_DISABLE_EVENT_LOG !== 'true';

/**
 * Do not export this function to use it directly for feature tracking - use `trackFeature` instead.
 * @param eventName
 * @param attributes
 */
const trackEvent = (eventName: string, attributes?: AnyObject) => {
  // Add the accountId as groupId to all track calls as per
  // https://segment.com/docs/connections/destinations/catalog/pendo/#track
  const accountId = CrispApp.getAccountId();
  const withGroupId = accountId
    ? {
        groupId: accountId,
        ...attributes,
      }
    : attributes;

  const isUnfiInsights = window.location.pathname.startsWith('/unfi-insights-embedded');
  if (isUnfiInsights) {
    // Within UNFI Insights, we call Pendo directly for track events,
    // due to the fact that they are not flowing through from Segment.
    window['pendo']?.track(eventName, withGroupId);
  } else if (hasAnalytics) {
    analytics.track(eventName, withGroupId);
  } else if (isEventConsoleLogEnabled) {
    console.log('Event: ' + eventName, withGroupId);
  }
  window['crispApp.touchAccount']?.(accountId);
};

export const trackGroup = (groupId: string, attributes?: AnyObject) => {
  if (hasAnalytics) {
    analytics.group(groupId, attributes);
  } else if (isEventConsoleLogEnabled) {
    console.log('Group: ' + groupId, attributes);
  }
};

/**
 * Composite components that have `FeatureProps` should make sure the 'active' component (e.g a button or link)
 * adds the props from `invokesFeature`. In the future, we might also have a `containsFeature` function to indicate
 * which container e.g an on-boarding guide arrow should be pointing at.
 */
export type FeatureProps = {
  featureId?: Feature;
};

/**
 * Returns the attributes to indicate that a feature (from an analytics perspective) is invoked
 * by interacting with this element. This is used for Pendo instrumentation and testing. The reason for wrapping
 * this in a function is to give us some future flexibility in how we want to express features in the DOM.
 *
 * Example use case `<button {...invokesFeature("sign-up")} onClick=.../>`
 *
 * @param feature a unique feature id
 */
export const invokesFeature = (feature?: Feature) =>
  feature ? { 'data-feature-id': feature } : {};
/**
 * Track / trace the feature when it's actually used. This is redundant to Pendo's DOM based implementation,
 * but allows us to track features in other systems, such as Google Analytics.
 *
 * @param feature a unique feature id
 * @param attributes
 */
export const trackFeature = (feature: Feature, attributes?: AnyObject) =>
  trackEvent(feature, attributes);
