import React, { useMemo } from 'react';
import { formatRelative, parseISO } from 'date-fns';
import { useGetUserInvitationAcceptUrlQuery, UserInvitation } from '../../../../generated/graphql';
import { Spinner } from '../../../ui';
import { TextFieldWithCopyButton } from '../../../ui/form/Fields';
import { capitalizeFirst } from '../../../../utils/capitalizeFirst';
import Modal from '../../components/Modal';
import ActionButtons from '../../components/ActionButtons';
import style from './InvitationAcceptUrl.module.css';

const adjustAcceptUrl = (url: string) => {
  // The BE does not know whether the FE is run in the dev cluster or on a local machine in dev mode.
  if (window.location.href.startsWith('http://localhost:3000')) {
    return url.replace('core-ui.motiion.local', 'localhost:3000');
  }

  return url;
};

export type InvitationAcceptUrlModalProps = {
  onCloseClick: () => void;
  invitationId: UserInvitation['id'];
  accountId: string;
};

const InvitationAcceptUrlModal: React.FC<InvitationAcceptUrlModalProps> = ({
  onCloseClick,
  accountId,
  invitationId,
}) => {
  const { data, loading } = useGetUserInvitationAcceptUrlQuery({
    variables: {
      accountId,
      invitationId,
    },
  });

  const invitation = data?.account.userInvitation;
  const onAcceptInvitation = invitation?.acceptUrl
    ? () =>
        invitation!.acceptUrl &&
        window.open(adjustAcceptUrl(invitation!.acceptUrl), '_blank')?.focus()
    : undefined;

  const now = useMemo(() => new Date(), []);

  return (
    <Modal
      title="Accept URL"
      onCloseClick={onCloseClick}
      footer={
        <ActionButtons
          primaryLabel="Close"
          onPrimary={onCloseClick}
          onSecondary={onAcceptInvitation}
          disabled={{ secondary: !onAcceptInvitation }}
          secondaryLabel="Accept invitation"
        />
      }
    >
      {loading && <Spinner />}
      {invitation && (
        <div className="mbm">
          <div className="mod">
            <div className={style.label}>Recipient email:</div>
            <div>{invitation.email}</div>
          </div>
          <div className="mod">
            <div className={style.label}>Last sent:</div>
            <div>
              {capitalizeFirst(formatRelative(parseISO(invitation.lastSentAtTimestamp), now))}
            </div>
          </div>
          {invitation.acceptUrl ? (
            <TextFieldWithCopyButton
              value={adjustAcceptUrl(invitation.acceptUrl)}
              disabled={true}
              readOnly={true}
            />
          ) : (
            <p>This invitation does not have a shareable acceptance URL.</p>
          )}
        </div>
      )}
    </Modal>
  );
};

export default InvitationAcceptUrlModal;
