import React, { useContext } from 'react';
import useEventCallback from '../../../utils/useEventCallback';
import { FCC } from '../../../../../utils/types';

const PortalRootId = 'portal-root';

export const getFloatingPortalNode = (id = PortalRootId): HTMLElement => {
  let el = document.getElementById(id);
  if (!el) {
    el = document.createElement('div');
    el.id = id;
    document.body.appendChild(el);
    return el;
  }

  return el;
};

type FloatingContextInterface = {
  floatingPortalNodeRef: React.Ref<HTMLDivElement>;
  getFloatingPortalNode: () => HTMLElement;
  getFloatingPortalNodeId: () => string;
};

const FloatingContext = React.createContext<FloatingContextInterface>({
  floatingPortalNodeRef: React.createRef<HTMLDivElement>(),
  getFloatingPortalNode,
  getFloatingPortalNodeId: () => PortalRootId,
});

const FloatingContextProvider: FCC = ({ children }) => {
  const floatingPortalNodeRef = React.createRef<HTMLDivElement>();
  const getFloatingPortalNode = useEventCallback(
    () => floatingPortalNodeRef.current || getFloatingPortalNode(),
  );
  const getFloatingPortalNodeId = useEventCallback(
    () => floatingPortalNodeRef.current?.id || PortalRootId,
  );

  return (
    <FloatingContext.Provider
      value={{ floatingPortalNodeRef, getFloatingPortalNode, getFloatingPortalNodeId }}
    >
      {children}
    </FloatingContext.Provider>
  );
};

export default FloatingContextProvider;

export const useFloatingContext = () => useContext(FloatingContext);
export const useGetFloatingPortalNode = () => useFloatingContext().getFloatingPortalNode();
